import React from 'react';
import { useNavigation } from '@react-navigation/core';
import { useActionItemsQuery } from './hooks/useActionItems';
import { PendingMedicalAssessmentReviewActionItem } from './ActionListItem/PendingMedicalAssessmentReviewActionItem';
import { PendingDocumentSubmissionReviewActionItem } from './ActionListItem/PendingDocumentSubmissionReviewActionItem';
import { ActivityIndicator, Spacer } from '../../uiComponents';
import { PendingInvitationActionItem } from './ActionListItem/PendingInvitationActionItem';
import { ActionListItem } from './ActionListItem/ActionListItem';
import { useTranslations } from '../../i18n/useTranslations';
import { LoggedInNavType } from '../LoggedIn.interface';
import { PendingEducationItemResubmission } from './ActionListItem/PendingEducationItemResubmission';
import { IncompleteIncidentReport } from './ActionListItem/IncompleteIncidentReport';

export const ActionList = () => {
  const { data } = useActionItemsQuery();
  const actionItems = data?.actionItems;

  if (!actionItems) return <ActivityIndicator />;

  const { translateWithVars, translate } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ActionItems'>>();

  const items: Record<string, React.ReactNode> = {};
  const list = actionItems.reduce((accum, action) => {
    switch (action.__typename) {
      case 'PendingMedicalAssessmentReviewActionItem':
        accum[action.id] = <PendingMedicalAssessmentReviewActionItem item={action} />;
        return accum;
      case 'PendingDocumentSubmissionReviewActionItem':
        accum[action.id] = <PendingDocumentSubmissionReviewActionItem item={action} />;
        return accum;
      case 'PendingInvitationActionItem':
        accum[action.id] = <PendingInvitationActionItem item={action} />;
        return accum;
      case 'ProtocolApprovalActionItem':
        accum[action.id] = (
          <ActionListItem
            onPress={() =>
              navigation.navigate('LoggedIn_Protocol', {
                organizationId: action.organization.id,
              })
            }
            text={translateWithVars(translate.actionItems.protocolApprovalActionItem, {
              organization: action.organization.name,
            })}
          />
        );
        return accum;
      case 'EducationItemResubmissionActionItem':
        accum[action.id] = <PendingEducationItemResubmission item={action} />;
        return accum;
      case 'IncompleteIncidentReportActionItem':
        accum[action.id] = <IncompleteIncidentReport item={action} />;
        return accum;
      default:
        return accum;
    }
  }, items);

  return <Spacer size="large" items={list} />;
};
