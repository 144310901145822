import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppText } from '../../../../uiComponents';
import { Card } from './Card';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { GetCircleOfCare } from '../../ProfileChrome/hooks/__generated__/GetCircleOfCare';

export interface ContentProps {
  circleOfCare: GetCircleOfCare;
}

export const Content: React.FC<ContentProps> = ({ circleOfCare }) => {
  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const data = circleOfCare;

  if (!data) return null;

  const styles = StyleSheet.create({
    container: {
      marginTop: 30,
      flex: 1,
      flexGrow: 1,
    },
    title: {
      textAlign: 'center',
    },
    cards: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: !isMobile ? 'flex-start' : 'center',
      justifyContent: 'space-between',
      marginTop: 15,
    },
  });

  if (circleOfCare.circleOfCare.__typename === 'ApiError') return null;

  const uniqueGuardians = circleOfCare.circleOfCare.guardians
    ? circleOfCare.circleOfCare.guardians.filter(
        (value, index, self) => index === self.findIndex((item) => item.userId === value.userId)
      )
    : [];

  return (
    <View style={styles.container}>
      <View>
        <AppText font="header3">{translate.myProfile.profileHeader.circleOfCare}</AppText>
      </View>
      {data && data.circleOfCare.__typename === 'CircleOfCare' ? (
        <View style={styles.cards}>
          <Card persons={uniqueGuardians} personsType="Guardians" />
          <Card persons={data.circleOfCare.primaryDesignatedPersons} personsType="Primary Designated Persons" />
          <Card persons={data.circleOfCare.secondaryDesignatedPersons} personsType="Secondary Designated Persons" />
        </View>
      ) : null}
    </View>
  );
};
