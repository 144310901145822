import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';

export const useOnNavigationFocus = (handler: () => void) => {
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      handler();
    });

    return unsubscribe;
  }, [navigation]);
};
