import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';

export const GET_USER_EDUCATION_ITEMS_QUERY = gql`
  query GetEducationItems {
    currentUser {
      ... on CurrentUser {
        educationItems {
          ... on OrganizationEducationItemList {
            organizationId
            organizationName
            items {
              link
              name
              id
            }
          }
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetEducationItems = () => useQuery(GET_USER_EDUCATION_ITEMS_QUERY);
