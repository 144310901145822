import { gql } from '@apollo/client';
import { ImageFragment } from './ImageFragment';

export const CurrentUserFragment = gql`
  fragment CurrentUser on CurrentUser {
    id
    firstName
    lastName
    fullName
    birthday
    age
    email
    language
    isSuperAdmin
    roles {
      organizationId
      role
      organizationName
    }
    userImage {
      ...Image
    }
  }
  ${ImageFragment}
`;
