import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { SubmitStageSignoff, SubmitStageSignoffVariables } from './__generated__/SubmitStageSignoff';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';

export const SUBMIT_STAGE_SIGNOFF = gql`
  mutation SubmitStageSignoff($input: SubmitStageSignoffInput!) {
    submitStageSignoff(input: $input) {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${RecoveryPlanFragment}
`;

export const useSubmitStageSignoff = (options?: MutationHookOptions<SubmitStageSignoff, SubmitStageSignoffVariables>) =>
  useMutation<SubmitStageSignoff, SubmitStageSignoffVariables>(SUBMIT_STAGE_SIGNOFF, options);
