import { Chrome } from '../shared/Chrome/Chrome';
import React from 'react';
import { Content } from './Content';
import { LoggedInNavType } from '../LoggedIn.interface';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../i18n/useTranslations';
import { RouteGuard } from '../shared/RouteGuard';
import { Role } from '../../types/globalTypes';

export const OnProtocol: React.FC = () => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_OnProtocol'>>();
  const { translate } = useTranslations();

  const onBack = () => navigation.navigate('LoggedIn_Home');

  return (
    <RouteGuard acceptedRoles={[Role.GUARDIAN, Role.PRIMARY_DESIGNATED_PERSON, Role.SECONDARY_DESIGNATED_PERSON]}>
      <Chrome
        navBarProps={{ onBack }}
        mode="title-card"
        autoScroll={false}
        titleText={translate.caseLog.activeCases}
        content={<Content />}
      />
    </RouteGuard>
  );
};
