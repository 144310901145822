export const french = {
  localeKey: 'fr',
  translations: {
    language: {
      english: 'Anglais',
      french: 'Français',
    },
    label: {
      resume: 'Reprenez',
      done: 'Terminé',
      select: 'Sélectionnez',
      cancel: 'Annulez',
      goBack: 'Revenez en arrière',
      none: 'Aucun',
      saveChanges: 'Sauvegardez les changements',
      submit: 'Soumettez',
      agreeTerms: {
        agreementPart1: 'J’ai lu et j’accepte les',
        termsAndConditions: 'Modalités',
        agreementPart2: 'd’achat de CAPT.',
        modalTitle: 'MODALITÉS DE CAPT',
      },
      next: 'Suivant',
      correct: 'Correct',
      incorrect: 'Incorrect',
      remove: 'Supprimez',
      call: 'Appelez',
      downloadTemplate: 'Téléchargez le modèle',
      uploadAssessment: 'Téléchargez l’évaluation',
      medicalAssessment: 'Évaluation médicale',
      approve: 'Approuvez',
      reject: 'Rejetez',
      reason: 'Raison',
      view: 'Consultez',
      stepForward: 'Avance',
      stepBack: 'Recul',
      completeVerb: 'Complétez',
      completeAdjective: 'Terminé',
      uploadDocument: 'Téléchargez le document',
      create: 'Créez',
      name: 'Nom',
      update: 'Actualisez',
      quit: 'Quitter',
      delete: 'Effacer',
      reactivate: 'Réactivez',
    },
    firebaseAuthErrors: {
      invalidPassword: 'Le mot de passe que vous avez saisi est incorrect. Veuillez réessayer.',
      userNotFound: 'Cet utilisateur est introuvable. Veuillez réessayer.',
      invalidPasswordOrNouser: 'Adresse courriel ou mot de passe incorrect',
    },
    navigation: {
      superAdmin: 'Ventes',
      home: 'Accueil',
      cases: 'Cas',
      training: 'Formation',
      myAccount: 'Mon compte',
    },

    time: {
      present: 'présent',
    },

    placeholder: {
      phone: 'Numéro de téléphone',
      email: 'Adresse courriel',
      password: 'Mot de passe',
      passwordConfirm: 'Confirmez le mot de passe',
      passwordExisting: 'Mot de passe actuel',
      passwordNew: 'Nouveau mot de passe',
      passwordNewConfirm: 'Confirmez le nouveau mot de passe',
      createPassword: 'Créez un mot de passe',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      smsCode: '123123',
      searchPlace: 'Recherchez des lieux...',
      dateOfBirth: 'Date de naissance',
    },

    removeFromSport: 'Retirez',

    flash: {
      title: {
        messageSent: 'Message envoyé',
        passwordChanged: 'Actualisé',
        passwordNotChanged: 'La mise à jour du mot de passe a échoué',
      },
      message: {
        checkYourEmail: 'Consultez vos courriels pour obtenir des directives',
        passwordChanged: 'Votre mot de passe a été mis à jour',
      },
    },

    subjectStatus: {
      modalTitle: 'Statut',
      organizationStatusMessage: '%{status} au sein de %{organization}',
      active: 'Actif',
      onProtocol: 'Soumis au protocole',
      removed: 'Retiré',
      complex: 'Plusieurs',
    },

    violator: {
      noConnection: 'Aucune connexion Internet',
    },

    error: {
      title: {
        generic: 'Erreur',
        authNoPassword: 'Champ obligatoire manquant',
      },
      message: {
        cantBeEmpty: 'Ce champ ne peut pas être vide',
        authNoFirstName: 'Veuillez fournir votre prénom',
        authNoLastName: 'Veuillez fournir votre nom de famille',
        authNoEmail: 'Veuillez fournir une adresse courriel',
        inviteSubjectNoEmail: 'Veuillez ajouter une adresse courriel ou créer un compte de tuteur',
        authNoPassword: 'Veuillez fournir un mot de passe valide',
        authNoPasswordConfirm: 'Veuillez confirmer le mot de passe',
        authNoBirthday: 'Veuillez fournir une date de naissance',
        authPasswordConfirmNotMatch: 'Ces mots de passe ne correspondent pas. Réessayez',
        authNoCode: 'Veuillez saisir le code',
        authFieldsRequired: 'Veuillez à ce que tous les champs soient remplis.',
        existingPassword: 'Votre mot de passe actuel est incorrect.',
        unkownError: 'Erreur inconnue.',
        auth: {
          invalidEmail: 'Veuillez fournir une adresse courriel valide.',
          tooManyRequests: 'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.',
          invalidPhoneNumber: 'Assurez-vous de fournir un numéro de téléphone valide.',
        },
        missingProtocolForSubject: "Il n'a pas été possible d'identifier un protocole à exécuter pour cette personne.",
        mustSelectOption: 'Vous devez sélectionner l’une de ces options',
      },
    },

    errorBoundary: {
      title: "Une erreur s'est produite",
      message: "Une erreur s'est produite. Veuillez recharger l'appli et réessayer",
      reloadButton: 'Rechargez',
    },
    validation: {
      validUrl: 'Saisissez une URL valide',
      validPassword: 'Saisissez un mot de passe valide',
      matchingPasswords: 'Les mots de passe doivent correspondre',
      requiredField: 'Ce champ est obligatoire',
      password: {
        uppercase: '%{num} lettre majuscule',
        lowercase: '%{num} lettre minuscule',
        number: '%{num} chiffre',
        symbol: '%{num} symbole',
        length: '%{num} caractères',
      },
      invalidVerificationCode: 'Code de vérification invalide',
    },

    privacyPolicy: {
      link: 'https://captcares.com/fr/politique-de-confidentialite/',
      readAndAgree1: 'Veuillez lire et accepter notre ',
      readAndAgree2: 'politique de confidentialité.',
    },

    auth: {
      title: {
        signUp: 'Inscrivez-vous',
        phoneLogin: 'Confirmez\nvotre identité',
        resetPassword: 'Réinitialisez mot de passe',
        privacyPolicy: 'Politique de confidentialité',
      },
      changePassword: {
        title: 'Mot de passe modifié',
        success:
          'Vous avez modifié votre mot de passe avec succès. Vous passerez maintenant à l’écran de connexion pour vous connecter',
        emailSent:
          'Un courriel avec des directives sur la façon de réinitialiser votre mot de passe a été envoyé à l’adresse courriel fournie',
        errorTypes: {
          expiredActionCode:
            'Le lien utilisé pour réinitialiser votre mot de passe a expiré. Veuillez soumettre votre adresse courriel pour réinitialiser votre mot de passe',
          invalidActionCode: 'Code d’action invalide. Veuillez réessayer',
          userDisabled: 'Erreur : Ce compte d’utilisateur a été désactivé',
          userNotFound: 'Erreur : L’utilisateur n’a pas été trouvé',
          weakPassword: 'Le nouveau mot de passe n’est pas conforme aux exigences minimales relatives au mot de passe',
        },
      },
      message: {
        codeSent:
          "<b>Un code vient d'être envoyé par SMS à (***) ***-%{digits}</b>. Veuillez le saisir ci-dessous pour vérifier votre identité.",
      },
      term: {
        signIn: 'Connectez-vous',
        createAccount: 'Créez un compte',
        forgotPassword: 'Mot de passe oublié?',
        resetPassword: 'Réinitialisez le mñot de passe',
        resendCode: 'Renvoyer un code?',
        iAccept: 'J’accepte',
        inPartnershipWith: 'En partenariat avec',
      },
    },

    subjectList: {
      organizations: 'Organism',
      organizationList: {
        other: '+%{extra} autre',
        others: '+%{extra} autres',
      },
      subjectData: {
        allGroups: 'Tous les groupes',
        groupLabel: 'Groupe',
        groups: 'groupes',
        noUsersFromSearch: 'Vos critères de recherche ne correspondent à aucun cas',
        endOfList:
          'Vous avez atteint la fin de la liste des utilisateurs. Appuyez sur le bouton Retour pour revenir à la liste',
        noSubjectsInOrganization: "Il n'y a aucun cas lié à cet organisme",
        noKidsInOrganization: "Vous n'avez pas d'enfants dans cet organisme",
        noSubjectsOnProtocol: "Aucun cas n'est actuellement soumis au protocole de cet organisme",
      },
    },

    caseLog: {
      open: 'Ouvert',
      closed: 'Fermé',
      caseLogs: 'Historique du cas',
      activeCases: 'Cas actifs',
      caseItemCaseStatusChange: {
        closed: 'Cas %{caseId} fermé pour %{subject}',
        opened: 'Cas %{caseId} ouvert pour %{subject}',
      },
      caseItemMedicalAssessmentReviewApproved: {
        message: "%{actor} a examiné et approuvé le document d'évaluation médicale",
      },
      caseItemMedicalAssessmentReviewRejected: {
        message: "%{actor} a examiné et rejeté le document d'évaluation médicale pour la raison : %{rejectionReason}",
      },
      caseItemMedicalAssessmentSubmission: {
        message: "%{actor} a soumis un document d'évaluation médicale indiquant %{diagnosis}",
        concussion: 'commotion cérébrale',
        noConcussion: 'aucune commotion cérébrale',
      },
      caseItemRfaProtocolResult: {
        removeDescription: '%{actor} a retiré %{subject} des activités',
        resumeDescription: '%{actor} a permis à %{subject} de reprendre les activités',
        date: 'Date',
        incidentDescription: 'Description de l’incident',
        noneProvided: 'Aucune n’a été fournie',
        runBy: 'Protocole exécuté par',
        outcome: 'Résultat',
        outcomeDescription: 'Protocole a suggéré : %{suggestedResult}\n%{actor} a sélectionné : %{selectedResult}',
        observedRedFlags: "Signaux d'alerte observés : %{redFlags}.",
        noRedFlags: 'Aucun signal d’alerte n’a été observé.',
        viewIncidentReport: 'Rapport',
        attachedDocument: 'Document joint',
        download: 'Téléchargez le document',
        close: 'Fermez',
        noObservedSymptoms: "Aucun symptôme n'a été observé.",
        observedSymptoms: 'Symptômes observés : %{symptoms}.',
        noFailedChallenges: "Aucune question n'a fait l'objet d'une réponse incorrecte.",
        failedChallenges: 'Réponses incorrectes aux questions : %{challenges}.',
        protocolConcluded: 'Protocole conclu avant l’atteinte de cette étape.',
      },
      caseItemRtaProtocolCompletion: {
        message: '%{actor} a indiqué que %{subject} a terminé le protocole %{protocol}',
        fastTracked:
          '%{actor} a indiqué que %{subject} a terminé le protocole %{protocol} avant que la durée minimale requise de %{stepDefinedWaitTime} %{hourOrHours}',
        themself: 'lui-même',
      },
      caseItemRtaProtocolStageChange: {
        other: '%{actor} a approuvé le %{direction} de %{subject} à l’étape %{stage} du protocole %{protocol}',
        self: '%{actor} a approuvé le %{direction} de lui-même à l’étape %{stage} du protocole %{protocol}',
        advance: 'avance',
        fastTracked:
          '%{actor} a avancé %{subject} à l’étape %{stage} avant que la durée minimale requise de %{stepDefinedWaitTime} %{hourOrHours}',
      },
      caseItemRtaProtocolStageDocumentReviewApproved: {
        message: "%{actor} a examiné et approuvé le document soumis pour l'étape %{stage} du protocole %{protocol}",
      },
      caseItemRtaProtocolStageDocumentReviewRejected: {
        message:
          "%{actor} a examiné et rejeté le document soumis pour l'étape %{stage} du protocole %{protocol} pour la raison : %{rejectionReason}",
      },
      caseItemRtaProtocolStageDocumentSubmission: {
        message: '%{actor} a soumis le document pour l’étape %{stage} du protocole %{protocol}',
        fastTracked:
          '%{actor} a soumis le document pour l’étape %{stage} du protocole %{protocol} avant que la durée minimale requise de %{stepDefinedWaitTime} %{hourOrHours}',
      },
      caseItemSubjectStatusChange: {
        message: 'Le statut de %{subject} a changé à %{status}',
      },
      protocolVersion: 'version from %{creationDate}',
    },

    teamRoster: {
      noActiveCasesTitle: 'Aucun cas actif',
      noActiveCasesMessage:
        'Il n’y a pas de cas actifs. Si on soupçonne un cas de commotion cérébrale, le cas apparaîtra ici.',
    },

    wrongUser: {
      screenTitle: 'Utilisateur incorrect',
      title: 'Ce lien concerne un autre utilisateur.',
      message: `Vous êtes actuellement connecté en tant que %{currentEmail}, mais ce lien concerne un autre utilisateur.\n\nVous devez vous déconnecter pour continuer.`,
      logout: 'Déconnectez',
    },

    recovery: {
      index: {
        returnToLearn: 'Retour à l’apprentissage',
        returnToSport: 'Retour aux sports',
        remaining: 'À venir',
        current: 'Présentement actif',
        inactive: 'Inactif',
        pending: 'En attente',
        awaitingReview: 'En attente de révision',
        rejected: 'Rejeté',
        resumeMessage: {
          title: 'Programme de rétablissement terminé',
          concussionInstruction:
            '%{fullName} a terminé le programme de rétablissement et est prêt à reprendre les activités.',
          noConcussionInstruction: '%{fullName} est prêt à reprendre les activités.',
          returnLink: 'Retournez au profil',
        },
        noActiveCasesMessage: '%{fullName} n’a aucun cas actif.',
      },
      submitMedicalAssessment: {
        medicalAssessment: 'Évaluation médicale',
        diagnosis: 'Diagnostic',
        concussion: 'Commotion cérébrale',
        noConcussion: 'Aucune commotion cérébrale',
        documentsSubmitted: 'Merci, votre document a été soumis.',
        notAuthorized: "Vous n'êtes pas autorisé à soumettre une évaluation.",
      },
      reviewMedicalAssessment: {
        rejectionReason: 'Raison du rejet',
        resumingActivity: '%{name} reprend les activités',
        onProtocol: '%{name} est soumis au protocole',
        notAuthorized: 'Cette évaluation est en cours de révision.',
      },
      rejectionReason: {
        title: 'Le document soumis précédemment a été rejeté.',
        titleWithReason: 'Le document soumis précédemment a été rejeté pour la raison suivante : %{reason}',
        resubmit: 'Veuillez vous assurer que vous vous conformez aux exigences, puis soumettez-le de nouveau.',
      },
      stageSignoff: {
        subtitle: '%{organization} - %{protocol}',
        notAuthorized: "Vous n'êtes pas autorisé à approuver cette étape.",
        timeGateRestriction: "Ne devrait pas passer à l'étape suivante avant le %{date}",
        timeGateHours: 'Durée : au moins %{hours} %{hourOrHours}',
        hours: 'heures',
        hour: 'heure',
      },
      stageDocumentSubmission: {
        notAuthorized: "Vous n'êtes pas autorisé à soumettre ce document.",
      },
      stageDocumentReview: {
        notAuthorized: 'Ce document est en cours de révision.',
        document: 'Document',
      },
      fastTrackWarning: {
        title:
          "Vous ne devriez pas passer à l'étape suivante avant que %{numberOfHours} %{hourOrHours} se soit écoulées",
        content:
          "Passer à l'étape suivante trop tôt constitue une violation du protocole des commotions cérébrales qui sera enregistrée et fera l'objet d'un rapport. Assurez-vous de passer suffisamment de temps à cette étape avant de continuer",
      },
      incompleteIncidentReport: {
        title: 'Rapport d’incident incomplet',
        content:
          'Le rapport d’incident doit être terminé par la personne qui a créé le cas avant que la personne blessée ne puisse reprendre les activités',
      },
    },

    login: {
      invitationNotice: {
        invitationMessage: {
          message: `Bonjour %{firstName},\n\nVous avez été invité à vous joindre à %{organization} sur CAPT.\n\n%{organization} utilise CAPT pour s'assurer que tout le monde suit son protocole relatif aux commotions cérébrales.\n\nVeuillez vous connecter en utilisant %{email} pour accepter cette invitation.`,
        },
      },
    },

    register: {
      invitationNotice: {
        invitationMessage: {
          message: `Bonjour %{firstName},\n\nVous avez été invité à vous joindre à %{organization} sur CAPT.\n\nNous utilisons CAPT pour nous assurer que tout le monde comprend et suit notre protocole relatif aux commotions cérébrales.\n\nVous devez créer un compte CAPT pour "%{email}". Inscrivez-vous pour continuer.`,
        },
      },
    },

    verified: {
      title: 'Compte vérifié',
      subtitle: "Merci d'avoir vérifié votre compte.",
      loggedIn: {
        description: `Vous êtes prêt à utiliser CAPT.`,
        continue: 'Continuez',
        stillNotVerified:
          'Désolé, mais votre compte ne semble pas avoir été vérifié. Veuillez vous déconnecter et réessayer.',
      },
      loggedOut: {
        description: 'Pour continuer, veuillez vous connecter avec votre adresse courriel et votre mot de passe.',
        login: 'Connectez-vous',
      },
    },

    invitation: {
      home: {
        title: 'Invitation',
      },
      invitationNotFound: {
        title: "L'invitation n'a pas été trouvée",
        message:
          "Cette invitation est invalide ou a expiré. Veuillez vérifier le lien et réessayer. Si vous avez reçu plusieurs invitations, assurez-vous de suivre le lien figurant dans le courriel le plus récent. Si vous ne pouvez toujours pas accepter l'invitation, veuillez contacter l'organisme auquel vous essayez de vous joindre.",
        goHome: 'Allez à la page d’accueil',
      },
    },

    recognize: {
      title: 'Reconnaissance des commotions cérébrales',
      exitModal: {
        title: 'Êtes-vous certain?',
        content: 'Vos progrès seront perdus si vous choisissez de continuer.',
      },
    },

    loggedIn: {
      chrome: {
        notVerified: {
          title: 'Vérifiez votre adresse courriel',
          description:
            'Avant que vous puissiez utiliser CAPT, vous devez vérifier votre adresse courriel en cliquant sur le lien figurant dans le courriel que nous avons envoyé à %{email}.',
          trouble: `Si vous n'avez pas reçu ce courriel, cliquez sur le bouton ci-dessous pour qu’il soit renvoyé. N’oubliez pas de vérifier votre dossier de courrier indésirable.`,
          resend: `Renvoyez le courriel de vérification`,
          success: 'Le courriel de vérification a été envoyé.',
          successDescription:
            'Veuillez cliquer sur le lien figurant dans le courriel qui vous a été envoyé afin de vérifier votre compte. Si vous ne voyez pas de courriel de notre part, vérifiez votre dossier de courrier indésirable.',
          ok: 'D’accord',
        },
      },
    },

    training: {
      trainingReport: 'Rapport de formation',
      trainingResources: "Ressources d'éducation et formation",
      trainingAndEducation: 'Formation et éducation',
      label: {
        report: 'Rapport de formation de l’organisme',
        resources: "Ressources d'éducation et formation",
        designatedPersonsTrained: 'des responsables et chefs de groupe formés sur les commotions cérébrales',
        completed: 'terminé',
        notCompletedForWeb: 'pas terminé',
        notCompletedForMobile: 'pas \nterminé',
      },
      reportUsersEmpty: "Il n'y a pas encore d'utilisateurs dans cet organisme.",
      disclaimer: {
        part1:
          "* Ces données sont uniquement celles que les utilisateurs ont fournies à CAPT. Pour un complément d'information ou pour validation, veuillez visiter le site web de ", // to be translated - done by Sarah
        part2: 'l’Association canadienne des entraîneurs ',
        part3: "pour voir le relevé public d'un individu.",
      },
      trainingMaterials: 'Matériaux de formation de l’organisme',
      makingHeadWay: {
        title: 'Prendre une tête d’avance - Association canadienne des entraîneurs',
        trainingProgram: 'Programme de formation : Prendre une tête d’avance',
        description:
          "Prendre une tête d’avance est un programme de formation gratuit destiné aux entraîneurs sportifs et à toute personne désireuse d'apprendre à réagir à une commotion cérébrale survenue pendant une activité sportive. Une version s’applique à tous les sports et des versions spécifiques sont adaptées au soccer, au football, à la planche à neige, au ski acrobatique et au patinage de vitesse.",
        instructions: {
          step1:
            'Pour accéder au programme Prendre une tête d’avance, vous devrez créer un compte auprès de l’Association canadienne des entraîneurs dans son portail « Le Casier ».',
          step2:
            'CAPT peut suivre votre achèvement du programme Prendre une tête d’avance. Si vous avez terminé votre formation, veuillez vous connecter au Casier et trouver votre numéro du PNCE sur votre page de profil.',
          step3: 'Ensuite, saisissez votre numéro du PNCE ici :',
        },
        links: {
          trainingSite: 'https://coach.ca/fr/serie-de-modules-dapprentissage-en-ligne-prendre-une-tete-davance',
        },
        button: {
          label: 'Allez à Prendre une tête d’avance',
        },
        checkbox: [
          {
            label: 'J’ai suivi cette formation',
            value: true,
          },
        ],
      },
      nccpNumberLabel: 'Numéro du PNCE',
      trainingCompleted: 'Vous avez indiqué avoir suivi cette formation',
      downloadDocument: 'Téléchargez le document',
      noUsers: "Il n'y a aucun utilisateur dans cet organisme",
      noUsersFound: "Il n'y a pas d'utilisateurs dans cet organisme qui correspondent aux vos critères de recherche",
    },
    education: {
      educationReport: 'Rapport sur l’éducation',
      label: {
        resources: 'Rapport sur l’éducation de l’organisme',
        completed: 'examiné',
        notCompleted: 'pas examiné',
        usersEducated: 'des utilisateurs ont examiné les éléments pédagogiques',
        nudgeAllNotReviewed: 'Encouragez tous ceux qui ne les ont pas examinés',
      },
    },
    home: {
      greeting: 'Bienvenue, %{name}',
      tile: {
        recoveryPlan: 'Programme de rétablissement',
        recognize: 'Reconnaissez une commotion cérébrale',
        recognizeMobile: 'Reconnaissez',
        actionItems: `Mesures à prendre`,
        teamRoster: 'Composition de l’équipe',
        myKids: 'Mes enfants',
        myProfile: 'Mon profil',
        training: 'Formation et Education', //CAPT-1078 to be translated
      },
    },

    actionItems: {
      title: `Mesures à prendre`,
      pendingMedicalAssessmentActionItem:
        'Une évaluation médicale pour %{subject} de %{organization} est en attente de votre révision.',
      pendingDocumentSubmissionActionItem:
        'Un document pour %{subject} de %{organization} est en attente de votre révision.',
      pendingInvitationActionItem: {
        message: `Vous avez été invité à vous joindre à %{organization}.`,
      },
      pendingEducationItemResubmission: {
        message: `Veuillez consulter les ressources d’éducation sur les commotions cérébrales de %{organization}`,
        modalMessage: 'Complétez ces éléments pédagogiques si vous voulez continuer à utiliser CAPT',
      },
      protocolApprovalActionItem: `Le protocole relatif aux commotions cérébrales doit être examiné et approuvé avant que %{organization} puisse commencer à utiliser CAPT.`,
      incompleteIncidentReport: 'Vous devez terminer le rapport d’incident pour %{name} en date du %{date}',
    },

    reviewInvitation: {
      title: `Vous avez été invité!`,
      invitationNotFound: {
        title: "L'invitation n'a pas été trouvée",
      },
      rightUser: {
        title: `Vous avez été invité`,
        messageUserRole: `Vous avez été invité à vous joindre à %{organization} sur CAPT.\n\nNous utilisons CAPT pour nous assurer que les personnes responsables de la sécurité des participants à nos activités comprennent et respectent notre protocole relatif aux commotions cérébrales.\n\n`,
        termsAndConditions: 'Pour continuer, veuillez confirmer que vous avez pris connaissance des modalités.',
        acceptButton: 'Acceptez',
        youMustAcceptAllPolicies: 'Vous devez accepter toutes les politiques pour continuer',
        accepted: 'Merci de vous joindre à %{organization}!',
      },
      wrongUser: {
        title: `Cette invitation concerne un autre utilisateur.`,
        message: `Vous êtes actuellement connecté en tant que %{currentEmail}, mais cette invitation concerne un autre utilisateur.\n\nVous devez vous déconnecter pour continuer.`,
      },
    },

    protocolAttribution: {
      providedBy: 'Protocole fourni par :',
    },

    emergency: {
      callNumber: 'Appelez %{number}',
      isEmergency: 'Traitez comme une urgence',
      title: 'Urgence',
    },

    emergencyContact: {
      contactPhoneNumber: 'Numéro de téléphone : %{phoneNumber}',
      guardians: 'Tuteurs',
      noContactsFound: "Aucun contact en cas d'urgence n'a été trouvé",
    },

    chooseOrganizationProtocol: {
      chooseOrganizationProtocol: 'Sélectionnez un protocole',
      organization:
        'Vous et %{nom} avez plusieurs organismes en commun.\n\nSélectionnez l’organisme que vous voulez utiliser :',
      alreadyRemoved: 'Déjà retiré',
    },

    step: {
      shouldBeRemoved: '%{name} doit être retiré des activités',
      reasonTomRemove: 'Y a-t-il une raison de retirer %{name} des activités?',
    },

    stepChallenges: {
      question: 'QUESTION %{n} sur %{of}',
    },

    incidentReport: {
      title: 'Rapport d’incident',
      incidentDescription: 'Description de l’incident',
      subjectRemovedFromActivity: '%{name} a été retiré des activités',
      subjectResumingActivity: '%{name} reprend les activités',
      uploadFile: 'Téléchargez le fichier',
      instructions: 'Vous pouvez revenir plus tard pour terminer ce rapport, puis le soumettre si nécessaire.',
      skipForNow: 'Sautez cette étape pour le moment',
      dateError: 'Vous devez sélectionner une date antérieure',
      incidentDate: 'Date de l’incident',
      hour: 'Heure',
      minute: 'Minute',
      locationFacility: 'Lieu ou établissement de l’incident',
      incidentType: 'Type d’incident',
      incidentTypes: {
        collisionWithGround: 'Collision avec le sol',
        collisionWithMovingObject: 'Collision avec un objet en mouvement',
        collisionWithPerson: 'Collision avec une personne',
        collisionWithStaticObject: 'Collision avec un objet statique',
        motorVehicleCollision: 'Collision avec un véhicule à moteur',
        unknown: 'Inconnu',
      },
      detailsHeader: 'Détails de l’incident',
      incidentDetails: "Veuillez décrire l'incident",
      pleaseList: 'Veuillez énumérer',
      pleaseDescribe: 'Veuillez décrire',
      howMany: 'Combien?',
      otherSymptomsSection: {
        title: 'Autres blessures ou symptômes',
        question1: "Y a-t-il d'autres symptômes observables ou signalés?",
        question2: 'Y a-t-il des blessures ailleurs (à part la tête)?',
      },
      concussionHistory: {
        title: 'Antécédents de commotion cérébrale',
        question1: 'Est-ce que %{firstName} a déjà subi une commotion cérébrale?',
      },
      medicalHistory: {
        title: 'Antécédents médicaux',
        question1: 'Est-ce que %{firstName} a des problèmes médicaux préexistants?',
        question2: 'Est-ce que %{firstName} prend des médicaments?',
      },
      reportForm: "Formulaire de rapport d'incident",
      attachFile: 'Joignez le fichier',
      symptoms: 'Symptômes',
      signs: 'Signes',
      other: 'Autres blessures ou symptômes',
      author: 'Auteur',
      reportSubmitted: 'Rapport soumis',
      form: "Formulaire de rapport d'incident",
      birthDate: 'Date de naissance',
      observableSigns: 'Signes visibles',
      otherSymptoms: 'Autres symptômes',
      memoryAssessment: 'Évaluation de la mémoire',
      otherInjuries: 'Autres blessures',
      previousConcussions: 'Commotion(s) cérébrale(s) antérieure(s)',
      existingMedicalConditions: 'Problèmes médicaux existants',
      existingMedications: 'Médicaments actuels',
      phone: 'Téléphone',
      removedFromActivity: 'Retiré des activités',
      resumedActivity: 'A repris les activités',
      location: 'Lieu',
      notReported: 'Pas signalé',
      incidentTypeLowerCase: 'Type d’incident',
      details: 'Détails',
      printReport: 'Imprimez le rapport',
      incorrectResponses: 'Réponses incorrectes',
      subjectName: 'Participant: %{subjectName}',
    },

    protocol: {
      tiles: {
        recognize: 'Recognize a Concussion',
        remove: 'Retirez des activités : Commotion cérébrale soupçonnée',
        report: 'Signalez une commotion cérébrale',
      },
      removeModal: {
        title: 'Retirez des activités',
        content1:
          'Vous changez le statut de %{subjectName} qui est maintenant Retiré. Ce statut s’applique aux personnes blessées qui semblent avoir subi une commotion cérébrale et qui ont été retirées des activités.',
        content2: 'Une évaluation médicale est requise pour confirmer le diagnostic.',
        content3: 'Un rapport d’incident doit être soumis pour compléter le cas.',
      },
      mustApprove:
        'Vous devez prendre connaissance du contenu de cette page et accepter son exactitude avant que votre organisme puisse utiliser CAPT.',
      protocolName: 'Nom du protocole',
      link: 'Lien vers le document du protocole',
      education: 'Formation en matière de commotions cérébrales',
      educationText:
        "Les utilisateurs doivent lire ces points et indiquer qu’ils les comprennent au moment où ils se joignent à l'organisme dans CAPT.",
      shownTo: 'Montré à',
      noOne: 'personne',
      removal: 'Protocole de retrait',
      redFlags: 'Signaux d’alerte',
      removalInstructions: 'Directives relatives au retrait',
      emergencyInstructionDescription:
        "Directive pour la personne désignée en cas d'urgence lorsque des signaux d'alerte ont été détectés et que le participant a besoin de soins d'urgence :",
      discretionaryInstructionDescription:
        'Directive pour la personne désignée quand la décision du retrait est prise à titre discrétionnaire (aucun symptôme détecté) :',
      removeInstructionDescription:
        "Directive pour la personne désignée après que la décision du retrait a été prise. Incluez les directives concernant ce que le participant n'est pas autorisée à faire :",
      resumeInstructionDescription:
        'Directive pour la personne désignée qui est montrée après que la décision de continuer les activités a été prise :',
      incidentReport: 'Rapport d’incident',
      incidentReportText:
        "Un rapport d'incident, qui contient les remarques de la personne désignée et(ou) un document téléchargé, est rempli à la fin de ce protocole.",
      incidentReportInstructionDescription: "Directive pour remplir le rapport d'incident :",

      downloadTemplate: 'Téléchargez le document modèle',
      noTemplateDocument: 'Document modèle : Aucun',
      return: 'Protocoles de reprise des activités',
      returnText:
        'Chaque fois qu’un participant passe à une nouvelle étape d’un protocole de reprise des activités, un avis est envoyé par courriel au participant, à ses tuteurs, aux chefs de groupe qui en sont responsables et au responsable des commotions cérébrales de l’organisme.',
      medicalAssessmentApproval: 'Ce document doit être approuvé par la principale personne désignée.',
      returnToLearn: "Protocole de Retour à l'apprentissage",
      returnToSport: 'Protocole de Retour aux sports',
      returnStep: {
        anyone: 'N’importe qui',
        pdp: 'Seule la principale personne désignée',
        documentPermission: 'peut télécharger le document',
        signoffPermission: 'peut avancer',
        minimum: 'Minimum ',
        hours: '%{numberOfHours} %{hourOrHours}',
      },
      documentApproval: 'Nécessite l’approbation du document soumis par la principale personne désignée',
      approval: `J'ai examiné le ou les protocoles relatifs aux commotions cérébrales de mon organisme dans CAPT et je reconnais qu'ils sont corrects.`,
      approvalConfirmation: 'Protocole approuvé : Votre organisme peut maintenant utiliser CAPT.',
    },

    profile: {
      heading: {
        personal: 'Renseignements personnels',
        emergencyContact: 'Contact en cas d’urgence',
        history: 'Historique du cas',
      },
      term: {
        organization: 'Organisme',
      },
    },

    myProfile: {
      title: 'Profil',
      profileHeader: {
        recovery: 'Rétablissement',
        log: 'Historique du cas',
        circleOfCare: 'Cercle de soins',
      },
      memberships: {
        groups: 'Groupes',
      },
      activeCases: {
        stageNumberDetails: '(étape %{latestStage} sur %{numberOfStages})',
      },
      yearsOld: 'ans',
    },

    settings: {
      title: 'Paramètres',
      term: {
        updatePassword: 'Mettez le mot de passe à jour',
      },
      multiFactor: {
        enterPassword:
          "Saisissez votre mot de passe pour désactiver l'authentification multifactorielle sur votre compte",
      },
      changePassword: {
        title: 'Changez\nmot de passe',
        requirements:
          'Les mots de passe doivent comprendre <b>une lettre majuscule, une lettre minuscule, un chiffre, un symbole</b> et au moins 6 caractères.',
      },
    },

    account: {
      title: 'Mon compte',
      pickImage: 'Choisissez une image',
      yourDetails: {
        birthday: 'Date de naissance',
        email: 'Courriel',
        phoneNumber: 'Numéro de téléphone',
        changePassword: 'Changez le mot de passe',
        passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
        allPasswordFieldsRequired: 'Vous devez remplir tous les champs du mot de passe pour le mettre à jour',
        language: 'Langue',
      },
      save: 'Sauvegardez',
    },

    users: {
      manageUsersTitle: 'Utilisateurs',
      participationReportTitle: 'Rapport sur la participation',
      participants: 'utilisateurs',
      accepted: 'Accepté',
      admin: 'administrateur',
      allDesignatedPersons: 'toutes les personnes désignées',
      subjects: 'participants',
      guardians: 'tuteurs',
      nudgedAlert: '%{total} utilisateurs encouragés',
      roles: {
        admin: 'Administrateur',
        primaryDesignatedPerson: 'Responsable des commotions cérébrales',
        secondaryDesignatedPerson: 'Chef de groupe',
        subject: 'Participant',
        guardian: 'Tuteur',
      },
      rolesPlural: {
        admin: 'Administrateurs',
        primaryDesignatedPerson: 'Responsable des commotions cérébrales',
        secondaryDesignatedPerson: 'Chefs de groupe',
        subject: 'Participants',
        guardian: 'Tuteurs',
      },
      sectionHeaders: {
        guardians: 'Tuteur(s)',
        addToGroups: 'Ajoutez à des groupes',
        addUserToGroups: 'Ajoutez un utilisateur à des groupes',
      },
      selectGroups: {
        selectGroups: 'Sélectionnez des groupes',
        selectAGroup: 'Sélectionnez un groupe',
      },
      addUser: {
        title: 'Ajoutez un utilisateur',
        invite: 'Invitez',
        guardians: 'Tuteur %{index}',
        addGuardian: 'Ajoutez un tuteur',
      },
      userDetails: {
        title: 'Modifiez un utilisateur',
        invite: 'Invitez un utilisateur',
        noUsers: 'Il n’y a pas d’utilisateurs. Cliquez sur « Ajouter » pour inviter votre premier utilisateur.',
        removeRoleConfirmModal: {
          title: 'Supprimez le rôle',
          message: `Êtes-vous sûr de vouloir supprimer le rôle %{role} de %{name}?`,
        },
        nudgeRoleConfirmModal: {
          title: "Envoyez un courriel d'encouragement",
          message: `%{name} n’a pas activé son compte.`,
          send: 'Envoyez',
        },
        userHasNoGuardians: 'Cet utilisateur n’a aucun tuteur',
        sort: 'Triez',
        role: 'Rôle',
        searchByName: 'Recherchez par nom...',
        nudgeAllPending: 'Encouragez tous ceux qui sont en attente',
        resendEducationItems: 'Renvoyez les éléments pédagogiques',
        resendEducationConfirmation:
          "Appuyez sur 'Renvoyez les éléments pédagogiques' pour exiger que tous les utilisateurs complètent les éléments pédagogiques. Ils ne pourront pas utiliser CAPT tant qu'ils ne les auront pas complétés.",
        educationItemsResent: 'Éléments pédagogiques renvoyés',
        educationItemsResentDetails:
          'Les éléments pédagogiques ont été renvoyés avec succès à tous les utilisateurs. Les utilisateurs devront les compléter à nouveau avant de pouvoir utiliser CAPT.',
        deleteUserConfirmationTitle: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
        deleteUserConfirmationContent:
          'Les autres utilisateurs de CAPT de cet organisme ne pourront plus voir %{userName}.',
      },
    },

    seasons: {
      title: 'Saisons',
      createSeason: 'Créez la saison',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      noSeasons: 'Aucune saison',
    },

    admin: {
      title: 'Administration',
      heading: {
        manageUsers: 'Gérez les utilisateurs',
        manageSeasons: 'Gérez les saisons',
        settings: 'Paramètres de l’organisme',
      },
      label: {
        manageUsers: 'Gérez les utilisateurs',
        manageSeasons: 'Gérez les saisons',
        manageProtocol: 'Gérez le protocole',
        manageGroups: 'Gérez les groupes',
        userLocationTracking: "Activez le suivi de l’emplacement de l'utilisateur",
        demoModeAvailable: 'Proposez le mode de démonstration',
        homeTiles: "Tuiles d'écran d'accueil",
        telemedicineProviders: 'Fournisseurs de services de télémédecine',
        disabledServices: 'Services désactivés',
        triageOnly: 'Assurez seulement le triage',
        manageOrganization: 'Gérez l’organisme',
        viewProtocol: 'Consultez le protocole',
        selectOrganization: 'Sélectionnez l’organisme',
      },
      manageSeasons: {
        seasonDetails: {
          groups: 'Groupes :',
          noGroups: "Il n'y a aucun groupe dans cette saison.",
          addGroup: 'Ajoutez un groupe',
          deactivateGroupModal: {
            title: 'Désactivez le groupe',
            content: 'Êtes-vous sûr de vouloir désactiver le groupe?',
            deactivate: 'Désactivez',
          },
          updateGroupModal: {
            title: 'Mettez le groupe à jour',
          },
          createGroupModal: {
            title: 'Créez un groupe',
            groupName: 'Nom du groupe',
            groupAlreadyExistsTitle: 'Group Existe Déjà',
            inactiveGroupAlreadyExistsContent:
              'Il existe déjà un groupe nommé %{group} qui était désactivé auparavant. Voulez-vous réactiver ce groupe?',
            activeGroupAlreadyExistsContent:
              'Un groupe nommé %{group} existe déjà. Veuillez utiliser cela ou donnez les deux groupes des noms différents.',
          },
        },
        addGroup: {
          title: 'Ajoutez un groupe',
          invitePlaceholder: 'Nom ou courriel',
          saveGroup: 'Sauvegardez le groupe',
        },
        groupDetails: {
          invited: 'Invitée',
          invitePlaceholder: 'Commencez à taper...',
          subjects: 'Participants',
          addDesignatedPerson: 'Ajouter un chef de groupe',
          addSubject: 'Ajoutez un participant',
          noSubjects:
            'Ce groupe n’a aucun participant. Cliquez sur « Ajouter » pour inviter votre premier participant.',
          noSubjectsFound: 'Il n’y a pas de participants dans ce groupe qui correspondent à vos critères de recherche',
          endOfSubjectsList:
            'Vous avez atteint la fin de la liste des participants. Appuyez sur le bouton Retour pour revenir à la page précédente',
          noDesignatedPersons:
            'Ce groupe n’a pas de chef. Cliquez sur « Ajouter » pour inviter votre premier chef de groupe.',
          noDesignatedPersonsFound: 'There are no group leaders in this group that match your search criteria',
          endOfDesigantedPersonsList:
            'Vous avez atteint la fin de la liste des chefs de groupe. Appuyez sur le bouton Retour pour revenir à la page précédente',
          inviteInstruction: "Saisissez un nom pour ajouter un membre de l'organisme à ce groupe.",
          inviteOrSearchInstruction:
            "Saisissez une adresse courriel pour inviter un nouvel utilisateur ou saisissez un nom pour trouver quelqu'un qui a déjà été ajouté à cette saison.",
          createUser: 'Créez utilisateur',
          createSubject: 'Créez un participant',
          createDesignatedPerson: 'Créez une personne désignée',
        },
      },
      manageOrganization: {
        organizationNameInputLabel: 'Nom de l’organisme',
        typeLabel: 'Type',
        sportLabel: 'Sport',
        protocolLabel: 'Protocole',
        medicalEmergencyPhoneNumber: "Numéro de téléphone en cas d'urgence médicale",
        medicalEmergencyNameLabel: 'Nom (p. ex. : services d’urgence sur le campus)',
        medicalEmergencyPhoneLabel: 'Numéro de téléphone',
        updateOrganizationBtnLabel: 'Mettez l’organisme à jour',
        ok: 'D’accord',
        successfullyUpdated: '%{organizationName} a été mis à jour avec succès',
        modalTitle: 'Organisme mis à jour',
      },
      manageProtocol: {
        title: 'Gérez le protocole',
        label: 'Étiquette',
        value: 'Valeur',
        index: {
          instruction: 'Sélectionnez une section du protocole à modifier :',
          sectionGeneral: 'Détails généraux',
          sectionEducation: 'Protocole de formation',
          sectionRemoval: 'Protocole de retrait',
          preseasonEducationItems: "Articles d'éducation d'avant-saison",
        },
        documentSelector: {
          clear: 'Effacez',
          downloadCurrent: 'Téléchargez celui qui est courant',
        },
        successTitle: 'Protocole sauvegardé',
        successDescription: 'Les cas ouverts continuent de suivre le protocole en vigueur au moment de leur création.',
        instruction: 'Directive',
        selectTemplateDocument: 'Sélectionnez le document modèle',
        templateDocument: 'Document modèle',
        updateProtocolConfigForm: {
          overview: {
            title: 'Détails du protocole',
            instruction1: "Indiquez le nom et le site Web de l'organisme qui a élaboré votre protocole.",
            instruction2:
              'Si vous avez élaboré votre propre protocole, utilisez le nom et le site Web de votre organisme.',
            protocolName: 'Source du protocole',
            protocolUrl: 'URL du protocole',
          },
          education: {
            instruction:
              "Définissez les éléments que vos utilisateurs doivent examiner et reconnaître lorsqu'ils se joignent à une nouvelle saison.",
            item: {
              selectDocument: 'Téléchargez le nouveau document',
              downloadCurrentDocument: 'Téléchargez le document actuel',
              instruction: "Modèle d'élément",
              showToDesignatedPersons: 'Montrez aux personnes désignées invitées',
              showToGuardians: 'Montrez aux tuteurs invités',
              showToSubjects: 'Montrez aux participants invités',
              preview: 'Prévisualisez',
              missingDocument: 'Vous devez joindre un document',
              url: 'URL',
              resourceType: 'Type de ressource',
            },
            addNew: 'Ajoutez un nouvel élément',
          },
          remove: {
            instruction:
              "Définissez les étapes que les personnes désignées doivent suivre lorsqu'elles prennent une décision de retrait des activités.",
            addNewStep: 'Ajoutez une nouvelle étape',
            emergencyInstruction: 'Directive en cas d’urgence',
            removeInstruction: 'Directive relative au retrait des activités',
            resumeInstruction: 'Directive relative à la reprise des activités',
            discretionaryInstruction: 'Directive discrétionnaire',
            redFlag: {
              flags: 'Signaux',
              addNewRedFlag: 'Ajoutez un nouveau signal d’alerte',
            },
            steps: 'Étapes',
            step: {
              challenge: 'Défi',
              stepType: 'Type d’étape',
              symptom: 'Symptôme',
              addSymptom: 'Ajoutez un symptôme',
              addChallenge: 'Ajoutez un défi',
            },
          },
          return: {
            title: 'Protocole de reprise des activités',
            instruction:
              'Définissez les protocoles qui doivent être suivis afin de permettre aux participants de reprendre les activités.',
            returnToLearn: 'Retour à l’apprentissage',
            returnToSport: 'Retour au sport',
            childProtocol: {
              enable: 'Activez',
              disable: 'Désactivez',
            },
            medicalAssessment: {
              instruction: 'Directives',
            },
            addNewStage: 'Ajoutez une nouvelle étape',
            stage: {
              description: 'Description',
              documentSubmission: 'Soumission du document',
              signoff: 'Autorisation',
              stageType: 'Type d’étape',
              authorizedRoleSignoff: 'Qui peut autoriser cette étape?',
              authorizedRoleDocumentSubmission: 'Qui peut soumettre un document pour cette étape?',
            },
          },
          update: 'Sauvegardez les changements',
          reset: 'Réinitialisez',
          goBack: 'Revenez en arrière',
        },
        timeGateOption: {
          askForTimeGating: 'Voulez-vous exiger une période de temps minimale à cette étape?',
          minimumHours: "Nombre d'heures minimales à cette étape",
          nonIntegerErrorMessage: 'Ce chiffre doit être entier et positif',
          emptyErrorMessage:
            "Ce champ est obligatoire. Sélectionnez 'non' pour l'option précédente si vous ne voulez pas régler un nombre d'heures minimales",
        },
      },
      settings: {
        title: {
          orgAdmin: 'Paramètres de l’organisme',
          superAdmin: 'Paramètres administratifs',
        },
        term: {
          orgSettings: "Paramètres de base de l'organisme",
          adminSettings: "Paramètres essentiels de l'organisme",
        },
        hint: {
          feedbackChannel: 'Le moyen utilisé pour la rétroaction peut être une URL ou une adresse courriel',
          supportEmail: 'Une adresse courriel à laquelle les utilisateurs peuvent envoyer leurs demandes de soutien',
          minimumAge: "Âge minimum requis pour utiliser l'appli",
          demoModeAvailable:
            "À savoir si les utilisateurs peuvent activer le mode de démonstration pour tester les fonctions d'assistance médicale",
          disabledServices: "Empêchez que les services suivants soient offerts dans le cadre de l'assistance médicale",
          triageOnly: "Proposez seulement des services de triage par le biais de l'assistance médicale",
        },
      },
    },

    superAdmin: {
      createOrganization: {
        title: 'Créez un organisme',
        isPDP: 'Faites de l’administrateur un responsable des commotions cérébrales',
        successfullyCreated: "L'organisme %{organization} a été créé avec succès",
        sucessNotification: 'Un nouvel organisme a été créé.',
        modalTitle: 'Organisme créé',
        protocolPreset: 'Préréglage du protocole',
        protocolPresets: {
          blank: 'Vide',
          ontarioSoccerAssociation: 'Ontario Soccer Association',
          ontarioHockeyAssociation: 'Ontario Hockey Association',
          parachuteSmartHockey: 'Hockey intelligent de Parachute',
          ontarioProtocolForSchools: "Protocole de l'Ontario pour les écoles",
          parachuteProtocolForSchools: 'Protocole de Parachute pour les écoles',
        },
        organizationType: 'Type d’organisme',
        organizationTypes: {
          sportsOrganization: 'Organisme sportif',
          school: 'École',
        },
        createAdmin: 'Créez un administrateur',
        sports: {
          other: 'Autre',
          baseball: 'Baseball',
          basketball: 'Basketball',
          fieldHockey: 'Hockey sur gazon',
          hockey: 'Hockey',
          soccer: 'Soccer',
          rugby: 'Rugby',
          athletics: 'Atlétisme',
          equestrian: 'Équestre',
          tennis: 'Tennis',
          multiple: 'Plusieurs',
          speedSkating: 'Patinage de vitesse',
          alpineSki: 'Ski alpin',
          football: 'Football',
          freestyleSki: 'Ski acrobatique',
          snowboard: 'Snowboard',
          lacrosse: 'Crosse',
          boxing: 'Boxe',
          wrestling: 'Lutte',
          gymnastics: 'Gymnastique',
          ringette: 'Ringuette',
        },
        associationsHeader: 'Association(s) ou ligue(s)',
        selectAssociation: 'Ajoutez...',
      },
      importData: {
        chooseOrganization: 'Choisissez un organisme pour importer les données',
        selectOrganization: 'Sélectionnez l’organisme',
        downloadTemplates: 'TÉLÉCHARGEZ LES MODÈLES',
        chooseOrganizationDropdown: 'Choisissez l’organisme',
        importData: 'Importez les données',
        importErrors: "Erreurs d'importation",
        pastUploads: 'Téléchargements antérieurs',
        instructionsContent:
          'Veuillez télécharger les fichiers suivants qui sont basés sur les modèles : participants, personnes désignées, tuteurs, mappage entre des groupes et de participants et mappage entre des groupes et de personnes désignées.', // to be translated
        formFieldTitles: [
          { title: 'Créez des participants', value: 'SUBJECTS' },
          { title: 'Créez des tuteurs', value: 'GUARDIANS' },
          { title: 'Créez le personnel (personnes désignées et administrateurs)', value: 'STAFF' },
          { title: 'Créez ou actualisez groupes des participants', value: 'SUBJECT_GROUPS' },
          { title: 'Créez ou actualisez groupes des personnes désignées', value: 'DESIGNATED_PERSON_GROUPS' },
        ],
        uploadButton: 'Téléchargez le fichier',
        uploadAndValidate: 'Téléchargez et validez',
        uploadAndValidateInProgress: 'Importation en cours...',
        viewPastImports: 'Consultez les importations antérieures',
        importIsSuccessful: 'L’importation a réussi',
        importFailed: 'L’importation a échoué',
        viewData: 'Consultez les données importées',
        viewFailedData: 'Consultez les données qui ont échoué',
        failureMessage: 'Certaines données sont invalides',
        invalidRowsMessage: 'Consultez les lignes invalides',
        successMessage: 'Les données sont valides : Cliquez sur Importez pour terminer',
        completeImport: 'Importez',
        downloadCsv: 'Téléchargez',
        importErrorsModalText: "Les données énumérées ici n'ont pas été importées dans CAPT.",
        noPastUploadsAvailable: 'Il n’y a pas de téléchargements antérieurs pour cet organisme.',
        partialErrors: "Certaines données n'ont pas été importées",
        row: 'Rangée',
        invalidLink: 'Erreur: Impossible de télécharger le fichier. Lien invalide.',
        status: {
          SUCCESSFULLY_COMPLETED: 'importé',
          IN_PROGRESS: 'importation en cours',
          COMPLETED_WITH_ERRORS: 'importé avec erreurs',
          FAILED: 'échec',
          ERRORS: 'importé avec des erreurs',
          INCORRECT_HEADERS: 'En-têtes invalides',
        },
        sendInviteAfterImport: 'Send invite emails immediately on import',
        sendInviteAtLaterDate:
          'If you want to send emails at a later time, use the "Nudge All" button in the participation report.',
      },
      paymentStatus: {
        title: 'État du paiement',
        expiresAtLabel: "Abonnement valable jusqu'au",
        label: {
          dateCreated: 'Date créé',
        },
        statusUpdate: 'Le statut de %{organizationName} a été mis à jour à %{organizationStatus}',
        statuses: {
          trial: 'Essai',
          paid: 'Payé',
          suspended: 'Suspendu',
          demo: 'Démonstration',
          test: 'Test',
        },
      },
    },

    cases: {
      pastCases: 'Cas antérieurs',
      activeCases: 'Cas actifs',
      noPastCases: "Il n'y a pas de cas antérieurs",
      noActiveCases: "Il n'y a pas de cas actifs",
      label: {
        startDateDesc: 'Date de début: La plus récente',
        startDateAsc: 'Date de début: La plus ancienne',
        endDateDesc: 'Date de fin: La plus récente',
        endDateAsc: 'Date de fin: La plus ancienne',
      },
      fromStartDate: 'À compter de la date de début',
      toStartDate: 'Jusqu’à la date de début',
      numberOfDays: '%{number} jours',
      dateRangeForClosedCase: '%{createdDate} jusqu’au %{closedDate}',
      dateRangeForActiveCase: '%{createdDate} jusqu’à présent',
    },

    fileButton: {
      selectQuestion: 'Comment souhaitez-vous soumettre votre document?',
      attachFile: 'Joignez un fichier',
      takePhoto: 'Prenez une photo',
    },

    featureFlag: {
      noAccess: 'Vous ne pouvez pas accéder à cet écran.',
    },

    searchAndSort: {
      all: 'Toutes',
    },

    answers: {
      YES: 'oui',
      NO: 'non',
      DONT_KNOW: 'je ne sais pas',
    },

    errorCodes: {
      adminDoesNotBelongInOrganization: 'L’administrateur ne fait pas partie de l’organisme',
      unknownImportType: 'Type d’importation inconnu',
      couldNotCreateImport: 'Impossible de créer une importation',
      incorrectHeaders: 'En-têtes incorrects',
    },

    // Duplicate variable
    organizationSports: {
      athletics: 'Atlétisme',
      baseball: 'Baseball',
      basketball: 'Basketball',
      equestrian: 'Équestre',
      fieldHockey: 'Hockey sur gazon',
      hockey: 'Hockey',
      multiple: 'Plusieurs',
      other: 'Autre',
      rugby: 'Rugby',
      soccer: 'Soccer',
      tennis: 'Tennis',
      speedSkating: 'Patinage de vitesse',
      alpineSki: 'Ski alpin',
      football: 'Football',
      freestyleSki: 'Ski acrobatique',
      snowboard: 'Snowboard',
      lacrosse: 'Crosse',
      boxing: 'Boxe',
      wrestling: 'Lutte',
      gymnastics: 'Gymnastique',
      ringette: 'Ringuette',
    },

    importTypes: {
      staff: 'Personnel',
      subjectGroups: 'Groupes de participants',
      designatedPersonGroups: 'Groupes de personnes désignées',
    },

    appUpdate: {
      title: 'Vous devez mettre l’appli CAPT à jour',
      message: 'Il existe une nouvelle version de CAPT. La version que vous utilisez ne fonctionnera plus.',
      instructions:
        "Veuillez télécharger la version la plus récente sur l'App Store ou Google Play pour continuer à utiliser CAPT.",
      goToAppStore: 'Allez sur l’App Store',
      goToPlayStore: 'Allez sur Google Play Store',
      appStore: 'l’App Store',
      playStore: 'Google Play',
    },

    termsAndConditions: `<AppText style={{ display: 'flex', flexDirection: 'column' }}>
      <View style={{ marginVertical: 5 }}>
        <Text> Agile Intelligence Solutions Inc. (<Text style={{ fontWeight: 'bold' }}>« Entreprise »</Text>) est titulaire de certains logiciels qu'elle a développés et met commercialement à la disposition de ses clients en tant qu’offre de services de logiciel géré et hébergé en nuage. Dans le cadre de l'utilisation du Service (tel que défini ci-dessous), vous acceptez par les présentes les modalités contenus dans le présent document (les{' '} <Text style={{ fontWeight: 'bold' }}>« Modalités »</Text>). </Text> 
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> EN ACCEPTANT LES MODALITÉS SOIT : A) EN SIGNANT LE BON DE COMMANDE OU LE DEVIS (TEL QUE DÉFINI CI-DESSOUS) QUI RENVOIE AUX MODALITÉS, OU B) EN UTILISANT LE SERVICE OU EN Y ACCÉDANT, LE CLIENT RECONNAÎT QU'IL A LU ET COMPRIS TOUTES LES DISPOSITIONS, ET QU'IL A LE POUVOIR D'ACCEPTER, ET CONFIRME QU'IL ACCEPTE DE SE CONFORMER ET D'ÊTRE LIÉ PAR TOUTES LES MODALITÉS CONTENUES DANS LE PRÉSENT DOCUMENT, AINSI QUE DANS TOUT BON DE COMMANDE OU DEVIS, ET Y COMPRIS LA POLITIQUE DE CONFIDENTIALITÉ DE L'ENTREPRISE DÉFINIE À L'ADRESSE <AppLink>https://www.captcares.com/privacy-notice</AppLink>, QUI SONT TOUS INTÉGRÉS PAR RENVOI ET CONSIDÉRÉS COMME FAISANT PARTIE DE L'ENTENTE INTÉGRALE CONCLUE ENTRE L’ENTREPRISE ET LE CLIENT. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> SI VOUS ÊTES UN AGENT OU UN EMPLOYÉ DU CLIENT, PAR LES PRÉSENTES, VOUS DÉCLAREZ ET GARANTISSEZ CE QUI SUIT : (I) LA PERSONNE QUI ACCEPTE LA PRÉSENTE ENTENTE EST DÛMENT AUTORISÉE À ACCEPTER LA PRÉSENTE ENTENTE AU NOM DU CLIENT ET À ENGAGER LE CLIENT, ET (II) LE CLIENT A TOUT POUVOIR, CORPORATIF OU AUTRE, POUR CONCLURE LA PRÉSENTE ENTENTE ET S'ACQUITTER DES OBLIGATIONS EN VERTU DES PRÉSENTES. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>1. Définitions</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text>1.1 Dans la présente Entente :</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Entente</Text> » : le Bon de commande ou le Devis, selon le cas, et les présentes Modalités. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Protocole relatif aux commotions cérébrales</Text> » : le ou les protocoles internes relatifs aux commotions cérébrales du Client tels qu'élaborés par le Client et fournis à l’Entreprise, qui seront rendus disponibles dans le cadre du Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Renseignements confidentiels</Text> » : tout renseignement des parties sous forme électronique ou matérielle qui porte clairement la mention « Confidentiel » et(ou) qui : a) n'était pas connu auparavant de la partie qui reçoit la divulgation; b) n'a pas été obtenu d'un tiers n'ayant pas de devoir de confidentialité envers la partie qui invoque la confidentialité; ou c) n'a pas été publié ou n'est pas disponible d'une autre manière à partir de sources accessibles au public. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Client</Text> » : le client dont le nom figure dans le Bon de commande ou le Devis applicable. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Données des clients</Text>" : les données fournies au Service par le Client ou pour le Client, ou recueillies et traitées par le Client ou pour le Client en utilisant le Service, y compris, mais sans s'y limiter, les données et les Renseignements personnels appartenant aux employés, aux élèves et(ou) aux athlètes, et aux parents et(ou) aux tuteurs du Client, le cas échéant. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Lois sur la protection des données</Text> » : l’ensemble des lois et des règlements, y compris les lois et les règlements du Canada (notamment la Loi sur la protection des renseignements personnels et les documents électroniques (Canada) et la Loi canadienne anti-pourriel, chacune étant modifiée ou remplacée de temps à autre) applicables au traitement des Renseignements personnels en vertu de l’Entente. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Frais</Texte> » : les frais facturés par l’Entreprise et payables par le Client pour le Service, tels que définis dans le Bon de commande ou le Devis. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Renseignements personnels</Text> » : tout renseignement relatif à une personne physique identifiée ou identifiable, telle que définie par les lois en vigueur sur la protection des données. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Bon de commande</Text> » ou « <Text style={{ fontWeight: 'bold' }}>Devis</Text> » : un formulaire fourni par le Client concernant l'achat du Service par le Client auprès de l’Entreprise. </Text> </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Service</Text> » : le logiciel hébergé en nuage en tant qu’offre de services, CAPT, pour lequel le Client dispose de droits d'accès et d'utilisation conformément à la présente Entente, y compris les services auxiliaires connexes qui sont disponibles puisque ce Service peut être mis à jour de temps à autre par l’Entreprise à sa seule discrétion. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Services de soutien</Text> » : les services de soutien technique pour le Service fourni par l’Entreprise, tels que décrits dans les Modalités de soutien et conformément à celles-ci. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Modalités de soutien</Text> » : les modalités que l’Entreprise applique pour la fourniture des Services de soutien au Client. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Durée</Text> » : la période pendant laquelle le Client est autorisé par l’Entreprise à accéder au Service et à l'utiliser. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> « <Text style={{ fontWeight: 'bold' }}>Utilisateur</Text> » : les employés, les élèves et(ou) les athlètes, ainsi que les parents et(ou) les tuteurs du Client à qui le Client (ou l’Entreprise à la demande du Client) a fourni un identificateur d'utilisateur et un mot de passe. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>2. Le Service</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.1. <Text style={{ textDecorationLine: 'underline' }}>Droit d'utilisation du Service</Text>. Sous réserve des modalités de la présente Entente (y compris le Bon de commande ou le Devis applicable) et du paiement des Frais applicables, l’Entreprise accorde au Client par les présentes un droit interne non exclusif, mondial, non transférable et ne pouvant donner lieu à l'octroi d'une sous-licence d'accéder au Service et de l'utiliser (et de permettre aux Utilisateurs d'accéder au Service et de l'utiliser), uniquement pendant la Durée. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.2. <Text style={{ textDecorationLine: 'underline' }}>Réserve de droits</Text>. L’Entreprise et ses concédants de licence possèdent et conservent tous les droits, titres et intérêts (y compris, mais sans s'y limiter, tous les droits de brevet, droits d'auteur, droits de marque, droits de secret commercial et tous les autres droits de propriété intellectuelle) relatifs au Service et les copies, les corrections, les correctifs de bogues, les améliorations, les modifications ou les nouvelles versions, qui sont tous considérés comme faisant partie du Service et sont soumis à toutes les dispositions de la présente Entente. Le Client veille à ce que le Service demeure libre et dégagé des privilèges, des charges et(ou) des intérêts en matière de sécurité. Sous réserve des droits limités expressément accordés dans la présente Entente, l’Entreprise se réserve tous les droits, titres et intérêts relatifs au Service. Aucun droit n'est accordé au Client en vertu de la présente Entente, à l'exception de ceux expressément indiqués dans la présente Entente. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.3. <Text style={{ textDecorationLine: 'underline' }}>Restrictions</Text>. Le Client ne doit pas (et ne doit pas permettre aux Utilisateurs ou à tout tiers de) : (a) posséder, télécharger ou copier le Service ou toute partie du Service, y compris, mais sans s'y limiter, tout élément composant le Service, à l'exclusion de tout résultat du Service; (b) interférer sciemment avec le service offert aux utilisateurs, à l'hôte ou au réseau de l’Entreprise, y compris en soumettant intentionnellement un virus, en surchargeant, en inondant, en faisant du multipostage abusif, en bombardant de courriels ou en faisant échouer le Service; (c) modifier, traduire, effectuer une ingénierie inverse, décompiler, désassembler ou créer des œuvres dérivées basées sur le Service, sauf dans la mesure où l'application est interdite par la loi applicable; (d) contourner les restrictions temporelles intégrées au Service; (e) vendre, louer, prêter, transférer, distribuer, concéder une licence ou accorder des droits sur le Service, sous quelque forme que ce soit, à toute personne sans l'accord écrit de l’Entreprise; (f) supprimer les avis, les étiquettes ou les marques de propriété du Service; (g) créer des « <Text style={{ fontWeight: 'bold' }}>liens</Text> » vers le Service, « <Text style={{ fontWeight: 'bold' }}>altérer</Text> » le Service ou « <Text style={{ fontWeight: 'bold' }}>faire un miroir</Text> » du Service ou de toute partie du Service; ou (h) utiliser le Service pour créer, recueillir, transmettre, stocker, utiliser ou traiter toute Donnée des clients : (i) que le Client n'a pas le droit légal de créer, de recueillir, de transmettre, de stocker, d’utiliser ou de traiter, ou (ii) qui viole les lois applicables ou enfreint, viole ou détourne de toute autre manière la propriété intellectuelle ou d'autres droits d'un tiers (y compris tout droit moral, droit à la vie privée ou droit de publicité). </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.4. <Text style={{ textDecorationLine: 'underline' }}>Données agrégées</Text>. Le Client reconnaît et accepte que le Service compile, stocke et utilise des données agrégées et des renseignements d'analyse, de diagnostic et sur l'utilisation du système pour contrôler et améliorer le Service et pour créer des nouveaux produits. Toutes les données recueillies, utilisées et divulguées par l’Entreprise le seront uniquement sous forme agrégée, anonyme et(ou) qui ne contient aucun identificateur et n'identifieront pas le Client, ses Utilisateurs, les Données des clients, les Renseignements personnels ou tout tiers qui utilise le Service. Les données agrégées qui ne sont plus utiles sont conservées pendant une période maximale de quatre-vingt-dix (90) jours après quoi elles seront détruites. Les données agrégées peuvent rester dans les fichiers de sauvegarde de l’Entreprise pendant jusqu’à trente-cinq (35) jours, la politique de l’Entreprise étant de conserver les sauvegardes pendant au moins trente-cinq (35) jours avant que ces sauvegardes ne soient détruites. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>3. Activation du compte</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 3.1. <Text style={{ textDecorationLine: 'underline' }}>Compte</Text>. Le Client est tenu d'ouvrir un compte auprès de l’Entreprise (un « <Text style={{ fontWeight : 'bold' }}>Compte</Text> ») pour pouvoir utiliser le Service. Lors de son inscription, un Utilisateur devra fournir certains Renseignements personnels pour créer un Compte au nom du Client. Le Client doit s'assurer que les renseignements relatifs à l'activation du Compte sont exacts et complets, et qu'ils restent à jour pendant toute la Durée. Le Client est entièrement responsable de toutes les activités qui se déroulent sur le Compte du Client, y compris de toutes les actions de ses Utilisateurs. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 3.2. <Text style={{ textDecorationLine: 'underline' }}>Mots de passe</Text>. Le Client est responsable de la sécurité de tous les mots de passe de son Compte. L’Entreprise ne sera pas responsable des pertes ou des dommages causés ou entraînés par un manquement du Client ou de ses Utilisateurs à veiller à la sécurité du Compte et du mot de passe du Client. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>4. Services de soutien</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 4.1. <Text style={{ textDecorationLine: 'underline' }}>Soutien de base</Text>. L’Entreprise fournit au Client le soutien de base suivant (« <Text style={{ fontWeight : 'bold' }}>Soutien de base</Text> ») : (a) une assistance téléphonique de 9 h à 17 h (heure de l'Est), du lundi au vendredi (à l'exception des jours fériés dans la province de l'Ontario) et tout appel reçu en dehors de ces heures est traité dans la mesure du possible et en fonction des disponibilités; (b) le soutien du courrier électronique est monitoré et fourni de 9 h à 17 h (heure de l'Est), du lundi au vendredi (à l'exception des jours fériés dans la province de l'Ontario); en dehors de ces heures, les courriels seront répondus dans la mesure du possible; (c) l’Entreprise déploiera des efforts commercialement raisonnables pour répondre aux demandes de soutien dans un délai d'un (1) jour ouvrable. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>5. Données et Renseignements personnels des clients</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.1. <Text style={{ textDecorationLine: 'underline' }}>Propriété</Text>. Entre l’Entreprise et le Client, le Client détient exclusivement tous les droits, titres et intérêts relatifs à toutes les Données des clients. L’Entreprise n'acquiert aucun droit, titre ou intérêt de propriété de quelque nature que ce soit, qu’il soit expresse ou implicite, sur les Données des clients. Par les présentes, le Client autorise l’Entreprise à utiliser les Données des clients dans la mesure où cela est nécessaire pour fournir le Service conformément aux modalités des présentes. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.2. <Text style={{ textDecorationLine: 'underline' }}>Mesures de sécurité techniques et organisationnelles</Text>. Dans le cadre de la fourniture du Service, l’Entreprise appliquera des mesures de sécurité administratives, physiques et techniques commercialement raisonnables pour la protection de la sécurité, de la confidentialité et de l'intégrité du Service et des Données des clients. Ces mesures de sécurité comprendront, sans s'y limiter, des mesures visant à empêcher l'accès, l'utilisation, la modification ou la divulgation des Données des clients par le personnel de l’Entreprise, sauf (a) pour fournir le Service et éviter ou régler des problèmes techniques ou de service, (b) dans la mesure où la loi l'exige et sur identification d'une autorité légitime, (c) dans la mesure où le Client l'a expressément autorisé par écrit, ou (d) dans la mesure où les lois en vigueur sur la protection des données l'autorisent. Dans le cadre de la fourniture du Service, l’Entreprise se conforme aux lois sur la protection des données, ainsi qu'à la Politique de confidentialité de l’Entreprise. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5,3.{' '} <Text style={{ textDecorationLine: 'underline' }}>Obligations du Client concernant les Renseignements personnels</Text>. Le Client est seul responsable de l'exactitude, de la qualité et de la légalité des Renseignements personnels, et des moyens par lesquels le Client a obtenu les Renseignements personnels. Par les présentes, le Client déclare et garantit à l’Entreprise que les Données des clients ne contiendront que des Renseignements personnels pour lesquels le Client a obtenu toutes les permissions et les autorisations de tiers applicables et dispose par ailleurs de toute l'autorité nécessaire, dans chaque cas conformément aux lois applicables, pour permettre à l’Entreprise de fournir le Service, en particulier concernant la collecte, le stockage, l'accès, l'utilisation, la divulgation et la transmission des Renseignements personnels. Toute correction des Renseignements personnels utilisés et stockés dans le Service doit être effectuée par le Client, à moins que le Client ne soit pas en mesure de le faire. Si le Client n’est pas en mesure d’effectuer une correction des Renseignements personnels, l’Entreprise doit effectuer une correction pour le compte du Client. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.4. <Text style={{ textDecorationLine: 'underline' }}>Conservation et suppression des Données des clients</Text>. L’Entreprise traitera les Données des clients uniquement pendant la durée nécessaire à la mise en œuvre, l’administration et la gestion des Renseignements personnels des clients et des utilisateurs dans le cadre du Service fourni. En cas d'expiration ou de résiliation de la présente Entente, l’Entreprise continuera à conserver les Renseignements personnels des clients et des utilisateurs conformément au présent Article 5, à certaines fins commerciales, y compris, mais sans s'y limiter, la défense d'une créance contractuelle, un audit, et conformément aux lois qui s’appliquent. Afin de gérer de façon appropriée le rendement du système, les Données des clients qui ne sont plus utiles comme indiqué ci-dessus sont conservées pendant une période maximale de quatre-vingt-dix (90) jours, après quoi elles seront détruites. Les Données des clients peuvent rester dans les fichiers de sauvegarde de l’Entreprise pendant jusqu’à trente-cinq (35) jours, puisque la politique de l’Entreprise consiste à conserver des sauvegardes pendant au moins trente-cinq (35) jours avant que ces sauvegardes ne soient détruites. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.5. <Text style={{ textDecorationLine: 'underline' }}>Exportation des Données des clients</Text>. À la demande du Client pendant la Durée ou dans les trente (30) jours suivant la date effective de résiliation de la présente Entente, l’Entreprise mettra les Données des clients à la disposition du Client pour l'exportation ou le téléchargement. Après cette période de 30 jours, l’Entreprise n'aura aucune obligation de fournir les Données des clients au Client. Les Données des clients seront conservées conformément à l’Article 5.4. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>6. Responsabilités du Client</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.1. <Text style={{ textDecorationLine: 'underline' }}>Utilisateurs</Text>. Le Client est responsable de toutes les activités qui se déroulent dans les comptes des Utilisateurs et de son respect de la présente Entente, ainsi que de celui de ses Utilisateurs. Le Client doit : (a) déployer des efforts commercialement raisonnables pour empêcher l'accès non autorisé au Service ou son utilisation, et aviser promptement l’Entreprise de tels accès ou utilisation non autorisés; et (b) utiliser le Service uniquement en conformité avec les lois et les règlements gouvernementaux applicables. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.2. <Text style={{ textDecorationLine: 'underline' }}>Protocole relatif aux commotions cérébrales</Text>. Il incombe au Client de fournir le Protocole relatif aux commotions cérébrales à l’Entreprise. Le Client est seul responsable de l'exactitude et l'exhaustivité du Protocole relatif aux commotions cérébrales et l’Entreprise ne sera pas responsable des pertes ou des dommages causés ou entraînés par la confiance du Client, de l'Utilisateur ou de tout tiers dans le Protocole relatif aux commotions cérébrales et l’utilisation qu’ils en font, ou des inexactitudes qu'il contient. Entre l’Entreprise et le Client, le Client détient exclusivement tous les droits, les titres et les intérêts à l’égard du Protocole relatif aux commotions cérébrales et l’Entreprise n'acquiert aucun droit, titre ou intérêt de propriété de quelque nature que ce soit, expresse ou implicite, à l’égard du Protocole relatif aux commotions cérébrales. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.3. <Text style={{ textDecorationLine: 'underline' }}>Équipement</Text>. Le Client est seul responsable de l'acquisition, l'entretien, la maintenance et la mise à jour de tous les équipements, les ordinateurs, les logiciels et les services de communications (tels que l'accès à Internet) qui sont nécessaires pour permettre au Client d'accéder au Service et de l'utiliser, ainsi que de toutes les dépenses qui y sont liées. Le Client s'engage à accéder au service et l'utiliser, et à veiller à ce que tous les Utilisateurs accèdent au Service et l’utilisent conformément à toutes les instructions ou les procédures d'exploitation qui peuvent être émises par l’Entreprise de temps à autre. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.4. <Text style={{ textDecorationLine: 'underline' }}>Rétroaction</Text>. Le Client peut fournir une rétroaction raisonnable à l’Entreprise, y compris, mais sans s'y limiter, la pertinence, les rapports de problèmes, les suggestions et les autres renseignements concernant le Service (« <Text style={ fontWeight : 'bold' }}>Rétroaction</Text> »). Par les présentes, le Client accorde à l’Entreprise une licence payée en totalité, libre de redevances, mondiale, cessible, transférable, pouvant faire l'objet d'une sous-licence, irrévocable et perpétuelle pour utiliser ou incorporer au Service et aux autres produits ou services de l’Entreprise, ou à toute autre fin, toute Rétroaction fournie par le Client ou ses Utilisateurs. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>7. Intégration, Frais et Paiement</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.1. <Text style={{ textDecorationLine: 'underline' }}>Initiation</Text>: L’Entreprise fournit des services d'initiation au Client avant de facturer les Frais pendant une période convenue par l’Entreprise et le Client (la « <Text style={ fontWeight : 'bold' }}>Période d'initiation</Text> »). Si, à l'issue de la Période d'initiation, le Client décide de ne pas continuer à utiliser le Service, le Client peut résilier le Bon de commande ou le Devis associé avec l’Entreprise et aucuns Frais ne seront facturés pour le Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.2. <Text style={{ textDecorationLine: 'underline' }}>Frais</Text>. Le Client doit payer tous les Frais mentionnés dans chaque Bon de commande ou Devis. Tous les Frais sont exprimés et payables en dollars canadiens. Sauf indication contraire dans les présentes ou dans un Bon de commande ou un Devis, les obligations de paiement ne peuvent pas être annulées et les Frais payés ne sont pas remboursables. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.3. <Text style={{ textDecorationLine: 'underline' }}>Facturation et Paiement</Text>. Les Frais seront facturés conformément au Bon de commande ou au Devis correspondant. Sauf indication contraire dans un Bon de commande ou un Devis, les frais sont dus immédiatement à la réception de la facture par le Client. Le Client est responsable de maintenir des renseignements complets et précis concernant la facturation et les contacts auprès de l’Entreprise. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.4. <Text style={{ textDecorationLine: 'underline' }}>Taxes</Text>. Les Frais ne comprennent pas les taxes, les prélèvements, les droits ou les évaluations gouvernementales similaires de quelque nature que ce soit, y compris, par exemple, la TVH, la TPS, la taxe de vente, la taxe sur la valeur ajoutée, la taxe d'utilisation ou la retenue d'impôt à la source, qui sont susceptibles d’évaluation par quelque juridiction que ce soit (collectivement, les « <Text style={ fontWeight : 'bold' }}>Taxes</Text> »). Le Client est responsable du paiement de toutes les Taxes associées à ses achats en vertu des présentes. Si l’Entreprise a l'obligation légale de payer ou de percevoir les Taxes dont le Client est responsable en vertu du présent article, l’Entreprise facturera le Client et le Client paiera ce montant à moins que le Client ne fournisse à l’Entreprise un certificat d'exonération d’impôt valide autorisé par l'autorité fiscale concernée. Par souci de clarté, l'Entreprise est seule responsable des taxes qui lui sont imputables en fonction de ses revenus, ses biens et ses employés. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.5. <Text style={{ textDecorationLine: 'underline' }}>Modification de prix</Text>. L’Entreprise se réserve le droit, à tout moment, de modifier et(ou) de rajuster ses Frais et avisera le Client par écrit d’une telle modification. [En cas de modification des Frais, le Client peut résilier la présente Entente conformément à l'Article 12.2]. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>8. Obligations de confidentialité</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Chaque partie reconnaît que tous les Renseignements confidentiels sont des renseignements confidentiels et exclusifs de la partie qui les divulgue. Chaque partie doit, et doit faire en sorte que ses employés, ses agents et ses contractants gardent confidentiels les Renseignements confidentiels de l'autre partie, et doit faire preuve, par instruction, entente ou autre, du même degré de diligence pour préserver la confidentialité des Renseignements confidentiels de l'autre partie que celui qu'elle utilise pour préserver la confidentialité de ses propres Renseignements confidentiels, mais avec au moins un degré de diligence raisonnable en rapport avec la nature et l'importance de ces Renseignements confidentiels. Chaque partie s'engage à ne pas utiliser les Renseignements confidentiels autrement que pour l'exercice des droits ou l'exécution des obligations découlant de la présente Entente, et à ne pas les divulguer, les communiquer ou les mettre à la disposition de tiers autres que les employés, les agents et les contractants de la partie qui ont raisonnablement besoin d'en prendre connaissance dans le cadre de l'exercice des droits ou l'exécution des obligations découlant de la présente Entente. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>9. Garanties limitées et Exclusions</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.1. <Text style={{ textDecorationLine: 'underline' }}>Garanties limitées</Text>. Par les présentes, l’Entreprise déclare et garantit au Client qu'elle est propriétaire du Service ou qu'elle dispose de droits suffisants sur le Service pour accorder au Client les droits d'accès et d'utilisation du Service accordés dans les présentes. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.2. <Text style={{ textDecorationLine: 'underline' }}>Recours</Text>. En cas de violation d'une ou de plusieurs des garanties énoncées à l’Article 9.1 des présentes, l’Entreprise déploiera des efforts commerciaux raisonnables pour remédier à cette violation de la garantie. Si l’Entreprise n'est pas en mesure de remédier à la violation de la garantie dans un délai raisonnable, l’Entreprise remboursera les Frais payés pour le Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.3. <Text style={{ textDecorationLine: 'underline' }}>Recours exclusifs</Text>. LES GARANTIES ÉNONCÉES À L'ARTICLE 9.1 DES PRÉSENTES SONT LES SEULES GARANTIES FOURNIES PAR L’ENTREPRISE ET LES RECOURS ÉNONCÉS À L'ARTICLE 9.2 DES PRÉSENTES SONT LES RECOURS UNIQUES ET EXCLUSIFS DU CLIENT EN CAS DE VIOLATION DE LA GARANTIE. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.4. <Text style={{ textDecorationLine: 'underline' }}>Exclusions de la Garantie générale</Text>. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> (A) À L'EXCEPTION DES GARANTIES EXPRESSES FOURNIES DANS LES PRÉSENTES, LE SERVICE EST FOURNI « <Text style={ fontWeight : 'bold' }}>EN L’ÉTAT</Text> » ET « <Text style={ fontWeight : 'bold' }}>SELON LA DISPONIBILITÉ</Text> » ET L’ENTREPRISE NE FAIT AUCUNE REPRÉSENTATION OU GARANTIE, ET IL N'Y A PAS DE CONDITIONS, D’ENDOSSEMENTS, D’ENGAGEMENTS, DE REPRÉSENTATIONS OU DE GARANTIES DE QUELQUE NATURE QUE CE SOIT, EXPRESSES OU IMPLICITES, (Y COMPRIS, SANS S'Y LIMITER, TOUTE GARANTIE OU CONDITION EXPRESSE OU IMPLICITE DE QUALITÉ, DE PERFORMANCE, DE RÉSULTATS, D'ADÉQUATION À UN USAGE SPÉCIFIQUE, DE QUALITÉ MARCHANDE OU DÉCOULANT D'UNE LOI OU D'UNE AUTRE DISPOSITION LÉGALE OU DE LA CONDUITE HABITUELLE DES AFFAIRES OU DE L’USAGE DU COMMERCE) EN CE QUI CONCERNE LES ÉLÉMENTS SUIVANTS, DÉCOULANT DE CEUX-CI OU S'Y RAPPORTANT : (I) LA PRÉSENTE ENTENTE; (II) LE SERVICE; ET(OU) (III) LA SÉCURITÉ ASSOCIÉE À LA TRANSMISSION DES RENSEIGNEMENTS OU DES DONNÉES DES CLIENTS FOURNIS À L’ENTREPRISE OU PAR L’ENTREPRISE PAR L’ENTREMISE DU SERVICE. L'ENTREPRISE NE DÉCLARE NI NE GARANTIT QUE LE SERVICE COMBLERA L’UNE OU L’ENSEMBLE DES EXIGENCES PARTICULIÈRES DU CLIENT, QUE LE SERVICE FONCTIONNERA SANS ERREUR OU INTERRUPTION, OU QUE TOUTES LES ERREURS DE PROGRAMMATION DANS LE SERVICE PEUVENT ÊTRE TROUVÉES AFIN D'ÊTRE CORRIGÉES. L'ENTREPRISE DÉCLINE TOUTES LES OBLIGATIONS EN MATIÈRE DE RESPONSABILITÉ ET D'INDEMNISATION POUR TOUT PRÉJUDICE OU DOMMAGE CAUSÉ PAR DES FOURNISSEURS D'HÉBERGEMENT TIERS. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> (B) <Text style={{ fontStyle: 'italic' }}>Aucune Confiance</Text>: LE SERVICE EST FOURNI À TITRE D'INFORMATION UNIQUEMENT ET LES RENSEIGNEMENTS FOURNIS PAR LE SERVICE NE SONT PAS DESTINÉS À REMPLACER LES CONSEILS, LE DIAGNOSTIC OU LE TRAITEMENT PAR UN PROFESSIONNEL DE LA SANTÉ. LE CLIENT RECONNAÎT QUE, BIEN QUE L'ENTREPRISE FASSE DES EFFORTS RAISONNABLES POUR METTRE À JOUR LES RENSEIGNEMENTS ET LES DONNÉES DES CLIENTS DANS LE SERVICE, L'ENTREPRISE NE FAIT AUCUNE DÉCLARATION OU GARANTIE, EXPRESSE OU IMPLICITE, QUANT À L'EXACTITUDE, L'EXHAUSTIVITÉ OU LA MISE À JOUR DES RENSEIGNEMENTS CONTENUS DANS LE SERVICE ET FOURNIS PAR CE DERNIER. L'UTILISATION DU SERVICE PAR LE CLIENT ET LA CONFIANCE QU’IL ACCORDE AUX RENSEIGNEMENTS ET AU PROTOCOLE RELATIF AUX COMMOTIONS CÉRÉBRALES SE FONT AUX RISQUES ET PÉRILS DU CLIENT ET L’ENTREPRISE N'ASSUME AUCUNE OBLIGATION OU RESPONSABILITÉ DE L'UTILISATION PAR LE CLIENT DU SERVICE ET DU PROTOCOLE RELATIF AUX COMMOTIONS CÉRÉBRALES TEL QU'IL EST FOURNI PAR LE CLIENT, NI DE LA CONFIANCE QU'IL LEUR ACCORDE. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.5. <Text style={{ textDecorationLine: 'underline' }}>Avis de non-responsabilité concernant la connectivité à Internet</Text>. L’Entreprise rend le Service accessible au moyen d’un accès par Internet. Le Client fournit, à ses propres frais, tous les biens matériels, applications et connectivité à Internet nécessaires pour accéder au Service par Internet. Par les présentes, le Client reconnaît que le Service peut être interrompu en raison (a) de temps d'arrêt pour entretien prévu, à la seule discrétion de l’Entreprise, ou (b) d'interruptions de la connectivité à Internet ou d'autres temps d'arrêt causés par des circonstances indépendantes de la volonté de l’Entreprise, y compris, sans s'y limiter, les catastrophes naturelles, les actes du gouvernement, les inondations, les incendies, les tremblements de terre, les agitations civiles, les actes de terrorisme, les grèves ou d’autres problèmes de main-d'œuvre, les pannes d'ordinateur ou de télécommunications, ou les retards impliquant du matériel ou des logiciels qui ne sont pas sous le contrôle de l’Entreprise ou les intrusions dans le réseau. Par les présentes, le Client reconnaît et convient que l’Entreprise ne sera en aucun cas responsable de ces interruptions de service et dégage l’Entreprise de toute réclamation à ce sujet. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>10. Indemnisation par le Client</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Le Client défendra, indemnisera et dégagera l’Entreprise de toute responsabilité en cas de perte, de dommage ou de frais (y compris les frais juridiques raisonnables) encouru dans le cadre de toute réclamation, demande, poursuite ou procédure intentée contre l’Entreprise par un tiers (a) alléguant que les Données des clients ou l'utilisation du Service par le Client en violation de la présente Entente, enfreint les droits de propriété intellectuelle d'un tiers ou lui a causé un préjudice quelconque; (b) sur la base d'une violation des lois sur la protection des données ou d'une violation de la présente Entente; (c) pour des maladies, des dommages corporels ou des décès; ou (d) causé par un acte de négligence ou une omission du Client ou de ses employés, ses contractants ou ses agents (chacun étant une « <Text style={{ fontWeight : 'bold' }}>Réclamation faisant l'objet d'un dédommagement du Client</Text> »); à condition que l’Entreprise (a) avise rapidement le Client par écrit de la Réclamation faisant l'objet d'un dédommagement du Client; (b) donne au Client le contrôle exclusif de la défense et du règlement de la Réclamation faisant l'objet d'un dédommagement du Client (à condition que le Client ne puisse pas régler ou défendre une Réclamation faisant l'objet d'un dédommagement du Client à moins qu'il ne libère inconditionnellement l’Entreprise de toute responsabilité); et (c) fournisse au Client, à ses frais, toute l’aide et tous les renseignements raisonnables. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>11. Limitation de responsabilité</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 11.1. <Text style={{ textDecorationLine: 'underline' }}>Exclusion des dommages indirects et consécutifs </Text>. SOUS RÉSERVE DE L'ARTICLE 11.3 DES PRÉSENTES, EN AUCUN CAS L'UNE OU L'AUTRE DES PARTIES NE SERA RESPONSABLE ENVERS L'AUTRE PARTIE DE DOMMAGES INDIRECTS, SPÉCIAUX, ACCESSOIRES, PUNITIFS OU CONSÉCUTIFS (Y COMPRIS, SANS S'Y LIMITER, LES DOMMAGES POUR PERTE COMMERCIALE, PERTE DE PROFITS, INTERRUPTION D'ACTIVITÉ, PERTE DE DONNÉES, PERTE D'ÉCONOMIES OU AUTRE PERTE PÉCUNIAIRE SIMILAIRE). </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 11.2. <Text style={{ textDecorationLine: 'underline' }}>Limitation de responsabilité</Text>. SOUS RÉSERVE DE L'ARTICLE 11.3 DES PRÉSENTES, LA RESPONSABILITÉ MAXIMALE, CUMULÉE ET GLOBALE DE L'UNE OU L'AUTRE DES PARTIES, DÉCOULANT DE LA PRÉSENTE ENTENTE OU DE SON OBJET, POUR L'ENSEMBLE DES RÉCLAMATIONS, DES COÛTS, DES PERTES ET DES DOMMAGES, NE PEUT EN AUCUN CAS DÉPASSER LES MONTANTS EFFECTIVEMENT PAYÉS PAR LE CLIENT EN VERTU DES PRÉSENTES. L'EXISTENCE DE PLUS D'UNE RÉCLAMATION N'ÉLARGIT PAS CETTE LIMITE CUMULATIVE. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 11.3. <Text style={{ textDecorationLine: 'underline' }}>Certains dommages non exclus ou limités</Text>. NONOBSTANT CE QUI PRÉCÈDE, AUCUNE LIMITATION DE LA RESPONSABILITÉ DE L'UNE OU L'AUTRE DES PARTIES PRÉVUE DANS LA PRÉSENTE ENTENTE NE S'APPLIQUE (I) AUX DOMMAGES RÉSULTANT DE LA VIOLATION PAR UNE PARTIE DE SES OBLIGATIONS DE CONFIDENTIALITÉ EN VERTU DES PRÉSENTES, (II) AUX DEMANDES D'INDEMNISATION SOUMISES À LA LIMITATION PRÉVUE À L'ARTICLE 11.2 DE LA PRÉSENTE ENTENTE, (III) AUX DOMMAGES RÉSULTANT DE LA VIOLATION DES DROITS DE PROPRIÉTÉ INTELLECTUELLE D'UNE PARTIE, (IV) À TOUTE RÉCLAMATION POUR DÉFAUT DE PAIEMENT, OU (V) À LA FRAUDE OU LA MAUVAISE CONDUITE VOLONTAIRE. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>12. Durée</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.1. <Text style={{ textDecorationLine: 'underline' }}>Durée</Text>. La présente Entente entre en vigueur à la date du Bon de commande ou du Devis initial et se poursuit jusqu'à ce qu'elle soit résiliée plus tôt conformément aux dispositions de la présente Entente ou de la loi applicable. La présente Entente reste en vigueur et régit tous les Bons de commande ou les Devis jusqu'à (i) la fin de la Durée prévue par le Bon de commande ou le Devis, (ii) la résiliation du Bon de commande ou du Devis par les parties, ou (iii) la pleine exécution des obligations respectives des parties dans le cadre du Bon de commande ou du Devis. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.2. <Text style={{ textDecorationLine: 'underline' }}>Résiliation anticipée</Text>. Le Client peut résilier un Bon de commande ou un Devis avant l'expiration de la Durée (telle qu'indiquée dans le Bon de commande ou le Devis) en fournissant à l’Entreprise un préavis écrit de cinq (5) jours l'informant de l’intention du Client de résilier le Bon de commande ou le Devis. Sur réception du préavis, l’Entreprise facturera au Client des frais de résiliation anticipée de dix pour cent (10 %) des Frais prépayés, à moins que le Client ne résilie conformément à l'Article 7.5, auquel cas aucuns frais de résiliation anticipée ne seront exigibles. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.3. <Text style={{ textDecorationLine: 'underline' }}>Résiliation</Text>. Une partie peut résilier la présente Entente pour un motif valable (i) moyennant un préavis écrit de trente (30) jours adressé à l'autre partie en cas de violation substantielle, si cette violation n'est pas corrigée à l'expiration de ce délai, ou (ii) si l'autre partie fait l'objet d'une demande de mise en faillite ou de toute autre procédure relative à l'insolvabilité, la mise sous séquestre, la liquidation ou la cession au profit de créanciers. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.4. <Text style={{ textDecorationLine: 'underline' }}>Remboursement ou Paiement en cas de résiliation</Text>. Si la présente Entente est résiliée par le Client conformément à l'Article 12.2, l’Entreprise remboursera au Client tous les Frais prépayés couvrant le reste de la Durée après la date effective de résiliation. Si la présente Entente est résiliée par le Client conformément à l'Article 12.3, l’Entreprise ne remboursera pas les Frais prépayés au Client. La résiliation ne libère en aucun cas le Client de son obligation de payer les Frais qu’il doit verser à l’Entreprise pour la période précédant la date effective de résiliation. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.5. <Text style={{ textDecorationLine: 'underline' }}>Suspension de l’accès au Service</Text>. Outre les droits de résiliation de l’Entreprise en vertu de la présente Entente, des circonstances extraordinaires peuvent obliger l’Entreprise à suspendre ou résilier (le cas échéant), tel que l’Entreprise l'a déterminé et à sa seule discrétion, l'accès et(ou) l'utilisation du Client, ou à modifier le Service de toute autre manière, afin de : (a) prévenir les dommages matériels ou la dégradation matérielle de l'intégrité du réseau Internet de l’Entreprise ou de son fournisseur; ou (b) se conformer aux lois, aux règlements, aux ordonnances judiciaires ou à d’autres consignes gouvernementales. L’Entreprise avisera le Client de ces mesures de suspension ou de résiliation aussi longtemps à l'avance qu'il est raisonnablement possible de le faire et, si un tel préavis n'est pas possible, dès que possible après la suspension ou la résiliation. En cas de suspension, l’Entreprise limitera cette suspension au minimum nécessaire et rétablira rapidement l'accès du Client au Service dès que l'événement à l'origine de la suspension aura été traité (y compris l'acceptation par le Client des risques associés à cette suspension) ou résolu. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>13. Affectation</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Le Client ne peut céder aucun de ses droits ou ses obligations en vertu des présentes, que ce soit par application de la loi, changement de contrôle ou autre, sans le consentement écrit préalable de l’Entreprise. Nonobstant ce qui précède, l’Entreprise peut céder la présente Entente dans son intégralité (y compris tous les Bons de commande ou les Devis), sans le consentement du Client, dans le cadre d'une fusion, d'une acquisition, d'une réorganisation de l'entreprise ou de la vente de la totalité ou la quasi-totalité de ses actifs auxquels la présente Entente se rapporte. Toute tentative par le Client de céder ses droits ou ses obligations en vertu de la présente Entente en violation du présent article sera nulle et sans effet. Sous réserve de ce qui précède, la présente Entente lie et avantage les parties, leurs successeurs respectifs et leurs ayants droits permis. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>14. Services professionnels – Tiers </Text>
      </View>
      <View style={{ marginVertical: 5}}>
        <Text>De temps à autre, l’Entreprise peut faire appel à un tiers pour fournir certains services professionnels requis dans le cadre de la présente Entente et(ou) à la demande du Client, à condition que le recours par l’Entreprise à un tel tiers ne libère pas l’Entreprise de son devoir ou sa responsabilité de remplir les obligations qui lui incombent dans le cadre de la présente Entente. Nonobstant ce qui précède, l’Entreprise n'est pas responsable envers le Client des actes ou des omissions d'un tiers engagé par l’Entreprise pour fournir des services professionnels au Client.</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>15. Avis</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Tout avis requis ou autorisé conformément à la présente Entente ne sera effectif que s’il est fait par écrit et est envoyé par le biais : (a) du Service; (b) de courrier recommandé ou certifié; ou (c) d’un service national reconnu de messagerie pour la livraison le lendemain, à la partie appropriée à l'adresse indiquée sur le Bon de commande ou le Devis, avec une copie, dans le cas de l’Entreprise, à l'adresse support@agileis.co. Chaque partie de la présente Entente consent expressément à ce que les documents légaux soient remis par courrier recommandé. L'une ou l'autre des parties peut modifier son adresse de réception des avis en avisant l'autre partie par le biais d'un avis fourni conformément au présent Article 14 (Avis). Les Avis sont considérés donnés s'ils sont délivrés par le biais du Service deux (2) jours ouvrables suivant la date d'envoi ou un (1) jour ouvrable suivant la remise à un service de messagerie. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>16. Général</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.1. <Text style={{ textDecorationLine: 'underline' }}>Renonciation</Text>. Le fait qu'une partie n'invoque pas une violation de l'une des conditions de la présente Entente ne constitue pas une renonciation à cette violation ou au droit de cette partie de faire valoir une violation ultérieure de cette condition. Aucun manquement ou retard de l'une ou l'autre des parties dans l'exercice d'un droit en vertu de la présente Entente ne constitue une renonciation à ce droit. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.2. <Text style={{ textDecorationLine: 'underline' }}>Dispositions inexécutoires</Text>. Si une disposition de la présente Entente est déclarée inexécutoire ou illégale, cette décision n'affectera pas la validité ou l'applicabilité de ces dispositions dans d'autres circonstances ou les autres dispositions de la présente Entente, et la présente Entente ne sera modifiée que dans la mesure nécessaire pour lui conférer un caractère exécutoire dans de telles circonstances. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.3. <Text style={{ textDecorationLine: 'underline' }}>Contractants indépendants</Text>. La relation entre l’Entreprise et le Client établie par la présente Entente est celle de contractants indépendants, et aucune disposition de la présente Entente ne sera interprétée comme (i) donnant à l'une des parties le pouvoir de diriger et de contrôler les activités quotidiennes de l'autre, (ii) faisant des parties des partenaires juridiques, des coentrepreneurs, des copropriétaires ou d'autres participants à une entreprise commune, ou (iii) permettant à l'une des parties de créer ou d'assumer une obligation au nom de l'autre partie, à quelque fin que ce soit. Toutes les obligations financières et autres liées aux activités des parties relèvent de leurs seules responsabilités respectives. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.4. <Text style={{ textDecorationLine: 'underline' }}>Loi applicable</Text>. La présente Entente est régie par les lois de la province de l'Ontario, abstraction faite des dispositions sur les conflits des lois. Les tribunaux situés dans la province de l'Ontario ont compétence exclusive pour trancher tout litige découlant de la présente Entente ou s'y rapportant, et chaque partie consent par les présentes à la juridiction exclusive de ces tribunaux. L'application de la Convention des Nations Unies sur les contrats de vente internationale de marchandises à la présente Entente est expressément exclue et ne s'applique pas à la présente Entente. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.5. <Text style={{ textDecorationLine: 'underline' }}>Entente intégrale</Text>. La présente Entente constitue l'Entente intégrale entre le Client et l’Entreprise en ce qui concerne l'objet de la présente Entente, et remplace toute autre entente ou discussion, orale ou écrite, et ne peut être modifiée que par un contrat de licence écrit avec l’Entreprise ou un distributeur de l’Entreprise. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.6. <Text style={{ textDecorationLine: 'underline' }}>Recours</Text>. Sauf mention expresse dans la présente Entente, les recours prévus dans la présente Entente s'ajoutent à tout autre recours d'une partie, en droit ou en équité, et ne l'excluent pas. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.7. <Text style={{ textDecorationLine: 'underline' }}>Modifications</Text>. SAUF SI LA LOI APPLICABLE L'INTERDIT OU S'IL EN EST CONVENU AUTREMENT DANS LE BON DE COMMANDE OU LE DEVIS, L’ENTREPRISE PEUT MODIFIER UNILATÉRALEMENT LES MODALITÉS PRÉSENTES, EN TOUT OU EN PARTIE (CHACUNE UNE « <Text style={{ fontWeight : 'bold' }}>MODIFICATION</Text> »), EN DONNANT AU CLIENT UN AVIS PRÉALABLE DE LADITE MODIFICATION. SAUF INDICATION CONTRAIRE DE LA PART DE L’ENTREPRISE, UNE TELLE MODIFICATION PREND EFFET À LA DATE À LAQUELLE L'AVIS DE MODIFICATION EST FOURNI AU CLIENT. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.8. <Text style={{ textDecorationLine: 'underline' }}>Langue de l’Entente</Text>. Les parties aux présentes confirment qu’elles ont exigé que la présente Entente et tous les documents connexes soient rédigés en anglais. Toute traduction française de la présente Entente est fournie à titre d'information uniquement, n’a aucune valeur juridique et ne crée aucun lien contractuel entre les parties. The parties hereto confirm that they have requested that this Agreement and all related documents be drafted in English. Any French translation hereof has been provided for information purposes only and does not have any legal value nor create any contractual relationship between the parties.</Text>
      </View>
    </AppText>`,
  },
};
