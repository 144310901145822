import { RfaProtocolResult } from '../../../../../types/globalTypes';
import { AssessContextState } from '../../../Assess.interface';

export const assessContextDefaultState: AssessContextState = {
  steps: [],
  redFlags: {
    values: [],
  },
  suggestedResult: RfaProtocolResult.RESUME,
  initialized: false,
  showFinalDialog: false,
};
