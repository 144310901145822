/* Password hints
=============================== */

import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { Icon } from '../Icon/Icon';
import { AppText } from '../AppText/AppText';
import { ColorKeys, useColors } from '../../shared/Ui';

export interface PasswordHintsRule {
  label: string;
  check: (t: string) => boolean;
}

interface PasswordHintsProps {
  rules?: PasswordHintsRule[];
  password: string;
  visible?: boolean;
  invalidColor?: ColorKeys;
  validColor?: ColorKeys;
  style?: ViewStyle;
}

export const PasswordHints: React.FC<PasswordHintsProps> = ({
  password,
  visible = false,
  invalidColor = 'gray',
  validColor = 'green',
  rules = [],
  ...props
}) => {
  const colors = useColors();
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 8,
    },
    rule: {
      width: '50%',
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 2,
    },
    validRule: {},
    icon: {
      width: 16,
      height: 16,
      marginRight: 8,
      color: colors[invalidColor],
      opacity: 0.5,
    },
    validIcon: {
      color: colors[validColor],
      opacity: 1,
    },
  });

  return (
    <>
      {visible ? (
        <View {...props} style={[styles.container, props.style]}>
          {rules.map(({ label, check }, index) => (
            <View key={index} style={[styles.rule, check(password) ? styles.validRule : '']}>
              <Icon
                name={check(password) ? 'check' : 'x'}
                size={16}
                style={[styles.icon, check(password) ? styles.validIcon : undefined]}
              />
              <AppText font="labelSmall" color={check(password) ? validColor : invalidColor}>
                {label}
              </AppText>
            </View>
          ))}
        </View>
      ) : null}
    </>
  );
};
