import * as React from 'react';
import { GroupDetailNavigation, GroupDetailNavigationTab } from './GroupDetailNavigation';
import { Chrome } from '../../../../shared/Chrome/Chrome';
import { UpdateGroupModal } from './UpdateGroupModal/UpdateGroupModal';
import { useState } from 'react';
import { EditableTitle } from './EditableTitle';
import { DeactivateGroupModal } from './DeactivateGroupModal';
import { ActivityIndicator } from '../../../../../uiComponents';

export interface GroupDetailChromeProps {
  content?: React.ReactNode;
  name: string;
  organizationId: string;
  groupId: string;
  activeTab: GroupDetailNavigationTab;
  onBack: () => void;
}

export const GroupDetailChrome: React.FC<GroupDetailChromeProps> = ({
  name,
  content,
  activeTab,
  organizationId,
  groupId,
  onBack,
}) => {
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);
  const [showDeactivateGroupModal, setShowDeactivateGroupModal] = useState(false);

  const handleShowUpdateGroupModal = () => setShowUpdateGroupModal(true);
  const handleDismissUpdateGroupModal = () => setShowUpdateGroupModal(false);

  const handleShowDeactivateGroupModal = () => setShowDeactivateGroupModal(true);
  const handleDismissDeactivateGroupModal = () => setShowDeactivateGroupModal(false);

  const title = name ? (
    <EditableTitle name={name} onEdit={handleShowUpdateGroupModal} onDelete={handleShowDeactivateGroupModal} />
  ) : (
    <ActivityIndicator />
  );

  return (
    <>
      <UpdateGroupModal
        visible={showUpdateGroupModal}
        organizationId={organizationId}
        name={name}
        groupId={groupId}
        onDismiss={handleDismissUpdateGroupModal}
      />
      <DeactivateGroupModal
        visible={showDeactivateGroupModal}
        organizationId={organizationId}
        name={name}
        groupId={groupId}
        onDismiss={handleDismissDeactivateGroupModal}
      />
      <Chrome
        mode="title-card"
        title={title}
        autoScroll={false}
        navBarProps={{
          onBack,
        }}
        subNavigation={<GroupDetailNavigation groupId={groupId} activeTab={activeTab} />}
        content={content}
      />
    </>
  );
};
