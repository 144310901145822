import React from 'react';
import { StageState as StageStateType } from '../../../../../../../../types/globalTypes';
import { AppText } from '../../../../../../../../uiComponents';
import { useTranslations } from '../../../../../../../../i18n/useTranslations';

export interface StageStateProps {
  state: StageStateType;
}

export const StageState: React.FC<StageStateProps> = ({ state }) => {
  const { translate } = useTranslations();

  switch (state) {
    case StageStateType.ACTIVE:
      return <AppText color="blue">{translate.subjectStatus.active}</AppText>;
    case StageStateType.AWAITING_REVIEW:
      return <AppText color="orange">{translate.recovery.index.awaitingReview}</AppText>;
    case StageStateType.INACTIVE:
      return <AppText>{translate.recovery.index.inactive}</AppText>;
    case StageStateType.COMPLETE:
      return <AppText color="green">{translate.label.completeAdjective}</AppText>;
    case StageStateType.PENDING:
      return <AppText color="blue">{translate.recovery.index.pending}</AppText>;
    case StageStateType.REJECTED:
      return <AppText color="red">{translate.recovery.index.rejected}</AppText>;
  }
};
