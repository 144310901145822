import { useFormikContext } from 'formik';
import { isString } from 'lodash/fp';
import React from 'react';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Input, InputLabel, Spacer } from '../../../../../../uiComponents';
import { DocumentInput, UpdateProtocolConfigFormValue } from '../../ManageProtocol.interface';
import { DocumentSelector } from '../../DocumentSelector';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            return: { medicalAssessment: translate },
          },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();

  const {
    handleChange,
    handleBlur,
    errors: _errors,
    touched: _touched,
    values: _values,
    setFieldValue,
  } = useFormikContext<UpdateProtocolConfigFormValue>();
  const itemKey = 'returnProtocol.medicalAssessment';
  const values = _values.returnProtocol.medicalAssessment;
  const itemErrors = _errors?.returnProtocol?.medicalAssessment;
  const itemTouched = _touched.returnProtocol?.medicalAssessment;
  const errors = !isString(itemErrors) ? itemErrors : undefined;
  const touched = !isString(itemTouched) ? itemTouched : undefined;

  const handleDocumentChange = (_templateDocument: DocumentInput) => {
    setFieldValue(`${itemKey}.templateDocument`, _templateDocument);
  };

  const documentError = errors?.templateDocument && isString(errors.templateDocument) ? errors.templateDocument : null;

  return (
    <Spacer
      items={{
        heading: (
          <AppText font="header3" gutter={true}>
            {rootTranslate.label.medicalAssessment}
          </AppText>
        ),
        name: (
          <Input
            label={rootTranslate.label.name}
            onChangeText={handleChange(`${itemKey}.name`)}
            onBlur={handleBlur(`${itemKey}.name`)}
            error={errors?.name}
            touched={touched?.name}
            value={values.name}
          />
        ),
        instruction: (
          <Input
            label={translate.instruction}
            type="text"
            multiline={true}
            numLines={5}
            numberOfLines={5}
            onChangeText={handleChange(`${itemKey}.instruction`)}
            onBlur={handleBlur(`${itemKey}.instruction`)}
            error={errors?.instruction}
            touched={touched?.instruction}
            value={values?.instruction}
          />
        ),
        templateDocument: (
          <Spacer
            items={{
              label: <InputLabel label={rootTranslate.admin.manageProtocol.templateDocument} />,
              selector: (
                <DocumentSelector
                  onChange={handleDocumentChange}
                  document={values.templateDocument}
                  error={documentError}
                />
              ),
            }}
          />
        ),
      }}
    />
  );
};
