import { OrganizationProtocolPreset, OrganizationSport, OrganizationType, Role } from '../../../../types/globalTypes';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash/fp';
import * as React from 'react';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useCreateOrganization } from '../../hooks/useCreateOrganization';
import { Fields } from './Fields';
import { CreateOrganizationFormData } from './CreateOrganization.interface';
import { isApiError } from '../../../../utils/isApiError';
import { useNotification } from '../../../../hooks/ui/useNotification';
import { useTranslations } from '../../../../i18n/useTranslations';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { useNavigation } from '@react-navigation/native';
import { UserLanguage } from '../../../../types/globalTypes';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';

export interface CreateOrganizationProps {}

export const CreateOrganization: React.FC<CreateOrganizationProps> = (props) => {
  const notify = useNotification();
  const { translate } = useTranslations();

  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();
  const [creationCompleted, setCreationCompleted] = React.useState<boolean>(false);

  const { currentUser } = useCurrentUser();
  const userEmail = currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.email : '';

  const [createOrganization] = useCreateOrganization(userEmail, {
    onCompleted: ({ createOrganization }) => {
      if (!isApiError(createOrganization)) {
        notify({ image: 'header/people', description: translate.superAdmin.createOrganization.sucessNotification });
      }
    },
  });

  const handleSubmit = (
    values: CreateOrganizationFormData,
    { setSubmitting, resetForm }: FormikHelpers<CreateOrganizationFormData>
  ) => {
    setSubmitting(true);
    createOrganization({
      variables: {
        input: {
          name: values.name,
          preset: values.preset,
          sport: values.sport,
          type: values.type,
          language: values.language,
          isPrimaryDesignatedPerson: values.isPrimaryDesignatedPerson,
          admin: values.admin.email
            ? {
                ...values.admin,
              }
            : undefined,
          associationIds: values.associationIds,
        },
      },
    }).then(({ data }) => {
      if (data && !isApiError(data.createOrganization)) {
        setCreationCompleted(true);
        setSubmitting(false);
        resetForm();
      } else {
        setSubmitting(false);
      }
    });
  };

  const handleValidate = (values: CreateOrganizationFormData) => {
    const errors: FormikErrors<CreateOrganizationFormData> = {};
    if (isEmpty(values.name)) {
      errors.name = translate.error.message.cantBeEmpty;
    }
    if (isEmpty(values.preset)) {
      errors.preset = translate.error.message.cantBeEmpty;
    }
    if (
      !values.admin ||
      isEmpty(values.admin.email) ||
      isEmpty(values.admin.firstName) ||
      isEmpty(values.admin.lastName)
    ) {
      errors.preset = translate.error.message.cantBeEmpty;
    }
    return errors;
  };

  const initialValues: CreateOrganizationFormData = {
    name: '',
    preset: OrganizationProtocolPreset.BLANK,
    type: OrganizationType.SPORTS_ORGANIZATION,
    sport: OrganizationSport.OTHER,
    isPrimaryDesignatedPerson: false,
    admin: {
      firstName: '',
      lastName: '',
      email: '',
    },
    language: UserLanguage.en,
    associationIds: [],
  };

  const onBack = () => navigation.navigate('LoggedIn_SuperAdmin', { screen: 'LoggedIn_SuperAdmin_Index' });

  return (
    <Formik<CreateOrganizationFormData> initialValues={initialValues} onSubmit={handleSubmit} validate={handleValidate}>
      <Chrome
        navBarProps={{ onBack }}
        mode="title-card"
        titleText={translate.superAdmin.createOrganization.title}
        content={<Fields creationCompleted={creationCompleted} setCreationCompleted={setCreationCompleted} />}
      />
    </Formik>
  );
};
