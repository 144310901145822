import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { BaseTile, TileProps, Tiles as AppTiles } from '../../../../uiComponents/Tiles';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AssessNavType } from '../../Assess.interface';
import { ReportChrome } from './ReportChrome';
import { AssessType } from '../../Assess.interface';

export const Tiles: React.FC = (props) => {
  const navigation = useNavigation<AssessNavType<'LoggedIn_Assess_Index'>>();

  const { translate } = useTranslations();

  const handleGoBack = () => {
    navigation.navigate('LoggedIn_Home');
  };

  const handleNext = (assessType: AssessType) => {
    navigation.navigate('LoggedIn_Assess', {
      screen: 'LoggedIn_Assess_FindAthlete',
      params: { assessType },
    });
  };

  const tiles: TileProps[] = [
    {
      key: 'recognize',
      tile: <BaseTile label={translate.protocol.tiles.recognize} onPress={() => handleNext('recognize')} />,
      width: 'half',
    },
    {
      key: 'remove',
      tile: <BaseTile label={translate.protocol.tiles.remove} onPress={() => handleNext('remove')} />,
      width: 'half',
    },
  ];

  return (
    <ReportChrome onBack={handleGoBack} titleText={translate.protocol.tiles.report} showHamburger={true}>
      <AppTiles tiles={tiles} />
    </ReportChrome>
  );
};
