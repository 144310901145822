import { gql } from '@apollo/client';
import { DocumentFragment } from '../../../../../fragments/DocumentFragment';

export const MedicalAssessmentFragment = gql`
  fragment MedicalAssessment on MedicalAssessment {
    id
    caseId
    organizationName
    name
    protocolName
    protocolUrl
    instruction
    iCanReview
    iCanSubmit
    order
    state
    rejectionReason
    templateDocument {
      ...Document
    }
    diagnosis
    document {
      ...Document
    }
    isFirstStage
    isLastStage
    isUpcomingStage
    isCompletedStage
    isCurrentStage
  }
  ${DocumentFragment}
`;
