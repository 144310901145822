import * as Linking from 'expo-linking';
import * as Device from 'expo-device';
import { AppConfigType, BuildAppConfigOptions, Environment, FirebaseWebConfig } from './app.interface';

import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { first } from 'lodash';
if (!Constants.expoConfig) {
  throw new Error('Manifest not set.');
}

const { hostUri } = Constants.platform || {};
const { extra, version: release } = Constants.expoConfig;

const {
  env,
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseAuthEmulatorHost,
  firebaseAppId,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseWebConfig,
  graphqlEndpoint,
  sentryDsn,
} = extra as any;

const getGraphqlEndpoint = (_graphqlEndpoint?: string, _hostUri?: string) => {
  // Note: The code below extracts your ip address base on the Expo Go manifest. If the code below does not work, add your local machine's IP to the ip variable to run the app locally on your device. make sure your local device is connected to the same wifi network as your machine.
  const ip = first(Constants.manifest2?.extra?.expoGo?.debuggerHost?.split(':19000'));

  if (_graphqlEndpoint) {
    return _graphqlEndpoint;
  } else if (_hostUri) {
    //GraphQL endpoint if there is a host uri set
    return `http://${_hostUri.split(':19000')[0]}:3333/graphql`;
  } else if (
    (Platform.OS === 'ios' || Platform.OS === 'android') &&
    Device.isDevice &&
    process.env.NODE_ENV === 'development'
  ) {
    //GraphQL endpoint to run the app locally on actual devices. The ip is the address of the local host machine.
    return `http://${ip ? ip : 'localhost'}:3333/graphql`;
  } else if (Platform.OS === 'android' && process.env.NODE_ENV === 'development') {
    //GraphQL endpoint to run the app on Android simulator using Android's loopback ip. Read more here: https://developer.android.com/studio/run/emulator-networking.html
    return `http://10.0.2.2:3333/graphql`;
  } else {
    //GraphQL endpoint for web and iOS simulator
    return `http://localhost:3333/graphql`;
  }
};

const getFirebaseConfig = (
  apiKey?: string,
  authDomain?: string,
  appId?: string,
  projectId?: string,
  storageBucket?: string,
  webConfig?: FirebaseWebConfig
) => {
  if (Platform.OS === 'web') {
    if (!webConfig) {
      throw new Error('Missing firebase web config');
    }

    return webConfig;
  } else {
    if (!apiKey) {
      throw new Error('Missing firebase apiKey');
    }
    if (!authDomain) {
      throw new Error('Missing firebase authDomain');
    }
    if (!appId) {
      throw new Error('Missing firebase appId');
    }
    if (!projectId) {
      throw new Error('Missing firebase projectId');
    }
    if (!storageBucket) {
      throw new Error('Missing firebase storageBucket');
    }

    return {
      apiKey,
      authDomain,
      appId,
      projectId,
      storageBucket,
    };
  }
};

const getSentryConfig = (dsn?: string, environment?: string, release?: string) => {
  if (!dsn) {
    return undefined;
  }

  // TODO: [COL-347] create manual instrumentation for react-native / apollo tracing

  return {
    dsn,
    environment,
    debug: false,
    release,
  };
};

const getEnv = (env?: string): Environment => {
  switch (env) {
    case 'production':
      return Environment.PRODUCTION;
    case 'stage':
      return Environment.STAGE;
    default:
      return Environment.DEVELOPMENT;
  }
};

const getLinkingPrefix = (linkingPrefix?: string) => linkingPrefix || Linking.createURL('/');

const buildAppConfig = ({
  env,
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseAuthEmulatorHost,
  firebaseAppId,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseWebConfig,
  graphqlEndpoint,
  hostUri,
  release,
  sentryDsn,
  linkingPrefix,
}: BuildAppConfigOptions): AppConfigType => ({
  env: getEnv(env),
  graphqlEndpoint: getGraphqlEndpoint(graphqlEndpoint, hostUri),
  firebase: getFirebaseConfig(
    firebaseApiKey,
    firebaseAuthDomain,
    firebaseAppId,
    firebaseProjectId,
    firebaseStorageBucket,
    firebaseWebConfig
  ),
  firebaseAuthEmulatorHost,
  sentry: getSentryConfig(sentryDsn, env, release),
  version: release,
  linkingPrefix: getLinkingPrefix(linkingPrefix),
});

export const appConfig = buildAppConfig({
  env,
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseAppId,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseWebConfig,
  firebaseAuthEmulatorHost,
  graphqlEndpoint,
  sentryDsn,
  hostUri,
  release,
});
