import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { GetEducationCompletion, GetEducationCompletionVariables } from './__generated__/GetEducationCompletion';

export const GET_EDUCATION_COMPLETION = gql`
  query GetEducationCompletion($organizationId: ID!) {
    educationCompletion(organizationId: $organizationId) {
      ... on EducationCompletion {
        numberOfUsersCompleted
        totalNumberOfUsers
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetEducationCompletion = (organizationId: string, options?) =>
  useQuery<GetEducationCompletion, GetEducationCompletionVariables>(GET_EDUCATION_COMPLETION, {
    ...options,
    variables: { organizationId },
  });
