import { gql } from '@apollo/client';
import { UseActionItemsQuery } from './__generated__/UseActionItems';
import { useQuery } from '../../../hooks/useQuery';

export const MY_ACTION_ITEMS_QUERY = gql`
  query UseActionItems {
    actionItems {
      ... on PendingMedicalAssessmentReviewActionItem {
        ...PendingMedicalAssessmentReviewActionItem
      }
      ... on PendingDocumentSubmissionReviewActionItem {
        ...PendingDocumentSubmissionReviewActionItem
      }
      ... on PendingInvitationActionItem {
        ...PendingInvitationActionItem
      }
      ... on ProtocolApprovalActionItem {
        ...ProtocolApprovalActionItem
      }
      ... on EducationItemResubmissionActionItem {
        ...EducationItemResubmissionActionItem
      }
      ... on IncompleteIncidentReportActionItem {
        ...IncompleteIncidentReportActionItem
      }
    }
  }
  fragment PendingInvitationActionItem on PendingInvitationActionItem {
    id
    identifier
    organization {
      id
      name
    }
  }
  fragment PendingMedicalAssessmentReviewActionItem on PendingMedicalAssessmentReviewActionItem {
    id
    subject {
      id
      fullName
    }
    organization {
      id
      name
    }
  }
  fragment PendingDocumentSubmissionReviewActionItem on PendingDocumentSubmissionReviewActionItem {
    id
    subject {
      id
      fullName
    }
    organization {
      id
      name
    }
  }
  fragment ProtocolApprovalActionItem on ProtocolApprovalActionItem {
    id
    organization {
      id
      name
    }
  }
  fragment EducationItemResubmissionActionItem on EducationItemResubmissionActionItem {
    id
    organizationName
    educationItemIds
  }
  fragment IncompleteIncidentReportActionItem on IncompleteIncidentReportActionItem {
    id
    name
    caseDate
  }
`;

export const useActionItemsQuery = (options?) => useQuery<UseActionItemsQuery>(MY_ACTION_ITEMS_QUERY, options);
