import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { Membership } from '../../../shared/UserList/UserList.interface';
import { useColors } from '../../../../shared/Ui/hooks';
import { AppText, Icon } from '../../../../uiComponents';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Role } from '../../../../types/globalTypes';

export interface RolePillsProps {
  memberships: Array<Membership> | null | undefined;
  onRemoveRole: (role: string) => void;
  onNudgeRole: (role: string) => void;
}

export const RolePills: React.FC<RolePillsProps> = ({ memberships, onRemoveRole, onNudgeRole, ...props }) => {
  const colors = useColors();
  const isMobile = useIsMobile();
  const { translate } = useTranslations();

  const pillColors = {
    [Role.ADMIN]: colors.purple,
    [Role.PRIMARY_DESIGNATED_PERSON]: colors.navy,
    [Role.SECONDARY_DESIGNATED_PERSON]: colors.blue,
    [Role.SUBJECT]: colors.orange,
    [Role.GUARDIAN]: colors.green,
  };

  const styles = StyleSheet.create({
    container: {
      minHeight: Platform.OS !== 'web' ? 40 : '100%',
      marginTop: isMobile ? 10 : 0,
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    pill: {
      marginRight: 10,
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 10,
      backgroundColor: colors.gray,
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: isMobile ? 10 : 0,
      marginTop: 5,
    },
    label: {},
    icon: {
      marginLeft: 15,
    },
  });

  const canRemoveRole = memberships && memberships.length > 1;

  const getRole = (role: string) => {
    const roles = {
      [Role.SUBJECT]: translate.users.roles.subject,
      [Role.GUARDIAN]: translate.users.roles.guardian,
      [Role.PRIMARY_DESIGNATED_PERSON]: translate.users.roles.primaryDesignatedPerson,
      [Role.SECONDARY_DESIGNATED_PERSON]: translate.users.roles.secondaryDesignatedPerson,
      [Role.ADMIN]: translate.users.roles.admin,
    };
    return roles[role] ? roles[role] : '';
  };

  return (
    <View style={styles.container} {...props}>
      {memberships?.map((membership) => (
        <View key={membership.role} style={[styles.pill, { backgroundColor: pillColors?.[membership.role] }]}>
          <AppText style={[styles.label, isMobile ? { maxWidth: 180 } : null]} font="labelDefault" color="white">
            {getRole(membership.role).toUpperCase()}
          </AppText>
          {membership.status !== 'ACCEPTED' ? (
            <TouchableOpacity style={styles.icon} onPress={() => onNudgeRole(membership.role)}>
              <Icon size={14} name="refresh-cw" color="white" />
            </TouchableOpacity>
          ) : null}
          {canRemoveRole && (
            <TouchableOpacity style={styles.icon} onPress={() => onRemoveRole(membership.role)}>
              <Icon size={14} name="x" color="white" />
            </TouchableOpacity>
          )}
        </View>
      ))}
    </View>
  );
};
