import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { AdminTile } from './AdminTile';
import { StyleSheet, View } from 'react-native';
import { useTranslations } from '../../i18n/useTranslations';
import { Select, TileProps, Tiles, AppText, ActivityIndicator } from '../../uiComponents';
import { useAdminContext } from './hooks/useAdminContext';
import { AdminNavType } from './Admin.interface';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { Role, FeatureFlag } from '../../types/globalTypes';
import { useIsFeatureFlagAvailable } from '../../hooks/useIsFeatureFlagAvailable';
import { useGetAdminOrganizations } from './hooks/useGetAdminOrganizations';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_Index'>>();
  const { translate } = useTranslations();
  const { organizationId } = useAdminContext();
  const { currentUser } = useCurrentUser();

  const isPDP =
    currentUser &&
    currentUser.__typename === 'CurrentUser' &&
    currentUser.roles.filter(
      (role) => role.organizationId === organizationId && role.role === Role.PRIMARY_DESIGNATED_PERSON
    ).length > 0;

  const styles = StyleSheet.create({
    selectOrganization: {
      paddingHorizontal: 8,
    },
    header: {
      marginBottom: 20,
    },
  });

  const { data: manageProtocolFeatureFlag } = useIsFeatureFlagAvailable({
    variables: { featureFlag: FeatureFlag.ENABLE_MANAGE_PROTOCOL },
  });
  const { data: csvImporterFeatureFlag } = useIsFeatureFlagAvailable({
    variables: { featureFlag: FeatureFlag.ENABLE_CSV_IMPORTER },
  });
  const { data: organizationsData } = useGetAdminOrganizations();

  useEffect(() => {
    if (!organizationId && organizationsData?.organizations?.length) {
      // select first organization id by default
      navigation.navigate('LoggedIn_Admin', {
        screen: 'LoggedIn_Admin_Index',
        organizationId: organizationsData.organizations[0].id,
      });
    }
  }, [organizationId, organizationsData]);

  // if the page renders before it loads, there is an error in our mobile apps due to repeated animations in Tiles
  if (
    !organizationsData?.organizations.length ||
    !organizationId ||
    currentUser?.__typename !== 'CurrentUser' ||
    !currentUser?.roles ||
    !csvImporterFeatureFlag ||
    !manageProtocolFeatureFlag
  ) {
    return <ActivityIndicator />;
  }

  const organizations = organizationsData.organizations;

  const handleOrganizationChange = (_organizationId: string) => {
    navigation.navigate('LoggedIn_Admin', {
      screen: 'LoggedIn_Admin_Index',
      organizationId: _organizationId,
    });
  };

  const handleManageSeasons = () =>
    navigation.navigate('LoggedIn_Admin', {
      screen: 'LoggedIn_Admin_ManageSeasons',
      organizationId,
      params: {
        screen: 'LoggedIn_Admin_ManageSeasons_Index',
      },
    });

  const handleManageProtocol = () =>
    navigation.navigate('LoggedIn_Admin', {
      screen: 'LoggedIn_Admin_ManageProtocol',
      organizationId,
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Index',
      },
    });

  const handleManageUsers = () =>
    navigation.navigate('LoggedIn_Admin', {
      screen: 'LoggedIn_Admin_ManageUsers',
      organizationId,
      params: {
        screen: 'LoggedIn_Admin_ManageUsers_Index',
      },
    });

  const handleManageOrganization = () => {
    navigation.navigate('LoggedIn_Admin', {
      screen: 'LoggedIn_Admin_ManageOrganization',
      organizationId,
      params: {
        screen: 'LoggedIn_Admin_ManageOrganization_Index',
      },
    });
  };

  const handleViewProtocol = () => {
    navigation.navigate('LoggedIn_Protocol', { organizationId });
  };

  const handleImportData = () => {
    navigation.navigate('LoggedIn_Admin', {
      screen: 'LoggedIn_Admin_ImportData',
      organizationId,
    });
  };

  const organizationItems = organizations.map(({ id, name }) => ({ key: id, value: id, label: name }));

  const selector =
    organizations.length > 1 ? (
      <View style={styles.selectOrganization}>
        <AppText font="header3">{translate.admin.label.selectOrganization}</AppText>
        <Select<string>
          value={organizationId}
          onChange={handleOrganizationChange}
          items={organizationItems}
          large={true}
          title={translate.admin.label.selectOrganization}
        />
      </View>
    ) : null;

  let tiles: TileProps[] = [
    {
      key: 'manage-users',
      tile: <AdminTile label={translate.admin.label.manageUsers} onPress={handleManageUsers} />,
      width: 'half',
    },
    {
      key: 'manage-groups',
      tile: (
        <AdminTile
          label={translate.admin.label.manageGroups}
          onPress={() =>
            navigation.navigate('LoggedIn_Admin', {
              screen: 'LoggedIn_Admin_ManageGroups',
              organizationId,
              params: {
                screen: 'LoggedIn_Admin_ManageGroups_Index',
              },
            })
          }
        />
      ),
      width: 'half',
    },
    {
      key: 'manage-seasons',
      tile: <AdminTile label={translate.admin.label.manageSeasons} onPress={handleManageSeasons} />,
      width: 'half',
    },
    {
      key: 'manage-organization',
      tile: <AdminTile label={translate.admin.label.manageOrganization} onPress={handleManageOrganization} />,
      width: 'half',
    },
  ];

  if (isPDP) {
    tiles = [
      ...tiles,
      {
        key: 'view-protocol',
        tile: <AdminTile label={translate.admin.label.viewProtocol} onPress={handleViewProtocol} />,
        width: 'half',
      },
    ];
  }

  if (
    manageProtocolFeatureFlag?.hasFeature ||
    (currentUser?.__typename === 'CurrentUser' && currentUser?.isSuperAdmin)
  ) {
    tiles = [
      ...tiles,
      {
        key: 'manage-protocol',
        tile: <AdminTile label={translate.admin.label.manageProtocol} onPress={handleManageProtocol} />,
        width: 'half',
      },
    ];
  }

  if (csvImporterFeatureFlag?.hasFeature || (currentUser?.__typename === 'CurrentUser' && currentUser?.isSuperAdmin)) {
    tiles = [
      ...tiles,
      {
        key: 'csv-importer',
        tile: <AdminTile label={translate.superAdmin.importData.importData} onPress={handleImportData} />,
        width: 'half',
      },
    ];
  }

  return (
    <>
      <View style={styles.header}>{selector}</View>
      <Tiles tiles={tiles} />
    </>
  );
};
