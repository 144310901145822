/* HTML-enabled text block
=============================== */

import React from 'react';
import { StyleProp, Text, TextStyle, StyleSheet } from 'react-native';
import { isNil } from 'lodash';
import { ColorKeys, useColors } from '../../shared/Ui';
import { fonts } from '../../shared/Ui/defaultStyleConfig';

const makeAppTextStyles = (variant) => {
  switch (variant) {
    case 'bodySmall':
      return fonts.bodySmall;
    case 'bodyDefault':
      return fonts.bodyMedium;
    case 'labelSmall':
      return fonts.labelSmall;
    case 'labelDefault':
      return fonts.labelDefault;
    case 'header1':
      return fonts.header1;
    case 'header2':
      return fonts.header2;
    case 'header3':
      return fonts.header3;
    case 'header4':
      return fonts.header4;
    case 'header5':
      return fonts.header5;
    case 'header6':
      return fonts.header6;
  }
};

export interface AppTextVariant {
  header1: TextStyle;
  header2: TextStyle;
  header3: TextStyle;
  header4: TextStyle;
  header5: TextStyle;
  header6: TextStyle;
  bodySmall: TextStyle;
  bodyDefault: TextStyle;
  labelSmall: TextStyle;
  labelDefault: TextStyle;
}

export type AppTextVariantKeys = keyof AppTextVariant;

export interface AppTextProps {
  font?: AppTextVariantKeys;
  color?: ColorKeys;
  gutter?: boolean;
  style?: StyleProp<TextStyle>;
  numberOfLines?: number;
  children: string | undefined | null;
}

export const AppText: React.FC<AppTextProps> = ({
  children,
  color,
  gutter = false,
  font = 'bodyDefault',
  style,
  ...props
}) => {
  const colors = useColors();
  const textDefaultStyle = makeAppTextStyles(font);

  const styleObject = {
    textStyle: {
      ...textDefaultStyle,
      marginBottom: gutter ? 20 : 0,
    },
  };
  if (color) styleObject.textStyle['color'] = colors[color];
  const styles = StyleSheet.create(styleObject);

  if (isNil(children) || children === '') {
    return null;
  }

  return (
    <Text style={StyleSheet.flatten([styles.textStyle, style])} {...props}>
      {children}
    </Text>
  );
};
