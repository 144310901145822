import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useColors } from '../../../shared/Ui/hooks';
import { AppText, ShadowView } from '../../../uiComponents';

export interface ActionListItemProps {
  text: string;
  onPress: () => void;
}

export const ActionListItem = ({ text, onPress }: ActionListItemProps) => {
  const colors = useColors();
  const getTextOffsets = () => {
    switch (Platform.OS) {
      case 'web':
        return {
          warningTextOffset: -2,
          badgeTextOffsset: 0,
        };
      case 'android':
        return {
          warningTextOffset: -8,
          badgeTextOffsset: -5,
        };
      case 'ios':
        return {
          warningTextOffset: -4,
          badgeTextOffsset: -6,
        };
    }
  };

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignContent: 'center',
      marginHorizontal: 15,
      padding: 15,
      borderRadius: 8,
    },
    warning: {
      borderRadius: 50,
      borderColor: colors.white,
      borderWidth: 2,
      backgroundColor: colors.red,
      width: 40,
      height: 40,
      marginRight: 30,
    },
    warningText: {
      lineHeight: 40,
      textAlign: 'center',
      position: 'relative',
      top: getTextOffsets()?.warningTextOffset,
    },
    actionItemText: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '90%',
    },
  });

  const warningIcon = (
    <View style={styles.warning}>
      <AppText font="header3" color="white" style={styles.warningText}>
        !
      </AppText>
    </View>
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <ShadowView style={styles.container}>
        {warningIcon}
        <View style={styles.actionItemText}>
          <AppText color="blue" font="labelDefault">
            {text}
          </AppText>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};
