import * as React from 'react';
import { createContext } from 'react';
import { AssessContextReducer } from './Assess.interface';

export const CurrentAssessmentContext = createContext<AssessContextReducer>({} as any);

export interface CurrentAssessmentProps {
  value: AssessContextReducer;
  children: React.ReactNode;
}

export const CurrentAssessment: React.FC<CurrentAssessmentProps> = (props) => {
  return <CurrentAssessmentContext.Provider {...props} />;
};
