import { TrainingNavItem } from '../Training.interface';
import { Content } from '../screens/Content';
import { Index } from '../screens/Index';
import { Report } from '../screens/Report';
import { Education } from '../screens/Education';

const trainingRoutes: TrainingNavItem[] = [
  {
    component: Index,
    label: 'Training',
    name: 'LoggedIn_Training_Index' as const,
    path: 'index',
  },
  {
    component: Report,
    label: 'Organization Training Report' as const,
    name: 'LoggedIn_Training_Report',
    path: 'report',
  },
  {
    component: Education,
    label: 'Organization Education Report' as const,
    name: 'LoggedIn_Education_Report',
    path: 'education',
  },
  {
    component: Content,
    label: 'Training Resources',
    name: 'LoggedIn_Training_Resources' as const,
    path: 'resources',
  },
];

export const useTrainingRoutes = () => trainingRoutes;
