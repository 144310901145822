import { gql } from '@apollo/client';
import { useQuery } from '../../../../hooks/useQuery';

const GET_GROUPS_FOR_PRIMARY_DESIGNATED_PERSON = gql`
  query GetGroupsForPrimaryDesignatedPerson($organizationId: String!) {
    organizationGroupsForPDP(organizationId: $organizationId) {
      ... on Group {
        name
        id
      }
    }
  }
`;

export const useGetGroupsForPrimaryDesignatedPerson = (options) =>
  useQuery(GET_GROUPS_FOR_PRIMARY_DESIGNATED_PERSON, options);
