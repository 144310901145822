import { gql } from '@apollo/client';
import { GetOrganizationUsers } from './__generated__/GetOrganizationUsers';
import { useQuery } from '../../../hooks/useQuery';

export const GET_ORGANIZATION_USERS = gql`
  query GetOrganizationUsers(
    $organizationId: String!
    $paginationArgs: PaginationArgs!
    $searchStr: String
    $role: Role
    $status: String
  ) {
    organizationUsers(
      organizationId: $organizationId
      paginationArgs: $paginationArgs
      searchStr: $searchStr
      role: $role
      status: $status
    ) {
      ... on OrganizationUser {
        id
        fullName
        firstName
        lastName
        groups {
          groupId
        }
        email
        birthday
        memberships {
          role
          status
          id
        }
      }
    }
  }
`;

export const useGetOrganizationUsers = (options?) => useQuery<GetOrganizationUsers>(GET_ORGANIZATION_USERS, options);
