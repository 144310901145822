import React from 'react';
import { View, StyleSheet } from 'react-native';
import { UserList } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { RemoveButton } from '../RemoveButton';
import { Group } from '../../fragments/__generated__/Group';
import { useRemoveUserFromGroup } from '../../hooks/useRemoveUserFromGroup';
import { Role } from '../../../../../../types/globalTypes';
import { Name, Email } from '../../../ManageUsers/Columns';
import { GetGroupById_groupDesignatedPersons } from '../../hooks/__generated__/GetGroupById';

export interface ContentProps {
  group: Group;
  groupDesignatedPersons: GetGroupById_groupDesignatedPersons[] | null;
  organizationId: string;
  refetchGroup: () => void;
  searchStr: string;
  currentPage: number;
}

export const Content: React.FC<ContentProps> = ({
  group,
  organizationId,
  groupDesignatedPersons,
  refetchGroup,
  searchStr,
  currentPage,
}) => {
  const { translate } = useTranslations();

  const styles = StyleSheet.create({
    root: {
      flexGrow: 1,
      flexBasis: '100%',
      flexShrink: 0,
    },
    userList: {
      flex: 1,
    },
  });

  const [removeDesignatedPerson] = useRemoveUserFromGroup({
    onCompleted: () => {
      refetchGroup();
    },
  });

  const handleRemove = (user: GetGroupById_groupDesignatedPersons) => {
    removeDesignatedPerson({
      variables: {
        groupId: group.id,
        userId: user.id,
        role: Role.SECONDARY_DESIGNATED_PERSON,
        organizationId,
      },
    });
  };

  const getEmptyMessage = () => {
    if (searchStr === '') {
      return translate.admin.manageSeasons.groupDetails.noDesignatedPersons;
    } else if (currentPage !== 0) {
      return translate.admin.manageSeasons.groupDetails.endOfDesigantedPersonsList;
    } else {
      return translate.admin.manageSeasons.groupDetails.noDesignatedPersonsFound;
    }
  };

  return (
    <View style={styles.root}>
      {groupDesignatedPersons ? (
        <UserList
          noUsersMessage={getEmptyMessage()}
          mobileRearrangement={[0, 2, 1]}
          rightAlignLastItem={true}
          displayItems={groupDesignatedPersons.map((designatedPerson) => ({
            key: designatedPerson.id,
            components: [
              <Name name={designatedPerson.fullName} />,
              <Email email={designatedPerson.email} />,
              <RemoveButton onPress={() => handleRemove(designatedPerson)} />,
            ],
          }))}
        />
      ) : null}
    </View>
  );
};
