import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { isIncidentReportIncomplete } from './__generated__/isIncidentReportIncomplete';

export const IS_INCIDENT_REPORT_INCOMPLETE = gql`
  query isIncidentReportIncomplete($caseId: String!) {
    isIncidentReportIncomplete(caseId: $caseId)
  }
`;

export const useIsIncidentReportIncomplete = (caseId: string, options?) =>
  useQuery<isIncidentReportIncomplete>(IS_INCIDENT_REPORT_INCOMPLETE, { variables: { caseId }, ...options });
