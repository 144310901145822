import React from 'react';
import { StyleSheet, View, ViewStyle, StyleProp } from 'react-native';
import { Notch, NotchPosition } from './Notch';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { ColorKeys, useColors } from '../../shared/Ui';

interface NotchViewProps {
  primaryColor?: ColorKeys;
  secondaryColor?: ColorKeys;
  position?: NotchPosition;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}

export const NotchView: React.FC<NotchViewProps> = ({
  primaryColor = 'navyDark',
  secondaryColor = 'gray',
  children,
  position = 'top-right',
  style,
}) => {
  const colors = useColors();
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      backgroundColor: colors[primaryColor],
    },
    notch: {
      position: 'absolute',
      height: 90,
      width: 90,
    },
    bottomLeft: {
      bottom: 0,
      left: 0,
      transform: [{ rotate: '90deg' }],
    },
    topRight: {
      top: 0,
      right: 0,
    },
  });

  return (
    <View style={StyleSheet.flatten([styles.container, style])}>
      {children}
      {!isMobile ? <Notch position={position} color={secondaryColor} /> : null}
    </View>
  );
};
