/* Single checkbox
=============================== */

import React from 'react';
import { Checkbox, CheckboxProps, CheckboxOptionType } from '../Checkbox';

interface AgreeProps extends Omit<CheckboxProps<boolean>, 'onSelect' | 'value'> {
  label: string;
  onSelect?: (value: boolean) => void;
  value?: boolean;
}

export const Agree: React.FC<AgreeProps> = ({ label, onSelect, value, ...props }) => {
  const options: CheckboxOptionType<boolean>[] = [
    {
      label,
      value: true,
    },
  ];

  const handleSelect = (values: boolean[]) => {
    if (onSelect) {
      onSelect(values.includes(true));
    }
  };

  return (
    <Checkbox
      options={options}
      onSelect={handleSelect}
      value={value ? [options[0].value] : []}
      isMarkdown={true}
      {...props}
    />
  );
};
