import { ManageGroupsNavItem } from '../ManageGroups.interface';
import { GroupDetailsSubjects } from '../GroupDetails/Subjects/GroupDetailsSubjects';
import { GroupDetailsDesignatedPersons } from '../GroupDetails/DesignatedPersons/GroupDetailsDesignatedPersons';
import { Groups } from '../Groups';

const manageGroupsRoutes: ManageGroupsNavItem[] = [
  {
    name: 'LoggedIn_Admin_ManageGroups_GroupDetails_Subjects' as const,
    path: ':groupId/subjects',
    component: GroupDetailsSubjects,
  },
  {
    name: 'LoggedIn_Admin_ManageGroups_GroupDetails_DesignatedPersons' as const,
    path: ':groupId/designated-persons',
    component: GroupDetailsDesignatedPersons,
  },
  {
    name: 'LoggedIn_Admin_ManageGroups_Index' as const,
    path: '',
    component: Groups,
  },
];

export const useManageGroupsRoutes = () => {
  return manageGroupsRoutes;
};
