import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { AppSettingsQueryQuery } from './__generated__/AppSettingsQuery';
import { AppSettingsContext } from './AppSettingsContext';
import { APP_SETTINGS_QUERY } from './AppSettingsQuery';
import { AppSettings as AppSettingsType } from './AppSettings.interface';

const getAppSettings = (data?: AppSettingsQueryQuery): AppSettingsType | undefined => {
  if (data?.appSettings) {
    const { appSettings } = data;
    if (appSettings.__typename === 'AppSettings') {
      const { __typename, ...rest } = appSettings;
      return rest;
    }
  }
  return undefined;
};

export const AppSettings: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, refetch } = useQuery<AppSettingsQueryQuery>(APP_SETTINGS_QUERY);

  const appSettings = getAppSettings(data);

  return <AppSettingsContext.Provider value={{ appSettings, refetch }}>{children}</AppSettingsContext.Provider>;
};
