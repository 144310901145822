import { gql, useQuery } from '@apollo/client';
import { SeasonFragment } from '../fragments/SeasonFragment';
import {
  GetSeasonsByOrganizationId,
  GetSeasonsByOrganizationIdVariables,
} from './__generated__/GetSeasonsByOrganizationId';

export const GET_SEASONS_BY_ORGANIZATION_ID_QUERY = gql`
  query GetSeasonsByOrganizationId($organizationId: ID!) {
    seasons(organizationId: $organizationId) {
      ...Season
    }
  }
  ${SeasonFragment}
`;

export const useGetSeasonsByOrganizationId = (organizationId?: string) => {
  const { data, refetch, loading } = useQuery<GetSeasonsByOrganizationId, GetSeasonsByOrganizationIdVariables>(
    GET_SEASONS_BY_ORGANIZATION_ID_QUERY,
    {
      variables: {
        organizationId: organizationId as string,
      },
      skip: !organizationId,
    }
  );

  return { seasons: data?.seasons ? data.seasons : [], refetch, loading };
};
