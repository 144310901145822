import { gql } from '@apollo/client';
import { DocumentFragment } from '../../../../../fragments/DocumentFragment';

export const ProtocolFragment = gql`
  fragment Protocol on Protocol {
    id
    approved
    protocolName
    protocolUrl
    createdDate
    educationProtocol {
      id
      items {
        ... on EducationProtocolItemDocument {
          id
          name
          instruction
          instructionMarkdown
          order
          useForSubject
          useForGuardian
          useForDesignatedPerson
          document {
            ...Document
          }
        }
        ... on EducationProtocolItemUrl {
          id
          name
          instruction
          instructionMarkdown
          order
          useForSubject
          useForGuardian
          useForDesignatedPerson
          url
        }
      }
    }
    removeProtocol {
      id
      redFlag {
        id
        name
        instruction
        flags {
          id
          key
          value
          order
        }
      }
      steps {
        ... on StepChallenge {
          id
          name
          instruction
          order
          challenges {
            id
            key
            value
            order
          }
        }
        ... on StepInformation {
          id
          name
          instruction
          order
        }
        ... on StepSymptom {
          id
          name
          instruction
          order
          symptoms {
            id
            key
            value
            order
          }
        }
      }
      removeInstruction
      resumeInstruction
      emergencyInstruction
      discretionaryInstruction
      incidentReport {
        instruction
        templateDocument {
          ...Document
        }
      }
    }
    returnProtocol {
      id
      medicalAssessment {
        id
        name
        instruction
        templateDocument {
          ...Document
        }
      }
      childProtocols {
        id
        type
        stages {
          ... on SignOffStage {
            ...SignOffStage
          }
          ... on DocumentSubmissionStage {
            ...DocumentSubmissionStage
          }
        }
      }
    }
  }
  fragment SignOffStage on SignOffStage {
    id
    order
    name
    description
    authorizedRole
    timeGateHours
  }
  fragment DocumentSubmissionStage on DocumentSubmissionStage {
    id
    order
    name
    description
    authorizedRole
    timeGateHours
    templateDocument {
      ...Document
    }
  }
  ${DocumentFragment}
`;
