import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../hooks/useMutation';
import { ResubmitEducationItems, ResubmitEducationItemsVariables } from './__generated__/ResubmitEducationItems';
import { UseActionItemsQuery } from './__generated__/UseActionItems';
import { MY_ACTION_ITEMS_QUERY } from './useActionItems';

export const RESUBMIT_EDUCATION_ITEMS = gql`
  mutation ResubmitEducationItems($organizationId: ID!, $educationItemsAccepted: [String!]!) {
    resubmitEducationItems(organizationId: $organizationId, educationItemsAccepted: $educationItemsAccepted) {
      ... on UpdatedEducationItemCompletion {
        id
        completedDate
        organizationId
        completed
      }
    }
  }
`;

export const useResubmitEducationItems = (
  actionItemId: string,
  options?: MutationHookOptions<ResubmitEducationItems, ResubmitEducationItemsVariables>
) =>
  useMutation<ResubmitEducationItems, ResubmitEducationItemsVariables>(RESUBMIT_EDUCATION_ITEMS, {
    ...options,
    update(cache, { data }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });

      if (cachedActionItems && data?.resubmitEducationItems) {
        cache.writeQuery<UseActionItemsQuery>({
          query: MY_ACTION_ITEMS_QUERY,
          data: {
            __typename: 'Query',
            actionItems: cachedActionItems.actionItems.filter((actionItem) => actionItem.id !== actionItemId),
          },
        });
      }
    },
  });
