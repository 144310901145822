import { gql } from '@apollo/client';
import { DocumentFragment } from './DocumentFragment';

export const CaseFragment = gql`
  fragment Case on Case {
    id
    createdDate
    status
    organization {
      id
      name
    }
    items {
      ...CaseItem
    }
    protocolId
    protocolCreationDate
    protocolName
  }
  fragment CaseItem on CaseItem {
    ... on BaseCaseItem {
      ...BaseCaseItem
    }
    ... on CaseItemCaseStatusChange {
      ...CaseItemCaseStatusChange
    }
    ... on CaseItemMedicalAssessmentReviewApproved {
      ...CaseItemMedicalAssessmentReviewApproved
    }
    ... on CaseItemMedicalAssessmentReviewRejected {
      ...CaseItemMedicalAssessmentReviewRejected
    }
    ... on CaseItemMedicalAssessmentSubmission {
      ...CaseItemMedicalAssessmentSubmission
    }
    ... on CaseItemRfaProtocolResult {
      ...CaseItemRfaProtocolResult
    }
    ... on CaseItemRtaProtocolCompletion {
      ...CaseItemRtaProtocolCompletion
    }
    ... on CaseItemRtaProtocolStageChange {
      ...CaseItemRtaProtocolStageChange
    }
    ... on CaseItemRtaProtocolStageDocumentReviewApproved {
      ...CaseItemRtaProtocolStageDocumentReviewApproved
    }
    ... on CaseItemRtaProtocolStageDocumentReviewRejected {
      ...CaseItemRtaProtocolStageDocumentReviewRejected
    }
    ... on CaseItemRtaProtocolStageDocumentSubmission {
      ...CaseItemRtaProtocolStageDocumentSubmission
    }
    ... on CaseItemSubjectStatusChange {
      ...CaseItemSubjectStatusChange
    }
  }
  fragment CaseItemCaseStatusChange on CaseItemCaseStatusChange {
    ...BaseCaseItem
    newCaseStatus: newStatus
    caseId
  }
  fragment CaseItemMedicalAssessmentReviewApproved on CaseItemMedicalAssessmentReviewApproved {
    ...BaseCaseItem
    document {
      ...Document
    }
  }
  fragment CaseItemMedicalAssessmentReviewRejected on CaseItemMedicalAssessmentReviewRejected {
    ...BaseCaseItem
    rejectionReason
    document {
      ...Document
    }
  }
  fragment CaseItemMedicalAssessmentSubmission on CaseItemMedicalAssessmentSubmission {
    ...BaseCaseItem
    diagnosis
    document {
      ...Document
    }
  }
  fragment CaseItemRfaProtocolResult on CaseItemRfaProtocolResult {
    ...BaseCaseItem
    result
    suggestedResult
    incidentDescription
    hasMedicalConditions
    hasMedicalConditionsDetails
    hasMedication
    hasMedicationDetails
    hasOtherInjuries
    hasOtherInjuriesDetails
    hasOtherSymptoms
    hasOtherSymptomsDetails
    hasPreviousConcussion
    hasPreviousConcussionDetails
    location
    type
    typeDetails
    incidentDate
    isSkippedAssessment
    groupName
    attachedDocument: document {
      ...Document
    }
    redFlag {
      id
      name
      instruction
      flags {
        id
        key
        value
        order
        result
      }
    }
    steps {
      ... on StepChallengeReport {
        id
        name
        instruction
        order
        challenges {
          id
          key
          value
          order
          result
        }
      }
      ... on StepSymptomReport {
        id
        name
        instruction
        order
        symptoms {
          id
          key
          value
          order
          result
        }
      }
    }
  }
  fragment CaseItemRtaProtocolCompletion on CaseItemRtaProtocolCompletion {
    ...BaseCaseItem
    protocolType
    isFastTracked
    timeGateHours
  }
  fragment CaseItemRtaProtocolStageChange on CaseItemRtaProtocolStageChange {
    ...BaseCaseItem
    direction
    protocolType
    newStageName
    isFastTracked
    timeGateHours
  }
  fragment CaseItemRtaProtocolStageDocumentReviewApproved on CaseItemRtaProtocolStageDocumentReviewApproved {
    ...BaseCaseItem
    document {
      ...Document
    }
    protocolType
    stageName
  }
  fragment CaseItemRtaProtocolStageDocumentReviewRejected on CaseItemRtaProtocolStageDocumentReviewRejected {
    ...BaseCaseItem
    rejectionReason
    document {
      ...Document
    }
    protocolType
    stageName
  }
  fragment CaseItemRtaProtocolStageDocumentSubmission on CaseItemRtaProtocolStageDocumentSubmission {
    ...BaseCaseItem
    document {
      ...Document
    }
    protocolType
    stageName
    isFastTracked
    timeGateHours
  }
  fragment CaseItemSubjectStatusChange on CaseItemSubjectStatusChange {
    ...BaseCaseItem
    newSubjectStatus: newStatus
  }
  fragment BaseCaseItem on BaseCaseItem {
    id
    createdDate
    actor {
      id
      firstName
      lastName
      fullName
      email
      phoneNumber
    }
  }
  ${DocumentFragment}
`;
