import React, { useState, useEffect } from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { StyleSheet, View } from 'react-native';
import { useColors } from './Ui/hooks';
import { AppText, Spinner } from '../uiComponents';
import { Icon } from '../uiComponents';

export const IsConnected: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const { isConnected: _isConnected } = useNetInfo();

  const colors = useColors();

  useEffect(() => {
    setInitialized(true);
    setIsConnected(true);
  }, [_isConnected]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.navyDark,
    },
    icon: {
      color: colors.orange,
    },
    title: {
      marginTop: 10,
    },
  });

  if (!initialized) {
    return <Spinner />;
  } else {
    if (!isConnected) {
      return (
        <View style={styles.container}>
          <Icon style={styles.icon} name="zap-off" size={36} />
          <AppText font="header5" color="white" style={styles.title}>
            Can't connect
          </AppText>
          <AppText color="white">An internet connection is required</AppText>
        </View>
      );
    } else {
      return <>{children}</>;
    }
  }
};
