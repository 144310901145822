import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppLink, AppText } from '../../../../../../uiComponents';
import { useRecoveryPlan } from '../../hooks/useRecoveryPlan';
import { RecoveryPlanNavType } from '../../RecoveryPlan.interface';

export interface NoActiveCasesMessageProps {}

export const NoActiveCasesMessage: React.FC<NoActiveCasesMessageProps> = (props) => {
  const data = useRecoveryPlan();
  const { translate, translateWithVars } = useTranslations();
  const navigator = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Index'>>();
  if (!data) {
    return null;
  }

  const { fullName } = data;

  const handleReturnToProfile = () => navigator.navigate('LoggedIn_Profile_Overview');

  return (
    <>
      <AppText font="header3" gutter={true}>
        {translate.teamRoster.noActiveCasesTitle}
      </AppText>
      <AppText gutter={true}>{translateWithVars(translate.recovery.index.noActiveCasesMessage, { fullName })}</AppText>
      <AppLink onPress={handleReturnToProfile}>{translate.recovery.index.resumeMessage.returnLink}</AppLink>
    </>
  );
};
