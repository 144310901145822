import { useFirebase } from '../Firebase';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from '@firebase/auth';
import fb from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
export interface UseChangePasswordProps {
  onPasswordChange?: () => void;
  onPasswordChangeError?: (err: any) => void;
}

const defaultProps: UseChangePasswordProps = {};

export const useChangePassword = (props = defaultProps) => {
  const { onPasswordChange, onPasswordChangeError } = props;

  const { firebase } = useFirebase();

  return (oldPassword: string, newPassword: string) => {
    const { currentUser } = getAuth(firebase);

    if (!currentUser) {
      throw new Error('Not logged in');
    }

    const { email } = currentUser;

    if (!email) {
      throw new Error('No email available for current user');
    }

    const credential = EmailAuthProvider.credential(email, oldPassword);

    reauthenticateWithCredential(currentUser, credential)
      .then(() => updatePassword(currentUser, newPassword))
      .then(onPasswordChange, onPasswordChangeError);
  };
};
