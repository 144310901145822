import React, { useEffect } from 'react';
import { useRoute } from '@react-navigation/native';
import { LoggedOutChrome } from '../LoggedOutChrome';
import { useTranslations } from '../../../i18n/useTranslations';
import { RootRouteProp } from '../../../RootNavigator.interface';
import { useInvitationQuery } from '../../Invitation/hooks/useInvitationQuery';
import { RegisterForm } from './RegisterForm';
import { Spacer } from '../../../uiComponents';
import { InvitationNotice } from './InvitationNotice';
import { useInitialNavigation } from '../../../shared/InitialNavigationRoutes';
import { CountryIsoCode, Role } from '../../../types/globalTypes';
import { UserLanguage } from '../../../types/globalTypes';

export interface RegisterScreenProps {}

export const RegisterScreen: React.FC<RegisterScreenProps> = (props) => {
  const route = useRoute<RootRouteProp<'Register'>>();
  const { setInitialLoginRoute } = useInitialNavigation();

  const invitationIdentifier = route.params?.invitationIdentifier || '';
  const organizationLanguage = route.params?.language;
  const { translate } = useTranslations(organizationLanguage ? UserLanguage[organizationLanguage] : null);

  const { data } = useInvitationQuery({
    variables: {
      input: {
        identifier: invitationIdentifier as any as string,
      },
    },
    skip: !invitationIdentifier,
  });

  const isAskForPhoneNumber: boolean =
    data?.invitation.__typename === 'FoundInvitation'
      ? data?.invitation.foundInvitation.roles.some(
          (el) =>
            el === Role.PRIMARY_DESIGNATED_PERSON ||
            el === Role.GUARDIAN ||
            el === Role.SECONDARY_DESIGNATED_PERSON ||
            el === Role.ADMIN
        )
      : false;

  const isAskForBirthday: boolean =
    data?.invitation.__typename === 'FoundInvitation'
      ? data?.invitation.foundInvitation.roles.includes(Role.SUBJECT)
      : false;

  useEffect(() => {
    if (invitationIdentifier) {
      setInitialLoginRoute({ name: 'LoggedIn_ReviewInvitation', initialParams: { invitationIdentifier } });
    } else {
      setInitialLoginRoute({ name: 'LoggedIn_Home' });
    }
  }, [invitationIdentifier]);

  let content: React.ReactNode = null;

  if (invitationIdentifier) {
    if (data?.invitation.__typename === 'FoundInvitation') {
      content = (
        <Spacer
          size="large"
          items={{
            notice: (
              <InvitationNotice
                invitation={data.invitation.foundInvitation}
                organizationLanguage={organizationLanguage}
              />
            ),
            form: (
              <RegisterForm
                organizationLanguage={organizationLanguage}
                lockedToEmail={true}
                isAskForPhoneNumber={isAskForPhoneNumber}
                isAskForBirthday={isAskForBirthday}
                initialValues={{
                  firstName: data.invitation.foundInvitation.user.firstName,
                  lastName: data.invitation.foundInvitation.user.lastName,
                  birthday: data.invitation.foundInvitation.user.birthday,
                  email: data.invitation.foundInvitation.user.email,
                  password: '',
                  passwordConfirm: '',
                  invitation: invitationIdentifier,
                  phoneNumber: '',
                  isoCode: CountryIsoCode.CA,
                  termsAndConditionsAccepted: data.invitation.foundInvitation.roles.includes(Role.ADMIN) ? false : true,
                }}
              />
            ),
          }}
        />
      );
    }
  } else {
    content = (
      <RegisterForm
        organizationLanguage={organizationLanguage}
        lockedToEmail={false}
        isAskForPhoneNumber={isAskForPhoneNumber}
        isAskForBirthday={isAskForBirthday}
        initialValues={{
          firstName: '',
          lastName: '',
          birthday: new Date().toISOString(),
          email: '',
          password: '',
          passwordConfirm: '',
          invitation: '',
          phoneNumber: '',
          isoCode: CountryIsoCode.CA,
          termsAndConditionsAccepted:
            data &&
            data.invitation.__typename === 'FoundInvitation' &&
            data.invitation.foundInvitation.roles.includes(Role.ADMIN)
              ? false
              : true,
        }}
      />
    );
  }

  return <LoggedOutChrome mode="title-card" titleText={translate.auth.title.signUp} content={content} />;
};
