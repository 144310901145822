import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppButton, AppText, Spacer } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { RootNavType } from '../../../RootNavigator.interface';

export const VerifiedLoggedOut = () => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RootNavType<any>>();

  const handleContinue = () => navigation.navigate('Login');

  const content = (
    <Spacer
      size="large"
      items={{
        title: <AppText font="header3">{translate.verified.subtitle}</AppText>,
        description: <AppText>{translate.verified.loggedOut.description}</AppText>,
        button: <AppButton onPress={handleContinue}>{translate.verified.loggedOut.login}</AppButton>,
      }}
    />
  );

  return <Chrome mode="title-card" titleText={translate.verified.title} content={content} />;
};
