import React from 'react';
import { Platform, TouchableOpacity, View, StyleSheet } from 'react-native';
import { ColorKeys, useColors, useShadow } from '../../shared/Ui';
import { SVG, SVGImageType } from '../SVG/SVG';
import { AppText } from '../AppText/AppText';
import { fonts } from '../../shared/Ui/defaultStyleConfig';
import { useIsMobile } from '../../hooks/ui/useResponsive';

export type TileHeight = 'short' | 'full';

export interface BaseTileProps {
  label: string;
  backgroundColor?: ColorKeys;
  labelColor?: ColorKeys;
  image?: SVGImageType;
  onPress: () => void;
  warning?: boolean;
  disabled?: boolean;
  badge?: number;
  tileHeight?: TileHeight;
  tileWidth?: 'full' | 'half';
}

export const BaseTile: React.FC<BaseTileProps> = ({
  backgroundColor = 'white',
  labelColor = 'blue',
  label,
  image,
  onPress,
  warning,
  disabled,
  badge,
  tileHeight,
  tileWidth,
}) => {
  const shadow = useShadow();
  const colors = useColors();
  const isMobile = useIsMobile();
  const maxTextLength = tileWidth === 'full' ? 30 : 16;
  const shrinkText = isMobile && label.length > maxTextLength;

  const getTextOffsets = () => {
    switch (Platform.OS) {
      case 'web':
        return {
          warningTextOffset: -2,
          badgeTextOffsset: 0,
        };
      case 'android':
        return {
          warningTextOffset: -8,
          badgeTextOffsset: -5,
        };
      case 'ios':
        return {
          warningTextOffset: -4,
          badgeTextOffsset: -4,
        };
    }
  };

  const styles = StyleSheet.create({
    container: {
      ...shadow,
      borderRadius: 14,
      margin: 0,
      backgroundColor: colors[backgroundColor],
    },
    titleWrapper: {
      height: tileHeight === 'short' ? 80 : 100,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 16,
    },
    title: {
      maxWidth: '100%',
      paddingRight: 10,
    },
    warning: {
      borderRadius: 50,
      borderColor: colors.white,
      borderWidth: 2,
      backgroundColor: colors.red,
      width: 44,
      height: 44,
    },
    warningLeft: {
      borderRadius: 50,
      borderColor: colors.red,
      borderWidth: 2,
      backgroundColor: colors.white,
      width: 44,
      height: 44,
      marginRight: 30,
    },
    warningText: {
      lineHeight: 44,
      textAlign: 'center',
      position: 'relative',
      top: getTextOffsets()?.warningTextOffset,
    },
    warningTextLeft: {
      lineHeight: 44,
      textAlign: 'center',
      position: 'relative',
      top: getTextOffsets()?.warningTextOffset,
    },
    badge: {
      borderRadius: 50,
      backgroundColor: colors.white,
      width: 44,
      height: 44,
      marginTop: 5,
      position: 'absolute',
      top: -20,
      right: -12,
    },
    badgeText: {
      lineHeight: 44,
      textAlign: 'center',
      position: 'relative',
      top: getTextOffsets()?.badgeTextOffsset,
    },
    imageWrapper: {
      height: tileHeight === 'short' ? 50 : 140,
      display: 'flex',
      alignItems: 'flex-end',
    },
    image: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: '75%',
      width: '100%',
    },
    labelWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  });

  const badgeArea =
    badge !== undefined ? (
      <View style={styles.badge}>
        <AppText font="header5" color="blue" style={styles.badgeText}>
          {badge.toString()}
        </AppText>
      </View>
    ) : null;

  const warningArea = warning ? (
    <View style={tileHeight === 'short' ? styles.warningLeft : styles.warning}>
      <AppText font="header3" color="red" style={tileHeight === 'short' ? styles.warningTextLeft : styles.warningText}>
        !
      </AppText>
    </View>
  ) : null;

  const labelText = (
    <AppText font={shrinkText ? 'header6' : 'header5'} color={labelColor} style={styles.title}>
      {label}
    </AppText>
  );

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[styles.container, disabled ? { opacity: 0.8 } : null]}
    >
      {badgeArea}
      <View style={styles.titleWrapper}>
        {tileHeight === 'short' ? (
          <View style={styles.labelWrapper}>
            {warningArea}
            {labelText}
          </View>
        ) : (
          <>
            {labelText}
            <View>{warningArea}</View>
          </>
        )}
      </View>
      {tileHeight !== 'short' ? (
        <View style={styles.imageWrapper}>
          <View style={styles.image}>
            <SVG image={image} width={'100%'} height={'100%'} />
          </View>
        </View>
      ) : null}
    </TouchableOpacity>
  );
};
