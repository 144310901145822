import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useAdminContext } from '../../../../hooks/useAdminContext';
import { ManageProtocolNavType } from '../../ManageProtocol.interface';
import { ManageProtocolChrome } from '../../ManageProtocolChrome';
import { Content } from './Content';
import { CheckForFeatureFlag } from '../../../../../shared/CheckForFeatureFlag';
import { FeatureFlag } from '../../../../../../types/globalTypes';

export interface ReturnProtocolChildProtocolConfigProps {}

export const ReturnProtocolChildProtocolConfig: React.FC<ReturnProtocolChildProtocolConfigProps> = (props) => {
  const { organizationId } = useAdminContext();

  const navigation = useNavigation<ManageProtocolNavType<'LoggedIn_Admin_ManageProtocol_Return_ChildProtocol'>>();

  const handleBack = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Index',
      },
    });

  return (
    <ManageProtocolChrome onBack={handleBack}>
      <CheckForFeatureFlag featureFlag={FeatureFlag.ENABLE_MANAGE_PROTOCOL} orRoles={['SUPER_ADMIN']}>
        <Content />
      </CheckForFeatureFlag>
    </ManageProtocolChrome>
  );
};
