import React, { useRef, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FormikProps, Formik, FormikErrors } from 'formik';
import { useValidation } from '../../../hooks/useValidation';
import { AppButton, AppModal, AppText, useLoading } from '../../../uiComponents';
import { useAppAlert } from '../../../hooks/ui/useAppAlert';
import { useTranslations } from '../../../i18n/useTranslations';
import { ChangePasswordFields } from './ChangePasswordFields';
import { useNotification } from '../../../hooks/ui/useNotification';
import { LoggedOutChrome } from '../LoggedOutChrome';
import { RootNavType, RootRouteProp } from '../../../RootNavigator.interface';
import { confirmPasswordReset, getAuth } from '@firebase/auth';

export interface ChangePasswordFormValues {
  newPassword: string;
  newPasswordConfirm: string;
  oobCode: string;
}

type ErrorType =
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/user-not-found'
  | 'auth/weak-password'
  | null;

interface PasswordChangeModal {
  visible: boolean;
  errorType: ErrorType;
}

export const ChangePasswordScreen = () => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RootNavType<never>>();
  const formik = useRef<FormikProps<ChangePasswordFormValues>>();
  const route = useRoute<RootRouteProp<'ChangePassword'>>();
  const { alert } = useAppAlert();
  const { validatePassword } = useValidation();
  const [showPasswordChangeSuccessModal, setShowPasswordChangeSuccessModal] = useState<boolean>(false);
  const [passwordChangeErrorModal, setPasswordChangeErrorModal] = useState<PasswordChangeModal>({
    visible: false,
    errorType: null,
  });

  const notify = useNotification();

  useLoading(formik.current?.isSubmitting);

  const initialValues: ChangePasswordFormValues = {
    newPassword: '',
    newPasswordConfirm: '',
    oobCode: route?.params?.oobCode,
  };

  const handleSubmit = async ({ oobCode, newPassword }: ChangePasswordFormValues) => {
    try {
      await confirmPasswordReset(getAuth(), oobCode, newPassword).then(() => {
        setShowPasswordChangeSuccessModal(true);
      });
      // Replace the modal with notify once it is working again
      //     notify({
      //       image: 'header/people',
      //       title: translate.flash.title.passwordChanged,
      //       description: translate.flash.message.passwordChanged,
      //     });
    } catch (error: any) {
      setPasswordChangeErrorModal({
        visible: true,
        errorType: error?.code,
      });
    }
  };

  const validate = (values: ChangePasswordFormValues) => {
    const errors: FormikErrors<ChangePasswordFormValues> = {};

    if (values.newPassword && !validatePassword(values.newPassword)) {
      errors.newPassword = translate.validation.validPassword;
    }

    if (values.newPasswordConfirm && !validatePassword(values.newPasswordConfirm)) {
      errors.newPasswordConfirm = translate.validation.validPassword;
    }

    if (values.newPassword !== values.newPasswordConfirm) {
      errors.newPasswordConfirm = translate.validation.matchingPasswords;
    }

    return errors;
  };

  const handlePasswordChange = () => {
    setShowPasswordChangeSuccessModal(false);
    navigation.navigate('Login');
  };

  const handleAcceptError = () => {
    if (passwordChangeErrorModal.errorType !== 'auth/weak-password') {
      navigation.navigate('ResetPassword');
    }
    setPasswordChangeErrorModal({ visible: false, errorType: null });
  };

  const getErrorMessage = (errorType: ErrorType) => {
    switch (errorType) {
      case 'auth/expired-action-code':
        return translate.auth.changePassword.errorTypes.expiredActionCode;
      case 'auth/invalid-action-code':
        return translate.auth.changePassword.errorTypes.invalidActionCode;
      case 'auth/user-disabled':
        return translate.auth.changePassword.errorTypes.userDisabled;
      case 'auth/user-not-found':
        return translate.auth.changePassword.errorTypes.userNotFound;
      case 'auth/weak-password':
        return translate.auth.changePassword.errorTypes.weakPassword;
      default:
        return '';
    }
  };

  return (
    <LoggedOutChrome
      titleText={translate.auth.term.resetPassword}
      mode="title-card"
      content={
        <>
          <AppModal
            visible={showPasswordChangeSuccessModal}
            onDismiss={() => setShowPasswordChangeSuccessModal(false)}
            mode="dialog"
            title={translate.auth.changePassword.title}
            buttons={{
              ok: <AppButton onPress={handlePasswordChange}>{translate.admin.manageOrganization.ok}</AppButton>,
            }}
          >
            <AppText>{translate.auth.changePassword.success}</AppText>
          </AppModal>
          <AppModal
            visible={passwordChangeErrorModal.visible}
            onDismiss={() => setPasswordChangeErrorModal({ visible: false, errorType: null })}
            mode="dialog"
            title={translate.error.title.generic}
            buttons={{
              ok: <AppButton onPress={handleAcceptError}>{translate.admin.manageOrganization.ok}</AppButton>,
            }}
          >
            <AppText>{getErrorMessage(passwordChangeErrorModal.errorType)}</AppText>
          </AppModal>
          <Formik<ChangePasswordFormValues> initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
            <ChangePasswordFields />
          </Formik>
        </>
      }
    />
  );
};
