/* Grid layout
=============================== */

import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

interface GridProps {
  gutter?: number;
  columns?: number;
  tiles: Array<JSX.Element>;
  stretch?: boolean;
  style?: ViewStyle;
}

export const Grid: React.FC<GridProps> = ({ gutter = 14, columns = 1, tiles, stretch = false, style, ...props }) => {
  const styles = StyleSheet.create({
    tiles: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: stretch ? 'stretch' : 'flex-start',
      margin: -gutter / 2,
      flex: 1,
    },
    tile: {
      flexBasis: `${100 / columns}%`,
      flexGrow: 0,
      flexShrink: 0,
      padding: gutter / 2,
      flex: 1,
    },
  });

  return (
    <View style={[style, stretch ? { flex: 1 } : {}]}>
      <View style={styles.tiles}>
        {tiles.map((tile, index) => (
          <View key={index} style={styles.tile}>
            {tile}
          </View>
        ))}
      </View>
    </View>
  );
};
