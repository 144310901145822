import React from 'react';
import { StyleSheet, View } from 'react-native';
import { StageState } from '../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Spacer } from '../../../../../../uiComponents';
import { useBorderRadius, useColors } from '../../../../../../shared/Ui/hooks';

export interface RejectionReasonProps {
  rejectionReason?: string | undefined | null;
  state: StageState;
}

export const RejectionReason: React.FC<RejectionReasonProps> = ({ rejectionReason, state }) => {
  const colors = useColors();
  const borderRadius = useBorderRadius();
  const { translate, translateWithVars } = useTranslations();
  const styles = StyleSheet.create({
    banner: {
      backgroundColor: colors.error,
      padding: 15,
      borderRadius,
      marginBottom: 20,
    },
  });

  if (state !== StageState.REJECTED) {
    return null;
  }

  return (
    <View style={styles.banner}>
      <Spacer
        items={{
          header: (
            <AppText color="white">
              {rejectionReason
                ? translateWithVars(translate.recovery.rejectionReason.titleWithReason, { reason: rejectionReason })
                : translate.recovery.rejectionReason.title}
            </AppText>
          ),
          again: <AppText color="white">{translate.recovery.rejectionReason.resubmit}</AppText>,
        }}
      />
    </View>
  );
};
