import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../../../hooks/useMutation';
import { AcceptInvitation, AcceptInvitationVariables } from './__generated__/AcceptInvitation';
import { Role } from '../../../../../types/globalTypes';
import { UseActionItemsQuery } from '../../../../../screens/ActionItems/hooks/__generated__/UseActionItems';
import { MY_ACTION_ITEMS_QUERY } from '../../../../../screens/ActionItems/hooks/useActionItems';
import { GET_ALL_USER_ROLES } from '../../../../../hooks/useGetAllUserRoles';
import { allUserRoles } from '../../../../../hooks/__generated__/allUserRoles';
import { isDPCompletedEducationItems } from '../../../../../hooks/__generated__/isDPCompletedEducationItems';
import { IS_DP_COMPLETED_EDUCATION_ITEMS } from '../../../../../hooks/useIsDPCompletedEducationItems';

const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      ... on ApiError {
        ...ApiError
      }
      ... on AcceptedInvitation {
        id
        accepted
        allEducationItemsCompleted
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useAcceptInvitationMutation = (
  options?: MutationHookOptions<AcceptInvitation, AcceptInvitationVariables>
) =>
  useMutation(ACCEPT_INVITATION_MUTATION, {
    ...options,
    update(cache, { data }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });

      const cachedUserRoles = cache.readQuery<allUserRoles>({
        query: GET_ALL_USER_ROLES,
      });

      const organizationIds: string[] =
        cachedUserRoles && cachedUserRoles.allUserRoles
          ? cachedUserRoles.allUserRoles
              .filter(
                (role) => role.role === Role.PRIMARY_DESIGNATED_PERSON || role.role === Role.SECONDARY_DESIGNATED_PERSON
              )
              .map((role) => role.organizationId)
          : [];

      if (
        data?.acceptInvitation.__typename === 'AcceptedInvitation' &&
        cachedActionItems?.actionItems.filter((item) => item.__typename === 'ProtocolApprovalActionItem').length === 0
      ) {
        cache.writeQuery<isDPCompletedEducationItems>({
          query: IS_DP_COMPLETED_EDUCATION_ITEMS,
          data: {
            isDPCompletedEducationItems: data.acceptInvitation.accepted,
          },
          variables: {
            organizationIds,
          },
        });
      }
    },
  });
