import { useRoute } from '@react-navigation/core';
import * as React from 'react';
import { ProfileChrome } from '../../../../ProfileChrome/ProfileChrome';
import { useRecoveryPlan } from '../../hooks/useRecoveryPlan';
import { RecoveryPlanRouteProp } from '../../RecoveryPlan.interface';
import { MedicalAssessment } from './MedicalAssessment/MedicalAssessment';
import { StageDocument } from './StageDocument/StageDocument';
import { StageSignoff } from './StageSignoff';
import { useCurrentUser } from '../../../../../../shared/CurrentUser/useCurrentUser';
import { WrongUser } from '../../WrongUser/WrongUser';

export interface StageProps {}

export const Stage: React.FC<StageProps> = (props) => {
  const data = useRecoveryPlan();
  const route = useRoute<RecoveryPlanRouteProp<'LoggedIn_Profile_RecoveryPlan_Stage'>>();

  const foundStage = data?.recoveryPlan?.stages.find(({ id }) => id === route.params.stageId);

  const { currentUser } = useCurrentUser();

  const wrongUser = route.params.userId && route.params.userId !== currentUser?.id;
  if (wrongUser) {
    return <WrongUser />;
  }

  const defaultReturn = <ProfileChrome />;

  if (foundStage?.__typename === 'MedicalAssessment') {
    return <MedicalAssessment medicalAssessment={foundStage} caseCreatedDate={data?.recoveryPlan?.caseCreatedDate} />;
  } else if (foundStage?.__typename === 'RecoveryPlanStageDocument') {
    return <StageDocument stageDocument={foundStage} caseCreatedDate={data?.recoveryPlan?.caseCreatedDate} />;
  } else if (foundStage?.__typename === 'RecoveryPlanStageSignOff') {
    return <StageSignoff stageSignoff={foundStage} caseCreatedDate={data?.recoveryPlan?.caseCreatedDate} />;
  }
  return defaultReturn;
};
