import { keys, values, zip } from 'lodash';
import * as Localization from 'expo-localization';
import { translateErrorCode } from './translateErrorCode';
import { english, french } from './languages';
import { useCurrentUser } from '../shared/CurrentUser/useCurrentUser';
import { UserLanguage } from '../types/globalTypes';

// if a new language is added, update listOfLangauges and languages
export const languageList = [
  {
    name: 'english',
    locale: UserLanguage.en,
    nameInOwnLanguage: 'English',
  },
  {
    name: 'french',
    locale: UserLanguage.fr,
    nameInOwnLanguage: 'Français',
  },
];

export const useTranslations = (customLanguage?: UserLanguage) => {
  const { currentUser } = useCurrentUser();
  const userLanguage = () => {
    if (customLanguage) {
      return customLanguage;
    } else if (currentUser && currentUser.__typename === 'CurrentUser') {
      return currentUser.language;
    } else {
      return Localization.locale.split('-')[0];
    }
  };

  return {
    translate: translate(userLanguage()),
    translateWithVars: translateWithVars,
    translateErrorCode: translateErrorCode(Localization.locale),
  };
};

const languages = [english, french];

const translate = (selectedLanguage: string) => {
  let translation = english.translations;

  const localizedTranslation = languages.filter((language) => language.localeKey === selectedLanguage);

  if (localizedTranslation && localizedTranslation.length > 0) {
    translation = localizedTranslation[0].translations;
  }

  return translation;
};

export const variableSubstitution = (text: string, vars: Record<string, any>) => {
  const zipped = zip(keys(vars), values(vars));

  return zipped.reduce((accum, [key, value]) => {
    if (key) {
      return accum.replace(new RegExp(`%{${key}}`, 'g'), value);
    }
    return accum;
  }, text);
};

const translateWithVars = variableSubstitution;
