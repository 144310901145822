import React, { useState } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { NavBarProps } from '../../shared/Chrome/Chrome';
import { AppButton, AppModal, AppText } from '../../uiComponents';
import { Chrome } from '../shared/Chrome/Chrome';
import { AssessNavType } from './Assess.interface';
import { useTranslations } from '../../i18n/useTranslations';
import { useCurrentAssessment } from './hooks/useCurrentAssessment';
import { FinishAssessment } from './screens/FinishAssessment/FinishAssessment';
import { Subtitle } from '../Profile/ProfileChrome/Subtitle';

export interface AssessChromeProps extends Omit<NavBarProps, 'title' | 'titleImage' | 'navBarProps' | 'mode'> {
  customTitle?: string;
}

export const AssessChrome: React.FC<AssessChromeProps> = (props) => {
  const navigator = useNavigation<AssessNavType<any>>();
  const { translate } = useTranslations();
  const [confirmDialog, showConfirmDialog] = useState(false);
  const { dispatch } = useCurrentAssessment();
  const handleOnBack = () => {
    showConfirmDialog(true);
  };
  const handleDismiss = () => {
    showConfirmDialog(false);
  };
  const handleContinue = () => {
    dispatch({ type: 'reset' });
    navigator.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'LoggedIn_Home',
          },
        ],
      })
    );
  };

  return (
    <>
      <AppModal
        visible={confirmDialog}
        onDismiss={handleDismiss}
        mode="dialog"
        title={translate.recognize.exitModal.title}
        buttons={{
          continue: (
            <AppButton type="outline" onPress={handleContinue}>
              {translate.label.quit}
            </AppButton>
          ),
        }}
        children={<AppText>{translate.recognize.exitModal.content}</AppText>}
      />
      <FinishAssessment />

      <Chrome
        title={<Subtitle fullName={props.customTitle ? props.customTitle : translate.recognize.title} />}
        titleImage="BrainHealth"
        navBarProps={{ showHamburger: false, onBack: handleOnBack }}
        mode="title-card"
        autoScroll={false}
        {...props}
      />
    </>
  );
};
