import { useFormikContext } from 'formik';
import { isString } from 'lodash';
import React from 'react';
import { UpdateProtocolConfigFormValue } from '../../ManageProtocol.interface';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Input, Spacer, SpacerItems } from '../../../../../../uiComponents';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: { remove: translate },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();

  const {
    handleChange,
    handleBlur,
    errors: _errors,
    touched: _touched,
    values: _values,
  } = useFormikContext<UpdateProtocolConfigFormValue>();
  const itemKey = 'removeProtocol';
  const values = _values.removeProtocol;
  const itemErrors = _errors?.removeProtocol;
  const itemTouched = _touched.removeProtocol;
  const errors = !isString(itemErrors) ? itemErrors : undefined;
  const touched = !isString(itemTouched) ? itemTouched : undefined;

  const items: SpacerItems = {
    heading: (
      <>
        <AppText font="header3" gutter={true}>
          {rootTranslate.protocol.removal}
        </AppText>
        <AppText>{translate.instruction}</AppText>
      </>
    ),
    emergencyInstruction: (
      <Input
        multiline={true}
        type="text"
        numLines={5}
        numberOfLines={5}
        label={translate.emergencyInstruction}
        onChangeText={handleChange(`${itemKey}.emergencyInstruction`)}
        onBlur={handleBlur(`${itemKey}.emergencyInstruction`)}
        error={errors?.emergencyInstruction}
        touched={touched?.emergencyInstruction}
        value={values.emergencyInstruction}
      />
    ),
    resumeInstruction: (
      <Input
        multiline={true}
        type="text"
        numLines={5}
        numberOfLines={5}
        label={translate.resumeInstruction}
        onChangeText={handleChange(`${itemKey}.resumeInstruction`)}
        onBlur={handleBlur(`${itemKey}.resumeInstruction`)}
        error={errors?.resumeInstruction}
        touched={touched?.resumeInstruction}
        value={values.resumeInstruction}
      />
    ),
    removeInstruction: (
      <Input
        multiline={true}
        type="text"
        numLines={5}
        numberOfLines={5}
        label={translate.removeInstruction}
        onChangeText={handleChange(`${itemKey}.removeInstruction`)}
        onBlur={handleBlur(`${itemKey}.removeInstruction`)}
        error={errors?.removeInstruction}
        touched={touched?.removeInstruction}
        value={values.removeInstruction}
      />
    ),
    discretionaryInstruction: (
      <Input
        multiline={true}
        type="text"
        numLines={5}
        numberOfLines={5}
        label={translate.discretionaryInstruction}
        onChangeText={handleChange(`${itemKey}.discretionaryInstruction`)}
        onBlur={handleBlur(`${itemKey}.discretionaryInstruction`)}
        error={errors?.discretionaryInstruction}
        touched={touched?.discretionaryInstruction}
        value={values.discretionaryInstruction}
      />
    ),
  };

  return <Spacer size="large" items={items} />;
};
