import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { NudgePendingParticipants, NudgePendingParticipantsVariables } from './__generated__/NudgePendingParticipants';

export const NUDGE_PENDING_SUBJECT_MUTATION = gql`
  mutation NudgePendingParticipants($organizationId: ID!) {
    nudgePendingParticipants(organizationId: $organizationId) {
      ... on ApiError {
        ...ApiError
      }
      ... on NudgedParticipants {
        id
        total
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useNudgePendingParticipants = (
  options?: MutationHookOptions<NudgePendingParticipants, NudgePendingParticipantsVariables>
) => useMutation<NudgePendingParticipants, NudgePendingParticipantsVariables>(NUDGE_PENDING_SUBJECT_MUTATION, options);
