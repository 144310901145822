import React, { useRef, useState } from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { StyleSheet, TextInput, View, Linking } from 'react-native';
import { useTranslations } from '../../../i18n/useTranslations';
import { usePasswordRules } from '../../../hooks/usePasswordRules';
import { RegisterFormValues } from './RegisterScreen.interface';
import {
  AppButton,
  AppDateTimePicker,
  AppLink,
  AppText,
  Check,
  Input,
  PasswordHints,
  PhoneInput,
  AppModal,
} from '../../../uiComponents';
import { useIsMobile } from '../../../hooks/ui/useResponsive';
import { UserLanguage } from '../../../types/globalTypes';
import { TermsAndConditions } from '../../Invitation/ReviewInvitation/RightUser/TermsAndConditions';

export interface RegisterFieldsProps {
  disableEmail?: boolean;
  isAskForPhoneNumber: boolean;
  organizationLanguage?: string;
}

export const TermsAndConditionsError = () => {
  const { translate } = useTranslations();

  return (
    <View>
      <AppText font="labelDefault" color="red">
        {translate.reviewInvitation.rightUser.youMustAcceptAllPolicies}
      </AppText>
    </View>
  );
};

export const RegisterFields: React.FC<RegisterFieldsProps> = ({
  disableEmail,
  isAskForPhoneNumber,
  organizationLanguage,
}) => {
  const passwordInput = useRef<TextInput | null>(null);
  const { translate } = useTranslations(organizationLanguage ? UserLanguage[organizationLanguage] : null);
  const isMobile = useIsMobile();
  const [hintsVisible, setHintsVisible] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = React.useState(false);

  const passwordRules = usePasswordRules();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    values,
    errors,
    touched,
    initialValues,
  } = useFormikContext<RegisterFormValues>();

  const handleBirthdayChange = (date: string) => setFieldValue('birthday', date);
  const handleBirthdayBlur = () => setFieldTouched('birthday', true);

  const [privacyPolicyModalVisible, setPrivacyPolicyModalVisible] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const handlePolicyToggle = () => {
    setPrivacyPolicyAccepted(!privacyPolicyAccepted);
  };

  const handlePasswordBlur = (e: any) => {
    hidePasswordHints();
    handleBlur('password')(e);
  };

  const showPasswordHints = () => {
    setHintsVisible(true);
    setTimeout(() => {
      if (passwordInput.current) {
        passwordInput.current.focus();
      }
    });
  };

  const hidePasswordHints = () => {
    setHintsVisible(false);
  };

  const handleAcceptPrivacyPolicy = () => {
    setPrivacyPolicyAccepted(true);
    setPrivacyPolicyModalVisible(false);
  };

  const styles = StyleSheet.create({
    buttons: {
      marginTop: 30,
      marginBottom: 30,
    },
    button: {
      marginRight: 'auto' as const,
    },
    linkContainer: {
      flex: 1,
    },
    link: {
      marginTop: 20,
    },
    formInputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      margin: -8,
    },
    formInput: {
      flexBasis: isMobile ? '100%' : '50%',
      flexGrow: 0,
      flexShrink: 0,
      padding: 8,
    },
    termsAndConditionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingHorizontal: 12,
    },
    termsAndConditionsCheckbox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    termsAndConditionsError: {
      alignSelf: 'center',
    },
    termsAndConditionsText: {
      display: 'flex',
      flexDirection: 'row',
    },
  });

  return (
    <>
      <View style={styles.formInputWrapper}>
        <View style={styles.formInput}>
          <Input
            key="first-name"
            icon="user"
            value={values.firstName}
            type="text"
            placeholder={translate.placeholder.firstName}
            returnKeyType="go"
            onChangeText={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
            error={errors.firstName}
            touched={touched.firstName}
            autoFocus={true}
            label={translate.placeholder.firstName}
          />
        </View>
        <View style={styles.formInput}>
          <Input
            key="last-name"
            icon="user"
            value={values.lastName}
            type="text"
            placeholder={translate.placeholder.lastName}
            returnKeyType="go"
            onChangeText={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
            error={errors.lastName}
            touched={touched.lastName}
            label={translate.placeholder.lastName}
          />
        </View>
      </View>
      <View style={styles.formInputWrapper}>
        <View style={styles.formInput}>
          <AppDateTimePicker
            value={values.birthday}
            key="birthday"
            onChange={handleBirthdayChange}
            onBlur={handleBirthdayBlur}
            error={errors.birthday}
            touched={touched.birthday}
            label={translate.placeholder.dateOfBirth}
          />
        </View>
        <View style={styles.formInput}>
          {isAskForPhoneNumber ? (
            <PhoneInput
              key="phone-number"
              phoneNumber={values.phoneNumber ? values.phoneNumber : ''}
              returnKeyType="go"
              onChangePhoneNumber={handleChange('phoneNumber')}
              onBlurPhoneNumber={handleBlur('phoneNumber')}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              label={translate.placeholder.phone}
              isoCode={values.isoCode ? values.isoCode : 'CA'}
              onChangeIsoCode={handleChange('isoCode')}
              onBlurIsoCode={handleBlur('isoCode')}
            />
          ) : null}
        </View>
        {/* create an invisible input so that the phone input doesn't autofill with the email */}
        <View style={{ opacity: 0, height: 0, width: 0 }}>
          <Input />
        </View>
      </View>
      <View style={styles.formInputWrapper}>
        <View style={styles.formInput}>
          <Input
            icon="mail"
            value={values.email}
            disabled={disableEmail}
            type="email"
            placeholder={translate.placeholder.email}
            returnKeyType="go"
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            error={errors.email}
            label={translate.placeholder.email}
          />
        </View>
      </View>
      <View style={styles.formInputWrapper}>
        <View style={styles.formInput}>
          <Input
            key="password"
            icon="lock"
            value={values.password}
            type="password"
            placeholder={translate.placeholder.password}
            returnKeyType="go"
            onChangeText={handleChange('password')}
            onBlur={handlePasswordBlur}
            onFocus={showPasswordHints}
            error={touched.password && errors.password ? errors.password : undefined}
            touched={touched.password}
            label={translate.placeholder.createPassword}
          />
        </View>
      </View>
      <PasswordHints password={values.password} visible={hintsVisible} rules={passwordRules} />
      <View style={styles.formInputWrapper}>
        <View style={styles.formInput}>
          <Input
            key="password-confirm"
            icon="lock"
            value={values.passwordConfirm}
            type="password"
            placeholder={translate.placeholder.passwordConfirm}
            returnKeyType="go"
            onChangeText={handleChange('passwordConfirm')}
            onBlur={handleBlur('passwordConfirm')}
            error={errors.passwordConfirm}
            touched={touched.passwordConfirm}
          />
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: 12,
        }}
      >
        <Check checked={privacyPolicyAccepted} onPress={handlePolicyToggle} />
        <AppText>{translate.privacyPolicy.readAndAgree1}</AppText>
        <AppLink onPress={() => Linking.openURL(translate.privacyPolicy.link)}>
          {translate.privacyPolicy.readAndAgree2}
        </AppLink>
      </View>

      {!initialValues.termsAndConditionsAccepted ? (
        <View style={styles.termsAndConditionsContainer}>
          <View style={styles.termsAndConditionsCheckbox}>
            <Check
              checked={values.termsAndConditionsAccepted}
              onPress={() => setFieldValue('termsAndConditionsAccepted', !values.termsAndConditionsAccepted)}
            />
            <View style={styles.termsAndConditionsText}>
              <AppText>{`${translate.label.agreeTerms.agreementPart1} `}</AppText>
              <AppLink onPress={() => setTermsModalOpen(true)}>{translate.label.agreeTerms.termsAndConditions}</AppLink>
              <AppText>{` ${translate.label.agreeTerms.agreementPart2}`}</AppText>
            </View>
          </View>
          <View style={styles.termsAndConditionsError}>
            <ErrorMessage name="termsAndConditionsAccepted" component={TermsAndConditionsError} />
          </View>
        </View>
      ) : null}

      <AppModal
        mode="full-screen"
        title={translate.label.agreeTerms.modalTitle}
        visible={termsModalOpen}
        onDismiss={() => setTermsModalOpen(false)}
      >
        <TermsAndConditions />
      </AppModal>

      <View style={styles?.buttons}>
        <AppButton onPress={handleSubmit} style={styles.button} disabled={!isValid || !privacyPolicyAccepted}>
          {translate.auth.term.createAccount}
        </AppButton>
      </View>
    </>
  );
};
