import { gql, MutationHookOptions, useLazyQuery } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import {
  NudgeParticipantsNotReviewedEducation,
  NudgeParticipantsNotReviewedEducationVariables,
} from './__generated__/NudgeParticipantsNotReviewedEducation';

export const NUDGE_PENDING_SUBJECT_QUERY = gql`
  query NudgeParticipantsNotReviewedEducation($input: NudgeParticipantsNotReviewedEducationInput!) {
    nudgeParticipantsNotReviewedEducation(input: $input) {
      ... on ApiError {
        ...ApiError
      }
      ... on NudgeEducationParticipants {
        id
        total
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useNudgeParticipantsNotReviewedEducation = (
  options?: MutationHookOptions<NudgeParticipantsNotReviewedEducation, NudgeParticipantsNotReviewedEducationVariables>
) =>
  useLazyQuery<NudgeParticipantsNotReviewedEducation, NudgeParticipantsNotReviewedEducationVariables>(
    NUDGE_PENDING_SUBJECT_QUERY,
    options
  );
