import React from 'react';
import { Linking } from 'react-native';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseItemRtaProtocolStageDocumentSubmission } from '../../../../../fragments/__generated__/CaseItemRtaProtocolStageDocumentSubmission';
import { RtaProtocolType } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useColors } from '../../../../../shared/Ui/hooks';
import { AppText, AppButton } from '../../../../../uiComponents';

export interface CaseItemRtaProtocolStageDocumentSubmissionProps {
  item: CaseItemRtaProtocolStageDocumentSubmission;
}

export const CaseItemRtaProtocolStageDocumentSubmissionView: React.FC<CaseItemRtaProtocolStageDocumentSubmissionProps> =
  ({ item }) => {
    const colors = useColors();
    const { translate, translateWithVars } = useTranslations();

    const handleDocumentPress = () => {
      if (item.document.downloadUri) {
        Linking.openURL(item.document.downloadUri);
      }
    };

    const protocolTerm =
      item.protocolType === RtaProtocolType.LEARN
        ? translate.recovery.index.returnToLearn
        : translate.recovery.index.returnToSport;

    const text = !item.isFastTracked
      ? translateWithVars(translate.caseLog.caseItemRtaProtocolStageDocumentSubmission.message, {
          actor: `${item.actor.firstName} ${item.actor.lastName}`,
          stage: item.stageName.toLowerCase(),
          protocol: protocolTerm,
        })
      : translateWithVars(translate.caseLog.caseItemRtaProtocolStageDocumentSubmission.fastTracked, {
          actor: `${item.actor.firstName} ${item.actor.lastName}`,
          stage: item.stageName.toLowerCase(),
          protocol: protocolTerm,
          stepDefinedWaitTime: item.timeGateHours,
          hourOrHours:
            item.timeGateHours === 1 ? translate.recovery.stageSignoff.hour : translate.recovery.stageSignoff.hours,
        });
    const action = (
      <AppButton icon="file" onPress={handleDocumentPress} type="default">
        {translate.recovery.stageDocumentReview.document}
      </AppButton>
    );
    return (
      <BaseCaseLogItemView item={item} action={action}>
        <AppText color={item.isFastTracked ? 'red' : 'grayDark'}>{text}</AppText>
      </BaseCaseLogItemView>
    );
  };
