import React from 'react';
import { Group } from './Group/Group';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppText, Grid } from '../../../../uiComponents';

export interface GroupListItem {
  id: string;
  name: string;
}

export interface GroupsList {
  items: GroupListItem[] | undefined;
  onPress: (groupId: string) => void;
}

export const GroupsList: React.FC<GroupsList> = ({ items = [], onPress }) => {
  const { translate } = useTranslations();
  const tiles = items.map((group) => <Group key={group.id} label={group.name} onPress={() => onPress(group.id)} />);
  if (tiles.length === 0) {
    return (
      <AppText style={{ marginVertical: 20, textAlign: 'center' }}>
        {translate.admin.manageSeasons.seasonDetails.noGroups}
      </AppText>
    );
  }
  return <Grid tiles={tiles} columns={2} stretch={true} />;
};
