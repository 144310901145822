import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface LayoutProps {
  style?: StyleProp<ViewStyle>;
  childrenAlignment?:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'center-left'
    | 'center-center'
    | 'center-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';
  footer?: React.ReactNode;
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children, footer, childrenAlignment = 'top', style }) => {
  let contentStyle: ViewStyle = {};

  switch (childrenAlignment) {
    case 'top-left':
      contentStyle = {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      };
      break;
    case 'top-center':
      contentStyle = {
        alignItems: 'center',
        justifyContent: 'flex-start',
      };
      break;
    case 'top-right':
      contentStyle = {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      };
      break;
    case 'center-left':
      contentStyle = {
        alignItems: 'flex-start',
        justifyContent: 'center',
      };
      break;
    case 'center-center':
      contentStyle = {
        alignItems: 'center',
        justifyContent: 'center',
      };
      break;
    case 'center-right':
      contentStyle = {
        alignItems: 'flex-end',
        justifyContent: 'center',
      };
      break;
    case 'bottom-left':
      contentStyle = {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      };
      break;
    case 'bottom-center':
      contentStyle = {
        alignItems: 'center',
        justifyContent: 'flex-end',
      };
      break;
    case 'bottom-right':
      contentStyle = {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      };
      break;
  }

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flex: 1,
    },
    content: {
      flex: 1,
      display: 'flex',
      ...contentStyle,
    },
    footer: {
      flex: 0,
      flexBasis: 'auto',
    },
  });

  return (
    <View style={[styles.container, style]}>
      <View style={styles.content}>{children}</View>
      <View style={styles.footer}>{footer}</View>
    </View>
  );
};
