import React from 'react';
import { AppModal, AppButton, AppText } from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';
import { Platform, StyleSheet, View, Linking } from 'react-native';
import * as Updates from 'expo-updates';

interface AppUpdateModalProps {}

export const AppUpdateModal: React.FC<AppUpdateModalProps> = () => {
  const { translate, translateWithVars } = useTranslations();

  const goToStore = () => {
    if (Platform.OS === 'android') {
      Linking.openURL('https://play.google.com/store/apps/details?id=co.agileis.capt');
    } else if (Platform.OS === 'ios') {
      Linking.openURL('https://apps.apple.com/ca/app/capt/id1572880628');
    }
    if (Platform.OS !== 'web') Updates.reloadAsync();
  };

  const styles = StyleSheet.create({
    body: {
      textAlign: 'center',
    },
    storeButton: {
      alignItems: 'center',
    },
  });

  return (
    <AppModal mode="full-screen" title={translate.appUpdate.title}>
      <AppText style={[styles.body, { marginBottom: 20, marginTop: 120 }]}>{translate.appUpdate.message}</AppText>
      <AppText style={[styles.body, { marginBottom: 60 }]}>
        {translateWithVars(translate.appUpdate.instructions, {
          storeName: Platform.OS === 'ios' ? translate.appUpdate.appStore : translate.appUpdate.playStore,
        })}
      </AppText>
      <View style={styles.storeButton}>
        <AppButton onPress={goToStore} size="large">
          {Platform.OS === 'ios' ? translate.appUpdate.goToAppStore : translate.appUpdate.goToPlayStore}
        </AppButton>
      </View>
    </AppModal>
  );
};
