import { FormikErrors, FormikTouched, isString, useFormikContext } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { InputLabel, Spacer } from '../../../../../../../uiComponents';
import {
  DocumentInput,
  UpdateEducationProtocolItemDocumentConfigFormValue,
  UpdateProtocolConfigFormValue,
} from '../../../ManageProtocol.interface';
import { EducationProtocolItemBaseConfig } from './EducationProtocolItemBaseConfig';
import { DocumentSelector } from '../../../DocumentSelector';

export interface EducationProtocolItemDocumentConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateEducationProtocolItemDocumentConfigFormValue>;
  touch?: FormikTouched<UpdateEducationProtocolItemDocumentConfigFormValue>;
  value: UpdateEducationProtocolItemDocumentConfigFormValue;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const EducationProtocolItemDocumentConfig: React.FC<EducationProtocolItemDocumentConfigProps> = ({
  index,
  onRemove,
  move,
  itemKey,
  error,
  touch,
  value,
  canMoveUp,
  canMoveDown,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            education: { item: translate },
          },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();
  const { setFieldValue } = useFormikContext<UpdateProtocolConfigFormValue>();

  const handleDocumentChange = (_document: DocumentInput) => {
    setFieldValue(`${itemKey}.document`, _document);
  };

  const documentError = error?.document && isString(error.document) ? error.document : null;

  return (
    <EducationProtocolItemBaseConfig
      index={index}
      itemKey={itemKey}
      move={move}
      onRemove={onRemove}
      value={value}
      canMoveDown={canMoveDown}
      canMoveUp={canMoveUp}
      error={error}
      touch={touch}
      typeSpecificItems={{
        document: (
          <Spacer
            items={{
              label: <InputLabel label={rootTranslate.recovery.stageDocumentReview.document} />,
              document: (
                <DocumentSelector error={documentError} onChange={handleDocumentChange} document={value.document} />
              ),
            }}
          />
        ),
      }}
    />
  );
};
