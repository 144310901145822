import React from 'react';
import { StyleSheet } from 'react-native';
import { AppText } from '../AppText/AppText';

interface InputLabelProps {
  label: string;
}

export const InputLabel: React.FC<InputLabelProps> = ({ label }) => {
  const styles = StyleSheet.create({
    label: {
      marginBottom: 5,
    },
  });

  return (
    <AppText font="bodyDefault" color="grayDark" style={[styles.label]}>
      {label}
    </AppText>
  );
};
