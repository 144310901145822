import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppButton } from '../../../../../uiComponents';
import { CreateGroupFormData } from './CreateGroupModal.interface';

export interface SubmitButtonProps {}

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { dirty, isValid, submitForm, isSubmitting } = useFormikContext<CreateGroupFormData>();
  const { translate } = useTranslations();

  return (
    <AppButton loading={isSubmitting} disabled={!isValid || !dirty} onPress={submitForm} style={{ marginLeft: 'auto' }}>
      {translate.label.create}
    </AppButton>
  );
};
