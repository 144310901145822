import React from 'react';
import { Linking } from 'react-native';
import { AppButton, AppText } from '../../../../uiComponents';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { useTranslations } from '../../../../i18n/useTranslations';
import { EmergencyContactCard } from './EmergencyContactCard';
import { AssessChrome } from '../../AssessChrome';
import { hasPhoneNumber } from './hasPhoneNumber';

export interface EmergencyContactProps {}

export const EmergencyContact: React.FC<EmergencyContactProps> = (props) => {
  const { translate, translateWithVars } = useTranslations();
  const { state, dispatch } = useCurrentAssessment();

  const { selectedProtocolOption, subject } = state;

  if (!selectedProtocolOption || !subject) {
    return <AssessChrome content={null} />;
  }

  const guardians = subject.guardians
    .filter((guardian) => guardian.organizationId === state.selectedProtocolOption?.organization.id)
    .filter(hasPhoneNumber);

  const handleCallContact = (phoneNumber: string) => {
    Linking.openURL(`tel:${phoneNumber}`);
  };

  const handleSkipButtonPress = () => {
    dispatch({
      type: 'setDoneRemovalProtocol',
      payload: {
        status: true,
      },
    });
  };

  const content = (
    <>
      <AppText font="header2" gutter={true}>
        {translateWithVars(translate.emergencyContact.guardians, {
          name: state.subject?.fullName,
        })}
      </AppText>
      {guardians.length > 0 ? (
        <>
          {guardians.map((contact) => (
            <EmergencyContactCard
              key={contact.id}
              fullName={`${contact.firstName} ${contact.lastName}`}
              phoneNumber={contact.phoneNumber?.phoneNumber ?? ''}
              onCallContact={handleCallContact}
            />
          ))}
        </>
      ) : (
        <AppText font="bodyDefault" gutter={true} color="grayDark">
          {translate.emergencyContact.noContactsFound}
        </AppText>
      )}
      <AppButton onPress={handleSkipButtonPress} style={{ width: 300, alignSelf: 'flex-start', marginTop: 15 }}>
        {translate.label.next}
      </AppButton>
    </>
  );

  return <AssessChrome content={content} />;
};
