import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { ProtocolFragment } from '../fragments/ProtocolFragment';
import { CurrentProtocol, CurrentProtocolVariables } from './__generated__/CurrentProtocol';

const CURRENT_PROTOCOL_QUERY = gql`
  query CurrentProtocol($organizationId: ID!) {
    currentProtocolByOrganization(organizationId: $organizationId) {
      ... on Protocol {
        ...Protocol
      }
    }
  }
  ${ProtocolFragment}
`;
export const useCurrentProtocol = (options?: QueryHookOptions<CurrentProtocol, CurrentProtocolVariables>) =>
  useQuery<CurrentProtocol, CurrentProtocolVariables>(CURRENT_PROTOCOL_QUERY, options);
