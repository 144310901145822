import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SubjectSimpleStatusIcon } from './SubjectSimpleStatusIcon';
import { SubjectMemberships } from '../../fragments/__generated__/SubjectMemberships';
import { OverallSubjectStatus, SubjectStatus } from '../../types/globalTypes';
import { SubjectComplexStatusIcon } from './SubjectComplexStatusIcon';

interface SubjectStatusIconProps {
  subjectMemberships: SubjectMemberships;
  style?: StyleProp<ViewStyle>;
}

export const SubjectStatusIcon: React.FC<SubjectStatusIconProps> = ({ subjectMemberships, style }) => {
  switch (subjectMemberships.status) {
    case OverallSubjectStatus.MULTIPLE:
      return <SubjectComplexStatusIcon style={style} subjectMemberships={subjectMemberships} />;
    case OverallSubjectStatus.ACTIVE:
      return <SubjectSimpleStatusIcon style={style} subjectStatus={SubjectStatus.ACTIVE} />;
    case OverallSubjectStatus.ON_PROTOCOL:
      return <SubjectSimpleStatusIcon style={style} subjectStatus={SubjectStatus.ON_PROTOCOL} />;
    case OverallSubjectStatus.REMOVED:
      return <SubjectSimpleStatusIcon style={style} subjectStatus={SubjectStatus.REMOVED} />;
  }
};
