import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { GroupListItem } from '../../ManageGroups/GroupsList';
import { useColors } from '../../../../../shared/Ui/hooks';
import { AppText, Icon } from '../../../../../uiComponents';

export interface GroupPillsProps {
  groups: GroupListItem[];
  onRemoveRole: (role: string) => void;
}

export const GroupPills: React.FC<GroupPillsProps> = ({ groups, onRemoveRole, ...props }) => {
  const colors = useColors();

  const styles = StyleSheet.create({
    container: {
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
      flexWrap: 'wrap',
    },
    pill: {
      marginRight: 10,
      paddingVertical: 5,
      paddingRight: 20,
      marginVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 99,
      backgroundColor: colors.gray,
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '90%',
    },
    label: {},
    icon: {
      marginLeft: 8,
      position: 'absolute',
      right: 5,
    },
  });

  return (
    <View style={styles.container} {...props}>
      {groups?.map((group, idx) => (
        <View key={group.id} style={[styles.pill, { backgroundColor: colors.lime }]}>
          <AppText style={styles.label} font="labelDefault" color="grayDark">
            {group.name}
          </AppText>

          <TouchableOpacity style={styles.icon} onPress={() => onRemoveRole(group.id)}>
            <Icon size={14} name="x" />
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};
