import { useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useInvitationQuery } from '../../Invitation/hooks/useInvitationQuery';
import { RootRouteProp } from '../../../RootNavigator.interface';
import { LoggedOutChrome } from '../LoggedOutChrome';
import { LoginForm } from './LoginForm';
import { InvitationNotice } from './InvitationNotice';
import { Spacer } from '../../../uiComponents';
import { useInitialNavigation } from '../../../shared/InitialNavigationRoutes';
import { useTranslations } from '../../../i18n/useTranslations';

export interface LoginScreenProps {}

export const LoginScreen: React.FC<LoginScreenProps> = () => {
  const route = useRoute<RootRouteProp<'Login'>>();
  const { setInitialLoginRoute } = useInitialNavigation();
  const invitationIdentifier = route.params?.invitationIdentifier || '';
  const { translate } = useTranslations();

  const { data } = useInvitationQuery({
    variables: {
      input: {
        identifier: invitationIdentifier as any as string,
      },
    },
    skip: !invitationIdentifier,
  });

  useEffect(() => {
    if (invitationIdentifier) {
      setInitialLoginRoute({ name: 'LoggedIn_ReviewInvitation', initialParams: { invitationIdentifier } });
    } else {
      setInitialLoginRoute({ name: 'LoggedIn_Home' });
    }
  }, [invitationIdentifier]);

  let content: React.ReactNode = null;

  if (invitationIdentifier) {
    if (data?.invitation.__typename === 'FoundInvitation') {
      content = (
        <Spacer
          size="large"
          items={{
            notice: <InvitationNotice invitation={data.invitation.foundInvitation} />,
            form: (
              <LoginForm
                lockedToEmail={true}
                invitationIdentifier={invitationIdentifier}
                initialValues={{
                  email: data.invitation.foundInvitation.user.email,
                  password: '',
                }}
              />
            ),
          }}
        />
      );
    }
  } else {
    content = (
      <LoginForm
        lockedToEmail={false}
        initialValues={{
          email: '',
          password: '',
        }}
      />
    );
  }

  return <LoggedOutChrome mode="title-card" titleText={translate.auth.term.signIn} content={content} />;
};
