import { Chrome } from '../shared/Chrome/Chrome';
import React from 'react';

interface TrainingChromeProps {
  onBack: () => void;
  titleText: string;
  showHamburger: boolean;
  children: React.ReactNode;
}

export const TrainingChrome: React.FC<TrainingChromeProps> = ({ titleText, onBack, children, showHamburger }) => {
  return (
    <Chrome
      mode="title-card"
      autoScroll={false}
      titleText={titleText}
      content={children}
      navBarProps={{ showHamburger, onBack }}
    />
  );
};
