import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React from 'react';
import {
  UpdateEducationProtocolItemUrlConfigFormValue,
  UpdateProtocolConfigFormValue,
} from '../../../ManageProtocol.interface';
import { EducationProtocolItemBaseConfig } from './EducationProtocolItemBaseConfig';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { Input } from '../../../../../../../uiComponents';

export interface EducationProtocolItemUrlConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateEducationProtocolItemUrlConfigFormValue>;
  touch?: FormikTouched<UpdateEducationProtocolItemUrlConfigFormValue>;
  value: UpdateEducationProtocolItemUrlConfigFormValue;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const EducationProtocolItemUrlConfig: React.FC<EducationProtocolItemUrlConfigProps> = ({
  index,
  onRemove,
  move,
  itemKey,
  error,
  touch,
  value,
  canMoveUp,
  canMoveDown,
}) => {
  const { handleChange, handleBlur } = useFormikContext<UpdateProtocolConfigFormValue>();

  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            education: { item: translate },
          },
        },
      },
    },
  } = useTranslations();

  return (
    <EducationProtocolItemBaseConfig
      index={index}
      itemKey={itemKey}
      move={move}
      onRemove={onRemove}
      value={value}
      canMoveDown={canMoveDown}
      canMoveUp={canMoveUp}
      error={error}
      touch={touch}
      typeSpecificItems={{
        url: (
          <Input
            icon="link"
            label={translate.url}
            onChangeText={handleChange(`${itemKey}.url`)}
            onBlur={handleBlur(`${itemKey}.url`)}
            error={error?.url}
            touched={touch?.url}
            value={value?.url || ''}
          />
        ),
      }}
    />
  );
};
