import * as React from 'react';
import { ActionListItem } from './ActionListItem';
import { PendingInvitationActionItem as PendingInvitationActionItemType } from '../hooks/__generated__/PendingInvitationActionItem';
import { useTranslations } from '../../../i18n/useTranslations';
import { useNavigation } from '@react-navigation/core';
import { LoggedInNavType } from '../../LoggedIn.interface';
export interface PendingInvitationActionItemProps {
  item: PendingInvitationActionItemType;
}

export const PendingInvitationActionItem: React.FC<PendingInvitationActionItemProps> = ({ item }) => {
  const { translateWithVars, translate } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ActionItems'>>();

  const handlePress = () =>
    navigation.navigate('LoggedIn_ReviewInvitation', {
      invitationIdentifier: item.identifier,
      prevScreen: 'LoggedIn_ActionItems',
    });
  return (
    <ActionListItem
      onPress={handlePress}
      text={translateWithVars(translate.actionItems.pendingInvitationActionItem.message, {
        organization: item.organization.name,
      })}
    />
  );
};
