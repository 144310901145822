import * as React from 'react';
import { useState, useEffect, SetStateAction } from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { ActivityIndicator, AppButton, AppText, Select } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { ImportDataTab } from './ImportDataTab';
import { PastUploads } from './PastUploadsTab';
import { Tabs } from './ImportData.interface';
import { useImportDataContext } from '../../hooks/useImportDataContext';
import { appConfig } from '../../../../app.config';
import { useGetOrganizationStatuses } from '../../hooks/useGetOrganizationStatuses';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { useRoute } from '@react-navigation/native';
import { useAdminContext } from '../../../Admin/hooks/useAdminContext';

const TEMPLATES_ID: string = '741af010-3d88-4b5b-9670-e37ed3a060e8.zip';

interface ImportDataProps {
  currentTab: Tabs;
  setCurrentTab: React.Dispatch<SetStateAction<Tabs>>;
}

export const Content: React.FC<ImportDataProps> = ({ currentTab, setCurrentTab }) => {
  const route = useRoute();
  const userRole = route.name === 'LoggedIn_SuperAdmin_ImportData' ? 'superAdmin' : 'admin';
  const { translate } = useTranslations();
  const isMobile = useIsMobile();
  const { data } = useGetOrganizationStatuses({
    skip: userRole !== 'superAdmin',
  });
  const organizations = data?.organizationStatuses || null;

  const rootUrl = appConfig.graphqlEndpoint.replace('/graphql', '');

  const { setCsvAttachments } = useImportDataContext();
  const { organizationId } = useAdminContext();

  useEffect(() => {
    setSelectedOrganization(organizationId);
  }, [organizationId]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 5,
    },
    buttonRow: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: userRole === 'superAdmin' ? 'space-between' : 'flex-start',
      flexWrap: 'wrap',
      marginBottom: 10,
    },
    selectOrganization: {
      width: isMobile ? '100%' : 'auto',
    },
    downloadButton: {
      flexGrow: 0,
      flexShrink: 1,
    },
    navigationButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: isMobile ? 'center' : 'flex-start',
    },
    selectText: {
      textAlign: 'center',
    },
  });

  const [selectedOrganization, setSelectedOrganization] = useState<string>(
    userRole === 'superAdmin' ? '' : organizationId
  );
  const organizationItems = organizations && organizations.map(({ id, name }) => ({ key: id, value: id, label: name }));

  const handleOrganizationChange = (_organizationId: string) => {
    setCsvAttachments({
      SUBJECTS: undefined,
      GUARDIANS: undefined,
      STAFF: undefined,
      SUBJECT_GROUPS: undefined,
      DESIGNATED_PERSON_GROUPS: undefined,
    });
    setSelectedOrganization(_organizationId);
  };

  const handleTemplateDownload = () => {
    Linking.openURL(`${rootUrl}/documents/public/${TEMPLATES_ID}?download=1`);
  };

  return (
    <View style={styles.container}>
      <View style={styles.buttonRow}>
        {userRole === 'superAdmin' ? (
          organizations && organizations.length > 1 ? (
            <Select<string>
              value={selectedOrganization}
              onChange={handleOrganizationChange}
              items={[{ key: '-1', value: '', label: translate.superAdmin.importData.selectOrganization }].concat(
                organizationItems
              )}
              placeholder={translate.superAdmin.importData.chooseOrganizationDropdown}
              style={styles.selectOrganization}
            />
          ) : (
            <ActivityIndicator />
          )
        ) : null}
        <AppButton style={styles.downloadButton} onPress={handleTemplateDownload} icon="file">
          {translate.superAdmin.importData.downloadTemplates}
        </AppButton>
      </View>
      {selectedOrganization !== '' ? (
        <View>
          {currentTab === Tabs.IMPORT_DATA ? (
            <ImportDataTab setCurrentTab={setCurrentTab} selectedOrganization={selectedOrganization} />
          ) : null}
          {currentTab === Tabs.PAST_UPLOADS ? (
            <PastUploads pastUploads={[]} selectedOrganization={selectedOrganization} />
          ) : null}
        </View>
      ) : (
        <View>
          <AppText style={styles.selectText}>{translate.superAdmin.importData.chooseOrganization}</AppText>
        </View>
      )}
    </View>
  );
};
