import React from 'react';
import { View, StyleSheet } from 'react-native';
import { AppText, Icon } from '../../../../../../uiComponents';

export interface LockedActionProps {
  text: string;
}

export const LockedAction: React.FC<LockedActionProps> = ({ text }) => {
  const styles = StyleSheet.create({
    root: {
      display: 'flex',
      marginVertical: 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      marginRight: 10,
    },
  });
  return (
    <View style={styles.root}>
      <Icon style={styles.icon} color="red" name="lock" />
      <AppText font="bodyDefault" color="red">
        {text}
      </AppText>
    </View>
  );
};
