import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useSuperAdminRoutes } from './hooks/useSuperAdminRoutes';
import { SuperAdminParamList } from './SuperAdmin.interface';
import { AdminContextProvider } from '../Admin/AdminContext';
import { useRoute } from '@react-navigation/native';
import { LoggedInRouteProp } from '../LoggedIn.interface';
import { ImportDataContextProvider } from './ImportDataContext';
import { SuperAdminGuard } from './SuperAdminGuard';

const SuperAdminNav = createStackNavigator<SuperAdminParamList>();
const routes = useSuperAdminRoutes();

export interface SuperAdminNavigatorProps {}

export const SuperAdminNavigator: React.FC<SuperAdminNavigatorProps> = () => {
  const route = useRoute<LoggedInRouteProp<'LoggedIn_SuperAdmin'>>();

  return (
    <SuperAdminNav.Navigator initialRouteName="LoggedIn_SuperAdmin_Index" screenOptions={{ headerShown: false }}>
      {routes.map(({ component: Component, name }) => (
        <SuperAdminNav.Screen
          key={name}
          name={name}
          children={() => (
            <SuperAdminGuard>
              <ImportDataContextProvider>
                <AdminContextProvider organizationId={route.params?.organizationId} children={<Component />} />
              </ImportDataContextProvider>
            </SuperAdminGuard>
          )}
        />
      ))}
    </SuperAdminNav.Navigator>
  );
};
