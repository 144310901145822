import { Platform } from 'react-native';
import { StyleConfig } from './AppUiConfig.interface';

export const colors = {
  // do not change or add colors without approval from design & marketing
  // we want to keep our color scheme as consistent & simple as possible
  black: '#000000',
  blue: '#3498DB',
  navy: '#365DB2',
  navyDark: '#1F4B88', // this is our main colour for CAPT
  navyDarker: '#183968',
  green: '#5FB162',
  lime: '#B8DF49',
  purple: '#9B59B6',
  red: '#FF5441',
  grayDark: '#484E4E',
  gray: '#959C9F',
  grayLight: '#D5DCE0',
  white: '#FFFFFF',
  orange: '#F0A33D',
};

// aliases

const aliases = {
  success: colors.green,
  warning: colors.red,
  error: colors.red,
  info: colors.blue,
};

// brand palette

const defaultPalette = {
  primary: colors.green,
  secondary: colors.blue,
  text: colors.grayDark,
  overlay: colors.navyDark + 'dd',
};

const topPadding =
  Platform.select<number>({
    ios: 0,
    android: 0.25,
    web: 0,
  }) || 0;

export const fonts = {
  labelExtraSmall: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 12,
    paddingTop: 12 * topPadding,
    lineHeight: 14,
    color: colors.gray,
  },
  labelSmall: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 14,
    paddingTop: 14 * topPadding,
    lineHeight: 16,
    color: colors.gray,
  },
  labelDefault: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 16,
    paddingTop: 16 * topPadding,
    lineHeight: 18,
    color: colors.navyDark,
  },
  header6: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 18,
    paddingTop: 18 * topPadding,
    lineHeight: 20,
    color: colors.navyDark,
  },
  header5: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 22,
    paddingTop: 22 * topPadding,
    lineHeight: 26,
    color: colors.navyDark,
  },
  header4: {
    fontFamily: 'Montserrat_500Medium',
    fontSize: 24,
    paddingTop: 24 * topPadding,
    lineHeight: 29,
    color: colors.navyDark,
  },
  header3: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 26,
    paddingTop: 26 * topPadding,
    lineHeight: 32,
    color: colors.navyDark,
  },
  header2: {
    fontFamily: 'Montserrat_500Medium',
    fontSize: 30,
    paddingTop: 30 * topPadding,
    lineHeight: 35,
    color: colors.navyDark,
  },
  header1: {
    fontFamily: 'Montserrat_700Bold',
    fontSize: 30,
    paddingTop: 30 * topPadding,
    lineHeight: 35,
    color: colors.navyDark,
  },
  bodySmall: {
    fontFamily: 'Montserrat_400Regular',
    fontSize: 14,
    paddingTop: 14 * topPadding,
    lineHeight: 18,
    color: colors.grayDark,
  },
  bodyMedium: {
    fontFamily: 'Montserrat_400Regular',
    fontSize: 16,
    paddingTop: 16 * topPadding,
    lineHeight: 20,
    color: colors.grayDark,
  },
  bodyLarge: {
    fontFamily: 'Montserrat_400Regular',
    fontSize: 20,
    paddingTop: 20 * topPadding,
    lineHeight: 26,
    color: colors.grayDark,
  },
};

export const defaultStyleConfig: StyleConfig = {
  maxContentWidth: 1024,
  borderRadius: 30,
  shadow: {
    shadowColor: colors.grayDark,
    shadowOffset: { width: 0, height: 15 },
    shadowRadius: 20,
    shadowOpacity: 0.15,
    elevation: 2,
  },
  colors: {
    ...colors,
    ...aliases,
    ...defaultPalette,
  },
};
