import { isString } from 'lodash';
import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Pill } from '../Pill/Pill';

interface InputErrorProps {
  error?: string;
  touched?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const InputError: React.FC<InputErrorProps> = ({ error, style, touched }) => {
  const styles = StyleSheet.create({
    root: {},
  });

  if (!touched || !error) {
    return null;
  }

  return (
    <Pill style={StyleSheet.flatten([styles.root, style])} icon="x" color="red" label={isString(error) ? error : ''} />
  );
};
