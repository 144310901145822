import { isEmpty } from 'lodash';
import { useDialCodes } from './useDialCodes';

export const useMakeFullPhoneNumber = <T extends string, >() => {
	
	const dialCodes = useDialCodes<T>();

	return (localPhoneNumber: string, value: T) => {
		
		const foundItem = dialCodes.find(({ isoCode: _value }) => _value === value);

		return [
			foundItem?.dialCode,
			localPhoneNumber
		].filter(
			(val) => !isEmpty(val)
		).join(' ');
		
	};

}