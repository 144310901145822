import { GenericProfile } from '../../Profile.interface';
import { useProfileContext } from '../../ProfileContext';
import { useGetSubjectProfile } from './useGetSubjectProfile';
import { GetSubjectProfile } from './__generated__/GetSubjectProfile';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { MyProfile_userImage } from './__generated__/MyProfile';

export interface UserProfileData {
  __typename: 'RecoveryPlan' | 'CurrentUser';
  id: string;
  fullName: string;
  age: number | null;
  email: string | null;
  birthday: any | null;
  userImage: MyProfile_userImage | null;
}

const makeMyProfile = (currentUser): UserProfileData | null => {
  const profile: UserProfileData = {
    __typename: 'CurrentUser',
    userImage: currentUser.userImage,
    id: currentUser.id,
    fullName: currentUser.fullName,
    age: currentUser.age,
    email: currentUser.email,
    birthday: currentUser.birthday,
  };

  return profile;
};

const makeSubjectProfile = (data: GetSubjectProfile): GenericProfile | null => {
  if (data.getSubject.__typename !== 'Subject') {
    return null;
  }

  return data.getSubject;
};

export function useGetProfile(): GenericProfile | UserProfileData | null | undefined {
  const navigationParams = useProfileContext();

  const { currentUser } = useCurrentUser();

  const subjectProfile = useGetSubjectProfile({
    skip: !navigationParams.subjectId ? true : false,
    variables: {
      subjectId: navigationParams.subjectId as string,
    },
    hideLoading: true,
  });

  if (!navigationParams.subjectId && (!currentUser || currentUser.__typename !== 'CurrentUser')) {
    return null;
  }

  if (currentUser && !navigationParams.subjectId) {
    return makeMyProfile(currentUser);
  } else if (subjectProfile.data) {
    return makeSubjectProfile(subjectProfile.data);
  } else {
    return null;
  }
}
