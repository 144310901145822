import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React from 'react';
import { View } from 'react-native';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import {
  AppMarkdown,
  Checkbox,
  Icon,
  Input,
  InputLabel,
  RadioButton,
  ShadowView,
  Spacer,
  SpacerItems,
} from '../../../../../../../uiComponents';
import { useColors } from '../../../../../../../shared/Ui/hooks';
import {
  EducationItemScope,
  UpdateEducationProtocolItemConfigFormValue,
  UpdateEducationProtocolItemDocumentConfigFormValue,
  UpdateEducationProtocolItemUrlConfigFormValue,
  UpdateProtocolConfigFormValue,
} from '../../../ManageProtocol.interface';
import { notNullFilter } from './notNullFilter';

export interface EducationProtocolItemBaseConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateEducationProtocolItemConfigFormValue>;
  touch?: FormikTouched<UpdateEducationProtocolItemConfigFormValue>;
  value: UpdateEducationProtocolItemConfigFormValue;
  typeSpecificItems: SpacerItems;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const EducationProtocolItemBaseConfig: React.FC<EducationProtocolItemBaseConfigProps> = ({
  itemKey,
  error,
  touch,
  value,
  typeSpecificItems,
  canMoveDown,
  canMoveUp,
  onRemove,
  move,
  index,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            education: { item: translate },
          },
        },
      },
    },
    translate: rootTranslate,
    translateWithVars,
  } = useTranslations();

  const { handleChange, handleBlur, setFieldValue } = useFormikContext<UpdateProtocolConfigFormValue>();
  const colors = useColors();

  const handleChangeType = (type: 'url' | 'document') => {
    if (type === 'url') {
      const urlItem: UpdateEducationProtocolItemUrlConfigFormValue = {
        ...value,
        type: 'url',
        url: '',
      };
      setFieldValue(`${itemKey}`, urlItem);
    } else {
      const documentItem: UpdateEducationProtocolItemDocumentConfigFormValue = {
        ...value,
        type: 'document',
        document: {
          currentDocument: null,
          nextDocument: null,
        },
      };

      setFieldValue(`${itemKey}`, documentItem);
    }
  };

  const handleSelect = (value: EducationItemScope[]) => {
    const useForDesignatedPerson = value.indexOf(EducationItemScope.USE_FOR_DESIGNATED_PERSON) !== -1 ? true : false;
    const useForGuardian = value.indexOf(EducationItemScope.USE_FOR_GUARDIAN) !== -1 ? true : false;
    const useForSubject = value.indexOf(EducationItemScope.USE_FOR_SUBJECT) !== -1 ? true : false;
    setFieldValue(`${itemKey}.useForDesignatedPerson`, useForDesignatedPerson);
    setFieldValue(`${itemKey}.useForGuardian`, useForGuardian);
    setFieldValue(`${itemKey}.useForSubject`, useForSubject);
  };

  const permissionsOptions = [
    { label: translate.showToDesignatedPersons, value: EducationItemScope.USE_FOR_DESIGNATED_PERSON },
    { label: translate.showToGuardians, value: EducationItemScope.USE_FOR_GUARDIAN },
    { label: translate.showToSubjects, value: EducationItemScope.USE_FOR_SUBJECT },
  ];
  const permissionsValues = [
    value?.useForDesignatedPerson ? EducationItemScope.USE_FOR_DESIGNATED_PERSON : null,
    value?.useForGuardian ? EducationItemScope.USE_FOR_GUARDIAN : null,
    value?.useForSubject ? EducationItemScope.USE_FOR_SUBJECT : null,
  ].filter(notNullFilter);

  const handleRemove = () => onRemove(index);
  const handleMoveUp = () => move(index, index - 1);
  const handleMoveDown = () => move(index, index + 1);

  return (
    <ShadowView style={{ padding: 20 }}>
      <Spacer
        size="large"
        items={{
          name: (
            <Input
              label={rootTranslate.label.name}
              onChangeText={handleChange(`${itemKey}.name`)}
              onBlur={handleBlur(`${itemKey}.name`)}
              error={error?.name}
              touched={touch?.name}
              value={value?.name || ''}
            />
          ),
          instruction: (
            <>
              <Input
                label={translate.instruction}
                onChangeText={handleChange(`${itemKey}.instruction`)}
                onBlur={handleBlur(`${itemKey}.instruction`)}
                error={error?.instruction}
                touched={touch?.instruction}
                value={value?.instruction || ''}
              />
            </>
          ),
          type: (
            <>
              <InputLabel label={translate.resourceType} />
              <RadioButton<'url' | 'document'>
                value={value.type}
                onSelect={handleChangeType}
                options={[
                  {
                    icon: 'check',
                    label: rootTranslate.recovery.stageDocumentReview.document,
                    value: 'document',
                  },
                  {
                    icon: 'check',
                    label: translate.url,
                    value: 'url',
                  },
                ]}
              />
            </>
          ),

          ...typeSpecificItems,

          permissions: (
            <Checkbox<EducationItemScope>
              onSelect={handleSelect}
              options={permissionsOptions}
              value={permissionsValues}
            />
          ),
          preview: (
            <View style={{ marginTop: 20 }}>
              <InputLabel label={translate.preview} />
              <AppMarkdown
                disableLinks={true}
                children={translateWithVars(value?.instruction || '', { name: value?.name || '' })}
              />
            </View>
          ),
          remove: (
            <Spacer
              orientation="horizontal"
              style={{ alignSelf: 'flex-end', width: 'auto' }}
              items={{
                up: canMoveUp ? <Icon color={colors.blue} name="arrow-up" onPress={handleMoveUp} size={24} /> : null,
                down: canMoveDown ? (
                  <Icon color={colors.blue} name="arrow-down" onPress={handleMoveDown} size={24} />
                ) : null,
                trash: <Icon color={colors.red} name="trash" onPress={handleRemove} size={24} />,
              }}
            />
          ),
        }}
      />
    </ShadowView>
  );
};
