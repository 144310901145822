import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { AppText } from '../AppText/AppText';
import { useColors } from '../../shared/Ui';
import { useIsMobile } from '../../hooks/ui/useResponsive';

interface TabButtonProps {
  onPress?: () => void;
  active?: boolean;
  children?: any;
}

export const TabButton: React.FC<TabButtonProps> = ({ children, onPress, active }) => {
  const colors = useColors();
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    tabButton: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderColor: colors.navyDark,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    },
    active: {
      backgroundColor: colors.navyDark,
      borderWidth: 1,
    },
    buttonLabel: {
      textAlign: 'center',
    },
  });

  return (
    <TouchableOpacity onPress={onPress} style={[styles.tabButton, active ? styles.active : undefined]}>
      <AppText style={styles.buttonLabel} font={isMobile ? 'header6' : 'header5'} color={active ? 'white' : 'grayDark'}>
        {children}
      </AppText>
    </TouchableOpacity>
  );
};
