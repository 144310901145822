import React from 'react';
import { View, ViewStyle } from 'react-native';
import { AppText, SubjectStatusIcon } from '../../uiComponents';
import { SubjectForSubjectsList_subjectMemberships } from '../shared/SubjectList/hooks/__generated__/SubjectForSubjectsList';

interface Group {
  __typename: string;
  id: string;
  name: string;
}

interface GroupsProps {
  groups: Group[];
}

interface NameProps {
  name: string;
  style?: ViewStyle;
}

interface SubjectStatusProps {
  memberships: SubjectForSubjectsList_subjectMemberships;
}

export const Name: React.FC<NameProps> = ({ name, style }) => {
  return (
    <View style={style}>
      <AppText color="blue" font="labelDefault">
        {name}
      </AppText>
    </View>
  );
};

export const SubjectStatus: React.FC<SubjectStatusProps> = ({ memberships }) => {
  return (
    <View>
      <SubjectStatusIcon subjectMemberships={memberships} />
    </View>
  );
};

export const Groups: React.FC<GroupsProps> = ({ groups }) => {
  return <AppText numberOfLines={1}>{groups.length > 0 ? groups.map((group) => group.name).join(', ') : '-'}</AppText>;
};
