import { StepChallenge } from '../../../screens/FindSubject/__generated__/StepChallenge';
import { StepInformation } from '../../../screens/FindSubject/__generated__/StepInformation';
import { StepSymptom } from '../../../screens/FindSubject/__generated__/StepSymptom';

export const isNotStepInformation = (
  val: StepInformation | StepChallenge | StepSymptom
): val is StepChallenge | StepSymptom => {
  if (val.__typename === 'StepChallenge' || val.__typename === 'StepSymptom') {
    return true;
  }
  return false;
};
