import { gql, MutationHookOptions } from '@apollo/client';
import { IS_DP_COMPLETED_EDUCATION_ITEMS } from '../../../hooks/useIsDPCompletedEducationItems';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';
import { MY_ACTION_ITEMS_QUERY } from '../../ActionItems/hooks/useActionItems';
import { OrganizationFragment } from '../../Admin/fragments/OrganizationFragment';
import { CreateOrganization, CreateOrganizationVariables } from './__generated__/CreateOrganization';
import { allUserRoles, allUserRoles_allUserRoles } from '../../../hooks/__generated__/allUserRoles';
import { GET_ALL_USER_ROLES } from '../../../hooks/useGetAllUserRoles';
import { Role } from '../../../types/globalTypes';
import { isDPCompletedEducationItems } from '../../../hooks/__generated__/isDPCompletedEducationItems';

const CREATE_ORGANIZATION_QUERY = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ... on ApiError {
        ...ApiError
      }
      ... on Organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragment}
  ${ApiErrorFragment}
`;
export const useCreateOrganization = (
  userEmail: string | null,
  options?: MutationHookOptions<CreateOrganization, CreateOrganizationVariables>
) =>
  useMutation(CREATE_ORGANIZATION_QUERY, {
    ...options,
    refetchQueries: () => [{ query: MY_ACTION_ITEMS_QUERY }, 'UseActionItems'],
    update(cache, { data }, { variables }) {
      if (
        variables?.input.isPrimaryDesignatedPerson &&
        variables.input.admin?.email === userEmail &&
        data?.createOrganization.__typename === 'Organization'
      ) {
        const cachedUserRoles = cache.readQuery<allUserRoles>({
          query: GET_ALL_USER_ROLES,
        });

        const newRoles: allUserRoles_allUserRoles[] = [
          {
            __typename: 'RoleEntry',
            role: Role.PRIMARY_DESIGNATED_PERSON,
            organizationId: data.createOrganization.__typename === 'Organization' ? data.createOrganization.id : '',
          },
          {
            __typename: 'RoleEntry',
            role: Role.ADMIN,
            organizationId: data.createOrganization.__typename === 'Organization' ? data.createOrganization.id : '',
          },
        ];

        if (cachedUserRoles?.allUserRoles) {
          cachedUserRoles.allUserRoles.concat(newRoles);
        }

        if (cachedUserRoles) {
          cache.writeQuery({
            query: GET_ALL_USER_ROLES,
            data: {
              allUserRoles: [...cachedUserRoles.allUserRoles],
            },
          });
        }

        const organizationIds: string[] =
          cachedUserRoles && cachedUserRoles.allUserRoles
            ? cachedUserRoles.allUserRoles
                .filter(
                  (role) =>
                    role.role === Role.PRIMARY_DESIGNATED_PERSON || role.role === Role.SECONDARY_DESIGNATED_PERSON
                )
                .map((role) => role.organizationId)
            : [];

        cache.writeQuery<isDPCompletedEducationItems>({
          query: IS_DP_COMPLETED_EDUCATION_ITEMS,
          data: {
            isDPCompletedEducationItems: false,
          },
          variables: {
            organizationIds,
          },
        });
      }
    },
  });
