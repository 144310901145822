import { Alert, AlertButton, AlertStatic, Platform } from 'react-native';
import { isUndefined } from 'lodash';
import { useTranslations } from '../../i18n/useTranslations';
import { useNotification } from './useNotification';
import { ErrorCode } from '../../types/globalTypes';

type AppAlert = () => {
  alert: (title: string | ErrorCode, message?: string | ErrorCode, buttons?: AlertButton[]) => void;
};

const isErrorCode = (maybeErrorCode: string | ErrorCode): maybeErrorCode is ErrorCode => {
  return Object.values(ErrorCode).indexOf(maybeErrorCode as ErrorCode) !== -1;
};

class WebAlert implements Pick<AlertStatic, 'alert'> {
  public alert(title: string, message?: string, buttons?: AlertButton[]): void {
    if (buttons === undefined || buttons.length === 0) {
      window.alert([title, message].filter(Boolean).join('\n'));
      return;
    }

    const result = window.confirm([title, message].filter(Boolean).join('\n'));

    if (result === true) {
      const confirm = buttons.find(({ style }) => style !== 'cancel');
      confirm?.onPress?.();
      return;
    }

    const cancel = buttons.find(({ style }) => style === 'cancel');
    cancel?.onPress?.();
  }
}

const AppWebAlert = new WebAlert();

export const useAppAlert: AppAlert = () => {
  const { translateErrorCode } = useTranslations();

  const notify = useNotification();

  return {
    alert: (_title: string | ErrorCode, _message?: string | ErrorCode, buttons?: AlertButton[]) => {
      let title: string;

      if (isErrorCode(_title)) {
        title = translateErrorCode(_title);
      } else {
        title = _title;
      }

      let message: string | undefined;
      if (isUndefined(_message)) {
        message = undefined;
      } else if (isErrorCode(_message)) {
        message = translateErrorCode(_message);
      } else {
        message = _message;
      }

      if (Platform.OS === 'web') {
        notify({
          image: 'cancel',
          description: `${title}: ${message}`,
          alertType: 'error',
        });
      } else {
        Alert.alert(title, message, buttons);
      }
    },
  };
};
