import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { UserListItem } from './UserListItem';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { UserListProps } from './UserList.interface';
import { ShadowView } from '../ShadowView/ShadowView';
import { AppScrollView } from '../AppScrollView/AppScrollView';
import { AppText } from '../AppText/AppText';

export const UserList: React.FC<UserListProps> = (props) => {
  const {
    style,
    noUsersMessage,
    setUserListHeight,
    displayItems,
    mobileRearrangement,
    customWidths,
    rightAlignLastItem = false,
  } = props;

  const isMobile = useIsMobile();

  const windowHeight = Dimensions.get('window').height;

  const styles = StyleSheet.create({
    userList: {
      maxHeight: isMobile ? windowHeight - 300 : 500,
      marginBottom: 30,
    },
    noUsersMessage: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  return displayItems.length > 0 ? (
    <ShadowView
      style={style}
      onLayout={(event) => setUserListHeight && setUserListHeight(event.nativeEvent.layout.y)}
      // NOTE: this is optional. It can be used to set the same height for the loading animation when navigating between screens or searches
    >
      <AppScrollView style={styles.userList}>
        {displayItems.map((displayItem, index) => (
          <UserListItem
            onPress={displayItem.onSelect}
            key={displayItem.key}
            separator={index !== displayItems.length - 1}
            displayItem={displayItem.components}
            rightAlignLastItem={rightAlignLastItem}
            mobileRearrangement={mobileRearrangement}
            customWidths={customWidths}
          />
        ))}
      </AppScrollView>
    </ShadowView>
  ) : (
    <View style={[style, styles.noUsersMessage]}>
      <AppText>{noUsersMessage}</AppText>
    </View>
  );
};
