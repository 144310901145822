import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { DocumentFragment } from '../../../fragments/DocumentFragment';
import { CaseFragment } from '../../../fragments/CaseFragment';
import { IncidentReportCase, IncidentReportCaseVariables } from './__generated__/IncidentReportCase';

const INCIDENT_REPORT_CASE_QUERY = gql`
  query IncidentReportCase($caseId: ID!) {
    getIncidentReportCase(caseId: $caseId) {
      ... on Case {
        ...Case
        subject {
          id
          firstName
          lastName
        }
        incidentReportConfig {
          instruction
          templateDocument {
            ...Document
          }
        }
      }
    }
  }
  ${DocumentFragment}
  ${CaseFragment}
`;

export const useGetIncidentReportCase = (options?: QueryHookOptions<IncidentReportCase, IncidentReportCaseVariables>) =>
  useQuery<IncidentReportCase, IncidentReportCaseVariables>(INCIDENT_REPORT_CASE_QUERY, options);
