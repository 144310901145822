import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { AdminSubjectFragment } from '../fragments/AdminSubjectFragment';
import { GetSubjectsNotInGroup, GetSubjectsNotInGroupVariables } from './__generated__/GetSubjectsNotInGroup';

const SUBJECTS_NOT_IN_GROUP_QUERY = gql`
  query GetSubjectsNotInGroup(
    $groupId: ID!
    $organizationId: ID!
    $paginationArgs: PaginationArgs
    $searchStr: String!
  ) {
    subjectsNotInGroup(
      groupId: $groupId
      organizationId: $organizationId
      paginationArgs: $paginationArgs
      searchStr: $searchStr
    ) {
      ...AdminSubject
    }
  }
  ${AdminSubjectFragment}
`;

export const useGetSubjectsNotInGroup = (options?) =>
  useQuery<GetSubjectsNotInGroup, GetSubjectsNotInGroupVariables>(SUBJECTS_NOT_IN_GROUP_QUERY, options);
