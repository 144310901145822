import * as React from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import Constants from 'expo-constants';
import { Notification } from './hooks/ui/useNotification/Notification';
import { IsConnected } from './shared/IsConnected';
import { InitializeSentry } from './shared/Sentry';
import { ErrorBoundary } from './shared/ErrorHandler/ErrorBoundary';
import { RootNavigator } from './RootNavigator';
import { Firebase, FirebaseAuth, useFirebase } from './shared/Firebase';
import { Ui } from './shared/Ui/Ui';
import { Loading } from './uiComponents';
import { AppSettings } from './shared/AppSettings';
import { CurrentUser } from './shared/CurrentUser';
import { InitializeFirebase } from './shared/Firebase';
import { appConfig } from './app.config';
import { InitializeApolloClient } from './shared/Apollo/MakeApolloClient';
import { InitialNavigationRoutes } from './shared/InitialNavigationRoutes';
import { Preloader } from './shared/Preloader';
import * as Sentry from "@sentry/react-native";
// import { Storybook } from './storybook/Storybook';
// import * as SplashScreen from 'expo-splash-screen';
// import {
//   useFonts,
//   Montserrat_400Regular,
//   Montserrat_700Bold,
//   Montserrat_500Medium,
// } from '@expo-google-fonts/montserrat';
import { Platform, AppState, View, Text } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { useIsAppUpdateRequired } from './hooks/useIsAppUpdateRequired';
import { AppUpdateModal } from './shared/AppUpdate/AppUpdateModal';

InitializeSentry();
InitializeFirebase(appConfig.firebase, appConfig.firebaseAuthEmulatorHost);

const apolloClient = () =>
  InitializeApolloClient(
    appConfig.graphqlEndpoint,
    () => {
      /* noop */
    },
    () => {
      /* noop */
    } );

const FirebaseContext = () => {
  const client = useApolloClient();

  const onLogin = () => {
    console.log('Login');
  };
  const onLogout = () => {
    console.log('Logout');
    client.resetStore();
  };

  const appState = React.useRef(AppState.currentState);
  const [isUpdateRequired, setIsUpdateRequired] = React.useState<boolean>(false);

  const versionNumber = DeviceInfo.getVersion();
  const { data, refetch: checkIfAppUpdateIsRequired } = useIsAppUpdateRequired({
    variables: {
      versionNumber,
    },
    skip: Platform.OS !== 'android' && Platform.OS !== 'ios',
  });

  React.useEffect(() => {
    const platform = Platform.OS;
    const subscription = AppState.addEventListener('change', async (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active' && platform !== 'web') {
        const res = await checkIfAppUpdateIsRequired();
        if (res?.data?.isAppUpdateRequired === true) {
          setIsUpdateRequired(true);
        }
        return res;
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (data && data.isAppUpdateRequired === true) || isUpdateRequired === true ? (
    <AppUpdateModal />
  ) : (
    <FirebaseAuth onLogin={onLogin} onLogout={onLogout}>
      <AppSettings>
        {/* <Ui> */}
        <Loading>
          <RootNavigator />
        </Loading>
        {/* </Ui> */}
      </AppSettings>
    </FirebaseAuth>
  );
};

const ApolloContext = () => {
  const { initialized, user } = useFirebase();
  const client = apolloClient();

  return (
    <ApolloProvider client={client}>
      <CurrentUser isReady={initialized} isLoggedIn={user ? true : false}>
        <InitialNavigationRoutes initialLoginRoute={{ name: 'LoggedIn_Home' }}>
          <FirebaseContext />
        </InitialNavigationRoutes>
      </CurrentUser>
    </ApolloProvider>
  );
};

export const App = Sentry.wrap(() => {
  const { extra } = Constants.expoConfig || {};

  const { storybook } = extra || { storybook: false };

  // // for some reason, storybook is creating issues loading fonts after upgrading our expo-font dependency.
  // // as far as I can tell it doesn't matter what version of storybook or @expo-google-fonts/montserrat we are on, at least as of the time of this comment.
  // // I expect this to be fixed once @storybook/react-native releases their 6.5 release, which should happen soon.
  // //
  // // uncomment this code to use as a workaround until this works again - this loads storybook without the right font but still works
  // // for some reason the app still crashes if I put this inside an if statement on condition `storybook` like it used to be.
  // // all this extra code is similar to what's inside <Preloader> btw, with some changes to get it working
  // SplashScreen.hideAsync();
  // let [fontsLoaded, error] = useFonts({
  //   Montserrat_400Regular,
  //   Montserrat_500Medium,
  //   Montserrat_700Bold,
  // });
  // if (error) console.error(error);
  // return fontsLoaded ? <Storybook /> : <Storybook />;

  // // this is the original storybook code that used to work
  // if (storybook) {
  //   return (
  //     <Preloader>
  //       <Storybook />
  //     </Preloader>
  //   );
  // }

  // // This is for debugging purposes: if the app is stuck on the splash screen, return this and keep commenting things out until you locate the error
  // return (
  //   <View>
  //     <Text>test text</Text>
  //   </View>
  // );

  return (
    <Preloader>
      <ErrorBoundary>
        <Firebase>
          <IsConnected>
            <ApolloContext />
          </IsConnected>
        </Firebase>
      </ErrorBoundary>
    </Preloader>
  );
});
