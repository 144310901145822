import { useOnNavigationFocus } from '../../../../../hooks/useOnFocus';
import { isApiError } from '../../../../../utils/isApiError';
import { useProfileContext } from '../../../ProfileContext';
import { useSubjectRecoveryPlan } from './useSubjectRecoveryPlan';
import { MyRecoveryPlan, MyRecoveryPlan_cases } from './__generated__/MyRecoveryPlan';
import { SubjectRecoveryPlan } from './__generated__/SubjectRecoveryPlan';
import { SubjectRecoveryPlanQuery } from './__generated__/SubjectRecoveryPlanQuery';
import { useGetUserRecoveryPlan } from './useGetUserRecoveryPlan';
import { useCurrentUser } from '../../../../../shared/CurrentUser/useCurrentUser';
import { RecoveryPlan } from '../fragments/__generated__/RecoveryPlan';

interface RecoveryPlanWithCases {
  recoveryPlanWithCases: {
    __typename: string;
    recoveryPlan: RecoveryPlan;
    cases: MyRecoveryPlan_cases[];
  };
}

const makeMyRecoveryPlan = (data: RecoveryPlanWithCases, currentUser): MyRecoveryPlan | null => {
  if (!data || (data.recoveryPlanWithCases && data.recoveryPlanWithCases.__typename !== 'RecoveryPlanWithCases')) {
    return null;
  }

  const recoveryPlanWithCasesAndUserInfo: MyRecoveryPlan = {
    __typename: 'CurrentUser',
    id: currentUser.id,
    fullName: currentUser.fullName,
    recoveryPlan: data.recoveryPlanWithCases.recoveryPlan,
    cases: data.recoveryPlanWithCases.cases,
  };

  return recoveryPlanWithCasesAndUserInfo;
};

const makeSubjectRecoveryPlan = (data: SubjectRecoveryPlanQuery): SubjectRecoveryPlan | null => {
  if (!data || isApiError(data.getSubject) || data.getSubject.__typename !== 'Subject') {
    return null;
  }

  return data.getSubject;
};

export const useRecoveryPlan = (): MyRecoveryPlan | SubjectRecoveryPlan | null => {
  const { subjectId } = useProfileContext();

  const myRecoveryPlan = useGetUserRecoveryPlan({
    skip: subjectId ? true : false,
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
  });

  const { currentUser } = useCurrentUser();

  const subjectRecoveryPlan = useSubjectRecoveryPlan({
    skip: !subjectId ? true : false,
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
    variables: {
      input: {
        subjectId: subjectId as string,
      },
    },
  });

  useOnNavigationFocus(() => {
    subjectRecoveryPlan.refetch();
    myRecoveryPlan.refetch();
  });

  if (!subjectId && (!currentUser || currentUser.__typename !== 'CurrentUser')) {
    return null;
  }

  if (myRecoveryPlan.data) {
    return makeMyRecoveryPlan(myRecoveryPlan.data, currentUser);
  } else if (subjectRecoveryPlan.data) {
    return makeSubjectRecoveryPlan(subjectRecoveryPlan.data);
  } else {
    return null;
  }
};
