export const english = {
  localeKey: 'en',
  translations: {
    language: {
      english: 'English',
      french: 'French',
    },
    label: {
      resume: 'Resume',
      done: 'Done',
      select: 'Select',
      cancel: 'Cancel',
      goBack: 'Go back',
      none: 'None',
      saveChanges: 'Save changes',
      submit: 'Submit',
      agreeTerms: {
        agreementPart1: 'I have read and accept the',
        termsAndConditions: 'Terms & Conditions',
        agreementPart2: 'for purchasing CAPT.',
        modalTitle: 'CAPT TERMS AND CONDITIONS',
      },
      next: 'Next',
      correct: 'Correct',
      incorrect: 'Incorrect',
      remove: 'Remove',
      call: 'Call',
      downloadTemplate: 'Download Template',
      uploadAssessment: 'Upload Assessment',
      medicalAssessment: 'Medical Assessment',
      approve: 'Approve',
      reject: 'Reject',
      reason: 'Reason',
      view: 'View',
      stepForward: 'Advance',
      stepBack: 'Step back',
      completeVerb: 'Complete',
      completeAdjective: 'Complete',
      uploadDocument: 'Upload Document',
      create: 'Create',
      name: 'Name',
      update: 'Update',
      quit: 'Quit',
      delete: 'Delete',
      reactivate: 'Reactivate',
    },
    firebaseAuthErrors: {
      invalidPassword: 'The password you entered was incorrect. Please try again.',
      userNotFound: 'That user could not be found. Please try again.',
      invalidPasswordOrNouser: 'Incorrect email address or password',
    },
    navigation: {
      superAdmin: 'Sales',
      home: 'Home',
      cases: 'Cases',
      training: 'Training',
      myAccount: 'My Account',
    },

    time: {
      present: 'present',
    },

    placeholder: {
      phone: 'Phone number',
      email: 'Email address',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      passwordExisting: 'Current password',
      passwordNew: 'New password',
      passwordNewConfirm: 'Confirm new password',
      createPassword: 'Create password',
      firstName: 'First name',
      lastName: 'Last name',
      smsCode: '123123',
      searchPlace: 'Search for places...',
      dateOfBirth: 'Date of birth',
    },

    removeFromSport: 'Remove',

    flash: {
      title: {
        messageSent: 'Message sent',
        passwordChanged: 'Updated',
        passwordNotChanged: 'Password update failed',
      },
      message: {
        checkYourEmail: 'Check your email for instructions',
        passwordChanged: 'Your password has been updated',
      },
    },

    subjectStatus: {
      modalTitle: 'Status',
      organizationStatusMessage: '%{status} Within %{organization}',
      active: 'Active',
      onProtocol: 'On Protocol',
      removed: 'Removed',
      complex: 'Multiple',
    },

    violator: {
      noConnection: 'No internet connection',
    },

    error: {
      title: {
        generic: 'Error',
        authNoPassword: 'Missing required field',
      },
      message: {
        cantBeEmpty: 'This field cannot be empty',
        authNoFirstName: 'Please provide your first name',
        authNoLastName: 'Please provide your last name',
        authNoEmail: 'Please provide an email address',
        inviteSubjectNoEmail: 'Please add an email address or create a guardian account',
        authNoPassword: 'Please provide a valid password',
        authNoPasswordConfirm: 'Please provide a confirm password',
        authNoBirthday: 'Please provide a birthday',
        authPasswordConfirmNotMatch: 'Those passwords did not match. Try again',
        authNoCode: 'Please enter the code',
        authFieldsRequired: 'Please ensure that all fields are filled in.',
        existingPassword: 'Your current password is incorrect.',
        unkownError: 'Unknown error.',
        auth: {
          invalidEmail: 'Please provide a valid email address.',
          tooManyRequests: 'Too many unsuccessful login attempts. Please try again later.',
          invalidPhoneNumber: 'Please ensure that you are providing a valid phone number.',
        },
        missingProtocolForSubject: 'Could not identify a protocol to run for this participant.',
        mustSelectOption: 'You must select one of these options',
      },
    },

    errorBoundary: {
      title: 'An error has occurred',
      message: 'An unknown error has occured. Please reload the app to try again',
      reloadButton: 'Reload',
    },
    validation: {
      validUrl: 'Enter a valid URL',
      validPassword: 'Enter a valid password',
      matchingPasswords: 'Passwords must match',
      requiredField: 'This is a required field',
      password: {
        uppercase: '%{num} uppercase',
        lowercase: '%{num} lowercase',
        number: '%{num} number',
        symbol: '%{num} symbol',
        length: '%{num} characters',
      },
      invalidVerificationCode: 'Invalid verification code',
    },

    privacyPolicy: {
      link: 'https://captcares.com/privacy-policy/',
      readAndAgree1: 'Please read and accept our ',
      readAndAgree2: 'privacy policy.',
    },

    auth: {
      title: {
        signUp: 'Sign up',
        phoneLogin: 'Confirm\nyour identity',
        resetPassword: 'Reset password',
        privacyPolicy: 'Privacy Policy',
      },
      changePassword: {
        title: 'Password Changed',
        success: 'You have sucessfully changed your password. You will now be taken to the login screen to sign in',
        emailSent:
          'An email has been sent to the provided email address with instructions on how to reset your password',
        errorTypes: {
          expiredActionCode:
            'The link used to reset your password has expired. Please submit your email to reset your password again',
          invalidActionCode: 'Invalid action code. Please try again',
          userDisabled: 'Error: This user account has been disabled',
          userNotFound: 'Error: User not found',
          weakPassword: 'The new password does not meet the minimum password requirements',
        },
      },
      message: {
        codeSent:
          '<b>A code was just texted to (***) ***-%{digits}</b>. Please enter it below to verify your identity.',
      },
      term: {
        signIn: 'Sign in',
        createAccount: 'Create account',
        forgotPassword: 'Forgot password?',
        resetPassword: 'Reset password',
        resendCode: 'Resend code?',
        iAccept: 'I Accept',
        inPartnershipWith: 'In partnership with',
      },
    },

    subjectList: {
      organizations: 'Organization',
      organizationList: {
        other: '+%{extra} other',
        others: '+%{extra} others',
      },
      subjectData: {
        allGroups: 'All groups',
        groupLabel: 'Group',
        groups: 'groups',
        noUsersFromSearch: 'There are no participants matching your search criteria',
        endOfList: 'You have reached the end of the list of users. Press the back button to return to the list',
        noSubjectsInOrganization: 'There are no participants in this organization',
        noKidsInOrganization: 'You have no kids in this organization',
        noSubjectsOnProtocol: 'There are no participants on protocol in this organization',
      },
    },

    caseLog: {
      open: 'Open',
      closed: 'Closed',
      caseLogs: 'Case Logs',
      activeCases: 'Active Cases',
      caseItemCaseStatusChange: {
        closed: 'Case %{caseId} closed for %{subject}',
        opened: 'Case %{caseId} opened for %{subject}',
      },
      caseItemMedicalAssessmentReviewApproved: {
        message: '%{actor} reviewed and approved medical assessment document',
      },
      caseItemMedicalAssessmentReviewRejected: {
        message: '%{actor} reviewed and rejected medical assessment document for reason: %{rejectionReason}',
      },
      caseItemMedicalAssessmentSubmission: {
        message: '%{actor} submitted a medical assessment document indicating %{diagnosis}',
        concussion: 'concussion',
        noConcussion: 'no concussion',
      },
      caseItemRfaProtocolResult: {
        removeDescription: '%{actor} removed %{subject} from activity',
        resumeDescription: '%{actor} allowed %{subject} to resume activity',
        date: 'Date',
        incidentDescription: 'Incident Description',
        noneProvided: 'None Provided',
        runBy: 'Protocol Run By',
        outcome: 'Outcome',
        outcomeDescription: 'Protocol suggested: %{suggestedResult}\n%{actor} selected: %{selectedResult}',
        observedRedFlags: 'Observed red flags: %{redFlags}.',
        noRedFlags: 'No red flags were observed.',
        viewIncidentReport: 'Report',
        attachedDocument: 'Attached Document',
        download: 'Download document',
        close: 'Close',
        noObservedSymptoms: 'No symptoms were observed.',
        observedSymptoms: 'Observed symptoms: %{symptoms}.',
        noFailedChallenges: 'No challenges were responded to incorrectly.',
        failedChallenges: 'Incorrect challenge responses: %{challenges}.',
        protocolConcluded: 'Protocol concluded before reaching this stage.',
      },
      caseItemRtaProtocolCompletion: {
        message: '%{actor} marked %{subject} as having completed the %{protocol} protocol',
        fastTracked:
          '%{user} marked %{subject} as having completed the %{protocol} protocol before the minimum required time of %{stepDefinedWaitTime} %{hourOrHours}',
        themself: 'themself',
      },
      caseItemRtaProtocolStageChange: {
        other: '%{actor} approved %{direction} for %{subject} to %{stage} of the %{protocol} protocol',
        self: '%{actor} %{direction} themself to %{stage} of %{protocol} protocol',
        advance: 'advance',
        fastTracked:
          '%{user} advanced %{participant} to %{stageName} before the minimum required time of %{stepDefinedWaitTime} %{hourOrHours}',
      },
      caseItemRtaProtocolStageDocumentReviewApproved: {
        message: '%{actor} reviewed and approved submitted document for stage %{stage} of %{protocol} protocol',
      },
      caseItemRtaProtocolStageDocumentReviewRejected: {
        message:
          '%{actor} reviewed and rejected submitted document for stage %{stage} of %{protocol} protocol for reason: %{rejectionReason}',
      },
      caseItemRtaProtocolStageDocumentSubmission: {
        message: '%{actor} submitted document for stage %{stage} of %{protocol} protocol',
        fastTracked:
          '%{actor} submitted document for stage %{stage} of %{protocol} protocol before the minimum required time of %{stepDefinedWaitTime} %{hourOrHours}',
      },
      caseItemSubjectStatusChange: {
        message: '%{subject} status changed to %{status}',
      },
      protocolVersion: 'version from %{creationDate}',
    },

    teamRoster: {
      noActiveCasesTitle: 'No Active Cases',
      noActiveCasesMessage:
        'There are no active cases. If a participant has a suspected concussion, the case will appear here.',
    },

    wrongUser: {
      screenTitle: 'Wrong User',
      title: 'This link is for another user.',
      message: `You are currently logged in as %{currentEmail} but this link is for another user.\n\nYou must logout to continue.`,
      logout: 'Logout',
    },

    recovery: {
      index: {
        returnToLearn: 'Return To Learn',
        returnToSport: 'Return To Sport',
        remaining: 'Upcoming',
        current: 'Currently Active',
        inactive: 'Inactive',
        pending: 'Pending',
        awaitingReview: 'Awaiting review',
        rejected: 'Rejected',
        resumeMessage: {
          title: 'Recovery Plan Completed',
          concussionInstruction: '%{fullName} has completed the recovery plan and is clear to resume activity.',
          noConcussionInstruction: '%{fullName} is clear to resume activity.',
          returnLink: 'Return to profile',
        },
        noActiveCasesMessage: '%{fullName} has no active cases.',
      },
      submitMedicalAssessment: {
        medicalAssessment: 'Medical assessment',
        diagnosis: 'Diagnosis',
        concussion: 'Concussion',
        noConcussion: 'No Concussion',
        documentsSubmitted: 'Thanks, your document has been submitted.',
        notAuthorized: 'You are not authorized to submit an assessment.',
      },
      reviewMedicalAssessment: {
        rejectionReason: 'Rejection Reason',
        resumingActivity: '%{name} is resuming acitivity',
        onProtocol: '%{name} is on protocol',
        notAuthorized: 'This assessment is awaiting review.',
      },
      rejectionReason: {
        title: 'The previously submitted document was rejected.',
        titleWithReason: 'The previously submitted document was rejected for the following reason: %{reason}',
        resubmit: 'Please ensure you follow the requirements and resubmit.',
      },
      stageSignoff: {
        subtitle: '%{organization} - %{protocol}',
        notAuthorized: 'You are not authorized to signoff on this stage.',
        timeGateRestriction: 'Should not advance until %{date}',
        timeGateHours: 'Duration: at least %{hours} %{hourOrHours}',
        hours: 'hours',
        hour: 'hour',
      },
      stageDocumentSubmission: {
        notAuthorized: 'You are not authorized to submit this document.',
      },
      stageDocumentReview: {
        notAuthorized: 'This document is awaiting review.',
        document: 'Document',
      },
      fastTrackWarning: {
        title: 'You should not advance until it has been %{numberOfHours} %{hourOrHours}',
        content:
          'Advancing through steps early is a violation of the concussion protocol. This will be recorded and reported. Please ensure that enough time has passed on this step before advancing.',
      },
      incompleteIncidentReport: {
        title: 'Incident Report Incomplete',
        content:
          'The incident report must be completed by the person who created the case before the participant can be returned to activity.',
      },
    },

    login: {
      invitationNotice: {
        invitationMessage: {
          message: `Hi %{firstName},\n\nYou've been invited to join %{organization} on CAPT.\n\n%{organization} uses CAPT to ensure everyone follows their concussion protocol.\n\nPlease login as %{email} to accept this invitation.`,
        },
      },
    },

    register: {
      invitationNotice: {
        invitationMessage: {
          message: `Hi %{firstName},\n\nYou’ve been invited to join %{organization} on CAPT.\n\nWe use CAPT to ensure everyone understands and follows our concussion protocol.\n\nYou need to register for a CAPT account for "%{email}". Signup to continue.`,
        },
      },
    },

    verified: {
      title: 'Account verified',
      subtitle: 'Thanks for verifying your account.',
      loggedIn: {
        description: `You're all set to start using CAPT.`,
        continue: 'Continue',
        stillNotVerified: 'Sorry, but your account still does not appear to be verified. Please logout and try again.',
      },
      loggedOut: {
        description: 'In order to continue please login with your email and password.',
        login: 'Login',
      },
    },

    invitation: {
      home: {
        title: 'Invitation',
      },
      invitationNotFound: {
        title: 'Invitation not found',
        message:
          'This invitation is invalid or expired. Please check the link and try again. If you received multiple invitations, make sure to follow the link in the most recent email. If you are still not able to accept the invite please contact the organization you are trying to join.',
        goHome: 'Go Home',
      },
    },

    recognize: {
      title: 'Concussion Recognition',
      exitModal: {
        title: 'Are you sure?',
        content: 'Your progress will be lost if you choose to continue.',
      },
    },

    loggedIn: {
      chrome: {
        notVerified: {
          title: 'Verify your email',
          description:
            'Before you can use CAPT you must verify your email address by clicking on the link in the email we sent to %{email}.',
          trouble: `If you haven't received this email click the button below to resend it. Be sure to check your spam folder.`,
          resend: `Resend verification email`,
          success: 'The verification email has been sent.',
          successDescription:
            "Please click the link in the email that was sent to you in order to verify your account. If you don't see an email from us be sure to check your spam folder.",
          ok: 'Ok',
        },
      },
    },

    training: {
      trainingReport: 'Training Report',
      trainingResources: 'Education & Training Resources',
      trainingAndEducation: 'Training & Education',
      label: {
        report: 'Organization Training Report',
        resources: 'Education & Training Resources',
        designatedPersonsTrained: 'of concussion managers and group leaders trained',
        completed: 'completed',
        notCompletedForWeb: 'not completed',
        notCompletedForMobile: 'not \ncompleted',
      },
      reportUsersEmpty: 'There are no users in this organization yet.',
      disclaimer: {
        part1: '* The data displayed reflects what has been reported in CAPT. For further validation, go to the ',
        part2: 'Coaching Association of Canada ',
        part3: 'website to view an individual’s public transcript.',
      },
      trainingMaterials: 'Organization Training Materials',
      makingHeadWay: {
        title: 'Making Head Way - Coaching Association of Canada',
        trainingProgram: 'NCCP Making Head Way',
        description:
          'Making Head Way is a free education program for sports coaches & anyone else who wants to learn how to respond to a concussion during sports. There is one version applicable to any sport, and specific versions tailored to Soccer, Football, Snowboard, Freestyle Ski, and Speed Skating.',
        instructions: {
          step1:
            'To access Making Head Way, you will need to create an account with the Coaching Association of Canada in their portal, "The Locker".',
          step2:
            'CAPT can track your completion of Making Head Way. If you have completed your training, please log in to The Locker and find your NCCP number on your profile page.',
          step3: 'Then, enter your NCCP number here:',
        },
        links: {
          trainingSite: 'https://coach.ca/making-head-way-concussion-elearning-series',
        },
        button: {
          label: 'Go to Making Head Way',
        },
        checkbox: [
          {
            label: 'I have completed this training',
            value: true,
          },
        ],
      },
      nccpNumberLabel: 'NCCP Number',
      trainingCompleted: 'You have indicated you have completed this training',
      downloadDocument: 'Download Document',
      noUsers: 'This organization has no users',
      noUsersFound: 'There are no users in this organization that match your search criteria',
    },
    education: {
      educationReport: 'Education Report',
      label: {
        resources: 'Organization Education Report',
        completed: 'reviewed',
        notCompleted: 'not reviewed',
        usersEducated: 'of users have reviewed education items',
        nudgeAllNotReviewed: 'Nudge All Not Reviewed',
      },
    },

    home: {
      greeting: 'Welcome, %{name}',
      tile: {
        recoveryPlan: 'Recovery Plan',
        recognize: 'Recognize a concussion',
        recognizeMobile: 'Recognize',
        actionItems: 'Action Items',
        teamRoster: 'Team Roster',
        myKids: 'My Kids',
        myProfile: 'My Profile',
        training: 'Training & Education',
      },
    },

    actionItems: {
      title: 'Action Items',
      pendingMedicalAssessmentActionItem:
        'A medical assessment for %{subject} in %{organization} is awaiting your review.',
      pendingDocumentSubmissionActionItem: 'A document for %{subject} in %{organization} is awaiting your review.',
      pendingInvitationActionItem: {
        message: `You've been invited to join %{organization}.`,
      },
      pendingEducationItemResubmission: {
        message: `Please review %{organization}'s concussion education resources`,
        modalMessage: 'Complete these education items to continue using CAPT',
      },
      protocolApprovalActionItem: `The concussion protocol must be reviewed & approved before %{organization} can begin using CAPT.`,
      incompleteIncidentReport: 'You must complete the incident report for %{name} from %{date}',
    },

    reviewInvitation: {
      title: `You've been invited!`,
      invitationNotFound: {
        title: 'Invitation Not Found',
      },
      rightUser: {
        title: `You've been invited`,
        messageUserRole: `You've been invited to join %{organization} on CAPT.\n\nWe use CAPT to ensure that individuals responsible for the safety of participants in our activities understand and follow our concussion protocol.\n\n`,
        termsAndConditions: 'To proceed please confirm you have reviewed the terms and conditions.',
        acceptButton: 'Accept',
        youMustAcceptAllPolicies: 'You must accept all policies to continue',
        accepted: 'Thanks for joining %{organization}!',
      },
      wrongUser: {
        title: `This invitation is for another user.`,
        message: `You are currently logged in as %{currentEmail} but this invitation is for another user.\n\nYou must logout to continue.`,
      },
    },

    protocolAttribution: {
      providedBy: 'Protocol by:',
    },

    emergency: {
      callNumber: 'Call %{number}',
      isEmergency: 'Treat as emergency',
      title: 'Emergency',
    },

    emergencyContact: {
      contactPhoneNumber: 'Phone number: %{phoneNumber}',
      guardians: 'Guardians',
      noContactsFound: 'No Emergency Contacts Found',
    },

    chooseOrganizationProtocol: {
      chooseOrganizationProtocol: 'Select a protocol',
      organization: 'You and %{name} have several organizations in common.\n\nSelect the organization you want to use:',
      alreadyRemoved: 'Already removed',
    },

    step: {
      shouldBeRemoved: '%{name} should be removed from activity',
      reasonTomRemove: 'Any reason to remove %{name} from activity?',
    },

    stepChallenges: {
      question: 'QUESTION %{n} of %{of}',
    },

    incidentReport: {
      title: 'Incident Report',
      incidentDescription: 'Incident description',
      subjectRemovedFromActivity: '%{name} is removed from activity',
      subjectResumingActivity: '%{name} is resuming activity',
      uploadFile: 'Upload File',
      instructions: 'You can come back to finish and submit this report later if needed.',
      skipForNow: 'Skip for now',
      dateError: 'You must select a date from the past',
      incidentDate: 'Incident Date',
      hour: 'Hour',
      minute: 'Minute',
      locationFacility: 'Incident Location / Facility',
      incidentType: 'Incident Type',
      incidentTypes: {
        collisionWithGround: 'Collision with ground',
        collisionWithMovingObject: 'Collision with moving object',
        collisionWithPerson: 'Collision with person',
        collisionWithStaticObject: 'Collision with static object',
        motorVehicleCollision: 'Motor vehicle collision',
        unknown: 'Unknown',
      },
      detailsHeader: 'Incident Details',
      incidentDetails: 'Please describe the incident',
      pleaseList: 'Please list',
      pleaseDescribe: 'Please describe',
      howMany: 'How many?',
      otherSymptomsSection: {
        title: 'Other injuries or symptoms',
        question1: 'Are there any other observable or reported symptoms?',
        question2: 'Is there injury to anywhere else? (besides head)',
      },
      concussionHistory: {
        title: 'Concussion history',
        question1: 'Has %{firstName} had a concussion before?',
      },
      medicalHistory: {
        title: 'Medical history',
        question1: 'Does %{firstName} have any pre-existing medical conditions?',
        question2: 'Does %{firstName} take any medications?',
      },
      reportForm: 'Incident report form',
      attachFile: 'Attach File',
      symptoms: 'Symptoms',
      signs: 'Signs',
      other: 'Other injuries or symptoms',
      author: 'Author',
      reportSubmitted: 'Report Submitted',
      form: 'Incident Report Form',
      birthDate: 'Birth date',
      observableSigns: 'Observable signs',
      otherSymptoms: 'Other symptoms',
      memoryAssessment: 'Memory assessment',
      otherInjuries: 'Other injuries',
      previousConcussions: 'Previous concussion(s)',
      existingMedicalConditions: 'Existing medical conditions',
      existingMedications: 'Existing medications',
      phone: 'Phone',
      removedFromActivity: 'Removed from activity',
      resumedActivity: 'Resumed activity',
      location: 'Location',
      notReported: 'Not reported',
      incidentTypeLowerCase: 'Incident type',
      details: 'Details',
      printReport: 'Print Report',
      incorrectResponses: 'Incorrect responses',
      subjectName: 'Participant: %{subjectName}',
    },

    protocol: {
      tiles: {
        recognize: 'Recognize a Concussion',
        remove: 'Remove From Activity: Suspected Concussion',
        report: 'Report a Concussion',
      },
      removeModal: {
        title: 'Remove From Activity',
        content1:
          'You are changing %{subjectName}’s status to Removed. This status is for subjects who have a suspected concussion and have been removed from activity.',
        content2: 'A medical assessment is needed to confirm the diagnosis.',
        content3: 'An incident report must be submitted to complete the case.',
      },
      mustApprove:
        'You must review the content of this page and agree that it is correct before your organization can use CAPT.',
      protocolName: 'Protocol Name',
      link: 'Link to Protocol Document',
      education: 'Concussion Education',
      educationText: 'Users must review and acknowledge these items upon joining the organization in CAPT.',
      shownTo: 'Shown to',
      noOne: 'no one',
      removal: 'Removal Protocol',
      redFlags: 'Red Flags',
      removalInstructions: 'Instructions for Removal',
      emergencyInstructionDescription:
        'Instruction to the designated person in an emergency when red flags have been detected & the subject needs emergency care:',
      discretionaryInstructionDescription:
        'Instruction to the designated person when removal decision is discretionary (no symptoms detected):',
      removeInstructionDescription:
        'Instruction to the designated person shown after decision to remove – include instructions for what the subject is not allowed to do:',
      resumeInstructionDescription: 'Instruction to the designated person shown after decision to continue activity:',
      incidentReport: 'Incident Report',
      incidentReportText:
        'An incident report containing any notes from the designated person and/or an uploaded document is filled out at the end of this protocol.',
      incidentReportInstructionDescription: 'Instruction for filling out incident report:',

      downloadTemplate: 'Download Template Document',
      noTemplateDocument: 'Template document: none',
      return: 'Return to Activity Protocols',
      returnText:
        'Each time a participant advances to a new step in a return protocol, an email notification is sent out to the participant, their guardians, the group leaders responsible for them, and the concussion manager for the organization.',
      medicalAssessmentApproval: 'This document requires approval by the primary designated person.',
      returnToLearn: 'Return to Learn Protocol',
      returnToSport: 'Return to Sport Protocol',
      returnStep: {
        anyone: 'Anyone',
        pdp: 'Only primary designated person',
        documentPermission: 'can upload document',
        signoffPermission: 'can advance',
        minimum: 'Minimum ',
        hours: '%{numberOfHours} %{hourOrHours}',
      },
      documentApproval: 'Requires approval of submitted document from primary designated person',
      approval: `I have reviewed my organization's concussion protocol(s) in CAPT, and acknowledge that they are correct.`,
      approvalConfirmation: 'Protocol Approved: Your organization can now use CAPT.',
    },

    profile: {
      heading: {
        personal: 'Personal Information',
        emergencyContact: 'Emergency Contact',
        history: 'Case History',
      },
      term: {
        organization: 'Organization',
      },
    },

    myProfile: {
      title: 'Profile',
      profileHeader: {
        recovery: 'Recovery',
        log: 'Case Log',
        circleOfCare: 'Circle of Care',
      },
      memberships: {
        groups: 'Groups',
      },
      activeCases: {
        stageNumberDetails: '(stage %{latestStage} of %{numberOfStages})',
      },
      yearsOld: 'years old',
    },

    settings: {
      title: 'Settings',
      term: {
        updatePassword: 'Update password',
      },
      multiFactor: {
        enterPassword: 'Enter your password to disable multi-factor authentication on your account',
      },
      changePassword: {
        title: 'Change\npassword',
        requirements:
          'Passwords must include an uppercase letter, a lowercase letter, a number, a symbol and be at least 6 characters long',
      },
    },

    account: {
      title: 'My Account',
      pickImage: 'Pick an image',
      yourDetails: {
        birthday: 'Birthday',
        email: 'Email',
        phoneNumber: 'Phone number',
        changePassword: 'Change password',
        passwordsDoNotMatch: 'Passwords do not match',
        allPasswordFieldsRequired: 'You must fill in all password fields to update your password',
        language: 'Language',
      },
      save: 'Save',
    },

    users: {
      manageUsersTitle: 'Users',
      participationReportTitle: 'Participation Report',
      participants: 'users',
      accepted: 'Accepted',
      admin: 'admin',
      allDesignatedPersons: 'all designated persons',
      subjects: 'subjects',
      guardians: 'guardians',
      nudgedAlert: '%{total} users nudged',
      roles: {
        admin: 'Administrator',
        primaryDesignatedPerson: 'Concussion Manager',
        secondaryDesignatedPerson: 'Group Leader',
        subject: 'Participant',
        guardian: 'Guardian',
      },
      rolesPlural: {
        admin: 'Administrators',
        primaryDesignatedPerson: 'Concussion Managers',
        secondaryDesignatedPerson: 'Group Leaders',
        subject: 'Participants',
        guardian: 'Guardians',
      },
      sectionHeaders: {
        guardians: 'Guardian(s)',
        addToGroups: 'Add to Groups',
        addUserToGroups: 'Add User to Groups',
      },
      selectGroups: {
        selectGroups: 'Select groups',
        selectAGroup: 'Select a Group',
      },
      addUser: {
        title: 'Add User',
        invite: 'Invite',
        guardians: 'Guardian %{index}',
        addGuardian: 'Add Guardian',
      },
      userDetails: {
        title: 'Edit User',
        invite: 'Invite User',
        noUsers: 'There are no users. Click "Add" to invite your first user.',
        removeRoleConfirmModal: {
          title: 'Remove role',
          message: `Are you sure you want to remove the %{role} role from %{name}?`,
        },
        nudgeRoleConfirmModal: {
          title: 'Send a nudge email',
          message: `%{name} has not activated their account.`,
          send: 'Send',
        },
        userHasNoGuardians: 'This user has no guardians',
        sort: 'Sort',
        role: 'Role',
        searchByName: 'Search by name...',
        nudgeAllPending: 'Nudge All Pending',
        resendEducationItems: 'Resend Education',
        resendEducationConfirmation:
          "Press 'Resend Education' to require all users to complete the education items. They will not be able to use CAPT until they have completed them.",
        educationItemsResent: 'Education Items Resent',
        educationItemsResentDetails:
          'Education items have been successfully resent to all users. They will need to complete those items again before they can use CAPT.',
        deleteUserConfirmationTitle: 'Are you sure you want to delete this user?',
        deleteUserConfirmationContent:
          '%{userName} will no longer be visible to other CAPT users in this organization.',
      },
    },

    seasons: {
      title: 'Seasons',
      createSeason: 'Create season',
      startDate: 'Start date',
      endDate: 'End date',
      noSeasons: 'No seasons',
    },

    admin: {
      title: 'Admin',
      heading: {
        manageUsers: 'Manage Users',
        manageSeasons: 'Manage Seasons',
        settings: 'Organization Settings',
      },
      label: {
        manageUsers: 'Manage users',
        manageSeasons: 'Manage seasons',
        manageProtocol: 'Manage protocol',
        manageGroups: 'Manage groups',
        userLocationTracking: 'Enable user location tracking',
        demoModeAvailable: 'Offer demo mode',
        homeTiles: 'Home screen tiles',
        telemedicineProviders: 'Telemedicine providers',
        disabledServices: 'Disabled services',
        triageOnly: 'Provide triage only',
        manageOrganization: 'Manage organization',
        viewProtocol: 'View Protocol',
        selectOrganization: 'Select organization',
      },
      manageSeasons: {
        seasonDetails: {
          groups: 'Groups:',
          noGroups: "There aren't any groups in this season.",
          addGroup: 'Add group',
          deactivateGroupModal: {
            title: 'Deactivate Group',
            content: 'Are you sure you want to deactivate the group',
            deactivate: 'Deactivate',
          },
          updateGroupModal: {
            title: 'Update Group',
          },
          createGroupModal: {
            title: 'Create Group',
            groupName: 'Group Name',
            groupAlreadyExistsTitle: 'Group Already Exists',
            inactiveGroupAlreadyExistsContent:
              'There already exists a group named %{group} that was previously deactivated. Would you like to reactivate that group?',
            activeGroupAlreadyExistsContent:
              'A group named %{group} already exists. Please use that one or give the two groups different names',
          },
        },
        addGroup: {
          title: 'Add Group',
          invitePlaceholder: 'Name or email',
          saveGroup: 'Save group',
        },
        groupDetails: {
          invited: 'Invited',
          invitePlaceholder: 'Start typing...',
          subjects: 'Participants',
          addDesignatedPerson: 'Add Group Leader',
          addSubject: 'Add Participants',
          noSubjects: 'This group has no participants. Click "Add" to invite your first participant.',
          noSubjectsFound: 'There are no participants in this group that match your search criteria',
          endOfSubjectsList:
            'You have reached the end of the list of participants. Press the back button to go back to the previous page',
          noDesignatedPersons: 'This group has no group leaders. Click "Add" to invite your first group leader.',
          noDesignatedPersonsFound: 'There are no group leaders in this group that match your search criteria',
          endOfDesigantedPersonsList:
            'You have reached the end of the list of group leaders. Press the back button to go back to the previous page',
          inviteInstruction: 'Enter a name to add someone in the organization to this group.',
          inviteOrSearchInstruction:
            'Enter an email to invite someone new or enter a name to find someone already added to this season.',
          createUser: 'Create User',
          createSubject: 'Create Participant',
          createDesignatedPerson: 'Create Group Leader',
        },
      },
      manageOrganization: {
        organizationNameInputLabel: 'Organization Name',
        typeLabel: 'Type',
        sportLabel: 'Sport',
        protocolLabel: 'Protocol',
        medicalEmergencyPhoneNumber: 'Medical Emergency Phone Number',
        medicalEmergencyNameLabel: 'Name (eg. Campus Emergency Services)',
        medicalEmergencyPhoneLabel: 'Phone Number',
        updateOrganizationBtnLabel: 'Update Organization',
        ok: 'OK',
        successfullyUpdated: '%{organizationName} has been successfully updated',
        modalTitle: 'Organization Updated',
      },
      manageProtocol: {
        title: 'Manage Protocol',
        label: 'Label',
        value: 'Value',
        index: {
          instruction: 'Select a section of the protocol to edit:',
          sectionGeneral: 'General Details',
          sectionEducation: 'Education Protocol',
          sectionRemoval: 'Removal Protocol',
          preseasonEducationItems: 'Pre-Season Education Items',
        },
        documentSelector: {
          clear: 'Clear',
          downloadCurrent: 'Download Current',
        },
        successTitle: 'Protocol Saved',
        successDescription: 'Open cases continue to follow the protocol active when they were created.',
        instruction: 'Instruction',
        selectTemplateDocument: 'Select Template Document',
        templateDocument: 'Template Document',
        updateProtocolConfigForm: {
          overview: {
            title: 'Protocol Details',
            instruction1: 'Provide the name and website of the organization that developed your protocol.',
            instruction2: "If you developed your own protocol use your organization's name and website.",
            protocolName: 'Protocol Source',
            protocolUrl: 'Protocol URL',
          },
          education: {
            instruction: 'Define the items that your users should review and acknowledge upon joining a new season.',
            item: {
              selectDocument: 'Upload New Document',
              downloadCurrentDocument: 'Download Current Document',
              instruction: 'Item Template',
              showToDesignatedPersons: 'Show to invited concussion managers and group leaders',
              showToGuardians: 'Show to invited guardians',
              showToSubjects: 'Show to invited participants',
              preview: 'Preview',
              missingDocument: 'You must attach a document',
              url: 'URL',
              resourceType: 'Resource Type',
            },
            addNew: 'Add New Item',
          },
          remove: {
            instruction:
              'Define the steps that concussion managers and group leaders should follow when making a removal from activity decision.',
            addNewStep: 'Add New Step',
            emergencyInstruction: 'Emergency Instruction',
            removeInstruction: 'Remove Instruction',
            resumeInstruction: 'Resume Instruction',
            discretionaryInstruction: 'Discretionary Instruction',
            redFlag: {
              flags: 'Flags',
              addNewRedFlag: 'Add New Red Flag',
            },
            steps: 'Steps',
            step: {
              challenge: 'Challenge',
              stepType: 'Step Type',
              symptom: 'Symptom',
              addSymptom: 'Add Symptom',
              addChallenge: 'Add Challenge',
            },
          },
          return: {
            title: 'Return Protocol',
            instruction: 'Define the protocols that must be followed to allows participants to return to activity.',
            returnToLearn: 'Return to Learn',
            returnToSport: 'Return to Sport',
            childProtocol: {
              enable: 'Enable',
              disable: 'Disable',
            },
            medicalAssessment: {
              instruction: 'Instructions',
            },
            addNewStage: 'Add New Stage',
            stage: {
              description: 'Description',
              documentSubmission: 'Document Submission',
              signoff: 'Signoff',
              stageType: 'Stage Type',
              authorizedRoleSignoff: 'Who can signoff on this stage?',
              authorizedRoleDocumentSubmission: 'Who can submit a document for this stage?',
            },
          },
          update: 'Save Changes',
          reset: 'Reset',
          goBack: 'Go Back',
        },
        timeGateOption: {
          askForTimeGating: 'Do you want to require a minimum time spent on this step?',
          minimumHours: 'Minimum hours at this step',
          nonIntegerErrorMessage: 'This needs be a positive whole number',
          emptyErrorMessage:
            "This field cannot be empty. Select 'no' for the option above if you do not want to set a minimum number of hours",
        },
      },
      settings: {
        title: {
          orgAdmin: 'Organization settings',
          superAdmin: 'Admin settings',
        },
        term: {
          orgSettings: 'Basic organization settings',
          adminSettings: 'Core organization settings',
        },
        hint: {
          feedbackChannel: 'Feedback channel can be a URL or an email address',
          supportEmail: 'Email address where users can send support queries',
          minimumAge: 'Minimum age required to use the app',
          demoModeAvailable: 'Whether users can enable demo mode to test medical assistance features',
          disabledServices: 'Prevent the following services from being offered through medical assistance',
          triageOnly: 'Only offer triage services through medical assistance',
        },
      },
    },

    superAdmin: {
      createOrganization: {
        title: 'Create Organization',
        isPDP: 'Make admin also a concussion manager',
        successfullyCreated: 'The organization %{organization} has been successfully created',
        sucessNotification: 'New organization has been created.',
        modalTitle: 'Organization Created',
        protocolPreset: 'Protocol Preset',
        protocolPresets: {
          blank: 'Blank',
          ontarioSoccerAssociation: 'Ontario Soccer Association',
          ontarioHockeyAssociation: 'Ontario Hockey Association',
          parachuteSmartHockey: 'Parachute Smart Hockey',
          ontarioProtocolForSchools: 'Ontario Protocol For Schools',
          parachuteProtocolForSchools: 'Parachute Protocol For Schools',
        },
        organizationType: 'Organization Type',
        organizationTypes: {
          sportsOrganization: 'Sports Organization',
          school: 'School',
        },
        createAdmin: 'Create Admin',
        sports: {
          other: 'Other',
          baseball: 'Baseball',
          basketball: 'Basketball',
          fieldHockey: 'Field Hockey',
          hockey: 'Hockey',
          soccer: 'Soccer',
          rugby: 'Rugby',
          athletics: 'Athletics',
          equestrian: 'Equestrian',
          tennis: 'Tennis',
          multiple: 'Multiple',
          speedSkating: 'Speed Skating',
          alpineSki: 'Alpine Ski',
          football: 'Football',
          freestyleSki: 'Freestyle Ski',
          snowboard: 'Snowboard',
          lacrosse: 'Lacrosse',
          boxing: 'Boxing',
          wrestling: 'Wrestling',
          gymnastics: 'Gymnastics',
          ringette: 'Ringette',
        },
        associationsHeader: 'Association(s) or League(s)',
        selectAssociation: 'Add...',
      },
      importData: {
        chooseOrganization: 'Choose an organization to import data',
        selectOrganization: 'Select an organization',
        downloadTemplates: 'Download Templates',
        chooseOrganizationDropdown: 'Choose organization',
        importData: 'Import Data',
        importErrors: 'Import Errors',
        pastUploads: 'Past Uploads',
        instructionsContent:
          'Please upload the following files based on the templates: participants, guardians, staff (concussion managers, group leaders & admins), participant-group mapping, and group leader-group mapping',
        formFieldTitles: [
          { title: 'Create Participants', value: 'SUBJECTS' },
          { title: 'Create Guardians', value: 'GUARDIANS' },
          { title: 'Create Staff (concussion managers, group leaders & admin)', value: 'STAFF' },
          { title: 'Create or Update Participant Groups', value: 'SUBJECT_GROUPS' },
          { title: 'Create or Update Group Leader Groups', value: 'DESIGNATED_PERSON_GROUPS' },
        ],
        uploadButton: 'Upload File',
        uploadAndValidate: 'Upload & Validate',
        uploadAndValidateInProgress: 'Importing...',
        viewPastImports: 'View past imports',
        importIsSuccessful: 'Import Successful!',
        importFailed: 'Import failed',
        viewData: 'View Imported Data',
        viewFailedData: 'View failed data',
        failureMessage: 'Some data is invalid',
        invalidRowsMessage: 'View invalid rows',
        successMessage: 'Data is valid: click import to complete',
        completeImport: 'Import',
        downloadCsv: 'Download',
        importErrorsModalText: 'The data listed here was not imported to CAPT.',
        noPastUploadsAvailable: 'There are no past uploads for this organization.',
        partialErrors: 'Some data was not imported',
        row: 'Row',
        invalidLink: 'Error: Cannot download the file. Invalid link.',
        status: {
          SUCCESSFULLY_COMPLETED: 'imported',
          IN_PROGRESS: 'importing',
          COMPLETED_WITH_ERRORS: 'imported with errors',
          FAILED: 'failed',
          ERRORS: 'imported with errors',
          INCORRECT_HEADERS: 'Invalid headers',
        },
        sendInviteAfterImport: 'Send invite emails immediately on import',
        sendInviteAtLaterDate:
          'If you want to send emails at a later time, use the "Nudge All" button in the participation report.',
      },
      paymentStatus: {
        title: 'Payment Status',
        expiresAtLabel: 'Subscription valid until',
        label: {
          dateCreated: 'Date created',
        },
        statusUpdate: "%{organizationName}'s status has been updated to %{organizationStatus}",
        statuses: {
          trial: 'Trial',
          paid: 'Paid',
          suspended: 'Suspended',
          demo: 'Demo',
          test: 'Test',
        },
      },
    },

    cases: {
      pastCases: 'Past Cases',
      activeCases: 'Active Cases',
      noPastCases: 'There are no past cases',
      noActiveCases: 'There are no active cases',
      label: {
        startDateDesc: 'Start date: newest',
        startDateAsc: 'Start date: oldest',
        endDateDesc: 'End date: newest',
        endDateAsc: 'End date: oldest',
      },
      fromStartDate: 'From start date',
      toStartDate: 'To start date',
      numberOfDays: '%{number} days',
      dateRangeForClosedCase: '%{createdDate} to %{closedDate}',
      dateRangeForActiveCase: '%{createdDate} to present',
    },

    fileButton: {
      selectQuestion: 'How would you like to submit your document?',
      attachFile: 'Attach a file',
      takePhoto: 'Take a photo',
    },

    featureFlag: {
      noAccess: 'You do not have access to this screen.',
    },

    searchAndSort: {
      all: 'All',
    },

    answers: {
      YES: 'yes',
      NO: 'no',
      DONT_KNOW: "don't know",
    },

    errorCodes: {
      adminDoesNotBelongInOrganization: 'Admin does not belong in organization',
      unknownImportType: 'Unknown import type',
      couldNotCreateImport: 'Could not create import',
      incorrectHeaders: 'Incorrect headers',
    },

    organizationSports: {
      athletics: 'Athletics',
      baseball: 'Baseball',
      basketball: 'Basketball',
      equestrian: 'Equestrian',
      fieldHockey: 'Field Hockey',
      hockey: 'Hockey',
      multiple: 'Multiple',
      other: 'Other',
      rugby: 'Rugby',
      soccer: 'Soccer',
      tennis: 'Tennis',
      speedSkating: 'Speed Skating',
      alpineSki: 'Alpine Ski',
      football: 'Football',
      freestyleSki: 'Freestyle Ski',
      snowboard: 'Snowboard',
      lacrosse: 'Lacrosse',
      boxing: 'Boxing',
      wrestling: 'Wrestling',
      gymnastics: 'Gymnastics',
      ringette: 'Ringette',
    },

    importTypes: {
      staff: 'Staff',
      subjectGroups: 'Participant groups',
      designatedPersonGroups: 'Group leader groups',
    },

    appUpdate: {
      title: 'You must update the CAPT app',
      message: 'There is a new version of CAPT and this version will no longer work.',
      instructions: 'Please update to the latest version from %{storeName} to continue using CAPT.',
      goToAppStore: 'Go to App Store',
      goToPlayStore: 'Go to Play Store',
      appStore: 'the App Store',
      playStore: 'Google Play',
    },

    termsAndConditions: `<AppText style={{ display: 'flex', flexDirection: 'column' }}>
      <View style={{ marginVertical: 5 }}>
        <Text> Agile Intelligence Solutions Inc. (<Text style={{ fontWeight: 'bold' }}>“Company”</Text>) owns certain software that it has developed and makes commercially available to its customers for use as a cloud based, managed software as a service offering. In connection with the use of the Service (as defined below) you hereby agree to the terms and conditions and conditions contained herein (the{' '} <Text style={{ fontWeight: 'bold' }}>“Terms and Conditions”</Text>). </Text> 
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> BY ACCEPTING THE TERMS AND CONDITIONS EITHER BY: A) SIGNING THE PURCHASE ORDER OR QUOTATION (AS DEFINED BELOW) WHICH REFERENCE THE TERMS AND CONDITIONS, OR B) USING OR ACCESSING THE SERVICE, CUSTOMER ACKNOWLEDGES THAT ITHAS READ AND UNDERSTOOD ALL OF THE PROVISIONS, AND HAS THE AUTHORITY TO AGREE TO, AND IS CONFIRMING THAT IT IS AGREEING TO, COMPLY WITH AND BE BOUND BY, ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN, TOGETHER WITH ANY PURCHASE ORDER OR QUOTATION AND INCLUDING THE COMPANY’S PRIVACY POLICY LOCATED AT <AppLink>https://www.captcares.com/privacy-notice</AppLink>, ALL OF WHICH ARE INCORPORATED BY REFERENCE AND DEEMED TO BE PART OF THE ENTIRE AGREEMENT ENTERED INTO BETWEEN COMPANY AND THE CUSTOMER. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> IF YOU ARE AN AGENT OR EMPLOYEE OF THE CUSTOMER THEN YOU HEREBY REPRESENT AND WARRANT THAT: (I) THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS DULY AUTHORIZED TO ACCEPT THIS AGREEMENT ON CUSTOMER’S BEHALF AND TO BIND THE CUSTOMER, AND (II) THE CUSTOMER HAS FULL POWER, CORPORATE OR OTHERWISE, TO ENTER INTO THIS AGREEMENT AND PERFORM ITS OBLIGATIONS HEREUNDER. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>1. Definitions</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text>1.1 In this Agreement:</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Agreement</Text>” means the Purchase Order or Quotation, as applicable, and these Terms and Conditions. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Concussion Protocol</Text>” means Customer’s internal concussion protocol(s) as developed by Customer and provided to Company, which shall be made available in the Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Confidential Information</Text>” means any information of the parties in electronic or tangible form which is clearly marked “Confidential” and/or which: a) was not previously known to the party receiving the disclosure; b) was not obtained from a third party having no duty of confidentiality to the party asserting confidentiality; or c) was not published or otherwise available from publicly available sources. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Customer</Text>” means the customer named in the applicable Purchase Order or Quotation. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Customer Data</Text>” means the data submitted by or for Customer to the Service or collected and processed by or for Customer using the Service, including but not limited to the data of and Personal Information belonging to Customer’s employees, students and/or athletes, and parents and/or guardians, as applicable. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Data Protection Laws</Text>” means all laws and regulations, including laws and regulations of Canada (including the Personal Information Protection and Electronic Documents Act (Canada) and the Canadian Anti-Spam Legislation, each as amended or superseded from time to time) applicable to the processing of Personal Information under the Agreement. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Fee</Text>” means the fee invoiced by Company and payable by Customer for the Service as set out in the Purchase Order or Quotation. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Personal Information</Text>” means any information relating to an identified or identifiable natural person as defined under applicable Data Protection Laws. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Purchase Order</Text>” or “ <Text style={{ fontWeight: 'bold' }}>Quotation</Text>” means a form provided by Customer in respect to Customer’s purchase(s) of the Service from Company. </Text> </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Service</Text>” means the cloud-based hosted software as a service offering, CAPT, for which Customer is granted rights of access and use in accordance with this Agreement, including any ancillary services available in connection therewith, as such Service may be updated from time to time by Company in its sole discretion. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Support Services</Text>” means the technical support services for the Service provided by Company as described in, and in accordance with, the Support Terms. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Support Terms</Text>” means the terms on which Company provides Support Services to Customer. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>Term</Text>” means the period of time that Customer is authorized by Company to access and use the Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> “<Text style={{ fontWeight: 'bold' }}>User</Text>” means employees, students and/or athletes, and parents and/or guardians of Customer to whom Customer (or Company at Customer’s request) has supplied a user identification and password. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>2. The Service</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.1. <Text style={{ textDecorationLine: 'underline' }}>Right to Use the Service</Text>. Subject to the terms and conditions of this Agreement (including the applicable Purchase Order or Quotation) and payment of the applicable Fees, Company hereby grants to Customer a non-exclusive, worldwide, non-transferable, non-sublicensable, internal right to access and use (and to permit Users to access and use) the Service, solely during the Term. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.2. <Text style={{ textDecorationLine: 'underline' }}>Reservation of Rights</Text>. Company and its licensors own and shall retain all right, title and interest (including without limitation all patent rights, copyrights, trade-mark rights, trade secret rights and all other intellectual property rights), in and to the Service and any copies, corrections, bug fixes, enhancements, modifications or new versions thereof, all of which shall be deemed part of the Service and subject to all of the provisions of this Agreement. Customer shall keep the Service free and clear of all liens, encumbrances and/or security interests. Subject to the limited rights expressly granted in this Agreement, Company reserves all rights, title and interest in and to the Service. No rights are granted to Customer pursuant to this Agreement other than as expressly set forth in this Agreement. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.3. <Text style={{ textDecorationLine: 'underline' }}>Restrictions</Text>. Customer shall not (and shall not allow Users or any third party to): (a) possess, download or copy the Service or any part of the Service, including but not limited any component which comprises the Service, but not including any output from the Service; (b) knowingly interfere with service to any of Company’s users, host or network, including by means of intentionally submitting a virus, overloading, flooding, spamming, mail bombing or crashing the Service; (c) modify, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Service, except to the extent that enforcement is prohibited by applicable law; (d) circumvent any timing restrictions that are built into the Service; (e) sell, rent, lend, transfer, distribute, license, or grant any rights in the Service in any form to any person without the written consent of Company; (f) remove any proprietary notices, labels, or marks from the Service; (g) create any “ <Text style={{ fontWeight: 'bold' }}>links</Text>” to or “<Text style={{ fontWeight: 'bold' }}>frame</Text>” or “<Text style={{ fontWeight: 'bold' }}>mirror</Text>” of the Service or any portion thereof; or (h) use the Service to create, collect, transmit, store, use or process any Customer Data that: (i) Customer does not have the lawful right to create, collect, transmit, store, use or process, or (ii) violates any applicable laws, or infringes, violates or otherwise misappropriates the intellectual property or other rights of any third party (including any moral right, privacy right or right of publicity). </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 2.4. <Text style={{ textDecorationLine: 'underline' }}>Aggregated Data</Text>. Customer acknowledges and agrees that the Service compiles, stores and uses aggregated data and system usage, analytics and diagnostic information to monitor and improve the Service and for the creation of new products. All data collected, used, and disclosed by Company will be in aggregate, anonymized and/or de-identified form only and will not identify Customer, its Users, Customer Data, Personal Information, or any third parties utilizing the Service. Aggregated data that has outlived its use is retained for no more than ninety (90) days before it is destroyed. The aggregated data may remain in Company’s backup files for up to thirty five (35) days, as it is Company’s policy to maintain backups for a minimum of thirty five (35) days before those backups are destroyed. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>3. Account Activation</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 3.1. <Text style={{ textDecorationLine: 'underline' }}>Account</Text>. Customer is required to open an account with Company (an “<Text style={{ fontWeight: 'bold' }}>Account</Text>”) in order to use the Service. During registration, a User will be asked to provide certain Personal Information in order to create an Account on behalf of Customer. Customer shall ensure that such Account activation information is accurate and complete and that such information remains current throughout the Term. Customer is fully responsible for all activity that occurs in Customer’s Account, including for any actions taken by its Users. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 3.2. <Text style={{ textDecorationLine: 'underline' }}>Passwords</Text>. Customer is responsible for keeping all Account passwords secure. Company will not be liable for any loss or damage caused by or arising from a failure by Customer or its Users to maintain the security of the Customer’s Account and password. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>4. Support Services</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 4.1. <Text style={{ textDecorationLine: 'underline' }}>Basic Support</Text>. Company shall provide the following basic support (“<Text style={{ fontWeight: 'bold' }}>Basic Support</Text>”) to Customer: (a) telephone support from 9:00 A.M. to 5:00 P.M. EST, Monday to Friday (excluding statutory holidays in the Province of Ontario) and any calls received outside of such hours are handled on a reasonable efforts and as available basis; (b) email support is monitored and provided from 9:00 A.M. to 5:00 P.M. EST, Monday to Friday (excluding statutory holidays in the Province of Ontario) and emails outside of these hours will be responded to on a reasonable efforts basis (c) Company will use commercially reasonable efforts to respond to support enquiries within one (1) business day. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>5. Customer Data and Personal Information</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.1. <Text style={{ textDecorationLine: 'underline' }}>Ownership</Text>. As between Company and Customer, Customer exclusively owns all rights, title and interest in and to all Customer Data. Company does not acquire any rights, title or ownership interest of any kind whatsoever, express or implied, in any of the Customer Data. Customer hereby authorizes Company to use Customer Data as required to provide the Service in accordance with the terms hereof. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.2. <Text style={{ textDecorationLine: 'underline' }}>Technical and Organizational Safeguards</Text>. In connection with the provision of the Service, Company will maintain commercially reasonable administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of the Service and Customer Data. Those safeguards will include, but will not be limited to, measures for preventing access, use, modification or disclosure of Customer Data by Company personnel except (a) to provide the Service and prevent or address service or technical problems, (b) as compelled by law and upon identification of lawful authority, (c) as expressly permitted in writing by Customer, or (d) as allowed under applicable Data Protection Laws. Company shall, in connection with the provision of the Service, comply with Data Protection Laws, as well as the Company’s Privacy Policy. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.3.{' '} <Text style={{ textDecorationLine: 'underline' }}>Customer’s Obligations Regarding Personal Information</Text> . Customer shall have sole responsibility for the accuracy, quality, and legality of Personal Information and the means by which Customer acquired the Personal Information. Customer hereby represents and warrants to, and covenants with Company that Customer Data will only contain Personal Information in respect of which Customer has obtained all applicable third party consents and permissions and otherwise has all authority, in each case as required by applicable laws, to enable Company to provide the Service, including with respect to the collection, storage, access, use, disclosure and transmission of Personal Information. Any correction to Personal Information used and stored in the Service must be made by Customer unless Customer is unable to do so. If Customer is unable to make a correction to Personal Information, Company shall perform a correction on Customer’s behalf. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.4. <Text style={{ textDecorationLine: 'underline' }}>Customer Data Retention and Deletion</Text>. Company will process the Customer Data only as long as is necessary to implement, administer and manage Customer and User’s Personal Information in the Service. Upon any expiration or termination of this Agreement, Company will continue to store Customer and User’s Personal Information in accordance with this Section 5 for certain business purposes, including but not limited to defence of a contractual claim, auditing, and as required to comply with applicable law. In order to appropriately manage system performance, Customer Data that has outlived its use as outlined above, is retained for no more than ninety (90) days before it is destroyed. Customer Data may remain in Company’s backup files for up to thirty five (35) days, as it is Company’s policy to maintain backups for a minimum of thirty five (35) days before those backups are destroyed. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 5.5. <Text style={{ textDecorationLine: 'underline' }}>Customer Data Export</Text>. Upon request by Customer made during the Term or within thirty (30) days after the effective date of termination of this Agreement, Company will make the Customer Data available to Customer for export or download. After such 30-day period, Company will have no obligation to provide any Customer Data to Customer. Customer Data will be retained in accordance with Section 5.4 </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>6. Customer Responsibilities</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.1. <Text style={{ textDecorationLine: 'underline' }}>Users</Text>. Customer is responsible for all activities that occur in User accounts and for its and its Users’ compliance with this Agreement. Customer shall: (a) use commercially reasonable efforts to prevent unauthorized access to, or use of, the Service, and notify Company promptly of any such unauthorized access or use; and (b) use the Service only in accordance with applicable laws and government regulations. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.2. <Text style={{ textDecorationLine: 'underline' }}>Concussion Protocol</Text>. Customer is responsible for providing Company with the Concussion Protocol. Customer has sole responsibility for the accuracy and completeness of the Concussion Protocol and Company will not be liable for any loss or damage caused by or arising from Customer’s, User’s or any third party’s reliance on and use of the Concussion Protocol, or any inaccuracies therein. As between Company and Customer, Customer exclusively owns all rights, title and interest in and to the Concussion Protocol and Company does not acquire any rights, title or ownership interest of any kind whatsoever, express or implied, in the Concussion Protocol. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.3. <Text style={{ textDecorationLine: 'underline' }}>Equipment</Text>. Customer is solely responsible for acquiring, servicing, maintaining and updating all equipment, computers, software and communications services (such as Internet access) that are required to allow Customer to access and use the Service and for all expenses relating thereto. Customer agrees to access and use, and shall ensure that all Users access and use, the Service in accordance with any and all operating instructions or procedures that may be issued by Company from time to time. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 6.4. <Text style={{ textDecorationLine: 'underline' }}>Feedback</Text>. Customer may provide reasonable feedback to Company including, but not limited to, suitability, problem reports, suggestions and other information with respect to the Service (“<Text style={{ fontWeight: 'bold' }}>Feedback</Text>”). Customer hereby grants to Company a fully paid-up, royalty-free, worldwide, assignable, transferable, sublicenseable, irrevocable, perpetual license to use or incorporate into the Service and any other Company products or services, or for any other purposes, any Feedback provided by Customer or its Users. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>7. Onboarding, Fees and Payment</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.1. <Text style={{ textDecorationLine: 'underline' }}>Onboarding</Text>: Company shall provide onboarding services to Customer prior to invoicing Fees for a period that is mutually agreed upon between Company and Customer (the “<Text style={{ fontWeight: 'bold' }}>Onboarding Period</Text>”). If, after completion of the Onboarding Period, Customer elects not to continue using the Service, Customer may terminate the associated Purchase Order/Quotation with Company and no Fees shall be invoiced for the Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.2. <Text style={{ textDecorationLine: 'underline' }}>Fees</Text>. Customer shall pay all Fees specified in each Purchase Order/Quotation. All Fees are quoted and payable in Canadian dollars. Except as otherwise specified herein or in a Purchase Order/Quotation, payment obligations are non-cancellable and Fees paid are non-refundable. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.3. <Text style={{ textDecorationLine: 'underline' }}>Invoicing and Payment</Text>. Fees will be invoiced in accordance with the relevant Purchase Order/Quotation. Unless otherwise stated in a Purchase Order/Quotation, charges are due immediately upon Customer’s receipt of invoice. Customer is responsible for maintaining complete and accurate billing and contact information with Company. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.4. <Text style={{ textDecorationLine: 'underline' }}>Taxes</Text>. Fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including, for example, HST, GST, sales, value-added, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, “ <Text style={{ fontWeight: 'bold' }}>Taxes</Text>”). Customer is responsible for paying all Taxes associated with its purchases hereunder. If Company has the legal obligation to pay or collect Taxes for which Customer is responsible under this section, Company will invoice Customer and Customer will pay that amount unless Customer provides Company with a valid tax exemption certificate authorized by the appropriate taxing authority. For clarity, Company is solely responsible for taxes assessable against it based on its income, property and employees. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 7.5. <Text style={{ textDecorationLine: 'underline' }}>Price Modification</Text>. Company reserves the right at any time to modify and/or adjust its Fees and shall provide Customer with written notice of any such modification. [Upon any modification in Fees, Customer may terminate this Agreement in accordance with Section 12.2]. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>8. Confidentiality Obligations</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Each party acknowledges that all Confidential Information consists of confidential and proprietary information of the disclosing party. Each party shall, and shall cause its employees, agents and contractors to hold Confidential Information of the other party in confidence, and shall use the same degree of care by instruction, agreement or otherwise, to maintain the confidentiality of the other party’s Confidential Information that it uses to maintain the confidentiality of its own Confidential Information, but with at least a reasonable degree of care commensurate with the nature and importance of such Confidential Information. Each party agrees not to make use of Confidential Information other than for the exercise of rights or the performance of obligations under this Agreement, and not to release, disclose, communicate it or make it available to any third person other than employees, agents and contractors of the party who reasonably need to know it in connection with the exercise of rights or the performance of obligations under this Agreement. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>9. Limited Warranties and Disclaimers</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.1. <Text style={{ textDecorationLine: 'underline' }}>Limited Warranties</Text>. Company hereby represents and warrants to Customer that it owns or otherwise has sufficient rights in the Service to grant to Customer the rights to access and use the Service granted herein. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.2. <Text style={{ textDecorationLine: 'underline' }}>Remedy</Text>. In the event of a breach of one or more of the warranties set forth in Section 9.1 hereof, Company shall use reasonable commercial efforts to correct such breach of the warranty. If Company is unable to remedy the breach of warranty within a reasonable time, Company shall refund the Fees paid for the Service. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.3. <Text style={{ textDecorationLine: 'underline' }}>Exclusive Remedies</Text>. THE WARRANTIES SET OUT IN SECTION 9.1 HEREOF ARE THE ONLY WARRANTIES PROVIDED BY COMPANY AND THE REMEDIES SET OUT IN SECTION 9.2 HEREOF ARE THE SOLE AND EXCLUSIVE REMEDIES OF CUSTOMER FOR A BREACH OF WARRANTY. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.4. <Text style={{ textDecorationLine: 'underline' }}>General Warranty Disclaimers</Text>. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> (A) EXCEPT FOR THE EXPRESS WARRANTIES PROVIDED HEREIN, THE SERVICE IS PROVIDED “ <Text style={{ fontWeight: 'bold' }}>AS IS</Text>” AND “ <Text style={{ fontWeight: 'bold' }}>AS AVAILABLE</Text>” AND COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES, AND THERE ARE NO CONDITIONS, ENDORSEMENTS, UNDERTAKINGS, GUARANTEES, REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, (INCLUDING WITHOUT LIMITATION ANY EXPRESS OR IMPLIED WARRANTIES OR CONDITIONS OF QUALITY, PERFORMANCE, RESULTS, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY OR ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF THE TRADE) AS TO, ARISING OUT OF OR RELATED TO THE FOLLOWING: (I) THIS AGREEMENT; (II) THE SERVICE; AND/OR (III) SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION OR CUSTOMER DATA TRANSMITTED TO OR FROM COMPANY VIA THE SERVICE. COMPANY DOES NOT REPRESENT OR WARRANT THAT THE SERVICE WILL MEET ANY OR ALL OF CUSTOMER’S PARTICULAR REQUIREMENTS, THAT THE SERVICE WILL OPERATE ERROR-FREE OR UNINTERRUPTED OR THAT ALL PROGRAMMING ERRORS IN THE SERVICE CAN BE FOUND IN ORDER TO BE CORRECTED. COMPANY DISCLAIMS ALL LIABILITY AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGES CAUSED BY ANY THIRD-PARTY HOSTING PROVIDERS. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> (B) <Text style={{ fontStyle: 'italic' }}>No Reliance</Text>: THE SERVICE IS FOR INFORMATIONAL PURPOSES ONLY AND THE INFORMATION PROVIDED BY THE SERVICE IS NOT INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. CUSTOMER ACKNOWLEDGES THAT ALTHOUGH COMPANY MAKES REASONABLE EFFORTS TO UPDATE INFORMATION AND CUSTOMER DATA IN THE SERVICE, COMPANY MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES, WHETHER EXPRESS OR IMPLIED, THAT THE INFORMATION IN AND AS PROVIDED BY THE SERVICE IS ACCURATE, COMPLETE, OR UP TO DATE. CUSTOMER’S USE OF THE SERVICE AND RELIANCE ON THE INFORMATION AND CONCUSSION PROTOCOL IS AT CUSTOMER’S OWN RISK AND COMPANY HAS NO RESPONSIBILITY OR LIABILITY WHATSOEVER FROM CUSTOMER’S USE OF AND RELIANCE ON THE SERVICE AND THE CONCUSSION PROTOCOL AS PROVIDED THEREIN BY CUSTOMER. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 9.5. <Text style={{ textDecorationLine: 'underline' }}>Internet Connectivity Disclaimer</Text>. Company makes the Service available for access via the Internet. Customer shall provide, at Customer’s own expense, all necessary hardware, applications and Internet connectivity necessary to access the Service over the Internet. Customer hereby acknowledges that the Service may be interrupted due to (a) downtime for scheduled maintenance at Company’s sole discretion, or (b) interruptions in Internet connectivity or other downtime caused by circumstances beyond Company’s control, including, without limitation, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems, computer or telecommunications failures, or delays involving hardware of software not within Company’s control or network intrusions. Customer hereby acknowledges and agrees that Company shall not, in any way, be liable for, or have responsibility with respect to, any such service interruptions and releases Company from any claims relating thereto. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>10. Indemnification by Customer</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Customer shall defend, indemnify and hold Company harmless against any loss, damage or costs (including reasonable legal fees) incurred in connection with any claims, demands, suits, or proceedings made or brought against Company by a third party (a) alleging that the Customer Data or Customer’s use of the Service in violation of this Agreement, infringes the intellectual property rights of, or has otherwise harmed, a third party; (b) based on a breach of any Data Protection Laws or a breach of this Agreement; (c) for any illness, personal injury or death; or (d) caused by any negligent act or omission of Customer or its employees, contractors or agents (each a “<Text style={{ fontWeight: 'bold' }}>Customer Indemnified Claim</Text>”); provided, that Company (a) promptly gives written notice of the Customer Indemnified Claim to Customer; (b) gives Customer sole control of the defense and settlement of the Customer Indemnified Claim (provided that Customer may not settle or defend any Customer Indemnified Claim unless it unconditionally releases Company of all liability); and (c) provides to Customer, at Customer’s cost, all reasonable assistance and information. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>11. Limitation of Liability</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 11.1. <Text style={{ textDecorationLine: 'underline' }}>Exclusion of Indirect and Consequential Damages</Text> . SUBJECT TO SECTION 11.3 HEREOF, IN NO EVENT SHALL EITHER PARTY HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOST SAVINGS OR OTHER SIMILAR PECUNIARY LOSS). </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 11.2. <Text style={{ textDecorationLine: 'underline' }}>Limitation of Liability</Text>. SUBJECT TO SECTION 11.3 HEREOF, IN NO EVENT SHALL EITHER PARTY’S MAXIMUM, CUMULATIVE AND AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR RELATING TO THE SUBJECT MATTER HEREOF FOR ALL CLAIMS, COSTS, LOSSES AND DAMAGES EXCEED THE AMOUNTS ACTUALLY PAID BY CUSTOMER HEREUNDER. THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE THIS CUMULATIVE LIMIT. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 11.3. <Text style={{ textDecorationLine: 'underline' }}>Certain Damages Not Excluded or Limited</Text>. NOTWITHSTANDING THE FOREGOING, NO LIMITATION OF EITHER PARTY’S LIABILITY SET FORTH IN THIS AGREEMENT SHALL APPLY TO (I) DAMAGES ARISING FROM A PARTY’S BREACH OF ITS CONFIDENTIALITY OBLIGATIONS HEREUNDER, (II) INDEMNIFICATION CLAIMS SUBJECT TO THE LIMITATION CONTAINED IN SECTION 11.2 HEREOF, (III) DAMAGES ARISING FROM INFRINGEMENT OF A PARTY’S INTELLECTUAL PROPERTY RIGHTS; (IV) ANY CLAIMS FOR NON-PAYMENT, OR (V) FRAUD OR WILLFUL MISCONDUCT. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>12. Term</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.1. <Text style={{ textDecorationLine: 'underline' }}>Term</Text>. This Agreement commences on the date of the initial Purchase Order/Quotation and shall continue until terminated earlier in accordance with the provisions of this Agreement or applicable law. This Agreement shall remain in effect and govern all Purchase Orders/Quotations until (i) the end of the Term under such Purchase Order/Quotation, (ii) such Purchase Order/Quotation is terminated by the parties, or (iii) there has been full performance of the parties’ respective obligations under such Purchase Order/Quotation. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.2. <Text style={{ textDecorationLine: 'underline' }}>Early Termination</Text>. Customer may terminate a Purchase Order/Quotation prior to the expiry of the Term (as indicated in the Purchase Order/Quotation) by providing Company with five (5) days’ written notice of Customer’s intention to terminate the Purchase Order/Quotation. Upon receipt of notice, Company shall charge Customer an early termination fee of ten percent (10%) of the prepaid Fees, unless Customer is terminating pursuant to Section 7.5, in which case no early termination fee would be payable. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.3. <Text style={{ textDecorationLine: 'underline' }}>Termination</Text>. A party may terminate this Agreement for cause (i) upon thirty (30) days’ written notice to the other party of a material breach if such breach remains uncured at the expiration of such period, or (ii) if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.4. <Text style={{ textDecorationLine: 'underline' }}>Refund or Payment upon Termination</Text>. If this Agreement is terminated by Customer in accordance with Section 12.2, Company will refund Customer any prepaid Fees covering the remainder of the Term after the effective date of termination. If this Agreement is terminated by Company in accordance with Section 12.3, Company shall not refund any prepaid Fees to Customer. In no event will termination relieve Customer of its obligation to pay any Fees payable to Company for the period prior to the effective date of termination. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 12.5. <Text style={{ textDecorationLine: 'underline' }}>Suspension of Access to Service</Text>. In addition to any termination rights of Company pursuant to this Agreement, extraordinary circumstances may require Company to suspend or terminate (where appropriate), as determined in Company’s reasonable discretion, Customer’s access to and/or use of, or otherwise modify, the Service in order to: (a) prevent material damages to, or material degradation of the integrity of, Company’s or its provider’s Internet network; or (b) comply with any law, regulation, court order, or other governmental order. Company will notify Customer of such suspension or termination action as far in advance of such suspension or termination as reasonably possible, and if such advance notice is not possible, then as soon as possible after such suspension or termination. In the event of a suspension, Company will limit such suspension to that which is minimally required and will promptly restore Customer’s access to the Service as soon as the event giving rise to the suspension has been addressed (including by Customer agreeing to accept the risks associated with such suspension) or resolved. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>13. Assignment</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Customer may not assign any of its rights or obligations hereunder, whether by operation of law, change of control or otherwise, without the prior written consent of Company. Notwithstanding the foregoing, Company may assign this Agreement in its entirety (including all Purchase Orders/Quotations), without consent of the Customer, in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets to which this Agreement relates. Any attempt by Customer to assign its rights or obligations under this Agreement in breach of this section shall be void and of no effect. Subject to the foregoing, this Agreement shall bind and inure to the benefit of the parties, their respective successors and permitted assigns. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>14. Professional Services – Third Parties </Text>
      </View>
      <View style={{ marginVertical: 5}}>
        <Text>From time to time, Company may use a third party to perform certain professional services as required under this Agreement and/or as requested by Customer; provided that Company’s use of such third party shall not release Company from any duty or liability to fulfill Company’s obligations as required under this Agreement. Notwithstanding the foregoing, Company shall not be liable to Customer for any act or omission of any third party contracted by Company to perform professional services for Customer.</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>15. Notices</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> Any notice required or permitted to be given in accordance with this Agreement will be effective only if it is in writing and sent using: (a) the Service; (b) certified or registered mail; or (c) a nationally recognized overnight courier, to the appropriate party at the address set forth on the Purchase Order/Quotation, with a copy, in the case of Company, to support@agileis.co. Each party hereto expressly consents to service of process by registered mail. Either party may change its address for receipt of notice by notice to the other party through a notice provided in accordance with this Section 14 (Notices). Notices are deemed given upon receipt if delivered using the Service, two (2) business days following the date of mailing, or one (1) business day following delivery to a courier. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', }}>16. General</Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.1. <Text style={{ textDecorationLine: 'underline' }}>Waiver</Text>. The failure of a party to claim a breach of any term of this Agreement shall not constitute a waiver of such breach or the right of such party to enforce any subsequent breach of such term. No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that right. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.2. <Text style={{ textDecorationLine: 'underline' }}>Unenforceable Provisions</Text>. If any provision of this Agreement is held to be unenforceable or illegal, such decision shall not affect the validity or enforceability of such provisions under other circumstances or the remaining provisions of this Agreement and this Agreement shall be reformed only to the extent necessary to make it enforceable under such circumstances. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.3. <Text style={{ textDecorationLine: 'underline' }}>Independent Contractors</Text>. The relationship of Company and Customer established by this Agreement is that of independent contractors, and nothing contained in this Agreement will be construed to (i) give either party the power to direct and control the day to-day activities of the other, (ii) constitute the parties as legal partners, joint venturers, co-owners or otherwise as participants in a joint undertaking, or (iii) allow either party to create or assume any obligation on behalf of the other party for any purpose whatsoever. All financial and other obligations associated with the businesses of the parties are their sole respective responsibilities. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.4. <Text style={{ textDecorationLine: 'underline' }}>Governing Law</Text>. This Agreement shall be governed by the laws of the Province of Ontario, without regard to its conflict of law principles. The courts located in the Province of Ontario shall have exclusive jurisdiction to adjudicate any dispute arising out of or relating to this Agreement and each party hereby consents to the exclusive jurisdiction of such courts. The application of the United Nations Convention on Contracts for the International Sale of Goods to this Agreement is expressly excluded and does not apply to this Agreement. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.5. <Text style={{ textDecorationLine: 'underline' }}>Entire Agreement</Text>. This Agreement is the entire agreement between Customer and Company in respect to the subject matter hereof, superseding any other agreements or discussions, oral or written, and may not be changed except by a written license agreement with Company or a distributor of Company. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.6. <Text style={{ textDecorationLine: 'underline' }}>Remedies</Text>. Other than as expressly stated herein, the remedies provided herein are in addition to, and not exclusive of, any other remedies of a party at law or in equity. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.7. <Text style={{ textDecorationLine: 'underline' }}>Amendments</Text>. EXCEPT WHERE PROHIBITED BY APPLICABLE LAW OR AS OTHERWISE AGREED IN THE PURCHASE ORDER/QUOTATION, COMPANY MAY UNILATERALLY AMEND THESE TERMS AND CONDITIONS, IN WHOLE OR IN PART (EACH, AN “<Text style={{ fontWeight: 'bold' }}>AMENDMENT</Text>”), BY GIVING CUSTOMER PRIOR NOTICE OF SUCH AMENDMENT. UNLESS OTHERWISE INDICATED BY COMPANY ANY SUCH AMENDMENT WILL BECOME EFFECTIVE AS OF THE DATE THE NOTICE OF SUCH AMENDMENT IS PROVIDED TO CUSTOMER. </Text>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text> 16.8. <Text style={{ textDecorationLine: 'underline' }}>Language of Agreement</Text>. The parties hereto confirm that they have requested that this Agreement and all related documents be drafted in English. Any French translation hereof has been provided for information purposes only and does not have any legal value nor create any contractual relationship between the parties. Les parties aux présentes ont exigé que la présente entente et tous les documents connexes soient rédigés en anglais. Toute traduction de celle-ci est non-officielle, est fournie à des fins d’information seulement et ne crée aucun lien contractuel entre les parties. </Text>
      </View>
    </AppText>`,
  },
};
