import React from 'react';
import { gql } from '@apollo/client';
import { View, StyleSheet } from 'react-native';
import { AppText, AppImage, FileButton, FileButtonSelectedFile } from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';
import { useMutation } from '../../hooks/useMutation';
import { useLoggedInUser } from '../shared/LoggedInUser/useLoggedInUser';
import { UpdateProfileImage, UpdateProfileImageVariables } from './__generated__/UpdateProfileImage';

export interface UpdateImageProps {}

const UPDATE_PROFILE_IMAGE = gql`
  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {
    updateProfileImage(input: $input) {
      ... on CurrentUser {
        id
        userImage {
          id
          thumbnail {
            id
            uri
          }
          original {
            id
            uri
          }
        }
      }
    }
  }
`;

export const UpdateImage: React.FC<UpdateImageProps> = (props) => {
  const { translate } = useTranslations();
  const [updateProfileImage, { loading }] = useMutation<UpdateProfileImage, UpdateProfileImageVariables>(
    UPDATE_PROFILE_IMAGE
  );
  const currentUser = useLoggedInUser();

  const uploadFile = async (file: FileButtonSelectedFile) => {
    updateProfileImage({
      variables: {
        input: {
          id: currentUser.id,
          file,
        },
      },
    });
  };

  const uri = currentUser?.userImage?.thumbnail?.uri;

  const ImageDisplay = () =>
    uri ? (
      <AppImage
        source={{
          uri,
        }}
        resizeMode="contain"
        style={styles.image}
      />
    ) : null;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingTop: 25,
      marginBottom: 40,
    },
    imageWrapper: {
      marginRight: 10,
    },
    image: {
      borderRadius: 50,
      width: 66,
      height: 66,
    },
    name: {
      marginBottom: 8,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <View style={styles.imageWrapper}>
          <ImageDisplay />
        </View>
        <View>
          <AppText font="header2" style={styles.name}>
            {currentUser.fullName}
          </AppText>
          <FileButton
            mediaType="images"
            disabled={loading}
            loading={loading}
            size="extraSmall"
            onFileChange={uploadFile}
            onFileClear={() => {}}
          >
            {translate.account.pickImage}
          </FileButton>
        </View>
      </View>
    </>
  );
};
