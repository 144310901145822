import { Index } from '../screens/Index/Index';
import { RecoveryPlanNavItem } from '../RecoveryPlan.interface';
import { Stage } from '../screens/Stage/Stage';

const recoveryPlanRoutes: RecoveryPlanNavItem[] = [
  {
    component: Index,
    label: 'Recovery Plan',
    name: 'LoggedIn_Profile_RecoveryPlan_Index' as const,
    path: '',
  },
  {
    component: Stage,
    label: 'Stage',
    name: 'LoggedIn_Profile_RecoveryPlan_Stage' as const,
    path: 'stage/:stageId?',
  },
];

export const useRecoveryPlanRoutes = () => recoveryPlanRoutes;
