import { createContext, Dispatch, SetStateAction } from 'react';
import type { CurrentUser as CurrentUserType } from '../../fragments/__generated__/CurrentUser';
import type { UnverifiedCurrentUser as UnverifiedCurrentUserType } from '../../fragments/__generated__/UnverifiedCurrentUser';

export type CurrentUserContextType = {
  currentUser: CurrentUserType | UnverifiedCurrentUserType | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUserType | UnverifiedCurrentUserType | undefined>>;
};

export const CurrentUserContext = createContext<CurrentUserContextType>([] as unknown as CurrentUserContextType);
