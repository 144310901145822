import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { View, StyleSheet } from 'react-native';
import { AppLink, AppModal, AppText, Check, Checkbox, MarkdownCheckboxOption } from '../../../../uiComponents';
import { ReviewInvitationFormValues } from '../ReviewInvitation.interface';
import { UserRoleInvitation } from '../../hooks/__generated__/UserRoleInvitation';
import { TermsAndConditions } from './TermsAndConditions';
import { Role } from '../../../../types/globalTypes';
import { useTranslations } from '../../../../i18n/useTranslations';

export interface AcceptFieldsProps {
  invitation: UserRoleInvitation;
}

export const AcceptFields: React.FC<AcceptFieldsProps> = ({ invitation }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<ReviewInvitationFormValues>();

  const handlePolicyToggle = (value: string[]) => {
    setFieldValue('educationPoliciesAccepted', value);
  };

  return (
    <>
      <Checkbox<string>
        value={values.educationPoliciesAccepted}
        onSelect={handlePolicyToggle}
        error={errors.educationPoliciesAccepted}
        touched={touched.educationPoliciesAccepted}
        options={invitation.educationItems.map((item) => ({
          label: item.instructionMarkdown || '',
          value: item.id,
        }))}
        customComponent={MarkdownCheckboxOption}
      />
    </>
  );
};
