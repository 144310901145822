import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { StepBackward, StepBackwardVariables } from './__generated__/StepBackward';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';

export const STEP_BACKWARD = gql`
  mutation StepBackward($input: StepBackwardInput!) {
    stepBackward(input: $input) {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${RecoveryPlanFragment}
`;

export const useStepBackward = (options?: MutationHookOptions<StepBackward, StepBackwardVariables>) =>
  useMutation<StepBackward, StepBackwardVariables>(STEP_BACKWARD, options);
