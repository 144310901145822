/* Entry on settings screen
=============================== */

import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { AppToggleSwitch } from '../AppToggleSwitch/AppToggleSwitch';
import { Icon, IconName } from '../Icon/Icon';
import { ColorKeys, useColors } from '../../shared/Ui';
import { AppText } from '../AppText/AppText';

interface SettingProps {
  color?: ColorKeys;
  icon?: IconName;
  label?: string;
  children?: any;
  value?: boolean;
  hint?: string;
  onPress?: () => void;
  onToggle?: (value?: any) => void;
  //...props;
}

export const Setting: React.FC<SettingProps> = ({
  color,
  icon,
  label,
  children,
  value,
  hint,
  onPress,
  onToggle,
  ...props
}) => {
  // funcs

  const [pressable, setPressable] = useState(onPress !== undefined || onToggle !== undefined);

  const pressHandler = () => {
    if (onPress) {
      onPress();
    }
    if (onToggle) {
      onToggle();
    }
  };

  // styles
  const colors = useColors();
  const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      marginVertical: 14,
    },
    iconHolder: {
      width: 36,
      height: 36,
      marginRight: 15,
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 2,
      borderRadius: 8,
    },
    icon: {
      color: colors.white,
    },
    mainLabel: {
      marginBottom: 4,
      paddingRight: 20,
    },
    secondaryLabel: {
      marginBottom: 4,
      marginLeft: 'auto',
      opacity: 0.65,
    },
    arrow: {
      color: colors.gray,
      width: 24,
      height: 24,
      marginLeft: 8,
      opacity: 0.65,
    },
    hint: {
      marginTop: -4,
      marginBottom: 10,
    },
  });

  return (
    <>
      <TouchableOpacity style={styles.container} onPress={pressHandler} disabled={!pressable}>
        {icon ? (
          <View style={[styles.iconHolder, color ? { backgroundColor: colors[color] } : {}]}>
            {icon ? <Icon name={icon} size={20} style={styles.icon} /> : null}
          </View>
        ) : null}
        <View style={{ flex: 1, marginRight: 20 }}>
          <AppText style={styles.mainLabel}>{children}</AppText>
        </View>
        {onToggle ? (
          <View pointerEvents="none">
            <AppToggleSwitch enabled={value} />
          </View>
        ) : null}
        {!onToggle && pressable ? (
          <>
            <AppText color="gray" font="bodyDefault" style={styles.secondaryLabel}>
              {label}
            </AppText>
            <Icon name="chevron-right" size={24} style={styles.arrow} />
          </>
        ) : null}
        {!pressable ? (
          <>
            <AppText color="gray" font="bodyDefault" style={styles.secondaryLabel}>
              {label}
            </AppText>
          </>
        ) : null}
      </TouchableOpacity>
      {hint ? (
        <AppText font="bodyDefault" color="gray" style={styles.hint}>
          {hint}
        </AppText>
      ) : null}
    </>
  );
};
