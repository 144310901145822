import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { CaseFragment } from '../../../../../fragments/CaseFragment';
import { SubjectCaseLogQuery, SubjectCaseLogQueryVariables } from './__generated__/SubjectCaseLogQuery';
import { SubjectMembershipsFragment } from '../../../../../fragments/SubjectMembershipsFragment';

export const SUBJECT_CASE_LOG_QUERY = gql`
  query SubjectCaseLogQuery($subjectInput: CurrentUserSubjectInput!, $caseInput: SubjectCaseInput!) {
    getSubject(input: $subjectInput) {
      ... on Subject {
        id
        firstName
        lastName
        fullName
        birthday
        case(input: $caseInput) {
          ... on Case {
            ...Case
          }
          ... on ApiError {
            ...ApiError
          }
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${CaseFragment}
`;

export const useSubjectCaseLogQuery = (options: UseQueryOptions<SubjectCaseLogQuery, SubjectCaseLogQueryVariables>) =>
  useQuery<SubjectCaseLogQuery, SubjectCaseLogQueryVariables>(SUBJECT_CASE_LOG_QUERY, options);
