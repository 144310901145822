import React from 'react';
import { SVGImageType, BaseTile } from '../../uiComponents';

export interface AdminTileProps {
  label: string;
  image?: SVGImageType;
  onPress: () => void;
}

export const AdminTile: React.FC<AdminTileProps> = ({ label, image, onPress }) => {
  return <BaseTile label={label} image={image} onPress={onPress} backgroundColor="white" labelColor="blue" />;
};
