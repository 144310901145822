import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../../../hooks/useMutation';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { SubmitStageDocument, SubmitStageDocumentVariables } from './__generated__/SubmitStageDocument';

export const SUBMIT_STAGE_DOCUMENT = gql`
  mutation SubmitStageDocument($input: SubmitStageDocumentInput!) {
    submitStageDocument(input: $input) {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${RecoveryPlanFragment}
`;

export const useSubmitStageDocument = (
  options?: MutationHookOptions<SubmitStageDocument, SubmitStageDocumentVariables>
) => useMutation<SubmitStageDocument, SubmitStageDocumentVariables>(SUBMIT_STAGE_DOCUMENT, options);
