import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { AppText, ShadowView } from '../../../../../uiComponents';

export interface Group {
  onPress: () => void;
  label: string;
}

export const Group: React.FC<Group> = ({ label, onPress }) => {
  const styles = StyleSheet.create({
    container: {
      backgroundColor: 'white',
      borderRadius: 8,
      padding: 16,
    },
    content: {
      textAlign: 'center',
    },
  });
  return (
    <TouchableOpacity onPress={onPress}>
      <ShadowView style={styles.container}>
        <AppText style={styles.content} color="blue" font="labelDefault">
          {label}
        </AppText>
      </ShadowView>
    </TouchableOpacity>
  );
};
