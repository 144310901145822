import React from 'react';
import ToggleSwitch from 'toggle-switch-react-native';
import { useColors } from '../../shared/Ui/hooks/useColors';

export const AppToggleSwitch = ({ enabled = false, ...props }) => {
  const colors = useColors();

  return (
    <ToggleSwitch
      isOn={enabled}
      animationSpeed={200}
      onColor={colors.green}
      offColor={colors.gray}
      onToggle={() => {}}
      {...props}
    />
  );
};
