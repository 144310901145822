import { CasesNavItem } from '../Cases.interface';
import { Content } from '../Content';
import { Tiles } from '../Tiles';

const casesRoutes: CasesNavItem[] = [
  {
    component: Tiles,
    label: 'Cases',
    name: 'LoggedIn_Cases_Index' as const,
    path: 'index',
  },
  {
    component: Content,
    label: 'CaseList',
    name: 'LoggedIn_Cases_CaseList' as const,
    path: 'caselist',
  },
];

export const useCasesRoutes = () => casesRoutes;
