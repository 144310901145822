import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ManageProtocolParamList } from './ManageProtocol.interface';
import { useManageProtocolRoutes } from './hooks/useManageProtocolRoutes';

const ManageProtocolNav = createStackNavigator<ManageProtocolParamList>();
const routes = useManageProtocolRoutes();

export interface ManageProtocolNavigatorProps {}

export const ManageProtocolNavigator: React.FC<ManageProtocolNavigatorProps> = () => {
  return (
    <ManageProtocolNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <ManageProtocolNav.Screen key={name} name={name} component={component} />
      ))}
    </ManageProtocolNav.Navigator>
  );
};
