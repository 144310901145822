import { Index } from '../Index';
import { SeasonDetails } from '../SeasonDetails/SeasonDetails';
import { ManageSeasonsNavItem } from '../ManageSeasons.interface';

const manageSeasonsRoutes: ManageSeasonsNavItem[] = [
  {
    name: 'LoggedIn_Admin_ManageSeasons_SeasonsDetails' as const,
    path: ':seasonId',
    component: SeasonDetails,
  },
  {
    name: 'LoggedIn_Admin_ManageSeasons_Index',
    path: '',
    component: Index,
  },
];

export const useManageSeasonsRoutes: () => ManageSeasonsNavItem[] = () => {
  return manageSeasonsRoutes;
};
