import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../hooks/useMutation';
import { ApiErrorFragment } from '../../fragments/ApiErrorFragment';
import { ChangeUserLanguage, ChangeUserLanguageVariables } from './__generated__/ChangeUserLanguage';

export const CHANGE_USER_LANGUAGE = gql`
  mutation ChangeUserLanguage($language: UserLanguage!) {
    changeLanguage(language: $language) {
      ... on Language {
        language
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useChangeUserLanguage = (options?: MutationHookOptions<ChangeUserLanguage, ChangeUserLanguageVariables>) =>
  useMutation<ChangeUserLanguage, ChangeUserLanguageVariables>(CHANGE_USER_LANGUAGE, options);
