import React, { useEffect, useState } from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import {
  AppButton,
  AppMarkdown,
  AppModal,
  AppScrollView,
  AppText,
  FileButton,
  FileButtonSelectedFile,
  ProtocolAttribution,
  Spacer,
} from '../../../../../../../uiComponents';
import { RecoveryPlanNavType } from '../../../RecoveryPlan.interface';
import { useSubmitStageDocument } from '../../../hooks/useSubmitStageDocument';
import { ProfileChrome } from '../../../../../ProfileChrome/ProfileChrome';
import { RecoveryPlanStageDocument } from '../../../fragments/__generated__/RecoveryPlanStageDocument';
import { LockedAction } from '../LockedAction';
import { RejectionReason } from '../RejectionReason';
import { useStepBackward } from '../../../hooks/useStepBackward';
import { RtaProtocolType, StageState } from '../../../../../../../types/globalTypes';
import { useDateFormat } from '../../../../../../../hooks/ui/useDateFormat';
import { differenceInMilliseconds } from 'date-fns';
import { useTimeGating } from '../../../hooks/useTimeGating';
import { FastTrackWarningModal } from '../FastTrackWarningModal';
import { useIsMobile } from '../../../../../../../hooks/ui/useResponsive';

export interface SubmitDocumentProps {
  stageDocument: RecoveryPlanStageDocument;
  caseCreatedDate: string;
}

export const SubmitDocument: React.FC<SubmitDocumentProps> = ({ stageDocument, caseCreatedDate }) => {
  const { getTimeGateEnd, startTimeGateEndCountdown, TimeGateMessage } = useTimeGating(
    stageDocument.stepStartTime,
    stageDocument.timeGateHours
  );

  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Stage'>>();
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [submitStageDocument] = useSubmitStageDocument();
  const [stepBackward] = useStepBackward();
  const [file, setFile] = useState<FileButtonSelectedFile>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSteppingBackward, setIsSteppingBackward] = useState(false);
  const [timeGateMessageColour, setTimeGateMessageColour] = useState<'red' | 'grayDark'>(
    differenceInMilliseconds(getTimeGateEnd(), Date.now()) > 0 ? 'red' : 'grayDark'
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    const timeGateEnd = getTimeGateEnd();
    startTimeGateEndCountdown(timeGateEnd);
  }, []);

  const { formatDateAndTime } = useDateFormat();

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const handleDownloadTemplate = () => {
    if (stageDocument.templateDocument?.downloadUri) {
      Linking.openURL(stageDocument.templateDocument.downloadUri);
    }
  };

  const handlePickDocument = async (val: FileButtonSelectedFile) => setFile(val);

  const handleClearDocument = () => setFile(undefined);

  const handleSubmitButtonPress = (isFastTracked: boolean) => {
    setIsSubmitting(true);
    returnProtocolDocumentSubmissionAction(isFastTracked).then(() => {
      setIsSubmitting(false);
      setShowStatusDialog(true);
    });
    setIsModalVisible(false);
  };

  const handleStepBackwardButtonPress = () => {
    setIsSteppingBackward(true);
    stepBackward({
      variables: {
        input: {
          caseId: stageDocument.caseId,
          stageId: stageDocument.id,
        },
      },
    }).then((res) => {
      setIsSteppingBackward(false);
      setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
    });
  };

  const returnProtocolDocumentSubmissionAction = (isFastTracked: boolean) => {
    if (!file) {
      throw new Error('no file set');
    }

    return submitStageDocument({
      variables: {
        input: {
          caseId: stageDocument.caseId,
          file: file,
          stageId: stageDocument.id,
          isFastTracked,
        },
      },
    });
  };

  const handleCloseStatusDialog = () => {
    setShowStatusDialog(false);
    setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
  };

  const canStepForward = stageDocument.iCanSubmit && stageDocument.isCurrentStage;
  const canStepBackward = stageDocument.order !== 0 && stageDocument.iCanSubmit && stageDocument.isCurrentStage;
  const isLocked = !stageDocument.iCanSubmit && stageDocument.isCurrentStage;

  const styles = StyleSheet.create({
    buttons: {
      marginTop: 0,
    },
    secondButton: {
      marginTop: 10,
    },
    buttonContainer: {
      marginTop: 100,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
  });

  const submitContent = stageDocument.iCanSubmit ? (
    <>
      <FastTrackWarningModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        timeGateHours={stageDocument.timeGateHours}
        stepForward={() => handleSubmitButtonPress(true)}
      />
      <View style={styles.buttons}>
        <AppButton icon="file-text" disabled={!stageDocument.iCanSubmit} onPress={handleDownloadTemplate}>
          {translate.label.downloadTemplate}
        </AppButton>
        <FileButton
          style={styles.secondButton}
          file={file}
          disabled={!stageDocument.iCanSubmit}
          onFileChange={handlePickDocument}
          onFileClear={handleClearDocument}
          mediaType="image/pdf"
        >
          {file ? translate.recovery.stageDocumentReview.document : translate.label.uploadDocument}
        </FileButton>
      </View>
    </>
  ) : null;

  const content = (
    <>
      <AppModal
        mode="dialog"
        title={translate.recovery.submitMedicalAssessment.documentsSubmitted}
        onDismiss={handleCloseStatusDialog}
        onRequestClose={handleCloseStatusDialog}
        buttons={{
          done: <AppButton onPress={handleCloseStatusDialog}>{translate.label.done}</AppButton>,
        }}
        visible={showStatusDialog}
      />
      <AppScrollView>
        <Spacer
          items={{
            titleArea: (
              <Spacer
                items={{
                  title: <AppText font="header1">{stageDocument.name}</AppText>,
                  step: (
                    <AppText font="labelDefault" color="grayDark">
                      {translateWithVars(translate.recovery.stageSignoff.subtitle, {
                        organization: stageDocument.organizationName,
                        protocol:
                          stageDocument.protocolType === RtaProtocolType.LEARN
                            ? translate.recovery.index.returnToLearn
                            : translate.recovery.index.returnToSport,
                      })}
                    </AppText>
                  ),
                  attribution: (
                    <ProtocolAttribution
                      protocolUrl={stageDocument.protocolUrl}
                      protocolName={stageDocument.protocolName}
                    />
                  ),
                }}
              />
            ),
            rejectionReason: <RejectionReason {...stageDocument} />,
            description: <AppMarkdown>{stageDocument.description}</AppMarkdown>,
            submitContent,
          }}
        />
        {stageDocument.timeGateHours && caseCreatedDate && stageDocument.state === StageState.ACTIVE ? (
          <TimeGateMessage timeGateHours={stageDocument.timeGateHours} />
        ) : null}
        <View style={styles.buttonContainer}>
          {canStepBackward ? (
            <AppButton
              disabled={isSubmitting || isSteppingBackward}
              loading={isSteppingBackward}
              type="outline"
              onPress={handleStepBackwardButtonPress}
              style={{ width: 200, marginRight: 15, marginBottom: 20 }}
            >
              {translate.label.stepBack}
            </AppButton>
          ) : null}
          {canStepForward ? (
            <AppButton
              disabled={!file || isSubmitting || isSteppingBackward}
              loading={isSubmitting}
              onPress={() =>
                timeGateMessageColour === 'grayDark' ? handleSubmitButtonPress(false) : setIsModalVisible(true)
              }
              style={{ width: 200 }}
            >
              {translate.label.submit}
            </AppButton>
          ) : null}
          {isLocked ? <LockedAction text={translate.recovery.stageDocumentSubmission.notAuthorized} /> : null}
        </View>
      </AppScrollView>
    </>
  );

  return <ProfileChrome autoScroll={false} activeTab="recovery" content={content} />;
};
