import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { Tabs } from '../../../../../uiComponents';
import { ManageGroupsNavType } from '../ManageGroups.interface';

export type GroupDetailNavigationTab = 'subjects' | 'designated-persons';
export interface GroupDetailNavigationProps {
  activeTab: GroupDetailNavigationTab;
  groupId: string;
}

export const GroupDetailNavigation: React.FC<GroupDetailNavigationProps> = ({ activeTab, groupId }) => {
  const { translate } = useTranslations();
  const navigation =
    useNavigation<
      ManageGroupsNavType<
        | 'LoggedIn_Admin_ManageGroups_GroupDetails_Subjects'
        | 'LoggedIn_Admin_ManageGroups_GroupDetails_DesignatedPersons'
      >
    >();

  return (
    <Tabs
      tabs={[
        {
          label: translate.admin.manageSeasons.groupDetails.subjects,
          onPress: () =>
            navigation.navigate('LoggedIn_Admin_ManageGroups_GroupDetails_Subjects', {
              groupId,
            }),
          active: activeTab === 'subjects',
        },
        {
          label: translate.users.rolesPlural.secondaryDesignatedPerson,
          onPress: () =>
            navigation.navigate('LoggedIn_Admin_ManageGroups_GroupDetails_DesignatedPersons', {
              groupId,
            }),
          active: activeTab === 'designated-persons',
        },
      ]}
    />
  );
};
