import { useNavigation } from '@react-navigation/core';
import { orderBy } from 'lodash/fp';
import React, { useEffect } from 'react';
import { AssessNavType, RedFlagValue } from '../../Assess.interface';
import { ProtocolAttribution } from '../../../../uiComponents';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { AssessRedFlag } from '../FindSubject/__generated__/AssessRedFlag';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AssessChrome } from '../../AssessChrome';
import { SymptomResult } from '../../../../types/globalTypes';
import { AppButton, AppMarkdown, AppScrollView, AppText, Checkbox, Spacer } from '../../../../uiComponents';
import { StyleSheet } from 'react-native';

export interface RedFlagsProps {}

export const RedFlags: React.FC<RedFlagsProps> = (props) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<AssessNavType<'LoggedIn_Assess_RedFlags'>>();
  const { state, dispatch } = useCurrentAssessment();
  const { redFlags: redFlagState, emergency, selectedProtocolOption } = state;
  const redFlagConfig: AssessRedFlag[] = selectedProtocolOption?.protocol.removeProtocol.redFlag.flags || [];
  const protocol = selectedProtocolOption?.protocol;
  if (!protocol) {
    throw new Error('Protocol not found');
  }

  const allFlags = protocol.removeProtocol.redFlag.flags;
  const viewed = redFlagState.viewed;
  const canAdvance = viewed ? true : false;
  const options = orderBy<AssessRedFlag>((fl) => fl.order, 'asc', redFlagConfig).map(({ id, value }) => ({
    label: value,
    value: id,
  }));

  const value = redFlagState.values
    .filter(({ result }) => result === SymptomResult.PRESENT)
    .map(({ redFlagId }) => redFlagId);
  useEffect(() => {
    if (!viewed) {
      dispatch({ type: 'setRedFlagsViewed' });
    }
  }, [viewed]);

  const handleSelect = (values: string[]) => {
    const _redFlags: RedFlagValue[] = allFlags.map(({ id }) => {
      if (!viewed) {
        return {
          redFlagId: id,
          result: null,
        };
      } else {
        return {
          redFlagId: id,
          result: values.includes(id) ? SymptomResult.PRESENT : SymptomResult.ABSENT,
        };
      }
    });
    dispatch({
      type: 'setRedFlags',
      payload: {
        redFlags: _redFlags,
      },
    });
  };

  const handleNext = () => {
    emergency ? navigation.push('LoggedIn_Assess_Emergency') : navigation.push('LoggedIn_Assess_Step');
  };

  const styles = StyleSheet.create({
    button: {
      width: 300,
      alignSelf: 'flex-start',
      marginBottom: 15,
    },
  });

  const content = (
    <AppScrollView>
      <Spacer
        items={{
          titleArea: (
            <Spacer
              items={{
                title: (
                  <AppText font="header2">{state.selectedProtocolOption?.protocol.removeProtocol.redFlag.name}</AppText>
                ),
                attribution: (
                  <ProtocolAttribution protocolUrl={protocol?.protocolUrl} protocolName={protocol?.protocolName} />
                ),
              }}
            />
          ),
          instruction: (
            <AppMarkdown>{state.selectedProtocolOption?.protocol.removeProtocol.redFlag.instruction}</AppMarkdown>
          ),
          checkboxes: <Checkbox value={value} options={options} onSelect={handleSelect} />,
        }}
      />
    </AppScrollView>
  );

  const bottomPanel = emergency ? (
    <AppButton disabled={!canAdvance} icon="alert-octagon" type="danger" onPress={handleNext} style={styles.button}>
      {translate.emergency.isEmergency}
    </AppButton>
  ) : (
    <AppButton disabled={!canAdvance} onPress={handleNext} style={styles.button}>
      {translate.label.none}
    </AppButton>
  );

  return (
    <AssessChrome
      content={
        <>
          {content}
          {bottomPanel}
        </>
      }
    />
  );
};
