import React, { FunctionComponent, useCallback } from 'react';
import { KeyboardAvoidingView, ScrollView, TouchableWithoutFeedback, View, ViewStyle, Platform } from 'react-native';
import { AppLink, AppText } from '../../../uiComponents';
import { BottomTabs } from './BottomTabs';
import { useFocusEffect } from '@react-navigation/native';
import { BackgroundProps } from './NavBarForMobile';
import { useIsMobile } from '../../../hooks/ui/useResponsive';

interface NavbarStyles {
  keyboardAvoidingView: ViewStyle;
  contentContainer: ViewStyle;
  header: ViewStyle;
  back: ViewStyle;
  wrapper: ViewStyle;
  footer: ViewStyle;
}

interface NavbarProps {
  background: FunctionComponent<BackgroundProps>;
  subNavigation?: React.ReactNode;
  content?: React.ReactNode;
  styles: NavbarStyles;
  footer?: React.ReactNode;
  title?: React.ReactNode;
  titleText?: string;
  navbar?: React.ReactNode;
  bottomTabs?: boolean;
  showHamburgerMenu: boolean;
  setShowHamburgerMenu: (boolean) => void;
  showLanguageMenu: boolean;
  setShowLanguageMenu: (boolean) => void;
  hideNavItems?: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({
  styles,
  background: Background,
  subNavigation,
  content,
  footer,
  title,
  titleText,
  navbar,
  bottomTabs,
  showHamburgerMenu,
  setShowHamburgerMenu,
  hideNavItems,
  setShowLanguageMenu,
  showLanguageMenu,
}) => {
  const hasHeader = title || titleText || (React.isValidElement(navbar) && navbar.props.onBack);

  const isMobile = useIsMobile();

  const Header = () => {
    return (
      <>
        <View
          style={[
            styles.header,
            {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            },
          ]}
        >
          {React.isValidElement(navbar) && navbar.props.onBack ? (
            <AppLink
              onPress={navbar.props.onBack}
              style={styles.back}
              icon="arrow-left"
              iconOpacity={1}
              color="navyDark"
            ></AppLink>
          ) : null}
          <View
            style={{
              width: isMobile ? '80%' : '100%',
              flexWrap: 'wrap',
            }}
          >
            {titleText ? <AppText font="header1">{titleText}</AppText> : null}
            {title}
          </View>
        </View>
      </>
    );
  };

  useFocusEffect(
    useCallback(() => {
      setShowHamburgerMenu(false);
      setShowLanguageMenu(false);
    }, [])
  );

  return (
    <TouchableWithoutFeedback
      disabled={!showHamburgerMenu && !showLanguageMenu}
      // there is a setTimeout to prevent the hamburger menu from closing first when selecting a hamburger menu item on Android. There should be a less hacky solution...
      onPress={() => {
        setTimeout(() => {
          setShowLanguageMenu(false);
          setShowHamburgerMenu(false);
        }, 1);
      }}
    >
      <KeyboardAvoidingView style={styles.keyboardAvoidingView} behavior="padding">
        <Background primaryColor="navyDark" secondaryColor="white" />
        <View style={styles.wrapper} pointerEvents={Platform.OS !== 'web' && showHamburgerMenu ? 'none' : 'auto'}>
          <ScrollView style={styles.contentContainer} showsVerticalScrollIndicator={false} nestedScrollEnabled={true}>
            <View>
              {hasHeader ? <Header /> : null}
              {subNavigation ? <View>{subNavigation}</View> : null}
              {content}
            </View>
          </ScrollView>
        </View>
        {footer ? <View style={styles.footer}>{footer}</View> : null}
        {bottomTabs ? <BottomTabs hideNavItems={hideNavItems} /> : null}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};
