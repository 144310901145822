/* Single tile
=============================== */

import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileActionItemsProps {
  onPress: () => void;
  disabled?: boolean;
  warning?: boolean;
}

export const TileActionItems: React.FC<TileActionItemsProps> = ({ onPress, warning, disabled = false }) => {
  const { translate } = useTranslations();

  return (
    <BaseTile
      backgroundColor="white"
      label={translate.home.tile.actionItems}
      onPress={onPress}
      warning={warning}
      tileHeight="short"
      tileWidth="full"
    />
  );
};
