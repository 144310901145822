import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { GET_ORGANIZATION_USERS } from '../../../hooks/useGetOrganizationUsers';
import {
  InviteUserToOrganizationRole,
  InviteUserToOrganizationRoleVariables,
} from './__generated__/InviteUserToOrganizationRole';
import { UserFragment } from '../../../../../fragments/UserFragment';

export const INVITE_USER_TO_ROLE_MUTATION = gql`
  mutation InviteUserToOrganizationRole($input: InviteUserToRoleInput!) {
    inviteUserToOrganizationRole(input: $input) {
      ... on User {
        ...User
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${UserFragment}
  ${ApiErrorFragment}
`;

export const useInviteUserToOrganizationRole = (
  options: MutationHookOptions<InviteUserToOrganizationRole, InviteUserToOrganizationRoleVariables>,
  organizationId,
  paginationArgs,
  searchStr: string | null,
  role: string | null = null
) =>
  useMutation<InviteUserToOrganizationRole, InviteUserToOrganizationRoleVariables>(INVITE_USER_TO_ROLE_MUTATION, {
    ...options,
    refetchQueries: [
      {
        query: GET_ORGANIZATION_USERS,
        variables: {
          organizationId,
          paginationArgs,
          searchStr,
          role,
        },
      },
      'GetOrganizationUsers',
    ],
  });
