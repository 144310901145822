import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { StyleSheet, View } from 'react-native';
import { isEqual } from 'lodash';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppDateTimePicker, AppText, Checkbox, Input, Select } from '../../../../../uiComponents';
import { Guardians } from './Guardians/Guardians';
import { InviteUserFormData } from './InvitationModal';
import { GroupPills } from './GroupPills';
import { GroupListItem } from '../../ManageGroups/GroupsList';
import { useGetGroupsByOrganizationId } from '../../ManageGroups/hooks/useGetGroupsByOrganizationId';
import { useAdminContext } from '../../../hooks/useAdminContext';
import { Role } from '../../../../../types/globalTypes';
import { InvitationModalProps } from './InvitationModal';

export const InvitationModalContent: React.FC<InvitationModalProps> = (props) => {
  const { translate } = useTranslations();
  const [groupsToAdd, setGroupsToAdd] = useState<GroupListItem[]>([]);
  const { organizationId } = useAdminContext();

  const { data, loading } = useGetGroupsByOrganizationId(organizationId);

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    useFormikContext<InviteUserFormData>();

  if (loading || !data?.groups) return null;
  const groups = data.groups;
  const currentGroup = props.defaultGroup ? props.defaultGroup : null;

  const styles = StyleSheet.create({
    namesWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      margin: -8,
      flex: 1,
    },
    nameInput: {
      flexBasis: `50%`,
      flexGrow: 0,
      flexShrink: 0,
      padding: 8,
      flex: 1,
    },
    groupSelection: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    pillContainer: {
      marginTop: 0,
      marginBottom: 100,
      height: 100,
    },
    addToGroupsLabelContainer: {
      marginTop: 10,
    },
  });

  const isSubject = isEqual(values.roles, [Role.SUBJECT]);

  const handleAddGroup = (selectedGroup: string) => {
    const foundGroup = groups && groups.find((item) => item.id === selectedGroup);
    if (foundGroup) {
      setFieldValue('groupList', values.groupList.concat({ id: foundGroup.id, name: foundGroup.name }));
    }
  };

  const handleRemoveGroup = (groupId: string) => {
    setGroupsToAdd(groupsToAdd.filter((group: GroupListItem) => group.id !== groupId));
    setFieldValue(
      'groupList',
      values.groupList.filter((groupListItem) => groupListItem.id !== groupId)
    );
  };

  const handleSelectRoles = (roles: string[]) => {
    if (!roles.includes(Role.SECONDARY_DESIGNATED_PERSON) && !roles.includes(Role.SUBJECT)) {
      currentGroup ? setFieldValue('groupList', [currentGroup]) : setFieldValue('groupList', []);
    }
    setFieldValue('roles', roles);
  };

  const unselectedGroups = groups
    ? groups
        .map((group: GroupListItem, idx: number) => ({
          key: idx.toString(),
          value: group.id,
          label: group.name,
        }))
        .filter((item) => !values.groupList.map((chosenGroup) => chosenGroup.id).includes(item.value))
    : [];

  return (
    <>
      <View style={styles.namesWrapper}>
        <View style={styles.nameInput}>
          <Input
            label={translate.placeholder.firstName}
            value={values.firstName}
            error={errors?.firstName}
            touched={touched?.firstName}
            onChangeText={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
          />
        </View>
        <View style={styles.nameInput}>
          <Input
            label={translate.placeholder.lastName}
            value={values.lastName}
            error={errors?.lastName}
            touched={touched?.lastName}
            onChangeText={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
          />
        </View>
      </View>
      <Input
        type="email"
        label={translate.placeholder.email}
        value={values.email}
        touched={touched?.email}
        error={errors?.email}
        onChangeText={handleChange('email')}
        onBlur={handleBlur('email')}
      />
      {isSubject ? (
        <AppDateTimePicker
          label={translate.placeholder.dateOfBirth}
          touched={touched?.birthday}
          error={errors?.birthday}
          value={values.birthday}
          maximumDate={new Date().toISOString()}
          onChange={(date: string) => setFieldValue('birthday', date)}
          onBlur={() => setFieldTouched('birthday', true)}
        />
      ) : null}
      <Checkbox
        value={values.roles}
        touched={touched?.roles}
        error={errors?.roles}
        options={[
          {
            label: translate.users.roles.admin,
            value: Role.ADMIN,
          },
          {
            label: translate.users.roles.primaryDesignatedPerson,
            value: Role.PRIMARY_DESIGNATED_PERSON,
          },
          {
            label: translate.users.roles.secondaryDesignatedPerson,
            value: Role.SECONDARY_DESIGNATED_PERSON,
          },
          {
            label: translate.users.roles.subject,
            value: Role.SUBJECT,
          },
          {
            label: translate.users.roles.guardian,
            value: Role.GUARDIAN,
          },
        ]}
        onSelect={(roles: string[]) => handleSelectRoles(roles)}
        // onBlur={() => setFieldTouched('roles', true)}
      />
      {isSubject ? (
        <FieldArray
          name="guardians"
          children={(formikProps) => <Guardians guardians={values.guardians} {...formikProps} />}
        />
      ) : null}
      {values.roles.includes(Role.SECONDARY_DESIGNATED_PERSON) || values.roles.includes(Role.SUBJECT) ? (
        <View style={styles.addToGroupsLabelContainer}>
          <AppText font="header3" style={{ marginVertical: 10 }}>
            {translate.users.sectionHeaders.addUserToGroups}
          </AppText>
          {groups && groups.length > 0 && unselectedGroups.length > 0 ? (
            <Select<string>
              style={styles.groupSelection}
              onChange={(e) => {
                handleAddGroup(e);
              }}
              title={translate.users.selectGroups.selectGroups}
              items={[{ key: '-1', value: '0', label: translate.users.selectGroups.selectAGroup }].concat(
                unselectedGroups
              )}
              value={''}
              error={''}
            />
          ) : null}

          {values.groupList.length > 0 ? (
            <View style={styles.pillContainer}>
              <GroupPills groups={values.groupList} onRemoveRole={(group) => handleRemoveGroup(group)} />
            </View>
          ) : null}
        </View>
      ) : null}
    </>
  );
};
