import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, SectionWrapper } from '../../../../uiComponents';
import { GroupsList } from './GroupsList';
import { ManageGroupsNavType } from './ManageGroups.interface';
import { CreateGroupModal } from './CreateGroupModal/CreateGroupModal';
import { useGetGroupsByOrganizationId } from './hooks/useGetGroupsByOrganizationId';
import { useAdminContext } from '../../hooks/useAdminContext';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { Role } from '../../../../types/globalTypes';

export const Groups: React.FC<{}> = () => {
  const { translate } = useTranslations();
  const { organizationId } = useAdminContext();
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);

  const { currentUser } = useCurrentUser();

  const isUserAnAdmin =
    currentUser?.__typename === 'CurrentUser'
      ? currentUser.roles
          .filter((item) => item.role === Role.ADMIN)
          .filter((item) => item.organizationId === organizationId).length > 0
      : false;

  const { data, loading } = useGetGroupsByOrganizationId(organizationId);
  if (loading || !data?.groups) return null;
  const groups = data.groups;

  const navigation = useNavigation<ManageGroupsNavType<'LoggedIn_Admin_ManageGroups_Index'>>();

  const handleGroupPressed = (groupId: string) => {
    navigation.navigate('LoggedIn_Admin_ManageGroups_GroupDetails_Subjects', {
      groupId,
    });
  };

  const handleOpenCreateGroupModal = () => setShowCreateGroupModal(true);
  const handleDismissCreateGroupModal = () => setShowCreateGroupModal(false);
  const onBack = () => navigation.navigate('LoggedIn_Admin', { screen: 'LoggedIn_Admin_Index', organizationId });

  return isUserAnAdmin ? (
    <Chrome
      navBarProps={{ showHamburger: false, onBack }}
      mode="title-card"
      titleText={translate.myProfile.memberships.groups}
      subtitleHighlighted={true}
      content={
        <>
          <CreateGroupModal
            visible={showCreateGroupModal}
            organizationId={organizationId}
            onDismiss={handleDismissCreateGroupModal}
          />
          <SectionWrapper>
            <GroupsList onPress={handleGroupPressed} items={groups} />
            <AppButton style={{ marginLeft: 'auto', marginTop: 30 }} onPress={handleOpenCreateGroupModal}>
              {translate.admin.manageSeasons.seasonDetails.addGroup}
            </AppButton>
          </SectionWrapper>
        </>
      }
    />
  ) : (
    <></>
  );
};
