import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { TrainingChrome } from '../TrainingChrome';
import { TrainingNavType } from '../Training.interface';
import { BaseTile, TileProps, Tiles } from '../../../uiComponents/Tiles';
import { useTranslations } from '../../../i18n/useTranslations';

export const Index: React.FC = (props) => {
  const navigation = useNavigation<TrainingNavType<'LoggedIn_Training_Index'>>();
  const { translate } = useTranslations();

  const handleGoBack = () => {
    navigation.navigate('LoggedIn_Home');
  };

  const handleResources = () => {
    navigation.navigate('LoggedIn_Training', {
      screen: 'LoggedIn_Training_Resources',
      params: { isPrimaryDesignatedPerson: true },
    });
  };

  const handleReport = () => {
    navigation.navigate('LoggedIn_Training', {
      screen: 'LoggedIn_Training_Report',
    });
  };
  const handleEducationReport = () => {
    navigation.navigate('LoggedIn_Training', {
      screen: 'LoggedIn_Education_Report',
    });
  };

  const tiles: TileProps[] = [
    {
      key: 'report',
      tile: <BaseTile label={translate.training.label.report} onPress={handleReport} />,
      width: 'half',
    },
    {
      key: 'resources',
      tile: <BaseTile label={translate.training.label.resources} onPress={handleResources} />,
      width: 'half',
    },
    {
      key: 'EducationReport',
      tile: <BaseTile label={translate.education.label.resources} onPress={handleEducationReport} />,
      width: 'half',
    },
  ];

  return (
    <TrainingChrome onBack={handleGoBack} titleText={translate.training.trainingAndEducation} showHamburger={true}>
      <Tiles tiles={tiles} />
    </TrainingChrome>
  );
};
