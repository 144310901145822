import { gql, MutationHookOptions } from '@apollo/client';
import { CaseFragment } from '../../../fragments/CaseFragment';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';
import { SubmitRemoveProtocol, SubmitRemoveProtocolVariables } from './__generated__/SubmitRemoveProtocol';

export const SUBMIT_REMOVE_PROTOCOL = gql`
  mutation SubmitRemoveProtocol($input: SubmitRemoveProtocolInput!) {
    submitRemoveProtocol(input: $input) {
      ... on Case {
        ...Case
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${CaseFragment}
`;

export const useSubmitRemoveProtocol = (
  options?: MutationHookOptions<SubmitRemoveProtocol, SubmitRemoveProtocolVariables>
) => useMutation<SubmitRemoveProtocol, SubmitRemoveProtocolVariables>(SUBMIT_REMOVE_PROTOCOL, options);
