import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { SubjectStatus } from '../../types/globalTypes';
import { AppText } from '../AppText/AppText';
import { useStatusColor } from './useStatusColor';
import { useStatusLabel } from './useStatusLabel';

interface SubjectSimpleStatusIconProps {
  subjectStatus: SubjectStatus;
  style?: StyleProp<ViewStyle>;
}

export const SubjectSimpleStatusIcon: React.FC<SubjectSimpleStatusIconProps> = ({ subjectStatus, style }) => {
  const getLabel = useStatusLabel();
  const getColor = useStatusColor();
  const styles = StyleSheet.create({
    itemIndicatorContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemIndicatorLabel: {
      color: getColor(subjectStatus),
    },
  });

  return (
    <View style={StyleSheet.flatten([styles.itemIndicatorContainer, style])}>
      <AppText font="bodyDefault" style={styles.itemIndicatorLabel}>
        {getLabel(subjectStatus)}
      </AppText>
    </View>
  );
};
