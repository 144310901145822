import React from 'react';
import { View, Text } from 'react-native';
import { AppLink, AppText } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import RNJsxParser from 'react-native-jsx-parser';

export const TermsAndConditions = () => {
  const { translate } = useTranslations();

  return <RNJsxParser components={{ AppText, AppLink, View, Text }} jsx={translate.termsAndConditions} />;
};
