import React from 'react';
import { StyleSheet, View } from 'react-native';

export type TileWidth = 'half' | 'full';
export type TileContainerHeight = 'short' | 'full';

export interface TileProps {
  key: string;
  tile: React.ReactNode;
  width: TileWidth;
  tileContainerHeight?: TileContainerHeight;
}
export interface TilesProps {
  tiles: Array<TileProps>;
}

export const Tiles: React.FC<TilesProps> = ({ tiles }) => {
  const styles = StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    tile: {
      height: 'auto',
      minHeight: 200,
      padding: 8,
    },
    halfWidth: {
      width: '50%',
    },
    fullWidth: {
      width: '100%',
    },
    halfHeightTile: {
      minHeight: 100,
      padding: 8,
      height: 'auto',
    },
  });

  return (
    <View style={styles.wrapper}>
      {tiles.map(({ key, tile, width, tileContainerHeight }) => (
        <View
          key={key}
          style={StyleSheet.flatten([
            tileContainerHeight === 'short' ? styles.halfHeightTile : styles.tile,
            width === 'half' ? styles.halfWidth : styles.fullWidth,
          ])}
        >
          {tile}
        </View>
      ))}
    </View>
  );
};
