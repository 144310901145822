import * as Sentry from '@sentry/react-native';
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Updates from "expo-updates";
import { appConfig } from '../../app.config';

export const InitializeSentry = () => {
  const { sentry } = appConfig;

  if (sentry) {
    Sentry.init({
      ...sentry,
      // enabled: !__DEV__,
    });

    Sentry.setExtras({
      manifest: Updates.manifest,
      deviceYearClass: Device.deviceYearClass,
      linkingUri: Constants.linkingUri,
    });

    Sentry.setTag("expoChannel", Updates?.channel);
    Sentry.setTag("runtimeVersion", Updates?.runtimeVersion);
    Sentry.setTag("deviceId", Constants.sessionId);
    Sentry.setTag("appOwnership", Constants.appOwnership || "N/A");
    if (Constants.appOwnership === "expo" && Constants.expoVersion) {
      Sentry.setTag("expoAppVersion", Constants.expoVersion);
    }
    Sentry.setTag("expoChannel", Updates.channel);
  }
};
