import { gql } from '@apollo/client';

export const BasicUserFragment = gql`
  fragment BasicUser on User {
    id
    fullName
    firstName
    lastName
    email
  }
`;
