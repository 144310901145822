import * as React from 'react';
import { App } from './apps/frontend/App';
import { registerRootComponent } from 'expo';
class Main extends React.Component {
  render() {
    return <App />;
  }
}

registerRootComponent(Main);
