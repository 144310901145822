import { FieldArrayRenderProps, FormikErrors, useFormikContext } from 'formik';
import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { isString } from 'lodash/fp';
import { isUndefined } from 'lodash';
import { InviteGuardianFormData, InviteUserFormData } from '../InvitationModal';
import { AppText, Icon, Input, SectionWrapper } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';

export interface GuardianProps extends FieldArrayRenderProps {
  index: number;
}

export const Guardian: React.FC<GuardianProps> = ({ index, remove }) => {
  const { translate, translateWithVars } = useTranslations();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<InviteUserFormData>();

  const styles = StyleSheet.create({
    titleContainer: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    namesWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      margin: -8,
      flex: 1,
    },
    nameInput: {
      flexBasis: `50%`,
      flexGrow: 0,
      flexShrink: 0,
      padding: 8,
      flex: 1,
    },
  });

  const guardianValue = values.guardians[index];
  const guardianTouched = touched?.guardians ? touched?.guardians[index] : undefined;

  const _guardianErrors = errors?.guardians;

  let guardiansErrors: FormikErrors<InviteGuardianFormData> = {};

  if (_guardianErrors && !isUndefined(_guardianErrors[index])) {
    const guardianErrors = _guardianErrors[index];

    if (!isString(guardianErrors)) {
      guardiansErrors = guardianErrors;
    }
  }

  return (
    <>
      <SectionWrapper key={index}>
        <View style={styles.titleContainer}>
          <AppText font="header3">
            {translateWithVars(translate.users.addUser.guardians, {
              index: index + 1,
            })}
          </AppText>
          <TouchableOpacity onPress={() => remove(index)}>
            <Icon size={24} color="red" name="trash" />
          </TouchableOpacity>
        </View>
        <View style={styles.namesWrapper}>
          <View style={styles.nameInput}>
            <Input
              label={translate.placeholder.firstName}
              value={guardianValue.firstName}
              error={guardiansErrors?.firstName}
              touched={guardianTouched?.firstName}
              onChangeText={handleChange(`guardians.${index}.firstName`)}
              onBlur={handleBlur(`guardians.${index}.firstName`)}
            />
          </View>
          <View style={styles.nameInput}>
            <Input
              label={translate.placeholder.lastName}
              value={guardianValue.lastName}
              error={guardiansErrors?.lastName}
              touched={guardianTouched?.lastName}
              onChangeText={handleChange(`guardians.${index}.lastName`)}
              onBlur={handleBlur(`guardians.${index}.lastName`)}
            />
          </View>
        </View>
        <Input
          type="email"
          label={translate.placeholder.email}
          value={guardianValue.email}
          error={guardiansErrors?.email}
          touched={guardianTouched?.email}
          onChangeText={handleChange(`guardians.${index}.email`)}
          onBlur={handleBlur(`guardians.${index}.email`)}
        />
      </SectionWrapper>
    </>
  );
};
