import * as React from 'react';
import { useState } from 'react';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Content } from './Content';
import { CheckForFeatureFlag } from '../../../shared/CheckForFeatureFlag';
import { FeatureFlag } from '../../../../types/globalTypes';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { AdminNavType } from '../../../Admin/Admin.interface';
import { Tabs } from './ImportData.interface';
import { ImportDataNavigation } from './ImportDataNavigation';
import { useAdminContext } from '../../../Admin/hooks/useAdminContext';

interface ImportDataChromeProps {}

export const ImportData: React.FC<ImportDataChromeProps> = () => {
  const { translate } = useTranslations();
  const route = useRoute();
  const { organizationId: adminOrganizationId } = useAdminContext();

  const navigationSuperAdmin = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();
  const navigationAdmin = useNavigation<AdminNavType<'LoggedIn_Admin_Index'>>();

  const onBack = () => {
    if (route.name === 'LoggedIn_SuperAdmin_ImportData') {
      navigationSuperAdmin.navigate('LoggedIn_SuperAdmin', { screen: 'LoggedIn_SuperAdmin_Index' });
    } else if (route.name === 'LoggedIn_Admin_ImportData') {
      navigationAdmin.navigate('LoggedIn_Admin', {
        screen: 'LoggedIn_Admin_Index',
        organizationId: adminOrganizationId,
      });
    }
  };

  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.IMPORT_DATA);

  const ChromeChildren = () => {
    return (
      <CheckForFeatureFlag featureFlag={FeatureFlag.ENABLE_CSV_IMPORTER} orRoles={['SUPER_ADMIN']}>
        <Content currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </CheckForFeatureFlag>
    );
  };

  return (
    <Chrome
      navBarProps={{ showHamburger: false, onBack }}
      mode="title-card"
      titleText={translate.superAdmin.importData.importData}
      content={<ChromeChildren />}
      subNavigation={<ImportDataNavigation setCurrentTab={setCurrentTab} currentTab={currentTab} />}
    />
  );
};
