/* Pill shape (icon + text)
=============================== */

import React from 'react';
import { StyleSheet, View, ViewStyle, Platform } from 'react-native';
import { Icon, IconName } from '../Icon/Icon';
import { AppText } from '../AppText/AppText';
import { useColors } from '../../shared/Ui/hooks/useColors';

interface PillProps {
  icon?: IconName;
  label?: string;
  color?: string;
  style?: ViewStyle;
}

export const Pill: React.FC<PillProps> = ({ icon, label = '', color = 'blue', style }) => {
  // styles

  const colors = useColors();
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingVertical: 7,
      paddingHorizontal: 15,
      backgroundColor: colors[color],
      borderRadius: 99,
    },
    icon: {
      color: colors.black,
      opacity: 0.45,
      marginTop: 2,
      marginRight: 6,
    },
    label: {
      flexShrink: 1,
      marginTop:
        Platform.select<number>({
          ios: 4,
          android: 0,
          web: 0,
        }) || 0,
    },
  });

  const iconDisplay = icon ? <Icon name={icon} size={18} style={styles.icon} /> : null;

  return (
    <View style={[styles.container, style]}>
      {iconDisplay}
      <AppText font="labelDefault" color="white" style={styles.label}>
        {label}
      </AppText>
    </View>
  );
};
