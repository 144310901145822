import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { GetGroupById, GetGroupByIdVariables } from './__generated__/GetGroupById';
import { GroupFragment } from '../fragments/GroupFragment';
import { AdminSubjectFragment } from '../fragments/AdminSubjectFragment';
import { BasicUserFragment } from '../../../../../fragments/BasicUserFragment';

export const GET_GROUP_BY_ID_QUERY = gql`
  query GetGroupById($organizationId: ID!, $groupId: ID!, $paginationArgs: PaginationArgs, $searchStr: String) {
    group(organizationId: $organizationId, groupId: $groupId) {
      ... on Group {
        ...Group
      }
      ... on ApiError {
        ...ApiError
      }
    }
    groupSubjects(
      groupId: $groupId
      organizationId: $organizationId
      paginationArgs: $paginationArgs
      searchStr: $searchStr
    ) {
      ...AdminSubject
    }
    groupDesignatedPersons(
      groupId: $groupId
      organizationId: $organizationId
      paginationArgs: $paginationArgs
      searchStr: $searchStr
    ) {
      ...BasicUser
    }
  }
  ${ApiErrorFragment}
  ${GroupFragment}
  ${AdminSubjectFragment}
  ${BasicUserFragment}
`;

export const useGetGroupById = (options?) =>
  useQuery<GetGroupById, GetGroupByIdVariables>(GET_GROUP_BY_ID_QUERY, options);
