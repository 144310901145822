import * as React from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useColors, useShadow } from '../../shared/Ui';
import { Input } from '../Input/Input';
import { Icon } from '../Icon/Icon';
import { AutocompleteList } from './AutocompleteList';
import { AutocompleteListItemType } from './AutocompleteListItem';

interface Debounce {
  milliseconds: number;
  callback: (string) => void;
  textValue: string;
}

interface AutocompleteProps<T> {
  items: AutocompleteListItemType<T>[];
  onClear?: () => void;
  onSelect?: (result: T) => void;
  query?: string;
  onQueryChange?: (query: string) => void;
  queryPlaceholder?: string;
  style?: StyleProp<ViewStyle>;
  clearIcon?: boolean;
  debounce?: Debounce;
}

export const Autocomplete = <T,>({
  style,
  items,
  onClear,
  onSelect,
  query,
  onQueryChange,
  queryPlaceholder,
  clearIcon = true,
}: AutocompleteProps<T>) => {
  const colors = useColors();
  const shadow = useShadow();

  const styles = StyleSheet.create({
    header: {
      backgroundColor: colors.white,
      flexDirection: 'row',
      flexGrow: 0,
      alignItems: 'center',
      marginBottom: 20,
    },
    close: {
      marginLeft: 10,
      color: colors.grayDark,
    },
    container: {
      flex: 1,
    },
    list: {
      ...shadow,
      borderRadius: 8,
      backgroundColor: colors.white,
      flex: 1,
      paddingLeft: 15,
      paddingRight: 15,
    },
  });

  return (
    <View style={[styles.container, style]}>
      <View style={styles.header}>
        <Input
          style={{ paddingTop: 7, paddingBottom: 7 }}
          value={query}
          onChangeText={onQueryChange}
          autoFocus={true}
          placeholder={queryPlaceholder}
          icon="search"
          afterComponent={
            clearIcon ? (
              <TouchableOpacity onPress={onClear}>
                <Icon name="x" size={32} style={styles.close} />
              </TouchableOpacity>
            ) : null
          }
        />
      </View>
      <View style={styles.list}>
        <AutocompleteList items={items} onSelect={onSelect} />
      </View>
    </View>
  );
};
