import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useInvitationQuery } from '../hooks/useInvitationQuery';
import { useCurrentUser } from '../../../shared/CurrentUser/useCurrentUser';
import { LoggedInNavType, LoggedInRouteProp } from '../../LoggedIn.interface';
import { Chrome } from '../../shared/Chrome/Chrome';
import { RightUser } from './RightUser/RightUser';
import { useTranslations } from '../../../i18n/useTranslations';
import { ErrorCode } from '../../../types/globalTypes';
import { AppButton, AppText, Spacer } from '../../../uiComponents';
import { useLogout } from '../../../shared/Firebase';

export interface ReviewInvitationProps {}

export const ReviewInvitation: React.FC<ReviewInvitationProps> = (props) => {
  const root = useRoute<LoggedInRouteProp<'LoggedIn_ReviewInvitation'>>();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ReviewInvitation'>>();
  const { currentUser } = useCurrentUser();
  const invitationIdentifier = root.params.invitationIdentifier;

  const { translate, translateWithVars } = useTranslations();
  const { logout } = useLogout();
  const { data } = useInvitationQuery({
    variables: {
      input: {
        identifier: invitationIdentifier as any as string,
      },
    },
    skip: !invitationIdentifier,
  });

  const handleGoHome = () => navigation.navigate('LoggedIn_Home');
  const handleLogout = () => {
    logout();
  };

  let content: React.ReactNode = null;
  let footer: React.ReactNode = null;
  const foundInvitation = data?.invitation.__typename === 'FoundInvitation' ? data.invitation.foundInvitation : null;

  if (foundInvitation) {
    const wrongUser = !foundInvitation.user.email || foundInvitation.user.email !== currentUser?.email;

    if (!wrongUser) {
      return <RightUser invitation={foundInvitation} />;
    } else {
      content = (
        <Spacer
          size="large"
          items={{
            title: (
              <AppText gutter={true} font="header3">
                {translate.reviewInvitation.wrongUser.title}
              </AppText>
            ),
            message: (
              <AppText>
                {translateWithVars(translate.reviewInvitation.wrongUser.message, {
                  currentEmail: currentUser?.email,
                  invitationEmail: foundInvitation.user.email,
                })}
              </AppText>
            ),
          }}
        />
      );

      footer = (
        <AppButton onPress={handleLogout} style={{ width: 200, alignSelf: 'flex-end', marginTop: 20 }}>
          {translate.wrongUser.logout}
        </AppButton>
      );
    }
  } else if (data?.invitation.__typename === 'ApiError') {
    if (data.invitation.code === ErrorCode.INVITATION_NOT_FOUND) {
      content = (
        <Spacer
          size="large"
          items={{
            title: <AppText font="header3">{translate.reviewInvitation.invitationNotFound.title}</AppText>,
            message: <AppText>{translate.invitation.invitationNotFound.message}</AppText>,
            button: <AppButton onPress={handleGoHome}>{translate.invitation.invitationNotFound.goHome}</AppButton>,
          }}
        />
      );
    }
  }

  return (
    <Chrome
      mode="title-card"
      titleText={translate.reviewInvitation.title}
      content={
        <>
          {content}
          {footer}
        </>
      }
    />
  );
};
