import React, { useState } from 'react';
import { Keyboard, Linking, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { DocumentReviewResult, MedicalAssessmentDiagnosis } from '../../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { useColors, useShadow } from '../../../../../../../shared/Ui/hooks';
import {
  AppButton,
  AppMarkdown,
  AppModal,
  AppScrollView,
  AppText,
  Input,
  ProtocolAttribution,
  Spacer,
} from '../../../../../../../uiComponents';
import { RecoveryPlanNavType } from '../../../RecoveryPlan.interface';
import { useReviewMedicalAssessment } from '../../../hooks/useReviewMedicalAssessment';
import { useRecoveryPlan } from '../../../hooks/useRecoveryPlan';
import { ProfileChrome } from '../../../../../ProfileChrome/ProfileChrome';
import { MedicalAssessment } from '../../../fragments/__generated__/MedicalAssessment';
import { LockedAction } from '../LockedAction';
import { RejectionReason } from '../RejectionReason';

export interface ReviewMedicalAssessmentProps {
  medicalAssessment: MedicalAssessment;
}

export const ReviewMedicalAssessment: React.FC<ReviewMedicalAssessmentProps> = ({ medicalAssessment }) => {
  const colors = useColors();
  const shadow = useShadow();
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Stage'>>();

  const data = useRecoveryPlan();
  const [reviewMedicalAssessment] = useReviewMedicalAssessment();

  const [showRejectionReasonDialog, setShowRejectionReasonDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<string>('');
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const diagnosis =
    medicalAssessment.diagnosis === MedicalAssessmentDiagnosis.CONCUSSION
      ? translate.recovery.submitMedicalAssessment.concussion
      : translate.recovery.submitMedicalAssessment.noConcussion;

  const postMedicalAssessmentReview = async (result: DocumentReviewResult) => {
    if (!medicalAssessment.document || !medicalAssessment.diagnosis) {
      throw new Error('Missing medical assessment document or diagnosis!');
    }

    return reviewMedicalAssessment({
      variables: {
        input: {
          caseId: medicalAssessment.caseId,
          diagnosis: medicalAssessment.diagnosis,
          documentId: medicalAssessment.document.id,
          reviewResult: result,
          rejectionReason,
        },
      },
    });
  };

  const handleAcceptButtonPress = () => {
    setIsApproving(true);
    postMedicalAssessmentReview(DocumentReviewResult.APPROVED).then(() => {
      setShowStatusDialog(true);
      setIsApproving(false);
    });
  };

  const handleRejectButtonPress = () => {
    setShowRejectionReasonDialog(true);
  };

  const handleViewDocument = () => {
    if (medicalAssessment.document?.downloadUri) {
      Linking.openURL(medicalAssessment.document.downloadUri);
    }
  };

  const handleRejectionReasonChange = (reason: string) => {
    setRejectionReason(reason);
  };

  const handleDialogCancelButton = () => {
    setShowRejectionReasonDialog(false);
  };

  const handleDialogSubmitButton = () => {
    setIsRejecting(true);
    postMedicalAssessmentReview(DocumentReviewResult.REJECTED).then(() => {
      setShowRejectionReasonDialog(false);
      setIsRejecting(false);
      setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
    });
  };

  const handleCloseStatusDialog = () => {
    setShowStatusDialog(false);
    setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
  };

  const styles = StyleSheet.create({
    itemGroupContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...shadow,
      padding: 10,
      paddingLeft: 14,
      marginBottom: 12,
      backgroundColor: colors.white,
      borderRadius: 8,
      width: '100%',
    },
    buttonPanel: {
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      paddingBottom: 24,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 20,
      flexWrap: 'wrap',
    },
    rejectButton: {
      width: 200,
      marginTop: 20,
      marginRight: 15,
    },
    approveButton: {
      width: 200,
      marginTop: 20,
    },
    diagnosisHeading: {
      marginTop: 20,
    },
  });

  const rejectionReasonDialog = (
    <AppModal
      mode="dialog"
      title={translate.recovery.reviewMedicalAssessment.rejectionReason}
      visible={showRejectionReasonDialog}
      buttonOrientation="horizontal"
      onDismiss={handleDialogCancelButton}
      onRequestClose={handleDialogCancelButton}
      buttons={{
        cancel: (
          <AppButton type="outline" onPress={handleDialogCancelButton}>
            {translate.label.cancel}
          </AppButton>
        ),
        submit: <AppButton onPress={handleDialogSubmitButton}>{translate.label.submit}</AppButton>,
      }}
    >
      <Input
        value={rejectionReason}
        placeholder={translate.label.reason}
        type="text"
        numLines={5}
        numberOfLines={5}
        multiline={true}
        onSubmitEditing={Keyboard.dismiss}
        onChangeText={handleRejectionReasonChange}
      />
    </AppModal>
  );

  const content = (
    <>
      <AppModal
        mode="dialog"
        title={translateWithVars(
          medicalAssessment.diagnosis === MedicalAssessmentDiagnosis.CONCUSSION
            ? translate.recovery.reviewMedicalAssessment.onProtocol
            : translate.recovery.reviewMedicalAssessment.resumingActivity,
          {
            name: data?.fullName || '',
          }
        )}
        onDismiss={handleCloseStatusDialog}
        onRequestClose={handleCloseStatusDialog}
        buttons={{
          done: <AppButton onPress={handleCloseStatusDialog}>{translate.label.done}</AppButton>,
        }}
        visible={showStatusDialog}
      />
      <AppScrollView>
        <Spacer
          items={{
            titleArea: (
              <Spacer
                items={{
                  title: (
                    <AppText font="header1">{translate.recovery.submitMedicalAssessment.medicalAssessment}</AppText>
                  ),
                  attribution: (
                    <ProtocolAttribution
                      protocolName={medicalAssessment.protocolName}
                      protocolUrl={medicalAssessment.protocolUrl}
                    />
                  ),
                }}
              />
            ),
            rejectionReason: <RejectionReason {...medicalAssessment} />,
            diagnosisHeading: (
              <AppText style={styles.diagnosisHeading} font="header5">
                {translate.recovery.submitMedicalAssessment.diagnosis}
              </AppText>
            ),
            diagnosis: <AppText>{diagnosis}</AppText>,
            instruction: <AppMarkdown>{medicalAssessment.instruction}</AppMarkdown>,
            buttons: (
              <View style={styles.buttons}>
                <TouchableOpacity style={styles.itemGroupContainer} onPress={handleViewDocument}>
                  <AppText>{translate.recovery.submitMedicalAssessment.medicalAssessment}</AppText>
                  <AppText color="blue" font="labelDefault">
                    {translate.label.view}
                  </AppText>
                </TouchableOpacity>
              </View>
            ),
          }}
        />
      </AppScrollView>
      {rejectionReasonDialog}
    </>
  );

  const bottomButtons = (
    <View style={styles.buttons}>
      {medicalAssessment.iCanReview ? (
        <AppButton
          type="outline"
          loading={isRejecting}
          disabled={isApproving || isRejecting}
          onPress={handleRejectButtonPress}
          style={styles.rejectButton}
        >
          {translate.label.reject}
        </AppButton>
      ) : null}
      {medicalAssessment.iCanReview ? (
        <AppButton
          loading={isApproving}
          disabled={isApproving || isRejecting}
          onPress={handleAcceptButtonPress}
          style={styles.approveButton}
        >
          {translate.label.approve}
        </AppButton>
      ) : null}
      {!medicalAssessment.iCanReview ? (
        <LockedAction text={translate.recovery.reviewMedicalAssessment.notAuthorized} />
      ) : null}
    </View>
  );

  return (
    <ProfileChrome
      activeTab="recovery"
      autoScroll={false}
      content={
        <>
          {content}
          {bottomButtons}
        </>
      }
    />
  );
};
