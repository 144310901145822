import React from 'react';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppModal, AppText } from '../../../../../../uiComponents';
import { Role } from '../../../../../../types/globalTypes';
import { Content } from './Content';

export interface InvitationModalProps {
  title: string;
  visible: boolean;
  onDismiss: () => void;
  onAdd: (email: string) => void;
  submitting?: boolean;
  role: Role;
  excludeGroupId: string;
  organizationId: string;
}

export const InvitationModal: React.FC<InvitationModalProps> = ({
  title,
  visible,
  onDismiss,
  onAdd,
  role,
  excludeGroupId,
  organizationId,
}) => {
  const { translate } = useTranslations();

  const handleSelect = (id: string) => {
    onAdd(id);
  };

  const handleDismiss = () => {
    onDismiss();
  };

  return (
    <AppModal mode="full-screen" visible={visible} title={title} onDismiss={handleDismiss}>
      <AppText gutter={true}>{translate.admin.manageSeasons.groupDetails.inviteInstruction}</AppText>
      <Content role={role} organizationId={organizationId} excludeGroupId={excludeGroupId} onSelect={handleSelect} />
    </AppModal>
  );
};
