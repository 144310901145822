import { Index } from '../Index';
import { ManageOrganizationNavItem } from '../ManageOrganization.interface';

const manageOrganizationRoutes: ManageOrganizationNavItem[] = [
  {
    name: 'LoggedIn_Admin_ManageOrganization_Index' as const,
    path: '',
    component: Index,
  },
];

export const useManageOrganizationRoutes: () => ManageOrganizationNavItem[] = () => {
  return manageOrganizationRoutes;
};
