/* Single tile
=============================== */

import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileConcussionProps {
  onPress: () => void;
  disabled?: boolean;
}

export const TileConcussion: React.FC<TileConcussionProps> = ({ onPress, disabled = false }) => {
  const { translate } = useTranslations();

  return (
    <BaseTile
      backgroundColor="red"
      labelColor="white"
      label={translate.home.tile.recognize}
      onPress={onPress}
      image="tile/brain-health"
      disabled={disabled}
      tileWidth="full"
    />
  );
};
