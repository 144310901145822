import { FormikErrors, FormikTouched, isString, useFormikContext } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { InputLabel, Spacer } from '../../../../../../../uiComponents';
import {
  DocumentInput,
  UpdateProtocolConfigFormValue,
  UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue,
} from '../../../ManageProtocol.interface';
import { ReturnProtocolStageBaseConfig } from './ReturnProtocolStageBaseConfig';
import { DocumentSelector } from '../../../DocumentSelector';

export interface ReturnProtocolStageDocumentSubmissionConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue>;
  touch?: FormikTouched<UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue>;
  value: UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const ReturnProtocolStageDocumentSubmissionConfig: React.FC<ReturnProtocolStageDocumentSubmissionConfigProps> =
  ({ index, onRemove, move, itemKey, error, touch, value, canMoveUp, canMoveDown }) => {
    const { translate } = useTranslations();
    const { setFieldValue } = useFormikContext<UpdateProtocolConfigFormValue>();

    const handleTemplateDocumentChange = (templateDocument: DocumentInput) => {
      setFieldValue(`${itemKey}.templateDocument`, templateDocument);
    };

    const documentError = error?.templateDocument && isString(error.templateDocument) ? error.templateDocument : null;

    return (
      <ReturnProtocolStageBaseConfig
        index={index}
        itemKey={itemKey}
        move={move}
        onRemove={onRemove}
        value={value}
        canMoveDown={canMoveDown}
        canMoveUp={canMoveUp}
        error={error}
        touch={touch}
        typeSpecificItems={{
          document: (
            <Spacer
              items={{
                label: <InputLabel label={translate.admin.manageProtocol.templateDocument} />,
                document: (
                  <DocumentSelector
                    document={value.templateDocument}
                    onChange={handleTemplateDocumentChange}
                    error={documentError}
                  />
                ),
              }}
            />
          ),
        }}
      />
    );
  };
