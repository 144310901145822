import React from 'react';
import { Linking } from 'react-native';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseItemMedicalAssessmentReviewRejected } from '../../../../../fragments/__generated__/CaseItemMedicalAssessmentReviewRejected';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useColors } from '../../../../../shared/Ui/hooks';
import { AppText, AppButton } from '../../../../../uiComponents';

export interface CaseItemMedicalAssessmentReviewRejectedProps {
  item: CaseItemMedicalAssessmentReviewRejected;
}

export const CaseItemMedicalAssessmentReviewRejectedView: React.FC<CaseItemMedicalAssessmentReviewRejectedProps> = ({
  item,
}) => {
  const colors = useColors();
  const { translate, translateWithVars } = useTranslations();

  const handleDocumentPress = () => {
    if (item.document.downloadUri) {
      Linking.openURL(item.document.downloadUri);
    }
  };

  const text = translateWithVars(translate.caseLog.caseItemMedicalAssessmentReviewRejected.message, {
    actor: `${item.actor.firstName} ${item.actor.lastName}`,
    rejectionReason: item.rejectionReason,
  });
  const action = (
    <AppButton icon="file" onPress={handleDocumentPress} type="default">
      {translate.recovery.stageDocumentReview.document}
    </AppButton>
  );

  return (
    <BaseCaseLogItemView item={item} action={action}>
      <AppText>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
