import React, { useState, useEffect } from 'react';
import { Image } from '../../../../fragments/__generated__/Image';
import { useTranslations } from '../../../../i18n/useTranslations';
import { SpacerItems } from '../../../../uiComponents';
import { Formik, FormikErrors } from 'formik';
import { InviteGuardianFormData } from './InvitationModal/InvitationModal';
import { isEmpty, isEqual, set } from 'lodash/fp';
import { GroupListItem } from '../ManageGroups/GroupsList';
import { UserGroup } from 'apps/frontend/screens/shared/UserList/UserList.interface';
import { EditUserModalContent } from './EditUserModalContent';
import { validateEmail, validateName } from './HelperFunctions';
import { Role } from '../../../../types/globalTypes';

export interface EditUserModalUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string | null;
  userImage?: Image | null;
  subtitle?: React.ReactNode;
  actions?: SpacerItems;
  memberships?: Array<Membership> | null;
  birthday?: Date | null;
  groups?: UserGroup[] | null;
}

export interface Membership {
  role: string;
  status: string;
}

export interface Group {
  name: string;
  id: string;
}

export interface EditUserModalProps {
  title: string;
  visible: boolean;
  onDismiss: () => void;
  onEdit: (user: any) => void;
  submitting?: boolean;
  user?: EditUserModalUser;
  organizationId: string;
  userRoles: string[];
  allGroups: Group[];
}

export interface EditUserFormData {
  guardians: InviteGuardianFormData[];
  roles: string[];
  groupList: GroupListItem[];
  firstName: string;
  lastName: string;
  guardiansToRemove: string[];
  email: string;
}

export const EditUserModal: React.FC<EditUserModalProps> = ({
  title,
  visible,
  onDismiss,
  onEdit,
  user,
  submitting,
  organizationId,
  userRoles,
  allGroups,
}) => {
  const { translate } = useTranslations();
  const [userGroups, setUserGroups] = useState<Group[]>([]);
  const [userGroupsLoaded, setUserGroupsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setUserGroups(user?.groups ? createUserGroups(user.groups) : []);
  }, [user]);

  const createUserGroups = (userGroups) => {
    const dedupedUserGroups = userGroups.filter(
      (value, index, self) =>
        index === self.findIndex((item) => item.groupId === value.groupId && item.__typename === value.__typename)
    );
    const userGroupData: Group[] = [];
    for (const userGroup of dedupedUserGroups) {
      const foundGroup = allGroups.find((group) => group.id === userGroup.groupId);
      const userGroupDataItem = { id: userGroup.groupId, name: foundGroup ? foundGroup.name : '' };
      userGroupData.push(userGroupDataItem);
    }
    setUserGroupsLoaded(true);
    return userGroupData;
  };

  const initialValues: EditUserFormData = {
    guardians: [],
    roles: userRoles,
    groupList: userGroups,
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    guardiansToRemove: [],
    email: user && user.email ? user.email : '',
  };

  const handleEdit = (values: EditUserFormData) => {
    if (onEdit && user) {
      onEdit({
        organizationId: organizationId,
        firstName: values.firstName,
        lastName: values.lastName,
        userId: user.id,
        newGuardians: values.guardians,
        guardiansToRemove: values.guardiansToRemove,
        rolesToAdd: getRolesToAdd(values.roles),
        rolesToRemove: getRolesToRemove(values.roles),
        groupsToAdd: values.groupList.map((group) => group.id),
        groupsToRemove:
          values.roles.includes(Role.SUBJECT) || values.roles.includes(Role.SECONDARY_DESIGNATED_PERSON)
            ? getGroupsToRemove(values.groupList)
            : userGroups.map((item) => item.id),
        email: values.email !== initialValues.email ? values.email : null,
      });
    }
  };

  const validate = (values: EditUserFormData) => {
    let errors: FormikErrors<EditUserFormData> = {};
    const isSubject = isEqual(values.roles, [Role.SUBJECT]);

    validateName(values, errors, translate);

    validateEmail(
      values,
      errors,
      translate,
      !isSubject || (values.guardians.length > 0 && values.guardians.some((guardian) => !guardian.email)),
      isSubject
    );

    if (!values.roles.length) {
      errors.roles = translate.error.message.cantBeEmpty;
    }

    const guardiansErrors = values.guardians
      .map((guardian) => {
        const guardianErrors: FormikErrors<InviteGuardianFormData> = {};
        validateName(guardian, guardianErrors, translate);
        validateEmail(guardian, guardianErrors, translate, true);
        return guardianErrors;
      })
      .filter((val) => val && !isEmpty(val));

    if (guardiansErrors.length > 0) {
      errors = set('guardians', guardiansErrors, errors);
    }

    return errors;
  };

  const getRolesToAdd = (values: string[]) => {
    const rolesToAdd: string[] = [];
    for (const role of values) {
      if (!initialValues.roles.includes(role)) {
        rolesToAdd.push(role);
      }
    }
    return rolesToAdd;
  };

  const getRolesToRemove = (values: string[]) => {
    const rolesToRemove: string[] = [];
    for (const role of initialValues.roles) {
      if (!values.includes(role)) {
        rolesToRemove.push(role);
      }
    }
    return rolesToRemove;
  };

  const getGroupsToRemove = (values: GroupListItem[]) => {
    const groupsToRemove: string[] = [];
    const userGroupsIds = userGroups.map((item) => item.id);
    const selectedGroupsIds = values.map((item) => item.id);
    for (const group of userGroupsIds) {
      if (!selectedGroupsIds.includes(group)) {
        groupsToRemove.push(group);
      }
    }
    return groupsToRemove;
  };

  return userGroupsLoaded ? (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleEdit(values)}
      validate={validate}
      validateOnChange
    >
      <EditUserModalContent
        title={title}
        visible={visible}
        onDismiss={onDismiss}
        user={user}
        submitting={submitting}
        organizationId={organizationId}
        userRoles={userRoles}
        allGroups={allGroups}
      />
    </Formik>
  ) : (
    <></>
  );
};
