import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppModal, Checkbox, Input, AppText, Select, Icon } from '../../../../uiComponents';
import { Guardians } from './InvitationModal/Guardians/Guardians';
import { FieldArray, useFormikContext } from 'formik';
import { useGetSubjectGuardiansForOrganization } from './hooks/useGetSubjectGuardiansForOrganization';
import { GroupPills } from './InvitationModal/GroupPills';
import { GroupListItem } from '../ManageGroups/GroupsList';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { EditUserModalProps, EditUserFormData } from './EditUserModal';
import { isEmailValid } from '../../../../utils/isEmailValid';
import { useDateFormat } from '../../../../hooks/ui/useDateFormat';
import { Role } from '../../../../types/globalTypes';

type EditUserModalContentProps = Omit<EditUserModalProps, 'onEdit'>;

export const EditUserModalContent: React.FC<EditUserModalContentProps> = ({
  title,
  visible,
  onDismiss,
  user,
  submitting,
  organizationId,
  allGroups,
}) => {
  const {
    values,
    resetForm,
    isValid,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    initialValues,
  } = useFormikContext<EditUserFormData>();

  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const handleDismiss = () => {
    onDismiss();
  };

  const { formatDate } = useDateFormat();

  const { data } = useGetSubjectGuardiansForOrganization(organizationId, user ? user.id : '');

  const isSubject = user?.memberships?.map((membership) => membership.role).includes(Role.SUBJECT);

  const styles = StyleSheet.create({
    namesWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      margin: -8,
      flex: 1,
    },
    nameInput: {
      flexBasis: `50%`,
      flexGrow: 0,
      flexShrink: 0,
      padding: 8,
      flex: 1,
    },
    rolesCheckboxes: {
      marginBottom: 20,
    },
    noGuardiansMessage: {
      marginBottom: 30,
    },
    addNewGuardianHeader: {
      marginBottom: 20,
    },
    guardianHeader: {
      marginBottom: 20,
    },
    guardianRowContainer: {
      position: 'relative',
    },
    guardianRow: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 20,
      alignItems: 'center',
    },
    guardianName: {
      width: isMobile ? '45%' : '33%',
    },
    guardianEmail: {
      width: isMobile ? '45%' : '33%',
    },
    strikethrough: {
      borderBottomColor: 'black',
      borderBottomWidth: 1,
      width: isMobile ? '80%' : '60%',
      position: 'absolute',
      top: '30%',
    },
    groupsHeader: {
      marginTop: 20,
    },
    groupSelection: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    pillContainer: {
      marginTop: 0,
    },
  });

  const isArraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  };

  const handleAddGroup = (selectedGroupId: string, values: EditUserFormData, setFieldValue) => {
    const foundGroup = allGroups.find((item) => item.id === selectedGroupId);
    if (foundGroup) {
      setFieldValue('groupList', values.groupList.concat({ id: foundGroup.id, name: foundGroup.name }));
    }
  };

  const getUnselectedGroups = (values: EditUserFormData) => {
    return allGroups
      ? allGroups
          .map((group: GroupListItem, idx: number) => ({
            key: idx.toString(),
            value: group.id,
            label: group.name,
          }))
          .filter((item) => !values.groupList.map((chosenGroup) => chosenGroup.id).includes(item.value))
      : [];
  };

  const isUserDetailsChanged = (user) => {
    return (
      values.firstName !== user.firstName ||
      values.lastName !== user.lastName ||
      values.email !== user.email ||
      values.guardians.length > 0 ||
      values.guardiansToRemove.length > 0 ||
      !isArraysEqual(values.roles, initialValues.roles) ||
      !isArraysEqual(
        values.groupList.map((item) => item.id),
        initialValues.groupList.map((item) => item.id)
      )
    );
  };

  const validEmailForSubject = !!(initialValues.email || !values.email || (values.email && isEmailValid(values.email)));

  return (
    <AppModal
      mode="full-screen"
      visible={visible}
      title={title}
      onDismiss={handleDismiss}
      buttons={{
        cancel: (
          <AppButton
            type="outline"
            onPress={() => {
              handleDismiss();
              resetForm();
            }}
          >
            {translate.label.cancel}
          </AppButton>
        ),
        submit: user ? (
          <AppButton
            disabled={!isValid || submitting || !isUserDetailsChanged(user) || !validEmailForSubject}
            loading={submitting}
            onPress={() => {
              handleSubmit();
              setTimeout(() => {
                resetForm();
              }, 1);
            }}
          >
            {translate.label.saveChanges}
          </AppButton>
        ) : null,
      }}
    >
      <View style={styles.namesWrapper}>
        <View style={styles.nameInput}>
          <Input
            label={translate.placeholder.firstName}
            value={values.firstName}
            error={errors?.firstName}
            touched={touched?.firstName}
            onChangeText={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
          />
        </View>
        <View style={styles.nameInput}>
          <Input
            label={translate.placeholder.lastName}
            value={values.lastName}
            error={errors?.lastName}
            touched={touched?.lastName}
            onChangeText={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
          />
        </View>
      </View>
      {user ? (
        <View style={styles.namesWrapper}>
          <View style={[styles.nameInput]}>
            <Input
              label={translate.placeholder.email}
              value={values.email}
              onChangeText={handleChange('email')}
              error={errors?.email}
              touched={touched?.email}
              onBlur={handleBlur('email')}
              disabled={!!initialValues.email || !isSubject}
            />
          </View>
          <View style={styles.nameInput}>
            <Input
              label={translate.placeholder.dateOfBirth}
              value={
                user && user.birthday ? formatDate(new Date(parseInt(user.birthday.toString())), 'MMMM dd yyyy') : ''
              }
              error={''}
              touched={false}
              onBlur={() => null}
              disabled={true}
            />
          </View>
        </View>
      ) : null}
      <View style={styles.rolesCheckboxes}>
        <Checkbox
          value={values.roles}
          touched={touched?.roles}
          error={errors?.roles}
          options={[
            {
              label: translate.users.roles.admin,
              value: Role.ADMIN,
            },
            {
              label: translate.users.roles.primaryDesignatedPerson,
              value: Role.PRIMARY_DESIGNATED_PERSON,
            },
            {
              label: translate.users.roles.secondaryDesignatedPerson,
              value: Role.SECONDARY_DESIGNATED_PERSON,
            },
            {
              label: translate.users.roles.subject,
              value: Role.SUBJECT,
            },
            {
              label: translate.users.roles.guardian,
              value: Role.GUARDIAN,
            },
          ]}
          onSelect={(roles: string[]) => setFieldValue('roles', roles)}
        />
      </View>
      {isSubject ? (
        <AppText font="header3" style={styles.guardianHeader}>
          {translate.users.sectionHeaders.guardians}
        </AppText>
      ) : null}
      {data &&
        isSubject &&
        (data.subjectGuardiansForOrganization.guardians.length === 0 ? (
          <View style={styles.noGuardiansMessage}>
            <AppText>{translate.users.userDetails.userHasNoGuardians}</AppText>
          </View>
        ) : (
          data.subjectGuardiansForOrganization.guardians.map((guardian) => (
            <View key={guardian.userId} style={styles.guardianRowContainer}>
              <View style={styles.guardianRow}>
                <View style={styles.guardianName}>
                  <AppText font="labelDefault">{`${guardian.firstName} ${guardian.lastName}`}</AppText>
                </View>
                <View style={styles.guardianEmail}>
                  <AppText>{guardian.email}</AppText>
                </View>
                {values.guardiansToRemove.includes(guardian.userId) ? (
                  <TouchableOpacity
                    onPress={() =>
                      setFieldValue(
                        'guardiansToRemove',
                        values.guardiansToRemove.filter((id) => id !== guardian.userId)
                      )
                    }
                  >
                    <Icon size={20} name="rotate-ccw" />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => setFieldValue('guardiansToRemove', values.guardiansToRemove.concat(guardian.userId))}
                  >
                    <Icon size={20} name="trash" />
                  </TouchableOpacity>
                )}
              </View>
              {values.guardiansToRemove.includes(guardian.userId) ? <View style={styles.strikethrough} /> : null}
            </View>
          ))
        ))}
      {isSubject ? (
        <FieldArray
          name="guardians"
          children={(formikProps) => <Guardians guardians={values.guardians} {...formikProps} />}
        />
      ) : null}

      {values.roles.includes(Role.SUBJECT) || values.roles.includes(Role.SECONDARY_DESIGNATED_PERSON) ? (
        <View>
          <AppText font="header3" style={styles.groupsHeader}>
            {translate.users.sectionHeaders.addToGroups}
          </AppText>
          {getUnselectedGroups(values).length > 0 ? (
            <Select<string>
              style={styles.groupSelection}
              title={translate.users.selectGroups.selectGroups}
              onChange={(e) => {
                handleAddGroup(e, values, setFieldValue);
              }}
              items={[{ key: '-1', value: '0', label: translate.users.selectGroups.selectAGroup }].concat(
                getUnselectedGroups(values)
              )}
              value={''}
              error={''}
            />
          ) : null}

          <View style={styles.pillContainer}>
            <GroupPills
              groups={values.groupList}
              onRemoveRole={(groupId) =>
                setFieldValue(
                  'groupList',
                  values.groupList.filter((groupListItem) => groupListItem.id !== groupId)
                )
              }
            />
          </View>
        </View>
      ) : null}
    </AppModal>
  );
};
