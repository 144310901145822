import { gql } from '@apollo/client';
import { UserFragment } from '../../../fragments/UserFragment';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useQuery, UseQueryOptions } from '../../../hooks/useQuery';
import {
  GetInvitationByIdentifier,
  GetInvitationByIdentifierVariables,
} from './__generated__/GetInvitationByIdentifier';

export const GET_INVITATION_BY_IDENTIFIER_QUERY = gql`
  query GetInvitationByIdentifier($input: InvitationInput!) {
    invitation(input: $input) {
      ... on ApiError {
        ...ApiError
      }
      ... on FoundInvitation {
        ...FoundInvitation
      }
    }
  }
  fragment FoundInvitation on FoundInvitation {
    id
    foundInvitation {
      ... on UserRoleInvitation {
        ...UserRoleInvitation
    }
    }
  }
  fragment UserRoleInvitation on UserRoleInvitation {
    id
    user {
      ...User
    }
    action
    organizationName
    roles
    termsAndConditionsAccepted
    educationItems {
      ... on BaseEducationItem {
        id
        instructionMarkdown
      }
    }
  }
  ${UserFragment}
  ${ApiErrorFragment}
`;

export const useInvitationQuery = (
  options?: UseQueryOptions<GetInvitationByIdentifier, GetInvitationByIdentifierVariables>
) =>
  useQuery(GET_INVITATION_BY_IDENTIFIER_QUERY, {
    ...options,
    fetchPolicy: 'no-cache', // TODO: important, do not change this from no-cache.
    hideAlerts: true,
    // There seems to be a bug where this query triggers and infinite loop when the cache is used during the invitation flow
    //TODO: fix 'cache-and-network' fetchPolicy causes an inifite render of this page
    // when performed as part of the invitation flow
  });
