import { gql } from '@apollo/client';
import { useQuery } from './useQuery';

export const GET_ALL_USER_GROUPS = gql`
  query allUserGroups($participantId: String) {
    allUserGroups(participantId: $participantId) {
      organizationId
      role
      group {
        id
        name
        organizationId
      }
    }
  }
`;

export const useGetAllUserGroups = (options?) => useQuery(GET_ALL_USER_GROUPS, options);
