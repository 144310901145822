import { useQuery } from './useQuery';
import { gql } from '@apollo/client';
import { isAppUpdateRequired } from './__generated__/isAppUpdateRequired';

export const IS_APP_UDPATE_REQUIRED = gql`
  query isAppUpdateRequired($versionNumber: String!) {
    isAppUpdateRequired(versionNumber: $versionNumber)
  }
`;

export const useIsAppUpdateRequired = (options?) => useQuery<isAppUpdateRequired>(IS_APP_UDPATE_REQUIRED, options);
