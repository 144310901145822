import React from 'react';
import { useNavigation } from '@react-navigation/core';
import { gql } from '@apollo/client';
import { TileProps, Tiles as BaseTiles } from '../../uiComponents';
import { LoggedInNavType } from '../LoggedIn.interface';
import { ApiErrorFragment } from '../../fragments/ApiErrorFragment';
import { CurrentUserFragment } from '../../fragments/CurrentUserFragment';
import { useQuery } from '../../hooks/useQuery';
import { SelectMySubjects } from './__generated__/SelectMySubjects';
import { TileConcussion } from './Tiles/TileConcussion';
import { TileOnProtocol } from './Tiles/TileOnProtocol';
import { TileTeamRoster } from './Tiles/TileTeamRoster';
import { TileMyKids } from './Tiles/TileMyKids';
import { TileMyProfile } from './Tiles/TileMyProfile';
import { TileRecoveryPlan } from './Tiles/TileRecoveryPlan';
import { TileTraining } from './Tiles/TileTraining';
import { isCurrentUser } from '../../utils/isCurrentUser';
import { useActionItemsQuery } from '../ActionItems/hooks/useActionItems';
import { SelectMyProtocols } from './__generated__/SelectMyProtocols';
import { TileActionItems } from './Tiles/TileActionItems';
import { useIsDPCompletedEducationItems } from '../../hooks/useIsDPCompletedEducationItems';
import { Role } from '../../types/globalTypes';
import { useGetAllUserRoles } from '../../hooks/useGetAllUserRoles';
import { useGetUserProfile } from '../Profile/ProfileChrome/hooks/useGetUserProfile';

export interface TilesProps {}

export const MY_SUBJECTS_QUERY = gql`
  query SelectMySubjects {
    currentUser {
      ... on CurrentUser {
        ...CurrentUser
      }
      ... on ApiError {
        ...ApiError
      }
    }
    kids: currentUserSubjects(filters: { relations: [KID] }) {
      ...SubjectForTiles
    }
    subjectsOnProtocol: currentUserSubjects(filters: { statuses: [ON_PROTOCOL, REMOVED] }) {
      ...SubjectForTiles
    }
    currentUserSubjects {
      ...SubjectForTiles
    }
  }
  fragment SubjectForTiles on Subject {
    id
    subjectMemberships {
      id
      status
      organizations {
        id
        status
      }
    }
  }
  ${ApiErrorFragment}
  ${CurrentUserFragment}
`;

export const Tiles: React.FC<TilesProps> = (props) => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Home'>>();

  const { data } = useQuery<SelectMySubjects>(MY_SUBJECTS_QUERY);
  const { data: userProfile } = useGetUserProfile();

  const { data: actionItemsData } = useActionItemsQuery();
  const actionItems = actionItemsData?.actionItems || [];

  const { data: protocolData } = useQuery<SelectMyProtocols>(gql`
    query SelectMyProtocols {
      currentProtocolsByUser {
        ... on Protocol {
          id
          organizationId
          approved
        }
      }
    }
  `);
  const currentUser = data?.currentUser;
  const subjects = data?.currentUserSubjects;
  const { data: rolesData } = useGetAllUserRoles();

  const organizationIds: string[] =
    rolesData && rolesData.allUserRoles
      ? rolesData.allUserRoles
          .filter(
            (role) => role.role === Role.PRIMARY_DESIGNATED_PERSON || role.role === Role.SECONDARY_DESIGNATED_PERSON
          )
          .map((role) => role.organizationId)
      : [];

  const { data: isDPCompletedEducationItems } = useIsDPCompletedEducationItems(organizationIds);

  const hideNavItems = isDPCompletedEducationItems && isDPCompletedEducationItems.isDPCompletedEducationItems === false;

  if (!isCurrentUser(currentUser)) {
    return null;
  }

  const hasRecoveryPlan = userProfile?.userProfile?.id;
  const hasSubjects =
    subjects &&
    subjects.length > 0 &&
    currentUser.roles.filter(
      (role) => role.role === Role.PRIMARY_DESIGNATED_PERSON || role.role === Role.SECONDARY_DESIGNATED_PERSON
    ).length > 0;
  const hasActionItems = actionItems.length > 0;
  const hasKids = data?.kids && data?.kids.length > 0;
  const hasSubjectsOnProtocol = data?.subjectsOnProtocol && data?.subjectsOnProtocol.length > 0;
  const numSubjectsOnProtocol = data?.subjectsOnProtocol.length;
  const activeProtocols = protocolData?.currentProtocolsByUser || [];
  const hasApprovedProtocol = activeProtocols.some((protocol) => protocol.approved);
  const userRoles: string[] = currentUser.roles.map((el) => el.role);

  const handleNavigateToTraining = () => {
    if (userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON)) {
      navigation.navigate('LoggedIn_Training', {
        screen: 'LoggedIn_Training_Index',
      });
    } else {
      navigation.navigate('LoggedIn_Training', {
        screen: 'LoggedIn_Training_Resources',
        params: { isPrimaryDesignatedPerson: false },
      });
    }
  };

  let tiles: TileProps[] = [];

  if (hasSubjects) {
    tiles = [
      ...tiles,
      {
        key: 'assess',
        width: 'full',
        tile: (
          <TileConcussion
            onPress={() => navigation.navigate('LoggedIn_Assess', { screen: 'LoggedIn_Assess_Index' })}
            disabled={!hasApprovedProtocol}
          />
        ),
      },
    ];
  }

  if (hasActionItems) {
    tiles = [
      {
        key: 'action-items',
        width: 'full',
        tileContainerHeight: 'short',
        tile: <TileActionItems onPress={() => navigation.navigate('LoggedIn_ActionItems')} warning={true} />,
      },
      ...tiles,
    ];
  }

  if (hasSubjects) {
    tiles = [
      ...tiles,
      {
        key: 'team-roster',
        width: 'half',
        tile: <TileTeamRoster onPress={() => navigation.navigate('LoggedIn_TeamRoster')} />,
      },
    ];
  }

  if (hasKids) {
    tiles = [
      ...tiles,
      {
        key: 'my-kids',
        width: 'half',
        tile: <TileMyKids onPress={() => navigation.navigate('LoggedIn_MyKids')} />,
      },
    ];
  }

  if (hasSubjectsOnProtocol) {
    tiles = [
      ...tiles,
      {
        key: 'on-protocol',
        width: 'half',
        tile: (
          <TileOnProtocol
            key="on-protocol"
            onPress={() => navigation.navigate('LoggedIn_OnProtocol')}
            badge={numSubjectsOnProtocol}
          />
        ),
      },
    ];
  }

  if (userRoles.includes(Role.SUBJECT)) {
    tiles = [
      ...tiles,
      {
        key: 'my-profile',
        width: 'half',
        tile: (
          <TileMyProfile
            key="my-profile"
            onPress={() =>
              navigation.navigate('LoggedIn_Profile', {
                screen: 'LoggedIn_Profile_Overview',
              })
            }
          />
        ),
      },
    ];
  }

  tiles = [
    ...tiles,
    {
      key: 'training',
      width: 'half',
      tile: <TileTraining key="training" onPress={handleNavigateToTraining} />,
    },
  ];

  if (hasRecoveryPlan) {
    tiles = [
      ...tiles,
      {
        key: 'recovery-plan',
        width: 'half',
        tile: (
          <TileRecoveryPlan
            key="recovery-plan"
            onPress={() =>
              navigation.navigate('LoggedIn_Profile', {
                screen: 'LoggedIn_Profile_RecoveryPlan',
                params: { screen: 'LoggedIn_Profile_RecoveryPlan_Index' },
              })
            }
          />
        ),
      },
    ];
  }

  if (hideNavItems)
    tiles = tiles.filter((tile) => tile.key !== 'on-protocol' && tile.key !== 'team-roster' && tile.key !== 'assess');

  return <BaseTiles tiles={tiles} />;
};
