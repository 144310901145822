/* Native modal
=============================== */

import { isNull, isString } from 'lodash';
import React, { useRef } from 'react';
import { SafeAreaView, StyleSheet, TouchableOpacity, View, Modal } from 'react-native';
import { Icon } from '../Icon/Icon';
import { NotchView } from '../NotchView/NotchView';
import { AppScrollView } from '../AppScrollView/AppScrollView';
import { Spacer } from '../Spacer/Spacer';
import { AppText } from '../AppText/AppText';
import { useColors } from '../../shared/Ui';
import { useAppUiConfigContext } from '../../shared/Ui/AppUiConfigContext';
import { AppModalProps } from './AppModal.interface';
import { useIsMobile } from '../../hooks/ui/useResponsive';

export const AppModal: React.FC<AppModalProps> = ({
  title,
  mode,
  style,
  buttons,
  buttonOrientation,
  children,
  ...props
}) => {
  const colors = useColors();
  const isMobile = useIsMobile();

  const scrollViewRef = useRef(null);
  const maxWidth = useAppUiConfigContext().styleConfig.maxContentWidth;

  const getModeStyle = () => {
    switch (mode) {
      case 'dialog':
        return StyleSheet.create({
          root: {},
          container: {
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto',
            minHeight: 100,
            minWidth: 400,
            backgroundColor: colors.white,
            width: isMobile ? '95%' : 700,
            maxWidth,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          scrollView: {
            maxHeight: 400,
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: 'auto',
          },
        });
      case 'full-screen':
        return StyleSheet.create({
          root: {},
          container: {
            flex: 1,
            width: '100%',
            backgroundColor: colors.white,
          },
          overlay: {},
          scrollView: {},
        });
      case 'notch':
        return StyleSheet.create({
          root: {},
          container: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            height: 'auto',
            maxHeight: 400,
            width: '100%',
          },
          overlay: { justifyContent: 'flex-end' },
          scrollView: {},
        });
    }
  };

  const modeStyle = getModeStyle();

  const getAnimationType = () => {
    switch (mode) {
      case 'dialog':
        return 'fade' as const;
      case 'full-screen':
      case 'notch':
        return 'slide' as const;
      default:
        return 'none' as const;
    }
  };
  const animationType = getAnimationType();

  const styles = StyleSheet.create({
    root: {
      margin: 0,
      padding: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
    },
    overlay: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    container: {
      padding: 20,
      borderRadius: 8,
    },
    nav: {
      position: 'absolute',
      top: 20,
      right: 20,
      zIndex: 99,
    },
    modalHeader: {
      marginTop: 40,
      marginBottom: 20,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    scrollView: {},
    title: {
      textAlign: 'center',
    },
    buttons: {
      marginTop: 20,
      flexGrow: 0,
      marginLeft: 'auto',
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
    },
    notchChildrenContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonDisplay: {
      flex: 0,
    },
  });

  const buttonDisplay = buttons ? <Spacer style={styles.buttons} orientation="horizontal" items={buttons} /> : null;

  const contentWrapper = (content: React.ReactNode) => {
    let titleArea: React.ReactNode = null;

    if (isString(title)) {
      titleArea = (
        <AppText style={styles.title} font="header1" color="navyDark">
          {title}
        </AppText>
      );
    } else if (!isNull(title)) {
      titleArea = title;
    }

    switch (mode) {
      case 'dialog':
      case 'full-screen':
        return (
          <View style={StyleSheet.flatten([modeStyle.container, styles.container, style])}>
            <View style={styles.nav}>
              {props.onDismiss ? (
                <TouchableOpacity onPress={props.onDismiss}>
                  <Icon name="x" size={28} />
                </TouchableOpacity>
              ) : null}
            </View>
            {titleArea ? <View style={styles.modalHeader}>{titleArea}</View> : null}
            <AppScrollView
              ref={scrollViewRef}
              start={false}
              end={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.flatten([styles.scrollView, modeStyle.scrollView])}
            >
              {content}
            </AppScrollView>
            {buttonDisplay}
          </View>
        );
      case 'notch':
        return (
          <NotchView style={StyleSheet.flatten([styles.container, modeStyle.container, style])}>
            <View style={styles.notchChildrenContainer}>{children}</View>
            <View style={styles.buttonDisplay}>{buttonDisplay}</View>
          </NotchView>
        );
    }
  };

  return (
    <Modal
      {...props}
      animationType={animationType}
      transparent={true}
      presentationStyle="overFullScreen"
      style={StyleSheet.flatten([styles.root, modeStyle.root])}
    >
      <View style={StyleSheet.flatten([styles.overlay, modeStyle.overlay])}>
        <SafeAreaView />
        {contentWrapper(children)}
      </View>
    </Modal>
  );
};
