import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Icon } from '../../../uiComponents';
import { useColors } from '../../Ui';
import { useRoute } from '@react-navigation/native';
import { useNavbarNavigation } from './useNavbarNavigation';

interface BottomTabsProps {
  hideNavItems: boolean | undefined;
}

export const BottomTabs: React.FC<BottomTabsProps> = ({ hideNavItems }) => {
  const colors = useColors();
  const route = useRoute();

  const { navigateToLink, navItems, showItem } = useNavbarNavigation(hideNavItems);

  const styles = StyleSheet.create({
    bottomTabs: {
      height: 65,
      backgroundColor: colors.navyDark,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    navItem: {
      width: `${((1 / navItems.length) * 100)}%`,
    },
    selectedItem: {
      backgroundColor: colors.navyDarker,
      paddingVertical: 19,
    },
    icon: {
      alignItems: 'center',
    },
  });

  return (
    <View style={styles.bottomTabs}>
      {navItems.map((item) => {
        return showItem(item.name) ? (
          <TouchableOpacity
            key={item.name}
            onPress={() => navigateToLink(item.name)}
            style={[
              styles.navItem,
              item.routes.includes(route.name) && !(route.name === 'LoggedIn_Profile_Overview' && route.params)
                ? styles.selectedItem
                : null,
            ]}
          >
            <View style={styles.icon}>
              <Icon name={item.icon} size={24} color={'white'} />
            </View>
          </TouchableOpacity>
        ) : null;
      })}
    </View>
  );
};
