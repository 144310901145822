import * as React from 'react';
import { StyleSheet, FlatList, ListRenderItem, View } from 'react-native';
import { useColors } from '../../shared/Ui/hooks/useColors';
import { AutocompleteListItem, AutocompleteListItemType } from './AutocompleteListItem';

interface AutocompleteListProps<T> {
  items: AutocompleteListItemType<T>[];
  onSelect?: (result: T) => void;
}

export const AutocompleteList = <T,>({ items, onSelect }: AutocompleteListProps<T>) => {
  const colors = useColors();
  const styles = StyleSheet.create({
    separator: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: colors.navyDark,
    },
  });

  const renderItem: ListRenderItem<AutocompleteListItemType<T>> = ({ item }) => (
    <AutocompleteListItem<T> item={item} onPress={onSelect} />
  );

  const FlatListItemSeparator = () => {
    return <View style={styles.separator} />;
  };

  return (
    <FlatList<AutocompleteListItemType<T>>
      ItemSeparatorComponent={FlatListItemSeparator}
      data={items}
      renderItem={renderItem}
      keyExtractor={(item) => item.key}
    />
  );
};
