import React from 'react';
import { useNavigation } from '@react-navigation/core';
import { Chrome } from '../shared/Chrome/Chrome';
import { Content } from './Content';
import { LoggedInNavType } from '../LoggedIn.interface';
import { useTranslations } from '../../i18n/useTranslations';

export const ActionItems: React.FC = () => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ActionItems'>>();
  const { translate } = useTranslations();

  return (
    <Chrome
      mode="title-card"
      titleText={translate.actionItems.title}
      content={<Content />}
      navBarProps={{ onBack: () => navigation.navigate('LoggedIn_Home') }}
    />
  );
};
