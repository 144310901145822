/* Scroll view with gradient edges
=============================== */

import React, { forwardRef, PropsWithChildren } from 'react';
import { Animated, ViewStyle, ScrollViewProps, ScrollView, StyleSheet } from 'react-native';

export interface AppScrollViewProps extends ScrollViewProps {
  vertical?: boolean;
  horizontal?: boolean;
  start?: boolean;
  end?: boolean;
  size?: number;
  scrollVar?: Animated.Value;
  style?: ViewStyle;
}

export const AppScrollView = forwardRef<ScrollView, PropsWithChildren<AppScrollViewProps>>((props, scrollViewRef) => {
  const {
    children,
    vertical = true,
    horizontal = false,
    start = true,
    end = true,
    size = 15,
    scrollVar = undefined,
    style = {},
    ...scrollViewProps
  } = props;

  const scrollHandler = scrollVar
    ? Animated.event([{ nativeEvent: { contentOffset: { y: scrollVar } } }], {
        useNativeDriver: true,
      })
    : () => null;

  const styles = StyleSheet.create({
    container: { paddingBottom: 16 },
  });

  return (
    <ScrollView
      {...scrollViewProps}
      ref={scrollViewRef || undefined}
      horizontal={horizontal}
      style={[styles.container, style]}
      scrollEventThrottle={1}
      onScroll={scrollHandler}
      nestedScrollEnabled={true}
    >
      {children}
    </ScrollView>
  );
});

// TODO: Remove unused comments

// styles

// const styles = StyleSheet.create({
//   // container: {
//   //   flex: 1,
//   // },
//   // scrollView: {},
//   // topGradient: {
//   //   height: size,
//   //   position: 'absolute',
//   //   top: 0,
//   //   left: 0,
//   //   right: 0,
//   // },
//   // bottomGradient: {
//   //   height: size,
//   //   position: 'absolute',
//   //   bottom: 0,
//   //   left: 0,
//   //   right: 0,
//   // },
//   // leftGradient: {
//   //   width: size,
//   //   position: 'absolute',
//   //   top: 0,
//   //   left: 0,
//   //   bottom: 0,
//   // },
//   // rightGradient: {
//   //   width: size,
//   //   position: 'absolute',
//   //   top: 0,
//   //   right: 0,
//   //   bottom: 0,
//   // },
// {
//   /* {vertical && start && (
//         <LinearGradient
//           colors={[hexToRgba(colors[color], 1), hexToRgba(colors[color], 0)]}
//           style={styles.topGradient}
//         />
//       )}
//       {vertical && end && (
//         <LinearGradient
//           colors={[hexToRgba(colors[color], 0), hexToRgba(colors[color], 1)]}
//           style={styles.bottomGradient}
//         />
//       )}
//       {horizontal && start && (
//         <LinearGradient
//           colors={[hexToRgba(colors[color], 1), hexToRgba(colors[color], 0)]}
//           start={{ x: 0, y: 0 }}
//           end={{ x: 1, y: 0 }}
//           style={styles.leftGradient}
//         />
//       )}
//       {horizontal && end && (
//         <LinearGradient
//           colors={[hexToRgba(colors[color], 0), hexToRgba(colors[color], 1)]}
//           start={{ x: 0, y: 0 }}
//           end={{ x: 1, y: 0 }}
//           style={styles.rightGradient}
//         />
//       )} */
// }
