import { FormikErrors, FormikTouched, getIn, isString, useFormikContext } from 'formik';
import { isUndefined } from 'lodash/fp';
import * as React from 'react';
import {
  UpdateProtocolConfigFormValue,
  UpdateReturnProtocolChildProtocolStageConfigFormValue,
} from '../../../ManageProtocol.interface';
import { ReturnProtocolStageDocumentSubmissionConfig } from './ReturnProtocolStageDocumentSubmissionConfig';
import { ReturnProtocolStageSignoffConfig } from './ReturnProtocolStageSignoffConfig';

export interface ReturnProtocolStageConfigProps {
  index: number;
  totalItems: number;
  name: string;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
}

export const ReturnProtocolStageConfig: React.FC<ReturnProtocolStageConfigProps> = ({
  index,
  totalItems,
  onRemove,
  move,
  name,
}) => {
  const { values: _values, errors: _errors, touched: _touched } = useFormikContext<UpdateProtocolConfigFormValue>();

  const itemKey = `${name}[${index}]`;
  const itemError = getIn(_errors, itemKey) as
    | FormikErrors<UpdateReturnProtocolChildProtocolStageConfigFormValue>
    | string
    | undefined;
  const itemTouched = getIn(_touched, itemKey) as
    | FormikTouched<UpdateReturnProtocolChildProtocolStageConfigFormValue>
    | undefined;
  const value = getIn(_values, itemKey) as UpdateReturnProtocolChildProtocolStageConfigFormValue;
  const error = !isString(itemError) && !isUndefined(itemError) ? itemError : undefined;
  const touch = !isString(itemTouched) && !isUndefined(itemTouched) ? itemTouched : undefined;
  const isFirst = index === 0;
  const isLast = index >= totalItems - 1;
  const moreThanOneItem = totalItems > 1;
  const canMoveUp = !isFirst && moreThanOneItem;
  const canMoveDown = !isLast && moreThanOneItem;

  if (value?.type === 'document-submission') {
    return (
      <ReturnProtocolStageDocumentSubmissionConfig
        canMoveUp={canMoveUp}
        canMoveDown={canMoveDown}
        touch={touch}
        error={error}
        value={value}
        itemKey={itemKey}
        onRemove={onRemove}
        move={move}
        index={index}
      />
    );
  } else if (value?.type === 'signoff') {
    return (
      <ReturnProtocolStageSignoffConfig
        canMoveUp={canMoveUp}
        canMoveDown={canMoveDown}
        touch={touch}
        error={error}
        value={value}
        onRemove={onRemove}
        move={move}
        index={index}
        itemKey={itemKey}
      />
    );
  } else {
    return null;
  }
};
