import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  AppButton,
  Input,
  Select,
  AppText,
  ActivityIndicator,
  AppModal,
  PageNavButtons,
} from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { UserList } from '../../../../uiComponents';
import { UserListUser } from '../../../shared/UserList/UserList.interface';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useAdminContext } from '../../hooks/useAdminContext';
import { RolePills } from './RolePills';
import { InvitationModal } from './InvitationModal/InvitationModal';
import { EditUserModal } from './EditUserModal';
import { NudgeRoleConfirmDialog } from './NudgeRoleConfirmDialog';
import { RemoveRoleConfirmDialog } from './RemoveRoleConfirmDialog';
import { Role } from '../../../../types/globalTypes';
import { useInviteUserToOrganizationRole } from './hooks/useInviteUserToOrganizationRole';
import { useNudgePendingUserRoleInvitation } from './hooks/useNudgePendingUserRoleInvitation';
import { useUpdateUser } from './hooks/useUpdateUser';
import { useRemoveUserRole } from './hooks/useRemoveUserRole';
import { ManageUsersButton } from './ManageUsersButton';
import { AdminNavType } from '../../Admin.interface';
import { InviteUserFormData } from './InvitationModal/InvitationModal';
import { useGetGroupsByOrganizationId } from '../ManageGroups/hooks/useGetGroupsByOrganizationId';
import { RemoveButton } from './RemoveButton';
import { useGetOrganizationUsers } from '../../hooks/useGetOrganizationUsers';
import { useDeactivateUser } from '../../../shared/UserList/hooks/useDeactivateUser';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { useSearchAndSort } from '../ManageGroups/GroupDetails/hooks/useSearchAndSort';
import { useFindParticipationCount } from './hooks/useFindParticipationCount';
import { Name } from './Columns';
import { GetOrganizationUsers_organizationUsers } from '../../hooks/__generated__/GetOrganizationUsers';
import { EditUserFormData } from './EditUserModal';
import { useGetAllUserRoles } from '../../../../hooks/useGetAllUserRoles';

export interface ActiveAndTotalUsers {
  active: number | undefined;
  total: number | undefined;
}

type SortOrder = 'asc' | 'desc';
type SortBy = 'firstName' | 'lastName';
export type SortValue = `${SortBy}_${SortOrder}`;

interface EditUserFormDataWithUserId extends EditUserFormData {
  userId: string;
}

export const Index: React.FC = (props) => {
  const { organizationId } = useAdminContext();
  const inputRef = useRef<TextInput>(null);
  const {
    handleSortChange,
    handleSearchStr,
    selectedSortType,
    searchStr,
    sortCategories,
    setIsSearchInputFocused,
    roleFilter,
    roleFilterOptions,
    handleRoleFilterChange,
  } = useSearchAndSort(inputRef);

  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_ManageUsers'>>();
  const [selectedUser, setSelectedUser] = useState<UserListUser | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [activeAndTotalUsers, setActiveAndTotalUsers] = useState<ActiveAndTotalUsers>({
    active: undefined,
    total: undefined,
  });
  const [isUserInvitationModalVisible, setUserInvitationModalVisible] = useState<boolean>(false);
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const [showRemoveRoleConfirmDialog, setShowRemoveRoleConfirmDialog] = useState<boolean>(false);
  const [showNudgeRoleConfirmDialog, setShowNudgeRoleConfirmDialog] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState<{
    isVisible: boolean;
    userName: string;
    userId: string;
    roles: string[];
    isActive: boolean;
  }>({
    isVisible: false,
    userName: '',
    userId: '',
    roles: [],
    isActive: false,
  });

  const { data: participationCount, loading: participationCountLoading } = useFindParticipationCount({
    variables: { organizationId },
  });

  const { refetch: refetchAllUserRoles } = useGetAllUserRoles();

  const isMobile = useIsMobile();

  useEffect(() => {
    setActiveAndTotalUsers({
      active: participationCount ? participationCount.participationReportCount.active : 0,
      total: participationCount ? participationCount.participationReportCount.total : 0,
    });
  }, [participationCount]);

  const { currentUser } = useCurrentUser();

  const isUserAnAdmin =
    currentUser?.__typename === 'CurrentUser'
      ? currentUser.roles
          .filter((item) => item.role === Role.ADMIN)
          .filter((item) => item.organizationId === organizationId).length > 0
      : false;

  const { translate, translateWithVars } = useTranslations();

  const styles = StyleSheet.create({
    search: {
      flex: 3,
      marginRight: 20,
    },
    userList: {
      flex: 1,
    },
    inviteAndPageNavContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inviteUserButton: {
      marginBottom: 20,
    },
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
      marginBottom: 10,
    },
    sortContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
      width: isMobile ? '100%' : '60%',
    },
    sortSelect: {
      width: '50%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
    },
    searchContainer: {
      width: isMobile ? '100%' : '40%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const [deactivateUser] = useDeactivateUser(
    {},
    organizationId,
    {
      skip: currentPage,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
    roleFilter === 'All' ? null : roleFilter,
    searchStr ? searchStr : null
  );

  const handleDeactivateUser = async (userId: string, roles: string[], isActive: boolean) => {
    const res = await deactivateUser({
      variables: {
        userId,
        organizationId: organizationId ? organizationId : '',
      },
    });

    if (
      res.data?.removeAllUserRoles.__typename === 'DeactivateUserResult' &&
      res.data?.removeAllUserRoles.success &&
      participationCount
    ) {
      const newActiveAndTotalUsers =
        activeAndTotalUsers.active && activeAndTotalUsers.total
          ? {
              active: isActive ? activeAndTotalUsers.active - 1 : activeAndTotalUsers.active,
              total: activeAndTotalUsers.total - 1,
            }
          : {
              active: 0,
              total: 0,
            };

      setActiveAndTotalUsers(newActiveAndTotalUsers);
    }
    setShowDeactivateUserModal({ isVisible: false, userName: '', userId: '', roles: [], isActive: false });
  };

  const [inviteUserToOrganizationRole, { loading: inviteSubmitting }] = useInviteUserToOrganizationRole(
    {
      onCompleted: () => {
        setUserInvitationModalVisible(false);
      },
    },
    organizationId,
    {
      skip: currentPage,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
    searchStr,
    selectedRole
  );

  const [removeUserRole, { loading: removeRoleSubmitting }] = useRemoveUserRole({
    organizationId,
    skip: currentPage,
    orderBy: selectedSortType.split('_')[0],
    direction: selectedSortType.split('_')[1],
    role: roleFilter === 'All' ? null : roleFilter,
    searchStr: searchStr ? searchStr : null,
    selectedRole,
  });
  const [nudgeUserRoleInvitation, { loading: nudgeUserRoleSubmitting }] = useNudgePendingUserRoleInvitation();
  const [updateUser, { loading: updateUserSubmitting }] = useUpdateUser(
    {
      skip: currentPage,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
    searchStr,
    selectedRole
  );

  const { data: groupData, loading: groupDataLoading } = useGetGroupsByOrganizationId(organizationId);
  const allGroups = groupData?.groups ? groupData?.groups.map((item) => ({ id: item.id, name: item.name })) : [];

  const { data: userList } = useGetOrganizationUsers({
    hideLoading: true,
    variables: {
      organizationId,
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      role: roleFilter === 'All' ? null : roleFilter,
      searchStr: searchStr ? searchStr : null,
    },
  });

  const users = userList?.organizationUsers;

  const openAddUserModal = () => {
    setUserInvitationModalVisible(true);
  };

  const closeAddUserModal = () => {
    setUserInvitationModalVisible(false);
  };
  const closeEditUserModal = () => {
    setSelectedUser(undefined);
    setShowEditUserModal(false);
  };

  const handleUserPress = (user: UserListUser) => {
    setSelectedUser(user);
    setShowEditUserModal(true);
  };

  const handleUserAdd = (user: InviteUserFormData) => {
    inviteUserToOrganizationRole({
      variables: {
        input: {
          organizationId,
          firstName: user.firstName.trim(),
          lastName: user.lastName.trim(),
          email: user.email ? user.email.trim() : '',
          roles: (user.roles || []) as Role[],
          groups: user.groupList.map((group) => group.id),
          guardians: user.guardians.length > 0 ? user.guardians : null,
          birthday: user.birthday ? user.birthday : null,
        },
      },
    });

    const newActiveAndTotalUsers = {
      active: activeAndTotalUsers?.active ? activeAndTotalUsers.active : 0,
      total: activeAndTotalUsers?.total ? activeAndTotalUsers.total + 1 : 0,
    };
    setActiveAndTotalUsers(newActiveAndTotalUsers);
  };

  const handleUserEdit = (userFields: EditUserFormDataWithUserId) => {
    updateUser({
      variables: { ...userFields, organizationId },
    }).then(() => {
      if (currentUser?.id === userFields.userId) {
        refetchAllUserRoles();
      }
    });
    setSelectedUser(undefined);
    setShowEditUserModal(false);
  };

  const handleDismissNudgeRoleConfirmDialog = () => {
    setSelectedUser(undefined);
    setSelectedRole(undefined);
    setShowNudgeRoleConfirmDialog(false);
  };

  const handleDismissRemoveRoleConfirmDialog = () => {
    setSelectedUser(undefined);
    setSelectedRole(undefined);
    setShowRemoveRoleConfirmDialog(false);
  };

  const handleUserRemoveRole = () => {
    if (selectedUser && selectedRole) {
      removeUserRole({
        variables: { userId: selectedUser.id, organizationId, role: selectedRole as Role },
      });
      setSelectedUser(undefined);
      setSelectedRole(undefined);
      setShowRemoveRoleConfirmDialog(false);
    }
  };

  const handleNudgeUserRoleInvitation = () => {
    nudgeUserRoleInvitation({ variables: { input: { userId: selectedUser?.id || '', organizationId } } });
    setSelectedUser(undefined);
    setSelectedRole(undefined);
    setShowNudgeRoleConfirmDialog(false);
  };

  const handleConfirmUserNudgeRole = (user: UserListUser, role: string) => {
    setSelectedUser(user);
    setSelectedRole(role);
    setShowNudgeRoleConfirmDialog(true);
  };

  const handleConfirmUserRemoveRole = (user: UserListUser, role: string) => {
    setSelectedUser(user);
    setSelectedRole(role);
    setShowRemoveRoleConfirmDialog(true);
  };

  const bottomButton = organizationId ? <ManageUsersButton activeAndTotalUsers={activeAndTotalUsers} /> : undefined;

  const onBack = () => navigation.navigate('LoggedIn_Admin', { screen: 'LoggedIn_Admin_Index', organizationId });

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [roleFilter, selectedSortType, searchStr]);

  const loadingBaseData = participationCountLoading || groupDataLoading;

  const RolePillsColumn: React.FC<{ user: GetOrganizationUsers_organizationUsers }> = ({ user }) => {
    return (
      <View style={{ maxWidth: '100%', flexWrap: 'wrap' }}>
        <RolePills
          memberships={user.memberships}
          onNudgeRole={(role) => handleConfirmUserNudgeRole(user, role)}
          onRemoveRole={(role) => handleConfirmUserRemoveRole(user, role)}
        />
      </View>
    );
  };

  return isUserAnAdmin && !loadingBaseData ? (
    <>
      <InvitationModal
        title={translate.users.addUser.title}
        visible={isUserInvitationModalVisible}
        onDismiss={closeAddUserModal}
        onAdd={handleUserAdd}
        submitting={inviteSubmitting}
      />
      {selectedUser && selectedUser.memberships && allGroups ? (
        <EditUserModal
          title={translate.users.userDetails.title}
          visible={showEditUserModal}
          onDismiss={closeEditUserModal}
          onEdit={handleUserEdit}
          user={selectedUser}
          submitting={updateUserSubmitting}
          organizationId={organizationId}
          userRoles={selectedUser.memberships.map((membership) => membership.role)}
          allGroups={allGroups}
        />
      ) : null}
      <AppModal
        title={translate.users.userDetails.deleteUserConfirmationTitle}
        mode="dialog"
        visible={showDeactivateUserModal.isVisible}
        buttons={{
          confirm: (
            <AppButton
              type="danger"
              onPress={() =>
                handleDeactivateUser(
                  showDeactivateUserModal.userId,
                  showDeactivateUserModal.roles,
                  showDeactivateUserModal.isActive
                )
              }
            >
              {translate.label.delete}
            </AppButton>
          ),
        }}
        onDismiss={() =>
          setShowDeactivateUserModal({ isVisible: false, userName: '', userId: '', roles: [], isActive: false })
        }
      >
        <AppText style={{ textAlign: 'center' }}>
          {translateWithVars(translate.users.userDetails.deleteUserConfirmationContent, {
            userName: showDeactivateUserModal.userName,
          })}
        </AppText>
      </AppModal>
      <NudgeRoleConfirmDialog
        inviting={nudgeUserRoleSubmitting}
        user={selectedUser}
        visible={showNudgeRoleConfirmDialog}
        onDismiss={handleDismissNudgeRoleConfirmDialog}
        onInvite={handleNudgeUserRoleInvitation}
      />
      <RemoveRoleConfirmDialog
        removing={removeRoleSubmitting}
        user={selectedUser}
        role={selectedRole}
        visible={showRemoveRoleConfirmDialog}
        onDismiss={handleDismissRemoveRoleConfirmDialog}
        onRemove={handleUserRemoveRole}
      />
      <Chrome
        mode="title-card"
        titleText={translate.users.manageUsersTitle}
        navBarProps={{ onBack }}
        content={
          <View>
            <View style={styles.searchAndSortContainer}>
              <View style={styles.sortContainer}>
                <View style={[styles.sortSelect, { paddingRight: 15 }]}>
                  <AppText>{translate.users.userDetails.sort}</AppText>
                  <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
                </View>
                <View style={[styles.sortSelect, !isMobile ? { paddingRight: 15 } : null]}>
                  <AppText>{translate.users.userDetails.role}</AppText>
                  <Select<string> value={roleFilter} items={roleFilterOptions} onChange={handleRoleFilterChange} />
                </View>
              </View>
              <View style={styles.searchContainer}>
                <Input
                  icon="search"
                  ref={inputRef}
                  onFocus={() => setIsSearchInputFocused(true)}
                  onBlur={() => setIsSearchInputFocused(false)}
                  placeholder={translate.users.userDetails.searchByName}
                  type="name"
                  debounce={{
                    milliseconds: 1000,
                    callback: handleSearchStr,
                    textValue: searchStr,
                  }}
                />
              </View>
            </View>
            <View style={styles.inviteAndPageNavContainer}>
              <AppButton style={styles.inviteUserButton} onPress={openAddUserModal}>
                {translate.users.userDetails.invite}
              </AppButton>
              <PageNavButtons
                isPrevPageButtonVisible={currentPage !== 0}
                isNextPageButtonVisible={users && users.length === 25}
                prevPageAction={goToPreviousPage}
                nextPageAction={goToNextPage}
              />
            </View>
            {users ? (
              <UserList
                noUsersMessage={translate.users.userDetails.noUsers}
                rightAlignLastItem={true}
                mobileRearrangement={[0, 2, 1]}
                displayItems={users.map((user: GetOrganizationUsers_organizationUsers) => ({
                  key: user.id,
                  onSelect: () => handleUserPress(user),
                  components: [
                    <Name name={user.fullName} />,
                    <RolePillsColumn user={user} />,
                    <RemoveButton
                      onPress={() =>
                        setShowDeactivateUserModal({
                          isVisible: true,
                          userName: user.firstName,
                          userId: user.id,
                          roles: user.memberships ? user.memberships.map((role) => role.role) : [],
                          isActive: user.memberships
                            ? user.memberships.filter((role) => role.status === 'ACCEPTED').length > 0
                            : false,
                        })
                      }
                    />,
                  ],
                }))}
              />
            ) : (
              <ActivityIndicator style={styles.activityIndicator} />
            )}
            {bottomButton}
          </View>
        }
      />
    </>
  ) : (
    <></>
  );
};
