import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import {
  GetSubjectProfileOverview,
  GetSubjectProfileOverviewVariables,
} from './__generated__/GetSubjectProfileOverview';
import { OverviewSubjectMembershipsFragment } from '../../../fragments/OverviewSubjectMemberships';

export const GET_SUBJECT_PROFILE_OVERVIEW = gql`
  query GetSubjectProfileOverview($subjectId: ID!) {
    getSubject(input: { subjectId: $subjectId }) {
      ...SubjectProfileOverview
      ... on ApiError {
        ...ApiError
      }
    }
  }
  fragment SubjectProfileOverview on Subject {
    id
    subjectMemberships {
      ...OverviewSubjectMemberships
    }
  }
  ${OverviewSubjectMembershipsFragment}
  ${ApiErrorFragment}
`;

export const useGetSubjectProfileOverview = (
  options: UseQueryOptions<GetSubjectProfileOverview, GetSubjectProfileOverviewVariables>
) => useQuery<GetSubjectProfileOverview, GetSubjectProfileOverviewVariables>(GET_SUBJECT_PROFILE_OVERVIEW, options);
