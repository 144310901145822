import React, { useState, useRef } from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { Input, ActivityIndicator, PageNavButtons } from '../../../../../../uiComponents';
import { Role } from '../../../../../../types/globalTypes';
import { useGetSubjectsNotInGroup } from '../../hooks/useGetSubjectsNotInGroup';
import { useGetDesignatedPersonsNotInGroup } from '../../hooks/useGetDesignatedPersonsNotInGroup';
import { Direction } from '../../../../../../types/globalTypes';
import { AutocompleteList } from '../../../../../../uiComponents/Autocomplete/AutocompleteList';

interface ContentProps {
  role: Role;
  organizationId: string;
  excludeGroupId: string;
  onSelect: (id: string) => void;
}

export const Content: React.FC<ContentProps> = ({ role, organizationId, excludeGroupId, onSelect }) => {
  const inputRef = useRef<TextInput>(null);
  const { translate } = useTranslations();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchStr, setSearchStr] = useState<string>('');
  const [isSearchInputFocused, setIsSearchInputFocused] = useState<boolean>(false);

  const { data: subjectsNotInGroup } = useGetSubjectsNotInGroup({
    hideLoading: true,
    skip: role !== Role.SUBJECT,
    variables: {
      groupId: excludeGroupId,
      organizationId,
      paginationArgs: {
        skip: currentPage,
        orderBy: 'lastName',
        direction: Direction.asc,
      },
      searchStr,
    },
  });

  const { data: designatedPersonsNotInGroup } = useGetDesignatedPersonsNotInGroup({
    hideLoading: true,
    skip: role !== Role.SECONDARY_DESIGNATED_PERSON,
    variables: {
      groupId: excludeGroupId,
      organizationId,
      paginationArgs: {
        skip: currentPage,
        orderBy: 'lastName',
        direction: Direction.asc,
      },
      searchStr,
    },
  });

  const usersNotInGroup =
    role === Role.SUBJECT
      ? subjectsNotInGroup?.subjectsNotInGroup.map((user) => ({
          label: user.fullName,
          value: user.id,
          key: user.id,
        }))
      : designatedPersonsNotInGroup?.designatedPersonsNotInGroup.map((user) => ({
          label: user.fullName,
          value: user.id,
          key: user.id,
        }));

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleSearchStr = (name: string) => {
    setSearchStr(name);
    if (inputRef && inputRef.current && isSearchInputFocused) {
      inputRef.current.focus();
    }
  };

  const styles = StyleSheet.create({
    searchAndPaginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    input: {
      width: !(usersNotInGroup && usersNotInGroup.length === 25) && currentPage === 0 ? '100%' : '90%',
    },
    pageNavButtons: {
      width: '10%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  return (
    <View>
      <View style={styles.searchAndPaginationContainer}>
        <View style={styles.input}>
          <Input
            icon="search"
            ref={inputRef}
            onFocus={() => setIsSearchInputFocused(true)}
            onBlur={() => setIsSearchInputFocused(false)}
            placeholder={translate.users.userDetails.searchByName}
            type="name"
            debounce={{
              milliseconds: 1000,
              callback: handleSearchStr,
              textValue: searchStr,
            }}
          />
        </View>
        <PageNavButtons
          isPrevPageButtonVisible={currentPage !== 0}
          isNextPageButtonVisible={usersNotInGroup && usersNotInGroup.length === 25}
          prevPageAction={goToPreviousPage}
          nextPageAction={goToNextPage}
          style={styles.pageNavButtons}
        />
      </View>
      {usersNotInGroup ? (
        <AutocompleteList items={usersNotInGroup} onSelect={onSelect} />
      ) : (
        <ActivityIndicator style={styles.activityIndicator} />
      )}
    </View>
  );
};
