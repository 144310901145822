import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { UserList } from '../../../../../../uiComponents';
import { ManageGroupsNavType } from '../../ManageGroups.interface';
import { UserListUser } from '../../../../../shared/UserList/UserList.interface';
import { RemoveButton } from '../RemoveButton';
import { Group } from '../../fragments/__generated__/Group';
import { useRemoveUserFromGroup } from '../../hooks/useRemoveUserFromGroup';
import { Role } from '../../../../../../types/globalTypes';
import { AdminSubject } from '../../../ManageUsers/fragments/__generated__/AdminSubject';
import { useCurrentUser } from '../../../../../../shared/CurrentUser/useCurrentUser';
import { Name, Email } from '../../../ManageUsers/Columns';
import { GetGroupById_groupSubjects } from '../../hooks/__generated__/GetGroupById';

export interface ContentProps {
  group: Group;
  groupSubjects: GetGroupById_groupSubjects[] | null;
  organizationId: string;
  refetchGroup: () => void;
  searchStr: string;
  currentPage: number;
}

export const Content: React.FC<ContentProps> = ({
  group,
  organizationId,
  refetchGroup,
  groupSubjects,
  searchStr,
  currentPage,
}) => {
  const navigation = useNavigation<ManageGroupsNavType<'LoggedIn_Admin_ManageGroups_GroupDetails_Subjects'>>();
  const { translate } = useTranslations();

  let userRolesInOrganization;

  const { currentUser } = useCurrentUser();
  if (currentUser?.__typename === 'CurrentUser' && currentUser.roles) {
    userRolesInOrganization = currentUser.roles
      .filter((role) => role.organizationId === organizationId)
      .map((el) => el.role);
  }

  const styles = StyleSheet.create({
    root: {
      flexGrow: 1,
      flexBasis: '100%',
      flexShrink: 0,
    },
    userList: {
      flex: 1,
    },
  });

  const [removeSubjectFromGroup] = useRemoveUserFromGroup({
    onCompleted: () => {
      refetchGroup();
    },
  });

  const handleRemove = (user: AdminSubject) => {
    removeSubjectFromGroup({
      variables: {
        organizationId,
        userId: user.id,
        role: Role.SUBJECT,
        groupId: group.id,
      },
    });
  };

  const handleUserPress = (user: UserListUser) => {
    navigation.navigate('LoggedIn_Profile', {
      screen: 'LoggedIn_Profile_Overview',
      subjectId: user.id,
    });
  };

  const getEmptyMessage = () => {
    if (searchStr === '' && currentPage === 0) {
      return translate.admin.manageSeasons.groupDetails.noSubjects;
    } else if (currentPage !== 0) {
      return translate.admin.manageSeasons.groupDetails.endOfSubjectsList;
    } else {
      return translate.admin.manageSeasons.groupDetails.noSubjectsFound;
    }
  };

  return (
    <View style={styles.root}>
      {groupSubjects ? (
        <UserList
          noUsersMessage={getEmptyMessage()}
          mobileRearrangement={[0, 2, 1]}
          rightAlignLastItem={true}
          displayItems={groupSubjects.map((groupSubject) => ({
            key: groupSubject.id,
            onSelect: () =>
              userRolesInOrganization && userRolesInOrganization.includes(Role.PRIMARY_DESIGNATED_PERSON)
                ? handleUserPress(groupSubject)
                : null,
            components: [
              <Name name={groupSubject.fullName} />,
              <Email email={groupSubject.email ? groupSubject.email : ''} />,
              <RemoveButton onPress={() => handleRemove(groupSubject)} />,
            ],
          }))}
        />
      ) : null}
    </View>
  );
};
