import React from 'react';
import { AppButton } from '../../../../../uiComponents';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useLogout } from '../../../../../shared/Firebase';

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = (props) => {
  const { translate } = useTranslations();
  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <AppButton onPress={handleLogout} style={{ width: 200, marginTop: 20, alignSelf: 'flex-end' }}>
      {translate.wrongUser.logout}
    </AppButton>
  );
};
