import { FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue } from '../../../ManageProtocol.interface';
import { ReturnProtocolStageBaseConfig } from './ReturnProtocolStageBaseConfig';

export interface ReturnProtocolStageSignoffConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue>;
  touch?: FormikTouched<UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue>;
  value: UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const ReturnProtocolStageSignoffConfig: React.FC<ReturnProtocolStageSignoffConfigProps> = ({
  index,
  onRemove,
  move,
  itemKey,
  error,
  touch,
  value,
  canMoveUp,
  canMoveDown,
}) => {
  return (
    <ReturnProtocolStageBaseConfig
      index={index}
      itemKey={itemKey}
      move={move}
      onRemove={onRemove}
      value={value}
      canMoveDown={canMoveDown}
      canMoveUp={canMoveUp}
      error={error}
      touch={touch}
      typeSpecificItems={{}}
    />
  );
};
