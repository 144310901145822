import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { SuperAdminTile } from './SuperAdminTile';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Grid } from '../../../../uiComponents';
import { useIsFeatureFlagAvailable } from '../../../../hooks/useIsFeatureFlagAvailable';
import { Role } from '../../../../types/globalTypes';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();
  const { translate } = useTranslations();
  const handleManageSeasons = () => navigation.navigate('LoggedIn_SuperAdmin_CreateOrganization' as const);

  const handleImportData = () => {
    navigation.navigate('LoggedIn_SuperAdmin', { screen: 'LoggedIn_SuperAdmin_ImportData' });
  };

  const handlePaymentStatus = () => {
    navigation.navigate('LoggedIn_SuperAdmin_PaymentStatus' as const);
  };

  const { data } = useIsFeatureFlagAvailable({
    variables: {
      featureFlag: 'ENABLE_CSV_IMPORTER',
    },
  });
  const { currentUser } = useCurrentUser();
  const isSuperAdmin = currentUser?.__typename === 'CurrentUser' && currentUser.isSuperAdmin;

  const tiles: Array<JSX.Element> = [
    <SuperAdminTile
      icon={'users'}
      title={translate.superAdmin.createOrganization.title}
      onPress={handleManageSeasons}
    />,
    <SuperAdminTile icon={'users'} title={translate.superAdmin.paymentStatus.title} onPress={handlePaymentStatus} />,
  ];

  (data?.hasFeature || isSuperAdmin) &&
    tiles.splice(
      1,
      0,
      <SuperAdminTile icon={'upload'} title={translate.superAdmin.importData.importData} onPress={handleImportData} />
    );

  return (
    <>
      <Grid columns={2} tiles={tiles} />
    </>
  );
};
