import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { ProtocolFragment } from '../fragments/ProtocolFragment';
import { UpdateProtocolConfig, UpdateProtocolConfigVariables } from './__generated__/UpdateProtocolConfig';

const UPDATE_PROTOCOL_CONFIG = gql`
  mutation UpdateProtocolConfig($input: UpdateProtocolConfigInput!) {
    updateProtocolConfig(input: $input) {
      ... on Protocol {
        ...Protocol
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ProtocolFragment}
  ${ApiErrorFragment}
`;

export const useUpdateProtocolConfig = (
  options?: MutationHookOptions<UpdateProtocolConfig, UpdateProtocolConfigVariables>
) => useMutation(UPDATE_PROTOCOL_CONFIG, options);
