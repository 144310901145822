import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';

export const GET_SUBJECT_GUARDIANS_FOR_ORGANIZATION = gql`
  query GetSubjectGuardiansForOrganization($organizationId: String!, $userId: String!) {
    subjectGuardiansForOrganization(organizationId: $organizationId, userId: $userId) {
      ... on SubjectGuardians {
        guardians {
          id
          firstName
          lastName
          userId
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetSubjectGuardiansForOrganization = (organizationId: string, userId: string) =>
  useQuery(GET_SUBJECT_GUARDIANS_FOR_ORGANIZATION, { variables: { organizationId, userId } });
