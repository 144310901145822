import React from 'react';
import { CaseStatus } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppText } from '../../../../../uiComponents';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseItemCaseStatusChange } from '../../../../../fragments/__generated__/CaseItemCaseStatusChange';
import { CaseLogContextSubject } from '../CaseLogs.interface';

export interface CaseItemCaseStatusChangeProps {
  item: CaseItemCaseStatusChange;
  subject: CaseLogContextSubject;
}

export const CaseItemCaseStatusChangeView: React.FC<CaseItemCaseStatusChangeProps> = ({ item, subject }) => {
  const { translate, translateWithVars } = useTranslations();
  const { caseId } = item;

  const translationText =
    item.newCaseStatus === CaseStatus.CLOSED
      ? translate.caseLog.caseItemCaseStatusChange.closed
      : translate.caseLog.caseItemCaseStatusChange.opened;

  const text = translateWithVars(translationText, {
    subject: subject.fullName,
    caseId: `${caseId}`,
  });

  return (
    <BaseCaseLogItemView item={item}>
      <AppText>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
