import React from 'react';
import { StyleSheet } from 'react-native';
import { CheckboxOptionType } from './Checkbox.interface';
import { AppText } from '../AppText/AppText';
import { useColors } from '../../shared/Ui/hooks/useColors';
import { Check } from './Check';

export interface CheckboxOptionProps<T> {
  option: CheckboxOptionType<T>;
  checked?: boolean;
  onPress: (option: CheckboxOptionType<T>) => void;
}

export function CheckboxOption<T = any>({ option, checked, onPress }: CheckboxOptionProps<T>) {
  const colors = useColors();

  const styles = StyleSheet.create({
    label: {
      marginTop: 1,
      flex: 1,
    },
    htmlLabel: {
      fontSize: 16,
      lineHeight: 16 * 1.5,
      flex: 1,
      marginBottom: 0,
    },
  });

  const handlePress = () => onPress(option);

  const label = (
    <AppText font="bodyDefault" color="navyDark" style={styles.label}>
      {option.label}
    </AppText>
  );

  return (
    <Check checked={checked} onPress={handlePress}>
      {label}
    </Check>
  );
}
