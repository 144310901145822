import { format, formatDistanceToNow } from 'date-fns';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { fr } from 'date-fns/locale';

export const useDateFormat = () => {
  const { currentUser } = useCurrentUser();

  const language = currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.language : 'en';

  const languageOptions = {
    // add to this list once there are more languages
    fr: fr,
  };

  let locale = {};

  if (languageOptions[language]) {
    locale = { locale: languageOptions[language] };
  }

  return {
    formatDate: (date: Date, custom?: string, options?) =>
      format(date, custom || 'MMMM do yyyy', { ...options, ...locale }),
    formatDistanceToNow,
    formatDateAndTime: (date: Date, options?) => {
      return format(date, `MMMM do yyyy h:mm aaa`, {
        ...options,
        ...locale,
      });
    },
  };
};
