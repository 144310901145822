import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { FindParticipationCount } from './__generated__/FindParticipationCount';

export const FIND_PARTICIPATION_COUNT = gql`
  query FindParticipationCount($organizationId: ID!) {
    participationReportCount(organizationId: $organizationId) {
      ... on ParticipationReportCount {
        total
        active
        pending
      }
    }
  }
`;

export const useFindParticipationCount = (options?) =>
  useQuery<FindParticipationCount>(FIND_PARTICIPATION_COUNT, options);
