import React from 'react';
import { View, ViewStyle, StyleSheet, StyleProp } from 'react-native';
import { RadioButtonOption, RadioButtonOptionProps } from './RadioButtonOption';
import { RadioButtonOptionType, RadioButtonDirection } from './RadioButton.interface';
import { InputError } from '../InputError/InputError';

export interface RadioButtonProps<T> {
  options: RadioButtonOptionType<T>[];
  onSelect?: (value: T) => void;
  direction?: RadioButtonDirection;
  value?: T | null;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  customComponent?: React.ComponentType<RadioButtonOptionProps<T>>;
  touched?: boolean;
  error?: string;
}

export const RadioButton: <T = any>(props: RadioButtonProps<T>) => React.ReactElement = ({
  options,
  onSelect,
  value = null,
  direction = 'horizontal',
  customComponent,
  style,
  disabled,
  touched,
  error,
}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
    },
    horizontal: {
      flexDirection: 'row',
    },
    vertical: {
      flexDirection: 'column',
    },
    disabled: {},
    error: {
      marginRight: 'auto',
      zIndex: 1,
    },
  });

  const directionStyle = direction === 'horizontal' ? styles.horizontal : styles.vertical;

  const OptionComponent = customComponent ? customComponent : RadioButtonOption;

  return (
    <View style={[styles.container, directionStyle, style]}>
      {options.map((option, index) => (
        <OptionComponent
          direction={direction}
          option={option}
          value={value}
          key={index}
          onSelect={onSelect}
          allDisabled={disabled}
        />
      ))}
      <InputError touched={touched} error={error} style={styles.error} />
    </View>
  );
};
