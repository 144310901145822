import { gql } from '@apollo/client';
import { MedicalAssessmentFragment } from './MedicalAssessmentFragment';
import { RecoveryPlanStageDocumentFragment } from './RecoveryPlanStageDocumentFragment';
import { RecoveryPlanStageSignOffFragment } from './RecoveryPlanStageSignOffFragment';

export const RecoveryPlanFragment = gql`
  fragment RecoveryPlan on RecoveryPlan {
    id
    caseCreatedDate
    stages {
      ... on RecoveryPlanStageSignOff {
        ...RecoveryPlanStageSignOff
      }
      ... on RecoveryPlanStageDocument {
        ...RecoveryPlanStageDocument
      }
      ... on MedicalAssessment {
        ...MedicalAssessment
      }
    }
  }
  ${MedicalAssessmentFragment}
  ${RecoveryPlanStageDocumentFragment}
  ${RecoveryPlanStageSignOffFragment}
`;
