import { AdminNavItem } from '../Admin.interface';
import { Index } from '../Index';
import { useManageProtocolRoutes } from '../screens/ManageProtocol/hooks/useManageProtocolRoutes';
import { ManageProtocolNavigator } from '../screens/ManageProtocol/ManageProtocolNavigator';
import { useManageSeasonsRoutes } from '../screens/ManageSeasons/hooks/useManageSeasonsRoutes';
import { ManageSeasonsNavigator } from '../screens/ManageSeasons/ManageSeasonsNavigator';
import { useManageUsersRoutes } from '../screens/ManageUsers/hooks/useManageUsersRoutes';
import { ManageUsersNavigator } from '../screens/ManageUsers/ManageUsersNavigator';
import { useManageGroupsRoutes } from '../screens/ManageGroups/hooks/useManageGroupsRoutes';
import { ManageGroupsNavigator } from '../screens/ManageGroups/ManageGroupsNavigator';
import { useManageOrganizationRoutes } from '../screens/ManageOrganization/hooks/useManageOrganizationRoutes';
import { ManageOrganizationNavigator } from '../screens/ManageOrganization/ManageOrganizationNavigator';
import { ImportData } from '../../SuperAdmin/screens/ImportData/ImportData';

export const useAdminRoutes: () => AdminNavItem[] = () => {
  const manageOrganizationRoutes = useManageOrganizationRoutes();
  const manageSeasonsRoutes = useManageSeasonsRoutes();
  const manageProtocolRoutes = useManageProtocolRoutes();
  const manageUsersRoutes = useManageUsersRoutes();
  const manageGroupsRoutes = useManageGroupsRoutes();

  return [
    {
      component: Index,
      label: 'Admin',
      name: 'LoggedIn_Admin_Index' as const,
      path: '',
    },
    {
      component: ManageSeasonsNavigator,
      label: 'Manage Seasons',
      name: 'LoggedIn_Admin_ManageSeasons' as const,
      path: 'seasons',
      children: manageSeasonsRoutes,
    },
    {
      component: ManageProtocolNavigator,
      label: 'Manage Protocol',
      name: 'LoggedIn_Admin_ManageProtocol' as const,
      path: 'protocol',
      children: manageProtocolRoutes,
    },
    {
      component: ManageUsersNavigator,
      label: 'Manage Users',
      name: 'LoggedIn_Admin_ManageUsers' as const,
      path: 'users',
      children: manageUsersRoutes,
    },
    {
      component: ManageGroupsNavigator,
      label: 'Manage Groups',
      name: 'LoggedIn_Admin_ManageGroups' as const,
      path: 'groups',
      children: manageGroupsRoutes,
    },
    {
      component: ManageOrganizationNavigator,
      label: 'Manage Organization',
      name: 'LoggedIn_Admin_ManageOrganization' as const,
      path: 'organization',
      children: manageOrganizationRoutes,
    },
    {
      component: ImportData,
      label: 'Import Data',
      name: 'LoggedIn_Admin_ImportData' as const,
      path: 'import-data',
    },
  ];
};
