import { gql, MutationHookOptions } from '@apollo/client';
import { UseActionItemsQuery } from '../../../../ActionItems/hooks/__generated__/UseActionItems';
import { useMutation } from '../../../../../hooks/useMutation';
import { Role } from '../../../../../types/globalTypes';
import { ResendEducationItems, ResendEducationItemsVariables } from './__generated__/ResendEducationItems';
import { MY_ACTION_ITEMS_QUERY } from '../../../../ActionItems/hooks/useActionItems';

export const RESEND_EDUCATION_ITEMS = gql`
  mutation ResendEducationItems($organizationId: ID!) {
    resendEducationItems(organizationId: $organizationId) {
      ... on EducationItemResubmissionActionItem {
        id
        organizationName
        educationItemIds
      }
    }
  }
`;

export const useResendEducationItems = (
  userRolesForOrganization: Role[],
  options?: MutationHookOptions<ResendEducationItems, ResendEducationItemsVariables>
) =>
  useMutation<ResendEducationItems, ResendEducationItemsVariables>(RESEND_EDUCATION_ITEMS, {
    ...options,
    update(cache, { data }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });

      if (
        cachedActionItems &&
        data?.resendEducationItems &&
        (userRolesForOrganization.includes(Role.SUBJECT) ||
          userRolesForOrganization.includes(Role.GUARDIAN) ||
          userRolesForOrganization.includes(Role.PRIMARY_DESIGNATED_PERSON) ||
          userRolesForOrganization.includes(Role.SECONDARY_DESIGNATED_PERSON))
      ) {
        cache.writeQuery<UseActionItemsQuery>({
          query: MY_ACTION_ITEMS_QUERY,
          data: {
            __typename: 'Query',
            actionItems: cachedActionItems.actionItems.concat(data.resendEducationItems),
          },
        });
      }
    },
  });
