import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useGetGroupById } from '../../hooks/useGetGroupById';
import { ManageGroupsNavType, ManageGroupsRouteProp } from '../../ManageGroups.interface';
import { Content } from './Content';
import { useAdminContext } from '../../../../hooks/useAdminContext';
import { GroupDetailChrome } from '../GroupDetailChrome';
import { useIsMobile } from '../../../../../../hooks/ui/useResponsive';
import { Input, AppText, Select, AppButton, ActivityIndicator, PageNavButtons } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { useSearchAndSort, SortValue } from '../hooks/useSearchAndSort';
import { InvitationModal as AddUserModal } from '../InvitationModal/InvitationModal';
import { InviteUserFormData } from '../../../ManageUsers/InvitationModal/InvitationModal';
import { InvitationModal } from '../../../ManageUsers/InvitationModal/InvitationModal';
import { useAddUserToGroup } from '../../hooks/useAddUserToGroup';
import { useInviteUserToOrganizationRole } from '../../../ManageUsers/hooks/useInviteUserToOrganizationRole';
import { Role } from '../../../../../../types/globalTypes';

export const GroupDetailsDesignatedPersons = () => {
  const { organizationId } = useAdminContext();
  const navigation = useNavigation<ManageGroupsNavType<'LoggedIn_Admin_ManageGroups_GroupDetails_DesignatedPersons'>>();
  const route = useRoute<ManageGroupsRouteProp<'LoggedIn_Admin_ManageGroups_GroupDetails_DesignatedPersons'>>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>('');

  const [isDesignatedPersonInvitationModalVisible, setDesignatedPersonInvitationModalVisible] =
    useState<boolean>(false);
  const [isDesignatedPersonCreationModalVisible, setDesignatedPersonCreationModalVisible] = useState<boolean>(false);
  const [isSearchAndSortVisible, setIsSearchAndSortVisible] = useState<boolean>(false);

  if (!route?.params?.groupId) {
    throw new Error('Group ID is not set');
  }
  const { groupId } = route.params;
  const { translate } = useTranslations();

  const inputRef = useRef<TextInput>(null);
  const { handleSortChange, handleSearchStr, selectedSortType, searchStr, sortCategories, setIsSearchInputFocused } =
    useSearchAndSort(inputRef);

  const handleBack = () => navigation.navigate('LoggedIn_Admin_ManageGroups_Index');

  const isMobile = useIsMobile();

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedSortType, searchStr]);

  const styles = StyleSheet.create({
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
    },
    sortSelect: {
      width: isMobile ? '100%' : '35%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
      paddingRight: 15,
    },
    searchContainer: {
      width: isMobile ? '100%' : '65%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
    },
    headerRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    search: {
      flex: 3,
      marginRight: 20,
    },
    buttonSpacing: {
      marginRight: 15,
      marginBottom: 15,
    },
    addOrCreateButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 20,
      flexWrap: 'wrap',
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const { data, refetch: refetchGroup } = useGetGroupById({
    hideLoading: true,
    variables: {
      organizationId,
      groupId,
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      searchStr: searchStr ? searchStr : null,
    },
  });

  const groupDesignatedPersons = data ? data.groupDesignatedPersons : null;
  const group = data && data.group.__typename !== 'ApiError' ? data.group : null;

  useEffect(() => {
    if (data && data.group.__typename === 'Group' && data.group.name !== groupName) {
      setGroupName(data.group.name);
    }
    if (data && ((data.groupDesignatedPersons.length === 25 && currentPage === 0) || currentPage !== 0)) {
      setIsSearchAndSortVisible(true);
    }
  }, [data]);

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const [inviteUserToOrganizationRole, { loading: inviteSubmitting }] = useInviteUserToOrganizationRole(
    {
      onCompleted: () => {
        setDesignatedPersonCreationModalVisible(false);
        refetchGroup();
      },
    },
    organizationId,
    {
      skip: currentPage,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
    searchStr
  );

  const [inviteDesignatedPersonToGroup, { loading: designatedPersonInviteSubmitting }] = useAddUserToGroup({
    onCompleted: () => {
      setDesignatedPersonInvitationModalVisible(false);
      refetchGroup();
    },
  });

  const closeCreateDesignatedPersonModal = () => {
    setDesignatedPersonCreationModalVisible(false);
  };

  const closeAddDesignatedPersonModal = () => {
    setDesignatedPersonInvitationModalVisible(false);
  };

  const handleDesignatedPersonAdd = (userId: string) => {
    inviteDesignatedPersonToGroup({
      variables: {
        groupId: group ? group.id : '',
        userId,
        role: Role.SECONDARY_DESIGNATED_PERSON,
        organizationId,
      },
    });
    setCurrentPage(0);
  };

  const handleUserAdd = async (user: InviteUserFormData) => {
    await inviteUserToOrganizationRole({
      variables: {
        input: {
          organizationId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email || '',
          roles: (user.roles || []) as Role[],
          guardians: user.guardians.length > 0 ? user.guardians : null,
          groups: user.groupList.map((group) => group.id),
          birthday: user.birthday ? user.birthday : null,
        },
      },
    });
    setCurrentPage(0);
  };

  const openCreateDesignatedPersonModal = () => {
    setDesignatedPersonCreationModalVisible(true);
  };

  const openAddDesignatedPersonModal = () => {
    setDesignatedPersonInvitationModalVisible(true);
  };

  return (
    <>
      {group ? (
        <>
          <AddUserModal
            title={translate.admin.manageSeasons.groupDetails.addDesignatedPerson}
            visible={isDesignatedPersonInvitationModalVisible}
            onDismiss={closeAddDesignatedPersonModal}
            onAdd={handleDesignatedPersonAdd}
            submitting={designatedPersonInviteSubmitting}
            role={Role.SECONDARY_DESIGNATED_PERSON}
            excludeGroupId={group.id}
            organizationId={organizationId}
          />
          <InvitationModal
            title={translate.admin.manageSeasons.groupDetails.createDesignatedPerson}
            visible={isDesignatedPersonCreationModalVisible}
            onDismiss={closeCreateDesignatedPersonModal}
            submitting={inviteSubmitting}
            onAdd={handleUserAdd}
            defaultGroup={{ id: group.id, name: group.name }}
          />
        </>
      ) : null}
      <GroupDetailChrome
        name={groupName}
        organizationId={organizationId}
        groupId={groupId}
        activeTab="designated-persons"
        onBack={handleBack}
        content={
          <View>
            {isSearchAndSortVisible ? (
              <View style={styles.searchAndSortContainer}>
                <View style={styles.sortSelect}>
                  <AppText>{translate.users.userDetails.sort}</AppText>
                  <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
                </View>
                <View style={styles.searchContainer}>
                  <Input
                    icon="search"
                    ref={inputRef}
                    onFocus={() => setIsSearchInputFocused(true)}
                    onBlur={() => setIsSearchInputFocused(false)}
                    placeholder={translate.users.userDetails.searchByName}
                    type="name"
                    debounce={{
                      milliseconds: 1000,
                      callback: handleSearchStr,
                      textValue: searchStr,
                    }}
                  />
                </View>
              </View>
            ) : null}
            <View style={styles.headerRow}>
              <View style={styles.addOrCreateButtonContainer}>
                <AppButton style={styles.buttonSpacing} onPress={openAddDesignatedPersonModal}>
                  {translate.users.addUser.title}
                </AppButton>
                <AppButton type="outline" onPress={openCreateDesignatedPersonModal}>
                  {translate.admin.manageSeasons.groupDetails.createUser}
                </AppButton>
              </View>
              <PageNavButtons
                isPrevPageButtonVisible={currentPage !== 0}
                isNextPageButtonVisible={groupDesignatedPersons && groupDesignatedPersons.length === 25}
                prevPageAction={goToPreviousPage}
                nextPageAction={goToNextPage}
              />
            </View>
            {group ? (
              <Content
                group={group}
                groupDesignatedPersons={groupDesignatedPersons}
                organizationId={organizationId}
                refetchGroup={refetchGroup}
                searchStr={searchStr}
                currentPage={currentPage}
              />
            ) : (
              <ActivityIndicator style={styles.activityIndicator} />
            )}
          </View>
        }
      />
    </>
  );
};
