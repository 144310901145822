import * as React from 'react';
import { ActionListItem } from './ActionListItem';
import { useTranslations } from '../../../i18n/useTranslations';
import { useNavigation } from '@react-navigation/core';
import { IncompleteIncidentReportActionItem } from '../hooks/__generated__/IncompleteIncidentReportActionItem';
import { LoggedInNavType } from '../../LoggedIn.interface';
import { useDateFormat } from '../../../hooks/ui/useDateFormat';

interface IncompleteIncidentReportProps {
  item: IncompleteIncidentReportActionItem;
}

export const IncompleteIncidentReport: React.FC<IncompleteIncidentReportProps> = ({ item }) => {
  const { translateWithVars, translate } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ActionItems'>>();

  const { formatDate } = useDateFormat();

  const handlePress = () => {
    navigation.navigate('LoggedIn_IncidentReport', { caseId: item.id });
  };

  return (
    <ActionListItem
      onPress={handlePress}
      text={translateWithVars(translate.actionItems.incompleteIncidentReport, {
        name: item.name,
        date: formatDate(new Date(item.caseDate)),
      })}
    />
  );
};
