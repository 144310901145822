import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AppText, CheckboxOption } from '../../uiComponents';

export const RemovalStepDisplay: React.FC<{
  index: number;
  name: string;
  instruction: string;
  __typename: string;
  challenges?;
  symptoms?;
}> = (props) => {
  const { index, name, instruction, challenges, symptoms, __typename } = props;
  const styles = StyleSheet.create({
    subTitle: {
      marginTop: 20,
      marginBottom: 15,
    },
    text: {
      marginBottom: 10,
    },
  });

  let items = [];
  if (__typename === 'StepSymptom' && symptoms) {
    items = symptoms.map((symptom) => (
      <CheckboxOption key={symptom.id} option={{ value: symptom.id, label: symptom.value }} onPress={() => {}} />
    ));
  } else if (__typename === 'StepChallenge' && challenges) {
    items = challenges.map((challenge) => (
      <AppText key={challenge.value} style={{ marginLeft: 15 }}>
        {challenge.value}
      </AppText>
    ));
  }
  return (
    <View>
      <AppText font="header3" style={styles.subTitle}>
        {`${index + 2}. ${name}`}
      </AppText>
      <AppText style={styles.text}>{instruction}</AppText>
      {items}
    </View>
  );
};
