import React from 'react';
import { first } from 'lodash/fp';
import { useRoute, useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { useCaseLogs } from './hooks/useCaseLogsQuery';
import { AppScrollView, AppText, ActivityIndicator, AppLink } from '../../../../uiComponents';
import { CaseLogItems } from './CaseLogItems';
import { useCaseLog } from './hooks/useCaseLogQuery';
import { ProfileRouteProp } from '../../Profile.interface';
import { useTranslations } from '../../../../i18n/useTranslations';
import { RouteGuard } from '../../../shared/RouteGuard';
import { Role } from '../../../../types/globalTypes';
import { ProfileNavType } from '../../Profile.interface';
import { useDateFormat } from '../../../../hooks/ui/useDateFormat';
import { useGetProfile } from '../../ProfileChrome/hooks/useGetProfile';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = () => {
  const logs = useCaseLogs();
  const firstCase = first(logs);
  const isMobile = useIsMobile();
  const { params } = useRoute<ProfileRouteProp<'LoggedIn_Profile_Logs'>>();
  const caseId = params?.caseId ? params.caseId : firstCase?.id ? firstCase.id : '';
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<ProfileNavType<'LoggedIn_Profile_Logs'>>();
  const { formatDate } = useDateFormat();
  const profileData = useGetProfile();
  const styles = StyleSheet.create({
    root: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    caseSelection: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    caseList: { flexGrow: 1, flexShrink: 1, flexBasis: '100%' },
    subtitle: { marginLeft: 10, marginBottom: 10 },
    link: {
      marginLeft: 10,
      marginRight: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxWidth: '100%',
    },
    linkItem: {
      maxWidth: isMobile ? '85%' : '100%',
    },
  });

  const log = useCaseLog(caseId);

  const caseOrganizationId = log?.kase.organization.id ? log.kase.organization.id : '';
  const caseProtocolId = log?.kase.protocolId ? log.kase.protocolId : '';
  const caseProtocolName = log?.kase.protocolName ? log.kase.protocolName : '';
  const caseProtocolCreationDate = log?.kase.protocolCreationDate ? log?.kase.protocolCreationDate : '';

  const logItemsArea =
    log?.kase.items && caseOrganizationId && caseProtocolId && caseProtocolCreationDate ? (
      <CaseLogItems items={log?.kase.items} subject={log.subject} organizationName={log.kase.organization.name} />
    ) : (
      <ActivityIndicator />
    );

  return (
    <RouteGuard
      acceptedRoles={[Role.PRIMARY_DESIGNATED_PERSON, Role.GUARDIAN, Role.SUBJECT, Role.SECONDARY_DESIGNATED_PERSON]}
    >
      <View style={styles.root}>
        <AppText font="header4" style={styles.subtitle}>
          {profileData?.fullName}
        </AppText>
        <View style={styles.link}>
          <AppText color="navyDark" font="labelDefault">
            {`${translate.admin.manageOrganization.protocolLabel}: `}
          </AppText>
          <AppLink
            style={styles.linkItem}
            onPress={() =>
              navigation.navigate('LoggedIn_Protocol', {
                organizationId: caseOrganizationId,
                protocolId: caseProtocolId,
              })
            }
          >
            {`${caseProtocolName} (${translateWithVars(translate.caseLog.protocolVersion, {
              creationDate: caseProtocolCreationDate ? formatDate(new Date(caseProtocolCreationDate)) : '',
            })})`}
          </AppLink>
        </View>
        <AppScrollView style={styles.caseList}>{logItemsArea}</AppScrollView>
      </View>
    </RouteGuard>
  );
};
