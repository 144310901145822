import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { AddUserToGroup, AddUserToGroupVariables } from './__generated__/AddUserToGroup';

export const ADD_USER_TO_GROUP_MUTATION = gql`
  mutation AddUserToGroup($groupId: ID!, $userId: ID!, $role: Role!, $organizationId: ID!) {
    addUserToGroup(groupId: $groupId, userId: $userId, role: $role, organizationId: $organizationId) {
      ... on GroupUserInfo {
        groupId
        userId
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;
// data from this mutation is refetched after it is done

export const useAddUserToGroup = (options?: MutationHookOptions<AddUserToGroup, AddUserToGroupVariables>) =>
  useMutation<AddUserToGroup, AddUserToGroupVariables>(ADD_USER_TO_GROUP_MUTATION, options);
