/* Form validation functions
=============================== */

import { usePasswordRules } from '../usePasswordRules';
import { useValidatePhoneNumber } from './components/useValidatePhoneNumber';

export const useValidation = () => {
  const passwordRules = usePasswordRules();
  const validatePhone = useValidatePhoneNumber();

  const validateEmail = (value: string) => {
    return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(value);
  };

  const validatePassword = (value: string) => {
    let isValid = true;

    passwordRules.forEach((rule) => {
      isValid = isValid && rule.check(value);
    });

    return isValid;
  };

  const validatePasswordConfirm = (value1: string, value2: string) => {
    return value1 === value2;
  };

  return {
    validateEmail,
    validatePhone,
    validatePassword,
    validatePasswordConfirm,
  };
};
