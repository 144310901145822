import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppModal, AppText } from '../../../../uiComponents';
import { UserListUser } from '../../../shared/UserList/UserList.interface';

export interface NudgeRoleConfirmDialogProps {
  inviting?: boolean;
  visible?: boolean;
  user?: UserListUser;
  onDismiss: () => void;
  onInvite: () => void;
}

export const NudgeRoleConfirmDialog: React.FC<NudgeRoleConfirmDialogProps> = ({
  inviting,
  visible,
  user,
  onDismiss,
  onInvite,
}) => {
  const { translate, translateWithVars } = useTranslations();
  return (
    <AppModal
      visible={visible}
      onDismiss={onDismiss}
      mode="dialog"
      title={translate.users.userDetails.nudgeRoleConfirmModal.title}
      children={
        <AppText style={{ textAlign: 'center' }}>
          {translateWithVars(translate.users.userDetails.nudgeRoleConfirmModal.message, {
            name: user?.fullName,
          })}
        </AppText>
      }
      buttons={{
        send: (
          <AppButton loading={inviting} disabled={inviting} onPress={onInvite}>
            {translate.users.userDetails.nudgeRoleConfirmModal.send}
          </AppButton>
        ),
      }}
    />
  );
};
