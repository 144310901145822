import { gql } from '@apollo/client';
import { CountryFragment } from './Country';
import { PhoneNumberFragment } from './PhoneNumber';

export const AppSettingsFragment = gql`
  fragment AppSettings on AppSettings {
    id
    defaultEmergencyNumber {
      ...PhoneNumber
    }
    defaultCountry {
      ...Country
    }
  }
  ${PhoneNumberFragment}
  ${CountryFragment}
`;
