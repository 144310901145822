import React from 'react';
import { View, StyleSheet } from 'react-native';
import { AppText, Input, RadioButton } from '../../uiComponents';
import { RadioButtonResponse } from '../../types/globalTypes';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { useFormikContext } from 'formik';
import { IncidentReportFormData } from './IncidentReport';
import { useTranslations } from '../../i18n/useTranslations';
import { LabelWithAsterisk } from './IncidentReportContent';

interface RadioQuestionProps {
  question: string;
  formikRadioValue: string | undefined;
  radioValue: string;
  formikInputValue: string | undefined;
  handleChange: (value: string, val: string) => void;
  inputValue: string;
  label: string;
  isRequired?: boolean;
}

export const RadioQuestion: React.FC<RadioQuestionProps> = ({
  question,
  formikRadioValue,
  radioValue,
  inputValue,
  formikInputValue,
  label,
  handleChange,
  isRequired = false,
}) => {
  const isMobile = useIsMobile();
  const { translate } = useTranslations();

  const { errors, setFieldTouched, touched } = useFormikContext<IncidentReportFormData>();

  const styles = StyleSheet.create({
    container: {
      marginTop: 10,
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    radioQuestion: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
    },
    detailsInput: {
      minWidth: '40%',
      flex: 1,
      marginLeft: 20,
      marginRight: 10,
    },
    input: {
      flex: 1,
      paddingRight: 30,
    },
    questionWithAsterisk: {
      display: 'flex',
      flexDirection: 'row',
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.radioQuestion}>
        <View style={styles.questionWithAsterisk}>
          <AppText>{question}</AppText>
          {isRequired ? <AppText color="red"> *</AppText> : null}
        </View>
        <RadioButton
          onSelect={(val) => handleChange(radioValue, val)}
          value={formikRadioValue}
          error={errors[radioValue]}
          touched={touched[radioValue]}
          options={[
            {
              icon: 'check',
              label: translate.answers.YES,
              value: RadioButtonResponse.YES,
            },
            {
              icon: 'check',
              label: translate.answers.NO,
              value: RadioButtonResponse.NO,
            },
            {
              icon: 'check',
              label: translate.answers.DONT_KNOW,
              value: RadioButtonResponse.DONT_KNOW,
            },
          ]}
        />
      </View>
      {formikRadioValue === RadioButtonResponse.YES ? (
        <View style={styles.details}>
          <LabelWithAsterisk label={<AppText>{label}</AppText>} />
          <View style={styles.input}>
            <Input
              style={styles.detailsInput}
              value={formikInputValue}
              onChangeText={(val) => handleChange(inputValue, val)}
              error={errors[inputValue]}
              onBlur={() => setFieldTouched(inputValue, true)}
              touched={touched[inputValue]}
            />
          </View>
        </View>
      ) : null}
    </View>
  );
};
