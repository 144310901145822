import React from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { TabButton } from './TabButton';
import { useColors } from '../../shared/Ui';

export interface Tab {
  label: string;
  onPress?: () => void;
  active?: boolean;
}

interface TabsProps {
  tabs: Tab[];
}

export const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const colors = useColors();

  const styles = StyleSheet.create({
    container: {
      marginVertical: 16,
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      display: 'flex',
      borderBottomColor: colors.navyDark,
      borderBottomWidth: 1,
    },
  });
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      nestedScrollEnabled={true}
    >
      {tabs.map((tab: Tab, index) => (
        <TabButton onPress={tab.onPress} key={index} active={tab.active}>
          {tab.label}
        </TabButton>
      ))}
    </ScrollView>
  );
};
