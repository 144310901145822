import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { AppText, SVG, Icon, AppButton } from '../../../uiComponents';
import { useColors, ColorKeys } from '../../Ui';
import { Notch } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import CAPTWithWhiteText from '../../../assets/svg/CAPTWithWhiteText.svg';
import { useActionItemsQuery } from '../../../screens/ActionItems/hooks/useActionItems';
import { useCurrentUser } from '../../CurrentUser/useCurrentUser';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Role } from '../../../types/globalTypes';
import { LoggedInNavType } from '../../../screens/LoggedIn.interface';
import { HamburgerMenu } from './HamburgerMenu';
import '@expo/match-media';
import { Navbar } from './Index';
import { useNavbarNavigation, NavBarIconProps } from './useNavbarNavigation';
import { NavBarProps } from '../Chrome';
import { useHamburgerMenuProps } from './useHamburgerMenuProps';
import * as Localization from 'expo-localization';

interface BackgroundProps {
  ratio?: number;
  primaryColor?: ColorKeys;
  secondaryColor?: ColorKeys;
  includeSecondary?: boolean;
}

export const NavBarForWeb: React.FC<NavBarProps> = (props) => {
  const { content, title, titleText, navbar, hideNavigation, footer, subNavigation, hideNavItems } = props;

  const colors = useColors();
  const { translate } = useTranslations();
  const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false);
  const route = useRoute();

  const { data: actionItemsData } = useActionItemsQuery({
    hideLoading: route.name !== 'LoggedIn_Home',
    skip: route.name === 'Welcome' || route.name === 'Login' || route.name === 'ResetPassword',
  });
  const actionItems = actionItemsData?.actionItems || [];

  let actionItemsToCheck = actionItems;
  if (route.name === 'LoggedIn_IncidentReport') {
    actionItemsToCheck = actionItems.filter(
      (actionItem) => actionItem.__typename !== 'IncompleteIncidentReportActionItem'
    );
  }
  const hasActionItems = actionItemsToCheck.length > 0;

  const { currentUser } = useCurrentUser();
  const userLanguage =
    currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.language : Localization.locale.split('-')[0];

  const userRoles = currentUser?.__typename === 'CurrentUser' ? currentUser.roles.map((role) => role.role) : [];
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Home'>>();
  const { changeUserLanguage, handleNavigation, languageMenuItems, navigationMenuItems } = useHamburgerMenuProps();

  const styles = StyleSheet.create({
    keyboardAvoidingView: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
    },
    contentContainer: {
      width: '100%',
      alignSelf: 'center',
      paddingVertical: 20, // using padding instead of margin so that box-shadows appear outside edges
      paddingHorizontal: '15%',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 0,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 5,
      marginBottom: 15,
    },
    back: {
      marginRight: 18,
      paddingRight: 15,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      backgroundColor: colors.white,
    },
    footer: {
      width: '100%',
    },
  });

  const Background: React.FC<BackgroundProps> = ({ primaryColor = 'green' }) => {
    const { navigateToLink, navItems, showItem } = useNavbarNavigation(hideNavItems);

    const getNavItemPadding = (numberOfNavItems: number): number => {
      switch (numberOfNavItems) {
        case 7:
        case 6:
          return 15;
        case 5:
          return 25;
        default:
          return 30;
      }
    };

    const styles = StyleSheet.create({
      backgroundContainer: {
        height: 80,
        backgroundColor: colors[primaryColor],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 30,
        zIndex: 100,
        elevation: Platform.OS === 'android' ? 50 : 10,
      },
      navbarMenuItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      iconContainer: {
        marginRight: 5,
      },
      logo: {
        paddingVertical: 18,
        paddingRight: 10,
      },
      navBarOther: {
        width: '10%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      recognizeButtonContainer: {
        marginRight: 15,
      },
      actionItemsButtonContainer: {
        backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        borderRadius: 18,
        height: 36,
        borderColor: colors.white,
        borderWidth: 3,
        marginRight: 15,
      },
      navItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 80,
        paddingHorizontal: getNavItemPadding(navItems.length),
      },
      selectedNavItem: {
        backgroundColor: colors.navyDarker,
      },
      languageMenu: {
        marginHorizontal: 10,
      },
    });

    const NavItem: React.FC<NavBarIconProps> = ({ icon, name, routes }) => {
      return showItem(name) ? (
        <TouchableOpacity
          style={[
            styles.navItem,
            routes.includes(route.name) && !(route.name === 'LoggedIn_Profile_Overview' && route.params)
              ? styles.selectedNavItem
              : null,
          ]}
          onPress={() => navigateToLink(name)}
        >
          <View style={styles.iconContainer}>
            <Icon name={icon} size={18} color="white" />
          </View>
          <AppText font={navItems.length > 5 ? 'labelDefault' : 'header6'} color="white">
            {name}
          </AppText>
        </TouchableOpacity>
      ) : null;
    };

    const handleToggleHamburgerMenu = () => {
      setShowLanguageMenu(false);
      setShowHamburgerMenu((showHamburgerMenu) => !showHamburgerMenu);
    };

    const handleToggleLanguageMenu = () => {
      setShowHamburgerMenu(false);
      setShowLanguageMenu((showLanguageMenu) => !showLanguageMenu);
    };

    const handleHomePressed = () => navigation.navigate('Welcome');

    return !hideNavigation ? (
      <View style={styles.backgroundContainer}>
        <Notch position="bottom-left" color="white" />
        <View style={styles.navbarMenuItems}>
          <TouchableOpacity onPress={() => navigateToLink(translate.navigation.home)} style={styles.logo}>
            <CAPTWithWhiteText width={120} height={44} />
          </TouchableOpacity>
          {navItems.map((item) =>
            item.name === translate.navigation.home ? (
              <View key="Home"></View>
            ) : (
              <NavItem icon={item.icon} name={item.name} key={item.name} routes={item.routes} />
            )
          )}
        </View>

        <View style={styles.navBarOther}>
          {userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON) ||
          userRoles.includes(Role.SECONDARY_DESIGNATED_PERSON) ? (
            <View style={styles.recognizeButtonContainer}>
              {!hideNavItems ? (
                <AppButton onPress={() => navigateToLink(translate.admin.manageProtocol.index.sectionRemoval)}>
                  {navItems.length > 5 ? translate.home.tile.recognizeMobile : translate.home.tile.recognize}
                </AppButton>
              ) : null}
            </View>
          ) : null}

          <View style={styles.languageMenu}>
            <TouchableOpacity onPress={handleToggleLanguageMenu}>
              <AppText color="white" font="labelDefault">
                {userLanguage.toUpperCase()}
              </AppText>
            </TouchableOpacity>
            {showLanguageMenu ? (
              <HamburgerMenu
                setShowHamburgerMenu={setShowLanguageMenu}
                type="languageMenuItems"
                handlePress={changeUserLanguage}
                menuItems={languageMenuItems}
              />
            ) : null}
          </View>

          {hasActionItems ? (
            <TouchableOpacity
              style={styles.actionItemsButtonContainer}
              onPress={() => navigateToLink(translate.home.tile.actionItems)}
            >
              <AppText font="header3" color="white">
                !
              </AppText>
            </TouchableOpacity>
          ) : null}
          <View>
            <TouchableOpacity onPress={handleToggleHamburgerMenu}>
              <SVG image={'hamburger'} width={21} height={36} />
            </TouchableOpacity>
            {showHamburgerMenu ? (
              <HamburgerMenu
                setShowHamburgerMenu={setShowHamburgerMenu}
                type="hamburgerMenuItems"
                handlePress={handleNavigation}
                menuItems={navigationMenuItems}
              />
            ) : null}
          </View>
        </View>
      </View>
    ) : (
      <View style={styles.backgroundContainer}>
        <Notch position="bottom-left" color="white" />
        <CAPTWithWhiteText width={120} height={44} />
        <TouchableOpacity style={{ marginLeft: 'auto' }} onPress={handleHomePressed}>
          <Icon name={'arrow-left'} size={24} color={colors.white} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <Navbar
      background={Background}
      subNavigation={subNavigation}
      content={content}
      styles={styles}
      footer={footer}
      navbar={navbar}
      title={title}
      titleText={titleText}
      showHamburgerMenu={showHamburgerMenu}
      setShowHamburgerMenu={setShowHamburgerMenu}
      showLanguageMenu={showLanguageMenu}
      setShowLanguageMenu={setShowLanguageMenu}
    />
  );
};
