import { AssessActionSetStepValuesPayload, AssessContextState } from '../../../Assess.interface';
import { setStepSymptomValues } from './setStepSymptomValues';
import { setStepChallengeValues } from './setStepChallengeValues';

export const setStepValues = (
  nextState: AssessContextState,
  payload: AssessActionSetStepValuesPayload
): AssessContextState => {
  if (payload.type === 'challenge') {
    return setStepChallengeValues(nextState, payload);
  } else {
    return setStepSymptomValues(nextState, payload);
  }
};
