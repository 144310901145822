import React from 'react';
import { useFormikContext } from 'formik';
import { AppButton, Input, Spacer } from '../../../uiComponents';
import { ResetPasswordFormValues } from './ResetPasswordScreen';
import { useTranslations } from '../../../i18n/useTranslations';

export interface ResetPasswordFieldsProps {}

export const ResetPasswordFields: React.FC<ResetPasswordFieldsProps> = () => {
  const { translate } = useTranslations();
  const {
    values: { email },
    errors,
    touched,
    handleChange,
    submitForm,
  } = useFormikContext<ResetPasswordFormValues>();

  return (
    <Spacer
      items={{
        input: (
          <Input
            key="email"
            icon="mail"
            value={email}
            error={errors.email}
            touched={touched.email}
            type="email"
            placeholder={translate.placeholder.email}
            returnKeyType="go"
            onChangeText={handleChange('email')}
            autoFocus={true}
          />
        ),
        button: <AppButton onPress={submitForm}>{translate.auth.term.resetPassword}</AppButton>,
      }}
    />
  );
};
