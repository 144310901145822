import { ApolloCache } from '@apollo/client';
import { NormalizedCacheObject, InMemoryCache, TypePolicies } from '@apollo/client/cache';
import possibleTypes from './possibleTypes.json';

export const makeCache: (typePolicies?: TypePolicies) => ApolloCache<NormalizedCacheObject> = (typePolicies) => {
  return new InMemoryCache({
    typePolicies: {
      ...(typePolicies || {}),
    },
    possibleTypes,
  });
};
