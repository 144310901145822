import { useMemo } from 'react';
import { LoginScreen } from '../screens/Login/LoginScreen/LoginScreen';
import { RegisterScreen } from '../screens/Login/RegisterScreen/RegisterScreen';
import { ResetPasswordScreen } from '../screens/Login/ResetPasswordScreen/ResetPasswordScreen';
import { WelcomeScreen } from '../screens/Login/WelcomeScreen';
import { RootNavItem, RootParamList } from '../RootNavigator.interface';
import { LoggedInNavigator } from '../screens/LoggedInNavigator';
import { useLoggedInRoutes } from '../screens/useLoggedInRoutes';
import { PathConfigMap } from '@react-navigation/native';
import { isEmpty, reduce } from 'lodash/fp';
import { AppNavItem } from '../app.interface';
import { VerifiedScreen } from '../screens/Login/VerifiedScreen/VerifiedScreen';
import { Invitation } from '../screens/Invitation/Invitation';
import { ChangePasswordScreen } from '../screens/Login/ChangePasswordScreen/ChangePasswordScreen';

export const useRootRoutes = () => {
  const loggedInRoutes = useLoggedInRoutes();

  const rootRoutes: RootNavItem[] = [
    {
      component: WelcomeScreen,
      label: 'Welcome',
      name: 'Welcome' as const,
      path: 'welcome',
      mode: 'public' as const,
    },
    {
      component: LoginScreen,
      label: 'Login',
      name: 'Login' as const,
      path: 'login/:invitationIdentifier?',
      mode: 'public' as const,
    },
    {
      component: RegisterScreen,
      label: 'Register',
      name: 'Register' as const,
      path: 'register/:invitationIdentifier?',
      mode: 'public' as const,
    },
    {
      component: ResetPasswordScreen,
      label: 'ResetPassword',
      name: 'ResetPassword' as const,
      path: 'reset-password',
      mode: 'public' as const,
    },
    {
      component: VerifiedScreen,
      label: 'VerifiedScreen',
      name: 'VerifiedScreen' as const,
      path: 'verified',
      mode: 'any' as const,
    },
    {
      component: LoggedInNavigator,
      label: 'Logged In',
      name: 'LoggedIn' as const,
      path: '',
      children: loggedInRoutes,
    },
    {
      component: Invitation,
      name: 'Invitation' as const,
      path: 'invitation/:invitationIdentifier/:language?',
      mode: 'any',
    },
    {
      component: ChangePasswordScreen,
      label: 'ChangePassword',
      name: 'ChangePassword' as const,
      path: 'change-password',
      mode: 'any',
    },
  ];

  return rootRoutes;
};

const useScreenReducer = (appNavItems: AppNavItem[]) => {
  const defaultItem = {};

  const reducer = reduce<AppNavItem, PathConfigMap<RootParamList>>((accum, value) => {
    if (isEmpty(value.children)) {
      accum[value.name] = value.path;
      return accum;
    } else {
      // TODO: fix typings here
      const configMap: any = {};
      const children: any = value.children;
      const screens = reducer(configMap, children);

      accum[value.name] = {
        path: value.path,
        screens,
      };

      return accum;
    }
  });

  const screens = useMemo(() => reducer(defaultItem, appNavItems), []);

  return screens;
};

export const useRootScreens = () => useScreenReducer(useRootRoutes());
