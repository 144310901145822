import * as React from 'react';
import { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { ActivityIndicator, AppScrollView, AppText, Select } from '../../../../uiComponents';
import { OrganizationField } from './OrganizationField';
import { useGetOrganizationStatuses } from '../../hooks/useGetOrganizationStatuses';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { useTranslations } from '../../../../i18n/useTranslations';

const statusOrder = {
  TRIAL: 1,
  SUSPENDED: 2,
  PAID: 3,
  CLOSED: 4,
  DEMO: 5,
  TEST: 6,
};

export const Content: React.FC = () => {
  const isMobile = useIsMobile();
  const { translate } = useTranslations();

  const sortTypes = [
    {
      key: 'dateCreated',
      value: 'dateCreated',
      label: translate.superAdmin.paymentStatus.label.dateCreated,
    },
    {
      key: 'status',
      value: 'status',
      label: translate.subjectStatus.modalTitle,
    },
    {
      key: 'organizationName',
      value: 'organizationName',
      label: translate.admin.manageOrganization.organizationNameInputLabel,
    },
  ];

  const [sortType, setSortType] = useState<string>(sortTypes[0].value);
  const { data } = useGetOrganizationStatuses();

  const organizationData =
    data && data.organizationStatuses
      ? data.organizationStatuses.map((organization) => ({
          organizationId: organization.id,
          expiresAt: organization.expiresAt ? organization.expiresAt : null,
          organizationName: organization.name,
          status: organization.status,
          createdAt: organization.createdAt,
        }))
      : [];

  const handleSortTypeChange = (_sortType: string) => {
    setSortType(_sortType);
  };

  const styles = StyleSheet.create({
    paymentStatusContainer: {
      flexGrow: 1,
      flexBasis: '100%',
      flexShrink: 0,
    },
    sortTypeSelect: {
      width: !isMobile ? 400 : '100%',
    },
  });

  let sortedData = organizationData;
  if (sortType === 'dateCreated') {
    sortedData = organizationData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  } else if (sortType === 'status') {
    sortedData = organizationData.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
  } else if (sortType === 'organizationName') {
    sortedData = organizationData.sort((a, b) => {
      if (a.organizationName.toLowerCase() < b.organizationName.toLowerCase()) {
        return -1;
      } else if (a.organizationName.toLowerCase() > b.organizationName.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return (
    <AppScrollView showsVerticalScrollIndicator={false}>
      <View style={{ flexGrow: 1, flexBasis: '100%', flexShrink: 0 }}>
        <AppText>{translate.users.userDetails.sort}</AppText>
        <Select<string>
          value={sortType}
          items={sortTypes}
          onChange={handleSortTypeChange}
          style={styles.sortTypeSelect}
        />
        {organizationData.length > 0 ? (
          sortedData.map((organization) => (
            <OrganizationField
              id={organization.organizationId}
              key={organization.organizationId}
              organizationName={organization.organizationName}
              status={organization.status}
              validUntilDate={organization.expiresAt}
            />
          ))
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </AppScrollView>
  );
};
