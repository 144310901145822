import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppModal, AppText } from '../../../../uiComponents';
import { UserListUser } from '../../../shared/UserList/UserList.interface';

export interface RemoveRoleConfirmDialogProps {
  removing?: boolean;
  visible?: boolean;
  user?: UserListUser;
  role?: string;
  onDismiss: () => void;
  onRemove: () => void;
}

export const RemoveRoleConfirmDialog: React.FC<RemoveRoleConfirmDialogProps> = ({
  removing,
  visible,
  user,
  role,
  onDismiss,
  onRemove,
}) => {
  const { translate, translateWithVars } = useTranslations();
  return (
    <AppModal
      visible={visible}
      onDismiss={onDismiss}
      mode="dialog"
      title={translate.users.userDetails.removeRoleConfirmModal.title}
      children={
        <AppText style={{ textAlign: 'center' }}>
          {translateWithVars(translate.users.userDetails.removeRoleConfirmModal.message, {
            name: user?.fullName,
            role,
          })}
        </AppText>
      }
      buttons={{
        dismiss: (
          <AppButton disabled={removing} onPress={onDismiss} type="outline">
            {translate.label.cancel}
          </AppButton>
        ),
        remove: (
          <AppButton loading={removing} disabled={removing} onPress={onRemove} type="danger">
            {translate.label.remove}
          </AppButton>
        ),
      }}
    />
  );
};
