import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton } from '../../../../uiComponents';
import { ManageUsersNavType } from './ManageUsers.interface';
import { ActiveAndTotalUsers } from './Index';

export interface ManageUsersButtonProps {
  activeAndTotalUsers: ActiveAndTotalUsers;
}

export const ManageUsersButton: React.FC<ManageUsersButtonProps> = ({ activeAndTotalUsers }) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<ManageUsersNavType<'LoggedIn_Admin_ManageUsers_Index'>>();

  const { active, total } = activeAndTotalUsers;
  const inactiveParticipantsButtonLabel = `${active}/${total} ${translate.users.participants}`;

  return (
    <AppButton
      key="participant"
      type="outline"
      onPress={() => navigation.navigate('LoggedIn_Admin_ManageUsers_ParticipationReport')}
      style={{ width: 200, marginTop: 20, alignSelf: 'flex-start', marginBottom: 20 }}
    >
      {inactiveParticipantsButtonLabel}
    </AppButton>
  );
};
