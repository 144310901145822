import { gql } from '@apollo/client';

export const DocumentFragment = gql`
  fragment Document on Document {
    id
    uri
    downloadUri: uri(input: { download: true })
    size
    createdDate
  }
`;
