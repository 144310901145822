/* Global nav bar
=============================== */

import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, SafeAreaView, ViewStyle } from 'react-native';
import { useNavigation, useRoute, CommonActions } from '@react-navigation/native';
import { DrawerActions } from '@react-navigation/routers';
import { ColorKeys } from '../../../../shared/Ui';
import { AppButton, AppLink, AppModal, AppText, SVG } from '../../../../uiComponents';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { RootNavType } from '../../../../RootNavigator.interface';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AssessNavType } from '../../../Assess/Assess.interface';
import { useImportDataContext } from '../../../SuperAdmin/hooks/useImportDataContext';

export interface NavBarProps {
  showBackLink?: boolean;
  showHamburger?: boolean;
  color?: ColorKeys;
  backLabel?: string;
  onBack?: () => void;
  style?: ViewStyle;
}

export const NavBar: React.FC<NavBarProps> = ({
  showBackLink = true,
  showHamburger = true,
  color = 'white',
  backLabel,
  onBack,
  style,
}) => {
  const navigation = useNavigation<RootNavType<any>>();
  const navigator = useNavigation<AssessNavType<any>>();
  const route = useRoute();
  const { translate } = useTranslations();

  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

  const { csvAttachments } = useImportDataContext();

  const goBackHandler = () => {
    const existingAttachments = Object.values(csvAttachments).filter((group) => group !== undefined);
    if (
      (route.name === 'LoggedIn_SuperAdmin_ImportData' || route.name === 'LoggedIn_Admin_ImportData') &&
      existingAttachments.length !== 0
    ) {
      setConfirmDialog(true);
    } else {
      const canGoBack = navigation.canGoBack();
      if (canGoBack) {
        navigation.goBack();
      }
    }
  };

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 30,
      paddingTop: 10,
    },
    back: {
      marginLeft: -18,
      paddingRight: 15,
    },
  });

  const handleOnBack = () => {
    setConfirmDialog(true);
  };
  const handleDismiss = () => {
    setConfirmDialog(false);
  };

  const handleContinue = () => {
    if (route.name === 'LoggedIn_SuperAdmin_ImportData') {
      navigator.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: 'LoggedIn_SuperAdmin_Index',
            },
          ],
        })
      );
    } else {
      navigator.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: 'LoggedIn_Home',
            },
          ],
        })
      );
    }
  };

  return (
    <View>
      <SafeAreaView />
      {confirmDialog ? (
        <AppModal
          visible={confirmDialog}
          onDismiss={handleDismiss}
          mode="dialog"
          title={translate.recognize.exitModal.title}
          buttons={{
            continue: (
              <AppButton onPress={handleContinue} type="outline">
                {translate.label.quit}
              </AppButton>
            ),
          }}
          children={<AppText>{translate.recognize.exitModal.content}</AppText>}
        />
      ) : null}
      <View style={[styles.container, style]}>
        {showBackLink ? (
          <AppLink
            onPress={onBack ? () => onBack() : () => goBackHandler()}
            style={styles.back}
            icon="chevron-left"
            iconOpacity={1}
            color={color}
          >
            {backLabel ? backLabel : ''}
          </AppLink>
        ) : null}
        {showHamburger ? (
          <TouchableOpacity
            style={{ marginLeft: 'auto' }}
            onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          >
            <SVG image={'hamburger'} width={28} height={48} />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};
