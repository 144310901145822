import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useDateFormat } from '../../../../../hooks/ui/useDateFormat';
import { BaseCaseItem } from '../../../../../fragments/__generated__/BaseCaseItem';
import { AppText, ShadowView, Spacer } from '../../../../../uiComponents';
import { useColors } from '../../../../../shared/Ui/hooks';

export interface BaseCaseLogItemProps {
  item: BaseCaseItem;
  action?: React.ReactNode | undefined | null;
  children: React.ReactNode;
}

export const BaseCaseLogItemView: React.FC<BaseCaseLogItemProps> = ({ item, action, children }) => {
  const colors = useColors();
  const { formatDateAndTime } = useDateFormat();

  const { width } = useWindowDimensions();

  const wide = width > 720;

  const styles = StyleSheet.create({
    root: {
      padding: 20,
      backgroundColor: colors.white,
      margin: 10,
    },
    actionContainer: wide
      ? {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {
          flexDirection: 'column',
        },
    actionChildren: wide
      ? {
          flex: 1,
        }
      : { flexGrow: 1, flexShrink: 0, flexBasis: 'auto', marginBottom: 10 },
    actionAction: wide
      ? {
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'auto',
        }
      : { flexGrow: 1, flexShrink: 0, flexBasis: 'auto' },
  });

  const childArea = (
    <Spacer
      items={{
        children: <>{children}</>,
        date: <AppText font="bodySmall">{formatDateAndTime(new Date(item.createdDate))}</AppText>,
      }}
    />
  );

  const content = action ? (
    <View style={styles.actionContainer}>
      <View style={styles.actionChildren}>{childArea}</View>
      <View style={styles.actionAction}>{action}</View>
    </View>
  ) : (
    childArea
  );
  return <ShadowView style={styles.root}>{content}</ShadowView>;
};
