import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';
import { ApproveProtocol, ApproveProtocolVariables } from './__generated__/ApproveProtocol';
import { MY_ACTION_ITEMS_QUERY } from '../../ActionItems/hooks/useActionItems';
import { UseActionItemsQuery } from '../../ActionItems/hooks/__generated__/UseActionItems';
import { IS_DP_COMPLETED_EDUCATION_ITEMS } from '../../../hooks/useIsDPCompletedEducationItems';
import { Role } from '../../../types/globalTypes';
import { GET_ALL_USER_ROLES } from '../../../hooks/useGetAllUserRoles';
import { allUserRoles } from '../../../hooks/__generated__/allUserRoles';
import { isDPCompletedEducationItems } from '../../../hooks/__generated__/isDPCompletedEducationItems';

export const APPROVE_PROTOCOL_MUTATION = gql`
  mutation ApproveProtocol($protocolId: ID!) {
    approveProtocol(protocolId: $protocolId) {
      ... on Protocol {
        approved
        id
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useApproveProtocol = (
  organizationId: string,
  options?: MutationHookOptions<ApproveProtocol, ApproveProtocolVariables>
) =>
  useMutation<ApproveProtocol, ApproveProtocolVariables>(APPROVE_PROTOCOL_MUTATION, {
    ...options,
    update(cache, { data }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });

      if (cachedActionItems) {
        cache.writeQuery<UseActionItemsQuery>({
          query: MY_ACTION_ITEMS_QUERY,
          data: {
            __typename: 'Query',
            actionItems: cachedActionItems.actionItems.filter(
              (actionItem) =>
                actionItem.id !== (data?.approveProtocol.__typename === 'Protocol' ? data.approveProtocol.id : null)
            ),
          },
        });
      }

      const cachedUserRoles = cache.readQuery<allUserRoles>({
        query: GET_ALL_USER_ROLES,
      });

      const organizationIds: string[] =
        cachedUserRoles && cachedUserRoles.allUserRoles
          ? cachedUserRoles.allUserRoles
              .filter(
                (role) => role.role === Role.PRIMARY_DESIGNATED_PERSON || role.role === Role.SECONDARY_DESIGNATED_PERSON
              )
              .map((role) => role.organizationId)
          : [];

      if (
        data?.approveProtocol.__typename === 'Protocol' &&
        cachedActionItems?.actionItems.filter((item) => item.__typename === 'PendingInvitationActionItem').length === 0
      ) {
        cache.writeQuery<isDPCompletedEducationItems>({
          query: IS_DP_COMPLETED_EDUCATION_ITEMS,
          data: {
            isDPCompletedEducationItems: true,
          },
          variables: {
            organizationIds,
          },
        });
      }
    },
  });
