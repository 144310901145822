import React from 'react';
import { LoggedInParamList } from './LoggedIn.interface';
import { useLoggedInRoutes } from './useLoggedInRoutes';
import { useInitialNavigation } from '../shared/InitialNavigationRoutes';
import { createStackNavigator } from '@react-navigation/stack';

const LoggedIn = createStackNavigator<LoggedInParamList>();

export const LoggedInNavigator: React.FC = () => {
  const {
    initialLoginRoute: { name },
  } = useInitialNavigation();
  const routes = useLoggedInRoutes();

  return (
    <LoggedIn.Navigator initialRouteName={name} screenOptions={{ headerShown: false }}>
      <LoggedIn.Group
        screenOptions={{
          headerShown: false,
        }}
      >
        {routes.map(({ component, name, initialParams }) => (
          <LoggedIn.Screen key={name} name={name} component={component} initialParams={initialParams} />
        ))}
      </LoggedIn.Group>
    </LoggedIn.Navigator>
  );
};
