import { Formik, FormikErrors, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppDateTimePicker, AppModal, Input } from '../../../../uiComponents';
import { useCreateSeason } from './hooks/useCreateSeason';

export interface CreateSeasonFormData {
  name: string;
  startDate: string;
  endDate?: string;
}

export interface CreateSeasonModalProps {
  visible?: boolean;
  organizationId: string;
  onDismiss: () => void;
}

export const CreateSeasonModal: React.FC<CreateSeasonModalProps> = ({ organizationId, visible, onDismiss }) => {
  const { translate } = useTranslations();
  const [createSeason] = useCreateSeason(organizationId);

  const handleCreateSeason = (
    input: CreateSeasonFormData,
    { setSubmitting, resetForm }: FormikHelpers<CreateSeasonFormData>
  ) => {
    createSeason({
      variables: {
        name: input.name,
        startDate: input.startDate,
        endDate: input.endDate,
        organizationId,
      },
    }).then(() => {
      setSubmitting(false);
      resetForm();
      onDismiss();
    });
  };

  const validate = (input: CreateSeasonFormData) => {
    const errors: FormikErrors<CreateSeasonFormData> = {};
    if (!input.name) {
      errors.name = translate.error.message.cantBeEmpty;
    }
    if (!input.startDate) {
      errors.startDate = translate.error.message.cantBeEmpty;
    }
    return errors;
  };

  return (
    <AppModal mode="dialog" title={translate.seasons.createSeason} visible={visible} onDismiss={onDismiss}>
      <Formik<CreateSeasonFormData>
        initialValues={{ name: '', startDate: new Date().toISOString(), endDate: undefined }}
        onSubmit={handleCreateSeason}
        validate={validate}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          isValid,
          submitForm,
          isSubmitting,
        }) => (
          <>
            <Input
              label={translate.label.name}
              value={values.name}
              touched={touched?.name}
              error={errors?.name}
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
            />
            <AppDateTimePicker
              label={translate.seasons.startDate}
              touched={touched?.startDate}
              error={errors?.startDate}
              value={values.startDate}
              onChange={(date: string) => setFieldValue('startDate', date)}
              onBlur={() => setFieldTouched('startDate', true)}
            />
            <AppDateTimePicker
              label={translate.seasons.endDate}
              touched={touched?.endDate}
              error={errors?.endDate}
              value={values.endDate || ''}
              onChange={(date: string) => setFieldValue('endDate', date)}
              onBlur={() => setFieldTouched('endDate', true)}
            />
            <AppButton
              loading={isSubmitting}
              disabled={!isValid || !touched?.name}
              onPress={submitForm}
              style={{ marginLeft: 'auto' }}
            >
              {translate.label.create}
            </AppButton>
          </>
        )}
      </Formik>
    </AppModal>
  );
};
