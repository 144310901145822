import React from 'react';
import { LoggedInUserContext } from './LoggedInUserContext';
import type { CurrentUser as CurrentUserType } from '../../../fragments/__generated__/CurrentUser';
import { LoggedOutChrome } from '../../Login/LoggedOutChrome';

export interface LoggedInUserProps {
  user?: CurrentUserType | undefined | null;
  children: React.ReactNode;
}

export const LoggedInUser: React.FC<LoggedInUserProps> = ({ user, children }) => {
  if (!user) {
    return <LoggedOutChrome mode="dashboard" />;
  }

  return <LoggedInUserContext.Provider value={user}>{children}</LoggedInUserContext.Provider>;
};
