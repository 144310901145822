export const getMediaType = (_mediaType: 'all' | 'images' | 'videos' | 'image/pdf' | 'csv') => {
  switch (_mediaType) {
    case 'all':
    default:
      return '*/*';
    case 'image/pdf':
      return ['image/*', 'application/pdf'];
    case 'images':
      return 'image/*';
    case 'videos':
      return 'video/*';
    case 'csv':
      return 'text/csv';
  }
};
