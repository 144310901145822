import React from 'react';
import { Content } from './Content';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useNavigation } from '@react-navigation/native';
import { ManageOrganizationNavType } from './ManageOrganization.interface';
import { useAdminContext } from '../../hooks/useAdminContext';

export const Index = () => {
  const { translate } = useTranslations();
  const { organizationId } = useAdminContext();
  const navigation = useNavigation<ManageOrganizationNavType<'LoggedIn_Admin_ManageOrganization_Index'>>();
  const handleBack = () => navigation.navigate('LoggedIn_Admin', { organizationId, screen: 'LoggedIn_Admin_Index' });

  return (
    <Chrome
      mode="title-card"
      titleText={translate.admin.label.manageOrganization}
      content={<Content />}
      navBarProps={{ showHamburger: false, onBack: handleBack }}
    />
  );
};
