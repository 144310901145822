import { useAppUiConfigContext } from '../AppUiConfigContext';

export const useStates = <States extends string, Countries extends string>(countryCode?: Countries) => {
  const { states } = useAppUiConfigContext<Countries, States>();

  const _states = states || [];

  if (countryCode) {
    return _states.filter((state) => state.countryIsoCode === countryCode);
  }

  return _states;
};
