import React from 'react';
import { Linking } from 'react-native';
import { CaseItemRtaProtocolStageDocumentReviewApproved } from '../../../../../fragments/__generated__/CaseItemRtaProtocolStageDocumentReviewApproved';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { RtaProtocolType } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useColors } from '../../../../../shared/Ui/hooks';
import { AppText, AppButton } from '../../../../../uiComponents';

export interface CaseItemRtaProtocolStageDocumentReviewApprovedProps {
  item: CaseItemRtaProtocolStageDocumentReviewApproved;
}

export const CaseItemRtaProtocolStageDocumentReviewApprovedView: React.FC<CaseItemRtaProtocolStageDocumentReviewApprovedProps> =
  ({ item }) => {
    const colors = useColors();
    const { translate, translateWithVars } = useTranslations();

    const handleDocumentPress = () => {
      if (item.document.downloadUri) {
        Linking.openURL(item.document.downloadUri);
      }
    };

    const protocolTerm =
      item.protocolType === RtaProtocolType.LEARN
        ? translate.recovery.index.returnToLearn
        : translate.recovery.index.returnToSport;

    const text = translateWithVars(translate.caseLog.caseItemRtaProtocolStageDocumentReviewApproved.message, {
      actor: `${item.actor.firstName} ${item.actor.lastName}`,
      stage: item.stageName.toLowerCase(),
      protocol: protocolTerm,
    });
    const action = (
      <AppButton icon="file" onPress={handleDocumentPress} type="default">
        {translate.recovery.stageDocumentReview.document}
      </AppButton>
    );
    return (
      <BaseCaseLogItemView item={item} action={action}>
        <AppText>{text}</AppText>
      </BaseCaseLogItemView>
    );
  };
