import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Constants from 'expo-constants';
import { SVG, Icon, AppButton, AppText } from '../../../uiComponents';
import { useColors, ColorKeys } from '../../Ui';
import { useTranslations } from '../../../i18n/useTranslations';
import CAPTWithWhiteText from '../../../assets/svg/CAPTWithWhiteText.svg';
import { useActionItemsQuery } from '../../../screens/ActionItems/hooks/useActionItems';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LoggedInNavType } from '../../../screens/LoggedIn.interface';
import { useCurrentUser } from '../../CurrentUser/useCurrentUser';
import { Role } from '../../../types/globalTypes';
import { HamburgerMenu } from './HamburgerMenu';
import '@expo/match-media';
import { Navbar } from './Index';
import { useNavbarNavigation } from './useNavbarNavigation';
import { NavBarProps } from '../Chrome';
import { useHamburgerMenuProps } from './useHamburgerMenuProps';
import * as Localization from 'expo-localization';

export interface BackgroundProps {
  ratio?: number;
  primaryColor?: ColorKeys;
  secondaryColor?: ColorKeys;
  includeSecondary?: boolean;
}

export const NavBarForMobile: React.FC<NavBarProps> = (props) => {
  const { content, title, titleText, navbar, hideNavigation, footer, subNavigation, hideNavItems } = props;

  const colors = useColors();
  const { translate } = useTranslations();
  const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false);
  const { changeUserLanguage, handleNavigation, languageMenuItems, navigationMenuItems } = useHamburgerMenuProps();

  const { currentUser } = useCurrentUser();
  const userLanguage =
    currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.language : Localization.locale.split('-')[0];

  const userRoles = currentUser?.__typename === 'CurrentUser' ? currentUser.roles.map((role) => role.role) : [];

  const route = useRoute();

  const { data: actionItemsData } = useActionItemsQuery({
    hideLoading: route.name !== 'LoggedIn_Home',
    skip: route.name === 'Welcome' || route.name === 'Login' || route.name === 'ResetPassword',
  });
  const actionItems = actionItemsData?.actionItems || [];
  const hasActionItems = actionItems.length > 0;

  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Home'>>();

  const styles = StyleSheet.create({
    keyboardAvoidingView: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
    },
    contentContainer: {
      width: '100%',
      paddingHorizontal: 10,
      alignSelf: 'center',
      paddingTop: 10,
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 0,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 5,
      marginBottom: 15,
      width: '100%',
    },
    back: {
      marginRight: 18,
      paddingRight: 15,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      backgroundColor: colors.white,
    },
    footer: {
      width: '100%',
    },
  });

  const Background: React.FC<BackgroundProps> = ({ primaryColor = 'green' }) => {
    const getBackgroundHeight = (os) => {
      switch (os) {
        case 'web':
          return 50;
        case 'android':
        case 'ios':
          return 50 + Constants.statusBarHeight;
      }
    };

    const getTopPadding = (os) => {
      switch (os) {
        case 'web':
          return 0;
        case 'android':
        case 'ios':
          return Constants.statusBarHeight;
      }
    };

    const styles = StyleSheet.create({
      backgroundContainer: {
        height: getBackgroundHeight(Platform.OS),
        backgroundColor: colors[primaryColor],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: getTopPadding(Platform.OS),
        paddingRight: 15,
        zIndex: 100,
      },
      backgroundSubContainer: {},
      navbarMenuItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      iconContainer: {
        marginRight: 15,
      },
      navBarOther: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      recognizeButtonContainer: {
        marginRight: 10,
      },
      actionItemsButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        borderRadius: 18,
        height: 36,
        borderColor: colors.red,
        borderWidth: 3,
        marginRight: 15,
      },
      navItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      languageMenu: {
        marginHorizontal: 10,
      },
    });

    const { navigateToLink } = useNavbarNavigation(hideNavItems);

    const handleToggleHamburgerMenu = () => {
      setShowLanguageMenu(false);
      setShowHamburgerMenu((showHamburgerMenu) => !showHamburgerMenu);
    };

    const handleToggleLanguageMenu = () => {
      setShowHamburgerMenu(false);
      setShowLanguageMenu((showLanguageMenu) => !showLanguageMenu);
    };

    const handleHomePressed = () => navigation.navigate('Welcome');

    return !hideNavigation ? (
      <View style={styles.backgroundContainer}>
        <StatusBar style="light" backgroundColor={colors.navyDark} />
        <View style={styles.navbarMenuItems}>
          <TouchableOpacity onPress={() => navigateToLink(translate.navigation.home)}>
            <CAPTWithWhiteText width={100} height={30} />
          </TouchableOpacity>
        </View>
        <View style={styles.navBarOther}>
          {userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON) ||
          userRoles.includes(Role.SECONDARY_DESIGNATED_PERSON) ? (
            <View style={styles.recognizeButtonContainer}>
              {!hideNavItems ? (
                <AppButton
                  size="extraSmall"
                  onPress={() => navigateToLink(translate.admin.manageProtocol.index.sectionRemoval)}
                >
                  {translate.home.tile.recognizeMobile}
                </AppButton>
              ) : null}
            </View>
          ) : null}

          <View style={styles.languageMenu}>
            <TouchableOpacity onPress={handleToggleLanguageMenu}>
              <AppText color="white" font="labelDefault">
                {userLanguage.toUpperCase()}
              </AppText>
            </TouchableOpacity>
            {showLanguageMenu ? (
              <HamburgerMenu
                setShowHamburgerMenu={setShowLanguageMenu}
                type="languageMenuItems"
                handlePress={changeUserLanguage}
                menuItems={languageMenuItems}
              />
            ) : null}
          </View>

          {hasActionItems ? (
            <TouchableOpacity onPress={() => navigateToLink(translate.home.tile.actionItems)}>
              <View style={styles.iconContainer}>
                <Icon name="alert-circle" color={colors.red} size={36} />
              </View>
            </TouchableOpacity>
          ) : null}

          <View>
            <TouchableOpacity onPress={handleToggleHamburgerMenu}>
              <SVG image={'hamburger'} width={21} height={36} />
            </TouchableOpacity>
            {showHamburgerMenu ? (
              <HamburgerMenu
                setShowHamburgerMenu={setShowHamburgerMenu}
                type="hamburgerMenuItems"
                handlePress={handleNavigation}
                menuItems={navigationMenuItems}
              />
            ) : null}
          </View>
        </View>
      </View>
    ) : (
      <View style={styles.backgroundContainer}>
        <StatusBar style="light" backgroundColor={colors.navyDark} />
        <CAPTWithWhiteText width={100} height={30} />
        <TouchableOpacity style={{ marginLeft: 'auto' }} onPress={handleHomePressed}>
          <Icon name={'arrow-left'} size={24} color={colors.white} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <Navbar
      background={Background}
      subNavigation={subNavigation}
      content={content}
      styles={styles}
      footer={footer}
      bottomTabs={!hideNavigation}
      navbar={navbar}
      title={title}
      titleText={titleText}
      showHamburgerMenu={showHamburgerMenu}
      setShowHamburgerMenu={setShowHamburgerMenu}
      hideNavItems={hideNavItems}
      showLanguageMenu={showLanguageMenu}
      setShowLanguageMenu={setShowLanguageMenu}
    />
  );
};
