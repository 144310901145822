import { gql, MutationHookOptions, useLazyQuery } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import {
  NudgeNotReviewedEducation,
  NudgeNotReviewedEducationVariables,
} from './__generated__/NudgeNotReviewedEducation';

export const NUDGE_USER_ROLE_INVITATION_QUERY = gql`
  query NudgeNotReviewedEducation($input: NudgeNotReviewedEducationInput!) {
    nudgeNotReviewedEducation(input: $input) {
      ... on NudgeEducationParticipants {
        id
        total
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useNudgeNotReviewedEducation = (
  options?: MutationHookOptions<NudgeNotReviewedEducation, NudgeNotReviewedEducationVariables>
) =>
  useLazyQuery<NudgeNotReviewedEducation, NudgeNotReviewedEducationVariables>(
    NUDGE_USER_ROLE_INVITATION_QUERY,
    options
  );
