import { Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Fields } from './Fields';
import { SubmitButton } from './SubmitButton';
import { useCreateGroup } from './hooks/useCreateGroup';
import { CreateGroupFormData } from './CreateGroupModal.interface';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppButton, AppModal, AppText } from '../../../../../uiComponents';
import { useReactivateGroup } from './hooks/useReactivateGroup';

export interface CreateGroupModalProps {
  visible?: boolean;
  organizationId: string;
  onDismiss: () => void;
}

interface ExistingGroupModal {
  name: string;
  visible: boolean;
}

export const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ organizationId, visible, onDismiss }) => {
  const [showReactivateModal, setShowReactivateModal] = useState<ExistingGroupModal>({
    name: '',
    visible: false,
  });
  const [showExistingActiveGroupModal, setShowExistingActiveGroupModal] = useState<ExistingGroupModal>({
    name: '',
    visible: false,
  });

  const { translate, translateWithVars } = useTranslations();
  const [reactivateGroup] = useReactivateGroup();
  const [createGroup] = useCreateGroup(organizationId, {
    onCompleted: (data) => {
      if (data.createGroup.__typename === 'ExistingGroup') {
        if (!data.createGroup.active) {
          setShowReactivateModal({
            name: data.createGroup.name,
            visible: true,
          });
        } else {
          setShowExistingActiveGroupModal({
            name: data.createGroup.name,
            visible: true,
          });
        }
      }
    },
  });

  const handleCreateGroup = (
    input: CreateGroupFormData,
    { setSubmitting, resetForm }: FormikHelpers<CreateGroupFormData>
  ) => {
    createGroup({
      variables: {
        name: input.name,
        organizationId,
      },
    }).then(() => {
      setSubmitting(false);
      resetForm();
      if (onDismiss) {
        onDismiss();
      }
    });
  };

  const validate = (input: CreateGroupFormData) => {
    const errors: FormikErrors<CreateGroupFormData> = {};
    if (!input.name) {
      errors.name = translate.error.message.authFieldsRequired;
    }
    return errors;
  };

  return (
    <>
      <AppModal
        mode="dialog"
        title={translate.admin.manageSeasons.seasonDetails.createGroupModal.groupAlreadyExistsTitle}
        buttonOrientation="horizontal"
        visible={showReactivateModal.visible}
        onDismiss={() => setShowReactivateModal({ name: '', visible: false })}
        buttons={{
          cancel: (
            <AppButton type="outline" onPress={() => setShowReactivateModal({ name: '', visible: false })}>
              {translate.label.cancel}
            </AppButton>
          ),
          reactivate: (
            <AppButton
              onPress={() => reactivateGroup({ variables: { organizationId, name: showReactivateModal.name } })}
            >
              {translate.label.reactivate}
            </AppButton>
          ),
        }}
      >
        <AppText>
          {translateWithVars(
            translate.admin.manageSeasons.seasonDetails.createGroupModal.inactiveGroupAlreadyExistsContent,
            {
              group: showReactivateModal.name,
            }
          )}
        </AppText>
      </AppModal>
      <AppModal
        mode="dialog"
        title={translate.admin.manageSeasons.seasonDetails.createGroupModal.groupAlreadyExistsTitle}
        visible={showExistingActiveGroupModal.visible}
        onDismiss={() => setShowExistingActiveGroupModal({ name: '', visible: false })}
        buttons={{
          ok: (
            <AppButton onPress={() => setShowExistingActiveGroupModal({ name: '', visible: false })}>
              {translate.admin.manageOrganization.ok}
            </AppButton>
          ),
        }}
      >
        <AppText>
          {translateWithVars(
            translate.admin.manageSeasons.seasonDetails.createGroupModal.activeGroupAlreadyExistsContent,
            { group: showExistingActiveGroupModal.name }
          )}
        </AppText>
      </AppModal>
      <AppModal
        mode="dialog"
        title={translate.admin.manageSeasons.seasonDetails.createGroupModal.title}
        visible={visible}
        onDismiss={onDismiss}
      >
        <Formik<CreateGroupFormData> initialValues={{ name: '' }} onSubmit={handleCreateGroup} validate={validate}>
          <>
            <Fields />
            <SubmitButton />
          </>
        </Formik>
      </AppModal>
    </>
  );
};
