import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, TextInput, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  ActivityIndicator,
  AppScrollView,
  AppText,
  KPI,
  Select,
  UserList,
  PageNavButtons,
  Input,
  AppLink,
} from '../../../uiComponents';
import { TrainingChrome } from '../TrainingChrome';
import { TrainingNavType } from '../Training.interface';
import { useTranslations } from '../../../i18n/useTranslations';
import { useGetTrainingReport } from '../hooks/useGetTrainingReport';
import { useCurrentUser } from '../../../shared/CurrentUser/useCurrentUser';
import { CurrentUser_roles } from '../../../fragments/__generated__/CurrentUser';
import { useIsMobile } from '../../../hooks/ui/useResponsive';
import { useGetDPTrainingCompletion } from '../hooks/useGetDPTrainingCompletion';
import { Name } from '../../TeamRoster/Columns';
import { Roles } from '../../Admin/screens/ManageUsers/Columns';
import { Role } from '../../../types/globalTypes';
import { SortValue, useSearchAndSort } from '../../Admin/screens/ManageGroups/GroupDetails/hooks/useSearchAndSort';

export const Report: React.FC = (props) => {
  const [organizations, setOrganizations] = useState<CurrentUser_roles[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');

  // TODO: set the training program once there is more than one program available
  const [trainingProgram, setTrainingProgram] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<'completed' | 'notCompleted' | 'all'>('all');

  const navigation = useNavigation<TrainingNavType<'LoggedIn_Training_Report'>>();
  const { currentUser } = useCurrentUser();

  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  useEffect(() => {
    const allOrganizations = currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.roles : [];
    const organizationsWherePDP = allOrganizations.filter(
      (organization) => organization.role === Role.PRIMARY_DESIGNATED_PERSON
    );
    setOrganizations(organizationsWherePDP);
    if (organizationsWherePDP.length > 0) setSelectedOrganization(organizationsWherePDP[0].organizationId);
  }, [currentUser]);

  const handleGoBack = () => {
    navigation.navigate('LoggedIn_Training', {
      screen: 'LoggedIn_Training_Index',
    });
  };

  const inputRef = useRef<TextInput>(null);

  const {
    handleSortChange,
    handleSearchStr,
    selectedSortType,
    searchStr,
    sortCategories,
    setIsSearchInputFocused,
    roleFilter,
    roleFilterOptions,
    handleRoleFilterChange,
  } = useSearchAndSort(inputRef);

  let useGetTrainingReportVariables: {
    organizationId: string;
    paginationArgs: {
      skip: number;
      take: number;
      orderBy: string;
      direction: string;
    };
    status?: boolean;
    searchStr?: string;
    role?: string;
  } = {
    organizationId: selectedOrganization,
    paginationArgs: {
      skip: currentPage,
      take: 25,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
  };

  if (selectedStatus !== 'all') {
    useGetTrainingReportVariables = {
      ...useGetTrainingReportVariables,
      status: selectedStatus === 'completed' ? true : false,
    };
  }

  if (searchStr) {
    useGetTrainingReportVariables = {
      ...useGetTrainingReportVariables,
      searchStr,
    };
  }

  if (roleFilter !== 'All') {
    useGetTrainingReportVariables = {
      ...useGetTrainingReportVariables,
      role: roleFilter,
    };
  }

  const { data: trainingReport } = useGetTrainingReport({
    skip: !selectedOrganization,
    variables: useGetTrainingReportVariables,
  });

  const { data: DPTrainingCompletion } = useGetDPTrainingCompletion(selectedOrganization, {
    skip: !selectedOrganization,
  });

  const handleOrganizationChange = (_organizationId: string) => {
    setSelectedOrganization(_organizationId);
  };

  const handleTrainingProgramChange = (_trainingProgramId: string) => {
    setTrainingProgram(_trainingProgramId);
  };

  const organizationItems = organizations.map(({ organizationId, organizationName }) => ({
    key: organizationId,
    value: organizationId,
    label: organizationName,
  }));

  // TODO: replace with real data once there is a list of organizations available
  const MOCK_TRAINING_PROGRAM_DATA = [
    {
      id: 'makingHeadway',
      name: 'NCCP Making Head Way',
    },
  ];

  const trainingProgramItems = MOCK_TRAINING_PROGRAM_DATA.map(({ id, name }) => ({ key: id, value: id, label: name }));

  const calculateTrainingPercentage = () => {
    if (DPTrainingCompletion && DPTrainingCompletion.dpTrainingCompletion.__typename === 'DPTrainingCompletion') {
      if (DPTrainingCompletion.dpTrainingCompletion.totalNumberOfDPs > 0) {
        return `${Math.round(
          (100 * DPTrainingCompletion.dpTrainingCompletion.numberOfDPsCompleted) /
            DPTrainingCompletion.dpTrainingCompletion.totalNumberOfDPs
        )}%`;
      } else {
        return '0%';
      }
    } else {
      return 'Error';
    }
  };

  const trainingCompletionStatuses = [
    { label: translate.searchAndSort.all, key: 'all', value: 'all' },
    { label: translate.training.label.completed, key: 'completed', value: 'completed' },
    { label: translate.training.label.notCompletedForWeb, key: 'not completed', value: 'not completed' },
  ];

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 10,
    },
    selectorsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      justifyContent: !isMobile ? 'flex-start' : 'center',
    },
    selectorContainer: {
      marginRight: !isMobile ? 40 : 0,
      marginTop: isMobile ? 20 : 0,
      width: isMobile ? '100%' : 400, // change width to 50% once we have a selector for the training program instead of just Making Head Way
    },
    selector: {
      width: isMobile ? '100%' : 400,
    },
    disclaimerContainer: {
      marginTop: 40,
      marginBottom: !isMobile ? 20 : 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    disclaimerText: {
      flex: 1,
    },
    kpiValue: {
      fontSize: 60,
      paddingTop: !isMobile ? 20 : 50,
      paddingBottom: !isMobile ? 20 : 0,
    },
    trainingCompletedContainer: {
      marginBottom: 40,
    },
    reportUsersTitle: {
      marginBottom: 15,
    },
    report: {
      width: !isMobile ? 600 : '100%',
      marginVertical: 10,
      paddingVertical: 8,
    },
    reportInner: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: !isMobile ? 550 : '90%',
      alignSelf: 'center',
    },
    reportName: {
      marginRight: 50,
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
      marginBottom: 10,
    },
    sortContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
      width: isMobile ? '100%' : '60%',
    },
    sortSelect: {
      width: '50%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
    },
    searchContainer: {
      width: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
      top: 10,
    },
    pageNavButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });

  const organizationSelector = (
    <Select<string> value={selectedOrganization} onChange={handleOrganizationChange} items={organizationItems} />
  );

  const trainingProgramSelector = (
    <Select<string> value={trainingProgram} onChange={handleTrainingProgramChange} items={trainingProgramItems} />
  );

  const isTrainingReportAvailable =
    trainingReport && trainingReport.trainingReport.__typename === 'GetTrainingReportResult';

  const isTrainingReportNotEmpty =
    trainingReport &&
    trainingReport.trainingReport.__typename === 'GetTrainingReportResult' &&
    trainingReport.trainingReport.TrainingReportResult.length > 0;

  const trainingReportResult =
    trainingReport && trainingReport.trainingReport.__typename === 'GetTrainingReportResult'
      ? trainingReport.trainingReport.TrainingReportResult
      : [];

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const noSearchOrFiltersApplied = selectedStatus === 'all' && roleFilter === 'All' && !searchStr;

  return (
    <TrainingChrome titleText={translate.training.trainingReport} onBack={handleGoBack} showHamburger={false}>
      <AppScrollView showsVerticalScrollIndicator={false} style={styles.container}>
        <View style={styles.selectorsContainer}>
          {organizations.length > 1 ? (
            <View style={styles.selectorContainer}>
              <AppText>{translate.profile.term.organization}</AppText>
              <View style={[styles.selector, { paddingRight: isMobile ? 15 : 0 }]}>{organizationSelector}</View>
            </View>
          ) : null}

          {/* <View style={styles.selectorContainer}>
            <AppText>Training Program</AppText>
            <View style={styles.selector}>{trainingProgramSelector}</View>
          </View> */}
          <AppText font="header2" style={{ marginBottom: !isMobile ? 10 : 0 }}>
            {translate.training.makingHeadWay.trainingProgram}
          </AppText>
        </View>
        <View style={styles.trainingCompletedContainer}>
          {trainingReport ? (
            <KPI value={calculateTrainingPercentage()} label={translate.training.label.designatedPersonsTrained} />
          ) : (
            <ActivityIndicator style={styles.activityIndicator} />
          )}
        </View>
        <View style={styles.searchAndSortContainer}>
          <View style={styles.sortContainer}>
            <View style={[styles.sortSelect, { paddingRight: 15 }]}>
              <AppText>{translate.subjectStatus.modalTitle}</AppText>
              <Select<string> value={selectedStatus} items={trainingCompletionStatuses} onChange={handleStatusChange} />
            </View>
            <View style={[styles.sortSelect, { paddingRight: isMobile ? 0 : 15 }]}>
              <AppText>{translate.users.userDetails.sort}</AppText>
              <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
            </View>
          </View>

          <View style={styles.sortContainer}>
            <View style={[styles.sortSelect, { paddingRight: 15 }]}>
              <AppText>{translate.users.userDetails.role}</AppText>
              <Select<string> value={roleFilter} items={roleFilterOptions} onChange={handleRoleFilterChange} />
            </View>
            <View style={styles.searchContainer}>
              <Input
                icon="search"
                ref={inputRef}
                onFocus={() => setIsSearchInputFocused(true)}
                onBlur={() => setIsSearchInputFocused(false)}
                placeholder={translate.users.userDetails.searchByName}
                type="name"
                debounce={{
                  milliseconds: 1000,
                  callback: handleSearchStr,
                  textValue: searchStr,
                }}
              />
            </View>
          </View>
        </View>
        <View style={styles.pageNavButtons}>
          <PageNavButtons
            isPrevPageButtonVisible={currentPage !== 0}
            isNextPageButtonVisible={trainingReportResult && trainingReportResult.length === 25}
            prevPageAction={goToPreviousPage}
            nextPageAction={goToNextPage}
          />
        </View>
        {isTrainingReportAvailable ? (
          <UserList
            noUsersMessage={noSearchOrFiltersApplied ? translate.training.noUsers : translate.training.noUsersFound}
            displayItems={trainingReportResult.map((user) => ({
              key: user.id,
              components: [
                <Name name={user.user} />,
                <AppText color={user.completed ? 'green' : 'red'}>
                  {user.completed ? translate.training.label.completed : translate.training.label.notCompletedForWeb}
                </AppText>,
                <Roles roles={user.roles.map((role) => Role[role])} />,
              ],
            }))}
          />
        ) : (
          <ActivityIndicator style={styles.activityIndicator} />
        )}
        <View style={styles.disclaimerContainer}>
          <View>
            <AppText style={styles.disclaimerText}>{translate.training.disclaimer.part1}</AppText>
          </View>
          <View>
            <AppLink
              style={styles.disclaimerText}
              onPress={() => Linking.openURL('https://thelocker.coach.ca/account/login?ReturnUrl=%2f')}
            >
              {translate.training.disclaimer.part2}
            </AppLink>
          </View>
          <View>
            <AppText style={styles.disclaimerText}>{translate.training.disclaimer.part3}</AppText>
          </View>
        </View>
      </AppScrollView>
    </TrainingChrome>
  );
};
