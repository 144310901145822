import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../../fragments/ApiErrorFragment';
import { GroupFragment } from '../../fragments/GroupFragment';
import { GET_GROUPS_BY_ORGANIZATION_ID_QUERY } from '../../hooks/useGetGroupsByOrganizationId';
import { GetGroupsByOrganizationId } from '../../hooks/__generated__/GetGroupsByOrganizationId';
import { ReactivateGroup, ReactivateGroupVariables } from './__generated__/ReactivateGroup';

export const REACTIVATE_GROUP = gql`
  mutation ReactivateGroup($name: String!, $organizationId: ID!) {
    reactivateGroup(name: $name, organizationId: $organizationId) {
      ... on Group {
        ...Group
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${GroupFragment}
  ${ApiErrorFragment}
`;

export const useReactivateGroup = (options?: MutationHookOptions<ReactivateGroup, ReactivateGroupVariables>) =>
  useMutation<ReactivateGroup, ReactivateGroupVariables>(REACTIVATE_GROUP, {
    ...options,
    update(cache, { data }, { variables }) {
      if (data && data.reactivateGroup.__typename === 'Group' && variables) {
        const groups = cache.readQuery<GetGroupsByOrganizationId>({
          query: GET_GROUPS_BY_ORGANIZATION_ID_QUERY,
          variables: {
            organizationId: variables.organizationId,
          },
        });

        if (groups && data?.reactivateGroup && data.reactivateGroup.__typename === 'Group') {
          cache.writeQuery<GetGroupsByOrganizationId>({
            query: GET_GROUPS_BY_ORGANIZATION_ID_QUERY,
            data: {
              groups: [...groups.groups, data?.reactivateGroup],
            },
            variables: {
              organizationId: variables.organizationId,
            },
          });
        }
      }
    },
  });
