import React from 'react';
import { Linking } from 'react-native';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseItemMedicalAssessmentReviewApproved } from '../../../../../fragments/__generated__/CaseItemMedicalAssessmentReviewApproved';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useColors } from '../../../../../shared/Ui/hooks';
import { AppText, AppButton } from '../../../../../uiComponents';

export interface CaseItemMedicalAssessmentReviewApprovedProps {
  item: CaseItemMedicalAssessmentReviewApproved;
}

export const CaseItemMedicalAssessmentReviewApprovedView: React.FC<CaseItemMedicalAssessmentReviewApprovedProps> = ({
  item,
}) => {
  const colors = useColors();
  const { translate, translateWithVars } = useTranslations();

  const handleDocumentPress = () => {
    if (item.document.downloadUri) Linking.openURL(item.document.downloadUri);
  };

  const text = translateWithVars(translate.caseLog.caseItemMedicalAssessmentReviewApproved.message, {
    actor: `${item.actor.firstName} ${item.actor.lastName}`,
  });
  const action = (
    <AppButton icon="file" onPress={handleDocumentPress} type="default">
      {translate.recovery.stageDocumentReview.document}
    </AppButton>
  );
  return (
    <BaseCaseLogItemView item={item} action={action}>
      <AppText>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
