import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { RtaProtocolType, StageState } from '../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import {
  AppButton,
  AppMarkdown,
  AppScrollView,
  AppText,
  ProtocolAttribution,
  Spacer,
} from '../../../../../../uiComponents';
import { useSubmitStageSignoff } from '../../hooks/useSubmitStageSignoff';
import { RecoveryPlanNavType } from '../../RecoveryPlan.interface';
import { ProfileChrome } from '../../../../ProfileChrome/ProfileChrome';
import { RecoveryPlanStageSignOff } from '../../fragments/__generated__/RecoveryPlanStageSignOff';
import { useStepBackward } from '../../hooks/useStepBackward';
import { LockedAction } from './LockedAction';
import { FastTrackWarningModal } from './FastTrackWarningModal';
import { useTimeGating } from '../../hooks/useTimeGating';
import { useIsIncidentReportIncomplete } from '../../hooks/useIsIncidentReportIncomplete';
import { IncidentReportNoticeModal } from './IncidentReportNoticeModal';
import { useIsMobile } from '../../../../../../hooks/ui/useResponsive';

export interface StageSignoffProps {
  stageSignoff: RecoveryPlanStageSignOff;
  caseCreatedDate: string;
}

export const StageSignoff: React.FC<StageSignoffProps> = ({ stageSignoff, caseCreatedDate }) => {
  const { getTimeGateEnd, startTimeGateEndCountdown, TimeGateMessage, timeGateMessageColour } = useTimeGating(
    stageSignoff.stepStartTime,
    stageSignoff.timeGateHours
  );

  const { data } = useIsIncidentReportIncomplete(stageSignoff.caseId, {
    skip: !(stageSignoff.isLastStage && stageSignoff.protocolType === RtaProtocolType.SPORT),
  });

  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Stage'>>();
  const [submitSignoff] = useSubmitStageSignoff();
  const [stepBackward] = useStepBackward();

  const [isSteppingForward, setIsSteppingForward] = useState(false);
  const [isSteppingBackward, setIsSteppingBackward] = useState(false);

  const [isFastTrackModalVisible, setIsFastTrackModalVisible] = useState<boolean>(false);
  const [isIncidentReportNoticeModalVisible, setIsIncidentReportNoticeModalVisible] = useState<boolean>(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    const timeGateEnd = getTimeGateEnd();
    startTimeGateEndCountdown(timeGateEnd);
  }, []);

  const handleStepBackButtonPress = () => {
    setIsSteppingBackward(true);
    stepBackward({
      variables: {
        input: {
          caseId: stageSignoff.caseId,
          stageId: stageSignoff.id,
        },
      },
    }).then((res) => {
      setIsSteppingBackward(false);
      setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
    });
  };

  const handleStepForwardButtonPress = (isFastTracked: boolean) => {
    if (data && data.isIncidentReportIncomplete) {
      setIsIncidentReportNoticeModalVisible(true);
    } else {
      setIsSteppingForward(true);
      submitSignoff({
        variables: {
          input: {
            caseId: stageSignoff.caseId,
            stageId: stageSignoff.id,
            isFastTracked,
          },
        },
      }).then(() => {
        setIsSteppingForward(false);
        setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
        setIsFastTrackModalVisible(false);
      });
    }
  };

  const canStepForward = stageSignoff.iCanSignoff && stageSignoff.isCurrentStage;
  const canStepBackward = stageSignoff.order !== 0 && stageSignoff.iCanSignoff && stageSignoff.isCurrentStage;
  const isLocked = !canStepForward && !canStepBackward && stageSignoff.isCurrentStage;

  const styles = StyleSheet.create({
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 100,
      flexWrap: 'wrap',
      marginBottom: 15,
    },
  });

  const content = (
    <AppScrollView>
      <FastTrackWarningModal
        isModalVisible={isFastTrackModalVisible}
        setIsModalVisible={setIsFastTrackModalVisible}
        timeGateHours={stageSignoff.timeGateHours}
        stepForward={() => handleStepForwardButtonPress(true)}
      />
      <IncidentReportNoticeModal
        isModalVisible={isIncidentReportNoticeModalVisible}
        setIsModalVisible={setIsIncidentReportNoticeModalVisible}
      />
      <Spacer
        items={{
          titleArea: (
            <Spacer
              items={{
                title: <AppText font="header1">{stageSignoff.name}</AppText>,
                step: (
                  <AppText font="labelDefault" color="grayDark">
                    {translateWithVars(translate.recovery.stageSignoff.subtitle, {
                      organization: stageSignoff.organizationName,
                      protocol:
                        stageSignoff.protocolType === RtaProtocolType.LEARN
                          ? translate.recovery.index.returnToLearn
                          : translate.recovery.index.returnToSport,
                    })}
                  </AppText>
                ),
                attribution: (
                  <ProtocolAttribution
                    protocolUrl={stageSignoff.protocolUrl}
                    protocolName={stageSignoff.protocolName}
                  />
                ),
              }}
            />
          ),
          description: <AppMarkdown>{stageSignoff.description}</AppMarkdown>,
        }}
      />
      {stageSignoff.timeGateHours && caseCreatedDate && stageSignoff.state === StageState.ACTIVE ? (
        <TimeGateMessage timeGateHours={stageSignoff.timeGateHours} />
      ) : null}
      <View style={styles.buttonContainer}>
        {canStepBackward ? (
          <AppButton
            disabled={isSteppingForward || isSteppingBackward}
            loading={isSteppingBackward}
            type="outline"
            onPress={handleStepBackButtonPress}
            style={{ width: 200, marginBottom: 20, marginRight: 10 }}
          >
            {translate.label.stepBack}
          </AppButton>
        ) : null}
        {canStepForward ? (
          <AppButton
            disabled={isSteppingForward || isSteppingBackward}
            loading={isSteppingForward}
            onPress={() =>
              timeGateMessageColour === 'grayDark'
                ? handleStepForwardButtonPress(false)
                : setIsFastTrackModalVisible(true)
            }
            style={{ width: 200 }}
          >
            {stageSignoff.isLastStage ? translate.label.completeVerb : translate.label.stepForward}
          </AppButton>
        ) : null}
        {isLocked ? <LockedAction text={translate.recovery.stageSignoff.notAuthorized} /> : null}
      </View>
    </AppScrollView>
  );

  return <ProfileChrome autoScroll={false} activeTab="recovery" content={content} />;
};
