import { useValidateUrl } from '../../../../../hooks/useValidation/components/useValidateUrl';
import { FormikErrors } from 'formik';
import { isEmpty, set, some } from 'lodash';
import {
  KeyValuePair,
  UpdateEducationProtocolItemConfigFormValue,
  UpdateProtocolConfigFormValue,
  UpdateRemoveProtocolStepChallengeConfigFormValue,
  UpdateRemoveProtocolStepSymptomConfigFormValue,
  UpdateReturnProtocolChildProtocolStageConfigFormValue,
  UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue,
  UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue,
} from '../ManageProtocol.interface';

const useKeyValuePairValidation = (translate) => (values: KeyValuePair[]) => {
  const errors = values.map((item) => {
    const _errors: FormikErrors<KeyValuePair> = {};
    if (isEmpty(item.key)) {
      set(_errors, 'key', translate.validation.requiredField);
    }
    if (isEmpty(item.value)) {
      set(_errors, 'value', translate.validation.requiredField);
    }

    return _errors;
  });

  if (some(errors, (item) => !isEmpty(item))) {
    return errors;
  }

  return [];
};

const ifNotEmptyThenSet = (arr: any[], errors: any, name: string) => {
  if (some(arr, (item) => !isEmpty(item))) {
    set(errors, name, arr);
  }
};

const useValidateChildProtocolStages = (translate) => {
  return (stages: UpdateReturnProtocolChildProtocolStageConfigFormValue[]) =>
    stages.map((stage) => {
      if (stage.type === 'document-submission') {
        const _errors: FormikErrors<UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue> = {};
        if (isEmpty(stage.name)) {
          set(_errors, 'name', translate.validation.requiredField);
        }
        return _errors;
      } else {
        const _errors: FormikErrors<UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue> = {};
        if (isEmpty(stage.name)) {
          set(_errors, 'name', translate.validation.requiredField);
        }

        if (stage.isTimeGateSelected && !stage.timeGateHours) {
          set(_errors, 'timeGateHours', translate.admin.manageProtocol.timeGateOption.emptyErrorMessage);
        }

        if (stage.timeGateHours && !/^[1-9]\d*$/.test(stage.timeGateHours)) {
          set(_errors, 'timeGateHours', translate.admin.manageProtocol.timeGateOption.nonIntegerErrorMessage);
        }
        return _errors;
      }
    });
};

export const useProtocolConfigValidation = (translate) => (values: UpdateProtocolConfigFormValue) => {
  const errors: FormikErrors<UpdateProtocolConfigFormValue> = {};
  const isValidUrl = useValidateUrl();
  const keyPairValuesValidation = useKeyValuePairValidation(translate);
  const validateChildProtocolStages = useValidateChildProtocolStages(translate);

  if (isEmpty(values.protocolName)) {
    set(errors, 'protocolName', translate.validation.requiredField);
  }

  if (!isEmpty(values.protocolUrl) && !isValidUrl(values.protocolUrl)) {
    set(errors, 'protocolUrl', translate.validation.validUrl);
  }

  const educationProtocolItems = values.educationProtocol.items.map((item) => {
    const _errors: FormikErrors<UpdateEducationProtocolItemConfigFormValue> = {};
    if (isEmpty(item.name)) {
      set(_errors, 'name', translate.validation.requiredField);
    }
    if (isEmpty(item.instruction)) {
      set(_errors, 'instruction', translate.validation.requiredField);
    }
    if (item.type === 'document') {
      if (!item.document.nextDocument?.id && !item.document.nextDocument?.upload) {
        set(
          _errors,
          'document',
          translate.admin.manageProtocol.updateProtocolConfigForm.education.item.missingDocument
        );
      }
    } else {
      if (!item.url) {
        set(_errors, 'url', translate.validation.requiredField);
      } else if (!isValidUrl(item.url)) {
        set(_errors, 'url', translate.validation.validUrl);
      }
    }
    return _errors;
  });

  ifNotEmptyThenSet(educationProtocolItems, errors, 'educationProtocol.items');

  const removeProtocolSteps = values.removeProtocol.steps.map((step) => {
    if (step.type === 'challenge') {
      const _errors: FormikErrors<UpdateRemoveProtocolStepChallengeConfigFormValue> = {};
      if (isEmpty(step.instruction)) {
        set(_errors, 'instruction', translate.validation.requiredField);
      }
      if (isEmpty(step.name)) {
        set(_errors, 'name', translate.validation.requiredField);
      }
      const challenges = keyPairValuesValidation(step.challenges);
      ifNotEmptyThenSet(challenges, _errors, 'challenges');
      return _errors;
    } else if (step.type === 'symptom') {
      const _errors: FormikErrors<UpdateRemoveProtocolStepSymptomConfigFormValue> = {};
      if (isEmpty(step.name)) {
        set(_errors, 'name', translate.validation.requiredField);
      }
      const symptoms = keyPairValuesValidation(step.symptoms);
      ifNotEmptyThenSet(symptoms, _errors, 'symptoms');
      return _errors;
    }
  });

  ifNotEmptyThenSet(removeProtocolSteps, errors, 'removeProtocol.steps');

  if (isEmpty(values.returnProtocol.medicalAssessment.name)) {
    set(errors, 'returnProtocol.medicalAssessment.name', translate.validation.requiredField);
  }

  const returnToLearnProtocolStages = validateChildProtocolStages(values.returnProtocol.returnToLearn.stages);
  ifNotEmptyThenSet(returnToLearnProtocolStages, errors, 'returnProtocol.returnToLearn.stages');

  const returnToSportProtocolStages = validateChildProtocolStages(values.returnProtocol.returnToSport.stages);
  ifNotEmptyThenSet(returnToSportProtocolStages, errors, 'returnProtocol.returnToSport.stages');

  return errors;
};
