import { useFormikContext } from 'formik';
import { isString } from 'lodash/fp';
import React from 'react';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Input, InputLabel, Spacer } from '../../../../../../uiComponents';
import { DocumentInput, UpdateProtocolConfigFormValue } from '../../ManageProtocol.interface';
import { DocumentSelector } from '../../DocumentSelector';
export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const { translate } = useTranslations();

  const {
    handleChange,
    handleBlur,
    errors: _errors,
    touched: _touched,
    values: _values,
    setFieldValue,
  } = useFormikContext<UpdateProtocolConfigFormValue>();
  const itemKey = 'removeProtocol.incidentReport';
  const values = _values.removeProtocol.incidentReport;
  const itemErrors = _errors?.removeProtocol?.incidentReport;
  const itemTouched = _touched.removeProtocol?.incidentReport;
  const errors = !isString(itemErrors) ? itemErrors : undefined;
  const touched = !isString(itemTouched) ? itemTouched : undefined;

  const handleDocumentChange = (_templateDocument: DocumentInput) => {
    setFieldValue(`${itemKey}.templateDocument`, _templateDocument);
  };

  const documentError = errors?.templateDocument && isString(errors.templateDocument) ? errors.templateDocument : null;

  return (
    <Spacer
      items={{
        heading: (
          <AppText font="header3" gutter={true}>
            {translate.protocol.incidentReport}
          </AppText>
        ),
        instruction: (
          <Input
            label={translate.admin.manageProtocol.instruction}
            type="text"
            multiline={true}
            numLines={5}
            numberOfLines={5}
            onChangeText={handleChange(`${itemKey}.instruction`)}
            onBlur={handleBlur(`${itemKey}.instruction`)}
            error={errors?.instruction}
            touched={touched?.instruction}
            value={values?.instruction}
          />
        ),
        templateDocument: (
          <Spacer
            items={{
              label: <InputLabel label={translate.admin.manageProtocol.templateDocument} />,
              selector: (
                <DocumentSelector
                  onChange={handleDocumentChange}
                  document={values.templateDocument}
                  error={documentError}
                />
              ),
            }}
          />
        ),
      }}
    />
  );
};
