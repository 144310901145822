import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../hooks/useQuery';
import { GetAdminOrganizations } from './__generated__/GetAdminOrganizations';

export const GET_ADMIN_ORGANIZATIONS = gql`
  query GetAdminOrganizations {
    organizations(role: ADMIN) {
      ... on Organization {
        id
        name
      }
    }
  }
`;

export const useGetAdminOrganizations = (options?: UseQueryOptions<GetAdminOrganizations, {}>) =>
  useQuery<GetAdminOrganizations, {}>(GET_ADMIN_ORGANIZATIONS, options);
