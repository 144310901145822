import React from 'react';
import { isNull } from 'lodash';
import { useRecoveryPlan } from '../../hooks/useRecoveryPlan';
import { ProfileChrome } from '../../../../ProfileChrome/ProfileChrome';
import { ResumeMessage } from './ResumeMessage';
import { NoActiveCasesMessage } from './NoActiveCasesMessage';
import { StageList } from './StageList/StageList';
import { WrongUser } from '../../WrongUser/WrongUser';
import { useRoute } from '@react-navigation/native';
import { RecoveryPlanRouteProp } from '../../RecoveryPlan.interface';
import { useCurrentUser } from '../../../../../../shared/CurrentUser/useCurrentUser';
import { ActivityIndicator } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';

export interface IndexProps {}

export const Index: React.FC<IndexProps> = (props) => {
  const data = useRecoveryPlan();
  const { translate } = useTranslations();

  const route = useRoute<RecoveryPlanRouteProp<'LoggedIn_Profile_RecoveryPlan_Stage'>>();
  const { currentUser } = useCurrentUser();

  const wrongUser = route.params && route.params.userId && route.params.userId !== currentUser?.id;
  if (wrongUser) {
    return <WrongUser />;
  }

  if (!data) {
    return (
      <ProfileChrome activeTab="recovery" content={<ActivityIndicator />} title={translate.home.tile.recoveryPlan} />
    );
  } else if (isNull(data.recoveryPlan)) {
    if (data.cases.length > 0) {
      return (
        <ProfileChrome activeTab="recovery" content={<ResumeMessage />} title={translate.home.tile.recoveryPlan} />
      );
    } else {
      return (
        <ProfileChrome
          activeTab="recovery"
          content={<NoActiveCasesMessage />}
          title={translate.home.tile.recoveryPlan}
        />
      );
    }
  }

  const { recoveryPlan } = data;

  return (
    <ProfileChrome
      activeTab="recovery"
      content={<StageList stages={recoveryPlan.stages || []} />}
      title={translate.home.tile.recoveryPlan}
    />
  );
};
