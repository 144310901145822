import { gql } from '@apollo/client';

export const PhoneNumberFragment = gql`
  fragment PhoneNumber on PhoneNumber {
    id
    name
    fullNumber
    phoneNumber
    countryDialCode
    country {
      isoCode
    }
  }
`;
