import React from 'react';
import { View, ViewProps, StyleSheet } from 'react-native';
import { useColors, useShadow } from '../../shared/Ui';

interface ShadowViewProps extends ViewProps {}

export const ShadowView: React.FC<ShadowViewProps> = ({ style, ...rest }) => {
  const shadow = useShadow();
  const colors = useColors();
  const styles = StyleSheet.create({
    root: {
      ...shadow,
      borderRadius: 6,
      backgroundColor: colors.white,
    },
  });

  return <View style={[styles.root, style]} {...rest} />;
};
