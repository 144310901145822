import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AdminNavType } from '../../Admin.interface';
import { useAdminContext } from '../../hooks/useAdminContext';
import { AppText, Spacer } from '../../../../uiComponents';
import { SectionButton } from './SectionButton';
import { ManageProtocolChrome } from './ManageProtocolChrome';
import { FeatureFlag } from '../../../../types/globalTypes';
import { CheckForFeatureFlag } from '../../../shared/CheckForFeatureFlag';

export const Index: React.FC = () => {
  const {
    translate: {
      admin: {
        manageProtocol: { index: translate },
      },
    },
    translate: rootTranslate,
  } = useTranslations();
  const { organizationId } = useAdminContext();
  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_ManageProtocol'>>();

  const handleGeneralPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_General',
      },
    });
  const handleEducationProtocolPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Education',
      },
    });
  const handleRemovalInstructionsProtocolPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Remove_Instructions',
      },
    });
  const handleRemovalRedFlagProtocolPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Remove_RedFlag',
      },
    });
  const handleRemovalStepsProtocolPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Remove_Steps',
      },
    });
  const handleIncidentReportPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Remove_IncidentReport',
      },
    });
  const handleReturnProtocolMedicalAssessmentPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Return_MedicalAssessment',
      },
    });
  const handleReturnProtocolReturnToLearnPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Return_ChildProtocol',
        params: {
          name: 'returnToLearn',
        },
      },
    });
  const handleReturnProtocolReturnToSportPress = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_ManageProtocol',
      params: {
        screen: 'LoggedIn_Admin_ManageProtocol_Return_ChildProtocol',
        params: {
          name: 'returnToSport',
        },
      },
    });

  const handleBack = () =>
    navigation.navigate('LoggedIn_Admin', {
      organizationId,
      screen: 'LoggedIn_Admin_Index',
    });

  const Content = () => {
    return (
      <Spacer
        items={{
          title: <AppText gutter={true}>{translate.instruction}</AppText>,
          general: (
            <SectionButton
              items={[
                {
                  key: 'protocolSource',
                  onPress: handleGeneralPress,
                  label: rootTranslate.admin.manageProtocol.updateProtocolConfigForm.overview.protocolName,
                },
              ]}
              label={rootTranslate.admin.manageProtocol.updateProtocolConfigForm.overview.protocolName}
            />
          ),
          educationProtocol: (
            <SectionButton
              items={[
                {
                  key: 'items',
                  onPress: handleEducationProtocolPress,
                  label: translate.preseasonEducationItems,
                },
              ]}
              label={translate.sectionEducation}
            />
          ),
          removalProtocol: (
            <SectionButton
              items={[
                {
                  key: 'instructions',
                  onPress: handleRemovalInstructionsProtocolPress,
                  label:
                    rootTranslate.admin.manageProtocol.updateProtocolConfigForm.return.medicalAssessment.instruction,
                },
                {
                  key: 'redFlags',
                  onPress: handleRemovalRedFlagProtocolPress,
                  label: rootTranslate.protocol.redFlags,
                },
                {
                  key: 'steps',
                  onPress: handleRemovalStepsProtocolPress,
                  label: rootTranslate.admin.manageProtocol.updateProtocolConfigForm.remove.steps,
                },
                {
                  key: 'incidentReport',
                  onPress: handleIncidentReportPress,
                  label: rootTranslate.protocol.incidentReport,
                },
              ]}
              label={translate.sectionRemoval}
            />
          ),
          returnProtocol: (
            <SectionButton
              items={[
                {
                  key: 'medicalAssessment',
                  onPress: handleReturnProtocolMedicalAssessmentPress,
                  label: rootTranslate.label.medicalAssessment,
                },
                {
                  key: 'returnToLearn',
                  onPress: handleReturnProtocolReturnToLearnPress,
                  label: rootTranslate.recovery.index.returnToLearn,
                },
                {
                  key: 'returnToSport',
                  onPress: handleReturnProtocolReturnToSportPress,
                  label: rootTranslate.recovery.index.returnToSport,
                },
              ]}
              label={rootTranslate.admin.manageProtocol.updateProtocolConfigForm.return.title}
            />
          ),
        }}
      />
    );
  };

  return (
    <ManageProtocolChrome onBack={handleBack}>
      <CheckForFeatureFlag featureFlag={FeatureFlag.ENABLE_MANAGE_PROTOCOL} orRoles={['SUPER_ADMIN']}>
        <Content />
      </CheckForFeatureFlag>
    </ManageProtocolChrome>
  );
};
