import { gql, useLazyQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { first } from 'lodash/fp';
import React, { useEffect, useState, useRef } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { AssessNavType, AssessRouteProp } from '../../Assess.interface';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import {
  Input,
  AppText,
  Select,
  ActivityIndicator,
  PageNavButtons,
  AppModal,
  AppButton,
} from '../../../../uiComponents';
import { useAppAlert } from '../../../../hooks/ui/useAppAlert';
import { useTranslations } from '../../../../i18n/useTranslations';
import { SubjectForSubjectsList } from '../../../shared/SubjectList/hooks/__generated__/SubjectForSubjectsList';
import { GetProtocols, GetProtocolsVariables, GetProtocols_getSubject_Subject } from './__generated__/GetProtocols';
import { RfaProtocolResult, Role, Subject, SubjectStatus } from '../../../../types/globalTypes';
import { AssessChrome } from '../../AssessChrome';
import { useSubjectsListQuery } from '../../../shared/SubjectList/hooks/useSubjectsList';
import { PhoneNumberFragment } from '../../../../fragments/PhoneNumber';
import { DocumentFragment } from '../../../../fragments/DocumentFragment';
import { useSearchAndSort, SortValue } from '../../../Admin/screens/ManageGroups/GroupDetails/hooks/useSearchAndSort';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { UserList } from '../../../../uiComponents';
import { Name, Groups, SubjectStatus as StatusLabel } from '../../../TeamRoster/Columns';
import { useSubmitRemoveProtocol } from '../../hooks/useSubmitRemoveProtocol';
import { useRemoveWithoutAssessment } from '../../hooks/useRemoveWithoutAssessment';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { useGetGroupsByUserId } from './hooks/useGetGroupByUserId';

export interface FindSubjectProps {}

const GET_PROTOCOLS = gql`
  query GetProtocols($subjectId: ID!) {
    getSubject(input: { subjectId: $subjectId }) {
      ... on Subject {
        ...SubjectForAssessProtocol
      }
    }
  }
  fragment SubjectForAssessProtocol on Subject {
    id
    firstName
    fullName
    guardians {
      ...GuardianForAssessProtocol
    }
    subjectMemberships {
      id
      status
      organizations {
        id
        status
        organization {
          id
          name
          emergencyPhoneNumber {
            ...PhoneNumber
          }
          protocol {
            ...AssessProtocol
          }
        }
        groups {
          ...Group
        }
      }
    }
  }
  fragment GuardianForAssessProtocol on Guardian {
    id
    firstName
    lastName
    phoneNumber {
      ...PhoneNumber
    }
    organizationId
  }
  fragment AssessProtocol on Protocol {
    id
    protocolName
    protocolUrl
    approved
    createdDate
    removeProtocol {
      id
      removeInstruction
      resumeInstruction
      emergencyInstruction
      discretionaryInstruction
      redFlag {
        id
        instruction
        name
        flags {
          ...AssessRedFlag
        }
      }
      steps {
        ... on StepChallenge {
          ...StepChallenge
        }
        ... on StepInformation {
          ...StepInformation
        }
        ... on StepSymptom {
          ...StepSymptom
        }
      }
      incidentReport {
        instruction
        templateDocument {
          ...Document
        }
      }
    }
  }
  fragment StepChallenge on StepChallenge {
    ...BaseStep
    challenges {
      ...Challenge
    }
  }
  fragment StepInformation on StepInformation {
    ...BaseStep
  }
  fragment StepSymptom on StepSymptom {
    ...BaseStep
    symptoms {
      ...Symptom
    }
  }
  fragment BaseStep on BaseStep {
    id
    instruction
    name
    order
  }
  fragment AssessRedFlag on RedFlagFlag {
    id
    key
    order
    value
  }
  fragment Symptom on Symptom {
    id
    key
    value
    order
  }
  fragment Challenge on Challenge {
    id
    key
    value
    order
  }
  fragment Group on Group {
    name
    id
    organizationId
  }
  ${PhoneNumberFragment}
  ${DocumentFragment}
`;
const GET_GROUPS_FOR_CURRENT_USER = gql`
  query GetGroupsByUserId($userId: ID!) {
    groupsByUserId(userId: $userId) {
      id
      organizationId
      name
    }
  }
`;

export const FindSubject: React.FC<FindSubjectProps> = (props) => {
  const { currentUser } = useCurrentUser();

  const { data: groupData, refetch: refetchGroup } = useGetGroupsByUserId({
    hideLoading: true,
    variables: {
      userId: currentUser?.id,
    },
  });

  const inputRef = useRef<TextInput>(null);
  const { handleSortChange, handleSearchStr, selectedSortType, searchStr, sortCategories, setIsSearchInputFocused } =
    useSearchAndSort(inputRef);
  const [selectedSubject, setSelectedSubject] = useState<SubjectForSubjectsList>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isSearchAndSortVisible, setIsSearchAndSortVisible] = useState<boolean>(false);
  const [submitRemoveProtocol, { loading }] = useSubmitRemoveProtocol({
    onCompleted: (data) => {
      setRemoveModal({ visible: false });
      if (data.submitRemoveProtocol.__typename === 'Case' && data.submitRemoveProtocol)
        navigation.navigate('LoggedIn_IncidentReport', { caseId: data.submitRemoveProtocol.id });
    },
  });

  const navigation = useNavigation<AssessNavType<'LoggedIn_Assess_FindAthlete'>>();

  const { alert } = useAppAlert();
  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const route = useRoute<AssessRouteProp<'LoggedIn_Assess_FindAthlete'>>();
  const assessType = route.params.assessType;

  useEffect(() => {
    setCurrentPage(0);
  }, [searchStr, selectedSortType]);

  useEffect(() => {
    const subjectId = selectedSubject?.id;
    if (subjectId) {
      retrieveProtocols({
        variables: {
          subjectId,
        },
      });
    }
  }, [selectedSubject?.id]);

  const [retrieveProtocols] = useLazyQuery<GetProtocols, GetProtocolsVariables>(GET_PROTOCOLS, {
    fetchPolicy: 'no-cache', // TODO: change policy once https://github.com/apollographql/apollo-client/issues/6636 is resolved
    onCompleted: (data) => {
      if (data.getSubject.__typename !== 'Subject') {
        alert(translate.error.title.generic, translate.error.message.missingProtocolForSubject);
        return;
      }

      const subject = data.getSubject;
      const { organizations } = subject.subjectMemberships;

      const protocols = organizations
        .map(({ groups, organization: { id, name, emergencyPhoneNumber, protocol }, status }) => ({
          organization: {
            id,
            name,
            emergencyPhoneNumber,
          },
          groups,
          name,
          protocol,
          status,
        }))
        .filter(({ protocol }) => protocol.approved);

      let mode: 'go-to-red-flags' | 'go-to-protocol-selection' | 'missing-protocol' = 'missing-protocol';

      const currentUserGroups = groupData?.groupsByUserId.map((group) => {
        return {
          __typename: 'GroupMembership',
          group: {
            ...group,
          },
        };
      });
      const currentUserConcussionManager =
        currentUser?.__typename === 'CurrentUser'
          ? currentUser?.roles
              .filter((role) => role.role === Role.PRIMARY_DESIGNATED_PERSON)
              .map((role) => role.organizationId)
          : [];

      const currentUserGroupLeader =
        currentUser?.__typename === 'CurrentUser'
          ? currentUser?.roles
              .filter((role) => role.role === Role.SECONDARY_DESIGNATED_PERSON)
              .map((role) => role.organizationId)
          : [];

      const currentUserGroupsGroupLeader = currentUserGroups
        ? currentUserGroups
            .filter((group) => currentUserGroupLeader.includes(group.group.organizationId))
            .map((group) => group.group.id)
        : [];
      const SubjectsOrganizations = protocols
        .map(({ organization: { id, name }, groups, status }) => {
          if (currentUserConcussionManager.includes(id)) {
            return id;
          }
        })
        .filter((organization) => organization);

      const subjectsGroups = protocols
        .map(({ organization: { id, name }, groups, status }) =>
          groups.map((group) => {
            if (currentUserGroupsGroupLeader.includes(group.id)) {
              return id;
            }
          })
        )
        .flat()
        .filter((group) => group)
        .filter((group) => !SubjectsOrganizations.includes(group));

      const filteredProtocols = protocols.filter(
        ({ organization: { id, name }, groups, status }) =>
          SubjectsOrganizations.includes(id) || subjectsGroups.includes(id)
      );
      const protocolsWhereSubjectIsActive = filteredProtocols.filter(({ organization: { id, name }, groups, status }) =>
        status === SubjectStatus.ACTIVE ? true : false
      );

      const numApplicableProtocols = filteredProtocols.length;
      const numAvailableProtocols = protocolsWhereSubjectIsActive.length;
      const firstProtocolWhereSubjectIsActive = first(protocolsWhereSubjectIsActive);

      if (numApplicableProtocols === 0) {
        mode = 'missing-protocol';
      } else {
        if (numAvailableProtocols === 1 && numApplicableProtocols === 1) {
          mode = 'go-to-red-flags';
        } else {
          mode = 'go-to-protocol-selection';
        }
      }

      if (mode === 'missing-protocol') {
        alert(translate.error.title.generic, translate.error.message.missingProtocolForSubject);
      } else if (mode === 'go-to-protocol-selection') {
        dispatch({
          type: 'setSubject',
          payload: {
            subject,
            selectedProtocolOption: firstProtocolWhereSubjectIsActive,
            availableProtocolOptions: filteredProtocols,
          },
        });
        setTimeout(() => navigation.navigate('LoggedIn_Assess_ChooseOrganizationProtocol', { assessType }));
      } else if (mode === 'go-to-red-flags') {
        dispatch({
          type: 'setSubject',
          payload: {
            subject,
            selectedProtocolOption: firstProtocolWhereSubjectIsActive,
            availableProtocolOptions: filteredProtocols,
          },
        });

        setTimeout(() => {
          if (assessType === 'recognize') {
            navigation.navigate('LoggedIn_Assess_RedFlags');
          } else if (assessType === 'remove') {
            setRemoveModal({ visible: true, subject });
          }
        });
      }
    },
  });

  const { state, dispatch } = useCurrentAssessment();

  const handleSubjectSelect = (subject: SubjectForSubjectsList) => {
    setSelectedSubject(subject);
  };

  const handleRemoveWithoutAssessment = () => {
    if (removeModal.subject && state.selectedProtocolOption) {
      submitRemoveProtocol({
        variables: {
          input: {
            subjectId: removeModal.subject?.id,
            organizationId: state.selectedProtocolOption.organization.id,
            suggestedResult: RfaProtocolResult.REMOVE,
            protocolId: state.selectedProtocolOption.protocol.id,
            redFlagResults: [],
            stepResults: [],
            emergency: true,
            result: RfaProtocolResult.REMOVE,
            isSkippedAssessment: true,
          },
        },
      });
    }
  };

  const { removeModal, setRemoveModal, RemoveModal } = useRemoveWithoutAssessment(handleRemoveWithoutAssessment);

  const styles = StyleSheet.create({
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
    },
    sortSelect: {
      width: isMobile ? '100%' : '40%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
      paddingRight: 15,
    },
    searchContainer: {
      width: isMobile ? '100%' : '60%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
    },
    pageNavButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const { data } = useSubjectsListQuery({
    hideLoading: true,
    variables: {
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      searchStr: searchStr ? searchStr : null,
    },
  });

  useEffect(() => {
    if (data && data.currentUserSubjects && !isSearchAndSortVisible) {
      setIsSearchAndSortVisible(true);
    }
  }, [data]);

  const subjects = data?.currentUserSubjects;

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const content = !loading ? (
    <>
      <RemoveModal />
      <View style={{ flexGrow: 1, flexBasis: '100%', flexShrink: 0 }}>
        {isSearchAndSortVisible ? (
          <View>
            <View style={styles.searchAndSortContainer}>
              <View style={styles.sortSelect}>
                <AppText>{translate.users.userDetails.sort}</AppText>
                <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
              </View>
              <View style={styles.searchContainer}>
                <Input
                  icon="search"
                  ref={inputRef}
                  onFocus={() => setIsSearchInputFocused(true)}
                  onBlur={() => setIsSearchInputFocused(false)}
                  placeholder={translate.users.userDetails.searchByName}
                  type="name"
                  debounce={{
                    milliseconds: 1000,
                    callback: handleSearchStr,
                    textValue: searchStr,
                  }}
                />
              </View>
            </View>
            <PageNavButtons
              isPrevPageButtonVisible={currentPage !== 0}
              isNextPageButtonVisible={subjects && subjects.length === 25}
              prevPageAction={goToPreviousPage}
              nextPageAction={goToNextPage}
              style={styles.pageNavButtons}
            />
          </View>
        ) : null}
        {subjects ? (
          <UserList
            noUsersMessage={translate.subjectList.subjectData.noUsersFromSearch}
            displayItems={subjects.map((subject) => ({
              onSelect: () => handleSubjectSelect(subject),
              key: subject.id,
              components: [
                <Name name={subject.fullName} />,
                <StatusLabel memberships={subject.subjectMemberships} />,
                <Groups groups={subject.groups} />,
              ],
            }))}
          />
        ) : (
          <ActivityIndicator style={styles.activityIndicator} />
        )}
      </View>
    </>
  ) : (
    <ActivityIndicator />
  );

  return (
    <AssessChrome
      autoScroll={false}
      content={content}
      customTitle={assessType === 'remove' ? translate.protocol.removeModal.title : undefined}
    />
  );
};
