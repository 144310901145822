import React from 'react';
import { RtaProtocolType } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppText } from '../../../../../uiComponents';
import { CaseItemRtaProtocolCompletion } from '../../../../../fragments/__generated__/CaseItemRtaProtocolCompletion';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseLogContextSubject } from '../CaseLogs.interface';

export interface CaseItemRtaProtocolCompletionProps {
  item: CaseItemRtaProtocolCompletion;
  subject: CaseLogContextSubject;
}

export const CaseItemRtaProtocolCompletionView: React.FC<CaseItemRtaProtocolCompletionProps> = ({ item, subject }) => {
  const { translate, translateWithVars } = useTranslations();

  const protocolTerm =
    item.protocolType === RtaProtocolType.LEARN
      ? translate.recovery.index.returnToLearn
      : translate.recovery.index.returnToSport;

  const text = !item.isFastTracked
    ? translateWithVars(translate.caseLog.caseItemRtaProtocolCompletion.message, {
        actor: `${item.actor.firstName} ${item.actor.lastName}`,
        subject: subject.fullName,
        protocol: protocolTerm,
      })
    : translateWithVars(translate.caseLog.caseItemRtaProtocolCompletion.fastTracked, {
        user: `${item.actor.firstName} ${item.actor.lastName}`,
        subject:
          subject.id !== item.actor.id ? subject.fullName : translate.caseLog.caseItemRtaProtocolCompletion.themself,
        protocol: protocolTerm,
        stepDefinedWaitTime: item.timeGateHours,
        hourOrHours:
          item.timeGateHours === 1 ? translate.recovery.stageSignoff.hour : translate.recovery.stageSignoff.hours,
      });

  return (
    <BaseCaseLogItemView item={item}>
      <AppText color={item.isFastTracked ? 'red' : 'grayDark'}>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
