import { ProfileNavItem } from '../Profile.interface';
import { Overview } from '../ProfileChrome/Index';
import { CaseLogs } from '../screens/CaseLogs/CaseLogs';
import { RecoveryPlanNavigator } from '../screens/RecoveryPlan/RecoveryPlanNavigator';
import { useRecoveryPlanRoutes } from '../screens/RecoveryPlan/hooks/useRecoveryPlanRoutes';

export const useProfileRoutes = () => {
  const recoveryPlanRoutes = useRecoveryPlanRoutes();

  const profileRoutes: ProfileNavItem[] = [
    {
      component: Overview,
      label: 'Overview',
      name: 'LoggedIn_Profile_Overview' as const,
      path: '',
    },
    {
      component: RecoveryPlanNavigator,
      name: 'LoggedIn_Profile_RecoveryPlan' as const,
      path: 'recovery',
      children: recoveryPlanRoutes,
    },
    {
      component: CaseLogs,
      label: 'Logs',
      name: 'LoggedIn_Profile_Logs' as const,
      path: 'logs/:caseId?',
    },
  ];

  return profileRoutes;
};
