import { gql, QueryHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useQuery } from '../../../hooks/useQuery';
import { ProtocolFragment } from '../../Admin/screens/ManageProtocol/fragments/ProtocolFragment';
import {
  GetProtocolByOrganization,
  GetProtocolByOrganizationVariables,
} from './__generated__/GetProtocolByOrganization';

const GET_PROTOCOL_BY_ORGANIZATION = gql`
  query GetProtocolByOrganization($organizationId: ID!, $protocolId: String) {
    currentProtocolByOrganization(organizationId: $organizationId, protocolId: $protocolId) {
      ... on Protocol {
        ...Protocol
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ProtocolFragment}
  ${ApiErrorFragment}
`;

export const useGetProtocolByOrganization = (
  options?: QueryHookOptions<GetProtocolByOrganization, GetProtocolByOrganizationVariables>
) =>
  useQuery<GetProtocolByOrganization, GetProtocolByOrganizationVariables>(GET_PROTOCOL_BY_ORGANIZATION, {
    ...options,
    hideAlerts: true,
  });
