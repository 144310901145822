import React from 'react';
import { ProtocolStageChangeDirection, RtaProtocolType } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppText } from '../../../../../uiComponents';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseItemRtaProtocolStageChange } from '../../../../../fragments/__generated__/CaseItemRtaProtocolStageChange';
import { CaseLogContextSubject } from '../CaseLogs.interface';

export interface CaseItemRtaProtocolStageChangeProps {
  item: CaseItemRtaProtocolStageChange;
  subject: CaseLogContextSubject;
}

export const CaseItemRtaProtocolStageChangeView: React.FC<CaseItemRtaProtocolStageChangeProps> = ({
  item,
  subject,
}) => {
  const { translate, translateWithVars } = useTranslations();

  const directionTerm =
    item.direction === ProtocolStageChangeDirection.ADVANCE
      ? translate.caseLog.caseItemRtaProtocolStageChange.advance
      : translate.label.stepBack;

  const protocolTerm =
    item.protocolType === RtaProtocolType.LEARN
      ? translate.recovery.index.returnToLearn
      : translate.recovery.index.returnToSport;

  const textTerm =
    subject.id !== item.actor.id
      ? translate.caseLog.caseItemRtaProtocolStageChange.other
      : translate.caseLog.caseItemRtaProtocolStageChange.self;

  const text = !item.isFastTracked
    ? translateWithVars(textTerm, {
        actor: `${item.actor.firstName} ${item.actor.lastName}`,
        subject: subject.fullName,
        direction: directionTerm,
        stage: item.newStageName.toLowerCase(),
        protocol: protocolTerm,
      })
    : translateWithVars(translate.caseLog.caseItemRtaProtocolStageChange.fastTracked, {
        user: `${item.actor.firstName} ${item.actor.lastName}`,
        participant: subject.id !== item.actor.id ? subject.fullName : '',
        stageName: item.newStageName.toLowerCase(),
        stepDefinedWaitTime: item.timeGateHours,
        hourOrHours:
          item.timeGateHours === 1 ? translate.recovery.stageSignoff.hour : translate.recovery.stageSignoff.hours,
      });

  return (
    <BaseCaseLogItemView item={item}>
      <AppText color={item.isFastTracked ? 'red' : 'grayDark'}>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
