import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { useColors } from '../../../../../../../shared/Ui/hooks';
import {
  Icon,
  Input,
  InputLabel,
  RadioButton,
  ShadowView,
  Spacer,
  SpacerItems,
} from '../../../../../../../uiComponents';
import {
  UpdateProtocolConfigFormValue,
  UpdateRemoveProtocolStepChallengeConfigFormValue,
  UpdateRemoveProtocolStepConfigFormValue,
  UpdateRemoveProtocolStepSymptomConfigFormValue,
} from '../../../ManageProtocol.interface';

export interface RemoveProtocolStepBaseConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateRemoveProtocolStepConfigFormValue>;
  touch?: FormikTouched<UpdateRemoveProtocolStepConfigFormValue>;
  value: UpdateRemoveProtocolStepConfigFormValue;
  typeSpecificItems: SpacerItems;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const RemoveProtocolStepBaseConfig: React.FC<RemoveProtocolStepBaseConfigProps> = ({
  itemKey,
  error,
  touch,
  value,
  typeSpecificItems,
  canMoveDown,
  canMoveUp,
  onRemove,
  move,
  index,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            remove: { step: translate },
          },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();

  const { handleChange, handleBlur, setFieldValue } = useFormikContext<UpdateProtocolConfigFormValue>();
  const colors = useColors();

  const handleChangeType = (type: 'symptom' | 'challenge') => {
    if (type === 'symptom') {
      const symptomItem: UpdateRemoveProtocolStepSymptomConfigFormValue = {
        ...value,
        type: 'symptom',
        symptoms: [],
      };
      setFieldValue(`${itemKey}`, symptomItem);
    } else {
      const documentItem: UpdateRemoveProtocolStepChallengeConfigFormValue = {
        ...value,
        type: 'challenge',
        challenges: [],
      };

      setFieldValue(`${itemKey}`, documentItem);
    }
  };

  const handleRemove = () => onRemove(index);
  const handleMoveUp = () => move(index, index - 1);
  const handleMoveDown = () => move(index, index + 1);

  return (
    <ShadowView style={{ padding: 20 }}>
      <Spacer
        size="large"
        items={{
          actions: (
            <Spacer
              orientation="horizontal"
              style={{ alignSelf: 'flex-end', width: 'auto' }}
              items={{
                up: canMoveUp ? <Icon color={colors.blue} name="arrow-up" onPress={handleMoveUp} size={24} /> : null,
                down: canMoveDown ? (
                  <Icon color={colors.blue} name="arrow-down" onPress={handleMoveDown} size={24} />
                ) : null,
                trash: <Icon color={colors.red} name="trash" onPress={handleRemove} size={24} />,
              }}
            />
          ),
          name: (
            <Input
              label={rootTranslate.label.name}
              onChangeText={handleChange(`${itemKey}.name`)}
              onBlur={handleBlur(`${itemKey}.name`)}
              error={error?.name}
              touched={touch?.name}
              value={value?.name || ''}
            />
          ),
          instruction: (
            <>
              <Input
                label={rootTranslate.admin.manageProtocol.instruction}
                onChangeText={handleChange(`${itemKey}.instruction`)}
                onBlur={handleBlur(`${itemKey}.instruction`)}
                error={error?.instruction}
                touched={touch?.instruction}
                value={value?.instruction || ''}
              />
            </>
          ),
          type: (
            <>
              <InputLabel label={translate.stepType} />
              <RadioButton<'symptom' | 'challenge'>
                value={value.type}
                onSelect={handleChangeType}
                options={[
                  {
                    icon: 'check',
                    label: translate.symptom,
                    value: 'symptom',
                  },
                  {
                    icon: 'check',
                    label: translate.challenge,
                    value: 'challenge',
                  },
                ]}
              />
            </>
          ),

          ...typeSpecificItems,
        }}
      />
    </ShadowView>
  );
};
