import { createContext } from 'react';

export interface LoadingContext {
  setLoading: (loading: boolean) => void;
}

export const LoadingContext = createContext<LoadingContext>({
  setLoading: () => {
    /* noop */
  },
});
