import { gql } from '@apollo/client';

export const OrganizationFragment = gql`
  fragment Organization on Organization {
    id
    name
    type
    sport
    language
    emergencyPhoneNumber {
      phoneNumber
      name
      country {
        isoCode
      }
    }
  }
`;
