import React, { useState } from 'react';
import { Formik, FormikProps, FormikErrors, FormikHelpers } from 'formik';
import { LoginFields } from './LoginFields';
import { useTranslations } from '../../../i18n/useTranslations';
import { useLoading } from '../../../uiComponents';
import { useSignInWithEmailAndPassword } from '../../../shared/Firebase';
import { useValidation } from '../../../hooks/useValidation';
import { useAuthErrorCode } from '../../../shared/Firebase/hooks/useAuthErrorCode';
import { LoginFormValues } from './LoginForm.interface';

interface LoginFormProps {
  initialValues: LoginFormValues;
  lockedToEmail?: boolean | undefined | null;
  invitationIdentifier?: string | undefined | null;
}

export const LoginForm: React.FC<LoginFormProps> = ({ initialValues, lockedToEmail }) => {
  const formik = React.useRef<FormikProps<LoginFormValues> | null>(null);
  const { translate } = useTranslations();
  const { validateEmail } = useValidation();
  const [loading, setLoading] = useState(false);
  const signIn = useSignInWithEmailAndPassword();
  const authErrorCode = useAuthErrorCode();

  useLoading(loading);

  const submitForm = async (values: LoginFormValues, { setSubmitting, setErrors }: FormikHelpers<LoginFormValues>) => {
    setLoading(true);
    signIn(values.email, values.password)
      .then(() => {
        setSubmitting(false);
        setLoading(false);
      })
      .catch((message) => {
        setErrors({ password: authErrorCode(message) });
        setLoading(false);
      });
  };

  const validateForm = (values: LoginFormValues) => {
    const errors: FormikErrors<LoginFormValues> = {};

    if (!validateEmail(values.email)) {
      errors.email = translate.error.message.authNoEmail;
    }

    if (!values.password) {
      errors.password = translate.error.message.authNoPassword;
    }

    return errors;
  };

  return (
    <Formik<LoginFormValues>
      innerRef={(ref) => (formik.current = ref)}
      onSubmit={submitForm}
      validate={validateForm}
      initialValues={initialValues}
    >
      <LoginFields disableEmail={lockedToEmail ? true : false} />
    </Formik>
  );
};
