import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../../hooks/useMutation';
import { DeactivateUser, DeactivateUserVariables } from './__generated__/DeactivateUser';
import { GET_ORGANIZATION_USERS } from '../../../Admin/hooks/useGetOrganizationUsers';

const DEACTIVATE_USER = gql`
  mutation DeactivateUser($userId: String!, $organizationId: String!) {
    removeAllUserRoles(userId: $userId, organizationId: $organizationId) {
      ... on DeactivateUserResult {
        success
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useDeactivateUser = (
  options: MutationHookOptions<DeactivateUser, DeactivateUserVariables>,
  organizationId,
  paginationArgs,
  role: string | null,
  searchStr: string | null
) =>
  useMutation<DeactivateUser, DeactivateUserVariables>(DEACTIVATE_USER, {
    ...options,
    refetchQueries: [
      {
        query: GET_ORGANIZATION_USERS,
        variables: { organizationId, paginationArgs, role, searchStr },
      },
      'GetOrganizationUsers',
    ],
  });
