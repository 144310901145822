import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Linking, Platform, LayoutAnimation } from 'react-native';
import { AppText, Icon } from '../../../../uiComponents';

interface CardRowProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  groups?: string[];
  phoneNumber?: string;
}

export const CardRow: React.FC<CardRowProps> = (props) => {
  const { id, firstName, lastName, email, groups, phoneNumber } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const styles = StyleSheet.create({
    content: {
      marginHorizontal: 20,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginHorizontal: 20,
      marginTop: 5,
    },
    contentText: {
      marginTop: 5,
    },
    cardTitle: {
      textAlign: 'center',
    },
  });

  const handleToggleExpand = () => {
    LayoutAnimation.configureNext({
      duration: 200,
      create: { type: 'linear', property: 'opacity' },
      update: { type: 'linear' },
      delete: { type: 'linear', property: 'opacity' },
    });
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const dialNumber = (number) => {
    if (Platform.OS === 'ios') {
      Linking.openURL(`telprompt:${number}`);
    } else if (Platform.OS === 'android') {
      Linking.openURL(`tel:${number}`);
    }
  };

  return (
    <View key={id}>
      <TouchableOpacity style={styles.header} onPress={handleToggleExpand}>
        <AppText font="labelDefault" color="grayDark">{`${firstName} ${lastName}`}</AppText>
        {isExpanded ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
      </TouchableOpacity>
      {isExpanded ? (
        <View style={styles.content}>
          {groups
            ? groups.map((group) => (
                <AppText font="labelDefault" color="navyDark" style={styles.contentText}>
                  {group}
                </AppText>
              ))
            : null}
          <AppText style={styles.contentText}>{email}</AppText>
          {!!phoneNumber ? (
            <TouchableOpacity onPress={() => dialNumber(phoneNumber)}>
              <AppText style={styles.contentText}>{phoneNumber}</AppText>
            </TouchableOpacity>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};
