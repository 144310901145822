import React from 'react';
import { KeyboardAvoidingView, Platform, View, StyleSheet } from 'react-native';
import { CommonProps } from './Chrome.interface';
import { MaybeScroll } from './MaybeScroll';
import { useColors } from '../Ui/hooks';

export interface FullScreenProps extends CommonProps {
  mode: 'full-screen';
  content?: React.ReactNode;
  theme?: 'default' | 'emergency';
  hideNavigation?: boolean;
  children: React.ReactNode;
}

export const FullScreen: React.FC<FullScreenProps> = ({
  navbar,
  content: primaryContent,
  theme = 'default',
  autoScroll = true,
}) => {
  const colors = useColors();

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme === 'default' ? colors['white'] : colors['red'],
    },
    header: {
      padding: 20,
    },
    body: {
      padding: 20,
    },
  });

  return (
    <View style={styles.container}>
      <MaybeScroll autoScroll={autoScroll} start={false} end={false} showsVerticalScrollIndicator={false}>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'position' : 'height'}>
          <View style={styles.header}>{navbar}</View>
          <View style={styles.body}>{primaryContent}</View>
        </KeyboardAvoidingView>
      </MaybeScroll>
    </View>
  );
};
