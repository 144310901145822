import React from 'react';
import { View } from 'react-native';
import { MaterialIndicator } from 'react-native-indicators';
import { ColorKeys, useColors } from '../../shared/Ui';

interface ActivityIndicatorProps {
  size?: string;
  color?: ColorKeys;
  style?: object;
}

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({
  size = 'large',
  color = 'navyDark',
  style = {},
}) => {
  const colors = useColors();
  return (
    <View style={style}>
      <MaterialIndicator size={size === 'large' ? 50 : 25} trackWidth={2} color={colors[color]} />
    </View>
  );
};
