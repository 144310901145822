import React from 'react';
import { StyleSheet, View } from 'react-native';

export const InputContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const styles = StyleSheet.create({
    container: {
      marginBottom: 10,
    },
  });

  return <View style={[styles.container]}>{children}</View>;
};
