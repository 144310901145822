import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { useState } from 'react';
import {
  AppText,
  Select,
  ActivityIndicator,
  UserList,
  AppDateTimePicker,
  AppButton,
  PageNavButtons,
} from '../../uiComponents';
import { CurrentUser_roles } from '../../fragments/__generated__/CurrentUser';
import { useTranslations } from '../../i18n/useTranslations';
import {
  useSearchAndSort,
  SortValue,
  SortOrder,
} from '../Admin/screens/ManageGroups/GroupDetails/hooks/useSearchAndSort';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { useGetCasePreviews } from './hooks/useGetCasePreviews';
import { CaseStatus } from '../../types/globalTypes';
import { Name } from '../TeamRoster/Columns';
import { differenceInDays } from 'date-fns';
import { useDateFormat } from '../../hooks/ui/useDateFormat';
import { UseSubjectsCases_subjectCases } from './hooks/__generated__/UseSubjectsCases';
import { useGetCasesCSV } from './hooks/useGetCasesCSV';

interface CasesDataProps {
  role: CurrentUser_roles;
  handleSelect: (subjectId: string, caseId: string) => void;
  emptyListMessage: string;
  caseType: 'active' | 'past';
}

type DateSort = 'createdDate' | 'closedDate';
type DateSortValue = `${DateSort}_${SortOrder}`;

type CasesSortValue = SortValue | DateSortValue;

interface CasesSortCategories {
  key: string;
  value: CasesSortValue;
  label: string;
}

export const CasesData: React.FC<CasesDataProps> = ({ role, handleSelect, emptyListMessage, caseType }) => {
  const { translate, translateWithVars } = useTranslations();

  const isMobile = useIsMobile();

  const { sortCategories } = useSearchAndSort();

  const casesSortCategories: CasesSortCategories[] = [
    {
      key: 'Start date: newest',
      value: 'createdDate_desc',
      label: translate.cases.label.startDateDesc,
    },
    {
      key: 'Start date: oldest',
      value: 'createdDate_asc',
      label: translate.cases.label.startDateAsc,
    },
    {
      key: 'End date: newest',
      value: 'closedDate_desc',
      label: translate.cases.label.endDateDesc,
    },
    {
      key: 'End date: oldest',
      value: 'closedDate_asc',
      label: translate.cases.label.endDateAsc,
    },
    ...sortCategories,
  ];

  const [selectedSortType, setSelectedSortType] = useState<CasesSortValue>(casesSortCategories[0].value);
  const [fromDate, setFromDate] = useState<string | undefined>(undefined);
  const [toDate, setToDate] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleSortChange = (value: CasesSortValue) => {
    setSelectedSortType(value);
  };

  const styles = StyleSheet.create({
    heading: {
      marginBottom: 20,
    },
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-start',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
      marginBottom: 20,
      maxWidth: isMobile ? '100%' : '80%',
    },
    sortSelect: {
      width: '50%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
    },
    sort: {
      width: isMobile ? '100%' : '35%',
    },
    dateSelectContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: isMobile ? '100%' : '65%',
    },
    listContainer: {
      marginBottom: 50,
    },
    pageNavButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 20,
    },
    nameOrStatus: {
      marginBottom: 10,
    },
    groupsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
  });

  const { formatDate } = useDateFormat();

  const { data } = useGetCasePreviews({
    variables: {
      organizationId: role.organizationId,
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      fromDate,
      toDate,
      status: caseType === 'active' ? CaseStatus.OPEN : CaseStatus.CLOSED,
    },
  });

  const [getCasesCSV] = useGetCasesCSV({
    variables: {
      organizationId: role.organizationId,
      fromDate,
      toDate,
      status: caseType === 'active' ? CaseStatus.OPEN : CaseStatus.CLOSED,
    },
    onCompleted: ({ getCasesCSV }) => {
      if (getCasesCSV) Linking.openURL(getCasesCSV);
    },
  });

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const casePreviews = data?.subjectCases;

  const getCaseDuration = (createdDate: string, closedDate: string | null) => {
    return translateWithVars(translate.cases.numberOfDays, {
      number: closedDate
        ? differenceInDays(new Date(closedDate), new Date(createdDate))
        : differenceInDays(new Date(), new Date(createdDate)),
    });
  };

  const getCaseStartAndEnd = (createdDate: string, closedDate: string | null) => {
    const formattedCreatedDate = !isMobile
      ? formatDate(new Date(createdDate))
      : formatDate(new Date(createdDate), 'MM/dd/yyy');

    const formattedClosedDate = closedDate
      ? !isMobile
        ? formatDate(new Date(closedDate))
        : formatDate(new Date(closedDate), 'MM/dd/yyy')
      : null;

    if (closedDate) {
      return translateWithVars(translate.cases.dateRangeForClosedCase, {
        createdDate: formattedCreatedDate,
        closedDate: formattedClosedDate,
      });
    } else {
      return translateWithVars(translate.cases.dateRangeForActiveCase, {
        createdDate: formattedCreatedDate,
      });
    }
  };

  const isSearchApplied = currentPage !== 0 || !!fromDate || !!toDate;

  const getStatus = (status: CaseStatus) => {
    const statuses = {
      [CaseStatus.OPEN]: translate.caseLog.open,
      [CaseStatus.CLOSED]: translate.caseLog.closed,
    };
    return statuses[status] ? statuses[status] : '';
  };

  return (
    <View>
      <AppText font="header2" style={styles.heading}>
        {role.organizationName}
      </AppText>
      <View style={styles.groupsContainer}>
        <AppText font="labelDefault" style={{ marginRight: 10 }}>
          {translate.subjectList.subjectData.allGroups}
        </AppText>
        <AppButton onPress={getCasesCSV} icon="download" size="extraSmall" />
      </View>
      {casePreviews?.length === 25 || isSearchApplied ? (
        <View style={styles.searchAndSortContainer}>
          <View style={[styles.sortSelect, styles.sort, !isMobile ? { paddingRight: 15, top: 5 } : null]}>
            <AppText>{translate.users.userDetails.sort}</AppText>
            <Select<CasesSortValue> value={selectedSortType} items={casesSortCategories} onChange={handleSortChange} />
          </View>
          <View style={styles.dateSelectContainer}>
            <View style={[styles.sortSelect, { paddingRight: 15 }]}>
              <AppDateTimePicker
                label={translate.cases.fromStartDate}
                value={fromDate}
                onChange={(date: string) => setFromDate(date)}
                maximumDate={toDate}
              />
            </View>
            <View style={styles.sortSelect}>
              <AppDateTimePicker
                label={translate.cases.toStartDate}
                value={toDate}
                onChange={(date: string) => setToDate(date)}
                minimumDate={fromDate}
              />
            </View>
          </View>
        </View>
      ) : null}
      {currentPage !== 0 || (casePreviews && casePreviews.length === 25) ? (
        <PageNavButtons
          isPrevPageButtonVisible={currentPage !== 0}
          isNextPageButtonVisible={casePreviews && casePreviews.length === 25}
          prevPageAction={goToPreviousPage}
          nextPageAction={goToNextPage}
          style={styles.pageNavButtons}
        />
      ) : null}
      <View style={styles.listContainer}>
        {casePreviews ? (
          <UserList
            displayItems={casePreviews.map((casePreview: UseSubjectsCases_subjectCases) => ({
              key: casePreview.id,
              onSelect: () => handleSelect(casePreview.subjectId, casePreview.id),
              components: [
                <Name name={casePreview.subjectName} style={isMobile ? styles.nameOrStatus : undefined} />,
                <AppText style={isMobile ? styles.nameOrStatus : null}>{getStatus(casePreview.caseStatus)}</AppText>,
                <AppText>{getCaseDuration(casePreview.createdDate, casePreview.closedDate)}</AppText>,
                <AppText>{getCaseStartAndEnd(casePreview.createdDate, casePreview.closedDate)}</AppText>,
              ],
            }))}
            noUsersMessage={emptyListMessage}
            customWidths={[25, 25, 20, 30]}
          />
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </View>
  );
};
