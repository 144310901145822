import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { ChangePasswordFormValues } from './ChangePasswordScreen';
import { AppButton, AppText, Input, PasswordHints } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { usePasswordRules } from '../../../hooks/usePasswordRules';

export interface ChangePasswordFieldsProps {}

export const ChangePasswordFields: React.FC<ChangePasswordFieldsProps> = (props) => {
  const { translate } = useTranslations();
  const {
    handleChange,
    handleBlur,
    isValid,
    values: { newPassword, newPasswordConfirm },
    errors,
    touched,
    submitForm,
  } = useFormikContext<ChangePasswordFormValues>();
  const [showHints, setShowHints] = useState(false);
  const passwordRules = usePasswordRules();
  const handlePasswordFieldFocus = () => setShowHints(true);

  return (
    <>
      <AppText style={{ marginTop: 20 }}>{translate.settings.changePassword.requirements}</AppText>
      <Input
        icon="lock"
        type="password"
        value={newPassword}
        onChangeText={handleChange('newPassword')}
        onBlur={handleBlur('newPassword')}
        onFocus={handlePasswordFieldFocus}
        error={errors.newPassword}
        placeholder={translate.placeholder.passwordNew}
      />
      <PasswordHints
        password={newPassword}
        visible={showHints}
        validColor="green"
        style={{ marginBottom: 15 }}
        rules={passwordRules}
      />
      <Input
        icon="lock"
        type="password"
        value={newPasswordConfirm}
        onChangeText={handleChange('newPasswordConfirm')}
        onBlur={handleBlur('newPasswordConfirm')}
        error={touched.newPassword && touched.newPasswordConfirm ? errors.newPasswordConfirm : undefined}
        placeholder={translate.placeholder.passwordNewConfirm}
      />
      <AppButton onPress={submitForm} disabled={!isValid}>
        {translate.settings.term.updatePassword}
      </AppButton>
    </>
  );
};
