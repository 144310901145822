import { getAuth, signOut } from '@firebase/auth';
import { useFirebase } from '../Firebase';

export const useLogout = () => {
  const { firebase } = useFirebase();

  return {
    logout: () => signOut(getAuth(firebase)),
  };
};
