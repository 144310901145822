import { FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import React from 'react';
import { AuthorizedRole } from '../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppButton, Spacer, SpacerItems } from '../../../../../../uiComponents';
import {
  UpdateProtocolConfigFormValue,
  UpdateReturnProtocolChildProtocolStageConfigFormValue,
  UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue,
} from '../../ManageProtocol.interface';
import { ReturnProtocolStageConfig } from './ReturnProtocolStageConfig/ReturnProtocolStageConfig';

export interface ReturnProtocolStagesConfigProps extends FieldArrayRenderProps {
  push<UpdateReturnProtocolChildProtocolStageConfigFormValue>(obj: UpdateReturnProtocolChildProtocolStageConfigFormValue): void;
}

export const ReturnProtocolStagesConfig: React.FC<ReturnProtocolStagesConfigProps> = ({ name, push, remove, move }) => {
  const { values } = useFormikContext<UpdateProtocolConfigFormValue>();
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: { return: translate },
        },
      },
    },
  } = useTranslations();

  const handleAdd = () => {
    const defaultNewItem: UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue = {
      type: 'signoff',
      description: '',
      name: '',
      authorizedRole: AuthorizedRole.ANYONE,
      isTimeGateSelected: false,
    };
    push(defaultNewItem);
  };

  const returnStages: SpacerItems = {};

  const stages = getIn(values, name) as UpdateReturnProtocolChildProtocolStageConfigFormValue[];

  return (
    <Spacer
      size="large"
      style={{ marginBottom: 20 }}
      items={{
        items: (
          <Spacer
            size="large"
            items={stages.reduce((accum, _, index) => {
              accum[index.toString()] = (
                <ReturnProtocolStageConfig
                  totalItems={stages.length}
                  onRemove={remove}
                  move={move}
                  name={name}
                  index={index}
                />
              );

              return accum;
            }, returnStages)}
          />
        ),
        add: <AppButton onPress={handleAdd}>{translate.addNewStage}</AppButton>,
      }}
    />
  );
};
