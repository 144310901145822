import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { GetEducationReport, GetEducationReportVariables } from './__generated__/GetEducationReport';

export const GET_EDUCATION_REPORT = gql`
  query GetEducationReport(
    $organizationId: ID!
    $paginationArgs: PaginationArgs!
    $searchStr: String
    $role: Role
    $status: Boolean
  ) {
    educationReport(
      input: {
        organizationId: $organizationId
        paginationArgs: $paginationArgs
        searchStr: $searchStr
        role: $role
        status: $status
      }
    ) {
      ... on GetEducationReportResult {
        EducationReportResult {
          id
          user
          completed
          roles
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetEducationReport = (options?) =>
  useQuery<GetEducationReport, GetEducationReportVariables>(GET_EDUCATION_REPORT, options);
