import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../../fragments/ApiErrorFragment';
import { CreateGroup, CreateGroupVariables } from './__generated__/CreateGroup';
import { GroupFragment } from '../../fragments/GroupFragment';
import { GetGroupsByOrganizationId } from '../../hooks/__generated__/GetGroupsByOrganizationId';
import { GET_GROUPS_BY_ORGANIZATION_ID_QUERY } from '../../hooks/useGetGroupsByOrganizationId';

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup($name: String!, $organizationId: ID!) {
    createGroup(name: $name, organizationId: $organizationId) {
      ... on Group {
        ...Group
      }
      ... on ApiError {
        ...ApiError
      }
      ... on ExistingGroup {
        name
        active
      }
    }
  }
  ${GroupFragment}
  ${ApiErrorFragment}
`;

export const useCreateGroup = (
  organizationId: string,
  options?: MutationHookOptions<CreateGroup, CreateGroupVariables>
) =>
  useMutation<CreateGroup, CreateGroupVariables>(CREATE_GROUP_MUTATION, {
    ...options,
    update(cache, { data }) {
      const groups = cache.readQuery<GetGroupsByOrganizationId>({
        query: GET_GROUPS_BY_ORGANIZATION_ID_QUERY,
        variables: {
          organizationId,
        },
      });

      if (groups && data?.createGroup && data.createGroup.__typename === 'Group') {
        cache.writeQuery<GetGroupsByOrganizationId>({
          query: GET_GROUPS_BY_ORGANIZATION_ID_QUERY,
          data: {
            groups: [...groups.groups, data?.createGroup],
          },
          variables: {
            organizationId,
          },
        });
      }
    },
  });
