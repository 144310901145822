import { gql, MutationHookOptions } from '@apollo/client';
import { PhoneNumberFragment } from '../../../fragments/PhoneNumber';
import { CurrentUserFragment } from '../../../fragments/CurrentUserFragment';
import { useMutation } from '../../../hooks/useMutation';
import { UpdatePersonalDetails, UpdatePersonalDetailsVariables } from './__generated__/UpdatePersonalDetails';

export const UPDATE_PERSONAL_DETAILS = gql`
  mutation UpdatePersonalDetails($input: UpdatePersonalDetailsInput!) {
    updatePersonalDetails(input: $input) {
      ... on CurrentUser {
        ...CurrentUser
        birthday
        phoneNumber {
          ...PhoneNumber
        }
      }
    }
  }
  ${PhoneNumberFragment}
  ${CurrentUserFragment}
`;

export const useUpdateAccount = (
  options?: MutationHookOptions<UpdatePersonalDetails, UpdatePersonalDetailsVariables>
) => useMutation<UpdatePersonalDetails, UpdatePersonalDetailsVariables>(UPDATE_PERSONAL_DETAILS, options);
