import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { useColors } from '../../../../shared/Ui/hooks';
import { AppText, Icon } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';

interface AssociationItem {
  id: string;
  name: string;
}

interface AssociationPillProps {
  associations: AssociationItem[];
  onRemoveAssociation: (associationId: string) => void;
}

export const AssociationPills: React.FC<AssociationPillProps> = ({ associations, onRemoveAssociation }) => {
  const colors = useColors();
  const { translate } = useTranslations();

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
      flexWrap: 'wrap',
    },
    pill: {
      marginRight: 10,
      paddingVertical: 5,
      paddingRight: 20,
      marginVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 99,
      backgroundColor: colors.lime,
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '90%',
    },
    label: {},
    icon: {
      marginLeft: 8,
      position: 'absolute',
      right: 5,
    },
  });

  return (
    <View style={styles.container}>
      {associations?.map((association) => (
        <View key={association.id} style={[styles.pill, { backgroundColor: colors.lime }]}>
          <AppText style={styles.label} font="labelDefault" color="white">
            {association.name}
          </AppText>

          <TouchableOpacity style={styles.icon} onPress={() => onRemoveAssociation(association.id)}>
            <Icon size={14} name="x" color="white" />
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};
