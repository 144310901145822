import React from 'react';
import { StyleSheet } from 'react-native';
import { GoogleAutoComplete, GoogleAutoCompleteProps } from 'react-native-google-autocomplete';
import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service';
import { useTranslations } from '../../i18n/useTranslations';
import { useColors } from '../../shared/Ui/hooks/useColors';
import { Autocomplete } from '../Autocomplete/Autocomplete';

export interface PlaceAutocompleteProps {
  apiKey: string;
  open: boolean;
  onClose?: () => void;
  onPlaceSelect?: (result: GoogleLocationDetailResult) => void;
}

export const PlaceAutocompleteModal: React.FC<PlaceAutocompleteProps> = ({ apiKey, open, onClose, onPlaceSelect }) => {
  const { translate } = useTranslations();
  const colors = useColors();

  const styles = StyleSheet.create({
    header: {
      backgroundColor: colors.green,
      paddingVertical: 10,
      paddingHorizontal: 15,
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '88%',
    },
    searchInput: {},
    close: {
      marginLeft: 10,
      color: colors.white,
    },
    container: {
      backgroundColor: colors.green,
      flex: 1,
    },
  });

  const createSelectHandler =
    (fetchDetails: (placeId: string) => Promise<GoogleLocationDetailResult>) => (placeId: string) => {
      if (onPlaceSelect) {
        fetchDetails(placeId).then(onPlaceSelect);
      }
    };

  const children = ({ inputValue, handleTextChange, locationResults, fetchDetails }: GoogleAutoCompleteProps) => {
    console.log(inputValue, locationResults);
    return (
      <Autocomplete<string>
        items={locationResults.map((value) => ({
          key: value.id,
          label: value.description,
          value: value.id,
        }))}
        query={inputValue}
        onQueryChange={handleTextChange}
        onSelect={createSelectHandler(fetchDetails)}
        queryPlaceholder={translate.placeholder.searchPlace}
        onClear={onClose}
      />
    );
  };
  return (
    <GoogleAutoComplete apiKey={apiKey} debounce={300} minLength={2} queryTypes={'(cities)'} children={children} />
  );
};
