/* Single tile
=============================== */

import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileRecoveryPlanProps {
  onPress: () => void;
  disabled?: boolean;
}

export const TileRecoveryPlan: React.FC<TileRecoveryPlanProps> = ({ onPress, disabled = false }) => {
  const { translate } = useTranslations();

  return (
    <BaseTile backgroundColor="orange" labelColor="white" label={translate.home.tile.recoveryPlan} onPress={onPress} />
  );
};
