import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment User on User {
    id
    fullName
    firstName
    lastName
    email
    birthday
  }
`;
