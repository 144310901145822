import { ShowNotificationParams } from './Notification.interface';
import { Notifier } from 'react-native-notifier';
import { Toast } from './Toast/Toast';
import { Platform } from 'react-native';

// this was commented out because it creates a react-native-reanimated error that I wasn't able to fix, as of reanimated 2.8.
// this means none of our success notifications that appear after actions will appear, but those actions still work

// export const useNotification =
//   () =>
//   ({ image, queueMode = 'next', alertType = 'success', ...otherProps }: ShowNotificationParams) => {
//     Notifier.showNotification({
//       ...otherProps,
//       swipeEnabled: !(Platform.OS === 'web'),
//       queueMode,
//       Component: Toast(Notifier.hideNotification),
//       componentProps: {
//         image,
//       },
//     });
//   };

export const useNotification =
  () =>
  ({ image, queueMode = 'next', alertType = 'success', ...otherProps }: ShowNotificationParams) => {
    return null;
  };
