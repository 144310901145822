import { useFormikContext } from 'formik';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { CreateOrganizationFormData } from './CreateOrganization.interface';
import {
  OrganizationProtocolPreset,
  OrganizationSport,
  OrganizationType,
  UserLanguage,
} from '../../../../types/globalTypes';
import { AppText, AppButton, Input, InputLabel, Select, Check, AppModal } from '../../../../uiComponents';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { useTranslations, languageList } from '../../../../i18n/useTranslations';
import { useNavigation } from '@react-navigation/native';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { useGetAssociations } from '../../hooks/useGetAssociations';
import { AssociationPills } from './AssociationPills';

export interface FieldsProps {
  creationCompleted: boolean;
  setCreationCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Fields: React.FC<FieldsProps> = ({ creationCompleted, setCreationCompleted }) => {
  const { errors, values, touched, handleChange, handleBlur, isSubmitting, isValid, submitForm, setFieldValue } =
    useFormikContext<CreateOrganizationFormData>();

  const isMobile = useIsMobile();
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_CreateOrganization'>>();

  const organizationSports = Object.values(OrganizationSport).sort();

  const { data: allAssociations } = useGetAssociations();

  const styles = StyleSheet.create({
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    input: {
      width: !isMobile ? '49%' : '100%',
    },
    pdpCheckboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 15,
    },
    pdpCheckbox: {
      marginRight: 38,
    },
    pdpCheckboxText: {
      width: '80%',
    },
    button: {
      marginBottom: 20
    },
    associationSelect: {
      width: '100%',
    },
  });

  const languages = languageList.map((language) => ({
    value: language.locale,
    label: translate.language[language.name],
    key: language.locale,
  }));

  const handleAccept = () => {
    setCreationCompleted(false);
    navigation.navigate('LoggedIn_Home', { prevScreen: 'LoggedIn_SuperAdmin_CreateOrganization' });
  };

  const snakeToCamel = (str) =>
    str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

  const getAssociationsToSelect = () => {
    return allAssociations
      ? [{ key: '-1', value: '0', label: translate.superAdmin.createOrganization.selectAssociation }].concat(
          allAssociations.associations
            .map((association) => ({
              key: association.id,
              label: association.name,
              value: association.id,
            }))
            .filter((association) => !values.associationIds.includes(association.value))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      : [];
  };

  return (
    <>
      {/* TODO: replace the modal with the popup from useNotification once that has been fixed */}
      <AppModal
        mode="dialog"
        title={translate.superAdmin.createOrganization.modalTitle}
        visible={creationCompleted}
        onDismiss={() => setCreationCompleted(false)}
        children={
          <AppText>
            {translateWithVars(translate.superAdmin.createOrganization.successfullyCreated, {
              organization: values.name,
            })}
          </AppText>
        }
        buttons={{
          ok: <AppButton onPress={handleAccept}>{translate.admin.manageOrganization.ok}</AppButton>,
        }}
      />
      <Input
        key="name"
        value={values.name}
        type="text"
        label={translate.admin.manageOrganization.organizationNameInputLabel}
        returnKeyType="go"
        onChangeText={handleChange('name')}
        onBlur={handleBlur('name')}
        touched={touched.name}
        error={touched.name ? errors.name : undefined}
      />
      <View style={styles.row}>
        <View style={styles.input}>
          <InputLabel label={translate.superAdmin.createOrganization.protocolPreset} />
          <Select<OrganizationProtocolPreset>
            onChange={handleChange('preset')}
            onBlur={handleBlur('preset')}
            value={values.preset}
            key="preset"
            items={[
              {
                key: OrganizationProtocolPreset.BLANK,
                label: translate.superAdmin.createOrganization.protocolPresets.blank,
                value: OrganizationProtocolPreset.BLANK,
              },
              {
                key: OrganizationProtocolPreset.ONTARIO_SOCCER,
                label: translate.superAdmin.createOrganization.protocolPresets.ontarioSoccerAssociation,
                value: OrganizationProtocolPreset.ONTARIO_SOCCER,
              },
              {
                key: OrganizationProtocolPreset.ONTARIO_HOCKEY_ASSOCIATION,
                label: translate.superAdmin.createOrganization.protocolPresets.ontarioHockeyAssociation,
                value: OrganizationProtocolPreset.ONTARIO_HOCKEY_ASSOCIATION,
              },
              {
                key: OrganizationProtocolPreset.PARACHUTE_HOCKEY,
                label: translate.superAdmin.createOrganization.protocolPresets.parachuteSmartHockey,
                value: OrganizationProtocolPreset.PARACHUTE_HOCKEY,
              },
              {
                key: OrganizationProtocolPreset.ONTARIO_SCHOOL,
                label: translate.superAdmin.createOrganization.protocolPresets.ontarioProtocolForSchools,
                value: OrganizationProtocolPreset.ONTARIO_SCHOOL,
              },
              {
                key: OrganizationProtocolPreset.PARACHUTE_SCHOOL,
                label: translate.superAdmin.createOrganization.protocolPresets.parachuteProtocolForSchools,
                value: OrganizationProtocolPreset.PARACHUTE_SCHOOL,
              },
            ]}
            error={errors.preset}
            touched={touched.preset}
          />
        </View>
        <View style={styles.input}>
          <InputLabel label={translate.superAdmin.createOrganization.organizationType} />
          <Select<OrganizationType>
            onChange={handleChange('type')}
            onBlur={handleBlur('type')}
            value={values.type}
            items={[
              {
                key: OrganizationType.SPORTS_ORGANIZATION,
                label: translate.superAdmin.createOrganization.organizationTypes.sportsOrganization,
                value: OrganizationType.SPORTS_ORGANIZATION,
              },
              {
                key: OrganizationType.SCHOOL,
                label: translate.superAdmin.createOrganization.organizationTypes.school,
                value: OrganizationType.SCHOOL,
              },
            ]}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.input}>
          <InputLabel label={translate.admin.manageOrganization.sportLabel} />
          <Select<OrganizationSport>
            onChange={handleChange('sport')}
            onBlur={handleBlur('sport')}
            value={values.sport}
            items={organizationSports.map((sport) => ({
              key: OrganizationSport[sport],
              label: translate.superAdmin.createOrganization.sports[snakeToCamel(sport)],
              value: OrganizationSport[sport],
            }))}
          />
        </View>
        <View style={styles.input}>
          <InputLabel label={translate.account.yourDetails.language} />
          <Select<UserLanguage>
            onChange={handleChange('language')}
            onBlur={handleBlur('language')}
            value={values.language}
            key="language"
            items={languages}
          />
        </View>
      </View>
      {allAssociations ? (
        <View>
          <AppText>{translate.superAdmin.createOrganization.associationsHeader}</AppText>
          <Select<string>
            style={styles.associationSelect}
            onChange={(e) => {
              setFieldValue('associationIds', [...values.associationIds, e]);
            }}
            value=""
            error=""
            items={getAssociationsToSelect()}
          />
          <AssociationPills
            associations={allAssociations.associations.filter((association) =>
              values.associationIds.includes(association.id)
            )}
            onRemoveAssociation={(associationId) =>
              setFieldValue(
                'associationIds',
                values.associationIds.filter((id) => id !== associationId)
              )
            }
          />
        </View>
      ) : null}

      <AppText font="header3" style={{ marginBottom: 10 }}>
        {translate.superAdmin.createOrganization.createAdmin}
      </AppText>
      <View style={styles.row}>
        <View style={styles.input}>
          <Input
            key="admin-first-name"
            icon="user"
            value={values.admin.firstName}
            type="text"
            label={translate.placeholder.firstName}
            returnKeyType="go"
            onChangeText={handleChange('admin.firstName')}
            onBlur={handleBlur('admin.firstName')}
            error={errors.admin?.firstName}
            touched={touched.admin?.firstName}
          />
        </View>
        <View style={styles.input}>
          <Input
            key="admin-last-name"
            icon="user"
            value={values.admin.lastName}
            type="text"
            label={translate.placeholder.lastName}
            returnKeyType="go"
            onChangeText={handleChange('admin.lastName')}
            onBlur={handleBlur('admin.lastName')}
            error={errors.admin?.lastName}
            touched={touched.admin?.lastName}
          />
        </View>
      </View>
      <Input
        icon="mail"
        value={values.admin.email}
        type="email"
        label={translate.account.yourDetails.email}
        returnKeyType="go"
        onChangeText={handleChange('admin.email')}
        onBlur={handleBlur('admin.email')}
        error={errors.admin?.email}
        touched={touched.admin?.email}
      />
      <View style={styles.pdpCheckboxContainer}>
        <View style={styles.pdpCheckbox}>
          <Check
            checked={values.isPrimaryDesignatedPerson}
            onPress={() => setFieldValue('isPrimaryDesignatedPerson', !values.isPrimaryDesignatedPerson)}
          />
        </View>
        <AppText style={styles.pdpCheckboxText}>{translate.superAdmin.createOrganization.isPDP}</AppText>
      </View>
      <AppButton disabled={isSubmitting || !isValid} loading={isSubmitting} onPress={submitForm} style={styles.button}>
        {translate.superAdmin.createOrganization.title}
      </AppButton>
    </>
  );
};
