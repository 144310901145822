import * as React from 'react';
import { Image, ImageProps } from 'react-native';
import { isArray, isNumber } from 'lodash';
import { useHostUriPatch } from '../../hooks/useHostUriPatch';

interface AppImageProps extends ImageProps {}

export const AppImage: React.FC<AppImageProps> = (props) => {
  const patchUri = useHostUriPatch();

  if (props.source) {
    if (!isNumber(props.source) && !isArray(props.source)) {
      props.source.uri = props.source.uri ? patchUri(props.source.uri) : undefined;
    }
  }

  return <Image {...props} />;
};
