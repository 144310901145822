import React from 'react';
import Markdown from 'react-native-markdown-display';
// import Markdown from '@jonasmerlin/react-native-markdown-display'; // this is a fork of this library because the official npm one hasn't been updated in 2+ years. Hopefully these will be merged together at some point.
import * as WebBrowser from 'expo-web-browser';
import { ViewStyle } from 'react-native';
import { isString } from 'lodash';
import { ColorKeys, useColors } from '../../shared/Ui';
import { fonts } from '../../shared/Ui/defaultStyleConfig';

interface AppMarkdownProps {
  style?: ViewStyle;
  headingColor?: ColorKeys;
  textColor?: ColorKeys;
  linkColor?: ColorKeys;
  disableLinks?: boolean;
  children: React.ReactNode;
}

export const AppMarkdown: React.FC<AppMarkdownProps> = ({
  children,
  headingColor,
  textColor,
  linkColor,
  style,
  disableLinks,
}) => {
  const colors = useColors();

  const handleLinkPress = (url: string) => {
    if (disableLinks) {
      return false;
    }
    WebBrowser.openBrowserAsync(url, {
      showTitle: true,
    });
    return false;
  };
  const defaultHeadingColor = headingColor ? colors[headingColor] : colors.navyDark;
  const defaultTextColor = textColor ? colors[textColor] : colors.grayDark;
  const defaultLinkColor = linkColor ? colors[linkColor] : colors.blue;

  if (!isString(children) || !children) {
    return null;
  }

  const defaultFontStyle = {
    ...fonts.bodyMedium,
    color: defaultTextColor,
  };

  return (
    <Markdown
      style={{
        body: { ...defaultFontStyle, ...style },
        heading1: {
          ...fonts.header1,
          color: defaultHeadingColor,
        },
        heading2: {
          ...fonts.header2,
          color: defaultHeadingColor,
        },
        heading3: {
          ...fonts.header3,
          color: defaultHeadingColor,
        },
        heading4: {
          ...fonts.header4,
          color: defaultHeadingColor,
        },
        heading5: {
          ...fonts.header5,
          color: defaultHeadingColor,
        },
        heading6: {
          ...fonts.header6,
          color: defaultHeadingColor,
        },
        hr: {
          color: colors.navyDark,
          marginVertical: 10,
        },
        strong: {
          fontFamily: 'Montserrat_700Bold',
          color: defaultTextColor,
        },
        em: defaultFontStyle,
        s: defaultFontStyle,
        blockquote: defaultFontStyle,
        bullet_list: defaultFontStyle,
        ordered_list: defaultFontStyle,
        list_item: defaultFontStyle,
        code_inline: {},
        code_block: {},
        fence: {},
        table: {},
        thead: {},
        tbody: {},
        th: {},
        tr: {},
        td: {},
        link: {
          ...defaultFontStyle,
          color: defaultLinkColor,
        },
        blocklink: {
          ...defaultFontStyle,
          color: defaultLinkColor,
        },
        image: {},
        text: {},
        textgroup: {},
        paragraph: {},
        hardbreak: {},
        softbreak: {},
        pre: {},
        inline: {},
        span: defaultFontStyle,
      }}
      onLinkPress={handleLinkPress}
      // @ts-ignore
      children={children}
    />
  );
};
