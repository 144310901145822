import React from 'react';
import { StyleSheet, View } from 'react-native';
import { CaseLog } from './CaseLog';
import { Content as CircleOfCare } from '../screens/CircleOfCare/Content';
import { ProfileDetails } from './ProfileDetails';
import { useGetProfileOverviewData } from '../screens/Overview/hooks/useGetProfileOverview';
import { useCaseLogs } from '../screens/CaseLogs/hooks/useCaseLogsQuery';
import { useGetCircleOfCare } from '../ProfileChrome//hooks/useGetCircleOfCare';
import { useGetProfile } from './hooks/useGetProfile';
import { ActivityIndicator } from '../../../uiComponents';
import { useCurrentUser } from '../../../shared/CurrentUser/useCurrentUser';

interface ContentProps {}

export const Content: React.FC<ContentProps> = () => {
  const styles = StyleSheet.create({
    container: {
      paddingBottom: 20,
    },
  });

  const profile = useGetProfileOverviewData();
  const logs = useCaseLogs();
  const profileDetails = useGetProfile();
  const { currentUser } = useCurrentUser();

  const { data: circleOfCare } = useGetCircleOfCare(profileDetails ? profileDetails.id : '');

  if (!logs || !profile || !profileDetails || !circleOfCare || !currentUser) return <ActivityIndicator />;
  if (circleOfCare.circleOfCare.__typename !== 'CircleOfCare') return null;

  return (
    <View style={styles.container}>
      <CaseLog subjectId={profile.navigationParams.subjectId} logs={logs ? logs : []} />
      <ProfileDetails
        profile={profile}
        profileDetails={profileDetails ? profileDetails : null}
        guardians={circleOfCare.circleOfCare.guardians}
      />
      <CircleOfCare circleOfCare={circleOfCare} />
    </View>
  );
};
