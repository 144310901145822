import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import {
  GetGroupsByOrganizationId,
  GetGroupsByOrganizationIdVariables,
} from './__generated__/GetGroupsByOrganizationId';
import { GroupFragment } from '../fragments/GroupFragment';

export const GET_GROUPS_BY_ORGANIZATION_ID_QUERY = gql`
  query GetGroupsByOrganizationId($organizationId: ID!) {
    groups(organizationId: $organizationId) {
      ... on Group {
        ...Group
      }
    }
  }
  ${GroupFragment}
`;

export const useGetGroupsByOrganizationId = (organizationId: string) =>
  useQuery<GetGroupsByOrganizationId, GetGroupsByOrganizationIdVariables>(GET_GROUPS_BY_ORGANIZATION_ID_QUERY, {
    hideLoading: true,
    variables: {
      organizationId,
    },
  });
