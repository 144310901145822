import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { CasesParamList } from './Cases.interface';
import { useCasesRoutes } from './hooks/useCasesRoutes';

const CasesNav = createStackNavigator<CasesParamList>();
const routes = useCasesRoutes();

export const CasesNavigator: React.FC<{}> = () => {
  return (
    <CasesNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <CasesNav.Screen key={name} name={name} component={component} />
      ))}
    </CasesNav.Navigator>
  );
};
