import React from 'react';
import { View, StyleSheet } from 'react-native';
import { AppScrollView, AppScrollViewProps } from '../../uiComponents';
import { useAppUiConfigContext } from '../Ui/AppUiConfigContext';

export interface MaybeScrollProps extends AppScrollViewProps {
  autoScroll?: boolean;
}

export const MaybeScroll: React.FC<MaybeScrollProps> = ({ autoScroll = true, children, ...rest }) => {
  const maxContentWidth = useAppUiConfigContext().styleConfig.maxContentWidth;

  const styles = StyleSheet.create({
    constrainMaxWidth: {
      maxWidth: maxContentWidth,
      width: '100%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 0,
    },
  });
  if (autoScroll) {
    return (
      <AppScrollView {...rest}>
        <View style={styles.constrainMaxWidth}>{children}</View>
      </AppScrollView>
    );
  } else {
    return (
      <View style={rest.style}>
        <View style={styles.constrainMaxWidth}>{children}</View>
      </View>
    );
  }
};
