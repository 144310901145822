import {
  DocumentNode,
  OperationVariables,
  MutationHookOptions,
  TypedDocumentNode,
  useMutation as useMutationBase,
  MutationTuple,
} from '@apollo/client';
import { first, isObject } from 'lodash/fp';
import { useEffect } from 'react';
import { useTranslations } from '../i18n/useTranslations';
import { useApiErrorExtractor } from './useApiErrorExtractor';
import { useAppAlert } from './ui/useAppAlert';
import { useLoading } from '../uiComponents/Loading/useLoading';

export interface UseMutationOptions<TData, TVariables> extends MutationHookOptions<TData, TVariables> {
  hideLoading?: boolean;
  hideAlerts?: boolean;
}

export const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: UseMutationOptions<TData, TVariables> = {}
): MutationTuple<TData, TVariables> => {
  const { hideLoading = true, hideAlerts, ...restOptions } = options;

  const [patchedMutation, { loading, error, data, ...rest }] = useMutationBase<TData, TVariables>(
    mutation,
    restOptions
  );

  const { translate } = useTranslations();
  const errorExtractor = useApiErrorExtractor();
  const firstError = isObject(data) ? first(errorExtractor(data as Record<string, unknown>)) : undefined;
  const { alert } = useAppAlert();

  useLoading(!hideLoading && loading);

  useEffect(() => {
    if (!hideAlerts && firstError) {
      alert(translate.error.title.generic, firstError.code);
    }
  }, [hideAlerts, firstError]);

  return [patchedMutation, { loading, error, data, ...rest }];
};
