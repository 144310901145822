import * as React from 'react';
import { useFormikContext } from 'formik';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AppButton, AppLink, Input } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { RootNavType } from '../../../RootNavigator.interface';
import { LoginFormValues } from './LoginForm.interface';

export interface LoginFieldsProps {
  disableEmail?: boolean;
}

export const LoginFields: React.FC<LoginFieldsProps> = ({ disableEmail }) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RootNavType<never>>();

  const { handleChange, handleBlur, handleSubmit, isValid, values, errors, touched } =
    useFormikContext<LoginFormValues>();

  const styles = StyleSheet.create({
    buttons: {
      marginTop: 30,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    forgot: {
      marginTop: 20,
    },
    link: {
      textAlign: 'left',
    },
  });

  const onForgotPassword = () => navigation.navigate('ResetPassword');

  return (
    <>
      <Input
        key="email"
        icon="mail"
        disabled={disableEmail}
        value={values.email}
        type="email"
        placeholder={translate.placeholder.email}
        returnKeyType="go"
        onChangeText={handleChange('email')}
        onBlur={handleBlur('email')}
        touched={touched.email}
        error={touched.email ? errors.email : undefined}
      />
      <Input
        icon="lock"
        value={values.password}
        type="password"
        placeholder={translate.placeholder.password}
        returnKeyType="go"
        onChangeText={handleChange('password')}
        touched={touched.password}
        onBlur={handleBlur('password')}
        error={touched.email ? errors.password : undefined}
      />
      <View style={styles.forgot}>
        <AppLink style={styles.link} onPress={onForgotPassword}>
          {translate.auth.term.forgotPassword}
        </AppLink>
      </View>
      <View style={styles.buttons}>
        <AppButton onPress={handleSubmit} disabled={!isValid}>
          {translate.auth.term.signIn}
        </AppButton>
      </View>
    </>
  );
};
