import { gql } from '@apollo/client';
import { AppSettingsFragment } from '../../fragments/AppSettingsFragment';

export const APP_SETTINGS_QUERY = gql`
  query AppSettingsQuery {
    appSettings {
      ...AppSettings
    }
  }
  ${AppSettingsFragment}
`;
