import { SymptomResult } from '../../../../../types/globalTypes';
import {
  AssessActionSetSymptomStepViewedPayload,
  AssessContextState,
  AssessStepValueSymptom,
} from '../../../Assess.interface';

export const setSymptomStepViewed = (
  nextState: AssessContextState,
  { stepId }: AssessActionSetSymptomStepViewedPayload
): AssessContextState => {
  return {
    ...nextState,
    steps: nextState.steps.map((step) => {
      const foundStepConfig = nextState.selectedProtocolOption?.protocol.removeProtocol.steps.find(
        ({ id }) => id === step.stepId
      );
      if (
        step.stepId === stepId &&
        step.type === 'symptom' &&
        foundStepConfig &&
        foundStepConfig.__typename === 'StepSymptom'
      ) {
        const result: AssessStepValueSymptom = {
          ...step,
          viewed: true,
          result: foundStepConfig.symptoms.map(({ id }) => ({ symptomId: id, result: SymptomResult.ABSENT })),
        };
        return result;
      }
      return step;
    }),
  };
};
