import { gql } from '@apollo/client';

export const RecoveryPlanStageSignOffFragment = gql`
  fragment RecoveryPlanStageSignOff on RecoveryPlanStageSignOff {
    id
    order
    caseId
    stepStartTime
    organizationName
    protocolType
    protocolName
    protocolUrl
    state
    iCanSignoff
    name
    description
    isFirstStage
    isLastStage
    isCurrentStage
    isUpcomingStage
    isCompletedStage
    timeGateHours
  }
`;
