import React, { useEffect } from 'react';
import { useCaseLog } from '../screens/CaseLogs/hooks/useCaseLogQuery';
import { StyleSheet, View, Platform, UIManager, LayoutAnimation } from 'react-native';
import { useColors, useShadow } from '../../../shared/Ui';
import { AppText, AppButton, ActivityIndicator } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../../LoggedIn.interface';
import { useDateFormat } from '../../../hooks/ui/useDateFormat';

interface ClosedCaseItemProps {
  caseId: string;
  subjectId: string | undefined;
  canViewCaseLog: boolean;
}

export const ClosedCaseItem: React.FC<ClosedCaseItemProps> = ({ caseId, subjectId, canViewCaseLog }) => {
  const log = useCaseLog(caseId);
  const shadow = useShadow();
  const colors = useColors();
  const { translate } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Profile'>>();

  if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
  }

  const { formatDate } = useDateFormat();

  useEffect(() => {
    LayoutAnimation.configureNext({
      duration: 200,
      create: { type: 'linear', property: 'opacity' },
      update: { type: 'linear' },
      delete: { type: 'linear', property: 'opacity' },
    });
  }, []);

  const styles = StyleSheet.create({
    container: {
      ...shadow,
      backgroundColor: colors.white,
      maxHeight: 150,
      width: 320,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: 10,
      marginTop: 15,
      marginRight: 25,
      borderRadius: 8,
      marginLeft: 20,
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      height: '100%',
    },
    viewBtn: {
      marginTop: 20,
    },
    activityIndicator: {
      height: 150,
      width: 300,
    },
  });

  const handleViewDetails = () => {
    if (subjectId) {
      navigation.navigate('LoggedIn_Profile', {
        subjectId,
        screen: 'LoggedIn_Profile_Logs',
        params: { caseId: log?.kase.id },
      });
    } else {
      navigation.navigate('LoggedIn_Profile', {
        screen: 'LoggedIn_Profile_Logs',
        params: { caseId: log?.kase.id },
      });
    }
  };

  return (
    <View style={styles.container}>
      <AppText color="navyDark" font="labelDefault">
        {translate.caseLog.closed}
      </AppText>
      {log ? (
        <View style={styles.rightSide}>
          {log.kase.items && log.kase.items[0]?.createdDate ? (
            <AppText font="labelDefault" color="grayDark">
              {`${formatDate(new Date(log.kase.items[0]?.createdDate), 'yyyy/MM/dd')} - ${formatDate(
                new Date(log.kase.items[log?.kase.items.length - 1].createdDate),
                'yyyy/MM/dd'
              )}`}
            </AppText>
          ) : null}
          <View style={styles.viewBtn}>
            {canViewCaseLog ? <AppButton onPress={handleViewDetails}>{translate.label.view}</AppButton> : null}
          </View>
        </View>
      ) : (
        <ActivityIndicator style={styles.activityIndicator} />
      )}
    </View>
  );
};
