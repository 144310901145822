import { PasswordHintsRule } from '../uiComponents/PasswordHints/PasswordHints';
import { useTranslations } from '../i18n/useTranslations';

export const usePasswordRules: () => PasswordHintsRule[] = () => {
  const { translate, translateWithVars } = useTranslations();
  return [
    {
      label: translateWithVars(translate.validation.password.uppercase, {
        num: 1,
      }),
      check: (t: string) => /[A-Z]/.test(t),
    },
    {
      label: translateWithVars(translate.validation.password.lowercase, {
        num: 1,
      }),
      check: (t: string) => /[a-z]/.test(t),
    },
    {
      label: translateWithVars(translate.validation.password.number, {
        num: 1,
      }),
      check: (t: string) => /[0-9]/.test(t),
    },
    {
      label: translateWithVars(translate.validation.password.symbol, {
        num: 1,
      }),
      check: (t: string) => /[^A-Za-z0-9]/.test(t),
    },
    {
      label: translateWithVars(translate.validation.password.length, {
        num: 6,
      }),
      check: (t: string) => t.length >= 6,
    },
  ];
};
