import * as React from 'react';
import { View } from 'react-native';
import { ActionListItem } from './ActionListItem';
import { useTranslations } from '../../../i18n/useTranslations';
import { useNavigation } from '@react-navigation/core';
import { LoggedInNavType } from '../../LoggedIn.interface';
import { AppButton, AppModal, Checkbox, MarkdownCheckboxOption } from '../../../uiComponents';
import { useCurrentProtocol } from '../../Admin/screens/ManageProtocol/hooks/useCurrentProtocol';
import { useResubmitEducationItems } from '../hooks/useResubmitEducationItems';
import { EducationItemResubmissionActionItem } from '../hooks/__generated__/EducationItemResubmissionActionItem';

export interface PendingEducationItemResubmissionProps {
  item: EducationItemResubmissionActionItem;
}

export const PendingEducationItemResubmission: React.FC<PendingEducationItemResubmissionProps> = ({ item }) => {
  const { translateWithVars, translate } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ActionItems'>>();
  const [showEducationItemsModal, setShowEducationItemsModal] = React.useState<boolean>(false);
  const [educationItemsAccepted, setEducationItemsAccepted] = React.useState<string[]>([]);

  const handlePress = () => {
    setShowEducationItemsModal(true);
  };

  const { data } = useCurrentProtocol({ variables: { organizationId: item.id } });

  const educationItems =
    data && data.currentProtocolByOrganization.__typename === 'Protocol'
      ? data.currentProtocolByOrganization.educationProtocol.items
      : null;

  const visibleEducationItems = educationItems
    ? educationItems.filter((el) => item.educationItemIds.includes(el.id))
    : [];

  const [resubmitEducationItems] = useResubmitEducationItems(item.id, {
    variables: {
      organizationId: item.id,
      educationItemsAccepted,
    },
    onCompleted: () => {
      navigation.navigate('LoggedIn_Home');
    },
  });

  const handleSubmit = () => {
    resubmitEducationItems();
  };

  return (
    <>
      <AppModal
        title={translate.actionItems.pendingEducationItemResubmission.modalMessage}
        mode="full-screen"
        visible={showEducationItemsModal}
        onDismiss={() => setShowEducationItemsModal(false)}
      >
        {educationItems ? (
          <View>
            <Checkbox<string>
              value={educationItemsAccepted}
              onSelect={(val) => setEducationItemsAccepted(val)}
              options={visibleEducationItems?.map((item) => ({
                label: item.instructionMarkdown || '',
                value: item.id,
              }))}
              customComponent={MarkdownCheckboxOption}
            />

            <AppButton disabled={educationItemsAccepted.length !== visibleEducationItems.length} onPress={handleSubmit}>
              {translate.label.done}
            </AppButton>
          </View>
        ) : null}
      </AppModal>
      <ActionListItem
        onPress={handlePress}
        text={translateWithVars(translate.actionItems.pendingEducationItemResubmission.message, {
          organization: item.organizationName,
        })}
      />
    </>
  );
};
