import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { DeleteSeason, DeleteSeasonVariables } from './__generated__/DeleteSeason';
import { GET_SEASONS_BY_ORGANIZATION_ID_QUERY } from './useGetSeasonsByOrganizationId';
import { GetSeasonsByOrganizationId } from './__generated__/GetSeasonsByOrganizationId';

export const DELETE_SEASON_MUTATION = gql`
  mutation DeleteSeason($organizationId: ID!, $seasonId: ID!) {
    deleteSeason(organizationId: $organizationId, seasonId: $seasonId) {
      ... on Season {
        id
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useDeleteSeason = (
  organizationId: string,
  options?: MutationHookOptions<DeleteSeason, DeleteSeasonVariables>
) => {
  return useMutation<DeleteSeason, DeleteSeasonVariables>(DELETE_SEASON_MUTATION, {
    ...options,
    update(cache, { data }) {
      const cachedSeasons = cache.readQuery<GetSeasonsByOrganizationId>({
        query: GET_SEASONS_BY_ORGANIZATION_ID_QUERY,
        variables: {
          organizationId,
        },
      });
      if (cachedSeasons && data) {
        cache.writeQuery<GetSeasonsByOrganizationId>({
          query: GET_SEASONS_BY_ORGANIZATION_ID_QUERY,
          variables: {
            organizationId,
          },
          data: {
            seasons: cachedSeasons.seasons.filter(
              (season) => season.id !== (data.deleteSeason.__typename === 'Season' ? data.deleteSeason.id : '')
            ),
          },
        });
      }
    },
  });
};
