import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { GetOrganization, GetOrganizationVariables } from './__generated__/GetOrganization';
import { OrganizationFragment } from '../../../fragments/OrganizationFragment';

const GET_ORGANIZATION = gql`
  query GetOrganization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      ... on Organization {
        ...Organization
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${OrganizationFragment}
  ${ApiErrorFragment}
`;

export const useGetOrganization = (options?: UseQueryOptions<GetOrganization, GetOrganizationVariables>) =>
  useQuery(GET_ORGANIZATION, options);
