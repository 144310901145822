import { ErrorCode } from '../types/globalTypes';
import { I18n } from 'i18n-js';
import { TranslateOptions } from 'i18n-js/typings';
import { errorCodeTranslations } from './errorCodes';
import { getLocale } from './getLocale';

const i18n = new I18n(errorCodeTranslations);

export const translateErrorCode = (locale: string) => (errorCode: ErrorCode, config?: TranslateOptions) => {
  i18n.translations = errorCodeTranslations;

  i18n.locale = getLocale(locale);

  return i18n.translate(errorCode, config);
};
