import React, { useState } from 'react';
import { View, StyleSheet, ViewStyle, StyleProp, TouchableOpacity } from 'react-native';
import { useStatusColor } from './useStatusColor';
import { useStatusLabel } from './useStatusLabel';
import { Dot } from './Dot';
import { SubjectMemberships } from '../../fragments/__generated__/SubjectMemberships';
import { useTranslations } from '../../i18n/useTranslations';
import { AppModal } from '../AppModal/AppModal';
import { Spacer, SpacerItems } from '../Spacer/Spacer';
import { AppText } from '../AppText/AppText';

export interface SubjectComplexStatusIconProps {
  subjectMemberships: SubjectMemberships;
  style?: StyleProp<ViewStyle>;
}

export const SubjectComplexStatusIcon: React.FC<SubjectComplexStatusIconProps> = ({ style, subjectMemberships }) => {
  const [modal, setModal] = useState(false);
  const { translate, translateWithVars } = useTranslations();
  const getLabel = useStatusLabel();
  const getColor = useStatusColor();

  const styles = StyleSheet.create({
    itemIndicatorContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemIndicatorLabel: {
      color: getColor('complex'),
    },
    itemMultiple: {
      marginRight: -4,
    },
    itemMultipleLast: {
      marginRight: 0,
    },
    modalItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    statusList: {
      alignItems: 'center',
      marginBottom: 20,
    },
  });

  const handleOpenModal = () => setModal(true);
  const handleCloseModal = () => setModal(false);

  const items: SpacerItems = subjectMemberships.organizations.reduce((accum, { id, organization, status }) => {
    accum[id] = (
      <View style={styles.modalItem}>
        <AppText color="grayDark">
          {translateWithVars(translate.subjectStatus.organizationStatusMessage, {
            organization: organization.name,
            status: getLabel(status),
          })}
        </AppText>
      </View>
    );

    return accum;
  }, {});
  return (
    <>
      <AppModal onDismiss={handleCloseModal} title={translate.subjectStatus.modalTitle} visible={modal} mode="dialog">
        <Spacer size="large" style={styles.statusList} items={items} />
      </AppModal>
      <TouchableOpacity onPress={handleOpenModal}>
        <View style={StyleSheet.flatten([styles.itemIndicatorContainer, style])}>
          {subjectMemberships.organizations.map(({ status, id }, index) => {
            const dotStyle = index !== subjectMemberships.organizations.length - 1 ? styles.itemMultiple : null;
            return <Dot key={id} status={status} style={dotStyle} />;
          })}
          <AppText style={styles.itemIndicatorLabel}>{translate.subjectStatus.complex}</AppText>
        </View>
      </TouchableOpacity>
    </>
  );
};
