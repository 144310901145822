import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { GetTrainingReport, GetTrainingReportVariables } from './__generated__/GetTrainingReport';

export const GET_TRAINING_REPORT = gql`
  query GetTrainingReport(
    $organizationId: ID!
    $paginationArgs: PaginationArgs!
    $searchStr: String
    $role: Role
    $status: Boolean
  ) {
    trainingReport(
      input: {
        organizationId: $organizationId
        paginationArgs: $paginationArgs
        searchStr: $searchStr
        role: $role
        status: $status
      }
    ) {
      ... on GetTrainingReportResult {
        TrainingReportResult {
          id
          user
          completed
          roles
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetTrainingReport = (options?) =>
  useQuery<GetTrainingReport, GetTrainingReportVariables>(GET_TRAINING_REPORT, options);
