import { useContext, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { LoadingContext } from './LoadingContext';

export const useLoading = (loading?: boolean) => {
  const context = useContext(LoadingContext);

  useEffect(() => {
    if (!isUndefined(loading)) {
      context.setLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    return () => context.setLoading(false);
  }, []);

  return context;
};
