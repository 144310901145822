import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { useFormikContext } from 'formik';
import { useTranslations, languageList } from '../../i18n/useTranslations';
import { UpdateImage } from './UpdateImage';
import { AppDateTimePicker, Input, PhoneInput, SectionWrapper, AppButton, AppText, Select } from '../../uiComponents';
import { UpdateAccountInput } from './Account';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { UserLanguage } from '../../types/globalTypes';

export interface ContentProps {
  initialValues: UpdateAccountInput;
}

export const AccountContent: React.FC<ContentProps> = ({ initialValues }) => {
  const { translate } = useTranslations();

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    submitForm,
  } = useFormikContext<UpdateAccountInput>();

  const languages = languageList.map((language) => ({
    value: language.locale,
    label: language.nameInOwnLanguage,
    key: language.locale,
  }));

  const isMobile = useIsMobile();

  const [noFieldsChanged, setNoFieldsChanged] = React.useState<boolean>(true);

  const handleBirthdayChange = (date: string) => setFieldValue('birthday', date);

  const handleBirthdayBlur = () => setFieldTouched('birthday', true);

  React.useEffect(() => {
    setNoFieldsChanged(
      (values.birthday ? values.birthday === initialValues.birthday : true) &&
        values.firstName === initialValues.firstName &&
        values.lastName === initialValues.lastName &&
        (values.phoneNumber
          ? values.phoneNumber.phoneNumber === initialValues.phoneNumber?.phoneNumber &&
            values.phoneNumber.isoCode === initialValues.phoneNumber.isoCode
          : true) &&
        values.language === initialValues.language
    );
  }, [values.birthday, values.firstName, values.lastName, values.phoneNumber, values.language]);

  const handleLanguageChange = (language: UserLanguage) => {
    setFieldValue('language', language);
  };

  const styles = StyleSheet.create({
    formRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    rowInput: {
      flexBasis: '40%',
      flexGrow: 0,
      flexShrink: 0,
      flex: 1,
    },
    firstInput: {
      paddingRight: 10,
    },
    secondInput: {
      paddingLeft: 10,
    },
    languageInput: {
      top: 5,
    },
  });

  return (
    <View>
      <UpdateImage />
      <SectionWrapper>
        <View style={!isMobile ? styles.formRow : null}>
          <View style={!isMobile ? [styles.rowInput, styles.firstInput] : null}>
            <Input
              label={translate.placeholder.firstName}
              onChangeText={handleChange('firstName')}
              onBlur={handleBlur('firstName')}
              error={errors.firstName}
              touched={touched.firstName}
              value={values.firstName}
            />
          </View>
          <View style={!isMobile ? [styles.rowInput, styles.secondInput] : null}>
            <Input
              label={translate.placeholder.lastName}
              onChangeText={handleChange('lastName')}
              onBlur={handleBlur('lastName')}
              error={errors.lastName}
              touched={touched.lastName}
              value={values.lastName}
            />
          </View>
        </View>
        <View style={!isMobile ? styles.formRow : null}>
          <View style={!isMobile ? [styles.rowInput, styles.firstInput] : null}>
            <Input disabled={true} value={values.email} label={translate.account.yourDetails.email} />
          </View>
          <View style={!isMobile ? [styles.rowInput, styles.secondInput] : null}>
            <PhoneInput
              label={translate.placeholder.phone}
              isoCode={values.phoneNumber?.isoCode || 'CA'}
              phoneNumber={values.phoneNumber?.phoneNumber || ''}
              onChangePhoneNumber={handleChange('phoneNumber.phoneNumber')}
              onBlurPhoneNumber={handleBlur('phoneNumber.phoneNumber')}
              onChangeIsoCode={handleChange('phoneNumber.isoCode')}
              onBlurIsoCode={handleBlur('phoneNumber.isoCode')}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
            />
          </View>
          {/* create an invisible input so that the phone input doesn't autofill with the email */}
          <View style={{ opacity: 0, height: 0, width: 0 }}>
            <Input />
          </View>
        </View>
        <View style={!isMobile ? styles.formRow : null}>
          <View style={!isMobile ? [styles.rowInput, styles.firstInput] : null}>
            <AppDateTimePicker
              label={translate.account.yourDetails.birthday}
              onChange={handleBirthdayChange}
              onBlur={handleBirthdayBlur}
              value={values.birthday}
              error={errors.birthday}
              touched={touched.birthday}
            />
          </View>
          <View style={!isMobile ? [styles.rowInput, styles.secondInput] : null}>
            <AppText>{translate.account.yourDetails.language}</AppText>
            <Select<UserLanguage>
              items={languages}
              onChange={(val) => handleLanguageChange(val)}
              error={errors?.language}
              touched={touched?.language}
              value={values.language}
              style={styles.languageInput}
            />
          </View>
        </View>
        <AppButton
          disabled={
            isSubmitting ||
            noFieldsChanged ||
            !!errors.birthday ||
            !!errors.firstName ||
            !!errors.lastName ||
            !!errors.phoneNumber
          }
          loading={isSubmitting}
          onPress={submitForm}
          style={{ marginBottom: 24 }}
        >
          {translate.account.save}
        </AppButton>
      </SectionWrapper>
    </View>
  );
};
