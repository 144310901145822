import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';

const GET_ORGANIZATION_STATUSES = gql`
  query GetOrganizationStatuses {
    organizationStatuses {
      ... on OrganizationDTO {
        id
        name
        createdAt
        status
        expiresAt
      }
    }
  }
`;

export const useGetOrganizationStatuses = (options?) => useQuery(GET_ORGANIZATION_STATUSES, options);
