import { Formik, FormikErrors, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppButton, AppDateTimePicker, Input } from '../../../../../uiComponents';
import { GET_SEASONS_BY_ORGANIZATION_ID_QUERY } from '../hooks/useGetSeasonsByOrganizationId';
import { useUpdateSeason } from '../hooks/useUpdateSeason';
import { GetSeasonsByOrganizationId } from '../hooks/__generated__/GetSeasonsByOrganizationId';

export interface UpdateSeasonFormData {
  name: string;
  startDate: string;
  endDate?: string;
}

export interface UpdateSeasonProps {
  organizationId: string;
  seasonId: string;
  input: UpdateSeasonFormData;
}

export const UpdateSeason: React.FC<UpdateSeasonProps> = ({ organizationId, seasonId, input }) => {
  const { translate } = useTranslations();
  const [updateSeason] = useUpdateSeason();

  const handleUpdateSeason = (input: UpdateSeasonFormData, { setSubmitting }: FormikHelpers<UpdateSeasonFormData>) => {
    updateSeason({
      variables: {
        name: input.name,
        startDate: input.startDate,
        endDate: input.endDate,
        organizationId,
        seasonId,
      },
    }).then(() => {
      setSubmitting(false);
    });
  };

  const validate = (input: UpdateSeasonFormData) => {
    const errors: FormikErrors<UpdateSeasonFormData> = {};
    if (!input.name) {
      errors.name = translate.error.message.cantBeEmpty;
    }
    if (!input.startDate) {
      errors.startDate = translate.error.message.cantBeEmpty;
    }
    return errors;
  };

  return (
    <Formik<UpdateSeasonFormData> initialValues={input} onSubmit={handleUpdateSeason} validate={validate}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        dirty,
        isValid,
        submitForm,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <Input
            label={translate.label.name}
            onChangeText={handleChange('name')}
            onBlur={handleBlur('name')}
            value={values.name}
            type="text"
            error={errors.name}
            touched={touched.name}
          />
          <AppDateTimePicker
            label={translate.seasons.startDate}
            touched={touched?.startDate}
            error={errors?.startDate}
            value={values.startDate}
            onChange={(date: string) => setFieldValue('startDate', date)}
            onBlur={() => setFieldTouched('startDate', true)}
          />
          <AppDateTimePicker
            label={translate.seasons.endDate}
            touched={touched?.endDate}
            error={errors?.endDate}
            value={values.endDate || ''}
            onChange={(date: string) => setFieldValue('endDate', date)}
            onBlur={() => setFieldTouched('endDate', true)}
          />
          <AppButton
            loading={isSubmitting}
            disabled={!isValid || !dirty}
            onPress={submitForm}
            style={{ marginLeft: 'auto' }}
          >
            {translate.label.update}
          </AppButton>
        </>
      )}
    </Formik>
  );
};
