import * as React from 'react';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Content } from './Content';
import { useNavigation } from '@react-navigation/native';
import { SuperAdminNavType } from '../../SuperAdmin.interface';

export const PaymentStatus: React.FC = () => {
  const { translate } = useTranslations();
  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();

  const onBack = () => navigation.navigate('LoggedIn_SuperAdmin', { screen: 'LoggedIn_SuperAdmin_Index' });

  return (
    <Chrome
      navBarProps={{ showHamburger: false, onBack }}
      mode="title-card"
      titleText={translate.superAdmin.paymentStatus.title}
      content={<Content />}
      autoScroll={false}
    />
  );
};
