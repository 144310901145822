import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { OrganizationFragment } from '../../../fragments/OrganizationFragment';
import { UpdateOrganization, UpdateOrganizationVariables } from './__generated__/UpdateOrganization';

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation UpdateOrganization(
    $organizationId: ID!
    $name: String
    $emergencyPhoneNumber: PhoneNumberInput!
    $language: UserLanguage!
  ) {
    updateOrganization(
      id: $organizationId
      name: $name
      emergencyPhoneNumber: $emergencyPhoneNumber
      language: $language
    ) {
      ... on Organization {
        ...Organization
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${OrganizationFragment}
  ${ApiErrorFragment}
`;

export const useUpdateOrganization = (options?: MutationHookOptions<UpdateOrganization, UpdateOrganizationVariables>) =>
  useMutation(UPDATE_ORGANIZATION_MUTATION, options);
