import { gql, MutationHookOptions } from '@apollo/client';
import { MY_ACTION_ITEMS_QUERY } from '../../../../../screens/ActionItems/hooks/useActionItems';
import { UseActionItemsQuery } from '../../../../../screens/ActionItems/hooks/__generated__/UseActionItems';
import { useMutation } from '../../../../../hooks/useMutation';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { ReviewStageDocument, ReviewStageDocumentVariables } from './__generated__/ReviewStageDocument';

export const REVIEW_STAGE_DOCUMENT = gql`
  mutation ReviewStageDocument($input: ReviewStageDocumentInput!) {
    reviewStageDocument(input: $input) {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
    }
  }
  ${RecoveryPlanFragment}
`;

export const useReviewStageDocument = (
  options?: MutationHookOptions<ReviewStageDocument, ReviewStageDocumentVariables>
) =>
  useMutation<ReviewStageDocument, ReviewStageDocumentVariables>(REVIEW_STAGE_DOCUMENT, {
    ...options,
    update(cache, { data }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });

      const documentId = data?.reviewStageDocument?.__typename === 'RecoveryPlan' ? data.reviewStageDocument.id : null;

      if (cachedActionItems && documentId) {
        cache.writeQuery<UseActionItemsQuery>({
          query: MY_ACTION_ITEMS_QUERY,
          data: {
            __typename: 'Query',
            actionItems: cachedActionItems.actionItems.filter(
              (actionItem) =>
                !(
                  actionItem.__typename === 'PendingDocumentSubmissionReviewActionItem' &&
                  actionItem.subject.id === documentId
                )
            ),
          },
        });
      }
    },
  });
