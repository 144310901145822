import * as React from 'react';
import { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { AppButton, AppDateTimePicker, AppText, Select } from '../../../../uiComponents';
import { useUpdateOrganizationStatus } from '../../hooks/useUpdateOrganizationStatus';
import { useNotification } from '../../../../hooks/ui/useNotification';
import { isApiError } from '../../../../utils/isApiError';
import { useTranslations } from '../../../../i18n/useTranslations';
import { OrganizationStatusEnum } from '../../../../types/globalTypes';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
interface OrganizationFieldProps {
  id: string;
  organizationName: string;
  status: OrganizationStatusEnum;
  validUntilDate?: string;
}

export const OrganizationField: React.FC<OrganizationFieldProps> = (props) => {
  const { id, organizationName, status, validUntilDate } = props;

  const { translate, translateWithVars } = useTranslations();
  const notify = useNotification();
  const isMobile = useIsMobile();
  const [updateOrganizationStatus] = useUpdateOrganizationStatus({
    onCompleted: ({ updateOrganizationStatus }) => {
      if (!isApiError(updateOrganizationStatus)) {
        notify({
          image: 'header/people',
          description: translateWithVars(translate.superAdmin.paymentStatus.statusUpdate, {
            organizationName,
            organizationStatus,
          }),
        });
      }
    },
  });

  const statuses = [
    {
      key: OrganizationStatusEnum.TRIAL,
      value: OrganizationStatusEnum.TRIAL,
      label: translate.superAdmin.paymentStatus.statuses.trial,
    },
    {
      key: OrganizationStatusEnum.PAID,
      value: OrganizationStatusEnum.PAID,
      label: translate.superAdmin.paymentStatus.statuses.paid,
    },
    {
      key: OrganizationStatusEnum.CLOSED,
      value: OrganizationStatusEnum.CLOSED,
      label: translate.caseLog.closed,
    },
    {
      key: OrganizationStatusEnum.SUSPENDED,
      value: OrganizationStatusEnum.SUSPENDED,
      label: translate.superAdmin.paymentStatus.statuses.suspended,
    },
    {
      key: OrganizationStatusEnum.DEMO,
      value: OrganizationStatusEnum.DEMO,
      label: translate.superAdmin.paymentStatus.statuses.demo,
    },
    {
      key: OrganizationStatusEnum.TEST,
      value: OrganizationStatusEnum.TEST,
      label: translate.superAdmin.paymentStatus.statuses.test,
    },
  ];

  const [organizationStatus, setOrganizationStatus] = useState<OrganizationStatusEnum>(status);
  const [organizationExpiryDate, setOrganizationExpiryDate] = useState<string | undefined>(validUntilDate);
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);

  const handleStatusChange = (_organizationStatus: OrganizationStatusEnum) => {
    _organizationStatus !== status ? setIsStatusChanged(true) : setIsStatusChanged(false);
    const item = statuses.find((el) => el.value === _organizationStatus);
    if (item) setOrganizationStatus(item.value);
  };

  const handleExpiryDateChange = (date: string) => {
    setOrganizationExpiryDate(date);
    setIsStatusChanged(true);
  };

  const hideUpdateButton = () => {
    if (!isMobile) {
      return styles.hiddenUpdateButtonWeb;
    } else return styles.hiddenUpdateButtonMobile;
  };

  const handleUpdateOrganizationStatus = () => {
    if (organizationExpiryDate) {
      updateOrganizationStatus({
        variables: {
          organizationId: id,
          status: organizationStatus,
          expiresAt: organizationExpiryDate,
        },
      });
    } else {
      updateOrganizationStatus({
        variables: {
          organizationId: id,
          status: organizationStatus,
        },
      });
    }
    setIsStatusChanged(false);
  };

  const styles = StyleSheet.create({
    organizationFieldContainer: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: !isMobile ? 'center' : 'flex-start',
      justifyContent: !isMobile ? 'space-between' : 'flex-start',
      width: !isMobile ? '90%' : '100%',
      height: isMobile ? 250 : 'auto',
    },
    organizationName: {
      width: !isMobile ? 200 : '90%',
    },
    statusSelector: {
      width: !isMobile ? 200 : '100%',
      marginTop: !isMobile ? 0 : 20,
    },
    hiddenUpdateButtonWeb: {
      opacity: 0,
      height: 0,
    },
    hiddenUpdateButtonMobile: {
      opacity: 0,
      width: 0,
    },
    validUntilLabel: {
      marginBottom: 25,
    },
    validUntilDate: {
      width: !isMobile ? 200 : '100%',
    },
    validUntilDateContainer: {
      marginTop: isMobile ? 20 : 0,
      height: isMobile ? 80 : 40,
      width: !isMobile ? 250 : '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    validUntilDateText: {
      paddingLeft: 20,
    },
  });

  return (
    <View style={styles.organizationFieldContainer}>
      <AppText font="header5" style={styles.organizationName}>
        {organizationName}
      </AppText>
      <View style={styles.statusSelector}>
        <AppText>{translate.subjectStatus.modalTitle}</AppText>
        <Select items={statuses} value={organizationStatus} onChange={handleStatusChange} />
      </View>
      <View style={styles.validUntilDate}>
        <View style={styles.validUntilDateContainer}>
          <AppDateTimePicker
            label={translate.superAdmin.paymentStatus.expiresAtLabel}
            value={organizationExpiryDate}
            onChange={(date: string) => handleExpiryDateChange(date)}
          />
        </View>
      </View>
      <View style={!isStatusChanged ? hideUpdateButton() : null}>
        <AppButton
          onPress={handleUpdateOrganizationStatus}
          disabled={
            (organizationStatus === OrganizationStatusEnum.TRIAL && !organizationExpiryDate) ||
            (organizationStatus === OrganizationStatusEnum.PAID && !organizationExpiryDate)
          }
        >
          {translate.label.update}
        </AppButton>
      </View>
    </View>
  );
};
