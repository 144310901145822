import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { makeCache } from './makeCache';
import { TypePolicies } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import 'react-native-get-random-values'; // needed due to this error: https://github.com/uuidjs/uuid#getrandomvalues-not-supported
// import uuid from 'react-native-uuid';
import { v4 as uuid } from 'uuid';
import { getAuth, getIdToken } from '@firebase/auth';
import { useFirebase } from '../Firebase';

export const InitializeApolloClient = (
  uri: string,
  errorHandler?: (error: string) => void,
  captureException?: (error: Error, context?: Record<string, unknown>) => void,
  customTypePolicies?: TypePolicies
) => {
  const cache = makeCache(customTypePolicies);
  const { firebase } = useFirebase();

  const httpLink = createUploadLink({ uri, headers: { 'Apollo-Require-Preflight': 'true' } });

  const authLink = () =>
    setContext((_, { headers }) => {
      const user = getAuth(firebase).currentUser;

      if (user) {
        return getIdToken(user).then((token) => ({
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        }));
      }
      return { headers };
    });

  const transactionLink = () =>
    setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          // 'X-Transaction-ID': uuid.v4(),
          'X-Transaction-ID': uuid(),
        },
      };
    });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (captureException) {
          captureException(error);
        }
        console.warn(error.message);
      });
    }

    if (networkError) {
      if (captureException) {
        captureException(networkError);
      }
      console.warn(networkError);
      if (errorHandler) {
        errorHandler(networkError.message);
      }
      networkError.message === 'Network request failed';
    }
  });

  return new ApolloClient({
    link: transactionLink()
      .concat(authLink())
      .concat(errorLink)
      .concat(httpLink as any as ApolloLink), // TODO: determine why typing is broken here
    cache,
  });
};
