import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../i18n/useTranslations';
import { AppButton, AppText, Spacer } from '../../uiComponents';
import { RootNavType } from '../../RootNavigator.interface';

export interface InvitationNotFoundProps {}

export const InvitationNotFound: React.FC<InvitationNotFoundProps> = (props) => {
  const { translate } = useTranslations();
  const nav = useNavigation<RootNavType<'Invitation'>>();

  const handleGoHome = () => nav.navigate('Welcome');

  return (
    <Spacer
      size="large"
      items={{
        title: <AppText font="header3">{translate.invitation.invitationNotFound.title}</AppText>,
        message: <AppText>{translate.invitation.invitationNotFound.message}</AppText>,
        button: <AppButton onPress={handleGoHome}>{translate.invitation.invitationNotFound.goHome}</AppButton>,
      }}
    />
  );
};
