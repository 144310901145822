import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React from 'react';
import { AuthorizedRole } from '../../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { useColors } from '../../../../../../../shared/Ui/hooks';
import {
  Icon,
  Input,
  InputLabel,
  RadioButton,
  Select,
  SelectItemProps,
  ShadowView,
  Spacer,
  SpacerItems,
} from '../../../../../../../uiComponents';
import {
  UpdateProtocolConfigFormValue,
  UpdateReturnProtocolChildProtocolStageConfigFormValue,
} from '../../../ManageProtocol.interface';
import { TimeGateOption } from '../TimeGateOption';

export interface ReturnProtocolStageBaseConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateReturnProtocolChildProtocolStageConfigFormValue>;
  touch?: FormikTouched<UpdateReturnProtocolChildProtocolStageConfigFormValue>;
  value: UpdateReturnProtocolChildProtocolStageConfigFormValue;
  typeSpecificItems: SpacerItems;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const ReturnProtocolStageBaseConfig: React.FC<ReturnProtocolStageBaseConfigProps> = ({
  itemKey,
  error,
  touch,
  value,
  typeSpecificItems,
  canMoveDown,
  canMoveUp,
  onRemove,
  move,
  index,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            return: { stage: translate },
          },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();

  const { handleChange, handleBlur, setFieldValue, values, errors } = useFormikContext<UpdateProtocolConfigFormValue>();
  const colors = useColors();

  const handleChangeType = (type: 'document-submission' | 'signoff') => {
    if (type === 'document-submission') {
      const symptomItem: UpdateReturnProtocolChildProtocolStageConfigFormValue = {
        ...value,
        type: 'document-submission',
        templateDocument: {
          currentDocument: null,
          nextDocument: null,
        },
      };
      setFieldValue(`${itemKey}`, symptomItem);
    } else {
      const documentItem: UpdateReturnProtocolChildProtocolStageConfigFormValue = {
        ...value,
        type: 'signoff',
      };

      setFieldValue(`${itemKey}`, documentItem);
    }
  };

  const handleRemove = () => onRemove(index);
  const handleMoveUp = () => move(index, index - 1);
  const handleMoveDown = () => move(index, index + 1);

  const handleAuthorizedPersonChanged = (value: AuthorizedRole) => {
    setFieldValue(`${itemKey}.authorizedRole`, value);
  };

  const authorizedRoleItems: SelectItemProps<AuthorizedRole>[] = [
    {
      key: AuthorizedRole.ANYONE,
      value: AuthorizedRole.ANYONE,
      label: rootTranslate.protocol.returnStep.anyone,
    },
    {
      key: AuthorizedRole.DESIGNATED_PERSON,
      value: AuthorizedRole.DESIGNATED_PERSON,
      label: rootTranslate.users.rolesPlural.primaryDesignatedPerson,
    },
  ];

  return (
    <ShadowView style={{ padding: 20 }}>
      <Spacer
        size="large"
        items={{
          actions: (
            <Spacer
              orientation="horizontal"
              style={{ alignSelf: 'flex-end', width: 'auto' }}
              items={{
                up: canMoveUp ? <Icon color={colors.blue} name="arrow-up" onPress={handleMoveUp} size={24} /> : null,
                down: canMoveDown ? (
                  <Icon color={colors.blue} name="arrow-down" onPress={handleMoveDown} size={24} />
                ) : null,
                trash: <Icon color={colors.red} name="trash" onPress={handleRemove} size={24} />,
              }}
            />
          ),
          name: (
            <Input
              label={rootTranslate.label.name}
              onChangeText={handleChange(`${itemKey}.name`)}
              onBlur={handleBlur(`${itemKey}.name`)}
              error={error?.name}
              touched={touch?.name}
              value={value?.name || ''}
            />
          ),
          description: (
            <>
              <Input
                multiline={true}
                numLines={5}
                numberOfLines={5}
                label={translate.description}
                onChangeText={handleChange(`${itemKey}.description`)}
                onBlur={handleBlur(`${itemKey}.description`)}
                error={error?.description}
                touched={touch?.description}
                value={value.description || ''}
              />
            </>
          ),
          authorizedPerson: (
            <>
              <InputLabel
                label={
                  value.type === 'document-submission'
                    ? translate.authorizedRoleDocumentSubmission
                    : translate.authorizedRoleSignoff
                }
              />
              <Select<AuthorizedRole>
                items={authorizedRoleItems}
                value={value.authorizedRole}
                error={error?.authorizedRole}
                onBlur={handleBlur(`${itemKey}.authorizedRole`)}
                touched={touch?.authorizedRole}
                onChange={handleAuthorizedPersonChanged}
              />
            </>
          ),
          type: (
            <>
              <InputLabel label={translate.stageType} />
              <RadioButton<'document-submission' | 'signoff'>
                value={value.type}
                onSelect={handleChangeType}
                options={[
                  {
                    icon: 'check',
                    label: translate.signoff,
                    value: 'signoff',
                  },
                  {
                    icon: 'check',
                    label: translate.documentSubmission,
                    value: 'document-submission',
                  },
                ]}
              />
            </>
          ),
          timeGateOption: (
            <TimeGateOption
              timeGateHoursValue={value.timeGateHours}
              isTimeGateSelectedValue={value.isTimeGateSelected}
              error={error}
              touch={touch}
              value={value}
              itemKey={itemKey}
            />
          ),

          ...typeSpecificItems,
        }}
      />
    </ShadowView>
  );
};
