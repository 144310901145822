import { AssessActionSetSubjectPayload, AssessContextState } from '../../../Assess.interface';
import { setProtocol } from './setProtocol';

export const setSubject = (nextState: AssessContextState, payload: AssessActionSetSubjectPayload) => {
  nextState = {
    ...nextState,
    subject: payload.subject,
    availableProtocolOptions: payload.availableProtocolOptions,
  };

  if (payload.selectedProtocolOption) {
    return setProtocol(nextState, { selectedProtocolOption: payload.selectedProtocolOption });
  } else {
    return nextState;
  }
};
