import { gql, QueryHookOptions } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { BasicUserFragment } from '../../../../../fragments/BasicUserFragment';
import {
  GetDesignatedPersonsNotInGroup,
  GetDesignatedPersonsNotInGroupVariables,
} from './__generated__/GetDesignatedPersonsNotInGroup';

const DESIGNATED_PERSONS_NOT_IN_GROUP_QUERY = gql`
  query GetDesignatedPersonsNotInGroup(
    $groupId: ID!
    $organizationId: ID!
    $paginationArgs: PaginationArgs
    $searchStr: String!
  ) {
    designatedPersonsNotInGroup(
      groupId: $groupId
      organizationId: $organizationId
      paginationArgs: $paginationArgs
      searchStr: $searchStr
    ) {
      ...BasicUser
    }
  }
  ${BasicUserFragment}
`;

export const useGetDesignatedPersonsNotInGroup = (options?) =>
  useQuery<GetDesignatedPersonsNotInGroup, GetDesignatedPersonsNotInGroupVariables>(
    DESIGNATED_PERSONS_NOT_IN_GROUP_QUERY,
    options
  );
