import { RfaProtocolResult } from '../../../../../types/globalTypes';
import { AssessActionSetRedFlagsPayload, AssessContextState } from '../../../Assess.interface';
import { filterPresentSymptomResult } from './filterPresentSymptomResult';

export const setRedFlags = (nextState: AssessContextState, { redFlags }: AssessActionSetRedFlagsPayload) => {
  const emergency = redFlags.filter(filterPresentSymptomResult).length > 0;
  return {
    ...nextState,
    redFlags: {
      viewed: true,
      values: redFlags,
    },
    emergency,
    suggestedResult: emergency ? RfaProtocolResult.REMOVE : RfaProtocolResult.RESUME,
    selectedResult: emergency ? RfaProtocolResult.REMOVE : undefined,
  };
};
