import { useNavigation, useRoute } from '@react-navigation/native';
import { LoggedInNavType } from '../../../screens/LoggedIn.interface';
import { Role } from '../../../types/globalTypes';
import { IconName } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { useGetAllUserRoles } from '../../../hooks/useGetAllUserRoles';

export interface NavBarIconProps {
  icon: IconName;
  name: string;
  routes: string[];
  hideIfNoEducationItems?: boolean;
  allowedRoles?: Role[];
}

export const useNavbarNavigation = (hideNavItems: boolean | undefined) => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Home'>>();
  const route = useRoute();
  const { data } = useGetAllUserRoles({
    skip: route.name === 'Welcome' || route.name === 'Login' || route.name === 'ResetPassword',
  });
  const userRoles = data && data.allUserRoles ? data.allUserRoles.map((role) => role.role) : [];

  const { translate } = useTranslations();

  const allNavItems: NavBarIconProps[] = [
    {
      icon: 'home',
      name: translate.navigation.home,
      routes: ['LoggedIn_Home'],
    },
    {
      icon: 'users',
      name: translate.admin.manageSeasons.groupDetails.subjects,
      routes: ['LoggedIn_TeamRoster'],
      hideIfNoEducationItems: true,
      allowedRoles: [Role.PRIMARY_DESIGNATED_PERSON, Role.SECONDARY_DESIGNATED_PERSON],
    },
    {
      icon: 'accessibility',
      name: translate.home.tile.myKids,
      routes: ['LoggedIn_MyKids'],
      hideIfNoEducationItems: true,
      allowedRoles: [Role.GUARDIAN],
    },
    {
      icon: 'user',
      name: translate.home.tile.myProfile,
      routes: ['LoggedIn_Profile_Overview'],
      hideIfNoEducationItems: true,
      allowedRoles: [Role.SUBJECT],
    },
    {
      icon: 'folder',
      name: translate.navigation.cases,
      routes: [
        'LoggedIn_OnProtocol',
        'LoggedIn_Profile_RecoveryPlan_Index',
        'LoggedIn_Cases_Index',
        'LoggedIn_Cases_CaseList',
      ],
      hideIfNoEducationItems: true,
    },
    {
      icon: 'organization',
      name: translate.subjectList.organizations,
      routes: ['LoggedIn_Admin_Index'],
    },
    {
      icon: 'award',
      name: translate.navigation.training,
      routes: ['LoggedIn_Training_Index', 'LoggedIn_Training_Resources'],
    },
  ];

  const navItemsFilteredByRole = allNavItems.filter(
    (navItem) =>
      !navItem.allowedRoles ||
      (navItem.allowedRoles && userRoles.some((userRole) => navItem.allowedRoles?.includes(userRole)))
  );

  const navItems = hideNavItems
    ? navItemsFilteredByRole.filter((navItem) => !navItem.hideIfNoEducationItems)
    : navItemsFilteredByRole;

  const navigateToLink = (link: string) => {
    const navItemLinks = {
      [translate.admin.manageSeasons.groupDetails.subjects]: () => {
        navigation.navigate('LoggedIn_TeamRoster');
      },
      [translate.home.tile.myProfile]: () => {
        navigation.navigate('LoggedIn_Profile', { screen: 'LoggedIn_Profile_Overview' });
      },
      [translate.home.tile.myKids]: () => {
        navigation.navigate('LoggedIn_MyKids');
      },
      [translate.navigation.cases]: () => {
        if (userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON)) {
          navigation.navigate('LoggedIn_Cases', { screen: 'LoggedIn_Cases_Index' });
        } else if (userRoles.includes(Role.SECONDARY_DESIGNATED_PERSON)) {
          navigation.navigate('LoggedIn_OnProtocol');
        } else if (userRoles.includes(Role.SUBJECT)) {
          navigation.navigate('LoggedIn_Profile', {
            screen: 'LoggedIn_Profile_RecoveryPlan',
            params: { screen: 'LoggedIn_Profile_RecoveryPlan_Index' },
          });
        }
      },
      [translate.subjectList.organizations]: () => {
        navigation.navigate('LoggedIn_Admin', { screen: 'LoggedIn_Admin_Index' });
      },
      [translate.navigation.training]: () => {
        if (userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON)) {
          navigation.navigate('LoggedIn_Training', {
            screen: 'LoggedIn_Training_Index',
          });
        } else {
          navigation.navigate('LoggedIn_Training', {
            screen: 'LoggedIn_Training_Resources',
            params: { isPrimaryDesignatedPerson: false },
          });
        }
      },
      [translate.navigation.home]: () => {
        navigation.navigate('LoggedIn_Home');
      },
      [translate.admin.manageProtocol.index.sectionRemoval]: () => {
        navigation.navigate('LoggedIn_Assess', { screen: 'LoggedIn_Assess_Index' });
      },
      [translate.home.tile.actionItems]: () => {
        navigation.navigate('LoggedIn_ActionItems');
      },
    };
    return navItemLinks[link]();
  };

  const showItem = (name) => {
    switch (name) {
      case translate.navigation.cases:
        return (
          userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON) || userRoles.includes(Role.SECONDARY_DESIGNATED_PERSON)
        );
      case translate.subjectList.organizations:
        return userRoles.includes(Role.ADMIN);
      case translate.admin.manageSeasons.groupDetails.subjects:
        return (
          userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON) || userRoles.includes(Role.SECONDARY_DESIGNATED_PERSON)
        );
      case translate.home.tile.myProfile:
        return userRoles.includes(Role.SUBJECT);
      case translate.home.tile.myKids:
        userRoles.includes(Role.GUARDIAN);
      default:
        return true;
    }
  };

  return { navigateToLink, navItems, showItem };
};
