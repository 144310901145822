import { createContext, useContext } from 'react';
import { AppUiContextType } from './AppUiConfig.interface';
import { defaultUiConfig } from './defaultUiConfig';

export const AppUiConfigContext = createContext<AppUiContextType<any, any>>(defaultUiConfig);

export const useAppUiConfigContext = <Countries extends string, States extends string>() => {
  const context = useContext(AppUiConfigContext) as AppUiContextType<Countries, States>;
  return context;
};
