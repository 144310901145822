import React, { useEffect } from 'react';
import { Keyboard, Linking, StyleSheet, View } from 'react-native';
import {
  AppButton,
  AppText,
  FileButton,
  FileButtonSelectedFile,
  Input,
  AppDateTimePicker,
  Select,
  InputLabel,
} from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';
import { useFormikContext } from 'formik';
import { IncidentReportFormData } from './IncidentReport';
import { CaseStatus, GroupMembership, IncidentType } from '../../types/globalTypes';
import { RadioQuestion } from './RadioQuestion';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { useColors } from '../../shared/Ui';

interface IncidentReportContentProps {
  incidentReportConfig: any;
  subject: { firstName: string; lastName: string } | null;
  handleSubmitButtonPress: (values: IncidentReportFormData) => void;
  updateFile: (file: FileButtonSelectedFile | null) => void;
  file: FileButtonSelectedFile | null;
  status: CaseStatus;
  participantGroups: GroupMembership[];
}

interface SelectData {
  key: string;
  value: string;
  label: string;
}

interface LabelWithAsteriskProps {
  label: React.ReactNode;
}

export const LabelWithAsterisk: React.FC<LabelWithAsteriskProps> = ({ label }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      {label}
      <AppText color="red"> *</AppText>
    </View>
  );
};

export const IncidentReportContent: React.FC<IncidentReportContentProps> = (props) => {
  const { translate, translateWithVars } = useTranslations();
  const isMobile = useIsMobile();

  const { incidentReportConfig, subject, handleSubmitButtonPress, updateFile, file, status, participantGroups } = props;

  const colors = useColors();

  const groupItems = [{ key: 'none', value: 'select', label: translate.label.select }]
    .concat(
      participantGroups.map((group) => ({
        key: group.group.id,
        value: group.group.id,
        label: group.group.name,
      }))
    )
    .concat({ key: 'noGroup', value: '', label: translate.label.none });

  if (!subject || !incidentReportConfig) {
    return null;
  }

  const styles = StyleSheet.create({
    groupSelectContainer: {
      width: 400,
    },
    incidentDate: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      justifyContent: 'flex-start',
      marginTop: 20,
    },
    incidentType: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
      backgroundColor: colors.white,
    },
    incidentTypeDropdown: {
      width: isMobile ? '100%' : 250,
    },
    dateSelectContainer: {
      width: isMobile ? '90%' : '40%',
    },
    timeSelect: {
      width: isMobile ? '40%' : '10%',
      bottom: isMobile ? 0 : 5,
    },
    timeSelectContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      marginLeft: isMobile ? 0 : 20,
    },
    hour: {
      marginRight: 10,
    },
    minutes: {
      marginLeft: 10,
    },
    otherIncident: {
      marginLeft: isMobile ? 0 : 20,
      minWidth: '30%',
      flex: 1,
    },
    formButtons: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      marginVertical: 20,
    },
    templateButton: {
      marginRight: 20,
      marginBottom: isMobile ? 20 : 0,
    },
    submitButton: {
      marginTop: 40,
      alignSelf: 'flex-end',
    },
    title: {
      marginTop: 30,
    },
  });

  const handlePickDocument = (incidentImage: FileButtonSelectedFile) => {
    updateFile(incidentImage);
  };

  const handleRemoveDocument = () => {
    updateFile(null);
  };

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid } =
    useFormikContext<IncidentReportFormData>();

  const getHoursOrMinutes = (type: 'hour' | 'minutes'): SelectData[] => {
    const values: SelectData[] = [];
    const max = type === 'hour' ? 23 : 59;
    for (let i = 0; i <= max; i++) {
      const num = i < 10 ? `0${i.toString()}` : i.toString();
      values.push({ key: num, value: num, label: num });
    }
    return values;
  };

  const incidentTypeString = {
    COLLISION_WITH_GROUND: translate.incidentReport.incidentTypes.collisionWithGround,
    COLLISION_WITH_MOVING_OBJECT: translate.incidentReport.incidentTypes.collisionWithMovingObject,
    COLLISION_WITH_PERSON: translate.incidentReport.incidentTypes.collisionWithPerson,
    COLLISION_WTH_STATIC_OBJECT: translate.incidentReport.incidentTypes.collisionWithStaticObject,
    MOTOR_VEHICLE_COLLISION: translate.incidentReport.incidentTypes.motorVehicleCollision,
    MULTIPLE: translate.subjectStatus.complex,
    OTHER: translate.superAdmin.createOrganization.sports.other,
    UNKNOWN: translate.incidentReport.incidentTypes.unknown,
  };

  const incidentTypeOptions = [{ key: 'none', value: '', label: translate.label.select }].concat(
    Object.values(IncidentType).map((incidentType: IncidentType) => ({
      key: incidentType,
      value: incidentType,
      label: incidentTypeString[incidentType],
    }))
  );

  useEffect(() => {
    if (values.type) {
      setFieldTouched('type', true);
    }
  }, [values.type]);

  return (
    <>
      <View style={styles.incidentDate}>
        <View style={styles.dateSelectContainer}>
          <AppDateTimePicker
            label={translate.incidentReport.incidentDate}
            touched={touched?.incidentDate}
            error={errors?.incidentDate}
            value={values.incidentDate || ''}
            onChange={(date: string) => setFieldValue('incidentDate', date)}
            onBlur={() => setFieldTouched('incidentDate', true)}
            maximumDate={new Date().toISOString()}
          />
        </View>
        <View style={styles.timeSelectContainer}>
          <View style={[styles.timeSelect, styles.hour]}>
            <InputLabel label={translate.incidentReport.hour} />
            <Select<string>
              value={values.hour}
              items={getHoursOrMinutes('hour')}
              onChange={(val) => setFieldValue('hour', val)}
              onBlur={() => setFieldTouched('incidentDate', true)}
            />
          </View>
          <AppText>:</AppText>
          <View style={[styles.timeSelect, styles.minutes]}>
            <InputLabel label={translate.incidentReport.minute} />
            <Select<string>
              value={values.minutes}
              items={getHoursOrMinutes('minutes')}
              onChange={(val) => setFieldValue('minutes', val)}
              onBlur={() => setFieldTouched('incidentDate', true)}
            />
          </View>
        </View>
      </View>
      {participantGroups.length > 1 ? (
        <View style={styles.groupSelectContainer}>
          <LabelWithAsterisk label={<InputLabel label={translate.users.selectGroups.selectAGroup} />} />
          <Select<string>
            value={values.group}
            onChange={(val) => setFieldValue('group', val)}
            items={groupItems}
            onBlur={() => setFieldTouched('group', true)}
            error={errors?.group}
            touched={touched?.group}
          />
        </View>
      ) : null}
      <Input
        label={translate.incidentReport.locationFacility}
        value={values.location}
        onChangeText={handleChange('location')}
        touched={touched?.location}
        onBlur={handleBlur('location')}
      />
      <View style={styles.incidentType}>
        <View style={styles.incidentTypeDropdown}>
          <LabelWithAsterisk label={<InputLabel label={translate.incidentReport.incidentType} />} />
          <Select<string>
            value={values.type}
            items={incidentTypeOptions}
            onChange={(val) => setFieldValue('type', val)}
            error={errors?.type}
            touched={touched?.type}
          />
        </View>
        {values.type === IncidentType.OTHER || values.type === IncidentType.MULTIPLE ? (
          <View style={styles.otherIncident}>
            <LabelWithAsterisk label={<InputLabel label={translate.incidentReport.detailsHeader} />} />
            <Input
              value={values.typeDetails}
              touched={touched?.typeDetails}
              error={errors?.typeDetails}
              onBlur={handleBlur('typeDetails')}
              onChangeText={handleChange('typeDetails')}
              placeholder={translate.incidentReport.incidentDetails}
            />
          </View>
        ) : null}
      </View>
      <AppText font="header3" style={styles.title}>
        {translate.incidentReport.otherSymptomsSection.title}
      </AppText>
      <RadioQuestion
        question={translate.incidentReport.otherSymptomsSection.question1}
        formikRadioValue={values.hasOtherSymptoms}
        radioValue="hasOtherSymptoms"
        formikInputValue={values.hasOtherSymptomsDetails}
        inputValue="hasOtherSymptomsDetails"
        label={`${translate.incidentReport.pleaseList}:`}
        handleChange={setFieldValue}
        isRequired={true}
      />
      <RadioQuestion
        question={translate.incidentReport.otherSymptomsSection.question2}
        formikRadioValue={values.hasOtherInjuries}
        radioValue="hasOtherInjuries"
        formikInputValue={values.hasOtherInjuriesDetails}
        inputValue="hasOtherInjuriesDetails"
        label={`${translate.incidentReport.pleaseDescribe}:`}
        handleChange={setFieldValue}
        isRequired={true}
      />
      <AppText font="header3" style={styles.title}>
        {translate.incidentReport.concussionHistory.title}
      </AppText>
      <RadioQuestion
        question={translateWithVars(translate.incidentReport.concussionHistory.question1, {
          firstName: subject.firstName,
        })}
        formikRadioValue={values.hasPreviousConcussion}
        radioValue="hasPreviousConcussion"
        formikInputValue={values.hasPreviousConcussionDetails}
        inputValue="hasPreviousConcussionDetails"
        label={`${translate.incidentReport.howMany}:`}
        handleChange={setFieldValue}
        isRequired={true}
      />
      <AppText font="header3" style={styles.title}>
        {translate.incidentReport.medicalHistory.title}
      </AppText>
      <RadioQuestion
        question={translateWithVars(translate.incidentReport.medicalHistory.question1, {
          firstName: subject.firstName,
        })}
        formikRadioValue={values.hasMedicalConditions}
        radioValue="hasMedicalConditions"
        formikInputValue={values.hasMedicalConditionsDetails}
        inputValue="hasMedicalConditionsDetails"
        label={`${translate.incidentReport.pleaseList}:`}
        handleChange={setFieldValue}
        isRequired={true}
      />
      <RadioQuestion
        question={translateWithVars(translate.incidentReport.medicalHistory.question2, {
          firstName: subject.firstName,
        })}
        formikRadioValue={values.hasMedication}
        radioValue="hasMedication"
        formikInputValue={values.hasMedicationDetails}
        inputValue="hasMedicationDetails"
        label={`${translate.incidentReport.pleaseList}:`}
        handleChange={setFieldValue}
        isRequired={true}
      />
      <AppText font="header3" style={styles.title}>
        {translate.incidentReport.incidentDescription}
      </AppText>
      {incidentReportConfig.instruction ? (
        <AppText font="header3" gutter={true}>
          {incidentReportConfig.instruction}
        </AppText>
      ) : null}
      <Input
        placeholder={translate.incidentReport.incidentDescription}
        value={values.incidentDescription}
        type="text"
        numLines={5}
        numberOfLines={5}
        onSubmitEditing={Keyboard.dismiss}
        multiline={true}
        onChangeText={handleChange('incidentDescription')}
      />
      <AppText font="header3" style={styles.title}>
        {translate.incidentReport.reportForm}
      </AppText>
      <View style={styles.formButtons}>
        {incidentReportConfig.templateDocument ? (
          <AppButton
            type="outline"
            onPress={() => {
              if (incidentReportConfig.templateDocument?.downloadUri) {
                Linking.openURL(incidentReportConfig.templateDocument.downloadUri);
              }
            }}
            style={styles.templateButton}
          >
            {translate.label.downloadTemplate}
          </AppButton>
        ) : null}

        <FileButton
          onFileChange={handlePickDocument}
          onFileClear={handleRemoveDocument}
          file={file || undefined}
          mediaType="image/pdf"
        >
          {translate.incidentReport.uploadFile}
        </FileButton>
      </View>
      <AppButton
        style={styles.submitButton}
        onPress={() => handleSubmitButtonPress(values)}
        disabled={!isValid || status !== CaseStatus.OPEN}
      >
        {translate.label.submit}
      </AppButton>
    </>
  );
};
