import React from 'react';
import { SvgProps } from 'react-native-svg';

// Header
import People from '../../assets/svg/People.svg';

import Checkbox from '../../assets/svg/checkbox.svg';
import Cancel from '../../assets/svg/cancel.svg';
import Pending from '../../assets/svg/pending.svg';
import Flag from '../../assets/svg/flag.svg';
import Plus from '../../assets/svg/plus.svg';

// Tiles
import Protocols from '../../assets/svg/tiles/Protocols.svg';
import BrainHealth from '../../assets/svg/tiles/BrainHealth.svg';
import Notifications from '../../assets/svg/tiles/Notifications.svg';
import TeamRoster from '../../assets/svg/tiles/TeamRoster.svg';
import Documents from '../../assets/svg/tiles/Documents.svg';
import TilePeople from '../../assets/svg/tiles/People.svg';
import HockeyPlayer from '../../assets/svg/tiles/HockeyPlayer.svg';
import Training from '../../assets/svg/tiles/Training.svg';

import Hamburger from '../../assets/svg/Hamburger.svg';
import Reload from '../../assets/svg/Reload.svg';

// Logos
import CAC from '../../assets/svg/CAC.svg';

const Images = {
  // Header
  'header/people': People,

  // Coaching
  checkbox: Checkbox,
  cancel: Cancel,
  pending: Pending,
  flag: Flag,
  plus: Plus,

  // Tiles
  'tile/protocols': Protocols,
  'tile/people': TilePeople,
  'tile/brain-health': BrainHealth,
  'tile/notifications': Notifications,
  'tile/documents': Documents,
  'tile/team-roster': TeamRoster,
  'tile/hockey-player': HockeyPlayer,
  'tile/training': Training,

  // Training
  'training/CAC': CAC,

  // misc
  hamburger: Hamburger,
  reload: Reload,
};

export type SVGImageType = keyof typeof Images;
export interface SVGProps extends SvgProps {
  image?: SVGImageType;
}

export const SVG: React.FC<SVGProps> = ({ image, ...props }: SVGProps) => {
  if (!image) {
    return null;
  }

  const Svg = Images[image];

  if (!Svg) {
    return null;
  }

  return <Svg {...props} />;
};
