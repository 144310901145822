import { useTranslations } from '../../../i18n/useTranslations';

export const useAuthErrorCode = () => {
  const { translate } = useTranslations();
  const translateAuthErrorCode = ({ message, code }: { message: string; code: string }) => {
    switch (code) {
      case 'auth/invalid-password':
      case 'auth/wrong-password':
      case 'auth/user-not-found':
      case 'auth/invalid-email':
        return translate.firebaseAuthErrors.invalidPasswordOrNouser;
      default:
        return message;
    }
  };

  return translateAuthErrorCode;
};
