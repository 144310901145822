import { orderBy } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { Symptom } from '../FindSubject/__generated__/Symptom';
import { AssessContextState, SymptomValue } from '../../Assess.interface';
import { AssessChrome } from '../../AssessChrome';
import { SymptomResult } from '../../../../types/globalTypes';
import {
  AppButton,
  AppMarkdown,
  AppScrollView,
  AppText,
  Checkbox,
  ProtocolAttribution,
  Spacer,
} from '../../../../uiComponents';

export interface StepSymptomsProps {
  stepId: string;
  name: string;
  instruction: string;
  symptoms: Symptom[];
  onNext: (nextState: AssessContextState) => void;
}

export const StepSymptoms: React.FC<StepSymptomsProps> = (props) => {
  const { stepId, name, instruction, symptoms, onNext } = props;
  const { translate } = useTranslations();

  const { state, dispatch } = useCurrentAssessment();
  const { steps, selectedProtocolOption } = state;
  const protocol = selectedProtocolOption?.protocol;

  if (!protocol) {
    throw new Error('Protocol not found');
  }
  const stepState = steps.find(({ stepId: _stepId }) => _stepId === stepId);

  const protocolStepConfig = protocol.removeProtocol.steps.find(({ id: _stepId }) => _stepId === stepId);

  if (protocolStepConfig?.__typename !== 'StepSymptom' || stepState?.type !== 'symptom') {
    throw new Error('Protocol step mismatch');
  }

  const allSymptoms = protocolStepConfig.symptoms;

  const viewed = stepState?.viewed;
  const canAdvance = viewed ? true : false;
  const options = orderBy<Symptom>((symptom) => symptom.order, 'asc', symptoms).map(({ id, value }) => ({
    label: value,
    value: id,
  }));

  const value =
    !stepState?.result || stepState.type !== 'symptom'
      ? []
      : stepState.result.filter(({ result }) => result === SymptomResult.PRESENT).map(({ symptomId }) => symptomId);

  const haveSymptoms = value.length > 0;

  useEffect(() => {
    if (!viewed) {
      dispatch({ type: 'setSymptomStepViewed', payload: { stepId } });
    }
  }, [viewed]);

  const handleSelect = (values: string[]) => {
    const _symptomResults: SymptomValue[] = allSymptoms.map(({ id }) => {
      if (!viewed) {
        return {
          symptomId: id,
          result: null,
        };
      } else {
        return {
          symptomId: id,
          result: values.includes(id) ? SymptomResult.PRESENT : SymptomResult.ABSENT,
        };
      }
    });
    dispatch({
      type: 'setStepValues',
      payload: {
        stepId,
        type: 'symptom',
        results: _symptomResults,
      },
    });
  };

  const handleNextPress = () => onNext(state);

  const content = (
    <AppScrollView>
      <Spacer
        items={{
          titleArea: (
            <Spacer
              items={{
                title: <AppText font="header2">{name}</AppText>,
                attribution: (
                  <ProtocolAttribution protocolUrl={protocol?.protocolUrl} protocolName={protocol?.protocolName} />
                ),
              }}
            />
          ),
          instruction: <AppMarkdown>{instruction}</AppMarkdown>,
          challenge: <Checkbox value={value} options={options} onSelect={handleSelect} />,
        }}
      />
    </AppScrollView>
  );

  const bottomPanel = (
    <AppButton
      disabled={!canAdvance}
      onPress={handleNextPress}
      style={{ width: 300, alignSelf: 'flex-start', marginBottom: 15 }}
    >
      {haveSymptoms ? translate.label.next : translate.label.none}
    </AppButton>
  );

  return (
    <AssessChrome
      content={
        <>
          {content}
          {bottomPanel}
        </>
      }
    />
  );
};
