import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { SimpleCaseFragment } from '../../../../../fragments/SimpleCaseFragment';

export const USER_CASE_LOGS_QUERY = gql`
  query UserCaseLogs {
    userCaseLogs {
      ... on Case {
        ...SimpleCase
      }
    }
  }
  ${SimpleCaseFragment}
`;

export const useUserCaseLogsQuery = (options?) => useQuery(USER_CASE_LOGS_QUERY, options);
