import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { debounce } from 'lodash';
import { Icon, IconName } from '../Icon/Icon';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';
import { useColors } from '../../shared/Ui';
import { AppText, AppTextVariantKeys } from '../AppText/AppText';
import { useIsMobile } from '../../hooks/ui/useResponsive';

export type AppButtonType = 'default' | 'secondary' | 'outline' | 'danger';

type AppButtonSize = 'large' | 'small' | 'extraSmall' | 'extraLarge';

const appButtonFonts = {
  extraLarge: 'header6' as AppTextVariantKeys,
  large: 'labelDefault' as AppTextVariantKeys,
  small: 'labelDefault' as AppTextVariantKeys,
  extraSmall: 'labelSmall' as AppTextVariantKeys,
};
export interface AppButtonProps {
  onPress?: (e?: any) => void;
  type?: AppButtonType;
  size?: AppButtonSize;
  icon?: IconName;
  disabled?: boolean;
  loading?: boolean;
  style?: ViewStyle;
  children?: string;
  invert?: boolean;
  afterLabel?: React.ReactNode;
}

export const AppButton: React.FC<AppButtonProps> = ({
  children,
  onPress: _onPress,
  type = 'default',
  size = 'small',
  afterLabel = null,
  icon,
  disabled,
  loading,
  style,
}) => {
  const colors = useColors();
  const isMobile = useIsMobile();
  const onPress = _onPress ? debounce(_onPress, 300, { leading: true, trailing: false }) : undefined;
  const getTextColor = (_type: AppButtonType) => {
    switch (_type) {
      case 'outline':
        return 'blue';
      default:
        return 'white';
    }
  };

  const getLoadingIndicatorColor = (_type: AppButtonType) => {
    switch (_type) {
      case 'outline':
        return 'blue';
      default:
        return 'white';
    }
  };

  const textColor = getTextColor(type);

  const loadingIndicatorColor = getLoadingIndicatorColor(type);

  const getSpacingFactor = (_size: AppButtonSize) => {
    switch (_size) {
      case 'extraLarge':
        return isMobile ? { vertical: 15, horizontal: 20 } : { vertical: 25, horizontal: 50 };
      case 'large':
        return isMobile ? { vertical: 10, horizontal: 20 } : { vertical: 20, horizontal: 30 };
      case 'small':
        return isMobile ? { vertical: 8, horizontal: 15 } : { vertical: 10, horizontal: 25 };
      case 'extraSmall':
        return { vertical: 5, horizontal: 15 };
    }
  };

  const spacing = getSpacingFactor(size);

  const secondaryStyles = StyleSheet.create({
    container: {
      backgroundColor: colors.green,
      borderColor: colors.green,
    },
    labelText: {
      color: colors[textColor],
    },
    label: {},
    icon: {
      color: colors[textColor],
    },
    spinner: {
      color: colors[textColor],
    },
  });

  const defaultStyles = StyleSheet.create({
    container: {
      backgroundColor: colors.blue,
      borderColor: colors.blue,
    },
    labelText: {
      color: colors[textColor],
    },
    label: {},
    icon: {
      color: colors[textColor],
    },
    spinner: {
      color: colors[textColor],
    },
  });

  const dangerStyles = StyleSheet.create({
    container: {
      backgroundColor: colors.red,
      borderColor: colors.red,
    },
    labelText: {
      color: colors[textColor],
    },
    label: {},
    icon: {
      color: colors[textColor],
    },
    spinner: {
      color: colors[textColor],
    },
  });

  const outlineStyles = StyleSheet.create({
    container: {
      backgroundColor: colors.white,
      borderColor: colors.blue,
    },
    labelText: {
      color: colors[textColor],
    },
    label: {},
    icon: {
      color: colors[textColor],
    },
    spinner: {
      color: colors[textColor],
    },
  });

  const getVariantStyle = (_type: AppButtonType) => {
    switch (_type) {
      case 'outline':
        return outlineStyles;
      case 'danger':
        return dangerStyles;
      case 'default':
        return defaultStyles;
      case 'secondary':
        return secondaryStyles;
      default:
        return defaultStyles;
    }
  };

  const variantStyle = getVariantStyle(type);

  const baseStyles = StyleSheet.create({
    container: {
      borderRadius: 99,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: spacing.vertical,
      paddingHorizontal: spacing.horizontal,
      borderWidth: 2,
      alignSelf: 'flex-start',
      flexGrow: 0,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    labelText: {
      textAlign: 'center',
    },
    icon: {
      marginRight: children ? 10 : 0,
    },
    afterLabel: {
      marginLeft: children ? 10 : 0,
      marginVertical: -4,
    },
    spinner: {
      marginRight: children ? 20 : 0,
      marginVertical: -4,
    },
    disabled: disabled ? { opacity: 0.5 } : {},
    loading: loading ? { opacity: 0.5 } : {},
  });

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled || loading}>
      <View
        style={StyleSheet.flatten([
          baseStyles.container,
          variantStyle.container,
          baseStyles.disabled,
          baseStyles.loading,
          style,
        ])}
      >
        <View style={StyleSheet.flatten([baseStyles.label, variantStyle.label])}>
          {loading ? (
            <ActivityIndicator
              size="small"
              color={loadingIndicatorColor}
              style={StyleSheet.flatten([baseStyles.spinner, variantStyle.spinner])}
            />
          ) : null}
          {icon && !loading ? (
            <Icon name={icon} size={24} style={StyleSheet.flatten([baseStyles.icon, variantStyle.icon])} />
          ) : null}
          <AppText
            color={textColor}
            font={appButtonFonts[size]}
            style={StyleSheet.flatten([baseStyles.labelText, variantStyle.labelText])}
            gutter={false}
          >
            {children}
          </AppText>
          {afterLabel ? <View style={baseStyles.afterLabel}>{afterLabel}</View> : null}
        </View>
      </View>
    </TouchableOpacity>
  );
};
