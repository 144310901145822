import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';
import { UpdateOrganizationStatus, UpdateOrganizationStatusVariables } from './__generated__/UpdateOrganizationStatus';

const UPDATE_ORGANIZATION_STATUS = gql`
  mutation UpdateOrganizationStatus($organizationId: String!, $status: OrganizationStatusEnum!, $expiresAt: DateTime) {
    updateOrganizationStatus(organizationId: $organizationId, status: $status, expiresAt: $expiresAt) {
      ... on OrganizationDTO {
        id
        name
        status
        expiresAt
        createdAt
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useUpdateOrganizationStatus = (
  options?: MutationHookOptions<UpdateOrganizationStatus, UpdateOrganizationStatusVariables>
) => useMutation(UPDATE_ORGANIZATION_STATUS, options);
