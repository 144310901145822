import { getAuth, signInWithCustomToken } from '@firebase/auth';
import { useFirebase } from '../Firebase';

export const useSignInWithCustomToken = () => {
  const { firebase } = useFirebase();

  return async (token: string) => {
    const res = await signInWithCustomToken(getAuth(firebase), token);

    if (!res.user) {
      throw new Error('User property undefined');
    }

    return res.user;
  };
};
