import { useTranslations } from '../../../../i18n/useTranslations';
import * as React from 'react';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { Content } from './Content';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { useNavigation } from '@react-navigation/native';

export interface IndexProps {}

export const Index: React.FC<IndexProps> = (props) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();

  const onBack = () => navigation.navigate('LoggedIn_Home');

  return (
    <Chrome
      navBarProps={{ onBack }}
      mode="title-card"
      titleText={translate.navigation.superAdmin}
      content={<Content />}
    />
  );
};
