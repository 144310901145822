import React from 'react';
import { StyleSheet } from 'react-native';
import {
  AppButton,
  AppMarkdown,
  AppScrollView,
  AppText,
  Layout,
  NotchView,
  ProtocolAttribution,
} from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AssessContextState } from '../../Assess.interface';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { AssessChrome } from '../../AssessChrome';

export interface StepInformationProps {
  name: string;
  instruction: string;
  stepId: string;
  onNext: (nextState: AssessContextState) => void;
}

export const StepInformation: React.FC<StepInformationProps> = (props) => {
  const { name, instruction, onNext } = props;
  const { translate } = useTranslations();
  const { state } = useCurrentAssessment();
  const protocol = state.selectedProtocolOption?.protocol;

  const handleNextButtonPress = () => onNext(state);

  const styles = StyleSheet.create({
    buttonPanel: {
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      paddingBottom: 24,
    },
  });

  const content = (
    <Layout>
      <ProtocolAttribution protocolUrl={protocol?.protocolUrl} protocolName={protocol?.protocolName} />
      <AppText font="header2" gutter={true}>
        {name}
      </AppText>
      <AppScrollView>
        <AppMarkdown>{instruction}</AppMarkdown>
      </AppScrollView>
    </Layout>
  );

  const bottomPanel = (
    <NotchView style={styles.buttonPanel}>
      <AppButton onPress={handleNextButtonPress} style={{ width: 300, alignSelf: 'flex-start', marginBottom: 15 }}>
        {translate.label.next}
      </AppButton>
    </NotchView>
  );

  return <AssessChrome content={content} footer={bottomPanel} />;
};
