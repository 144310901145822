import React, { useState } from 'react';
import { AppModal, AppText, AppButton } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { GetProtocols_getSubject_Subject } from '../screens/FindSubject/__generated__/GetProtocols';

interface RemoveModal {
  visible: boolean;
  subject?: GetProtocols_getSubject_Subject;
}

type HandleRemoveWithoutAsssessment = (e: any) => void;

export const useRemoveWithoutAssessment = (handleRemoveWithoutAssessment: HandleRemoveWithoutAsssessment) => {
  const [removeModal, setRemoveModal] = useState<RemoveModal>({ visible: false });

  const { translate, translateWithVars } = useTranslations();

  const RemoveModal: React.FC = () => {
    return (
      <AppModal
        mode="dialog"
        onDismiss={() => setRemoveModal({ visible: false })}
        visible={removeModal.visible}
        title={translate.protocol.removeModal.title}
        buttons={{
          cancel: (
            <AppButton type="outline" onPress={() => setRemoveModal({ visible: false, subject: undefined })}>
              {translate.label.cancel}
            </AppButton>
          ),
          submit: (
            <AppButton type="danger" onPress={handleRemoveWithoutAssessment}>
              {translate.label.submit}
            </AppButton>
          ),
        }}
      >
        <AppText>{`${translateWithVars(translate.protocol.removeModal.content1, {
          subjectName: removeModal.subject?.fullName,
        })}\n\n`}</AppText>
        <AppText>{`${translate.protocol.removeModal.content2}\n\n`}</AppText>
        <AppText>{`${translate.protocol.removeModal.content3}`}</AppText>
      </AppModal>
    );
  };

  return {
    removeModal,
    setRemoveModal,
    RemoveModal,
  };
};
