import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../hooks/useMutation';
import { importFile, importFileVariables } from './__generated__/importFile';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';

export const IMPORT_FILE = gql`
  mutation importFile($input: ImportInput!) {
    importFile(input: $input) {
      ... on ImportResult {
        organizationId
        success
        error
        partialErrors {
          rowNumber
          reason
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useImportFile = (options?: MutationHookOptions<importFile, importFileVariables>) =>
  useMutation<importFile, importFileVariables>(IMPORT_FILE, options);
