import { getAuth, getIdTokenResult } from '@firebase/auth';
import { useFirebase } from '../Firebase';

export const useUpdateToken = () => {
  const { firebase } = useFirebase();
  return () => {
    const currentUser = getAuth(firebase).currentUser;

    if (!currentUser) {
      throw new Error('Current User is not set');
    }

    return getIdTokenResult(currentUser, true);
  };
};
