import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Chrome as ChromeBase, ChromeProps as ChromeBaseProps } from '../../shared/Chrome/Chrome';
import { RootNavType } from '../../RootNavigator.interface';
import { useColors } from '../../shared/Ui/hooks';
import { Icon } from '../../uiComponents';

export type LoggedOutChromeProps = ChromeBaseProps;

const LoggedOutNavBar = () => {
  const { navigate } = useNavigation<RootNavType<any>>();
  const colors = useColors();
  const handleHomePressed = () => navigate('Welcome');

  return (
    <TouchableOpacity style={{ marginLeft: 'auto' }} onPress={handleHomePressed}>
      <Icon name={'arrow-left'} size={24} color={colors.white} />
    </TouchableOpacity>
  );
};

export const LoggedOutChrome: React.FC<LoggedOutChromeProps> = (props) => {
  return <ChromeBase hideNavigation={true} navbar={<LoggedOutNavBar />} {...props} />;
};
