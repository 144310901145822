import { RtaProtocolType } from '../../../../../types/globalTypes';
import { DocumentSubmissionStage } from '../fragments/__generated__/DocumentSubmissionStage';
import { Protocol } from '../fragments/__generated__/Protocol';
import { SignOffStage } from '../fragments/__generated__/SignOffStage';
import {
  UpdateEducationProtocolItemConfigFormValue,
  UpdateProtocolConfigFormValue,
  UpdateRemoveProtocolStepConfigFormValue,
  UpdateReturnProtocolChildProtocolStageConfigFormValue,
} from '../ManageProtocol.interface';

const mapReturnProtocolStages = (stages: Array<SignOffStage | DocumentSubmissionStage>) =>
  stages.map<UpdateReturnProtocolChildProtocolStageConfigFormValue>((stage) => {
    if (stage.__typename === 'DocumentSubmissionStage') {
      return {
        type: 'document-submission',
        authorizedRole: stage.authorizedRole,
        signOff: null,
        description: stage.description,
        name: stage.name,
        templateDocument: {
          currentDocument: stage.templateDocument,
          nextDocument: {
            id: stage.templateDocument?.id,
            upload: null,
          },
        },
        isTimeGateSelected: stage.timeGateHours ? true : false,
        timeGateHours: stage.timeGateHours ? stage.timeGateHours.toString() : '',
      };
    } else if (stage.__typename === 'SignOffStage') {
      return {
        type: 'signoff',
        authorizedRole: stage.authorizedRole,
        description: stage.description,
        name: stage.name,
        isTimeGateSelected: stage.timeGateHours ? true : false,
        timeGateHours: stage.timeGateHours ? stage.timeGateHours.toString() : '',
      };
    } else {
      throw new Error('Invalid stage type');
    }
  });
export const useMapCurrentProtocolToFormValues = () => (organizationId: string, currentProtocol: Protocol) => {
  const returnToSportProtocol = currentProtocol.returnProtocol.childProtocols.find(
    ({ type }) => type === RtaProtocolType.SPORT
  );
  const returnToLearnProtocol = currentProtocol.returnProtocol.childProtocols.find(
    ({ type }) => type === RtaProtocolType.LEARN
  );
  const initialValues: UpdateProtocolConfigFormValue = {
    protocolName: currentProtocol.protocolName || '',
    protocolUrl: currentProtocol.protocolUrl || '',
    organizationId,
    removeProtocol: {
      discretionaryInstruction: currentProtocol.removeProtocol.discretionaryInstruction || '',
      emergencyInstruction: currentProtocol.removeProtocol.emergencyInstruction || '',
      removeInstruction: currentProtocol.removeProtocol.removeInstruction || '',
      resumeInstruction: currentProtocol.removeProtocol.resumeInstruction || '',
      redFlag: {
        flags: currentProtocol.removeProtocol.redFlag.flags.map(({ key, value }) => ({
          key,
          value,
        })),
        instruction: currentProtocol.removeProtocol.redFlag.instruction,
        name: currentProtocol.removeProtocol.redFlag.name,
      },
      steps: currentProtocol.removeProtocol.steps.map<UpdateRemoveProtocolStepConfigFormValue>((step) => {
        if (step.__typename === 'StepChallenge') {
          return {
            type: 'challenge',
            challenges: step.challenges.map(({ key, value }) => ({
              key,
              value,
            })),
            instruction: step.instruction,
            name: step.name,
          };
        } else if (step.__typename === 'StepSymptom') {
          return {
            type: 'symptom',
            symptoms: step.symptoms.map(({ key, value }) => ({
              key,
              value,
            })),
            instruction: step.instruction,
            name: step.name,
          };
        } else {
          throw new Error('Invalid step type');
        }
      }),
      incidentReport: {
        instruction: currentProtocol.removeProtocol.incidentReport.instruction || '',
        templateDocument: {
          currentDocument: currentProtocol.removeProtocol.incidentReport.templateDocument,
          nextDocument: {
            id: currentProtocol.removeProtocol.incidentReport.templateDocument?.id,
            upload: null,
          },
        },
      },
    },
    returnProtocol: {
      medicalAssessment: {
        instruction: currentProtocol.returnProtocol.medicalAssessment.instruction,
        name: currentProtocol.returnProtocol.medicalAssessment.name,
        templateDocument: {
          currentDocument: currentProtocol.returnProtocol.medicalAssessment.templateDocument,
          nextDocument: {
            id: currentProtocol.returnProtocol.medicalAssessment.templateDocument?.id,
            upload: null,
          },
        },
      },
      returnToLearn: returnToLearnProtocol
        ? { enabled: true, stages: mapReturnProtocolStages(returnToLearnProtocol.stages) }
        : { enabled: false, stages: [] },
      returnToSport: returnToSportProtocol
        ? { enabled: true, stages: mapReturnProtocolStages(returnToSportProtocol.stages) }
        : { enabled: false, stages: [] },
    },
    educationProtocol: {
      items: currentProtocol.educationProtocol.items.map<UpdateEducationProtocolItemConfigFormValue>((item) => {
        if (item.__typename === 'EducationProtocolItemDocument') {
          return {
            type: 'document',
            instruction: item.instruction,
            name: item.name,
            useForDesignatedPerson: item.useForDesignatedPerson || false,
            useForGuardian: item.useForGuardian || false,
            useForSubject: item.useForSubject || false,
            document: {
              currentDocument: item.document,
              nextDocument: {
                id: item.document.id,
                upload: null,
              },
            },
          };
        } else if (item.__typename === 'EducationProtocolItemUrl') {
          return {
            type: 'url',
            instruction: item.instruction,
            name: item.name,
            useForDesignatedPerson: item.useForDesignatedPerson || false,
            useForGuardian: item.useForGuardian || false,
            useForSubject: item.useForSubject || false,
            url: item.url,
            initialDocumentId: null,
          };
        } else {
          throw new Error('Invalid education item type');
        }
      }),
    },
  };

  return initialValues;
};
