import React from 'react';
import { StyleSheet } from 'react-native';
import { Check, CheckboxOptionType } from '.././Checkbox';
import { AppMarkdown } from '.././AppMarkdown/AppMarkdown';

interface AppCheckboxOptionProps<T> {
  option: CheckboxOptionType<T>;
  checked?: boolean;
  onPress: (option: CheckboxOptionType<T>) => void;
}

export function MarkdownCheckboxOption<T = any>({ option, checked, onPress }: AppCheckboxOptionProps<T>) {
  const styles = StyleSheet.create({
    htmlLabel: {
      fontSize: 16,
      lineHeight: 16 * 1.5,
      flex: 1,
      marginBottom: 0,
    },
  });

  const handlePress = () => onPress(option);

  const label = <AppMarkdown style={styles.htmlLabel}>{option.label}</AppMarkdown>;

  return (
    <Check checked={checked} onPress={handlePress}>
      {label}
    </Check>
  );
}
