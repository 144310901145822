import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';

const GET_ASSOCIATIONS = gql`
    query GetAssociations {
        associations {
            ... on Association {
                id
                name
            }
        }
    }
`

export const useGetAssociations = (options?) => useQuery(GET_ASSOCIATIONS, options)