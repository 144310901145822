import React, { useEffect } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { useFilePicker } from 'use-file-picker';
import { first } from 'lodash/fp';
import { AppButton } from '../AppButton/AppButton';
import { FileButtonProps } from './FileButton.interface';
import { Icon } from '../Icon/Icon';
import { getMediaType } from './helpers';

export const FileButton: React.FC<FileButtonProps> = ({
  children,
  onFileChange,
  onFileClear,
  file,
  mediaType = 'all',
  ...props
}) => {
  const [openFilePicker, { filesContent }] = useFilePicker({
    multiple: false,
    readAs: 'DataURL',
    accept: getMediaType(mediaType),
  });

  if (Platform.OS !== 'web') {
    throw new Error('Wrong platform for component <AppFileButton />');
  }

  const firstFilesContent = first(filesContent);

  useEffect(() => {
    if (firstFilesContent) {
      fetch(firstFilesContent.content)
        .then((data) => data.blob())
        .then((file) =>
          onFileChange({
            filename: firstFilesContent.name,
            file,
          })
        );
    }
  }, [firstFilesContent]);

  const handlePickDocument = async () => {
    openFilePicker();
  };

  return (
    <AppButton
      onPress={handlePickDocument}
      children={children}
      type={file ? 'outline' : 'default'}
      icon={file ? 'paperclip' : 'file'}
      afterLabel={
        file ? (
          <TouchableOpacity onPress={onFileClear}>
            <Icon name="trash" size={24} color="red" />
          </TouchableOpacity>
        ) : null
      }
      {...props}
    />
  );
};
