import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { CreateSeason, CreateSeasonVariables } from './__generated__/CreateSeason';
import { SeasonFragment } from '../fragments/SeasonFragment';
import { GetSeasonsByOrganizationId } from './__generated__/GetSeasonsByOrganizationId';
import { GET_SEASONS_BY_ORGANIZATION_ID_QUERY } from './useGetSeasonsByOrganizationId';

export const CREATE_SEASON_MUTATION = gql`
  mutation CreateSeason($organizationId: ID!, $name: String!, $startDate: DateTime!, $endDate: DateTime) {
    createSeason(organizationId: $organizationId, name: $name, startDate: $startDate, endDate: $endDate) {
      ... on Season {
        ...Season
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${SeasonFragment}
  ${ApiErrorFragment}
`;

export const useCreateSeason = (
  organizationId: string,
  options?: MutationHookOptions<CreateSeason, CreateSeasonVariables>
) => {
  return useMutation<CreateSeason, CreateSeasonVariables>(CREATE_SEASON_MUTATION, {
    ...options,
    update(cache, { data }) {
      const cachedSeasons = cache.readQuery<GetSeasonsByOrganizationId>({
        query: GET_SEASONS_BY_ORGANIZATION_ID_QUERY,
        variables: {
          organizationId,
        },
      });

      if (cachedSeasons && data?.createSeason.__typename === 'Season') {
        cache.writeQuery<GetSeasonsByOrganizationId>({
          query: GET_SEASONS_BY_ORGANIZATION_ID_QUERY,
          variables: {
            organizationId,
          },
          data: {
            seasons: [...cachedSeasons.seasons, data?.createSeason],
          },
        });
      }
    },
  });
};
