import { gql } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { PhoneNumberFragment } from '../../../fragments/PhoneNumber';
import { useQuery } from '../../../hooks/useQuery';
import { GetAccount } from './__generated__/GetAccount';

export const GET_ACCOUNT = gql`
  query GetAccount {
    currentUser {
      ... on CurrentUser {
        ...MyAccount
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  fragment MyAccount on CurrentUser {
    id
    firstName
    lastName
    birthday
    email
    phoneNumber {
      ...PhoneNumber
    }
  }
  ${PhoneNumberFragment}
  ${ApiErrorFragment}
`;

export const useGetAccount = () => useQuery<GetAccount, {}>(GET_ACCOUNT);
