import React from 'react';
import { Feather, Octicons } from '@expo/vector-icons';
import { IconProps } from '@expo/vector-icons/build/createIconSet';
import { colors } from '../../shared/Ui/defaultStyleConfig';

type GlyphMap = typeof Feather.glyphMap;

export type IconName = keyof GlyphMap | 'organization' | 'accessibility';

interface AppIconProps extends IconProps<IconName> {}

export const Icon: React.FC<AppIconProps> = ({ name, color = colors.navyDark, size = 24, ...props }) => {
  switch (name) {
    case 'organization':
    case 'accessibility':
      return <Octicons name={name} color={color} size={size} {...props} />;
    default:
      return <Feather name={name} color={color} size={size} {...props} />;
  }
};
