import { FieldArray } from 'formik';
import React from 'react';
import { EducationProtocolItemsConfig } from './EducationProtocolItemsConfig';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Spacer, SpacerItems } from '../../../../../../uiComponents';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: { education: translate },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();

  const items: SpacerItems = {
    heading: (
      <>
        <AppText font="header3" gutter={true}>
          {rootTranslate.admin.manageProtocol.index.sectionEducation}
        </AppText>
        <AppText>{translate.instruction}</AppText>
      </>
    ),
    items: (
      <FieldArray name="educationProtocol.items" children={(vals) => <EducationProtocolItemsConfig {...vals} />} />
    ),
  };

  return <Spacer size="large" items={items} />;
};
