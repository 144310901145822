import React, { ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { Separator } from '../Separator/Separator';
import { SubjectListItemProps } from './UserList.interface';

export const UserListItem: React.FC<SubjectListItemProps> = ({
  style,
  separator,
  onPress,
  displayItem,
  rightAlignLastItem,
  mobileRearrangement,
  customWidths,
}) => {
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    root: {
      paddingHorizontal: 15,
      paddingTop: 10,
    },
    displayItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: customWidths ? 'flex-start' : 'space-between',
      flexWrap: 'wrap',
      paddingHorizontal: isMobile ? 0 : 20,
    },
    column: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingRight: 10,
    },
    columnForWeb: {
      maxWidth: displayItem.length <= 4 ? `${100 / displayItem.length}%` : '25%',
      flex: 1,
    },
    columnForMobile: {
      width: '50%',
    },
    lastColumnForMobile: {
      width: '90%',
    },
  });

  const TouchableWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

  const isRightAlignedLastItem = (index) => {
    if (!isMobile) {
      return rightAlignLastItem && displayItem.length !== 1 && (index + 1) % displayItem.length === 0;
    } else {
      return (index + 1) % 2 === 0;
    }
  };

  const getColumnOrderForMobile = (columns: ReactNode[]): ReactNode[] => {
    if (mobileRearrangement) {
      return columns.map((column, index) => columns[mobileRearrangement[index]]);
    } else {
      return columns;
    }
  };

  const displayItemToRender = mobileRearrangement && isMobile ? getColumnOrderForMobile(displayItem) : displayItem;

  const getMobileStyles = (index: number) => {
    if (index === displayItem.length - 1 && displayItem.length % 2 === 1) {
      return styles.lastColumnForMobile;
    } else {
      return styles.columnForMobile;
    }
  };

  return (
    <View style={StyleSheet.flatten([styles.root, style])}>
      <TouchableWrapper
        condition={onPress}
        wrapper={(children) => <TouchableOpacity onPress={onPress}>{children}</TouchableOpacity>}
      >
        <View style={styles.displayItem}>
          {displayItemToRender &&
            displayItemToRender.map((column, index) => (
              <View
                style={[
                  styles.column,
                  isMobile ? getMobileStyles(index) : styles.columnForWeb,
                  isRightAlignedLastItem(index)
                    ? { justifyContent: 'flex-end', flex: isMobile ? 1 : 0, marginLeft: isMobile ? 0 : 70 }
                    : null,
                  customWidths && !isMobile ? { maxWidth: `${customWidths[index]}%`, flex: 1 } : null,
                ]}
              >
                {column}
              </View>
            ))}
        </View>
      </TouchableWrapper>
      {separator ? <Separator /> : null}
    </View>
  );
};
