import { SuperAdminNavItem } from '../SuperAdmin.interface';
import { Index } from '../screens/Index/Index';
import { CreateOrganization } from '../screens/CreateOrganization/CreateOrganization';
import { PaymentStatus } from '../screens/PaymentStatus/PaymentStatus';
import { ImportData } from '../screens/ImportData/ImportData';

export const useSuperAdminRoutes: () => SuperAdminNavItem[] = () => {
  return [
    {
      component: Index,
      label: 'SuperAdmin',
      name: 'LoggedIn_SuperAdmin_Index' as const,
      path: '',
    },
    {
      component: CreateOrganization,
      label: 'Create Organization',
      name: 'LoggedIn_SuperAdmin_CreateOrganization' as const,
      path: 'create-organization',
    },
    {
      component: ImportData,
      label: 'Import Data',
      name: 'LoggedIn_SuperAdmin_ImportData' as const,
      path: 'import-data',
    },
    {
      component: PaymentStatus,
      label: 'Payment Status',
      name: 'LoggedIn_SuperAdmin_PaymentStatus' as const,
      path: 'payment-status',
    },
  ];
};
