import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppMarkdown, AppModal } from '../../../../uiComponents';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';

export interface SuggestedResultDialogProps {
  visible: boolean;
  onDismiss: () => void;
  onRemove: () => void;
  onResume: () => void;
}

export const SuggestedResultDialog: React.FC<SuggestedResultDialogProps> = ({
  visible,
  onDismiss,
  onRemove,
  onResume,
}) => {
  const { translate, translateWithVars } = useTranslations();
  const { state } = useCurrentAssessment();

  const resumeInstructionArea = state.selectedProtocolOption?.protocol.removeProtocol.discretionaryInstruction ? (
    <AppMarkdown>{state.selectedProtocolOption.protocol.removeProtocol.discretionaryInstruction}</AppMarkdown>
  ) : null;

  return (
    <AppModal
      mode="dialog"
      title={translateWithVars(translate.step.reasonTomRemove, {
        name: state.subject?.fullName,
      })}
      onDismiss={onDismiss}
      children={resumeInstructionArea}
      buttons={{
        resume: <AppButton onPress={onResume}>{translate.label.resume}</AppButton>,
        remove: (
          <AppButton type="danger" onPress={onRemove}>
            {translate.removeFromSport}
          </AppButton>
        ),
      }}
      visible={visible}
    />
  );
};
