import React from 'react';
import { View } from 'react-native';
import { AppText } from '../../uiComponents';
import { FeatureFlag } from '../../types/globalTypes';
import { Role } from '../../types/globalTypes';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { useIsFeatureFlagAvailable } from '../../hooks/useIsFeatureFlagAvailable';
import { useTranslations } from '../../i18n/useTranslations';

interface CheckForFeatureFlagProps {
  featureFlag?: FeatureFlag;
  orCondition?: string;
  orRoles?: (Role | 'SUPER_ADMIN')[];
  children: React.ReactNode | React.ReactNode[];
}

export const CheckForFeatureFlag: React.FC<CheckForFeatureFlagProps> = (props) => {
  const { featureFlag, children, orCondition, orRoles } = props;

  const { data } = useIsFeatureFlagAvailable({
    variables: { featureFlag },
    skip: !featureFlag,
  });
  const { currentUser } = useCurrentUser();
  const { translate } = useTranslations();

  const hasRequiredRole =
    currentUser?.__typename === 'CurrentUser' &&
    orRoles &&
    orRoles.some((role) =>
      role === 'SUPER_ADMIN' ? currentUser.isSuperAdmin : currentUser.roles.map((item) => item.role).includes(role)
    );

  return data?.hasFeature || orCondition || hasRequiredRole ? (
    <View>{children}</View>
  ) : (
    <View>
      <AppText>{translate.featureFlag.noAccess}</AppText>
    </View>
  );
};
