import { SimpleCase } from '../../../../../fragments/__generated__/SimpleCase';
import { isApiError } from '../../../../../utils/isApiError';
import { useProfileContext } from '../../../ProfileContext';
import { useSubjectCaseLogsQuery } from './useSubjectCaseLogsQuery';
import { SubjectCaseLogsQuery } from './__generated__/SubjectCaseLogsQuery';
import { useUserCaseLogsQuery } from './userGetUserCaseLogsQuery';

const defaultState: SimpleCase[] = [];

const makeMyCaseLog = (data?: { userCaseLogs: SimpleCase[] }): SimpleCase[] | null => {
  if (!data || !data.userCaseLogs) {
    return null;
  }

  return data.userCaseLogs;
};

const makeSubjectCaseLog = (data?: SubjectCaseLogsQuery): SimpleCase[] | null => {
  if (!data || isApiError(data.getSubject) || data.getSubject.__typename !== 'Subject') {
    return null;
  }

  return data.getSubject.cases;
};

export const useCaseLogs = (): SimpleCase[] | null => {
  const { subjectId } = useProfileContext();

  let mode: 'me' | 'subject' | undefined;

  const isMe = !subjectId ? true : false;

  if (isMe) {
    mode = 'me';
  } else if (!isMe) {
    mode = 'subject';
  }

  const myCaseLogs = useUserCaseLogsQuery({
    skip: mode === 'me' ? false : true,
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
    hideLoading: true,
  });

  const subjectCaseLogs = useSubjectCaseLogsQuery({
    skip: mode === 'subject' ? false : true,
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
    variables: {
      subjectInput: {
        subjectId: subjectId as string,
      },
    },
    hideLoading: true,
  });

  switch (mode) {
    case 'me':
      return makeMyCaseLog(myCaseLogs.data);
    case 'subject':
      return makeSubjectCaseLog(subjectCaseLogs.data);
    default:
      return defaultState;
  }
};
