import { gql } from '@apollo/client';

export const ImageFragment = gql`
  fragment Image on Image {
    id
    thumbnail {
      id
      uri
    }
  }
`;
