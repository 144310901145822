import React, { useState } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { Chrome } from '../shared/Chrome/Chrome';
import { useGetProtocolByOrganization } from './hooks/useGetProtocolByOrganization';
import {
  ActivityIndicator,
  AppButton,
  AppLink,
  AppScrollView,
  AppText,
  CheckboxOption,
  MarkdownCheckboxOption,
  AppModal,
} from '../../uiComponents';
import { useApproveProtocol } from './hooks/useApproveProtocol';
import { RemovalStepDisplay } from './RemovalStepDisplay';
import { ReturnProtocol } from './ReturnProtocol';
import { isApiError } from '../../utils/isApiError';
import { useNotification } from '../../hooks/ui/useNotification';
import { AdminNavType } from '../Admin/Admin.interface';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../i18n/useTranslations';

export const Protocol: React.FC<{ route: { params: { organizationId: string; protocolId?: string } } }> = (props) => {
  const organizationId = props.route.params.organizationId;
  const protocolId = props.route.params.protocolId;
  const notify = useNotification();
  const { translate } = useTranslations();
  const [approved, setApproved] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [approveProtocol, { loading }] = useApproveProtocol(organizationId);

  const response = useGetProtocolByOrganization({
    variables: {
      organizationId,
      protocolId,
    },
  });

  const protocol = response.data?.currentProtocolByOrganization;
  if (!protocol || protocol?.__typename !== 'Protocol')
    return (
      <Chrome
        mode="title-card"
        titleText={translate.admin.manageOrganization.protocolLabel}
        content={<ActivityIndicator />}
      />
    );

  const styles = StyleSheet.create({
    title: {
      marginTop: 30,
      marginBottom: 15,
    },
    subTitle: {
      marginTop: 20,
      marginBottom: 15,
    },
    text: {
      marginBottom: 10,
    },
    educationProtocolItem: { marginBottom: 15 },
    templateButton: {
      marginVertical: 15,
    },
    approveProtocol: {
      marginTop: 20,
    },
    approveButton: {
      marginTop: 20,
    },
    modalButton: {
      marginTop: 20,
    },
  });

  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_Index'>>();

  const returnToLearnProtocol = protocol.returnProtocol.childProtocols.find((protocol) => protocol.type === 'LEARN');
  const returnToSportProtocol = protocol.returnProtocol.childProtocols.find((protocol) => protocol.type === 'SPORT');

  const onBack = () => navigation.navigate('LoggedIn_Admin', { screen: 'LoggedIn_Admin_Index', organizationId });

  const completeProtocolApproval = () => {
    setIsModalVisible(false);
  };

  return (
    <Chrome
      mode="title-card"
      autoScroll={false}
      titleText={translate.admin.manageOrganization.protocolLabel}
      navBarProps={{ onBack }}
      content={
        <>
          <AppModal
            mode="dialog"
            title={translate.protocol.approvalConfirmation.split(':')[0]}
            visible={isModalVisible}
            onDismiss={() => {
              completeProtocolApproval();
            }}
            children={<AppText>{translate.protocol.approvalConfirmation.split(':')[1]}</AppText>}
            buttons={{
              ok: (
                <AppButton style={styles.modalButton} onPress={() => completeProtocolApproval()}>
                  {translate.admin.manageOrganization.ok}
                </AppButton>
              ),
            }}
          />
          <AppScrollView>
            {!protocol.approved ? (
              <AppText font="labelDefault" color="grayDark" style={styles.text}>
                {translate.protocol.mustApprove}
              </AppText>
            ) : null}
            <AppText font="header1" style={styles.subTitle}>
              {`${translate.protocol.protocolName}: ${protocol.protocolName}`}
            </AppText>
            <View style={styles.subTitle}>
              <AppText font="labelDefault">{`${translate.protocol.link}: `}</AppText>
              <AppLink
                onPress={() => {
                  if (protocol.protocolUrl) Linking.openURL(protocol.protocolUrl);
                }}
              >
                {protocol.protocolUrl}
              </AppLink>
            </View>
            <AppText font="header2" style={styles.title}>
              {translate.protocol.education}
            </AppText>
            <AppText style={styles.text}>{translate.protocol.educationText}</AppText>
            {protocol.educationProtocol.items.map((item) => {
              const shownTo: string[] = [];
              if (item.useForDesignatedPerson) shownTo.push(translate.users.allDesignatedPersons);
              if (item.useForGuardian) shownTo.push(translate.users.guardians);
              if (item.useForSubject) shownTo.push(translate.users.subjects);
              return (
                <View style={styles.educationProtocolItem} key={item.id}>
                  <MarkdownCheckboxOption
                    option={{ value: item.id, label: item.instructionMarkdown }}
                    checked
                    onPress={() => {}}
                  />
                  <View style={styles.text}>
                    <AppText style={styles.text}>{`${translate.protocol.shownTo}: `}</AppText>
                    <AppText font="labelDefault" color="grayDark">
                      {shownTo.length ? shownTo.join(', ') : translate.protocol.noOne}
                    </AppText>
                  </View>
                </View>
              );
            })}
            <AppText font="header2" style={styles.title}>
              {translate.protocol.removal}
            </AppText>
            <AppText font="header3" style={styles.subTitle}>
              {`1. ${translate.protocol.redFlags}`}
            </AppText>
            <AppText style={styles.text}>{protocol.removeProtocol.redFlag.instruction}</AppText>
            {protocol.removeProtocol.redFlag.flags.map((redFlag) => (
              <CheckboxOption
                key={redFlag.id}
                option={{ value: redFlag.id, label: redFlag.value }}
                onPress={() => {}}
              />
            ))}
            {protocol.removeProtocol.steps.map((step, index) => (
              <RemovalStepDisplay {...step} index={index} key={step.id} />
            ))}
            <AppText font="header3" style={styles.subTitle}>
              {translate.protocol.removalInstructions}
            </AppText>
            <AppText font="labelDefault" style={styles.subTitle}>
              {translate.protocol.emergencyInstructionDescription}
            </AppText>
            <AppText style={styles.text}>{protocol.removeProtocol.emergencyInstruction || ' '}</AppText>
            <AppText font="labelDefault" style={styles.subTitle}>
              {translate.protocol.discretionaryInstructionDescription}
            </AppText>
            <AppText style={styles.text}>{protocol.removeProtocol.discretionaryInstruction || ' '}</AppText>
            <AppText font="labelDefault" style={styles.subTitle}>
              {translate.protocol.removeInstructionDescription}
            </AppText>
            <AppText style={styles.text}>{protocol.removeProtocol.removeInstruction || ' '}</AppText>
            <AppText font="labelDefault" style={styles.subTitle}>
              {translate.protocol.resumeInstructionDescription}
            </AppText>
            <AppText style={styles.text}>{protocol.removeProtocol.resumeInstruction || ' '}</AppText>
            <AppText font="header3" style={styles.subTitle}>
              {translate.protocol.incidentReport}
            </AppText>
            <AppText style={styles.subTitle}>{translate.protocol.incidentReportText}</AppText>
            <AppText font="labelDefault" style={styles.subTitle}>
              {translate.protocol.incidentReportInstructionDescription}
            </AppText>
            <AppText style={styles.text}>{protocol.removeProtocol.incidentReport.instruction || ' '}</AppText>
            {protocol.removeProtocol.incidentReport.templateDocument ? (
              <AppButton
                onPress={() => {
                  if (protocol.removeProtocol.incidentReport.templateDocument?.downloadUri) {
                    Linking.openURL(protocol.removeProtocol.incidentReport.templateDocument.downloadUri);
                  }
                }}
                style={styles.templateButton}
              >
                {translate.protocol.downloadTemplate}
              </AppButton>
            ) : (
              <AppText font="labelDefault">{translate.protocol.noTemplateDocument}</AppText>
            )}
            <AppText font="header2" style={styles.title}>
              {translate.protocol.return}
            </AppText>
            <AppText style={styles.text}>{translate.protocol.returnText}</AppText>
            <AppText font="header3" style={styles.title}>
              {translate.label.medicalAssessment}
            </AppText>
            <AppText style={styles.text}>{protocol.returnProtocol.medicalAssessment.instruction}</AppText>
            <AppButton
              onPress={() => {
                if (protocol.returnProtocol.medicalAssessment.templateDocument?.downloadUri) {
                  Linking.openURL(protocol.returnProtocol.medicalAssessment.templateDocument.downloadUri);
                }
              }}
              style={styles.templateButton}
            >
              {translate.protocol.downloadTemplate}
            </AppButton>
            <AppText>{translate.protocol.medicalAssessmentApproval}</AppText>
            {returnToLearnProtocol ? <ReturnProtocol protocol={returnToLearnProtocol} /> : null}
            {returnToSportProtocol ? <ReturnProtocol protocol={returnToSportProtocol} /> : null}
            {!protocolId ? (
              !protocol.approved ? (
                <View style={styles.approveProtocol}>
                  <CheckboxOption
                    option={{
                      label: translate.protocol.approval,
                      value: 'accepted',
                    }}
                    checked={approved}
                    onPress={() => setApproved(!approved)}
                  />
                  <AppButton
                    disabled={!approved}
                    loading={loading}
                    onPress={() =>
                      approveProtocol({
                        variables: { protocolId: protocol.id },
                      }).then(({ data }) => {
                        if (!isApiError(data?.approveProtocol)) {
                          // TODO: restore the notify function below once notifications have been fixed. Use the popup modal for now
                          // notify({ description: translate.protocol.approvalConfirmation });
                          response.refetch();
                          setIsModalVisible(true);
                        }
                      })
                    }
                    style={styles.approveButton}
                  >
                    {translate.label.submit}
                  </AppButton>
                </View>
              ) : (
                <AppText font="labelDefault" style={{ textAlign: 'center' }}>
                  {translate.protocol.approvalConfirmation}
                </AppText>
              )
            ) : null}
          </AppScrollView>
        </>
      }
    />
  );
};
