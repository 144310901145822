import { FieldArray, getIn, useFormikContext } from 'formik';
import React from 'react';
import {
  UpdateProtocolConfigFormValue,
  UpdateReturnProtocolChildProtocolConfigFormValue,
} from '../../ManageProtocol.interface';
import { ReturnProtocolStagesConfig } from './ReturnProtocolStagesConfig';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, RadioButton, Spacer } from '../../../../../../uiComponents';

export interface ReturnProtocolChildProtocolConfigProps {
  name: string;
  title: string;
}

export const ReturnProtocolChildProtocolConfig: React.FC<ReturnProtocolChildProtocolConfigProps> = ({
  name,
  title,
}) => {
  const { values: _values, setFieldValue } = useFormikContext<UpdateProtocolConfigFormValue>();

  const value = getIn(_values, name) as UpdateReturnProtocolChildProtocolConfigFormValue;
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            return: { childProtocol: translate },
          },
        },
      },
    },
  } = useTranslations();

  const handleChangeType = (enabled: boolean) => {
    if (enabled) {
      const val: UpdateReturnProtocolChildProtocolConfigFormValue = {
        enabled: true,
        stages: [],
      };

      setFieldValue(name, val);
    } else {
      const val: UpdateReturnProtocolChildProtocolConfigFormValue = {
        enabled: false,
        stages: [],
      };
      setFieldValue(name, val);
    }
  };

  return (
    <Spacer
      size="large"
      items={{
        heading: (
          <AppText font="header3" gutter={true}>
            {title}
          </AppText>
        ),
        enabled: (
          <RadioButton<boolean>
            value={value.enabled}
            onSelect={handleChangeType}
            options={[
              {
                icon: 'check',
                label: translate.enable,
                value: true,
              },
              {
                icon: 'check',
                label: translate.disable,
                value: false,
              },
            ]}
          />
        ),
        stages: value.enabled ? (
          <FieldArray name={`${name}.stages`} children={(vals) => <ReturnProtocolStagesConfig {...vals} />} />
        ) : null,
      }}
    />
  );
};
