import {
  AssessActionSetStepValuesPayloadChallenge,
  AssessContextState,
  AssessStepChallenge,
} from '../../../Assess.interface';
import { setSuggestedResultFromState } from './setSuggestedResultFromState';

export const setStepChallengeValues = (
  nextState: AssessContextState,
  payload: AssessActionSetStepValuesPayloadChallenge
): AssessContextState => {
  nextState = {
    ...nextState,
    steps: nextState.steps.map((step) => {
      if (step.stepId === payload.stepId && payload.type === 'challenge' && step.type === 'challenge') {
        const challengeStep: AssessStepChallenge = {
          ...step,
          results: step.results.map((challenge) => {
            if (challenge.challengeId === payload.challengeId) {
              return {
                ...challenge,
                result: payload.result,
              };
            } else {
              return challenge;
            }
          }),
        };
        return challengeStep;
      } else {
        return step;
      }
    }),
  };
  return setSuggestedResultFromState(nextState);
};
