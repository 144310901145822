import React from 'react';
import { View } from 'react-native';
import { useColors } from '../../../../shared/Ui/hooks';
import { AppText, AppLink, Icon, ShadowView, Spacer, SpacerItems } from '../../../../uiComponents';

export interface SectionButtonProps {
  label: string;
  items?: Array<{
    key: string;
    label: string;
    onPress: () => void;
  }>;
}

export const SectionButton: React.FC<SectionButtonProps> = ({ label, items }) => {
  const colors = useColors();
  const initialItems: SpacerItems = {};

  const submenu = items ? (
    <Spacer
      style={{ marginTop: 20 }}
      items={items.reduce((accum, val) => {
        accum[val.key] = <AppLink onPress={val.onPress}>{`• ${val.label}`}</AppLink>;

        return accum;
      }, initialItems)}
    />
  ) : null;
  return (
    <ShadowView
      style={{
        backgroundColor: colors.white,
        padding: 20,
      }}
    >
      <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <AppText font="header4">{label}</AppText>
      </View>
      {submenu}
    </ShadowView>
  );
};
