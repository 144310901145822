import { ErrorCode } from '../types/globalTypes';
import { isObject, isString, reduce } from 'lodash/fp';

export interface GenericApiError extends Record<string, unknown> {
  code: ErrorCode;
}

export const useApiErrorExtractor =
  <T extends Record<string, unknown>>() =>
  (object: T): GenericApiError[] => {
    const searchKey = '__typename';
    const searchTypenameContains = 'ApiError';
    const collector: GenericApiError[] = [];
    const reducer = <U extends Record<string, unknown>>(_object: U) =>
      reduce((prev: GenericApiError[], currentKey: keyof U) => {
        const currentVal = _object[currentKey] as Record<string, unknown> | undefined;

        if (isObject(currentVal)) {
          const currentValTypename = currentVal[searchKey];

          if (isString(currentValTypename) && currentValTypename.indexOf(searchTypenameContains) !== -1) {
            return [...prev, currentVal];
          } else {
            return [...prev, ...reducer(currentVal)];
          }
        }
        return [...prev];
      }, collector)(Object.keys(_object));

    return reducer(object);
  };
