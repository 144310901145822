import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../hooks/useQuery';
import { UseSubjectsListQuery, UseSubjectsListQueryVariables } from './__generated__/UseSubjectsList';

const USE_SUBJECTS_LIST_QUERY = gql`
  query UseSubjectsList(
    $organizationId: String
    $groupId: String
    $filters: SubjectsFiltersInput
    $paginationArgs: PaginationArgs!
    $searchStr: String
  ) {
    currentUserSubjects(
      organizationId: $organizationId
      groupId: $groupId
      filters: $filters
      paginationArgs: $paginationArgs
      searchStr: $searchStr
    ) {
      ...SubjectForSubjectsList
    }
  }
  fragment SubjectForSubjectsList on Subject {
    id
    fullName
    firstName
    lastName
    groups {
      id
      name
      organizationId
    }
    subjectMemberships {
      id
      status
      organizations {
        id
        status
        organization {
          id
          name
        }
      }
    }
    organizations {
      id
      name
    }
  }
`;

export const useSubjectsListQuery = (options?: UseQueryOptions<any, any>) =>
  useQuery<UseSubjectsListQuery, UseSubjectsListQueryVariables>(USE_SUBJECTS_LIST_QUERY, options);
