import React, { useState, useRef, useEffect } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useGetGroupById } from '../../hooks/useGetGroupById';
import { ManageGroupsNavType, ManageGroupsRouteProp } from '../../ManageGroups.interface';
import { Content } from './Content';
import { useAdminContext } from '../../../../hooks/useAdminContext';
import { GroupDetailChrome } from '../GroupDetailChrome';
import { Input, AppText, Select, AppButton, ActivityIndicator, PageNavButtons } from '../../../../../../uiComponents';
import { useIsMobile } from '../../../../../../hooks/ui/useResponsive';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { useSearchAndSort, SortValue } from '../hooks/useSearchAndSort';
import { InvitationModal as AddUserModal } from '../InvitationModal/InvitationModal';
import { InviteUserFormData } from '../../../ManageUsers/InvitationModal/InvitationModal';
import { InvitationModal } from '../../../ManageUsers/InvitationModal/InvitationModal';
import { useAddUserToGroup } from '../../hooks/useAddUserToGroup';
import { useInviteUserToOrganizationRole } from '../../../ManageUsers/hooks/useInviteUserToOrganizationRole';
import { Role } from '../../../../../../types/globalTypes';

export const GroupDetailsSubjects: React.FC = () => {
  const inputRef = useRef<TextInput>(null);
  const { handleSortChange, handleSearchStr, selectedSortType, searchStr, sortCategories, setIsSearchInputFocused } =
    useSearchAndSort(inputRef);

  const { organizationId } = useAdminContext();
  const navigation = useNavigation<ManageGroupsNavType<'LoggedIn_Admin_ManageGroups_GroupDetails_Subjects'>>();
  const route = useRoute<ManageGroupsRouteProp<'LoggedIn_Admin_ManageGroups_GroupDetails_Subjects'>>();
  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>('');
  const [isSearchAndSortVisible, setIsSearchAndSortVisible] = useState<boolean>(false);
  const [isSubjectInvitationModalVisible, setSubjectInvitationModalVisible] = useState<boolean>(false);
  const [isSubjectCreationModalVisible, setSubjectCreationModalVisible] = useState<boolean>(false);

  const { translate } = useTranslations();

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedSortType, searchStr]);

  if (!route?.params?.groupId) {
    throw new Error('Group ID is not set');
  }
  const { groupId } = route.params;
  const handleBack = () => navigation.navigate('LoggedIn_Admin_ManageGroups_Index');

  const styles = StyleSheet.create({
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
    },
    sortSelect: {
      width: isMobile ? '100%' : '35%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
      paddingRight: 15,
    },
    searchContainer: {
      width: isMobile ? '100%' : '65%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
    },
    headerRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    search: {
      flex: 3,
      marginRight: 20,
    },
    buttonSpacing: {
      marginRight: 15,
      marginBottom: 15,
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      marginVertical: isMobile ? 10 : 0,
      marginBottom: 20,
      flexWrap: 'wrap',
    },

    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const { data, refetch: refetchGroup } = useGetGroupById({
    hideLoading: true,
    variables: {
      organizationId,
      groupId,
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      searchStr: searchStr ? searchStr : null,
    },
  });

  const groupSubjects = data ? data.groupSubjects : null;
  const group = data && data.group.__typename !== 'ApiError' ? data.group : null;

  useEffect(() => {
    if (data && data.group.__typename === 'Group' && data.group.name !== groupName) {
      setGroupName(data.group.name);
    }
    if (data && ((data.groupSubjects.length === 25 && currentPage === 0) || currentPage !== 0)) {
      setIsSearchAndSortVisible(true);
    }
  }, [data]);

  const [inviteUserToOrganizationRole, { loading: inviteSubmitting }] = useInviteUserToOrganizationRole(
    {
      onCompleted: () => {
        setSubjectCreationModalVisible(false);
        refetchGroup();
      },
    },
    organizationId,
    {
      skip: currentPage,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
    searchStr
  );

  const handleUserAdd = async (user: InviteUserFormData) => {
    await inviteUserToOrganizationRole({
      variables: {
        input: {
          organizationId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email || '',
          roles: (user.roles || []) as Role[],
          guardians: user.guardians.length > 0 ? user.guardians : null,
          groups: user.groupList.map((group) => group.id),
          birthday: user.birthday ? user.birthday : null,
        },
      },
    });
  };

  const openAddSubjectModal = () => {
    setSubjectInvitationModalVisible(true);
  };

  const closeAddSubjectModal = () => {
    setSubjectInvitationModalVisible(false);
  };

  const openCreateSubjectModal = () => {
    setSubjectCreationModalVisible(true);
  };

  const closeCreateSubjectModal = () => {
    setSubjectCreationModalVisible(false);
  };

  const [inviteExistingSubjectToGroup, { loading: existingSubjectInviteSubmitting }] = useAddUserToGroup({
    onCompleted: () => {
      setSubjectInvitationModalVisible(false);
      refetchGroup();
    },
  });

  const handleSubjectAdd = (userId: string) => {
    inviteExistingSubjectToGroup({
      variables: {
        groupId: group ? group.id : '',
        userId,
        role: Role.SUBJECT,
        organizationId,
      },
    });
  };

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  return (
    <>
      {group ? (
        <>
          <AddUserModal
            title={translate.admin.manageSeasons.groupDetails.addSubject}
            visible={isSubjectInvitationModalVisible}
            onDismiss={closeAddSubjectModal}
            onAdd={handleSubjectAdd}
            submitting={existingSubjectInviteSubmitting}
            role={Role.SUBJECT}
            excludeGroupId={group.id}
            organizationId={organizationId}
          />
          <InvitationModal
            title={translate.admin.manageSeasons.groupDetails.createSubject}
            visible={isSubjectCreationModalVisible}
            onDismiss={closeCreateSubjectModal}
            submitting={inviteSubmitting}
            onAdd={handleUserAdd}
            defaultGroup={{ id: group.id, name: group.name }}
          />
        </>
      ) : null}
      <GroupDetailChrome
        name={groupName}
        organizationId={organizationId}
        groupId={groupId}
        activeTab="subjects"
        onBack={handleBack}
        content={
          <View>
            {isSearchAndSortVisible ? (
              <View style={styles.searchAndSortContainer}>
                <View style={styles.sortSelect}>
                  <AppText>{translate.users.userDetails.sort}</AppText>
                  <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
                </View>
                <View style={styles.searchContainer}>
                  <Input
                    icon="search"
                    ref={inputRef}
                    onFocus={() => setIsSearchInputFocused(true)}
                    onBlur={() => setIsSearchInputFocused(false)}
                    placeholder={translate.users.userDetails.searchByName}
                    type="name"
                    debounce={{
                      milliseconds: 1000,
                      callback: handleSearchStr,
                      textValue: searchStr,
                    }}
                  />
                </View>
              </View>
            ) : null}
            <View style={styles.headerRow}>
              <View style={styles.buttonRow}>
                <AppButton style={styles.buttonSpacing} onPress={openAddSubjectModal}>
                  {translate.users.addUser.title}
                </AppButton>
                <AppButton type="outline" onPress={openCreateSubjectModal}>
                  {translate.admin.manageSeasons.groupDetails.createUser}
                </AppButton>
              </View>
              <PageNavButtons
                isPrevPageButtonVisible={currentPage !== 0}
                isNextPageButtonVisible={groupSubjects && groupSubjects.length === 25}
                prevPageAction={goToPreviousPage}
                nextPageAction={goToNextPage}
              />
            </View>
            {group ? (
              <Content
                group={group}
                groupSubjects={groupSubjects}
                organizationId={organizationId}
                refetchGroup={refetchGroup}
                searchStr={searchStr}
                currentPage={currentPage}
              />
            ) : (
              <ActivityIndicator style={styles.activityIndicator} />
            )}
          </View>
        }
      />
    </>
  );
};
