import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import {
  AppButton,
  AppText,
  Input,
  Loading,
  PhoneInput,
  AppModal,
  ActivityIndicator,
  Select,
  InputLabel,
} from '../../../../uiComponents';
import { useAdminContext } from '../../hooks/useAdminContext';
import { useTranslations, languageList } from '../../../../i18n/useTranslations';
import { CountryIsoCode, OrganizationType, OrganizationSport, UserLanguage } from '../../../../types/globalTypes';
import { useUpdateOrganization } from './hooks/useUpdateOrganization';
import { useNotification } from '../../../../hooks/ui/useNotification/useNotification';
import { isApiError } from '../../../../utils/isApiError';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { useGetProtocolByOrganization } from '../../../../screens/Protocol/hooks/useGetProtocolByOrganization';
import { useGetOrganization } from './hooks/useGetOrganization';

interface ManageOrganizationFormData {
  organizationName: string | null;
  emergencyContact: string;
  emergencyPhoneNumber: string;
  isoCode: CountryIsoCode;
  language: UserLanguage;
}

export const Content: React.FC = () => {
  const { organizationId } = useAdminContext();
  const { data: organizationProtocolData } = useGetProtocolByOrganization({ variables: { organizationId } });
  const { data: organizationData } = useGetOrganization({ variables: { organizationId } });
  const { translate, translateWithVars } = useTranslations();
  const isMobile = useIsMobile();
  const notify = useNotification();
  const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);

  const [updateOrganization] = useUpdateOrganization({
    onCompleted: ({ updateOrganization }) => {
      if (!isApiError(updateOrganization)) {
        notify({ image: 'header/people', description: translate.admin.manageOrganization.modalTitle });
      }
    },
  });

  if (organizationData?.organization.__typename !== 'Organization') return <ActivityIndicator />;
  const organization = organizationData?.organization;

  const initialValues: ManageOrganizationFormData = {
    organizationName: organization ? organization.name : '',
    emergencyContact: !organization?.emergencyPhoneNumber?.name ? '' : organization.emergencyPhoneNumber.name,
    emergencyPhoneNumber: !organization?.emergencyPhoneNumber?.phoneNumber
      ? ''
      : organization.emergencyPhoneNumber.phoneNumber,
    isoCode: !organization?.emergencyPhoneNumber?.country?.isoCode
      ? CountryIsoCode.CA
      : organization.emergencyPhoneNumber.country.isoCode,
    language: organization.language,
  };

  const handleUpdateOrganization = (
    values: ManageOrganizationFormData,
    { setSubmitting }: FormikHelpers<ManageOrganizationFormData>
  ) => {
    updateOrganization({
      variables: {
        organizationId,
        name: values.organizationName,
        emergencyPhoneNumber: {
          name: values.emergencyContact,
          phoneNumber: values.emergencyPhoneNumber,
          isoCode: values.isoCode,
        },
        language: values.language,
      },
    }).then(() => {
      setUpdateCompleted(true);
      setSubmitting(false);
    });
  };

  const validate = (values: ManageOrganizationFormData) => {
    let errors: FormikErrors<ManageOrganizationFormData> = {};

    if (!values.organizationName) errors.organizationName = translate.error.message.cantBeEmpty;

    return errors;
  };

  const styles = StyleSheet.create({
    nameAndLanguageRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    nameInput: {
      width: isMobile ? '100%' : '70%',
      paddingRight: isMobile ? 0 : 15,
    },
    languageInput: {
      width: isMobile ? '100%' : '30%',
    },
    organizationInfo: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      marginTop: 30,
      marginBottom: 50,
    },
    organizationInfoItem: {
      marginRight: !isMobile ? 40 : 0,
    },
    medicalEmergencyTitle: {
      marginBottom: 10,
    },
    medicalEmergencyRow: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      width: '100%',
    },
    medicalEmergencyInput: {
      flexBasis: '50%',
      marginRight: 15,
      flexGrow: 0,
      flexShrink: 0,
    },
    modalButton: {
      marginTop: 20,
      marginLeft: 'auto',
    },
  });

  const getOrganizationType = (organizationType: OrganizationType) => {
    const organizationTypes = {
      [OrganizationType.SCHOOL]: translate.superAdmin.createOrganization.organizationTypes.school,
      [OrganizationType.SPORTS_ORGANIZATION]:
        translate.superAdmin.createOrganization.organizationTypes.sportsOrganization,
    };
    return organizationTypes[organizationType] ? organizationTypes[organizationType] : '';
  };

  const getSport = (organizationSport: OrganizationSport) => {
    const organizationSports = {
      [OrganizationSport.ALPINE_SKI]: translate.organizationSports.alpineSki,
      [OrganizationSport.ATHLETICS]: translate.organizationSports.athletics,
      [OrganizationSport.BASEBALL]: translate.organizationSports.baseball,
      [OrganizationSport.BASKETBALL]: translate.organizationSports.basketball,
      [OrganizationSport.BOXING]: translate.organizationSports.boxing,
      [OrganizationSport.EQUESTRIAN]: translate.organizationSports.equestrian,
      [OrganizationSport.FIELD_HOCKEY]: translate.organizationSports.fieldHockey,
      [OrganizationSport.FOOTBALL]: translate.organizationSports.football,
      [OrganizationSport.FREESTYLE_SKI]: translate.organizationSports.freestyleSki,
      [OrganizationSport.GYMNASTICS]: translate.organizationSports.gymnastics,
      [OrganizationSport.HOCKEY]: translate.organizationSports.hockey,
      [OrganizationSport.MULTIPLE]: translate.organizationSports.multiple,
      [OrganizationSport.LACROSSE]: translate.organizationSports.lacrosse,
      [OrganizationSport.OTHER]: translate.organizationSports.other,
      [OrganizationSport.RINGETTE]: translate.organizationSports.ringette,
      [OrganizationSport.RUGBY]: translate.organizationSports.rugby,
      [OrganizationSport.SNOWBOARD]: translate.organizationSports.snowboard,
      [OrganizationSport.SOCCER]: translate.organizationSports.soccer,
      [OrganizationSport.SPEED_SKATING]: translate.organizationSports.speedSkating,
      [OrganizationSport.TENNIS]: translate.organizationSports.tennis,
      [OrganizationSport.WRESTLING]: translate.organizationSports.wrestling,
    };
    return organizationSports[organizationSport] ? organizationSports[organizationSport] : '';
  };

  const languages = languageList.map((language) => ({
    value: language.locale,
    label: translate.language[language.name],
    key: language.locale,
  }));

  return !initialValues || !organization ? (
    <Loading />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpdateOrganization}
      validate={validate}
      // enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, isValid, values, handleChange, handleBlur, errors, touched }) => (
        <View>
          {/* TODO: replace the modal with the popup from useNotification once that has been fixed */}
          <AppModal
            mode="dialog"
            title={translate.admin.manageOrganization.modalTitle}
            visible={updateCompleted}
            onDismiss={() => setUpdateCompleted(false)}
            children={
              <View>
                <AppText>
                  {translateWithVars(translate.admin.manageOrganization.successfullyUpdated, {
                    organizationName: values.organizationName,
                  })}
                </AppText>
                <AppButton style={styles.modalButton} onPress={() => setUpdateCompleted(false)}>
                  {translate.admin.manageOrganization.ok}
                </AppButton>
              </View>
            }
          />
          <View style={styles.nameAndLanguageRow}>
            <View style={styles.nameInput}>
              <Input
                value={values.organizationName ? values.organizationName : ''}
                label={translate.admin.manageOrganization.organizationNameInputLabel}
                onChangeText={handleChange('organizationName')}
                onBlur={handleBlur('organizationName')}
                error={errors?.organizationName}
                touched={touched?.organizationName}
              />
            </View>
            <View style={styles.languageInput}>
              <InputLabel label={translate.account.yourDetails.language} />
              <Select<UserLanguage>
                onChange={handleChange('language')}
                onBlur={handleBlur('language')}
                value={values.language}
                key="language"
                items={languages}
              />
            </View>
          </View>
          <View style={styles.organizationInfo}>
            <AppText style={styles.organizationInfoItem} font="labelDefault" color="grayDark">
              {`${translate.admin.manageOrganization.typeLabel}: ${getOrganizationType(organization.type)}`}
            </AppText>
            <AppText style={styles.organizationInfoItem} font="labelDefault" color="grayDark">
              {`${translate.admin.manageOrganization.sportLabel}: ${getSport(organization.sport)}`}
            </AppText>
            <AppText style={styles.organizationInfoItem} font="labelDefault" color="grayDark">
              {`${translate.admin.manageOrganization.protocolLabel}: ${
                organizationProtocolData?.currentProtocolByOrganization.__typename === 'Protocol'
                  ? organizationProtocolData.currentProtocolByOrganization.protocolName
                  : ''
              }`}
            </AppText>
          </View>
          <AppText font="header4" style={styles.medicalEmergencyTitle}>
            {translate.admin.manageOrganization.medicalEmergencyPhoneNumber}
          </AppText>
          <View style={styles.medicalEmergencyRow}>
            <View style={styles.medicalEmergencyInput}>
              <Input
                value={values.emergencyContact ? values.emergencyContact : ''}
                label={translate.admin.manageOrganization.medicalEmergencyNameLabel}
                onChangeText={handleChange('emergencyContact')}
                onBlur={handleBlur('emergencyContact')}
                error={errors?.emergencyContact}
                touched={touched?.emergencyContact}
              />
            </View>
            <View style={styles.medicalEmergencyInput}>
              <PhoneInput
                phoneNumber={values.emergencyPhoneNumber ? values.emergencyPhoneNumber : ''}
                label={translate.admin.manageOrganization.medicalEmergencyPhoneLabel}
                onChangePhoneNumber={handleChange('emergencyPhoneNumber')}
                onBlurPhoneNumber={handleBlur('emergencyPhoneNumber')}
                error={errors?.emergencyPhoneNumber}
                touched={touched?.emergencyPhoneNumber}
                isoCode={values.isoCode ? values.isoCode : ''}
              />
            </View>
          </View>
          <AppButton
            disabled={
              !isValid ||
              isSubmitting ||
              (values.organizationName === initialValues.organizationName &&
                values.emergencyContact === initialValues.emergencyContact &&
                values.emergencyPhoneNumber === initialValues.emergencyPhoneNumber &&
                values.isoCode === initialValues.isoCode &&
                values.language === initialValues.language)
            }
            loading={isSubmitting}
            onPress={handleSubmit}
          >
            {translate.admin.manageOrganization.updateOrganizationBtnLabel}
          </AppButton>
        </View>
      )}
    </Formik>
  );
};
