import { GroupMembership, OverallSubjectStatus, SubjectStatus } from '../../../../../types/globalTypes';
import { ProfileOverview } from '../../../Profile.interface';
import { useProfileContext } from '../../../ProfileContext';
import { useGetMyProfileOverview } from './useGetMyProfileOverview';
import { useGetSubjectProfileOverview } from './useGetSubjectProfileOverview';
import {
  GetMyProfileOverview,
  GetMyProfileOverview_currentUser_CurrentUser_groups_group,
} from './__generated__/GetMyProfileOverview';
import { GetSubjectProfileOverview } from './__generated__/GetSubjectProfileOverview';
import { useGetAllUserGroups } from '../../../../../hooks/useGetAllUserGroups';

export interface MySubjectMemberships {
  id: string;
  status: OverallSubjectStatus;
  organizations: Array<{
    organization: {
      id: string;
      name: string;
    };
    groups: GetMyProfileOverview_currentUser_CurrentUser_groups_group[];
    status: SubjectStatus | undefined;
  }>;
}

const makeMyProfile = (data: GetMyProfileOverview, groups: GroupMembership[]): ProfileOverview | null => {
  if (data.currentUser.__typename !== 'CurrentUser') {
    return null;
  }

  const currentUser = data.currentUser;

  const subjectMemberships: MySubjectMemberships = {
    id: '',
    status: currentUser.status,
    organizations: currentUser.roles
      .filter((role) => role.role === 'SUBJECT')
      .map((role) => {
        const organizationId = role.organizationId;
        const organizationStatus = currentUser.statuses.find((status) => status.organizationId === organizationId);
        return {
          organization: { id: organizationId, name: role.organizationName },
          groups: groups.filter((group) => group.organizationId === organizationId).map(({ group }) => group),
          status: organizationStatus?.status || SubjectStatus.ACTIVE,
        };
      }),
  };

  const profile = {
    overview: { subjectMemberships },
    navigationParams: {},
  };

  return profile;
};

const makeSubjectProfile = (data: GetSubjectProfileOverview): ProfileOverview | null => {
  if (data.getSubject.__typename !== 'Subject') {
    return null;
  }

  const profile: ProfileOverview = {
    overview: data.getSubject,
    navigationParams: {
      subjectId: data.getSubject.id,
    },
  };

  return profile;
};

export function useGetProfileOverviewData(): ProfileOverview | null {
  const { subjectId } = useProfileContext();

  const myProfile = useGetMyProfileOverview({
    skip: subjectId ? true : false,
    hideLoading: true,
  });

  const { data: groupData } = useGetAllUserGroups({
    skip: subjectId ? true : false,
  });

  const subjectProfile = useGetSubjectProfileOverview({
    skip: !subjectId ? true : false,
    variables: {
      subjectId: subjectId as string,
    },
    hideLoading: true,
  });

  if (!subjectId && (!groupData || !groupData.allUserGroups)) {
    return null;
  }

  if (myProfile.data) {
    return makeMyProfile(myProfile.data, groupData.allUserGroups);
  } else if (subjectProfile.data) {
    return makeSubjectProfile(subjectProfile.data);
  } else {
    return null;
  }
}
