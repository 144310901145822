import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../LoggedIn.interface';
import { useGetAllUserRoles } from '../../hooks/useGetAllUserRoles';
import { ActivityIndicator } from '../../uiComponents';
import { Role } from '../../types/globalTypes';

interface RouteGuardProps {
  children: React.ReactNode;
  acceptedRoles: Role[];
}

export const RouteGuard: React.FC<RouteGuardProps> = (props) => {
  const { children, acceptedRoles } = props;

  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Admin'>>();

  const { data } = useGetAllUserRoles();

  if (data && data.allUserRoles) {
    if (acceptedRoles.some((acceptedRole) => data.allUserRoles.map((item) => item.role).includes(acceptedRole))) {
      return <>{children}</>;
    } else {
      navigation.navigate('LoggedIn_Home');
      return null;
    }
  } else {
    return <ActivityIndicator />;
  }
};
