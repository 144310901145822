import React, { useState, useRef } from 'react';
import { View, StyleSheet, ScrollView, ScrollViewProps, Platform, TouchableOpacity } from 'react-native';
import { times } from 'lodash';
import { useColors } from '../../shared/Ui/hooks/useColors';

interface CarouselProps {
  onChange?: (index: number) => void;
  itemsPerInterval?: number;
  items: any[];
}

export const Carousel: React.FC<CarouselProps> = ({ onChange, itemsPerInterval = 1, items }) => {
  const [interval, setInterval] = useState<number | undefined>(1);
  const [intervals, setIntervals] = useState(1);
  const [width, setWidth] = useState(0);
  const scrollViewRef = useRef<ScrollView | null>(null);
  const colors = useColors();
  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    scrollView: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    bullets: {
      position: 'absolute',
      bottom: 60,
      left: 20,
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      paddingHorizontal: 10,
      paddingTop: 5,
    },
    bullet: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: colors.white,
      marginRight: 12,
    },
    activeBullet: {
      backgroundColor: colors.white,
      transform: [{ scale: 1.4 }],
    },
    inactiveBullet: {
      backgroundColor: colors.gray,
      transform: [{ scale: 1 }],
    },
    slide: {
      flexBasis: '100%',
      flex: 1,
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
  });

  const init = (w: number) => {
    setWidth(w + 1);
    const totalItems = items.length;
    setIntervals(Math.ceil(totalItems / itemsPerInterval));
  };

  const getInterval = (offset: number) => {
    const slideWidth = width / intervals;
    return Math.max(Math.min(intervals, Math.round(offset / slideWidth) + 1), 1);
  };

  const getCarouselItem = (index: number) => (
    <View key={index} style={styles.slide}>
      {items[index]}
    </View>
  );

  const bullets = times(intervals).map((index) => (
    <TouchableOpacity
      key={index}
      style={[styles.bullet, interval === index + 1 ? styles.activeBullet : styles.inactiveBullet]}
      onPress={() => scrollViewRef.current?.scrollTo({ x: (index * width) / intervals, animated: true })}
    />
  ));

  // add dataSet attribute for web scrollview

  interface AppScrollViewProps extends ScrollViewProps {
    dataSet: object;
    ref: any;
  }

  // FYI: casts ScrollView component so that custom dataSet property (above) can be included for web
  // previous approach of `CarouselScrollView = props => <ScrollView {...props} />` does not work for native or web (breaks carousel)

  const CarouselScrollView = ScrollView as unknown as React.ComponentType<AppScrollViewProps>;

  return (
    <View style={styles.container}>
      <CarouselScrollView
        ref={scrollViewRef}
        horizontal={true}
        contentContainerStyle={{
          ...styles.scrollView,
          width: `${100 * intervals}%`,
        }}
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={(w, h) => init(w)}
        onScroll={(data) => {
          const index = getInterval(data.nativeEvent.contentOffset.x);
          setInterval(index);

          if (index && onChange) {
            onChange(index);
          }
        }}
        scrollEventThrottle={200}
        pagingEnabled={true}
        dataSet={{ 'paging-enabled': true }}
        decelerationRate="fast"
      >
        {items.map((item, index) => getCarouselItem(index))}
      </CarouselScrollView>
      <View style={styles.bullets} pointerEvents={Platform.OS === 'web' ? 'auto' : 'none'}>
        {bullets}
      </View>
      {Platform.OS === 'web' ? (
        <style>{`
					[data-paging-enabled] > div > div {
						flex: 1
					}
				`}</style>
      ) : null}
    </View>
  );
};
