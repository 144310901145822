import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useColors, useShadow } from '../../../../shared/Ui/hooks';
import { AppText, Icon } from '../../../../uiComponents';
import { useAdminContext } from '../../hooks/useAdminContext';
import { useTranslations } from '../../../../i18n/useTranslations';
import { useDeleteSeason } from './hooks/useDeleteSeason';

interface SeasonProps {
  onPress?: () => void;
  id: string;
  label: string;
  startDate: string;
  endDate?: string;
}

export const Season: React.FC<SeasonProps> = (props) => {
  const { label, onPress, id, startDate, endDate } = props;
  const { organizationId } = useAdminContext();
  const { translate } = useTranslations();
  const [deleteSeason] = useDeleteSeason(organizationId);
  const handleDeleteSeason = () => {
    deleteSeason({ variables: { organizationId, seasonId: id } });
  };

  const shadow = useShadow();
  const colors = useColors();
  const styles = StyleSheet.create({
    container: {
      ...shadow,
      padding: 17,
      marginBottom: 15,
      backgroundColor: colors.white,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  });

  return (
    <TouchableOpacity onPress={onPress} style={[styles.container]}>
      <View>
        <AppText font="labelDefault" color="blue">
          {label}
        </AppText>
        <AppText font="labelDefault" color="gray">{`${new Date(startDate).toLocaleDateString()} - ${
          endDate ? new Date(endDate).toLocaleDateString() : translate.time.present
        }`}</AppText>
      </View>
      <TouchableOpacity onPress={handleDeleteSeason}>
        <Icon size={24} name="trash" />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};
