import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { TrainingParamList } from './Training.interface';
import { useTrainingRoutes } from './hooks/useTrainingRoutes';

const TrainingNav = createStackNavigator<TrainingParamList>();
const routes = useTrainingRoutes();

export const TrainingNavigator: React.FC<{}> = () => {
  return (
    <TrainingNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <TrainingNav.Screen key={name} name={name} component={component} />
      ))}
    </TrainingNav.Navigator>
  );
};
