import React from 'react';
import { Select } from '../Select/Select';
import { useStates } from '../../shared/Ui';
import { StateSelectItem } from './StateSelect.interface';

interface StateSelectProps<States extends string, Countries extends string> {
  value: States;
  placeholder?: string;
  countryIsoCode?: Countries;
  onChange?: (value: States) => void;
  onBlur?: (e: any) => void;
  filterStates?: (state: StateSelectItem<States, Countries>) => boolean;
  showAlphabeticalIndex?: boolean;
  error?: string;
  touched?: boolean;
}

export const StateSelect = <States extends string, Countries extends string>({
  value,
  onChange,
  onBlur,
  countryIsoCode,
  filterStates,
  error,
  touched,
}: React.PropsWithChildren<StateSelectProps<States, Countries>>) => {
  const _states = useStates<States, Countries>(countryIsoCode);

  const states: StateSelectItem<States, Countries>[] = _states.filter((val) => {
    if (!filterStates) {
      return true;
    } else {
      return filterStates(val);
    }
  });

  return (
    <Select<States>
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      items={states.map((state) => ({ key: state.key, value: state.value, label: state.label }))}
      error={error}
      touched={touched}
    />
  );
};
