import React, { useState } from 'react';
import { Keyboard, Linking, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  DocumentReviewResult,
  ReviewStageDocumentInput,
  RtaProtocolType,
} from '../../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { useColors, useShadow } from '../../../../../../../shared/Ui/hooks';
import {
  AppButton,
  AppMarkdown,
  AppModal,
  AppScrollView,
  AppText,
  Input,
  ProtocolAttribution,
  Spacer,
} from '../../../../../../../uiComponents';
import { RecoveryPlanNavType } from '../../../RecoveryPlan.interface';
import { useReviewStageDocument } from '../../../hooks/useReviewStageDocument';
import { ProfileChrome } from '../../../../../ProfileChrome/ProfileChrome';
import { RecoveryPlanStageDocument } from '../../../fragments/__generated__/RecoveryPlanStageDocument';
import { LockedAction } from '../LockedAction';
import { RejectionReason } from '../RejectionReason';
import { useStepBackward } from '../../../hooks/useStepBackward';
import { useActionItemsQuery } from '../../../../../../ActionItems/hooks/useActionItems';
import { useIsMobile } from '../../../../../../../hooks/ui/useResponsive';

export interface ReviewDocumentProps {
  stageDocument: RecoveryPlanStageDocument;
}

export const ReviewDocument: React.FC<ReviewDocumentProps> = ({ stageDocument }) => {
  const colors = useColors();
  const shadow = useShadow();
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Stage'>>();
  const [reviewStageDocument] = useReviewStageDocument();
  const [stepBackward] = useStepBackward();
  const [showRejectionReasonDialog, setShowRejectionReasonDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<string>('');
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isSteppingBackward, setIsSteppingBackward] = useState(false);

  const isMobile = useIsMobile();

  const postReturnProtocolDocumentReview = async (reviewResult: DocumentReviewResult) => {
    if (!stageDocument.document) {
      console.log('Missing stage document');
      return;
    }

    const input: ReviewStageDocumentInput = {
      caseId: stageDocument.caseId,
      documentId: stageDocument.document.id,
      stageId: stageDocument.id,
      reviewResult,
      rejectionReason,
      isFastTracked: false,
    };
    return reviewStageDocument({
      variables: { input },
    });
  };

  const handleStepBackwardButtonPress = () => {
    setIsSteppingBackward(true);
    stepBackward({
      variables: {
        input: {
          caseId: stageDocument.caseId,
          stageId: stageDocument.id,
        },
      },
    }).then(() => {
      setIsSteppingBackward(false);
      setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
    });
  };

  const handleAcceptButtonPress = () => {
    setIsApproving(true);
    postReturnProtocolDocumentReview(DocumentReviewResult.APPROVED).then(() => {
      setIsApproving(false);
      setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
    });
  };

  const handleRejectButtonPress = () => {
    setShowRejectionReasonDialog(true);
  };

  const handleViewDocument = () => {
    if (stageDocument.document?.downloadUri) {
      Linking.openURL(stageDocument.document.downloadUri);
    }
  };

  const handleRejectionReasonChange = (reason: string) => {
    setRejectionReason(reason);
  };

  const handleDialogCancelButton = () => {
    setShowRejectionReasonDialog(false);
  };

  const handleDialogSubmitButton = () => {
    setIsRejecting(true);
    postReturnProtocolDocumentReview(DocumentReviewResult.REJECTED).then(() => {
      setIsRejecting(false);
      setShowRejectionReasonDialog(false);
      setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
    });
  };

  const styles = StyleSheet.create({
    itemGroupContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...shadow,
      padding: 14,
      marginBottom: 12,
      backgroundColor: colors.white,
      borderRadius: 8,
      width: '100%',
    },
    diagnosisHeading: {
      marginTop: 20,
    },
    dialogButtons: {
      marginBottom: 20,
      flexDirection: 'row',
      flex: 1,
    },
    linkButton: {
      color: colors.blue,
    },
    buttons: {
      marginTop: 20,
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    button: {
      width: 200,
      marginBottom: isMobile ? 15 : 0,
      marginRight: 15,
    },
  });

  const rejectionReasonDialog = (
    <AppModal
      mode="dialog"
      title={translate.recovery.reviewMedicalAssessment.rejectionReason}
      visible={showRejectionReasonDialog}
      buttonOrientation="horizontal"
      onDismiss={handleDialogCancelButton}
      onRequestClose={handleDialogCancelButton}
      buttons={{
        cancel: (
          <AppButton type="outline" onPress={handleDialogCancelButton}>
            {translate.label.cancel}
          </AppButton>
        ),
        submit: <AppButton onPress={handleDialogSubmitButton}>{translate.label.submit}</AppButton>,
      }}
    >
      <Input
        value={rejectionReason}
        placeholder={translate.label.reason}
        type="text"
        numLines={5}
        numberOfLines={5}
        onSubmitEditing={Keyboard.dismiss}
        multiline={true}
        onChangeText={handleRejectionReasonChange}
      />
    </AppModal>
  );

  const content = (
    <>
      <AppScrollView>
        <Spacer
          items={{
            titleArea: (
              <Spacer
                items={{
                  title: <AppText font="header1">{stageDocument.name}</AppText>,
                  step: (
                    <AppText font="labelDefault" color="grayDark">
                      {translateWithVars(translate.recovery.stageSignoff.subtitle, {
                        organization: stageDocument.organizationName,
                        protocol:
                          stageDocument.protocolType === RtaProtocolType.LEARN
                            ? translate.recovery.index.returnToLearn
                            : translate.recovery.index.returnToSport,
                      })}
                    </AppText>
                  ),
                  attribution: (
                    <ProtocolAttribution
                      protocolUrl={stageDocument.protocolUrl}
                      protocolName={stageDocument.protocolName}
                    />
                  ),
                }}
              />
            ),
            rejectionReason: <RejectionReason {...stageDocument} />,
            description: <AppMarkdown>{stageDocument.description}</AppMarkdown>,
            buttons: (
              <View style={styles.buttons}>
                <TouchableOpacity style={styles.itemGroupContainer} onPress={handleViewDocument}>
                  <AppText>{translate.recovery.stageDocumentReview.document}</AppText>
                  <AppText style={styles.linkButton}>{translate.label.view}</AppText>
                </TouchableOpacity>
              </View>
            ),
          }}
        />
      </AppScrollView>
      {rejectionReasonDialog}
    </>
  );

  const canStepForward = stageDocument.iCanReview && stageDocument.isCurrentStage;
  const canStepBackward = stageDocument.order !== 0 && stageDocument.iCanReview && stageDocument.isCurrentStage;
  const isLocked = !stageDocument.iCanReview && stageDocument.isCurrentStage;

  const footer = (
    <View style={styles.buttons}>
      {isLocked ? <LockedAction text={translate.recovery.stageDocumentReview.notAuthorized} /> : null}
      {canStepForward ? (
        <AppButton
          disabled={isApproving || isRejecting || isSteppingBackward}
          loading={isRejecting}
          type="outline"
          onPress={handleRejectButtonPress}
          style={styles.button}
        >
          {translate.label.reject}
        </AppButton>
      ) : null}
      {canStepBackward ? (
        <AppButton
          disabled={isApproving || isRejecting || isSteppingBackward}
          loading={isSteppingBackward}
          type="outline"
          onPress={handleStepBackwardButtonPress}
          style={styles.button}
        >
          {translate.label.stepBack}
        </AppButton>
      ) : null}
      {canStepForward ? (
        <AppButton
          disabled={isApproving || isRejecting || isSteppingBackward}
          loading={isRejecting}
          onPress={handleAcceptButtonPress}
          style={StyleSheet.flatten([styles.button])}
        >
          {translate.label.approve}
        </AppButton>
      ) : null}
    </View>
  );
  return (
    <ProfileChrome
      autoScroll={false}
      activeTab="recovery"
      content={
        <>
          {content}
          {footer}
        </>
      }
    />
  );
};
