import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { AppText, Checkbox } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { FormField } from './FormField';
import { Tabs } from './ImportData.interface';

interface ImportDataProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<Tabs>>;
  selectedOrganization: string;
}
export const ImportDataTab: React.FC<ImportDataProps> = (props) => {
  const { translate } = useTranslations();

  const { setCurrentTab, selectedOrganization } = props;
  const [sendInviteOnImport, setSendInviteOnImport] = useState<boolean[]>([true]);

  return (
    <View style={styles.importDataContainer}>
      <AppText font="header5" style={{ marginBottom: 10 }}>
        {translate.admin.manageProtocol.updateProtocolConfigForm.return.medicalAssessment.instruction}
      </AppText>
      <Checkbox
        value={sendInviteOnImport}
        onSelect={(val) => setSendInviteOnImport(val)}
        options={[{ label: translate.superAdmin.importData.sendInviteAfterImport, value: true }]}
      />
      <View style={{ marginVertical: 20 }}>
        <AppText>{translate.superAdmin.importData.sendInviteAtLaterDate}</AppText>
      </View>

      <AppText style={styles.instructionsContent}>{translate.superAdmin.importData.instructionsContent}</AppText>
      {translate.superAdmin.importData.formFieldTitles.map((formFieldTitle: { title: string; value: string }) => (
        <FormField
          title={formFieldTitle.title}
          key={formFieldTitle.value}
          setCurrentTab={setCurrentTab}
          value={formFieldTitle.value}
          selectedOrganization={selectedOrganization}
          sendInviteEmailsOnImport={sendInviteOnImport.includes(true)}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  importDataContainer: {
    marginTop: 15,
  },
  instructionsContent: {
    marginBottom: 20,
  },
});
