/* Simple KPI component with a large numerical value and a small text label */

import React from 'react';
import { View, StyleSheet, TextStyle, StyleProp } from 'react-native';
import { AppText } from '../AppText/AppText';
import { useIsMobile } from '../../hooks/ui/useResponsive';

interface KPIProps {
  value: string;
  label: string;
  labelStyle?: StyleProp<TextStyle>;
  valueStyle?: StyleProp<TextStyle>;
}

export const KPI = (props: KPIProps) => {
  const { value, label, labelStyle, valueStyle } = props;

  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    xLargeValue: {
      fontSize: 70,
      paddingTop: 10,
      paddingBottom: !isMobile ? 20 : 0,
      lineHeight: 70,
    },
    xLargeLabel: {
      paddingLeft: 5,
      marginTop: isMobile ? 15 : 0,
    },
    xLargeLabelMobile: {
      bottom: 15,
    },
  });

  return (
    <View>
      <AppText font="header1" color="navyDark" style={StyleSheet.flatten([styles.xLargeValue, valueStyle])}>
        {value}
      </AppText>
      <AppText
        font="labelDefault"
        color="navyDark"
        style={StyleSheet.flatten([styles.xLargeLabel, !isMobile && styles.xLargeLabelMobile, labelStyle])}
      >
        {label}
      </AppText>
    </View>
  );
};
