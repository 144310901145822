import { gql } from '@apollo/client';
import { useQuery } from './useQuery';
import { allUserRoles } from './__generated__/allUserRoles';

export const GET_ALL_USER_ROLES = gql`
  query allUserRoles {
    allUserRoles {
      ... on RoleEntry {
        role
        organizationId
      }
    }
  }
`;

export const useGetAllUserRoles = (options?) => useQuery<allUserRoles>(GET_ALL_USER_ROLES, options);
