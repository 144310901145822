import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton } from '../../../../uiComponents';
import { ReviewInvitationFormValues } from '../ReviewInvitation.interface';

export interface AcceptButtonProps {
  totalInvitationItems: number;
  disabled: boolean;
}

export const AcceptButton: React.FC<AcceptButtonProps> = ({ totalInvitationItems, disabled }) => {
  const { isValid, isSubmitting, handleSubmit, values } = useFormikContext<ReviewInvitationFormValues>();
  const { translate } = useTranslations();
  return (
    <AppButton onPress={handleSubmit} disabled={!isValid || isSubmitting || disabled}>
      {values.educationPoliciesAccepted.length !== totalInvitationItems
        ? translate.incidentReport.skipForNow
        : translate.reviewInvitation.rightUser.acceptButton}
    </AppButton>
  );
};
