import * as React from 'react';
import { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AppButton, AppLink, AppText, FileButton, FileButtonSelectedFile } from '../../../../uiComponents';
import { FormFieldProps, ImportStatus, Tabs } from './ImportData.interface';
import { useTranslations } from '../../../../i18n/useTranslations';
import { useImportDataContext } from '../../hooks/useImportDataContext';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { useImportFile } from '../../hooks/useImportFile';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { importFile_importFile_ImportResult_partialErrors as RowValidationErrors } from '../../hooks/__generated__/importFile';
import { useColors } from '../../../../shared/Ui/hooks';
import { ErrorsModal } from './ErrorsModal';
import { ErrorCode } from '../../../../types/globalTypes';

export const FormField: React.FC<FormFieldProps> = (props) => {
  const { title, setCurrentTab, value, selectedOrganization, sendInviteEmailsOnImport } = props;
  const { translate } = useTranslations();

  const isMobile = useIsMobile();
  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();

  const { csvAttachments, setCsvAttachments, importStatuses, setImportStatuses } = useImportDataContext();
  const [importFile] = useImportFile();
  const { currentUser } = useCurrentUser();
  const colors = useColors();

  const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [rowValidationErrors, setRowValidationErrors] = useState<RowValidationErrors[]>([]);

  const route = useRoute();

  const styles = StyleSheet.create({
    formFieldContainer: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      flexWrap: 'wrap',
    },
    labelsContainer: {
      width: isMobile ? '100%' : '33%',
    },
    labels: {
      width: '75%',
    },
    uploadButtonContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: isMobile ? 'flex-start' : 'center',
      alignItems: isMobile ? 'flex-start' : 'center',
    },
    fileLabel: {
      marginBottom: !isMobile ? 0 : 20,
      width: isMobile ? '100%' : '33%',
    },
    uploadMessage: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: isMobile ? 'flex-start' : 'flex-end',
      flexWrap: 'wrap',
      marginBottom: 3,
    },
    uploadMessageText: {
      marginLeft: 10,
    },
    importStatusContainer: {
      width: isMobile ? '100%' : '33%',
      display: 'flex',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: 'center',
    },
    importStatus: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: !isMobile ? 'flex-end' : 'flex-start',
    },
    rowValidationErrorsTitle: {
      textAlign: 'center',
      marginBottom: 30,
    },
    rowValidationError: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowValidationRow: {
      marginLeft: 50,
      width: 300,
    },
    rowValidationReason: {},
    buttonsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: isMobile ? 'center' : 'space-between',
      alignItems: isMobile ? 'flex-start' : 'center',
      maxWidth: '100%',
      flexShrink: 0,
      flexGrow: 1,
      marginTop: 15,
    },
  });

  const handleUnattachCsv = () => {
    setImportStatuses({ ...importStatuses, [value]: ImportStatus.NO_ATTACHMENT });
    setCsvAttachments({ ...csvAttachments, [value]: undefined });
  };

  const handleViewInvalidRows = () => {
    setShowErrorsModal(true);
  };

  const getErrorMessage = (errorCode: ErrorCode) => {
    switch (errorCode) {
      case ErrorCode.ADMIN_DOES_NOT_BELONG_IN_ORGANIZATION:
        return translate.errorCodes.adminDoesNotBelongInOrganization;
      case ErrorCode.UNKNOWN_IMPORT_TYPE:
        return translate.errorCodes.unknownImportType;
      case ErrorCode.COULD_NOT_CREATE_IMPORT:
        return translate.errorCodes.couldNotCreateImport;
      case ErrorCode.INCORRECT_HEADERS:
        return translate.errorCodes.incorrectHeaders;
      default:
        return translate.error.title.generic;
    }
  };

  const uploadFile = async () => {
    if (currentUser) {
      const fileResult = await importFile({
        variables: {
          input: {
            type: value,
            organizationId: selectedOrganization,
            file: {
              filename: csvAttachments[value].filename,
              file: csvAttachments[value].file,
            },
            userId: currentUser.id,
            sendInviteEmailsOnImport,
          },
        },
      });

      if (fileResult.data?.importFile.__typename === 'ApiError') {
        setValidationErrorMessage(getErrorMessage(fileResult.data?.importFile.code));
        setImportStatuses({ ...importStatuses, [value]: ImportStatus.IMPORT_FAILED });
      } else if (
        fileResult.data?.importFile.__typename === 'ImportResult' &&
        (!fileResult.data?.importFile.success || fileResult.data?.importFile?.partialErrors?.length > 0)
      ) {
        setImportStatuses({ ...importStatuses, [value]: ImportStatus.INVALID_DATA });
        setRowValidationErrors(fileResult.data?.importFile?.partialErrors);
      } else if (fileResult.data?.importFile.__typename === 'ImportResult' && fileResult.data?.importFile.success) {
        setImportStatuses({ ...importStatuses, [value]: ImportStatus.IMPORT_SUCCESSFUL });
      }
    }
  };

  const navigateToData = () => {
    setCsvAttachments({
      SUBJECTS: undefined,
      GUARDIANS: undefined,
      DESIGNATED_PERSON_GROUPS: undefined,
      SUBJECT_GROUPS: undefined,
    });
    if (value === 'SUBJECTS' || value === 'GUARDIANS' || value === 'STAFF') {
      navigation.navigate('LoggedIn_Admin', {
        screen: 'LoggedIn_Admin_ManageUsers',
        organizationId: selectedOrganization,
        params: {
          screen: 'LoggedIn_Admin_ManageUsers_Index',
        },
      });
    } else if (value === 'SUBJECT_GROUPS' || value === 'DESIGNATED_PERSON_GROUPS') {
      navigation.navigate('LoggedIn_Admin', {
        screen: 'LoggedIn_Admin_ManageGroups',
        organizationId: selectedOrganization,
        params: {
          screen: 'LoggedIn_Admin_ManageGroups_Index',
        },
      });
    }
    setImportStatuses({ ...importStatuses, [value]: ImportStatus.NO_ATTACHMENT });
  };

  const renderUploadButton = () => {
    return (
      <View style={styles.uploadButtonContainer}>
        <FileButton
          onFileChange={(upload: FileButtonSelectedFile) => {
            setImportStatuses({ ...importStatuses, [value]: ImportStatus.READY_TO_IMPORT });
            setCsvAttachments({ ...csvAttachments, [value]: upload });
          }}
          file={csvAttachments[value]}
          mediaType="csv"
          onFileClear={handleUnattachCsv}
        >
          {translate.superAdmin.importData.uploadButton}
        </FileButton>
      </View>
    );
  };

  const renderImportStatus = (status: ImportStatus) => {
    switch (status) {
      case ImportStatus.NO_ATTACHMENT:
      case ImportStatus.READY_TO_IMPORT:
        return (
          <AppButton disabled={importStatuses[value] === ImportStatus.NO_ATTACHMENT} onPress={uploadFile}>
            {translate.superAdmin.importData.completeImport}
          </AppButton>
        );
      case ImportStatus.IMPORT_SUCCESSFUL:
        return (
          <View style={styles.importStatus}>
            <View style={styles.uploadMessage}>
              <MaterialIcons name="check-circle" size={24} color="green" />
              <AppText style={styles.uploadMessageText} font="labelDefault" color="grayDark">
                {translate.superAdmin.importData.importIsSuccessful}
              </AppText>
            </View>
            {route.name === 'LoggedIn_Admin_ImportData' ? (
              <AppLink onPress={navigateToData}>{translate.superAdmin.importData.viewData}</AppLink>
            ) : null}
          </View>
        );
      case ImportStatus.IMPORT_FAILED:
        return (
          <View style={styles.importStatus}>
            <View style={styles.uploadMessage}>
              <MaterialIcons name="error" size={24} color="red" />
              <AppText style={styles.uploadMessageText} font="labelDefault" color="navyDark">
                {translate.superAdmin.importData.importFailed}
              </AppText>
            </View>
            <View style={styles.uploadMessage}>
              <AppText style={{ marginRight: 10 }} font="labelDefault" color="navyDark">
                {validationErrorMessage}
              </AppText>
            </View>
          </View>
        );
      case ImportStatus.UPLOAD_AND_VALIDATE_IN_PROGRESS:
        return (
          <View style={styles.importStatus}>
            <AppText font="labelDefault">{translate.superAdmin.importData.uploadAndValidateInProgress}</AppText>
            <AppLink onPress={() => setCurrentTab(Tabs.PAST_UPLOADS)} style={styles.uploadMessageText}>
              {translate.superAdmin.importData.viewPastImports}
            </AppLink>
          </View>
        );
      case ImportStatus.INVALID_DATA:
        return (
          <View style={styles.importStatus}>
            <View style={styles.uploadMessage}>
              <MaterialIcons name="error" size={24} color={colors.orange} />
              <AppText style={styles.uploadMessageText} font="labelDefault" color="grayDark">
                {translate.superAdmin.importData.failureMessage}
              </AppText>
            </View>
            <View style={styles.uploadMessage}>
              <AppLink onPress={handleViewInvalidRows} style={styles.uploadMessageText}>
                {translate.superAdmin.importData.invalidRowsMessage}
              </AppLink>
              {route.name === 'LoggedIn_Admin_ImportData' ? (
                <AppLink onPress={navigateToData}>{translate.superAdmin.importData.viewData}</AppLink>
              ) : null}
            </View>
          </View>
        );
      case ImportStatus.IMPORT_FAILED:
        return (
          <View>
            <View style={styles.importStatus}>
              <View style={styles.uploadMessage}>
                <MaterialIcons name="error" size={24} color="red" />
                <AppText font="labelDefault" color="navyDark">
                  {translate.superAdmin.importData.importFailed}
                </AppText>
              </View>
              <View style={styles.uploadMessage}>
                <AppText style={{ marginRight: 10 }} font="labelDefault" color="navyDark">
                  {validationErrorMessage}
                </AppText>
              </View>
            </View>
          </View>
        );
      case ImportStatus.COMPLETE_IMPORT:
        return (
          <View style={styles.importStatus}>
            <View style={styles.uploadMessage}>
              <MaterialIcons name="check-circle" size={24} color="green" />
              <AppText style={styles.uploadMessageText} font="labelDefault" color="grayDark">
                {translate.superAdmin.importData.successMessage}
              </AppText>
            </View>
          </View>
        );
      default:
        return <></>;
    }
  };

  return (
    <View style={styles.formFieldContainer}>
      <ErrorsModal
        title={
          csvAttachments[value]
            ? `${translate.superAdmin.importData.importErrors}: ${csvAttachments[value].filename}`
            : ''
        }
        rowValidationErrors={rowValidationErrors}
        showErrorsModal={showErrorsModal}
        setShowErrorsModal={setShowErrorsModal}
      />
      <View style={styles.labelsContainer}>
        <AppText font="header6" style={styles.labels}>
          {title}
        </AppText>
      </View>
      <View style={styles.buttonsContainer}>
        <View style={styles.fileLabel}>{renderUploadButton()}</View>
        <View style={styles.importStatusContainer}>{renderImportStatus(importStatuses[value])}</View>
      </View>
    </View>
  );
};
