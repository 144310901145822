import { keys, values, zip } from 'lodash';
import React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';

export type SpacerItems = Record<string, React.ReactNode>;
export type SpacerSizes = 'small' | 'default' | 'large';
export type SpacerOrientation = 'horizontal' | 'vertical';
interface SpacerProps {
  items: SpacerItems;
  size?: SpacerSizes;
  orientation?: SpacerOrientation;
  style?: StyleProp<ViewStyle>;
}

export const Spacer: React.FC<SpacerProps> = ({ style, orientation = 'vertical', items, size = 'default' }) => {
  const zipped = zip(keys(items), values(items));
  let space = 10;
  if (size === 'large') {
    space = 20;
  } else if (size === 'small') {
    space = 5;
  } else {
    space = 10;
  }

  const modeStyles =
    orientation === 'horizontal'
      ? StyleSheet.create({
          root: {
            flexDirection: 'row',
            alignItems: 'stretch',
            width: '100%',
          },
          item: {
            marginRight: space,
          },
        })
      : StyleSheet.create({
          root: {
            flexDirection: 'column',
          },
          item: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: 'auto',
            marginBottom: space,
          },
        });

  const lastItemStyle =
    orientation === 'horizontal'
      ? StyleSheet.create({
          item: {
            marginRight: 0,
          },
        })
      : StyleSheet.create({
          item: {
            marginBottom: 0,
          },
        });

  const styles = StyleSheet.create({
    root: {
      display: 'flex',
    },
  });

  return (
    <View style={StyleSheet.flatten([styles.root, modeStyles.root, style])}>
      {zipped.map(([key, element], index) => {
        const isLastItem = index === zipped.length - 1;
        const _styles: ViewStyle[] = [modeStyles.item];
        if (isLastItem) {
          _styles.push(lastItemStyle.item);
        }
        return (
          <View key={key} style={StyleSheet.flatten(_styles)}>
            {element}
          </View>
        );
      })}
    </View>
  );
};
