import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { PastUploadProps } from './ImportData.interface';
import { AppText } from '../../../../uiComponents';
import { useGetUploadedCsvFiles } from '../../hooks/useGetUploadedCsvFiles';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';
import { PastUploadCard } from './PastUploadCard';
import { useTranslations } from '../../../../i18n/useTranslations';
import { GetUploadedCsvFiles_getUploadedCsvFiles_UploadedCsvFilesResult as PastUpload } from '../../hooks/__generated__/GetUploadedCsvFiles';

export const PastUploads: React.FC<PastUploadProps> = (props) => {
  const { selectedOrganization } = props;
  const { currentUser } = useCurrentUser();
  const { translate } = useTranslations();

  const styles = StyleSheet.create({
    pastUploadContainer: {
      marginTop: 20,
      marginBottom: 200,
      paddingBottom: 200,
    },
  });

  const { data: csvFiles } = useGetUploadedCsvFiles({
    variables: {
      input: {
        userId: currentUser ? currentUser.id : '',
        organizationId: selectedOrganization,
      },
    },
  });

  const pastUploadsToSort =
    csvFiles && csvFiles.getUploadedCsvFiles.UploadedCsvFilesResult.length > 0
      ? [...csvFiles.getUploadedCsvFiles.UploadedCsvFilesResult]
      : [];

  return (
    <View style={styles.pastUploadContainer}>
      {csvFiles && csvFiles.getUploadedCsvFiles.UploadedCsvFilesResult.length === 0 ? (
        <AppText style={{ textAlign: 'center' }}>{translate.superAdmin.importData.noPastUploadsAvailable}</AppText>
      ) : null}
      {pastUploadsToSort.length > 0
        ? pastUploadsToSort
            .sort(
              (a, b) => Math.floor(new Date(b.createdDate).getTime()) - Math.floor(new Date(a.createdDate).getTime())
            )
            .map((pastUpload: PastUpload) => (
              <PastUploadCard key={pastUpload.id} pastUpload={pastUpload} selectedOrganization={selectedOrganization} />
            ))
        : null}
    </View>
  );
};
