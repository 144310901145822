import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { AppText } from '../AppText/AppText';

export interface AutocompleteListItemType<T> {
  key: string;
  value: T;
  label: string;
}

interface AutocompleteListItemProps<T> {
  item: AutocompleteListItemType<T>;
  onPress?: (result: T) => void;
}

export const AutocompleteListItem = <T,>({ item, onPress }: AutocompleteListItemProps<T>) => {
  const styles = StyleSheet.create({
    item: {
      justifyContent: 'center',
      paddingTop: 30,
      paddingBottom: 30,
      margin: 0,
    },
  });

  const handlePress = () =>
    onPress
      ? onPress(item.value)
      : () => {
          /* noop */
        };

  return (
    <TouchableOpacity onPress={handlePress} style={styles.item}>
      <AppText font="labelDefault" color="blue">
        {item.label}
      </AppText>
    </TouchableOpacity>
  );
};
