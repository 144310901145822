/* Some utils for media query style functionality
=============================== */
import { Platform, StyleSheet } from 'react-native';
import { vw, vh } from 'react-native-expo-viewport-units';
import { useMediaQuery } from 'react-responsive';

interface ResponsiveStylesheet {
  query: QueryOptions;
  styles: StyleSheet.NamedStyles<any>;
}

interface QueryOptions {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  orientation?: string;
}

const MOBILE_MAX_VIEWPORT_HEIGHT = 1300;
const MOBILE_MAX_VIEWPORT_WIDTH = 992;

export const useIsMobile = () => {
  return (
    useMediaQuery({ maxWidth: MOBILE_MAX_VIEWPORT_WIDTH, maxHeight: MOBILE_MAX_VIEWPORT_HEIGHT }) ||
    Platform.OS !== 'web'
  );
};

export const useIsMobileLandscape = () => {
  const isMobile = useIsMobile;
  return isMobile() && vw(100) > vh(100);
};

export const useIsMobileApp = () => Platform.OS !== 'web';

// evaluate media query
const isWithinSize = ({ minWidth, maxWidth, minHeight, maxHeight, orientation }: QueryOptions) => {
  if (minWidth && vw(100) < minWidth) {
    return false;
  }
  if (maxWidth && vw(100) > maxWidth) {
    return false;
  }
  if (minHeight && vh(100) < minHeight) {
    return false;
  }
  if (maxHeight && vh(100) > maxHeight) {
    return false;
  }
  if (orientation && orientation.toLowerCase() === 'landscape' && vw(100) < vh(100)) {
    return false;
  }
  if (orientation && orientation.toLowerCase() === 'portrait' && vw(100) > vh(100)) {
    return false;
  }

  return true;
};

// conditionally display children based on media query
export const MediaQuery = ({ children, query }) => {
  const display = isWithinSize(query);
  return display ? children : null;
};

// choose styles based on media query
export const useStyleSheet = (stylesheet: ResponsiveStylesheet[]) => {
  const selectedSheets = stylesheet.filter(({ query: _query }) => isWithinSize(_query));

  const firstSheet = selectedSheets[0];

  if (!firstSheet) {
    return StyleSheet.create({});
  } else {
    const { styles } = firstSheet;

    return StyleSheet.create(styles);
  }
};
