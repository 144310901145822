import { gql } from '@apollo/client';

export const OverviewSubjectMembershipsFragment = gql`
  fragment OverviewSubjectMemberships on SubjectMemberships {
    id
    status
    organizations {
      id
      status
      organization {
        id
        name
      }
      groups {
        id
        name
      }
    }
  }
`;
