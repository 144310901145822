import React from 'react';
import { View } from 'react-native';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppText, Spacer } from '../../../../uiComponents';

export interface EmergencyContactProps {
  fullName: string;
  phoneNumber: string;
  onCallContact: (contactId: string) => void;
}

export const EmergencyContactCard: React.FC<EmergencyContactProps> = (props) => {
  const { fullName, phoneNumber, onCallContact } = props;
  const { translate, translateWithVars } = useTranslations();

  const handleCallButtonPress = () => onCallContact(phoneNumber);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Spacer
        orientation="vertical"
        items={{
          name: (
            <AppText font="labelDefault" color="grayDark">
              {fullName}
            </AppText>
          ),
          phoneNumber: (
            <AppText>
              {translateWithVars(translate.emergencyContact.contactPhoneNumber, {
                phoneNumber,
              })}
            </AppText>
          ),
        }}
      />
      <AppButton size="extraSmall" onPress={handleCallButtonPress}>
        {translate.label.call}
      </AppButton>
    </View>
  );
};
