import { isApiError } from '../../../../../utils/isApiError';
import { useProfileContext } from '../../../ProfileContext';
import { CaseLogContext } from '../CaseLogs.interface';
import { useSubjectCaseLogQuery } from './useSubjectCaseLogQuery';
import { SubjectCaseLogQuery } from './__generated__/SubjectCaseLogQuery';
import { useGetMyCaseLog } from './useGetMyCaseLog';
import { useCurrentUser } from '../../../../../shared/CurrentUser/useCurrentUser';
import { Case } from '../../../../../fragments/__generated__/Case';

interface UserCaseLog {
  userCaseLog: Case;
}

interface CaseLog {
  subject: {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    birthday: string;
  };
  kase: Case;
}

const makeMyCaseLog = (currentUser, data?: UserCaseLog): CaseLog | null => {
  if (
    !data ||
    isApiError(data.userCaseLog) ||
    data.userCaseLog.__typename !== 'Case' ||
    !data.userCaseLog.items?.length
  ) {
    return null;
  }

  return {
    subject: {
      id: data.userCaseLog.id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      fullName: currentUser.fullName,
      birthday: currentUser.birthday,
    },
    kase: data.userCaseLog,
  };
};

const makeSubjectCaseLog = (data?: SubjectCaseLogQuery): CaseLogContext | null => {
  if (
    !data ||
    isApiError(data.getSubject) ||
    data.getSubject.__typename !== 'Subject' ||
    data.getSubject.case.__typename !== 'Case'
  ) {
    return null;
  }

  return {
    subject: {
      id: data.getSubject.id,
      firstName: data.getSubject.firstName,
      lastName: data.getSubject.lastName,
      fullName: data.getSubject.fullName,
      birthday: data.getSubject.birthday,
    },
    kase: data.getSubject.case,
  };
};

export const useCaseLog = (caseId: string): CaseLogContext | null => {
  const { subjectId } = useProfileContext();
  const { currentUser } = useCurrentUser();

  let mode: 'me' | 'subject' | undefined;

  const isMe = !subjectId ? true : false;

  if (isMe && caseId) {
    mode = 'me';
  } else if (!isMe && caseId) {
    mode = 'subject';
  }

  const myCaseLog = useGetMyCaseLog({
    skip: mode === 'me' ? false : true,
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
    variables: {
      input: {
        caseId: caseId as string,
      },
    },
  });

  const subjectCaseLog = useSubjectCaseLogQuery({
    skip: mode === 'subject' ? false : true,
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
    variables: {
      subjectInput: {
        subjectId: subjectId as string,
      },
      caseInput: {
        caseId: caseId as string,
      },
    },
  });

  switch (mode) {
    case 'me':
      return makeMyCaseLog(currentUser, myCaseLog.data);
    case 'subject':
      return makeSubjectCaseLog(subjectCaseLog.data);
    default:
      return null;
  }
};
