import * as React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { RadioButtonDirection, RadioButtonOptionType } from './RadioButton.interface';
import { Icon } from '../Icon/Icon';
import { useColors } from '../../shared/Ui/hooks/useColors';
import { AppText } from '../AppText/AppText';

export interface RadioButtonOptionProps<T> {
  option: RadioButtonOptionType<T>;
  value?: T | null;
  direction?: RadioButtonDirection;
  onSelect?: (value: T) => void;
  allDisabled?: boolean | undefined | null;
}

export const RadioButtonOption: <T = any>(props: RadioButtonOptionProps<T>) => React.ReactElement = (props) => {
  const {
    direction = 'horizontal',
    value: selectedValue,
    onSelect,
    option: { value, icon, image, label, disabled: _disabled },
    allDisabled,
  } = props;

  const disabled = _disabled || allDisabled;

  const colors = useColors();

  const styles = StyleSheet.create({
    box: {
      flexShrink: direction === 'horizontal' ? 1 : 1,
      flexGrow: direction === 'horizontal' ? 1 : 0,
      flexBasis: direction === 'horizontal' ? 'auto' : '100%',
    },
    image: {
      width: '100%',
      aspectRatio: 1,
      borderBottomRightRadius: 14,
      borderBottomLeftRadius: 14,
    },
    default: {
      flex: direction === 'horizontal' ? 1 : 0,
    },
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      paddingLeft: direction === 'horizontal' ? 10 : 0,
      paddingRight: direction === 'horizontal' ? 10 : 0,
      flexShrink: direction === 'horizontal' ? 1 : 0,
      flex: direction === 'horizontal' ? 1 : 0,
    },
    label: {
      flexShrink: 0,
      flexGrow: 1,
      flexBasis: 'auto',
    },
    icon: {
      width: 25,
      height: 25,
      aspectRatio: 1,
      color: colors.white,
      borderRadius: 12,
      overflow: 'hidden',
      paddingLeft: 1,
      paddingTop: 2,
      marginRight: direction === 'horizontal' ? 10 : 10,
    },
    iconSelected: {
      backgroundColor: colors.green,
    },
    iconDeselected: {
      borderWidth: 2,
      borderColor: colors.blue,
    },
    disabled: {
      opacity: 0.5,
    },
  });

  const handleSelect = () => {
    if (onSelect && !disabled) {
      onSelect(value);
    }
  };

  const isActive = selectedValue === value;

  const iconDisplay = icon ? (
    <Icon name={icon} size={22} style={[styles.icon, isActive ? styles.iconSelected : styles.iconDeselected]} />
  ) : null;

  const imageDisplay = image ? <View style={styles.image}>{image}</View> : null;

  const maybeWrapInTouchable = (node: React.ReactNode) =>
    disabled ? (
      <View style={StyleSheet.flatten([styles.default, styles.disabled])}>{node}</View>
    ) : (
      <TouchableOpacity style={styles.default} onPress={handleSelect} onLongPress={handleSelect}>
        {node}
      </TouchableOpacity>
    );

  return (
    <View style={styles.box}>
      {maybeWrapInTouchable(
        <>
          <View style={styles.wrapper}>
            {iconDisplay}
            <AppText font="bodyDefault" color="navyDark" style={StyleSheet.flatten([styles.label])}>
              {label}
            </AppText>
          </View>
          {imageDisplay}
        </>
      )}
    </View>
  );
};
