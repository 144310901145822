import { useState, RefObject } from 'react';
import { TextInput } from 'react-native';
import { Role } from '../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../i18n/useTranslations';

export type SortType = 'firstName (A-Z)' | 'lastName (A-Z)' | 'firstName (Z-A)' | 'lastName (Z-A)';
export type SortLabel = `${string} (A-Z)` | `${string} (Z-A)`;

export type SortOrder = 'asc' | 'desc';
type SortBy = 'firstName' | 'lastName';
export type SortValue = `${SortBy}_${SortOrder}`;

interface SortCategories {
  key: SortType;
  value: SortValue;
  label: SortLabel;
}

export type StatusFilterValue = string;
type StatusFilterKey = 'all' | 'accepted' | 'pending';

interface StatusFilterOptions {
  key: StatusFilterKey;
  value: StatusFilterValue;
  label: StatusFilterValue;
}

export const useSearchAndSort = (inputRef: RefObject<TextInput> | null = null) => {
  const { translate } = useTranslations();

  const roleFilterOptions = [
    {
      key: 'all',
      value: 'All',
      label: translate.searchAndSort.all,
    },
    {
      key: 'subject',
      value: Role.SUBJECT,
      label: translate.users.roles.subject,
    },
    {
      key: 'guardian',
      value: Role.GUARDIAN,
      label: translate.users.roles.guardian,
    },
    {
      key: 'primaryDesignatedPerson',
      value: Role.PRIMARY_DESIGNATED_PERSON,
      label: translate.users.roles.primaryDesignatedPerson,
    },
    {
      key: 'secondaryDesignatedPerson',
      value: Role.SECONDARY_DESIGNATED_PERSON,
      label: translate.users.roles.secondaryDesignatedPerson,
    },
    {
      key: 'admin',
      value: Role.ADMIN,
      label: translate.users.roles.admin,
    },
  ];

  // TODO: remove SortCategories as the type once there are translations
  const sortCategories: SortCategories[] = [
    {
      key: 'firstName (A-Z)',
      value: 'firstName_asc',
      label: `${translate.placeholder.firstName} (A-Z)`,
    },
    {
      key: 'firstName (Z-A)',
      value: 'firstName_desc',
      label: `${translate.placeholder.firstName} (Z-A)`,
    },
    {
      key: 'lastName (A-Z)',
      value: 'lastName_asc',
      label: `${translate.placeholder.lastName} (A-Z)`,
    },
    {
      key: 'lastName (Z-A)',
      value: 'lastName_desc',
      label: `${translate.placeholder.lastName} (Z-A)`,
    },
  ];

  const statusFilterOptions: StatusFilterOptions[] = [
    {
      key: 'all',
      value: 'All',
      label: `${translate.searchAndSort.all}`,
    },
    {
      key: 'accepted',
      value: 'Accepted',
      label: `${translate.users.accepted}`,
    },
    {
      key: 'pending',
      value: 'Pending',
      label: `${translate.recovery.index.pending}`,
    },
  ];

  const [roleFilter, setRoleFilter] = useState<string>('All');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<StatusFilterValue>(statusFilterOptions[0].value);
  const [selectedSortType, setSelectedSortType] = useState<SortValue>(sortCategories[2].value);
  const [searchStr, setSearchStr] = useState<string>('');
  const [isSearchInputFocused, setIsSearchInputFocused] = useState<boolean>(false);

  const handleSortChange = (sortType: SortValue) => {
    setSelectedSortType(sortType);
  };

  const handleSearchStr = (name: string) => {
    setSearchStr(name);
    if (inputRef && inputRef.current && isSearchInputFocused) {
      inputRef.current.focus();
    }
  };

  const handleRoleFilterChange = (role: string) => {
    setRoleFilter(role);
  };

  const handleStatusFilterChange = (status: StatusFilterValue) => {
    setSelectedStatusFilter(status);
  };

  return {
    roleFilter,
    roleFilterOptions,
    handleRoleFilterChange,
    handleSortChange,
    statusFilterOptions,
    selectedStatusFilter,
    handleStatusFilterChange,
    handleSearchStr,
    selectedSortType,
    searchStr,
    sortCategories,
    setIsSearchInputFocused,
  };
};
