import { gql } from '@apollo/client';
import { useQuery } from '../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../fragments/ApiErrorFragment';
import { GetCircleOfCare } from './__generated__/GetCircleOfCare';

export const GET_CIRCLE_OF_CARE = gql`
  query GetCircleOfCare($subjectId: String!) {
    circleOfCare(subjectId: $subjectId) {
      ... on CircleOfCare {
        guardians {
          id
          firstName
          lastName
          email
          userId
          organizationId
          phoneNumber {
            phoneNumber
          }
        }
        primaryDesignatedPersons {
          id
          firstName
          lastName
          email
          phoneNumber {
            phoneNumber
          }
        }
        secondaryDesignatedPersons {
          id
          firstName
          lastName
          email
          groups
          phoneNumber {
            phoneNumber
          }
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetCircleOfCare = (subjectId: string) =>
  useQuery<GetCircleOfCare>(GET_CIRCLE_OF_CARE, {
    variables: { subjectId },
    hideLoading: true,
    fetchPolicy: 'network-only',
  });
