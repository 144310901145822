import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';
import { AppButton, AppText, Spacer } from '../../uiComponents';
import { Chrome } from '../Chrome';
import { useTranslations } from '../../i18n/useTranslations';

const Native: React.FC = () => {
  const { translate } = useTranslations();

  const handleReload = () => {
    if (Platform.OS !== 'web') {
      Updates.reloadAsync();
    }
  };
  return (
    <Spacer
      items={{
        title: <AppText font="header1">{translate.errorBoundary.title}</AppText>,
        message: <AppText font="bodyDefault">{translate.errorBoundary.message}</AppText>,
        button: <AppButton onPress={handleReload}>{translate.errorBoundary.reloadButton}</AppButton>,
      }}
    />
  );
};

const Web: React.FC = () => {
  const { translate } = useTranslations();

  const handleReload = () => {
    if (Platform.OS === 'web') {
      window.location.reload();
    }
  };
  return (
    <Spacer
      items={{
        title: <AppText font="header1">{translate.errorBoundary.title}</AppText>,
        message: <AppText font="bodyDefault">{translate.errorBoundary.message}</AppText>,
        button: <AppButton onPress={handleReload}>{translate.errorBoundary.reloadButton}</AppButton>,
      }}
    />
  );
};
export const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [error, setError] = useState<Error>();

  const handleError = (error: Error, componentStack: string, eventId: string) => {
    setError(error);
    console.error(error, componentStack, eventId);
  };

  const errorDisplay = <Chrome mode="full-screen" content={Platform.OS === 'web' ? <Web /> : <Native />} />;

  return (
    <Sentry.ErrorBoundary
      onError={handleError}
      fallback={() => errorDisplay}
      children={error ? errorDisplay : children}
    />
  );
};
