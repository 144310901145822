import React, { useState, useRef } from 'react';
import { StyleSheet, TextInput, View, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Chrome } from '../../../../shared/Chrome/Chrome';
import {
  AppButton,
  AppText,
  Input,
  Select,
  SVG,
  ActivityIndicator,
  UserList,
  AppModal,
  PageNavButtons,
} from '../../../../../uiComponents';
import { useAppAlert } from '../../../../../hooks/ui/useAppAlert';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useNudgePendingParticipants } from '../hooks/useNudgePendingParticipants';
import { isApiError } from '../../../../../utils/isApiError';
import { useAdminContext } from '../../../hooks/useAdminContext';
import { ManageUsersNavType } from '../ManageUsers.interface';
import { useSearchAndSort, StatusFilterValue, SortValue } from '../../ManageGroups/GroupDetails/hooks/useSearchAndSort';
import { useIsMobile } from '../../../../../hooks/ui/useResponsive';
import { useGetOrganizationUsers } from '../../../hooks/useGetOrganizationUsers';
import { useNudgePendingUserRoleInvitation } from '../hooks/useNudgePendingUserRoleInvitation';
import { useColors } from '../../../../../shared/Ui';
import { Name, Email, Roles } from '../Columns';
import { useResendEducationItems } from './../hooks/useResendEducationItems';
import { useGetAllUserRoles } from '../../../../../hooks/useGetAllUserRoles';

export const ParticipationReport: React.FC = () => {
  const { organizationId } = useAdminContext();
  const inputRef = useRef<TextInput>(null);
  const {
    handleSortChange,
    handleSearchStr,
    selectedSortType,
    searchStr,
    sortCategories,
    setIsSearchInputFocused,
    roleFilter,
    roleFilterOptions,
    handleRoleFilterChange,
    statusFilterOptions,
    selectedStatusFilter,
    handleStatusFilterChange,
  } = useSearchAndSort(inputRef);

  const { data: rolesData } = useGetAllUserRoles();
  const userRolesForOrganization = rolesData
    ? rolesData.allUserRoles.filter((role) => role.organizationId === organizationId).map((role) => role.role)
    : [];
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showEducationItemsSentModal, setShowEducationItemsSentModal] = useState<boolean>(false);

  const { translateWithVars, translate } = useTranslations();
  const isMobile = useIsMobile();
  const colors = useColors();
  const navigation = useNavigation<ManageUsersNavType<'LoggedIn_Admin_ManageUsers_ParticipationReport'>>();
  const { alert } = useAppAlert();
  const [nudge, { loading: nudgeLoading }] = useNudgePendingParticipants({
    onCompleted: (res) => {
      const total = !isApiError(res.nudgePendingParticipants) ? res.nudgePendingParticipants.total : 0;
      alert(translateWithVars(translate.users.nudgedAlert, { total }));
    },
  });

  const [nudgeUser, { loading }] = useNudgePendingUserRoleInvitation({
    onCompleted: (res) => {
      const total = !isApiError(res.nudgePendingUserRoleInvitation) ? res.nudgePendingUserRoleInvitation.total : 0;
      alert(translateWithVars(translate.users.nudgedAlert, { total }));
    },
  });

  const [resendEducationItems, { loading: resendLoading }] = useResendEducationItems(userRolesForOrganization, {
    variables: {
      organizationId,
    },
    onCompleted: () => {
      setShowConfirmModal(false);
      setShowEducationItemsSentModal(true);
    },
  });

  const handleNudge = (userId: string) =>
    nudgeUser({
      variables: {
        input: {
          userId,
          organizationId,
        },
      },
    });

  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data: userData } = useGetOrganizationUsers({
    variables: {
      organizationId,
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      role: roleFilter === 'All' ? null : roleFilter,
      searchStr: searchStr ? searchStr : null,
      status: selectedStatusFilter,
    },
  });

  const styles = StyleSheet.create({
    buttonPanel: {
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      paddingBottom: 24,
    },
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
    },
    sortSelect: {
      width: isMobile ? '100%' : '23%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
      paddingRight: 15,
    },
    searchContainer: {
      width: isMobile ? '100%' : '40%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
    },
    buttonsAndPageNavigation: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
      flexWrap: isMobile ? 'wrap' : 'nowrap',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    button: {
      marginTop: isMobile ? 20 : 0,
    },
    participationStatus: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: isMobile ? 'flex-end' : 'flex-start',
      width: 120,
    },
    reload: {
      marginLeft: 20,
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const handleNudgePress = () =>
    nudge({
      variables: {
        organizationId,
      },
    });

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const users = userData?.organizationUsers;

  const ParticipationStatus: React.FC<{ accepted: boolean | null; userId: string }> = ({ accepted, userId }) => {
    return (
      <View style={styles.participationStatus}>
        <AppText font="bodyDefault" color={accepted ? 'green' : 'orange'}>
          {accepted ? translate.users.accepted : translate.recovery.index.pending}
        </AppText>
        {!accepted ? (
          <TouchableOpacity disabled={loading} onPress={() => handleNudge(userId)} style={styles.reload}>
            <SVG color={colors.blue} height={isMobile ? 18 : 24} width={isMobile ? 18 : 24} image="reload" />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  return (
    <Chrome
      navBarProps={{ onBack: () => navigation.navigate('LoggedIn_Admin_ManageUsers_Index') }}
      mode="title-card"
      titleText={translate.users.participationReportTitle}
      content={
        <>
          <AppModal
            title={translate.recognize.exitModal.title}
            children={
              <>
                <AppText style={{ marginBottom: 20 }}>
                  {translate.users.userDetails.resendEducationConfirmation}
                </AppText>
              </>
            }
            mode="dialog"
            onDismiss={() => setShowConfirmModal(false)}
            visible={showConfirmModal}
            buttons={{
              done: (
                <AppButton onPress={() => resendEducationItems()}>
                  {translate.users.userDetails.resendEducationItems}
                </AppButton>
              ),
            }}
          />
          <AppModal
            title={translate.users.userDetails.educationItemsResent}
            children={
              <>
                <AppText style={{ marginBottom: 20 }}>
                  {translate.users.userDetails.educationItemsResentDetails}
                </AppText>
              </>
            }
            mode="dialog"
            onDismiss={() => setShowEducationItemsSentModal(false)}
            visible={showEducationItemsSentModal}
            buttons={{
              confirm: (
                <AppButton onPress={() => setShowEducationItemsSentModal(false)}>{translate.label.done}</AppButton>
              ),
            }}
          />

          <View style={styles.searchAndSortContainer}>
            <View style={styles.sortSelect}>
              <AppText>{translate.users.userDetails.sort}</AppText>
              <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
            </View>
            <View style={styles.sortSelect}>
              <AppText>{translate.subjectStatus.modalTitle}</AppText>
              <Select<StatusFilterValue>
                value={selectedStatusFilter}
                items={statusFilterOptions}
                onChange={handleStatusFilterChange}
              />
            </View>
            <View style={styles.sortSelect}>
              <AppText>{translate.users.userDetails.role}</AppText>
              <Select<string> value={roleFilter} items={roleFilterOptions} onChange={handleRoleFilterChange} />
            </View>
            <View style={styles.searchContainer}>
              <Input
                icon="search"
                ref={inputRef}
                onFocus={() => setIsSearchInputFocused(true)}
                onBlur={() => setIsSearchInputFocused(false)}
                placeholder={translate.users.userDetails.searchByName}
                type="name"
                debounce={{
                  milliseconds: 1000,
                  callback: handleSearchStr,
                  textValue: searchStr,
                }}
              />
            </View>
          </View>
          <View style={styles.buttonsAndPageNavigation}>
            <View style={styles.buttonContainer}>
              <AppButton
                size={isMobile ? 'extraSmall' : 'small'}
                style={{ marginRight: 10, ...styles.button }}
                onPress={handleNudgePress}
                disabled={nudgeLoading}
              >
                {translate.users.userDetails.nudgeAllPending}
              </AppButton>
              <AppButton
                size={isMobile ? 'extraSmall' : 'small'}
                loading={resendLoading}
                onPress={() => setShowConfirmModal(true)}
                style={styles.button}
              >
                {translate.users.userDetails.resendEducationItems}
              </AppButton>
            </View>
            <PageNavButtons
              isPrevPageButtonVisible={currentPage !== 0}
              isNextPageButtonVisible={users && users.length === 25}
              prevPageAction={goToPreviousPage}
              nextPageAction={goToNextPage}
            />
          </View>
          {users ? (
            <UserList
              noUsersMessage={translate.users.userDetails.noUsers}
              rightAlignLastItem={true}
              displayItems={users.map((user) => ({
                key: user.id,
                components: [
                  <Name name={user.fullName} />,
                  <Email email={user.email ? user.email : ''} />,
                  <Roles roles={user.memberships?.map((role) => role.role)} />,
                  <ParticipationStatus
                    userId={user.id}
                    accepted={
                      user.memberships &&
                      user.memberships.filter((membership) => membership.status === 'ACCEPTED').length > 0
                    }
                  />,
                ],
              }))}
            />
          ) : (
            <ActivityIndicator style={styles.activityIndicator} />
          )}
        </>
      }
    />
  );
};
