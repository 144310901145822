import React, { useState } from 'react';
import { Linking, StyleSheet, View, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { MedicalAssessmentDiagnosis, StageState } from '../../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import {
  AppButton,
  AppMarkdown,
  AppModal,
  AppScrollView,
  AppText,
  FileButton,
  FileButtonSelectedFile,
  ProtocolAttribution,
  RadioButton,
  Spacer,
} from '../../../../../../../uiComponents';
import { RecoveryPlanNavType } from '../../../RecoveryPlan.interface';
import { useSubmitMedicalAssessment } from '../../../hooks/useSubmitMedicalAssessment';
import { ProfileChrome } from '../../../../../ProfileChrome/ProfileChrome';
import { MedicalAssessment } from '../../../fragments/__generated__/MedicalAssessment';
import { LockedAction } from '../LockedAction';
import { RejectionReason } from '../RejectionReason';
import { useColors, useShadow } from '../../../../../../../shared/Ui';
import { useIsIncidentReportIncomplete } from '../../../hooks/useIsIncidentReportIncomplete';
import { IncidentReportNoticeModal } from '../IncidentReportNoticeModal';

export interface SubmitMedicalAssessmentProps {
  medicalAssessment: MedicalAssessment;
  canSubmit?: boolean;
  caseCreatedDate?: string;
  isComplete?: boolean;
}

export const SubmitMedicalAssessment: React.FC<SubmitMedicalAssessmentProps> = ({
  medicalAssessment,
  canSubmit = true,
  isComplete,
}) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Stage'>>();
  const [submitMedicalAssessment] = useSubmitMedicalAssessment();
  const [diagnosis, setDiagnosis] = useState<MedicalAssessmentDiagnosis | undefined>();
  const [isIncidentReportNoticeModalVisible, setIsIncidentReportNoticeModalVisible] = useState<boolean>(false);
  const [image, setImage] = useState<FileButtonSelectedFile>();
  const [showSubmittedDialog, setSubmittedDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: incidentReportData } = useIsIncidentReportIncomplete(medicalAssessment.caseId);

  const colors = useColors();
  const shadow = useShadow();

  const handleDownloadTemplate = () => {
    if (medicalAssessment.templateDocument?.downloadUri) {
      Linking.openURL(medicalAssessment.templateDocument.downloadUri);
    }
  };

  const handlePickDocument = async (file: FileButtonSelectedFile) => setImage(file);

  const handleRemoveAssessment = () => setImage(undefined);

  const handleDiagnosis = (value: MedicalAssessmentDiagnosis) => {
    setDiagnosis(value);
  };

  const handleSubmitButtonPress = () => {
    if (diagnosis === MedicalAssessmentDiagnosis.NO_CONCUSSION && incidentReportData?.isIncidentReportIncomplete) {
      setIsIncidentReportNoticeModalVisible(true);
    } else {
      setIsSubmitting(true);
      submitMedicalAssessmentAction().then(() => {
        setSubmittedDialog(true);
        setIsSubmitting(false);
      });
    }
  };

  const handleDismissSubmittedDialog = () => {
    setSubmittedDialog(false);
    setTimeout(() => navigation.navigate('LoggedIn_Profile_RecoveryPlan_Index'));
  };

  const submitMedicalAssessmentAction = () => {
    if (!image) {
      throw new Error('no image set');
    }

    if (!diagnosis) {
      throw new Error('no diagnosis provided');
    }

    return submitMedicalAssessment({
      variables: {
        input: {
          caseId: medicalAssessment.caseId,
          diagnosis,
          file: image,
        },
      },
    });
  };

  const styles = StyleSheet.create({
    buttons: {
      marginTop: 0,
    },
    secondButton: {
      marginTop: 10,
    },
    diagnosis: {
      marginTop: 20,
    },
    buttonContainer: {
      marginTop: 100,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    linkButton: {
      color: colors.blue,
    },
    itemGroupContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...shadow,
      padding: 14,
      marginBottom: 12,
      backgroundColor: colors.white,
      borderRadius: 8,
    },
  });

  const handleViewDocument = () => {
    if (medicalAssessment.document?.downloadUri) {
      Linking.openURL(medicalAssessment.document.downloadUri);
    }
  };

  const submitContent =
    medicalAssessment.iCanSubmit && !isComplete ? (
      <>
        <View style={styles.buttons}>
          <AppButton icon="file-text" onPress={handleDownloadTemplate}>
            {translate.label.downloadTemplate}
          </AppButton>
          <FileButton
            style={styles.secondButton}
            file={image}
            onFileChange={handlePickDocument}
            onFileClear={handleRemoveAssessment}
            mediaType="image/pdf"
          >
            {image ? translate.label.medicalAssessment : translate.label.uploadAssessment}
          </FileButton>
        </View>
        <AppText style={styles.diagnosis} font="header5" gutter={true}>
          {translate.recovery.submitMedicalAssessment.diagnosis}
        </AppText>
        <RadioButton
          direction="vertical"
          disabled={false}
          value={diagnosis}
          onSelect={handleDiagnosis}
          options={[
            {
              icon: 'check',
              label: translate.recovery.submitMedicalAssessment.concussion,
              value: MedicalAssessmentDiagnosis.CONCUSSION,
            },
            {
              icon: 'check',
              label: translate.recovery.submitMedicalAssessment.noConcussion,
              value: MedicalAssessmentDiagnosis.NO_CONCUSSION,
            },
          ]}
        />

        <View style={styles.buttonContainer}>
          {medicalAssessment.iCanSubmit ? (
            <AppButton
              loading={isSubmitting}
              disabled={!(image && diagnosis) || isSubmitting || !canSubmit}
              onPress={() => handleSubmitButtonPress()}
              style={{ width: 200 }}
            >
              {translate.label.submit}
            </AppButton>
          ) : null}
          {!medicalAssessment.iCanSubmit ? (
            <LockedAction text={translate.recovery.submitMedicalAssessment.notAuthorized} />
          ) : null}
        </View>
      </>
    ) : null;

  const content = (
    <>
      <AppModal
        mode="dialog"
        title={translate.recovery.submitMedicalAssessment.documentsSubmitted}
        onDismiss={handleDismissSubmittedDialog}
        onRequestClose={handleDismissSubmittedDialog}
        buttons={{
          done: <AppButton onPress={handleDismissSubmittedDialog}>{translate.label.done}</AppButton>,
        }}
        visible={showSubmittedDialog ? true : false}
      />
      <AppScrollView>
        <IncidentReportNoticeModal
          isModalVisible={isIncidentReportNoticeModalVisible}
          setIsModalVisible={setIsIncidentReportNoticeModalVisible}
        />
        <Spacer
          items={{
            titleArea: (
              <Spacer
                items={{
                  title: (
                    <AppText font="header1">{translate.recovery.submitMedicalAssessment.medicalAssessment}</AppText>
                  ),
                  attribution: (
                    <ProtocolAttribution
                      protocolUrl={medicalAssessment.protocolUrl}
                      protocolName={medicalAssessment.protocolName}
                    />
                  ),
                }}
              />
            ),
            rejectionReason: <RejectionReason {...medicalAssessment} />,
            instruction: <AppMarkdown>{medicalAssessment.instruction}</AppMarkdown>,
            submitContent,
          }}
        />
        {medicalAssessment.state === StageState.COMPLETE ? (
          <View style={styles.buttons}>
            <TouchableOpacity style={styles.itemGroupContainer} onPress={handleViewDocument}>
              <AppText>{translate.recovery.stageDocumentReview.document}</AppText>
              <AppText style={styles.linkButton}>{translate.label.view}</AppText>
            </TouchableOpacity>
          </View>
        ) : null}
      </AppScrollView>
    </>
  );

  return <ProfileChrome autoScroll={false} activeTab="recovery" content={content} />;
};
