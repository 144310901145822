import React from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';
import { Icon } from '../../../../../uiComponents';

export interface RemoveButtonProps extends TouchableOpacityProps {}

export const RemoveButton: React.FC<RemoveButtonProps> = (props) => (
  <TouchableOpacity {...props}>
    <Icon size={24} name="trash" />
  </TouchableOpacity>
);
