import { StageState } from '../../../../../../../types/globalTypes';
import * as React from 'react';
import { RecoveryPlanStageDocument } from '../../../fragments/__generated__/RecoveryPlanStageDocument';
import { ReviewDocument } from './ReviewDocument';
import { SubmitDocument } from './SubmitDocument';

export interface StageDocumentProps {
  stageDocument: RecoveryPlanStageDocument;
  caseCreatedDate: string;
}

export const StageDocument: React.FC<StageDocumentProps> = ({ stageDocument, caseCreatedDate }) => {
  switch (stageDocument.state) {
    case StageState.PENDING:
    case StageState.REJECTED:
    case StageState.ACTIVE:
      return <SubmitDocument stageDocument={stageDocument} caseCreatedDate={caseCreatedDate} />;
    default:
      return <ReviewDocument stageDocument={stageDocument} />;
  }
};
