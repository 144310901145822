import { Formik, FormikErrors, FormikHelpers } from 'formik';
import React from 'react';
import { Fields } from './Fields';
import { SubmitButton } from './SubmitButton';
import { useUpdateGroup } from '../hooks/useUpdateGroup';
import { UpdateGroupFormData } from './UpdateGroupModal.interface';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppModal } from '../../../../../../uiComponents';

export interface UpdateGroupModalProps {
  name: string;
  visible?: boolean;
  organizationId: string;
  groupId: string;
  onDismiss: () => void;
}

export const UpdateGroupModal: React.FC<UpdateGroupModalProps> = ({
  name,
  onDismiss,
  visible,
  organizationId,
  groupId,
}) => {
  const { translate } = useTranslations();
  const [updateGroup] = useUpdateGroup();

  const handleUpdateGroup = (input: UpdateGroupFormData, { setSubmitting }: FormikHelpers<UpdateGroupFormData>) => {
    updateGroup({
      variables: {
        groupId,
        name: input.name,
        organizationId,
      },
    }).then(
      () => {
        setSubmitting(false);
        onDismiss();
      },
      () => {
        setSubmitting(false);
        onDismiss();
      }
    );
  };
  const validate = (input: UpdateGroupFormData) => {
    const errors: FormikErrors<UpdateGroupFormData> = {};
    if (!input.name) {
      errors.name = translate.error.message.authFieldsRequired;
    }
    return errors;
  };

  return (
    <AppModal
      mode="dialog"
      title={translate.admin.manageSeasons.seasonDetails.updateGroupModal.title}
      visible={visible}
      onDismiss={onDismiss}
    >
      <Formik<UpdateGroupFormData>
        initialValues={{ name }}
        enableReinitialize={true}
        onSubmit={handleUpdateGroup}
        validate={validate}
      >
        <>
          <Fields />
          <SubmitButton />
        </>
      </Formik>
    </AppModal>
  );
};
