import { useNavigation } from '@react-navigation/native';
import { useNotification } from '../../../../hooks/ui/useNotification';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { View } from 'react-native';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { AdminNavType } from '../../Admin.interface';
import { useAdminContext } from '../../hooks/useAdminContext';
import { useCurrentProtocol } from './hooks/useCurrentProtocol';
import { useMapCurrentProtocolToFormValues } from './hooks/useMapCurrentProtocolToFormValues';
import { useMapFormValuesToUpdateProtocolConfigInput } from './hooks/useMapToFormValuesToUpdateProtocolConfigInput';
import { useProtocolConfigValidation } from './hooks/useProtocolConfigValidation';
import { useUpdateProtocolConfig } from './hooks/useUpdateProtocolConfig';
import { UpdateProtocolConfigFormValue } from './ManageProtocol.interface';
import { ActivityIndicator } from '../../../../uiComponents';
import { SaveProtocolButtons } from './SaveProtocolButtons';

export interface ManageProtocolChromeProps {
  onBack: () => void;
  children: React.ReactNode;
}
export const ManageProtocolChrome: React.FC<ManageProtocolChromeProps> = ({ onBack, children }) => {
  const { translate } = useTranslations();
  const { organizationId } = useAdminContext();
  const { data } = useCurrentProtocol({
    variables: {
      organizationId,
    },
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache', // TODO: fix once infinite loop of queries is fixed
  });
  const [updateProtocolConfig] = useUpdateProtocolConfig();
  const validate = useProtocolConfigValidation(translate);
  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_ManageProtocol'>>();
  const mapCurrentProtocolToFormValues = useMapCurrentProtocolToFormValues();
  const mapFormValuesToInput = useMapFormValuesToUpdateProtocolConfigInput();
  const notify = useNotification();
  if (data?.currentProtocolByOrganization.__typename !== 'Protocol') {
    return <Chrome mode="title-card" content={<ActivityIndicator />} />;
  }

  const currentProtocol = data.currentProtocolByOrganization;
  const initialValues = mapCurrentProtocolToFormValues(organizationId, currentProtocol);

  const handleUpdateProtocolConfig = (
    _values: UpdateProtocolConfigFormValue,
    { setSubmitting, setValues }: FormikHelpers<UpdateProtocolConfigFormValue>
  ) => {
    return updateProtocolConfig({
      variables: {
        input: mapFormValuesToInput(organizationId, _values),
      },
    }).then(({ data }) => {
      if (data && data.updateProtocolConfig.__typename === 'Protocol') {
        setSubmitting(false);

        // We manually set the values of the form after submission because
        // Using react navigation to navigate to the form post submission
        // Does not trigger a reinitialization, so some form values are stale
        setValues(mapCurrentProtocolToFormValues(organizationId, data.updateProtocolConfig));
        notify({
          image: 'header/people',
          title: translate.admin.manageProtocol.successTitle,
          description: translate.admin.manageProtocol.successDescription,
        });
        setTimeout(() =>
          navigation.navigate('LoggedIn_Admin', {
            organizationId,
            screen: 'LoggedIn_Admin_ManageProtocol',
            params: {
              screen: 'LoggedIn_Admin_ManageProtocol_Index',
            },
          })
        );
      }
    });
  };

  return (
    <Formik<UpdateProtocolConfigFormValue>
      initialValues={initialValues}
      initialErrors={validate(initialValues)}
      onSubmit={handleUpdateProtocolConfig}
      validate={validate}
      enableReinitialize={true}
    >
      <Chrome
        navBarProps={{ showHamburger: true, onBack }}
        mode="title-card"
        titleText={translate.admin.manageProtocol.title}
        content={
          <View>
            {children}
            <SaveProtocolButtons />
          </View>
        }
      />
    </Formik>
  );
};
