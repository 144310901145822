import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../LoggedIn.interface';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';

interface SuperAdminGuardProps {
  children: React.ReactNode;
}

export const SuperAdminGuard: React.FC<SuperAdminGuardProps> = (props) => {
  const { children } = props;

  const navigation = useNavigation<LoggedInNavType<'LoggedIn_SuperAdmin'>>();

  const { currentUser } = useCurrentUser();

  if (currentUser && currentUser.__typename === 'CurrentUser' && currentUser.isSuperAdmin) {
    return <>{children}</>;
  } else {
    navigation.navigate('LoggedIn_Home');
    return null;
  }
};
