import { EmergencyContact } from '../screens/Emergency/EmergencyContact';
import { AssessNavItem } from '../Assess.interface';
import { Emergency } from '../screens/Emergency/Emergency';
import { FindSubject } from '../screens/FindSubject/FindSubject';
import { RedFlags } from '../screens/RedFlags/RedFlags';
import { Step } from '../screens/Step/Step';
import { ChooseOrganizationProtocol } from '../screens/FindSubject/ChooseOrganizationProtocol';
import { Tiles } from '../screens/Index/Tiles';

const assessRoutes: AssessNavItem[] = [
  {
    component: Tiles,
    label: 'Index',
    name: 'LoggedIn_Assess_Index' as const,
    path: 'index',
  },
  {
    component: Emergency,
    label: 'Emergency',
    name: 'LoggedIn_Assess_Emergency' as const,
    path: 'emergency',
  },
  {
    component: EmergencyContact,
    label: 'Emergency',
    name: 'LoggedIn_Assess_EmergencyContact' as const,
    path: 'emergency-contact',
  },
  {
    component: ChooseOrganizationProtocol,
    label: 'Choose Organization Protocol',
    name: 'LoggedIn_Assess_ChooseOrganizationProtocol',
    path: 'choose-organization-protocol',
  },
  {
    component: RedFlags,
    label: 'Red Flags',
    name: 'LoggedIn_Assess_RedFlags' as const,
    path: 'red-flags',
  },
  {
    component: FindSubject,
    label: 'Find Athlete',
    name: 'LoggedIn_Assess_FindAthlete' as const,
    path: 'find-athlete',
  },
  {
    component: Step,
    label: 'Step',
    name: 'LoggedIn_Assess_Step' as const,
    path: 'step',
  },
];

export const useAssessRoutes = () => assessRoutes;
