import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Linking } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { AppButton, AppMarkdown, AppText } from '../../../../uiComponents';
import { AssessNavType } from '../../Assess.interface';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AssessChrome } from '../../AssessChrome';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { hasPhoneNumber } from './hasPhoneNumber';

export interface EmergencyProps {}

export const Emergency: React.FC<EmergencyProps> = (props) => {
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<AssessNavType<'LoggedIn_Assess_Emergency'>>();

  const { state, dispatch } = useCurrentAssessment();

  const { selectedProtocolOption, subject } = state;

  if (!selectedProtocolOption || !subject) {
    return <AssessChrome content={null} />;
  }

  const emergencyPhoneNumber = selectedProtocolOption.organization.emergencyPhoneNumber?.fullNumber
    ? selectedProtocolOption.organization.emergencyPhoneNumber
    : null;

  const { emergencyInstruction } = selectedProtocolOption.protocol.removeProtocol;

  const guardians = subject.guardians
    .filter((guardian) => guardian.organizationId === selectedProtocolOption.organization.id)
    .filter(hasPhoneNumber);

  const hasEmergencyContacts = guardians.length > 0;

  const emergencyInstructionArea = emergencyInstruction ? <AppMarkdown>{emergencyInstruction}</AppMarkdown> : null;

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginBottom: 15,
    },
    alternateNumber: {
      marginBottom: 20,
    },
    primaryText: {
      textAlign: 'center',
    },
    phoneNumberText: {
      marginTop: 4,
      textAlign: 'center',
    },
  });

  const handleCallEmergencyNumber = () => {
    if (emergencyPhoneNumber) {
      Linking.openURL(`tel:${emergencyPhoneNumber.fullNumber}`);
      return;
    }
  };

  const handleNextButtonPress = () => {
    if (hasEmergencyContacts) {
      navigation.navigate('LoggedIn_Assess_EmergencyContact');
    } else {
      dispatch({
        type: 'setDoneRemovalProtocol',
        payload: {
          status: true,
        },
      });
    }
  };

  const footer = (
    <View style={styles.buttonContainer}>
      {emergencyPhoneNumber ? (
        <AppButton
          style={{ marginRight: 15, marginBottom: 15 }}
          icon="phone"
          type="danger"
          onPress={handleCallEmergencyNumber}
        >
          {translateWithVars(translate.emergency.callNumber, {
            number: emergencyPhoneNumber.name || emergencyPhoneNumber.phoneNumber,
          })}
        </AppButton>
      ) : null}
      <AppButton onPress={handleNextButtonPress}>{translate.label.next}</AppButton>
    </View>
  );
  return (
    <AssessChrome
      content={
        <View style={styles.container}>
          <View>
            <AppText gutter={true} font="header2">
              {translate.emergency.title}
            </AppText>
            {emergencyInstructionArea}
          </View>
          {footer}
        </View>
      }
    />
  );
};
