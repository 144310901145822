import React from 'react';
import { AppText, Spacer } from '../../../../../uiComponents';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useCurrentUser } from '../../../../../shared/CurrentUser/useCurrentUser';
import { Footer } from './Footer';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const { translate, translateWithVars } = useTranslations();
  const { currentUser } = useCurrentUser();

  return (
    <Spacer
      size="large"
      items={{
        title: (
          <AppText gutter={true} font="header3">
            {translate.wrongUser.title}
          </AppText>
        ),
        message: (
          <AppText>
            {translateWithVars(translate.wrongUser.message, {
              currentEmail: currentUser?.email,
            })}
          </AppText>
        ),
        button: <Footer />,
      }}
    />
  );
};
