import { useReducer } from 'react';
import { RfaProtocolResult } from '../../../../types/globalTypes';
import { AssessAction, AssessContextReducer, AssessContextState } from '../../Assess.interface';
import { assessContextDefaultState } from './utils/getAssessContextDefaultState';
import { logState } from './utils/logState';
import { setSubject } from './utils/setSubject';
import { setProtocol } from './utils/setProtocol';
import { setStepValues } from './utils/setStepValues';
import { setRedFlagsViewed } from './utils/setRedFlagsViewed';
import { setRedFlags } from './utils/setRedFlags';
import { setSymptomStepViewed } from './utils/setSymptomStepViewed';

const reducer = (state: AssessContextState, action: AssessAction) => {
  let nextState: AssessContextState = { ...state };

  switch (action.type) {
    case 'reset':
      console.log('resetting state to default');
      return assessContextDefaultState;
    case 'initialize':
      nextState = {
        ...nextState,
        initialized: true,
      };
      break;
    case 'setSubject':
      nextState = setSubject(nextState, action.payload);
      break;
    case 'setProtocol':
      nextState = setProtocol(nextState, action.payload);
      break;
    case 'setIsEmergency':
      nextState = {
        ...nextState,
        emergency: true,
        suggestedResult: RfaProtocolResult.REMOVE,
        selectedResult: RfaProtocolResult.REMOVE,
      };
      break;
    case 'setIsNotEmergency':
      nextState = {
        ...nextState,
        emergency: false,
        suggestedResult: RfaProtocolResult.RESUME,
      };
      break;
    case 'setRedFlagsViewed':
      nextState = setRedFlagsViewed(nextState);
      break;
    case 'setRedFlags':
      nextState = setRedFlags(nextState, action.payload);
      break;
    case 'setSymptomStepViewed':
      nextState = setSymptomStepViewed(nextState, action.payload);
      break;
    case 'setStepValues':
      nextState = setStepValues(nextState, action.payload);
      break;
    case 'setSuggestedResult':
      nextState = {
        ...nextState,
        suggestedResult: action.payload.suggestedResult,
      };
      break;
    case 'setSelectedResult':
      nextState = {
        ...nextState,
        selectedResult: action.payload.selectedResult,
      };
      break;
    case 'setDoneRemovalProtocol':
      nextState = {
        ...nextState,
        showFinalDialog: action.payload.status,
      };
      break;
  }
  logState(nextState);
  return nextState;
};

export const useAssessReducer = (): AssessContextReducer => {
  const [state, _dispatch] = useReducer(reducer, {
    steps: [],
    redFlags: {
      viewed: false,
      values: [],
    },
    suggestedResult: RfaProtocolResult.RESUME,
    initialized: false,
    showFinalDialog: false,
  });

  const dispatch = (action: AssessAction) => {
    _dispatch(action);

    return reducer(state, action);
  };

  return { state, dispatch };
};
