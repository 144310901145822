import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppLink, AppText } from '../../../../../../uiComponents';
import { useRecoveryPlan } from '../../hooks/useRecoveryPlan';
import { RecoveryPlanNavType } from '../../RecoveryPlan.interface';
import { useGetCasePreviews } from '../../../../../Cases/hooks/useGetCasePreviews';
import { MedicalAssessmentDiagnosis } from '../../../../../../../frontend/types/globalTypes';

export interface ResumeMessageProps {}

export const ResumeMessage: React.FC<ResumeMessageProps> = (props) => {
  const data = useRecoveryPlan();
  const { translate, translateWithVars } = useTranslations();
  const navigator = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Index'>>();

  const mostRecentCase =
    data && data.cases.length
      ? data.cases.sort((a, b) => (a.closedDate ? -a.closedDate.localeCompare(b.closedDate) : 0))[0]
      : null;

  useGetCasePreviews({
    variables: {
      organizationId: mostRecentCase ? mostRecentCase.organization.id : '',
      paginationArgs: {
        skip: 0,
        orderBy: 'lastName',
        direction: 'desc',
      },
    },
  });

  if (!data) {
    return null;
  }

  const diagnosis = mostRecentCase?.items.find(
    (item) => item.__typename === 'CaseItemMedicalAssessmentReviewApproved'
  )?.diagnosis;
  const { fullName } = data;

  const handleReturnToProfile = () => navigator.navigate('LoggedIn_Profile_Overview');

  return (
    <>
      <AppText font="header3" gutter={true}>
        {translate.recovery.index.resumeMessage.title}
      </AppText>
      <AppText gutter={true}>
        {diagnosis === MedicalAssessmentDiagnosis.CONCUSSION
          ? translateWithVars(translate.recovery.index.resumeMessage.concussionInstruction, { fullName })
          : translateWithVars(translate.recovery.index.resumeMessage.noConcussionInstruction, { fullName })}
      </AppText>
      <AppLink onPress={handleReturnToProfile}>{translate.recovery.index.resumeMessage.returnLink}</AppLink>
    </>
  );
};
