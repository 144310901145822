import React from 'react';
import { AppScrollView } from '../../uiComponents';
import { CasesChrome } from './CasesChrome';
import { StyleSheet } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import { CasesRouteProp } from './Cases.interface';
import { CheckForFeatureFlag } from '../shared/CheckForFeatureFlag';
import { Role } from '../../types/globalTypes';
import { useTranslations } from '../../i18n/useTranslations';
import { CasesNavType } from './Cases.interface';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { CasesData } from './CasesData';
import { RouteGuard } from '../shared/RouteGuard';

export const Content: React.FC = () => {
  const handleSelect = (subjectId: string, caseId: string) => {
    navigation.navigate('LoggedIn_Profile', {
      screen: 'LoggedIn_Profile_Logs',
      subjectId,
      params: { caseId },
    });
  };

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 15,
    },
  });

  const { translate } = useTranslations();
  const { params } = useRoute<CasesRouteProp<'LoggedIn_Cases_CaseList'>>();

  const caseType = params.caseType;

  const navigation = useNavigation<CasesNavType<'LoggedIn_Cases_CaseList'>>();

  const handleGoBack = () => {
    navigation.navigate('LoggedIn_Cases', {
      screen: 'LoggedIn_Cases_Index',
    });
  };

  const { currentUser } = useCurrentUser();

  if (!currentUser || currentUser.__typename !== 'CurrentUser') {
    return null;
  }

  const organizationsWherePDP = currentUser.roles.filter((role) => role.role === Role.PRIMARY_DESIGNATED_PERSON);

  return (
    <RouteGuard acceptedRoles={[Role.PRIMARY_DESIGNATED_PERSON]}>
      <CasesChrome
        titleText={caseType === 'active' ? translate.cases.activeCases : translate.cases.pastCases}
        onBack={handleGoBack}
        showHamburger={true}
      >
        <AppScrollView showsVerticalScrollIndicator={false} style={styles.container}>
          {organizationsWherePDP.map((role) => (
            <CasesData
              role={role}
              key={role.organizationId}
              handleSelect={handleSelect}
              emptyListMessage={caseType === 'active' ? translate.cases.noActiveCases : translate.cases.noPastCases}
              caseType={caseType}
            />
          ))}
        </AppScrollView>
      </CasesChrome>
    </RouteGuard>
  );
};
