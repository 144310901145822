import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { useGetSeasonsByOrganizationId } from './hooks/useGetSeasonsByOrganizationId';
import { useAdminContext } from '../../hooks/useAdminContext';
import { ManageSeasonsNavType } from './ManageSeasons.interface';
import { SeasonPlaceholder } from './SeasonPlaceholder';
import { Season } from './Season';
import { AppButton, ActivityIndicator } from '../../../../uiComponents';
import { CreateSeasonModal } from './CreateSeasonModal';

export const Index: React.FC = () => {
  const { organizationId } = useAdminContext();
  const { translate } = useTranslations();
  const navigation = useNavigation<ManageSeasonsNavType<'LoggedIn_Admin_ManageSeasons_Index'>>();
  const { seasons, loading } = useGetSeasonsByOrganizationId(organizationId);
  const [createSeasonModalOpen, setCreateSeasonModalOpen] = useState(false);

  let itemsList = <SeasonPlaceholder />;

  if (seasons.length > 0) {
    const sortedSeasons = [...seasons].sort((s1, s2) => {
      const startDate1 = new Date(s1.startDate);
      const startDate2 = new Date(s2.startDate);
      return startDate2.getTime() - startDate1.getTime();
    });
    itemsList = (
      <>
        {sortedSeasons.map((season) => (
          <Season
            onPress={() =>
              navigation.navigate('LoggedIn_Admin_ManageSeasons_SeasonsDetails', {
                seasonId: season.id,
              })
            }
            key={season.id}
            id={season.id}
            label={season.name}
            startDate={season.startDate}
            endDate={season.endDate}
          />
        ))}
      </>
    );
  }

  const styles = StyleSheet.create({
    wrapper: {
      marginBottom: 30,
    },
  });

  const onBack = () => navigation.navigate('LoggedIn_Admin', { screen: 'LoggedIn_Admin_Index', organizationId });

  return (
    <Chrome
      mode="title-card"
      titleText={translate.seasons.title}
      navBarProps={{ onBack }}
      content={
        <View style={[styles.wrapper]}>
          {!loading ? itemsList : <ActivityIndicator style={{ alignItems: 'center', justifyContent: 'center' }} />}
          <AppButton style={{ marginLeft: 'auto' }} onPress={() => setCreateSeasonModalOpen(true)}>
            {translate.seasons.createSeason}
          </AppButton>
          <CreateSeasonModal
            visible={createSeasonModalOpen}
            onDismiss={() => setCreateSeasonModalOpen(false)}
            organizationId={organizationId}
          />
        </View>
      }
    />
  );
};
