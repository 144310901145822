import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ManageUsersParamList } from './ManageUsers.interface';
import { useManageUsersRoutes } from './hooks/useManageUsersRoutes';

const ManageUsersNav = createStackNavigator<ManageUsersParamList>();
const routes = useManageUsersRoutes();

export interface ManageUsersNavigatorProps {}

export const ManageUsersNavigator: React.FC<ManageUsersNavigatorProps> = () => {
  return (
    <ManageUsersNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <ManageUsersNav.Screen key={name} name={name} component={component} />
      ))}
    </ManageUsersNav.Navigator>
  );
};
