import { useCurrentUser } from '../../../shared/CurrentUser/useCurrentUser';
import React from 'react';
import { Chrome as ChromeBase, ChromeProps as ChromeBaseProps } from '../../../shared/Chrome/Chrome';
import { LoggedInUser } from '../LoggedInUser/LoggedInUser';
import { NavBar, NavBarProps } from './NavBar/NavBar';
import { Verify } from './Verify/Verify';

export type ChromeProps = ChromeBaseProps & {
  navBarProps?: NavBarProps;
};

export const Chrome: React.FC<ChromeProps> = ({ navBarProps = {}, ...props }) => {
  const { currentUser } = useCurrentUser();
  if (currentUser?.__typename === 'UnverifiedCurrentUser') {
    return (
      <ChromeBase
        mode="title-card"
        navbar={<NavBar showHamburger={true} showBackLink={false} />}
        content={<Verify user={currentUser} />}
      />
    );
  } else {
    return (
      <LoggedInUser user={currentUser}>
        <ChromeBase navbar={<NavBar {...navBarProps} />} {...props} />
      </LoggedInUser>
    );
  }
};
