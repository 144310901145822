import * as React from 'react';
import { BaseStage } from './BaseStage/BaseStage';
import { RecoveryPlanStageDocument as RecoveryPlanStageDocumentType } from '../../../fragments/__generated__/RecoveryPlanStageDocument';

export interface RecoveryPlanStageDocumentProps {
  onPress?: (id: string) => void;
  stage: RecoveryPlanStageDocumentType;
}

export const RecoveryPlanStageDocument: React.FC<RecoveryPlanStageDocumentProps> = ({ stage, onPress }) => {
  return (
    <BaseStage
      key={stage.id}
      id={stage.id}
      title={stage.name}
      organizationName={stage.organizationName}
      onPress={onPress}
      protocolType={stage.protocolType}
      state={stage.state}
    />
  );
};
