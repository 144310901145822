/* Text-based link
=============================== */

import React from 'react';
import { StyleSheet, Text, TouchableOpacity, TextStyle } from 'react-native';
import { Icon, IconName } from '../Icon/Icon';
import { useColors } from '../../shared/Ui';
import { fonts } from '../../shared/Ui/defaultStyleConfig';

interface AppLinkProps {
  onPress?: () => void;
  icon?: IconName;
  iconSize?: number;
  iconOpacity?: number;
  color?: string;
  style?: TextStyle;
  children?: string | null | undefined;
}

export const AppLink: React.FC<AppLinkProps> = ({
  children,
  onPress,
  icon,
  iconSize = 32,
  iconOpacity = 0.65,
  color = 'blue',
  style,
}) => {
  const colors = useColors();

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: style?.textAlign === 'right' ? 'flex-end' : 'flex-start',
    },
    link: {
      ...fonts.labelDefault,
      color: colors[color],
      textAlign: style?.textAlign ?? 'left',
      textDecorationLine: 'underline',
    },
    icon: {
      marginRight: 10,
      opacity: iconOpacity,
    },
  });

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      {icon ? <Icon name={icon} color={colors[color]} size={iconSize} style={styles.icon} /> : null}
      <Text style={styles.link}>{children}</Text>
    </TouchableOpacity>
  );
};
