import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { SubjectRecoveryPlanQuery, SubjectRecoveryPlanQueryVariables } from './__generated__/SubjectRecoveryPlanQuery';

export const SUBJECT_RECOVERY_PLAN = gql`
  query SubjectRecoveryPlanQuery($input: CurrentUserSubjectInput!) {
    getSubject(input: $input) {
      ...SubjectRecoveryPlan
      ... on ApiError {
        ...ApiError
      }
    }
  }
  fragment SubjectRecoveryPlan on Subject {
    id
    fullName
    recoveryPlan {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
    }
    cases {
      status
      closedDate
      items {
        ... on CaseItemMedicalAssessmentReviewApproved {
          __typename
          diagnosis
        }
      }
      organization {
        id
      }
    }
  }
  ${RecoveryPlanFragment}
  ${ApiErrorFragment}
`;

export const useSubjectRecoveryPlan = (
  options: UseQueryOptions<SubjectRecoveryPlanQuery, SubjectRecoveryPlanQueryVariables>
) => useQuery<SubjectRecoveryPlanQuery, SubjectRecoveryPlanQueryVariables>(SUBJECT_RECOVERY_PLAN, options);
