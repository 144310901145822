import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { UnverifiedCurrentUserFragment } from '../../../fragments/UnverifiedCurrentUserFragment';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { CurrentUserFragment } from '../../../fragments/CurrentUserFragment';
import { CurrentUserQuery } from './__generated__/CurrentUserQuery';

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      ... on CurrentUser {
        ...CurrentUser
      }
      ... on UnverifiedCurrentUser {
        ...UnverifiedCurrentUser
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${CurrentUserFragment}
  ${UnverifiedCurrentUserFragment}
  ${ApiErrorFragment}
`;

export const useCurrentUserLazyQuery = (options?: LazyQueryHookOptions<CurrentUserQuery>) =>
  useLazyQuery<CurrentUserQuery>(CURRENT_USER_QUERY, options);
