import { isEmpty } from 'lodash';
import { useTranslations } from '../../../../i18n/useTranslations';
import { isEmailValid } from '../../../../utils/isEmailValid';

export const validateName = (values, errors, translate) => {
  if (isEmpty(values.firstName)) {
    errors.firstName = translate.error.message.cantBeEmpty;
  }
  if (isEmpty(values.lastName)) {
    errors.lastName = translate.error.message.cantBeEmpty;
  }
};

export const validateEmail = (values, errors, translate, required = true, isSubject = false) => {
  if (required && isEmpty(values.email)) {
    errors.email = isSubject ? translate.error.message.inviteSubjectNoEmail : translate.error.message.authNoEmail;
  } else if (required && values.email && !isEmailValid(values.email)) {
    errors.email = translate.error.message.auth.invalidEmail;
  }
};
