import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import NotchSvg from '../../assets/svg/Notch.svg';
import { ColorKeys, useColors } from '../../shared/Ui';

export type NotchPosition = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

interface NotchProps {
  color?: ColorKeys;
  position?: NotchPosition;
}

export const Notch: React.FC<NotchProps> = ({ color = 'gray', position = 'top-left' }) => {
  const colors = useColors();
  const styles = StyleSheet.create({
    notch: {
      position: 'absolute',
      height: 45,
      width: 45,
    },
    bottomLeft: {
      bottom: 0,
      left: 0,
      transform: [{ rotate: '90deg' }],
    },
    bottomRight: {
      bottom: 0,
      right: 0,
      transform: [{ rotate: '0deg' }],
    },
    topLeft: {
      top: 0,
      left: 0,
      transform: [{ rotate: '180deg' }],
    },
    topRight: {
      top: 0,
      right: 0,
      transform: [{ rotate: '270deg' }],
    },
  });

  let notchPositionStyle: ViewStyle = {};

  if (position === 'bottom-left') {
    notchPositionStyle = styles.bottomLeft;
  } else if (position === 'bottom-right') {
    notchPositionStyle = styles.bottomRight;
  } else if (position === 'top-left') {
    notchPositionStyle = styles.topLeft;
  } else if (position === 'top-right') {
    notchPositionStyle = styles.topRight;
  }

  return (
    <View style={[styles.notch, notchPositionStyle]}>
      <NotchSvg height={'100%'} width={'100%'} fill={colors[color]} />
    </View>
  );
};
