import { useInitialNavigation } from '../shared/InitialNavigationRoutes';
import { Account } from './Account/Account';
import { ActionItems } from './ActionItems/ActionItems';
import { AdminNavigator } from './Admin/AdminNavigator';
import { useAdminRoutes } from './Admin/hooks/useAdminRoutes';
import { AssessNavigator } from './Assess/AssessNavigator';
import { useAssessRoutes } from './Assess/hooks/useAssessRoutes';
import { Home } from './Home/Home';
import { MyKids } from './MyKids/MyKids';
import { OnProtocol } from './OnProtocol/OnProtocol';
import { useProfileRoutes } from './Profile/hooks/useProfileRoutes';
import { ProfileNavigator } from './Profile/ProfileNavigator';
import { ReviewInvitation } from './Invitation/ReviewInvitation/ReviewInvitation';
import { useSuperAdminRoutes } from './SuperAdmin/hooks/useSuperAdminRoutes';
import { SuperAdminNavigator } from './SuperAdmin/SuperAdminNavigator';
import { TeamRoster } from './TeamRoster/TeamRoster';
import { LoggedInNavItem } from './LoggedIn.interface';
import { useTrainingRoutes } from './Training/hooks/useTrainingRoutes';
import { TrainingNavigator } from './Training/TrainingNavigator';
import { Protocol } from './Protocol/Protocol';
import { CasesNavigator } from './Cases/CasesNavigator';
import { useCasesRoutes } from './Cases/hooks/useCasesRoutes';
import { IncidentReport } from './IncidentReport/IncidentReport';

export const useLoggedInRoutes = () => {
  const {
    initialLoginRoute: { name, initialParams },
  } = useInitialNavigation();
  const assessRoutes = useAssessRoutes();
  const adminRoutes = useAdminRoutes();
  const superAdminRoutes = useSuperAdminRoutes();
  const profileRoutes = useProfileRoutes();
  const trainingRoutes = useTrainingRoutes();
  const casesRoutes = useCasesRoutes();

  const navItems: LoggedInNavItem[] = [
    {
      component: Home,
      name: 'LoggedIn_Home' as const,
      path: 'home',
    },
    {
      component: ReviewInvitation,
      name: 'LoggedIn_ReviewInvitation' as const,
      path: 'review-invitation/:invitationIdentifier',
    },
    {
      component: ProfileNavigator,
      name: 'LoggedIn_Profile' as const,
      path: 'profile/:subjectId?',
      children: profileRoutes,
    },
    {
      component: MyKids,
      name: 'LoggedIn_MyKids' as const,
      path: 'my-kids',
    },
    {
      component: Account,
      name: 'LoggedIn_Account' as const,
      path: 'account',
    },
    {
      component: TeamRoster,
      name: 'LoggedIn_TeamRoster',
      path: 'team-roster',
    },
    {
      component: OnProtocol,
      name: 'LoggedIn_OnProtocol',
      path: 'on-protocol',
    },
    {
      component: ActionItems,
      name: 'LoggedIn_ActionItems',
      path: 'action-items',
    },
    {
      component: AssessNavigator,
      label: 'Assess',
      name: 'LoggedIn_Assess' as const,
      path: 'assess',
      children: assessRoutes,
    },
    {
      component: AdminNavigator,
      label: 'Admin',
      name: 'LoggedIn_Admin' as const,
      path: 'admin/:organizationId?',
      children: adminRoutes,
    },
    {
      component: SuperAdminNavigator,
      label: 'Sales',
      name: 'LoggedIn_SuperAdmin' as const,
      path: 'super-admin/:organizationId?',
      children: superAdminRoutes,
    },
    {
      component: TrainingNavigator,
      label: 'Training',
      name: 'LoggedIn_Training' as const,
      path: 'training',
      children: trainingRoutes,
    },
    {
      component: Protocol,
      label: 'Protocol',
      name: 'LoggedIn_Protocol' as const,
      path: 'protocol/:organizationId',
    },
    {
      component: IncidentReport,
      label: 'Incident Report',
      name: 'LoggedIn_IncidentReport' as const,
      path: 'incident-report/:caseId',
    },
    {
      component: CasesNavigator,
      label: 'Cases',
      name: 'LoggedIn_Cases',
      path: 'cases',
      children: casesRoutes,
    },
  ];

  return navItems.map((val) => {
    if (val.name === name) {
      return {
        ...val,
        isInitialRoute: true,
        initialParams,
      };
    } else {
      return val;
    }
  });
};
