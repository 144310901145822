import React from 'react';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import { useTranslations } from '../../i18n/useTranslations';
import { AppLink } from '../AppLink/AppLink';
import { AppText } from '../AppText/AppText';

export interface ProtocolAttribution {
  protocolName: string | undefined | null;
  protocolUrl: string | undefined | null;
}

export const ProtocolAttribution: React.FC<ProtocolAttribution> = ({ protocolName, protocolUrl }) => {
  const { translate } = useTranslations();

  const handlePress = () => {
    if (protocolUrl) {
      Linking.openURL(protocolUrl);
    }
  };

  if (!protocolName) {
    return null;
  }
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
      <AppText style={{ marginRight: 5 }} font="labelDefault" color="grayDark">
        {translate.protocolAttribution.providedBy}
      </AppText>
      {protocolUrl ? (
        <AppLink onPress={handlePress}>{protocolName}</AppLink>
      ) : (
        <AppText font="labelDefault" color="grayDark">
          {protocolName}
        </AppText>
      )}
    </View>
  );
};
