import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../../../screens/LoggedIn.interface';
import { useCurrentUser } from '../../CurrentUser/useCurrentUser';
import { useLogout } from '../../Firebase';
import { useTranslations, languageList } from '../../../i18n/useTranslations';
import { useChangeUserLanguage } from '../../CurrentUser/useChangeUserLanguage';

type MenuType = 'language' | 'navigation';
export type MenuItem = { name: string; value: string; restrictToSuperAdmin?: boolean; type: MenuType };

export const useHamburgerMenuProps = () => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Home'>>();
  const { translate } = useTranslations();
  const { logout } = useLogout();
  const { setCurrentUser, currentUser } = useCurrentUser();

  const [changeUserLanguage] = useChangeUserLanguage({
    onCompleted: (data) => {
      if (data.changeLanguage.__typename === 'Language' && currentUser && currentUser.__typename === 'CurrentUser') {
        setCurrentUser({ ...currentUser, language: data.changeLanguage.language });
      }
    },
  });

  const handleNavigation = (value: string) => {
    const navItemLinks = {
      myAccount: () => {
        navigation.navigate('LoggedIn_Account');
      },
      sales: () => {
        navigation.navigate('LoggedIn_SuperAdmin', { screen: 'LoggedIn_SuperAdmin_Index' });
      },
      logout: () => {
        logout();
      },
    };

    return navItemLinks[value.charAt(0).toLowerCase() + value.slice(1)]();
  };

  const languageMenuItems: MenuItem[] = languageList.map((language) => ({
    name: language.nameInOwnLanguage,
    value: language.locale,
    type: 'language',
  }));

  const navigationMenuItems: MenuItem[] = [
    {
      name: translate.navigation.myAccount,
      value: 'myAccount',
      restrictToSuperAdmin: false,
      type: 'navigation',
    },
    {
      name: translate.navigation.superAdmin,
      value: 'sales',
      restrictToSuperAdmin: true,
      type: 'navigation',
    },
    {
      name: translate.wrongUser.logout,
      value: 'logout',
      restrictToSuperAdmin: false,
      type: 'navigation',
    },
  ];

  return { changeUserLanguage, handleNavigation, languageMenuItems, navigationMenuItems };
};
