import React from 'react';
import { Content } from './Content';
import { AdminChrome } from './AdminChrome';
import { useTranslations } from '../../i18n/useTranslations';

export const Index = () => {
  const { translate } = useTranslations();

  return <AdminChrome titleText={translate.subjectList.organizations} content={<Content />} />;
};
