import { Chrome } from '../shared/Chrome/Chrome';
import React from 'react';
import { Content } from './Content';
import { LoggedInNavType } from '../LoggedIn.interface';
import { useNavigation } from '@react-navigation/native';
import { useTranslations } from '../../i18n/useTranslations';
import { RouteGuard } from '../shared/RouteGuard';
import { Role } from '../../types/globalTypes';

export interface MyKidsProps {}

export const MyKids: React.FC<MyKidsProps> = (props) => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_MyKids'>>();
  const { translate } = useTranslations();

  const onBack = () => navigation.navigate('LoggedIn_Home');

  return (
    <RouteGuard acceptedRoles={[Role.GUARDIAN]}>
      <Chrome
        navBarProps={{ onBack }}
        mode="title-card"
        autoScroll={false}
        titleText={translate.home.tile.myKids}
        content={<Content />}
      />
    </RouteGuard>
  );
};
