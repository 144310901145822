import React from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../LoggedIn.interface';
import { SubjectForSubjectsList } from '../shared/SubjectList/hooks/__generated__/SubjectForSubjectsList';
import { SubjectData } from '../TeamRoster/SubjectData';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { Role } from '../../types/globalTypes';
import { useTranslations } from '../../i18n/useTranslations';
import { SubjectStatus } from '../../types/globalTypes';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_TeamRoster'>>();
  const { currentUser } = useCurrentUser();
  const { translate } = useTranslations();

  const handleSelect = ({ id }: SubjectForSubjectsList) =>
    navigation.navigate('LoggedIn_Profile', {
      screen: 'LoggedIn_Profile_Overview',
      subjectId: id,
      params: {
        prevScreen: 'LoggedIn_OnProtocol',
      },
    });

  if (!currentUser || currentUser.__typename !== 'CurrentUser') {
    return null;
  }

  const organizationsWhereDPorGuardian = currentUser.roles.filter(
    (role) =>
      role.role === Role.PRIMARY_DESIGNATED_PERSON ||
      role.role === Role.SECONDARY_DESIGNATED_PERSON ||
      role.role === Role.GUARDIAN
  );

  const dedupedOrganizationsWhereDPorGuardian = organizationsWhereDPorGuardian.filter(
    (role) =>
      !(
        (role.role === Role.SECONDARY_DESIGNATED_PERSON || role.role === Role.GUARDIAN) &&
        organizationsWhereDPorGuardian.find(
          (otherRole) =>
            otherRole.organizationId === role.organizationId && otherRole.role === Role.PRIMARY_DESIGNATED_PERSON
        )
      )
  );

  return (
    <View>
      {dedupedOrganizationsWhereDPorGuardian.map((role) => (
        <SubjectData
          role={role}
          key={role.organizationId}
          handleSelect={handleSelect}
          showStatusDropdown={false}
          filters={{ statuses: [SubjectStatus.ON_PROTOCOL, SubjectStatus.REMOVED] }}
          emptyListMessage={translate.subjectList.subjectData.noSubjectsOnProtocol}
        />
      ))}
    </View>
  );
};
