import Constants from 'expo-constants';
import { Platform } from 'react-native';
// In development, localhost does not necessarily refer to the host machine that is running the web server
// To load images from the host machine's development server rewrite to use the expo hostUri

const patchUri = (uri: string) => {
  if (Constants.expoConfig?.extra?.env === 'development' && Platform.OS !== 'web' && Constants.platform) {
    const url = new URL(uri);
    url.hostname = Constants.platform.hostUri || '127.0.0.1';
    return url.toString();
  }
  return uri;
};

export const useHostUriPatch = () => patchUri;
