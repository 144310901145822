import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import {
  NudgePendingUserRoleInvitation,
  NudgePendingUserRoleInvitationVariables,
} from './__generated__/NudgePendingUserRoleInvitation';

export const NUDGE_USER_ROLE_INVITATION_MUTATION = gql`
  mutation NudgePendingUserRoleInvitation($input: NudgePendingUserRoleInvitationInput!) {
    nudgePendingUserRoleInvitation(input: $input) {
      ... on NudgedParticipants {
        id
        total
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useNudgePendingUserRoleInvitation = (
  options?: MutationHookOptions<NudgePendingUserRoleInvitation, NudgePendingUserRoleInvitationVariables>
) =>
  useMutation<NudgePendingUserRoleInvitation, NudgePendingUserRoleInvitationVariables>(
    NUDGE_USER_ROLE_INVITATION_MUTATION,
    options
  );
