import { MedicalAssessment as MedicalAssessmentType } from '../../../fragments/__generated__/MedicalAssessment';
import * as React from 'react';
import { StageState } from '../../../../../../../types/globalTypes';
import { SubmitMedicalAssessment } from './SubmitMedicalAssessment';
import { ReviewMedicalAssessment } from './ReviewMedicalAssessment';

export interface MedicalAssessmentProps {
  medicalAssessment: MedicalAssessmentType;
  caseCreatedDate: string;
}

export const MedicalAssessment: React.FC<MedicalAssessmentProps> = ({ medicalAssessment, caseCreatedDate }) => {
  switch (medicalAssessment.state) {
    case StageState.PENDING:
    case StageState.REJECTED:
    case StageState.ACTIVE:
      return <SubmitMedicalAssessment medicalAssessment={medicalAssessment} caseCreatedDate={caseCreatedDate} />;
    case StageState.AWAITING_REVIEW:
      return <ReviewMedicalAssessment medicalAssessment={medicalAssessment} />;
    case StageState.COMPLETE:
      return <SubmitMedicalAssessment medicalAssessment={medicalAssessment} canSubmit={false} isComplete={true}/>;
    default:
      return <SubmitMedicalAssessment medicalAssessment={medicalAssessment} canSubmit={false} />;
  }
};
