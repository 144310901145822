import React from 'react';
import { useNavigation } from '@react-navigation/core';
import { useRoute } from '@react-navigation/native';
import { AssessNavType, AssessRouteProp } from '../../Assess.interface';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { StepInformation, StepInformationProps } from './StepInformation';
import { StepSymptoms, StepSymptomsProps } from './StepSymptoms';
import { StepChallenge, StepChallengeProps } from './StepChallenge';
import { first, flatMap } from 'lodash/fp';
import { AssessProtocol_removeProtocol_steps } from '../FindSubject/__generated__/AssessProtocol';

export interface StepProps {}

interface NormalizedSymptomStep extends Omit<StepSymptomsProps, 'onNext'> {
  type: 'symptom';
  challengeId: undefined;
}
interface NormalizedInformationStep extends Omit<StepInformationProps, 'onNext'> {
  type: 'information';
  challengeId: undefined;
}
interface NormalizedChallengeStep extends Omit<StepChallengeProps, 'onNext'> {
  type: 'challenge';
}

export const Step: React.FC<StepProps> = (props) => {
  const navigation = useNavigation<AssessNavType<'LoggedIn_Assess_Step'>>();
  const route = useRoute<AssessRouteProp<'LoggedIn_Assess_Step'>>();
  const { state, dispatch } = useCurrentAssessment();

  const steps = state.selectedProtocolOption?.protocol.removeProtocol.steps || [];

  const sortByOrder = ({ order: a }, { order: b }) => (a < b ? -1 : 1);

  const normalizedSteps = flatMap<
    AssessProtocol_removeProtocol_steps,
    NormalizedSymptomStep | NormalizedChallengeStep | NormalizedInformationStep
  >((step) => {
    if (step.__typename === 'StepSymptom') {
      const value: NormalizedSymptomStep[] = [
        {
          type: 'symptom',
          stepId: step.id,
          instruction: step.instruction,
          name: step.name,
          symptoms: step.symptoms,
          challengeId: undefined,
        },
      ];
      return value;
    } else if (step.__typename === 'StepChallenge') {
      const value: NormalizedChallengeStep[] = step.challenges.sort(sortByOrder).map((challenge) => ({
        type: 'challenge',
        stepId: step.id,
        challengeId: challenge.id,
        instruction: step.instruction,
        name: step.name,
        challenges: step.challenges,
      }));
      return value;
    } else {
      const value: NormalizedInformationStep[] = [
        {
          type: 'information',
          stepId: step.id,
          instruction: step.instruction,
          name: step.name,
          challengeId: undefined,
        },
      ];
      return value;
    }
  }, steps.sort(sortByOrder));

  const { stepId, challengeId } = route.params || first(normalizedSteps) || {};

  const currentStepIndex = normalizedSteps.findIndex((val) => {
    const currentChallengeId = val.challengeId;
    const currentStepId = val.stepId;

    return currentChallengeId === challengeId && currentStepId === stepId;
  });
  const currentStep = normalizedSteps[currentStepIndex];
  const isLastStep = currentStepIndex >= normalizedSteps.length;
  const nextStep = !isLastStep ? normalizedSteps[currentStepIndex + 1] : null;

  const handleNext = () => {
    if (nextStep) {
      navigation.push('LoggedIn_Assess_Step', {
        stepId: nextStep.stepId,
        challengeId: nextStep.challengeId,
      });
    } else {
      dispatch({
        type: 'setDoneRemovalProtocol',
        payload: {
          status: true,
        },
      });
    }
  };

  let content: React.ReactNode = null;

  if (currentStep.type === 'symptom') {
    const { type, ...symptomProps } = currentStep;
    content = <StepSymptoms {...symptomProps} onNext={handleNext} />;
  } else if (currentStep.type === 'challenge') {
    const { type, ...challengeProps } = currentStep;
    content = <StepChallenge {...challengeProps} onNext={handleNext} />;
  } else if (currentStep.type === 'information') {
    const { type, ...informationProps } = currentStep;

    content = <StepInformation {...informationProps} onNext={handleNext} />;
  }

  return content;
};
