export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** Upload custom scalar type */
  Upload: any;
};

/** Does an account already exist for this email? If so the user must login. If not they must register. */
export enum AcceptInvitationAction {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
}

export type AcceptInvitationInput = {
  educationPoliciesAccepted: Array<Scalars['String']>;
  identifier: Scalars['String'];
  termsAndConditionsAccepted: Scalars['Boolean'];
};

export type AcceptInvitationResult = AcceptedInvitation | ApiError;

export type AcceptedInvitation = {
  __typename: 'AcceptedInvitation';
  accepted: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type ActionItem =
  | EducationItemResubmissionActionItem
  | IncompleteIncidentReportActionItem
  | PendingDocumentSubmissionReviewActionItem
  | PendingInvitationActionItem
  | PendingMedicalAssessmentReviewActionItem
  | ProtocolApprovalActionItem;

export type ActionItemSubject = {
  __typename: 'ActionItemSubject';
  fullName: Scalars['String'];
  id: Scalars['ID'];
};

export type AdminCreationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AdminSubject = BaseSubject & {
  __typename: 'AdminSubject';
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  status: SubjectStatus;
};

export type ApiError = ApiErrorInterface & {
  __typename: 'ApiError';
  code: ErrorCode;
};

export type ApiErrorInterface = {
  code: ErrorCode;
};

export type AppSettings = {
  __typename: 'AppSettings';
  countries: Array<Country>;
  country: CountryResult;
  defaultCountry: Country;
  defaultEmergencyNumber: PhoneNumber;
  id: Scalars['ID'];
  privacyPolicy: PrivacyPolicyResult;
  states: Array<State>;
};

export type AppSettingscountryArgs = {
  isoCode: CountryIsoCode;
};

export type Association = {
  __typename: 'Association';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Whether a role is authorized to change the advance the return protocol stage */
export enum AuthorizedRole {
  ANYONE = 'ANYONE',
  DESIGNATED_PERSON = 'DESIGNATED_PERSON',
}

export type BaseCaseItem = {
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type BaseEducationItem = {
  id: Scalars['ID'];
  instruction: Scalars['String'];
  instructionMarkdown?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  useForDesignatedPerson?: Maybe<Scalars['Boolean']>;
  useForGuardian?: Maybe<Scalars['Boolean']>;
  useForSubject?: Maybe<Scalars['Boolean']>;
};

export type BaseOrganizationUser = {
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  groups?: Maybe<Array<GroupUser>>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  memberships?: Maybe<Array<OrganizationUserRole>>;
};

export type BaseRecoveryStage = {
  caseId: Scalars['ID'];
  iCanStepBackward?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isCompletedStage?: Maybe<Scalars['Boolean']>;
  isCurrentStage?: Maybe<Scalars['Boolean']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  isLastStage?: Maybe<Scalars['Boolean']>;
  isUpcomingStage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  organizationName: Scalars['String'];
  protocolName: Scalars['String'];
  protocolUrl?: Maybe<Scalars['String']>;
  state: StageState;
};

export type BaseReturnProtocolStage = {
  authorizedRole: AuthorizedRole;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type BaseStep = {
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type BaseStepReport = {
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type BaseSubject = {
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type BasicOrganization = {
  __typename: 'BasicOrganization';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Case = {
  __typename: 'Case';
  closedDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  incidentReportConfig?: Maybe<IncidentReportForm>;
  items?: Maybe<Array<CaseItem>>;
  organization: Organization;
  protocolId: Scalars['String'];
  status: CaseStatus;
  subject?: Maybe<User>;
};

export type CaseItem =
  | CaseItemCaseStatusChange
  | CaseItemMedicalAssessmentReviewApproved
  | CaseItemMedicalAssessmentReviewRejected
  | CaseItemMedicalAssessmentSubmission
  | CaseItemRfaProtocolResult
  | CaseItemRtaProtocolCompletion
  | CaseItemRtaProtocolStageChange
  | CaseItemRtaProtocolStageDocumentReviewApproved
  | CaseItemRtaProtocolStageDocumentReviewRejected
  | CaseItemRtaProtocolStageDocumentSubmission
  | CaseItemSubjectStatusChange;

export type CaseItemActor = {
  __typename: 'CaseItemActor';
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  userImageThumbFileUrl?: Maybe<Scalars['String']>;
};

export type CaseItemCaseStatusChange = BaseCaseItem & {
  __typename: 'CaseItemCaseStatusChange';
  actor: CaseItemActor;
  caseId: Scalars['String'];
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  newStatus: CaseStatus;
};

export type CaseItemMedicalAssessmentReviewApproved = BaseCaseItem & {
  __typename: 'CaseItemMedicalAssessmentReviewApproved';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  diagnosis: MedicalAssessmentDiagnosis;
  document: Document;
  id: Scalars['ID'];
};

export type CaseItemMedicalAssessmentReviewRejected = BaseCaseItem & {
  __typename: 'CaseItemMedicalAssessmentReviewRejected';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  diagnosis: MedicalAssessmentDiagnosis;
  document: Document;
  id: Scalars['ID'];
  rejectionReason?: Maybe<Scalars['String']>;
};

export type CaseItemMedicalAssessmentSubmission = BaseCaseItem & {
  __typename: 'CaseItemMedicalAssessmentSubmission';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  diagnosis: MedicalAssessmentDiagnosis;
  document: Document;
  id: Scalars['ID'];
};

export type CaseItemRfaProtocolResult = BaseCaseItem & {
  __typename: 'CaseItemRfaProtocolResult';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  document?: Maybe<Document>;
  hasMedicalConditions?: Maybe<RadioButtonResponseEnum>;
  hasMedicalConditionsDetails?: Maybe<Scalars['String']>;
  hasMedication?: Maybe<RadioButtonResponseEnum>;
  hasMedicationDetails?: Maybe<Scalars['String']>;
  hasOtherInjuries?: Maybe<RadioButtonResponseEnum>;
  hasOtherInjuriesDetails?: Maybe<Scalars['String']>;
  hasOtherSymptoms?: Maybe<RadioButtonResponseEnum>;
  hasOtherSymptomsDetails?: Maybe<Scalars['String']>;
  hasPreviousConcussion?: Maybe<RadioButtonResponseEnum>;
  hasPreviousConcussionDetails?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  incidentDate?: Maybe<Scalars['DateTime']>;
  incidentDescription?: Maybe<Scalars['String']>;
  isSkippedAssessment: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  redFlag: RedFlagReport;
  result: RfaProtocolResult;
  steps: Array<StepReport>;
  suggestedResult: RfaProtocolResult;
  type?: Maybe<IncidentTypeEnum>;
  typeDetails?: Maybe<Scalars['String']>;
};

export type CaseItemRtaProtocolCompletion = BaseCaseItem & {
  __typename: 'CaseItemRtaProtocolCompletion';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isFastTracked?: Maybe<Scalars['Boolean']>;
  protocolType: RtaProtocolType;
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type CaseItemRtaProtocolStageChange = BaseCaseItem & {
  __typename: 'CaseItemRtaProtocolStageChange';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  direction: ProtocolStageChangeDirection;
  id: Scalars['ID'];
  isFastTracked?: Maybe<Scalars['Boolean']>;
  newStageName: Scalars['String'];
  protocolType: RtaProtocolType;
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type CaseItemRtaProtocolStageDocumentReviewApproved = BaseCaseItem & {
  __typename: 'CaseItemRtaProtocolStageDocumentReviewApproved';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  document: Document;
  id: Scalars['ID'];
  protocolType: RtaProtocolType;
  stageName: Scalars['String'];
};

export type CaseItemRtaProtocolStageDocumentReviewRejected = BaseCaseItem & {
  __typename: 'CaseItemRtaProtocolStageDocumentReviewRejected';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  document: Document;
  id: Scalars['ID'];
  protocolType: RtaProtocolType;
  rejectionReason?: Maybe<Scalars['String']>;
  stageName: Scalars['String'];
};

export type CaseItemRtaProtocolStageDocumentSubmission = BaseCaseItem & {
  __typename: 'CaseItemRtaProtocolStageDocumentSubmission';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  document: Document;
  id: Scalars['ID'];
  isFastTracked?: Maybe<Scalars['Boolean']>;
  protocolType: RtaProtocolType;
  stageName: Scalars['String'];
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type CaseItemSubjectStatusChange = BaseCaseItem & {
  __typename: 'CaseItemSubjectStatusChange';
  actor: CaseItemActor;
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  newStatus: SubjectStatus;
};

export type CasePreview = {
  __typename: 'CasePreview';
  caseStatus: CaseStatus;
  closedDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  subjectId: Scalars['String'];
  subjectName: Scalars['String'];
};

/** Whether a case is open or closed */
export enum CaseStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export type Challenge = {
  __typename: 'Challenge';
  id: Scalars['ID'];
  key: Scalars['String'];
  order: Scalars['Float'];
  value: Scalars['String'];
};

export type ChallengeReport = {
  __typename: 'ChallengeReport';
  id: Scalars['ID'];
  key: Scalars['String'];
  order: Scalars['Float'];
  result?: Maybe<ChallengeResult>;
  value: Scalars['String'];
};

/** Whether a challenge was answered or correctly or incorrectly on a subject */
export enum ChallengeResult {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
}

export type ChallengeResultSetInput = {
  challengeId: Scalars['ID'];
  value?: InputMaybe<ChallengeResult>;
};

export type ChangeLanguageResult = ApiError | Language;

export type ChildReturnProtocol = {
  __typename: 'ChildReturnProtocol';
  id: Scalars['ID'];
  stages: Array<ReturnProtocolStageConfig>;
  type: RtaProtocolType;
};

export type CircleOfCare = {
  __typename: 'CircleOfCare';
  guardians?: Maybe<Array<SubjectGuardian>>;
  id: Scalars['ID'];
  primaryDesignatedPersons: Array<PrimaryDesignatedPerson>;
  secondaryDesignatedPersons: Array<SecondaryDesignatedPerson>;
};

export type CircleOfCareResult = ApiError | CircleOfCare;

export type Country = {
  __typename: 'Country';
  abbreviation: Scalars['String'];
  dialCode?: Maybe<Scalars['String']>;
  /** Country code as per ISO 3166-1 alpha-2 */
  isoCode: CountryIsoCode;
  name: Scalars['String'];
  states?: Maybe<Array<State>>;
};

/** Country codes according to ISO 3166 standard */
export enum CountryIsoCode {
  CA = 'CA',
  US = 'US',
}

export type CountryNotFoundError = ApiErrorInterface & {
  __typename: 'CountryNotFoundError';
  code: ErrorCode;
};

export type CountryResult = Country | CountryNotFoundError;

export type CreateGroupResult = ApiError | ExistingGroup | Group;

export type CreateOrganizationInput = {
  admin?: InputMaybe<AdminCreationInput>;
  associationIds: Array<Scalars['String']>;
  isPrimaryDesignatedPerson?: InputMaybe<Scalars['Boolean']>;
  language: UserLanguage;
  name: Scalars['String'];
  preset: OrganizationProtocolPreset;
  sport: OrganizationSport;
  type: OrganizationType;
};

export type CreateUserTrainingInput = {
  completed: Scalars['Boolean'];
  nccpNumber: Scalars['String'];
};

export type CreateUserTrainingResult = ApiError | UserTrainingResult;

export type CurrentUser = {
  __typename: 'CurrentUser';
  accountId: Scalars['ID'];
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cases: Array<Case>;
  children: Array<RelatedUser>;
  educationItems: Array<OrganizationEducationItemListResult>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  guardians: Array<RelatedUser>;
  id: Scalars['ID'];
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  language: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<PhoneNumber>;
  recoveryPlan?: Maybe<RecoveryPlan>;
  roles: Array<RoleEntry>;
  status: OverallSubjectStatus;
  statuses: Array<OrganizationSubjectStatus>;
  userImage?: Maybe<Image>;
};

export type CurrentUsercaseArgs = {
  input: SubjectCaseInput;
};

export type CurrentUserResult = ApiError | CurrentUser | UnverifiedCurrentUser;

export type CurrentUserSubjectInput = {
  subjectId: Scalars['ID'];
};

export type DPTrainingCompletion = {
  __typename: 'DPTrainingCompletion';
  numberOfDPsCompleted: Scalars['Float'];
  totalNumberOfDPs: Scalars['Float'];
};

export type DPTrainingCompletionResult = ApiError | DPTrainingCompletion;

export type DeactivateGroupResult = ApiError | DeactivatedGroup;

export type DeactivateUserResult = {
  __typename: 'DeactivateUserResult';
  success: Scalars['Boolean'];
};

export type DeactivatedGroup = {
  __typename: 'DeactivatedGroup';
  active: Scalars['Boolean'];
  creationDate: Scalars['DateTime'];
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

/** The sort direction */
export enum Direction {
  asc = 'asc',
  desc = 'desc',
}

export type Document = {
  __typename: 'Document';
  createdDate: Scalars['DateTime'];
  encoding?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mimetype?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type DocumenturiArgs = {
  input?: InputMaybe<DocumentInput>;
};

export type DocumentInput = {
  download: Scalars['Boolean'];
};

/** Whether a reviewed document (medical assessment, or document submission step of an RFA protocol was rejected or approved by the primary designated person? */
export enum DocumentReviewResult {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type DocumentSubmissionStage = BaseReturnProtocolStage & {
  __typename: 'DocumentSubmissionStage';
  authorizedRole: AuthorizedRole;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  templateDocument?: Maybe<Document>;
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type EditGroupUserResult = ApiError | GroupUserInfo;

export type EducationItem = {
  __typename: 'EducationItem';
  id: Scalars['ID'];
  link: Scalars['String'];
  name: Scalars['String'];
};

export type EducationItemResubmissionActionItem = {
  __typename: 'EducationItemResubmissionActionItem';
  educationItemIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  organizationName: Scalars['String'];
};

export type EducationProtocol = {
  __typename: 'EducationProtocol';
  id: Scalars['ID'];
  items: Array<EducationProtocolItem>;
};

/** The type of education protocol item */
export type EducationProtocolItem = EducationProtocolItemDocument | EducationProtocolItemUrl;

export type EducationProtocolItemDocument = BaseEducationItem & {
  __typename: 'EducationProtocolItemDocument';
  document: Document;
  id: Scalars['ID'];
  instruction: Scalars['String'];
  instructionMarkdown: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  useForDesignatedPerson?: Maybe<Scalars['Boolean']>;
  useForGuardian?: Maybe<Scalars['Boolean']>;
  useForSubject?: Maybe<Scalars['Boolean']>;
};

export type EducationProtocolItemUrl = BaseEducationItem & {
  __typename: 'EducationProtocolItemUrl';
  id: Scalars['ID'];
  instruction: Scalars['String'];
  instructionMarkdown: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  url: Scalars['String'];
  useForDesignatedPerson?: Maybe<Scalars['Boolean']>;
  useForGuardian?: Maybe<Scalars['Boolean']>;
  useForSubject?: Maybe<Scalars['Boolean']>;
};

/** Error Code */
export enum ErrorCode {
  ADMIN_DOES_NOT_BELONG_IN_ORGANIZATION = 'ADMIN_DOES_NOT_BELONG_IN_ORGANIZATION',
  CANNOT_CREATE_A_NONSUBJECT_WITH_NO_EMAIL = 'CANNOT_CREATE_A_NONSUBJECT_WITH_NO_EMAIL',
  CANNOT_REVIEW_BECAUSE_DOCUMENT_CHANGED = 'CANNOT_REVIEW_BECAUSE_DOCUMENT_CHANGED',
  CANNOT_STEP_BACKWARD_FROM_FIRST_STAGE = 'CANNOT_STEP_BACKWARD_FROM_FIRST_STAGE',
  CANNOT_STEP_BACKWARD_FROM_INACTIVE = 'CANNOT_STEP_BACKWARD_FROM_INACTIVE',
  CASE_NOT_FOUND = 'CASE_NOT_FOUND',
  COULD_NOT_ADD_USER_TO_GROUP = 'COULD_NOT_ADD_USER_TO_GROUP',
  COULD_NOT_CHANGE_USER_LANGUAGE = 'COULD_NOT_CHANGE_USER_LANGUAGE',
  COULD_NOT_CHECK_IF_DESIGNATED_PERSON_COMPLETED_EDUCATION_ITEMS = 'COULD_NOT_CHECK_IF_DESIGNATED_PERSON_COMPLETED_EDUCATION_ITEMS',
  COULD_NOT_CREATE_A_USER_WITHOUT_EMAIL = 'COULD_NOT_CREATE_A_USER_WITHOUT_EMAIL',
  COULD_NOT_CREATE_DESIGNATED_PERSON_INVITATION = 'COULD_NOT_CREATE_DESIGNATED_PERSON_INVITATION',
  COULD_NOT_CREATE_GROUP = 'COULD_NOT_CREATE_GROUP',
  COULD_NOT_CREATE_IMPORT = 'COULD_NOT_CREATE_IMPORT',
  COULD_NOT_CREATE_INVITATION = 'COULD_NOT_CREATE_INVITATION',
  COULD_NOT_CREATE_MEDICAL_ASSESSMENT_SUBMISSION = 'COULD_NOT_CREATE_MEDICAL_ASSESSMENT_SUBMISSION',
  COULD_NOT_CREATE_ORGANIZATION = 'COULD_NOT_CREATE_ORGANIZATION',
  COULD_NOT_CREATE_SEASON = 'COULD_NOT_CREATE_SEASON',
  COULD_NOT_CREATE_SUBJECT_INVITATION = 'COULD_NOT_CREATE_SUBJECT_INVITATION',
  COULD_NOT_DEACTIVATE_USERS_IN_GROUP = 'COULD_NOT_DEACTIVATE_USERS_IN_GROUP',
  COULD_NOT_DELETE_SEASON = 'COULD_NOT_DELETE_SEASON',
  COULD_NOT_DOWNLOAD_CSV_TEMPLATES = 'COULD_NOT_DOWNLOAD_CSV_TEMPLATES',
  COULD_NOT_GET_CIRCLE_OF_CARE_FOR_PROFILE = 'COULD_NOT_GET_CIRCLE_OF_CARE_FOR_PROFILE',
  COULD_NOT_GET_GUARDIANS = 'COULD_NOT_GET_GUARDIANS',
  COULD_NOT_GET_ORGANIZATION_STATUSES = 'COULD_NOT_GET_ORGANIZATION_STATUSES',
  COULD_NOT_GET_PAST_UPLOADS = 'COULD_NOT_GET_PAST_UPLOADS',
  COULD_NOT_GET_TRAINING_REPORTS = 'COULD_NOT_GET_TRAINING_REPORTS',
  COULD_NOT_GET_USER_TRAINING_STATUS = 'COULD_NOT_GET_USER_TRAINING_STATUS',
  COULD_NOT_NUDGE_DESIGNATED_PERSON = 'COULD_NOT_NUDGE_DESIGNATED_PERSON',
  COULD_NOT_NUDGE_SUBJECT = 'COULD_NOT_NUDGE_SUBJECT',
  COULD_NOT_REACTIVATE_GROUP = 'COULD_NOT_REACTIVATE_GROUP',
  COULD_NOT_REGISTER_USER = 'COULD_NOT_REGISTER_USER',
  COULD_NOT_RESEND_EDUCATION_ITEMS = 'COULD_NOT_RESEND_EDUCATION_ITEMS',
  COULD_NOT_RETURN_DEACTIVATED_GROUP = 'COULD_NOT_RETURN_DEACTIVATED_GROUP',
  COULD_NOT_RETURN_UPDATED_USER = 'COULD_NOT_RETURN_UPDATED_USER',
  COULD_NOT_SEND_NOTIFICATIONS = 'COULD_NOT_SEND_NOTIFICATIONS',
  COULD_NOT_UPDATE_GROUP = 'COULD_NOT_UPDATE_GROUP',
  COULD_NOT_UPDATE_ORGANIZATION = 'COULD_NOT_UPDATE_ORGANIZATION',
  COULD_NOT_UPDATE_SEASON = 'COULD_NOT_UPDATE_SEASON',
  COULD_NOT_UPDATE_TRAINING = 'COULD_NOT_UPDATE_TRAINING',
  COUNTRY_NOT_FOUND = 'COUNTRY_NOT_FOUND',
  CURRENT_USER_CANNOT_REMOVE_THEMSELF = 'CURRENT_USER_CANNOT_REMOVE_THEMSELF',
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  GROUP_NOT_FOUND = 'GROUP_NOT_FOUND',
  INCORRECT_HEADERS = 'INCORRECT_HEADERS',
  INVITATION_IS_FOR_ANOTHER_USER = 'INVITATION_IS_FOR_ANOTHER_USER',
  INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
  NOT_ALL_EDUCATION_ITEMS_WERE_ACCEPTED = 'NOT_ALL_EDUCATION_ITEMS_WERE_ACCEPTED',
  NO_EMERGENCY_NUMBERS_FOR_COUNTRY = 'NO_EMERGENCY_NUMBERS_FOR_COUNTRY',
  NO_PRIVACY_POLICY_FOUND = 'NO_PRIVACY_POLICY_FOUND',
  NO_USERS_LEFT_WITH_ROLE = 'NO_USERS_LEFT_WITH_ROLE',
  ORGANIZATION_HAS_NO_ACTIVE_SEASON = 'ORGANIZATION_HAS_NO_ACTIVE_SEASON',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  PAID_OR_TRIAL_ORGANIZATIONS_MUST_HAVE_AN_EXPIRY_DATE = 'PAID_OR_TRIAL_ORGANIZATIONS_MUST_HAVE_AN_EXPIRY_DATE',
  PASSWORD_RESET_INVALID_EMAIL = 'PASSWORD_RESET_INVALID_EMAIL',
  PASSWORD_RESET_USER_NOT_FOUND = 'PASSWORD_RESET_USER_NOT_FOUND',
  PRESET_INVALID_FOR_ORGANIZATION_TYPE = 'PRESET_INVALID_FOR_ORGANIZATION_TYPE',
  PROTOCOL_NOT_FOUND = 'PROTOCOL_NOT_FOUND',
  SEASON_DATES_NOT_VALID = 'SEASON_DATES_NOT_VALID',
  SEASON_NOT_FOUND = 'SEASON_NOT_FOUND',
  SUBJECT_NOT_FOUND = 'SUBJECT_NOT_FOUND',
  UNABLE_TO_ACCEPT_INVITATION = 'UNABLE_TO_ACCEPT_INVITATION',
  UNABLE_TO_SEND_INVITATION = 'UNABLE_TO_SEND_INVITATION',
  UNKNOWN_IMPORT_TYPE = 'UNKNOWN_IMPORT_TYPE',
  USER_ALREADY_EXISTS_IN_ORGANIZATION = 'USER_ALREADY_EXISTS_IN_ORGANIZATION',
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  USER_DOESNT_BELONG_TO_ANY_ORGANIZATION = 'USER_DOESNT_BELONG_TO_ANY_ORGANIZATION',
  USER_DOES_NOT_HAVE_FEATURE_FLAG = 'USER_DOES_NOT_HAVE_FEATURE_FLAG',
  USER_EMAIL_NOT_SET = 'USER_EMAIL_NOT_SET',
  USER_HAS_NOT_YET_BEEN_INVITED_TO_SEASON = 'USER_HAS_NOT_YET_BEEN_INVITED_TO_SEASON',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_FOUND_IN_GROUP = 'USER_NOT_FOUND_IN_GROUP',
  USER_NOT_FOUND_IN_ORGANIZATION = 'USER_NOT_FOUND_IN_ORGANIZATION',
  USER_NOT_FOUND_IN_ORGANIZATION_WITH_ROLE = 'USER_NOT_FOUND_IN_ORGANIZATION_WITH_ROLE',
}

export type ExistingGroup = {
  __typename: 'ExistingGroup';
  active: Scalars['Boolean'];
  name: Scalars['String'];
};

/** App feature flags */
export enum FeatureFlag {
  ENABLE_CSV_IMPORTER = 'ENABLE_CSV_IMPORTER',
  ENABLE_MANAGE_PROTOCOL = 'ENABLE_MANAGE_PROTOCOL',
}

export type FileIdOrUploadInput = {
  id?: InputMaybe<Scalars['String']>;
  upload?: InputMaybe<NamedFileInput>;
};

export type FoundInvitation = {
  __typename: 'FoundInvitation';
  foundInvitation: UserRoleInvitation;
  id: Scalars['ID'];
};

export type GetSubjectGuardiansResult = ApiError | SubjectGuardians;

export type GetTrainingReportResult = {
  __typename: 'GetTrainingReportResult';
  TrainingReportResult: Array<ReportResult>;
};

export type GetUploadedCsvFilesInput = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Group = {
  __typename: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type GroupMembership = {
  __typename: 'GroupMembership';
  group: Group;
  organizationId: Scalars['ID'];
  role: Role;
};

export type GroupResult = ApiError | Group;

export type GroupUser = {
  __typename: 'GroupUser';
  groupId: Scalars['ID'];
};

export type GroupUserInfo = {
  __typename: 'GroupUserInfo';
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Guardian = {
  __typename: 'Guardian';
  birthday?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
  phoneNumber?: Maybe<PhoneNumber>;
};

export type GuardianInvitationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type GuardianPhoneNumber = {
  __typename: 'GuardianPhoneNumber';
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename: 'Image';
  id: Scalars['ID'];
  original?: Maybe<Document>;
  thumbnail?: Maybe<Document>;
};

export type ImportInput = {
  file: NamedFileInput;
  organizationId: Scalars['ID'];
  sendInviteEmailsOnImport: Scalars['Boolean'];
  type: Scalars['String'];
  userId: Scalars['ID'];
};

export type ImportResult = {
  __typename: 'ImportResult';
  error?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  partialErrors: Array<RowValidationError>;
  success: Scalars['Boolean'];
};

export type IncidentReportForm = {
  __typename: 'IncidentReportForm';
  instruction: Scalars['String'];
  templateDocument?: Maybe<Document>;
};

/** Incident types */
export enum IncidentType {
  COLLISION_WITH_GROUND = 'COLLISION_WITH_GROUND',
  COLLISION_WITH_MOVING_OBJECT = 'COLLISION_WITH_MOVING_OBJECT',
  COLLISION_WITH_PERSON = 'COLLISION_WITH_PERSON',
  COLLISION_WTH_STATIC_OBJECT = 'COLLISION_WTH_STATIC_OBJECT',
  MOTOR_VEHICLE_COLLISION = 'MOTOR_VEHICLE_COLLISION',
  MULTIPLE = 'MULTIPLE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export enum IncidentTypeEnum {
  COLLISION_WITH_GROUND = 'COLLISION_WITH_GROUND',
  COLLISION_WITH_MOVING_OBJECT = 'COLLISION_WITH_MOVING_OBJECT',
  COLLISION_WITH_PERSON = 'COLLISION_WITH_PERSON',
  COLLISION_WTH_STATIC_OBJECT = 'COLLISION_WTH_STATIC_OBJECT',
  MOTOR_VEHICLE_COLLISION = 'MOTOR_VEHICLE_COLLISION',
  MULTIPLE = 'MULTIPLE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export type IncompleteIncidentReportActionItem = {
  __typename: 'IncompleteIncidentReportActionItem';
  caseDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InvitationInput = {
  identifier: Scalars['String'];
};

export type InvitationResult = ApiError | FoundInvitation;

export type InviteUserToRoleInput = {
  birthday?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  groups?: InputMaybe<Array<Scalars['String']>>;
  guardians?: InputMaybe<Array<GuardianInvitationInput>>;
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
  roles: Array<Role>;
};

export type Language = {
  __typename: 'Language';
  language: UserLanguage;
};

export type MedicalAssessment = BaseRecoveryStage & {
  __typename: 'MedicalAssessment';
  caseId: Scalars['ID'];
  diagnosis?: Maybe<MedicalAssessmentDiagnosis>;
  document?: Maybe<Document>;
  iCanReview?: Maybe<Scalars['Boolean']>;
  iCanStepBackward?: Maybe<Scalars['Boolean']>;
  iCanSubmit?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  instruction: Scalars['String'];
  isCompletedStage?: Maybe<Scalars['Boolean']>;
  isCurrentStage?: Maybe<Scalars['Boolean']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  isLastStage?: Maybe<Scalars['Boolean']>;
  isUpcomingStage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  organizationName: Scalars['String'];
  protocolName: Scalars['String'];
  protocolUrl?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  state: StageState;
  templateDocument?: Maybe<Document>;
};

export type MedicalAssessmentConfig = {
  __typename: 'MedicalAssessmentConfig';
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  templateDocument?: Maybe<Document>;
};

/** Does the submitted medical assessment indicate concussion or no concussion? */
export enum MedicalAssessmentDiagnosis {
  CONCUSSION = 'CONCUSSION',
  NO_CONCUSSION = 'NO_CONCUSSION',
}

export type Mutation = {
  __typename: 'Mutation';
  acceptInvitation: AcceptInvitationResult;
  addUserToGroup: EditGroupUserResult;
  approveProtocol: ProtocolResult;
  changeLanguage: ChangeLanguageResult;
  createGroup: CreateGroupResult;
  createOrganization: OrganizationResult;
  createSeason: SeasonResult;
  deactivateGroup: DeactivateGroupResult;
  deleteSeason: SeasonResult;
  importFile: SubmitImportFileResult;
  inviteUserToOrganizationRole: UserResult;
  nudgePendingParticipants: NudgePendingParticipantsResult;
  nudgePendingUserRoleInvitation: NudgePendingUserRolesInvitationResult;
  reactivateGroup: GroupResult;
  register: RegisterResult;
  removeAllUserRoles: RemoveUserRolesResult;
  removeUserFromGroup: EditGroupUserResult;
  removeUserRole: RemoveUserRoleResult;
  resendEducationItems: Array<EducationItemResubmissionActionItem>;
  resendVerificationEmail: Scalars['Boolean'];
  resetPassword: ResetPasswordResult;
  resubmitEducationItems: Array<UpdatedEducationItemCompletion>;
  reviewMedicalAssessment?: Maybe<RecoveryPlanResult>;
  reviewStageDocument?: Maybe<RecoveryPlanResult>;
  stepBackward?: Maybe<RecoveryPlanResult>;
  submitIncidentReport: SubmitRemoveProtocolResult;
  submitMedicalAssessment?: Maybe<RecoveryPlanResult>;
  submitRemoveProtocol: SubmitRemoveProtocolResult;
  submitStageDocument?: Maybe<RecoveryPlanResult>;
  submitStageSignoff?: Maybe<RecoveryPlanResult>;
  updateGroup: GroupResult;
  updateOrganization: OrganizationResult;
  updateOrganizationStatus: UpdateOrganizationResult;
  updatePersonalDetails: CurrentUser;
  updateProfileImage: CurrentUser;
  updateProtocolConfig: ProtocolResult;
  updateSeason: SeasonResult;
  updateUser: OrganizationUserResult;
  updateUserTrainingCompletion: CreateUserTrainingResult;
};

export type MutationacceptInvitationArgs = {
  input: AcceptInvitationInput;
};

export type MutationaddUserToGroupArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Role;
  userId: Scalars['ID'];
};

export type MutationapproveProtocolArgs = {
  protocolId: Scalars['ID'];
};

export type MutationchangeLanguageArgs = {
  language: UserLanguage;
};

export type MutationcreateGroupArgs = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type MutationcreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationcreateSeasonArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type MutationdeactivateGroupArgs = {
  groupId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type MutationdeleteSeasonArgs = {
  organizationId: Scalars['ID'];
  seasonId: Scalars['ID'];
};

export type MutationimportFileArgs = {
  input: ImportInput;
};

export type MutationinviteUserToOrganizationRoleArgs = {
  input: InviteUserToRoleInput;
};

export type MutationnudgePendingParticipantsArgs = {
  organizationId: Scalars['ID'];
};

export type MutationnudgePendingUserRoleInvitationArgs = {
  input: NudgePendingUserRoleInvitationInput;
};

export type MutationreactivateGroupArgs = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type MutationregisterArgs = {
  input: RegisterInput;
};

export type MutationremoveAllUserRolesArgs = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationremoveUserFromGroupArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Role;
  userId: Scalars['ID'];
};

export type MutationremoveUserRoleArgs = {
  organizationId: Scalars['ID'];
  role: Role;
  userId: Scalars['ID'];
};

export type MutationresendEducationItemsArgs = {
  organizationId: Scalars['ID'];
};

export type MutationresendVerificationEmailArgs = {
  input: ResendVerificationEmailInput;
};

export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationresubmitEducationItemsArgs = {
  educationItemsAccepted: Array<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type MutationreviewMedicalAssessmentArgs = {
  input: ReviewMedicalAssessmentInput;
};

export type MutationreviewStageDocumentArgs = {
  input: ReviewStageDocumentInput;
};

export type MutationstepBackwardArgs = {
  input: StepBackwardInput;
};

export type MutationsubmitIncidentReportArgs = {
  caseId: Scalars['ID'];
  input: SubmitIncidentReportInput;
};

export type MutationsubmitMedicalAssessmentArgs = {
  input: SubmitMedicalAssessmentInput;
};

export type MutationsubmitRemoveProtocolArgs = {
  input: SubmitRemoveProtocolInput;
};

export type MutationsubmitStageDocumentArgs = {
  input: SubmitStageDocumentInput;
};

export type MutationsubmitStageSignoffArgs = {
  input: SubmitStageSignoffInput;
};

export type MutationupdateGroupArgs = {
  groupId: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type MutationupdateOrganizationArgs = {
  emergencyPhoneNumber?: InputMaybe<PhoneNumberInput>;
  id: Scalars['ID'];
  language: UserLanguage;
  name?: InputMaybe<Scalars['String']>;
};

export type MutationupdateOrganizationStatusArgs = {
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  status: OrganizationStatusEnum;
};

export type MutationupdatePersonalDetailsArgs = {
  input: UpdatePersonalDetailsInput;
};

export type MutationupdateProfileImageArgs = {
  input: UpdateProfileImageInput;
};

export type MutationupdateProtocolConfigArgs = {
  input: UpdateProtocolConfigInput;
};

export type MutationupdateSeasonArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  seasonId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type MutationupdateUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  groupsToAdd?: InputMaybe<Array<Scalars['String']>>;
  groupsToRemove?: InputMaybe<Array<Scalars['String']>>;
  guardiansToRemove?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  newGuardians?: InputMaybe<Array<GuardianInvitationInput>>;
  organizationId: Scalars['String'];
  rolesToAdd?: InputMaybe<Array<Role>>;
  rolesToRemove?: InputMaybe<Array<Role>>;
  userId: Scalars['String'];
};

export type MutationupdateUserTrainingCompletionArgs = {
  input: CreateUserTrainingInput;
};

export type NamedFileInput = {
  file: Scalars['Upload'];
  filename: Scalars['String'];
};

export type NudgePendingParticipantsResult = ApiError | NudgedParticipants;

export type NudgePendingUserRoleInvitationInput = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type NudgePendingUserRolesInvitationResult = ApiError | NudgedParticipants;

export type NudgedParticipants = {
  __typename: 'NudgedParticipants';
  id: Scalars['ID'];
  total: Scalars['Float'];
};

export type Organization = {
  __typename: 'Organization';
  emergencyPhoneNumber?: Maybe<PhoneNumber>;
  id: Scalars['ID'];
  language: UserLanguage;
  name: Scalars['String'];
  protocol: Protocol;
  sport: OrganizationSport;
  type: OrganizationType;
};

export type OrganizationDTO = {
  __typename: 'OrganizationDTO';
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type OrganizationEducationItemList = {
  __typename: 'OrganizationEducationItemList';
  items: Array<EducationItem>;
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
};

export type OrganizationEducationItemListResult = ApiError | OrganizationEducationItemList;

/** Which preset should be used when creating the organization? */
export enum OrganizationProtocolPreset {
  BLANK = 'BLANK',
  HOLLAND_BLOORVIEW = 'HOLLAND_BLOORVIEW',
  ONTARIO_HOCKEY_ASSOCIATION = 'ONTARIO_HOCKEY_ASSOCIATION',
  ONTARIO_SCHOOL = 'ONTARIO_SCHOOL',
  ONTARIO_SOCCER = 'ONTARIO_SOCCER',
  PARACHUTE_HOCKEY = 'PARACHUTE_HOCKEY',
  PARACHUTE_SCHOOL = 'PARACHUTE_SCHOOL',
  STANSTEAD = 'STANSTEAD',
}

export type OrganizationResult = ApiError | Organization;

/** Sport that the organization conducts */
export enum OrganizationSport {
  ALPINE_SKI = 'ALPINE_SKI',
  ATHLETICS = 'ATHLETICS',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  BOXING = 'BOXING',
  EQUESTRIAN = 'EQUESTRIAN',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  FOOTBALL = 'FOOTBALL',
  FREESTYLE_SKI = 'FREESTYLE_SKI',
  GYMNASTICS = 'GYMNASTICS',
  HOCKEY = 'HOCKEY',
  LACROSSE = 'LACROSSE',
  MULTIPLE = 'MULTIPLE',
  OTHER = 'OTHER',
  RINGETTE = 'RINGETTE',
  RUGBY = 'RUGBY',
  SNOWBOARD = 'SNOWBOARD',
  SOCCER = 'SOCCER',
  SPEED_SKATING = 'SPEED_SKATING',
  TENNIS = 'TENNIS',
  WRESTLING = 'WRESTLING',
}

/** The organization status */
export enum OrganizationStatusEnum {
  CLOSED = 'CLOSED',
  DEMO = 'DEMO',
  EXPIRED = 'EXPIRED',
  PAID = 'PAID',
  SUSPENDED = 'SUSPENDED',
  TEST = 'TEST',
  TRIAL = 'TRIAL',
}

export type OrganizationSubjectStatus = {
  __typename: 'OrganizationSubjectStatus';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  status: SubjectStatus;
};

/** Type of organization */
export enum OrganizationType {
  SCHOOL = 'SCHOOL',
  SPORTS_ORGANIZATION = 'SPORTS_ORGANIZATION',
}

export type OrganizationUser = BaseOrganizationUser & {
  __typename: 'OrganizationUser';
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  groups?: Maybe<Array<GroupUser>>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  memberships?: Maybe<Array<OrganizationUserRole>>;
};

export type OrganizationUserResult = ApiError | OrganizationUser;

export type OrganizationUserRole = {
  __typename: 'OrganizationUserRole';
  id: Scalars['ID'];
  role: Role;
  status: Status;
};

/** A subject's overall status within all organizations the current user has the scope to view for a subject */
export enum OverallSubjectStatus {
  ACTIVE = 'ACTIVE',
  MULTIPLE = 'MULTIPLE',
  ON_PROTOCOL = 'ON_PROTOCOL',
  REMOVED = 'REMOVED',
}

export type PaginationArgs = {
  direction?: Direction;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};

export type ParticipationReport = {
  __typename: 'ParticipationReport';
  active: Scalars['Float'];
  admins: Array<ParticipationReportUser>;
  allUsers: Array<ParticipationReportUser>;
  guardians: Array<ParticipationReportUser>;
  inactive: Scalars['Float'];
  organizationId: Scalars['ID'];
  primaryDesignatedPersons: Array<ParticipationReportUser>;
  secondaryDesignatedPersons: Array<ParticipationReportUser>;
  subjects: Array<ParticipationReportSubject>;
  total: Scalars['Float'];
};

export type ParticipationReportCount = {
  __typename: 'ParticipationReportCount';
  active: Scalars['Float'];
  pending: Scalars['Float'];
  total: Scalars['Float'];
};

export type ParticipationReportSubject = {
  __typename: 'ParticipationReportSubject';
  accepted: Scalars['Boolean'];
  acceptedOn?: Maybe<Scalars['DateTime']>;
  atLeastOneGuardianAccepted: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  roles: Array<Role>;
};

export type ParticipationReportUser = {
  __typename: 'ParticipationReportUser';
  accepted: Scalars['Boolean'];
  acceptedOn?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  roles: Array<Role>;
};

export type PendingDocumentSubmissionReviewActionItem = {
  __typename: 'PendingDocumentSubmissionReviewActionItem';
  id: Scalars['ID'];
  organization: BasicOrganization;
  subject: ActionItemSubject;
};

export type PendingInvitationActionItem = {
  __typename: 'PendingInvitationActionItem';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  organization: BasicOrganization;
};

export type PendingMedicalAssessmentReviewActionItem = {
  __typename: 'PendingMedicalAssessmentReviewActionItem';
  id: Scalars['ID'];
  organization: BasicOrganization;
  subject: ActionItemSubject;
};

export type PhoneNumber = {
  __typename: 'PhoneNumber';
  country?: Maybe<Country>;
  countryDialCode?: Maybe<Scalars['String']>;
  fullNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  isoCode: CountryIsoCode;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type PrimaryDesignatedPerson = {
  __typename: 'PrimaryDesignatedPerson';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<GuardianPhoneNumber>;
};

export type PrivacyPolicy = {
  __typename: 'PrivacyPolicy';
  content: Scalars['String'];
  id: Scalars['ID'];
};

export type PrivacyPolicyResult = ApiError | PrivacyPolicy;

export type Protocol = {
  __typename: 'Protocol';
  approved: Scalars['Boolean'];
  createdDate: Scalars['DateTime'];
  educationProtocol: EducationProtocol;
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  protocolName?: Maybe<Scalars['String']>;
  protocolUrl?: Maybe<Scalars['String']>;
  removeProtocol: RemoveProtocol;
  returnProtocol: ReturnProtocol;
};

export type ProtocolApprovalActionItem = {
  __typename: 'ProtocolApprovalActionItem';
  id: Scalars['ID'];
  organization: BasicOrganization;
};

export type ProtocolResult = ApiError | Protocol;

/** Did the use advance to the next step or return to the previous step? */
export enum ProtocolStageChangeDirection {
  ADVANCE = 'ADVANCE',
  RETURN = 'RETURN',
}

export type Query = {
  __typename: 'Query';
  actionItems: Array<ActionItem>;
  allSecondaryDesignatedPersons: Array<User>;
  allSubjects: Array<User>;
  allUserGroups: Array<GroupMembership>;
  allUserRoles: Array<RoleEntry>;
  appSettings: AppSettings;
  associations: Array<Association>;
  circleOfCare: CircleOfCareResult;
  currentProtocolByOrganization: ProtocolResult;
  currentProtocolsByUser: Array<Protocol>;
  currentUser: CurrentUserResult;
  currentUserSubjects: Array<Subject>;
  designatedPersonsNotInGroup: Array<User>;
  dpTrainingCompletion: DPTrainingCompletionResult;
  getCasesCSV: Scalars['String'];
  getIncidentReportCase: Case;
  getSubject: SubjectResult;
  getUploadedCsvFiles: UploadedCsvFilesResult;
  group: GroupResult;
  groupDesignatedPersons: Array<User>;
  groupSubjects: Array<AdminSubject>;
  groups: Array<Group>;
  hasFeature: Scalars['Boolean'];
  invitation: InvitationResult;
  isAppUpdateRequired: Scalars['Boolean'];
  isDPCompletedEducationItems: Scalars['Boolean'];
  isEducationItemsComplete: Scalars['Boolean'];
  isIncidentReportIncomplete: Scalars['Boolean'];
  organization: OrganizationResult;
  organizationGroupsForGuardianOrSDP: Array<Group>;
  organizationGroupsForPDP: Array<Group>;
  organizationStatuses: Array<OrganizationDTO>;
  organizationUsers: Array<OrganizationUser>;
  organizations: Array<Organization>;
  participationReport: ParticipationReport;
  participationReportCount: ParticipationReportCount;
  protocolById: ProtocolResult;
  protocolsByOrganization: Array<Protocol>;
  recoveryPlanWithCases?: Maybe<RecoveryPlanWithCases>;
  season: Season;
  seasons: Array<Season>;
  subjectCases: Array<CasePreview>;
  subjectGuardiansForOrganization: GetSubjectGuardiansResult;
  subjectsNotInGroup: Array<AdminSubject>;
  trainingReport: TrainingReportResult;
  userCaseLog: SubjectCaseResult;
  userCaseLogs: Array<Case>;
  userProfile: RecoveryPlan;
  userTrainingStatus: UserTrainingStatusResult;
};

export type QueryallSecondaryDesignatedPersonsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryallSubjectsArgs = {
  organizationId: Scalars['ID'];
};

export type QuerycircleOfCareArgs = {
  subjectId: Scalars['String'];
};

export type QuerycurrentProtocolByOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type QuerycurrentUserSubjectsArgs = {
  filters?: InputMaybe<SubjectsFiltersInput>;
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  paginationArgs?: InputMaybe<PaginationArgs>;
  searchStr?: InputMaybe<Scalars['String']>;
};

export type QuerydesignatedPersonsNotInGroupArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
  paginationArgs?: InputMaybe<PaginationArgs>;
  searchStr?: InputMaybe<Scalars['String']>;
};

export type QuerydpTrainingCompletionArgs = {
  organizationId: Scalars['ID'];
};

export type QuerygetCasesCSVArgs = {
  fromDate?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  status?: InputMaybe<CaseStatus>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type QuerygetIncidentReportCaseArgs = {
  caseId: Scalars['ID'];
};

export type QuerygetSubjectArgs = {
  input: CurrentUserSubjectInput;
};

export type QuerygetUploadedCsvFilesArgs = {
  input: GetUploadedCsvFilesInput;
};

export type QuerygroupArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type QuerygroupDesignatedPersonsArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
  paginationArgs?: InputMaybe<PaginationArgs>;
  searchStr?: InputMaybe<Scalars['String']>;
};

export type QuerygroupSubjectsArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
  paginationArgs?: InputMaybe<PaginationArgs>;
  searchStr?: InputMaybe<Scalars['String']>;
};

export type QuerygroupsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryhasFeatureArgs = {
  featureFlag: FeatureFlag;
};

export type QueryinvitationArgs = {
  input: InvitationInput;
};

export type QueryisAppUpdateRequiredArgs = {
  versionNumber: Scalars['String'];
};

export type QueryisDPCompletedEducationItemsArgs = {
  organizationIds: Array<Scalars['String']>;
};

export type QueryisIncidentReportIncompleteArgs = {
  caseId: Scalars['String'];
};

export type QueryorganizationArgs = {
  organizationId: Scalars['ID'];
};

export type QueryorganizationGroupsForGuardianOrSDPArgs = {
  organizationId: Scalars['String'];
  role: Role;
};

export type QueryorganizationGroupsForPDPArgs = {
  organizationId: Scalars['String'];
};

export type QueryorganizationUsersArgs = {
  organizationId: Scalars['String'];
  paginationArgs: PaginationArgs;
  role?: InputMaybe<Role>;
  searchStr?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryorganizationsArgs = {
  role?: InputMaybe<Role>;
};

export type QueryparticipationReportArgs = {
  organizationId: Scalars['ID'];
};

export type QueryparticipationReportCountArgs = {
  organizationId: Scalars['ID'];
};

export type QueryprotocolByIdArgs = {
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};

export type QueryprotocolsByOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type QueryseasonArgs = {
  organizationId: Scalars['ID'];
  seasonId: Scalars['ID'];
};

export type QueryseasonsArgs = {
  organizationId: Scalars['ID'];
};

export type QuerysubjectCasesArgs = {
  fromDate?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  paginationArgs?: InputMaybe<PaginationArgs>;
  status?: InputMaybe<CaseStatus>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type QuerysubjectGuardiansForOrganizationArgs = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type QuerysubjectsNotInGroupArgs = {
  groupId: Scalars['ID'];
  organizationId: Scalars['ID'];
  paginationArgs?: InputMaybe<PaginationArgs>;
  searchStr?: InputMaybe<Scalars['String']>;
};

export type QuerytrainingReportArgs = {
  input: TrainingReportInput;
};

export type QueryuserCaseLogArgs = {
  input: SubjectCaseInput;
};

/** Answers to be used for the Incident Report questions */
export enum RadioButtonResponse {
  DONT_KNOW = 'DONT_KNOW',
  NO = 'NO',
  YES = 'YES',
}

export enum RadioButtonResponseEnum {
  DONT_KNOW = 'DONT_KNOW',
  NO = 'NO',
  YES = 'YES',
}

export type RecoveryPlan = {
  __typename: 'RecoveryPlan';
  caseCreatedDate: Scalars['DateTime'];
  id: Scalars['ID'];
  stages: Array<RecoveryPlanStage>;
};

export type RecoveryPlanResult = ApiError | RecoveryPlan;

export type RecoveryPlanStage = MedicalAssessment | RecoveryPlanStageDocument | RecoveryPlanStageSignOff;

export type RecoveryPlanStageDocument = BaseRecoveryStage & {
  __typename: 'RecoveryPlanStageDocument';
  caseId: Scalars['ID'];
  description: Scalars['String'];
  document?: Maybe<Document>;
  iCanReview?: Maybe<Scalars['Boolean']>;
  iCanStepBackward?: Maybe<Scalars['Boolean']>;
  iCanSubmit?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isCompletedStage?: Maybe<Scalars['Boolean']>;
  isCurrentStage?: Maybe<Scalars['Boolean']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  isLastStage?: Maybe<Scalars['Boolean']>;
  isUpcomingStage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  organizationName: Scalars['String'];
  protocolName: Scalars['String'];
  protocolType?: Maybe<RtaProtocolType>;
  protocolUrl?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  state: StageState;
  stepStartTime?: Maybe<Scalars['DateTime']>;
  templateDocument?: Maybe<Document>;
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type RecoveryPlanStageSignOff = BaseRecoveryStage & {
  __typename: 'RecoveryPlanStageSignOff';
  caseId: Scalars['ID'];
  description: Scalars['String'];
  iCanSignoff?: Maybe<Scalars['Boolean']>;
  iCanStepBackward?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isCompletedStage?: Maybe<Scalars['Boolean']>;
  isCurrentStage?: Maybe<Scalars['Boolean']>;
  isFirstStage?: Maybe<Scalars['Boolean']>;
  isLastStage?: Maybe<Scalars['Boolean']>;
  isUpcomingStage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  organizationName: Scalars['String'];
  protocolName: Scalars['String'];
  protocolType?: Maybe<RtaProtocolType>;
  protocolUrl?: Maybe<Scalars['String']>;
  state: StageState;
  stepStartTime?: Maybe<Scalars['DateTime']>;
  timeGateHours?: Maybe<Scalars['Float']>;
};

export type RecoveryPlanWithCases = {
  __typename: 'RecoveryPlanWithCases';
  cases: Array<Case>;
  recoveryPlan?: Maybe<RecoveryPlan>;
};

export type RedFlag = {
  __typename: 'RedFlag';
  flags: Array<RedFlagFlag>;
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
};

export type RedFlagFlag = {
  __typename: 'RedFlagFlag';
  id: Scalars['ID'];
  key: Scalars['String'];
  order: Scalars['Int'];
  value: Scalars['String'];
};

export type RedFlagReport = {
  __typename: 'RedFlagReport';
  flags: Array<RedFlagReportRedFlag>;
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
};

export type RedFlagReportRedFlag = {
  __typename: 'RedFlagReportRedFlag';
  id: Scalars['ID'];
  key: Scalars['String'];
  order: Scalars['Int'];
  result: SymptomResult;
  value: Scalars['String'];
};

export type RedFlagResultInput = {
  redFlagId: Scalars['ID'];
  value: SymptomResult;
};

export type RegisterInput = {
  birthday?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitation?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type RegisterResult = ApiError | RegisteredUser;

export type RegisteredUser = {
  __typename: 'RegisteredUser';
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type RelatedUser = {
  __typename: 'RelatedUser';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type RemoveProtocol = {
  __typename: 'RemoveProtocol';
  discretionaryInstruction?: Maybe<Scalars['String']>;
  emergencyInstruction?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  incidentReport: IncidentReportForm;
  redFlag: RedFlag;
  removeInstruction?: Maybe<Scalars['String']>;
  resumeInstruction?: Maybe<Scalars['String']>;
  steps: Array<Step>;
};

export type RemoveUserRoleInfo = {
  __typename: 'RemoveUserRoleInfo';
  organizationId: Scalars['String'];
  role: Scalars['String'];
  roleId: Scalars['String'];
  userId: Scalars['String'];
};

export type RemoveUserRoleResult = ApiError | RemoveUserRoleInfo;

export type RemoveUserRolesResult = ApiError | DeactivateUserResult;

export type ReportResult = {
  __typename: 'ReportResult';
  completed: Scalars['Boolean'];
  id: Scalars['ID'];
  roles: Array<Scalars['String']>;
  user: Scalars['String'];
};

export type ResendVerificationEmailInput = {
  id: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
};

export type ResetPasswordResult = ApiError | ResetPasswordStatus;

export type ResetPasswordStatus = {
  __typename: 'ResetPasswordStatus';
  success: Scalars['Boolean'];
};

export type ReturnProtocol = {
  __typename: 'ReturnProtocol';
  childProtocols: Array<ChildReturnProtocol>;
  id: Scalars['ID'];
  medicalAssessment: MedicalAssessmentConfig;
};

/** Type of the protocol stage */
export type ReturnProtocolStageConfig = DocumentSubmissionStage | SignOffStage;

export type ReviewMedicalAssessmentInput = {
  caseId: Scalars['ID'];
  diagnosis: MedicalAssessmentDiagnosis;
  documentId: Scalars['ID'];
  rejectionReason?: InputMaybe<Scalars['String']>;
  reviewResult: DocumentReviewResult;
};

export type ReviewStageDocumentInput = {
  caseId: Scalars['ID'];
  documentId: Scalars['ID'];
  isFastTracked: Scalars['Boolean'];
  rejectionReason?: InputMaybe<Scalars['String']>;
  reviewResult: DocumentReviewResult;
  stageId: Scalars['ID'];
};

/** Did the RFA protocol result in removing the subject or allowing them to resume? */
export enum RfaProtocolResult {
  REMOVE = 'REMOVE',
  RESUME = 'RESUME',
}

/** The role of the user within the organization */
export enum Role {
  ADMIN = 'ADMIN',
  GUARDIAN = 'GUARDIAN',
  PRIMARY_DESIGNATED_PERSON = 'PRIMARY_DESIGNATED_PERSON',
  SECONDARY_DESIGNATED_PERSON = 'SECONDARY_DESIGNATED_PERSON',
  SUBJECT = 'SUBJECT',
}

export type RoleEntry = {
  __typename: 'RoleEntry';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  role: Role;
};

export type RowValidationError = {
  __typename: 'RowValidationError';
  reason?: Maybe<Scalars['String']>;
  rowNumber?: Maybe<Scalars['Float']>;
};

/** Indicates whether this is a return to learn (LEARN) or return to sport (SPORT) protocol */
export enum RtaProtocolType {
  LEARN = 'LEARN',
  SPORT = 'SPORT',
}

export type Season = {
  __typename: 'Season';
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type SeasonResult = ApiError | Season;

export type SecondaryDesignatedPerson = {
  __typename: 'SecondaryDesignatedPerson';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  groups: Array<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<GuardianPhoneNumber>;
};

export type SignOffStage = BaseReturnProtocolStage & {
  __typename: 'SignOffStage';
  authorizedRole: AuthorizedRole;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  timeGateHours?: Maybe<Scalars['Float']>;
};

/** The state of a recovery plan stage */
export enum StageState {
  ACTIVE = 'ACTIVE',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  COMPLETE = 'COMPLETE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type State = {
  __typename: 'State';
  /** Country code as per ISO 3166-2 alpha-2 */
  countryIsoCode: CountryIsoCode;
  /** State code as per ISO 3166-2 alpha-2 */
  isoCode: StateIsoCode;
  name: Scalars['String'];
};

/** State codes according to ISO 3166-2 standard */
export enum StateIsoCode {
  CA_AB = 'CA_AB',
  CA_BC = 'CA_BC',
  CA_MB = 'CA_MB',
  CA_NB = 'CA_NB',
  CA_NL = 'CA_NL',
  CA_NS = 'CA_NS',
  CA_NT = 'CA_NT',
  CA_NU = 'CA_NU',
  CA_ON = 'CA_ON',
  CA_PE = 'CA_PE',
  CA_QC = 'CA_QC',
  CA_SK = 'CA_SK',
  CA_YT = 'CA_YT',
  US_AK = 'US_AK',
  US_AL = 'US_AL',
  US_AR = 'US_AR',
  US_AS = 'US_AS',
  US_AZ = 'US_AZ',
  US_CA = 'US_CA',
  US_CO = 'US_CO',
  US_CT = 'US_CT',
  US_DC = 'US_DC',
  US_DE = 'US_DE',
  US_FL = 'US_FL',
  US_FM = 'US_FM',
  US_GA = 'US_GA',
  US_GU = 'US_GU',
  US_HI = 'US_HI',
  US_IA = 'US_IA',
  US_ID = 'US_ID',
  US_IL = 'US_IL',
  US_IN = 'US_IN',
  US_KS = 'US_KS',
  US_KY = 'US_KY',
  US_LA = 'US_LA',
  US_MA = 'US_MA',
  US_MD = 'US_MD',
  US_ME = 'US_ME',
  US_MH = 'US_MH',
  US_MI = 'US_MI',
  US_MN = 'US_MN',
  US_MO = 'US_MO',
  US_MP = 'US_MP',
  US_MS = 'US_MS',
  US_MT = 'US_MT',
  US_NC = 'US_NC',
  US_ND = 'US_ND',
  US_NE = 'US_NE',
  US_NH = 'US_NH',
  US_NJ = 'US_NJ',
  US_NM = 'US_NM',
  US_NV = 'US_NV',
  US_NY = 'US_NY',
  US_OH = 'US_OH',
  US_OK = 'US_OK',
  US_OR = 'US_OR',
  US_PA = 'US_PA',
  US_PR = 'US_PR',
  US_PW = 'US_PW',
  US_RI = 'US_RI',
  US_SC = 'US_SC',
  US_SD = 'US_SD',
  US_TN = 'US_TN',
  US_TX = 'US_TX',
  US_UM = 'US_UM',
  US_UT = 'US_UT',
  US_VA = 'US_VA',
  US_VI = 'US_VI',
  US_VT = 'US_VT',
  US_WA = 'US_WA',
  US_WI = 'US_WI',
  US_WV = 'US_WV',
  US_WY = 'US_WY',
}

/** Whether or not the user has accepted the invitation to the organization. */
export enum Status {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
}

export type Step = StepChallenge | StepInformation | StepSymptom;

export type StepBackwardInput = {
  caseId: Scalars['ID'];
  stageId?: InputMaybe<Scalars['ID']>;
};

export type StepChallenge = BaseStep & {
  __typename: 'StepChallenge';
  challenges: Array<Challenge>;
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type StepChallengeReport = BaseStepReport & {
  __typename: 'StepChallengeReport';
  challenges: Array<ChallengeReport>;
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type StepInformation = BaseStep & {
  __typename: 'StepInformation';
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type StepReport = StepChallengeReport | StepSymptomReport;

export type StepResultInput = {
  challengeResults: Array<ChallengeResultSetInput>;
  protocolStepId: Scalars['ID'];
  symptomResults: Array<SymptomResultSetInput>;
};

export type StepSymptom = BaseStep & {
  __typename: 'StepSymptom';
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  symptoms: Array<Symptom>;
};

export type StepSymptomReport = BaseStepReport & {
  __typename: 'StepSymptomReport';
  id: Scalars['ID'];
  instruction: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  symptoms: Array<SymptomReport>;
};

export type Subject = BaseSubject & {
  __typename: 'Subject';
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['String']>;
  case: SubjectCaseResult;
  cases: Array<Case>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  groups: Array<Group>;
  guardians: Array<Guardian>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organizations: Array<Organization>;
  recoveryPlan?: Maybe<RecoveryPlan>;
  subjectMemberships: SubjectMemberships;
  userImage?: Maybe<Image>;
};

export type SubjectcaseArgs = {
  input: SubjectCaseInput;
};

export type SubjectCaseInput = {
  caseId: Scalars['ID'];
};

export type SubjectCaseResult = ApiError | Case;

export type SubjectGuardian = {
  __typename: 'SubjectGuardian';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<GuardianPhoneNumber>;
  userId: Scalars['String'];
};

export type SubjectGuardians = {
  __typename: 'SubjectGuardians';
  guardians: Array<SubjectGuardian>;
};

export type SubjectMembershipOrganization = {
  __typename: 'SubjectMembershipOrganization';
  groups: Array<Group>;
  id: Scalars['ID'];
  organization: Organization;
  status: SubjectStatus;
};

export type SubjectMemberships = {
  __typename: 'SubjectMemberships';
  id: Scalars['ID'];
  organizations: Array<SubjectMembershipOrganization>;
  status: OverallSubjectStatus;
};

/** How subject is related to current user - is subject for a designated person, is child of a guardian. */
export enum SubjectRelation {
  KID = 'KID',
  SUBJECT = 'SUBJECT',
}

export type SubjectResult = ApiError | Subject;

/** To what extent is a subject's able to participate in activity within an organization? */
export enum SubjectStatus {
  ACTIVE = 'ACTIVE',
  ON_PROTOCOL = 'ON_PROTOCOL',
  REMOVED = 'REMOVED',
}

export type SubjectsFiltersInput = {
  relations?: InputMaybe<Array<SubjectRelation>>;
  statuses?: InputMaybe<Array<SubjectStatus>>;
};

export type SubmitImportFileResult = ApiError | ImportResult;

export type SubmitIncidentReportInput = {
  file?: InputMaybe<NamedFileInput>;
  hasMedicalConditions?: InputMaybe<RadioButtonResponse>;
  hasMedicalConditionsDetails?: InputMaybe<Scalars['String']>;
  hasMedication?: InputMaybe<RadioButtonResponse>;
  hasMedicationDetails?: InputMaybe<Scalars['String']>;
  hasOtherInjuries?: InputMaybe<RadioButtonResponse>;
  hasOtherInjuriesDetails?: InputMaybe<Scalars['String']>;
  hasOtherSymptoms?: InputMaybe<RadioButtonResponse>;
  hasOtherSymptomsDetails?: InputMaybe<Scalars['String']>;
  hasPreviousConcussion?: InputMaybe<RadioButtonResponse>;
  hasPreviousConcussionDetails?: InputMaybe<Scalars['String']>;
  incidentDate?: InputMaybe<Scalars['DateTime']>;
  incidentDescription?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  subjectId: Scalars['ID'];
  type?: InputMaybe<IncidentType>;
  typeDetails?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
};

export type SubmitMedicalAssessmentInput = {
  caseId: Scalars['ID'];
  diagnosis: MedicalAssessmentDiagnosis;
  file: NamedFileInput;
};

export type SubmitRemoveProtocolInput = {
  emergency?: InputMaybe<Scalars['Boolean']>;
  isSkippedAssessment?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
  redFlagResults: Array<RedFlagResultInput>;
  result: RfaProtocolResult;
  stepResults: Array<StepResultInput>;
  subjectId: Scalars['ID'];
  suggestedResult: RfaProtocolResult;
};

export type SubmitRemoveProtocolResult = ApiError | Case;

export type SubmitStageDocumentInput = {
  caseId: Scalars['ID'];
  file: NamedFileInput;
  isFastTracked: Scalars['Boolean'];
  stageId: Scalars['ID'];
};

export type SubmitStageSignoffInput = {
  caseId: Scalars['ID'];
  isFastTracked: Scalars['Boolean'];
  stageId?: InputMaybe<Scalars['ID']>;
};

export type Symptom = {
  __typename: 'Symptom';
  id: Scalars['ID'];
  key: Scalars['String'];
  order: Scalars['Float'];
  value: Scalars['String'];
};

export type SymptomReport = {
  __typename: 'SymptomReport';
  id: Scalars['ID'];
  key: Scalars['String'];
  order: Scalars['Float'];
  result?: Maybe<SymptomResult>;
  value: Scalars['String'];
};

/** Whether a symptom was present or absent on a subject */
export enum SymptomResult {
  ABSENT = 'ABSENT',
  PRESENT = 'PRESENT',
}

export type SymptomResultSetInput = {
  symptomId: Scalars['ID'];
  value?: InputMaybe<SymptomResult>;
};

export type TrainingReportInput = {
  organizationId: Scalars['ID'];
  paginationArgs: PaginationArgs;
  role?: InputMaybe<Role>;
  searchStr?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type TrainingReportResult = ApiError | GetTrainingReportResult;

export type UnverifiedCurrentUser = {
  __typename: 'UnverifiedCurrentUser';
  accountId: Scalars['ID'];
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<PhoneNumber>;
  userImage?: Maybe<Image>;
};

export type UpdateEducationProtocolConfigInput = {
  items: Array<UpdateEducationProtocolItemConfigInput>;
};

export type UpdateEducationProtocolItemConfigInput = {
  document?: InputMaybe<FileIdOrUploadInput>;
  instruction: Scalars['String'];
  name: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  useForDesignatedPerson: Scalars['Boolean'];
  useForGuardian: Scalars['Boolean'];
  useForSubject: Scalars['Boolean'];
};

export type UpdateOrganizationResult = ApiError | OrganizationDTO;

export type UpdatePersonalDetailsInput = {
  birthday?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type UpdateProfileImageInput = {
  file: NamedFileInput;
  id: Scalars['String'];
};

export type UpdateProtocolConfigInput = {
  educationProtocol: UpdateEducationProtocolConfigInput;
  organizationId: Scalars['String'];
  protocolName: Scalars['String'];
  protocolUrl: Scalars['String'];
  removeProtocol: UpdateRemoveProtocolConfigInput;
  returnProtocol: UpdateReturnProtocolConfigInput;
};

export type UpdateRemoveProtocolConfigInput = {
  discretionaryInstruction: Scalars['String'];
  emergencyInstruction: Scalars['String'];
  incidentReport: UpdateRemoveProtocolIncidentReportConfigInput;
  redFlag: UpdateRemoveProtocolRedFlagConfigInput;
  removeInstruction: Scalars['String'];
  resumeInstruction: Scalars['String'];
  steps: Array<UpdateRemoveProtocolStepConfigInput>;
};

export type UpdateRemoveProtocolIncidentReportConfigInput = {
  instruction: Scalars['String'];
  templateDocument?: InputMaybe<FileIdOrUploadInput>;
};

export type UpdateRemoveProtocolRedFlagConfigInput = {
  flags: Array<UpdateRemoveProtocolRedFlagFlagConfigInput>;
  instruction: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateRemoveProtocolRedFlagFlagConfigInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateRemoveProtocolStepChallengeChallengeConfigInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateRemoveProtocolStepChallengeConfigInput = {
  challenges: Array<UpdateRemoveProtocolStepChallengeChallengeConfigInput>;
  instruction: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateRemoveProtocolStepConfigInput = {
  challenge?: InputMaybe<UpdateRemoveProtocolStepChallengeConfigInput>;
  symptom?: InputMaybe<UpdateRemoveProtocolStepSymptomConfigInput>;
};

export type UpdateRemoveProtocolStepSymptomConfigInput = {
  instruction: Scalars['String'];
  name: Scalars['String'];
  symptoms: Array<UpdateRemoveProtocolStepSymptomSymptomConfigInput>;
};

export type UpdateRemoveProtocolStepSymptomSymptomConfigInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateReturnProtocolChildProtocolConfigInput = {
  stages: Array<UpdateReturnProtocolStageConfigInput>;
  type: RtaProtocolType;
};

export type UpdateReturnProtocolConfigInput = {
  childProtocols: Array<UpdateReturnProtocolChildProtocolConfigInput>;
  medicalAssessment: UpdateReturnProtocolMedicalAssessmentConfigInput;
};

export type UpdateReturnProtocolMedicalAssessmentConfigInput = {
  instruction: Scalars['String'];
  name: Scalars['String'];
  templateDocument?: InputMaybe<FileIdOrUploadInput>;
};

export type UpdateReturnProtocolStageConfigInput = {
  authorizedRole: AuthorizedRole;
  documentSubmission?: InputMaybe<UpdateReturnProtocolStageDocumentSubmissionConfigInput>;
  signOff?: InputMaybe<UpdateReturnProtocolStageSignOffConfigInput>;
  timeGateHours?: InputMaybe<Scalars['Float']>;
};

export type UpdateReturnProtocolStageDocumentSubmissionConfigInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  templateDocument?: InputMaybe<FileIdOrUploadInput>;
};

export type UpdateReturnProtocolStageSignOffConfigInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatedEducationItemCompletion = {
  __typename: 'UpdatedEducationItemCompletion';
  completed: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type UploadedCsvFile = {
  __typename: 'UploadedCsvFile';
  author: Scalars['String'];
  createdDate: Scalars['DateTime'];
  downloadUri: Scalars['String'];
  errors?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
};

export type UploadedCsvFilesResult = {
  __typename: 'UploadedCsvFilesResult';
  UploadedCsvFilesResult: Array<UploadedCsvFile>;
};

export type User = {
  __typename: 'User';
  age?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<PhoneNumber>;
  userImage?: Maybe<Image>;
};

/** User language for CAPT */
export enum UserLanguage {
  en = 'en',
  fr = 'fr',
}

export type UserResult = ApiError | User;

export type UserRoleInvitation = {
  __typename: 'UserRoleInvitation';
  action: AcceptInvitationAction;
  educationItems: Array<EducationProtocolItem>;
  id: Scalars['String'];
  organizationName: Scalars['String'];
  roles: Array<Role>;
  termsAndConditionsAccepted: Scalars['Boolean'];
  user: User;
};

export type UserTrainingResult = {
  __typename: 'UserTrainingResult';
  completed: Scalars['Boolean'];
};

export type UserTrainingStatus = {
  __typename: 'UserTrainingStatus';
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  nccpNumber?: Maybe<Scalars['String']>;
};

export type UserTrainingStatusResult = ApiError | UserTrainingStatus;
