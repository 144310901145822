import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Icon, IconName } from '../Icon/Icon';
import { useColors, useShadow } from '../../shared/Ui';

export interface BaseInputProps {
  style?: ViewStyle;
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  children: React.ReactNode;
}

export const BaseInput: React.FC<BaseInputProps> = ({ style, children, icon, iconPosition = 'left' }) => {
  const colors = useColors();
  const shadow = useShadow();
  const styles = StyleSheet.create({
    baseInput: {
      backgroundColor: colors.white,
      ...shadow,
      borderRadius: 8,
      paddingHorizontal: 15,
      width: '100%',
      elevation: 5,
      flexDirection: 'row',
      marginVertical: 15,
      borderColor: 'transparent',
    },
    icon: {
      marginRight: iconPosition === 'left' ? 16 : 2,
      marginLeft: iconPosition === 'left' ? 2 : 16,
    },
  });

  return (
    <View style={[styles.baseInput, style]}>
      {icon ? <Icon name={icon} color={colors.blue} size={24} style={styles.icon} /> : null}
      {children}
    </View>
  );
};
