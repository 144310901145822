import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { find } from 'lodash/fp';
import { ActivityIndicator, AppButton, AppText, RadioButton } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AssessNavType, AssessProtocolOption, AssessRouteProp } from '../../Assess.interface';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { AssessChrome } from '../../AssessChrome';
import { RfaProtocolResult, Role, SubjectStatus } from '../../../../types/globalTypes';
import { useRemoveWithoutAssessment } from '../../hooks/useRemoveWithoutAssessment';
import { useSubmitRemoveProtocol } from '../../hooks/useSubmitRemoveProtocol';
import { useCurrentUser } from '../../../../shared/CurrentUser/useCurrentUser';

export interface ChooseOrganizationProtocolProps {}

export const ChooseOrganizationProtocol: React.FC<ChooseOrganizationProtocolProps> = (props) => {
  const { translate, translateWithVars } = useTranslations();
  const { state, dispatch } = useCurrentAssessment();
  const navigation = useNavigation<AssessNavType<'LoggedIn_Assess_ChooseOrganizationProtocol'>>();
  const [selectedProtocol, setSelectedProtocol] = React.useState<AssessProtocolOption>();

  const availableProtocolOptions = state.availableProtocolOptions || [];

  const route = useRoute<AssessRouteProp<'LoggedIn_Assess_ChooseOrganizationProtocol'>>();
  const assessType = route.params.assessType;

  const [submitRemoveProtocol, { loading }] = useSubmitRemoveProtocol({
    onCompleted: (data) => {
      setRemoveModal({ visible: false });
      if (data.submitRemoveProtocol.__typename === 'Case' && data.submitRemoveProtocol)
        navigation.navigate('LoggedIn_IncidentReport', { caseId: data.submitRemoveProtocol.id });
    },
  });

  const handleRemoveWithoutAssessment = () => {
    if (removeModal.subject && selectedProtocol) {
      submitRemoveProtocol({
        variables: {
          input: {
            subjectId: removeModal.subject?.id,
            organizationId: selectedProtocol.organization.id,
            suggestedResult: RfaProtocolResult.REMOVE,
            protocolId: selectedProtocol.protocol.id,
            redFlagResults: [],
            stepResults: [],
            emergency: true,
            result: RfaProtocolResult.REMOVE,
            isSkippedAssessment: true,
          },
        },
      });
    }
  };

  const { removeModal, setRemoveModal, RemoveModal } = useRemoveWithoutAssessment(handleRemoveWithoutAssessment);

  const handleNextButton = () => {
    if (selectedProtocol) {
      dispatch({
        type: 'setProtocol',
        payload: {
          selectedProtocolOption: selectedProtocol,
        },
      });
      if (assessType === 'recognize') {
        navigation.push('LoggedIn_Assess_RedFlags');
      } else if (assessType === 'remove') {
        setRemoveModal({ visible: true, subject: state.subject });
      }
    } else {
      console.log('No organization selected');
    }
  };

  const handleProtocolSelect = (value: string) => {
    const foundProtocol = find(({ organization: { id } }) => id === value, availableProtocolOptions);
    setSelectedProtocol(foundProtocol);
  };

  const styles = StyleSheet.create({
    selectAvailable: {
      marginTop: 20,
    },
    selectUnavailable: {
      marginTop: 20,
    },
  });

  const footer = (
    <AppButton
      disabled={!selectedProtocol}
      onPress={handleNextButton}
      style={{ width: 200, marginTop: 20, alignSelf: 'flex-start' }}
    >
      {translate.label.next}
    </AppButton>
  );

  const content = !loading ? (
    <>
      <RemoveModal />
      <AppText gutter={true} font="header2">
        {translate.chooseOrganizationProtocol.chooseOrganizationProtocol}
      </AppText>
      <AppText>
        {translateWithVars(translate.chooseOrganizationProtocol.organization, { name: state.subject?.fullName || '' })}
      </AppText>
      <RadioButton
        style={styles.selectAvailable}
        direction="vertical"
        disabled={false}
        value={selectedProtocol?.organization.id || ''}
        onSelect={handleProtocolSelect}
        options={availableProtocolOptions.map(({ organization: { id, name }, groups, status }) => ({
          icon: 'check',
          label:
            status !== SubjectStatus.ACTIVE ? `${name} (${translate.chooseOrganizationProtocol.alreadyRemoved})` : name,
          value: id,
          disabled: status !== SubjectStatus.ACTIVE,
        }))}
      />
    </>
  ) : (
    <ActivityIndicator />
  );

  return (
    <AssessChrome
      content={
        <>
          {content}
          {footer}
        </>
      }
      customTitle={assessType === 'remove' ? translate.protocol.removeModal.title : undefined}
    />
  );
};
