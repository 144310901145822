import { gql } from '@apollo/client';

export const SeasonFragment = gql`
  fragment Season on Season {
    id
    name
    startDate
    endDate
  }
`;
