import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useColors } from '../../shared/Ui/hooks';

export interface Separator {}

export const Separator: React.FC<Separator> = () => {
  const colors = useColors();
  const styles = StyleSheet.create({
    wrapper: {
      marginTop: 10,
      height: 0,
      borderBottomColor: colors.blue,
      borderBottomWidth: 1,
    },
  });

  return <View style={[styles.wrapper]}></View>;
};
