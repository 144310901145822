import { gql } from '@apollo/client';
import { useQuery } from '../../../../hooks/useQuery';

export const GET_USER_PROFILE = gql`
  query UserProfile {
    userProfile {
      id
      stages {
        ... on MedicalAssessment {
          id
        }
      }
    }
  }
`;

export const useGetUserProfile = (options?) => useQuery(GET_USER_PROFILE, options);
