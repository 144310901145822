import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { GetDPTrainingCompletion, GetDPTrainingCompletionVariables } from './__generated__/GetDPTrainingCompletion';

export const GET_DP_TRAINING_COMPLETION = gql`
  query GetDPTrainingCompletion($organizationId: ID!) {
    dpTrainingCompletion(organizationId: $organizationId) {
      ... on DPTrainingCompletion {
        numberOfDPsCompleted
        totalNumberOfDPs
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetDPTrainingCompletion = (organizationId: string, options?) =>
  useQuery<GetDPTrainingCompletion, GetDPTrainingCompletionVariables>(GET_DP_TRAINING_COMPLETION, {
    ...options,
    variables: { organizationId },
  });
