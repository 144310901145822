import { gql, useQuery } from '@apollo/client';

export const IS_DP_COMPLETED_EDUCATION_ITEMS = gql`
  query isDPCompletedEducationItems($organizationIds: [String!]!) {
    isDPCompletedEducationItems(organizationIds: $organizationIds)
  }
`;

export const useIsDPCompletedEducationItems = (organizationIds: string[], options?) =>
  useQuery(IS_DP_COMPLETED_EDUCATION_ITEMS, {
    variables: {
      organizationIds,
    },
    skip: organizationIds.length === 0,
    ...options,
  });
