import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Select } from '../Select/Select';
import { DialCodeSelectItem } from './PhoneInput.interface';
import { useDialCodes } from '../../shared/Ui/hooks/useDialCodes';

interface DialCodeListProps<T extends string> {
  value: T;
  onChange?: (value: T) => void;
  onBlur?: (e: any) => void;
  filterDialCodes?: (dialCode: DialCodeSelectItem<T>) => boolean;
  style?: StyleProp<ViewStyle>;
}

export const DialCodeList = <T extends string>({
  value,
  onChange,
  onBlur,
  filterDialCodes,
  style,
}: DialCodeListProps<T>) => {
  const _dialCodes = useDialCodes<T>();

  const dialCodes: DialCodeSelectItem<T>[] = _dialCodes.filter((val) =>
    filterDialCodes ? filterDialCodes(val) : true
  );

  return (
    <Select<T>
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      items={dialCodes.map(({ isoCode, dialCode }) => ({
        key: isoCode,
        value: isoCode,
        label: `${dialCode}`,
      }))}
    />
  );
};
