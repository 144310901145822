import { gql, QueryHookOptions } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { GetUploadedCsvFiles, GetUploadedCsvFilesVariables } from './__generated__/GetUploadedCsvFiles';

const GET_UPLOADED_CSV_FILES = gql`
  query GetUploadedCsvFiles($input: GetUploadedCsvFilesInput!) {
    getUploadedCsvFiles(input: $input) {
      ... on UploadedCsvFilesResult {
        UploadedCsvFilesResult {
          id
          fileName
          createdDate
          status
          downloadUri
          author
          errors
          type
        }
      }
    }
  }
`;

export const useGetUploadedCsvFiles = (options?: QueryHookOptions<GetUploadedCsvFiles, GetUploadedCsvFilesVariables>) =>
  useQuery<GetUploadedCsvFiles, GetUploadedCsvFilesVariables>(GET_UPLOADED_CSV_FILES, {
    ...options,
    hideAlerts: true,
  });
