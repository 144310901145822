/* Form validation functions
=============================== */
import { isNull } from 'lodash';
import { CountryIsoCode } from '../../../types/globalTypes';
import { useMakeFullPhoneNumber } from '../../../shared/Ui/hooks';

export const validatePhoneNumber = (phoneNumber: string) => {
  const regex =
    /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}/;

  const res = phoneNumber.match(regex);

  return !isNull(res);
};

export const useValidatePhoneNumber = () => {
  const makeFullPhoneNumber = useMakeFullPhoneNumber<CountryIsoCode>();

  return (value: string, countryIsoCode: CountryIsoCode) =>
    validatePhoneNumber(makeFullPhoneNumber(value, countryIsoCode));
};
