import { FieldArray, isString, useFormikContext } from 'formik';
import React from 'react';
import { UpdateProtocolConfigFormValue } from '../../ManageProtocol.interface';
import { KeyValuePairFields } from '../../KeyValuePairFields/KeyValuePairFields';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Input, ShadowView, Spacer } from '../../../../../../uiComponents';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            remove: { redFlag: translate },
          },
        },
      },
    },
    translate: rootTranslate,
  } = useTranslations();

  const {
    handleChange,
    handleBlur,
    errors: _errors,
    touched: _touched,
    values: _values,
  } = useFormikContext<UpdateProtocolConfigFormValue>();
  const itemKey = 'removeProtocol.redFlag';
  const values = _values.removeProtocol.redFlag;
  const itemErrors = _errors?.removeProtocol?.redFlag;
  const itemTouched = _touched.removeProtocol?.redFlag;
  const errors = !isString(itemErrors) ? itemErrors : undefined;
  const touched = !isString(itemTouched) ? itemTouched : undefined;

  return (
    <Spacer
      items={{
        heading: (
          <AppText font="header3" gutter={true}>
            {rootTranslate.protocol.redFlags}
          </AppText>
        ),
        name: (
          <Input
            label={rootTranslate.label.name}
            onChangeText={handleChange(`${itemKey}.name`)}
            onBlur={handleBlur(`${itemKey}.name`)}
            error={errors?.name}
            touched={touched?.name}
            value={values.name}
          />
        ),
        instruction: (
          <Input
            label={rootTranslate.admin.manageProtocol.instruction}
            onChangeText={handleChange(`${itemKey}.instruction`)}
            onBlur={handleBlur(`${itemKey}.instruction`)}
            error={errors?.instruction}
            touched={touched?.instruction}
            value={values?.instruction}
          />
        ),
        redFlags: (
          <>
            <AppText gutter={true} font="header4">
              {translate.flags}
            </AppText>
            <FieldArray
              name={`${itemKey}.flags`}
              children={(vals) => (
                <ShadowView style={{ padding: 20 }}>
                  <KeyValuePairFields addNewLabel={translate.addNewRedFlag} {...vals} />
                </ShadowView>
              )}
            />
          </>
        ),
      }}
    />
  );
};
