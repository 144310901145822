import React from 'react';
import { StyleSheet } from 'react-native';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppModal, AppButton, AppText } from '../../../../../uiComponents';
import { useNavigation } from '@react-navigation/native';
import { ManageGroupsNavType } from '../ManageGroups.interface';
import { useDeactivateGroup } from '../hooks/useDeactivateGroup';

interface DeactivateGroupModalProps {
  name: string;
  visible?: boolean;
  organizationId: string;
  groupId: string;
  onDismiss: () => void;
}

export const DeactivateGroupModal: React.FC<DeactivateGroupModalProps> = ({
  name,
  onDismiss,
  visible,
  organizationId,
  groupId,
}) => {
  const { translate } = useTranslations();
  const navigation =
    useNavigation<
      ManageGroupsNavType<
        | 'LoggedIn_Admin_ManageGroups_GroupDetails_Subjects'
        | 'LoggedIn_Admin_ManageGroups_GroupDetails_DesignatedPersons'
      >
    >();

  const [deactivateGroup] = useDeactivateGroup(organizationId, groupId);

  const handleDeactivateGroup = async () => {
    const { data } = await deactivateGroup();
    if (data && data.deactivateGroup.__typename === 'DeactivatedGroup') {
      navigation.navigate('LoggedIn_Admin_ManageGroups_Index', { prevScreen: 'groupDetails' });
      onDismiss();
    }
  };

  const styles = StyleSheet.create({
    modalContent: {
      marginBottom: 25,
    },
  });

  return (
    <AppModal
      mode="dialog"
      title={translate.admin.manageSeasons.seasonDetails.deactivateGroupModal.title}
      visible={visible}
      onDismiss={onDismiss}
      buttons={{
        deactivate: (
          <AppButton onPress={handleDeactivateGroup}>
            {translate.admin.manageSeasons.seasonDetails.deactivateGroupModal.deactivate}
          </AppButton>
        ),
      }}
    >
      <AppText style={styles.modalContent}>
        {`${translate.admin.manageSeasons.seasonDetails.deactivateGroupModal.content} ${name}?`}
      </AppText>
    </AppModal>
  );
};
