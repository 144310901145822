import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AppText } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';

export const SeasonPlaceholder: React.FC = () => {
  const { translate } = useTranslations();
  const styles = StyleSheet.create({
    content: {
      margin: 'auto',
    },
  });

  return (
    <View>
      <AppText style={styles.content}>{translate.seasons.noSeasons}</AppText>
    </View>
  );
};
