import { useFormikContext } from 'formik';
import React from 'react';
import { CreateGroupFormData } from './CreateGroupModal.interface';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { Input } from '../../../../../uiComponents';

export interface FieldsProps {}

export const Fields: React.FC<FieldsProps> = (props) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<CreateGroupFormData>();
  const { translate } = useTranslations();
  return (
    <Input
      label={translate.admin.manageSeasons.seasonDetails.createGroupModal.groupName}
      onChangeText={handleChange('name')}
      onBlur={handleBlur('name')}
      value={values.name}
      type="text"
      autoFocus={true}
      error={errors.name}
      touched={touched.name}
    />
  );
};
