import { gql } from '@apollo/client';
import { useQuery } from '../../../../hooks/useQuery';

const GET_GROUPS_FOR_GUARDIAN_OR_SDP = gql`
  query GetGroupsForGuardianOrSDP($role: Role!, $organizationId: String!) {
    organizationGroupsForGuardianOrSDP(role: $role, organizationId: $organizationId) {
      ... on Group {
        name
        id
      }
    }
  }
`;

export const useGetGroupsForGuardianOrSDP = (options) => useQuery(GET_GROUPS_FOR_GUARDIAN_OR_SDP, options);
