import React, { useRef, useEffect } from 'react';
import { View, StyleSheet, TextInput, Platform } from 'react-native';
import { useState } from 'react';
import { AppText, Input, Select, ActivityIndicator, UserList, PageNavButtons } from '../../uiComponents';
import { SubjectForSubjectsList } from '../shared/SubjectList/hooks/__generated__/SubjectForSubjectsList';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { useTranslations } from '../../i18n/useTranslations';
import { Role } from '../../types/globalTypes';
import { useSearchAndSort, SortValue } from '../Admin/screens/ManageGroups/GroupDetails/hooks/useSearchAndSort';
import { CurrentUser_roles } from '../../fragments/__generated__/CurrentUser';
import { useSubjectsListQuery } from '../shared/SubjectList/hooks/useSubjectsList';
import { useGetGroupsForGuardianOrSDP } from '../shared/SubjectList/hooks/useGetGroupsForGuardianOrSDP';
import { useGetGroupsForPrimaryDesignatedPerson } from '../shared/SubjectList/hooks/useGetGroupsForPrimaryDesignatedPerson';
import { SubjectStatus, SubjectRelation } from '../../types/globalTypes';
import { useMediaQuery } from 'react-responsive';
import { Name, Groups, SubjectStatus as StatusLabel } from './Columns';

interface Relations {
  relations: SubjectRelation[];
}

interface Statuses {
  statuses: SubjectStatus[];
}

interface ContentProps {
  role: CurrentUser_roles;
  handleSelect: (subject: SubjectForSubjectsList) => void;
  filters?: Relations | Statuses;
  showStatusDropdown?: boolean;
  emptyListMessage: string;
}

interface SelectOptions {
  key: string;
  value: string;
  label: string;
}

export const SubjectData: React.FC<ContentProps> = ({
  role,
  handleSelect,
  filters,
  showStatusDropdown = true,
  emptyListMessage,
}) => {
  const inputRef = useRef<TextInput>(null);
  const { handleSortChange, handleSearchStr, selectedSortType, searchStr, sortCategories, setIsSearchInputFocused } =
    useSearchAndSort(inputRef);

  const midWidth = useMediaQuery({ maxWidth: 1280 });

  const isPDP = role.role === Role.PRIMARY_DESIGNATED_PERSON;

  const [selectedGroup, setSelectedGroup] = useState<string>('all');
  const [subjectStatus, setSubjectStatus] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isSearchAndSortVisible, setIsSearchAndSortVisible] = useState<boolean>(false);

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedGroup, subjectStatus, searchStr, selectedSortType]);

  const isMobile = useIsMobile();
  const { translate } = useTranslations();

  const { data } = useSubjectsListQuery({
    hideLoading: true,
    variables: {
      organizationId: role.organizationId,
      groupId: selectedGroup === 'all' ? null : selectedGroup,
      paginationArgs: {
        skip: currentPage,
        orderBy: selectedSortType.split('_')[0],
        direction: selectedSortType.split('_')[1],
      },
      searchStr: searchStr ? searchStr : null,
      filters: { statuses: subjectStatus !== 'all' ? [subjectStatus] : [], ...filters },
    },
  });

  const subjects = data?.currentUserSubjects;

  useEffect(() => {
    if (subjects && subjects.length === 25 && isSearchAndSortVisible === false) {
      setIsSearchAndSortVisible(true);
    }
  }, [subjects]);

  const { data: guardianOrSDPGroups } = useGetGroupsForGuardianOrSDP({
    skip: isPDP,
    variables: {
      organizationId: role.organizationId,
      role: role.role,
    },
  });

  const { data: pdpGroups } = useGetGroupsForPrimaryDesignatedPerson({
    skip: !isPDP,
    variables: {
      organizationId: role.organizationId,
    },
  });

  if (!guardianOrSDPGroups && !pdpGroups) return null;

  const groups = isPDP
    ? pdpGroups.organizationGroupsForPDP.map((group) => ({ value: group.id, key: group.id, label: group.name }))
    : guardianOrSDPGroups.organizationGroupsForGuardianOrSDP.map((group) => ({
        value: group.id,
        key: group.id,
        label: group.name,
      }));

  const groupOptions: SelectOptions[] = [{ value: 'all', key: 'all', label: translate.searchAndSort.all }].concat(
    groups
  );

  const subjectStatusOptions: SelectOptions[] = [
    { value: 'all', key: 'all', label: translate.searchAndSort.all },
    { value: SubjectStatus.ACTIVE, key: SubjectStatus.ACTIVE, label: translate.subjectStatus.active },
    { value: SubjectStatus.ON_PROTOCOL, key: SubjectStatus.ON_PROTOCOL, label: translate.subjectStatus.onProtocol },
    { value: SubjectStatus.REMOVED, key: SubjectStatus.REMOVED, label: translate.subjectStatus.removed },
  ];

  const getWidth = (component: string) => {
    if (component === 'sortSelect') {
      if (midWidth || isMobile) {
        return '50%';
      }

      if (!showStatusDropdown) {
        return '30%';
      } else {
        return '22%';
      }
    }
  };

  const styles = StyleSheet.create({
    container: {
      marginBottom: 30,
    },
    organizationName: {
      marginBottom: 20,
      marginHorizontal: 10,
    },
    groups: {
      display: 'flex',
      marginTop: 15,
    },
    searchSortSelectWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      flex: 1,
      marginHorizontal: 10,
      width: '100%',
    },
    searchBarContainer: {
      marginHorizontal: !isMobile && !midWidth ? 10 : 0,
      marginRight: !isMobile ? 20 : 0,
      flexGrow: 1,
      alignSelf: 'flex-end',
      height: Platform.OS === 'ios' ? 70 : 'auto',
    },
    sortSelect: {
      flexBasis: getWidth('sortSelect'),
      flexGrow: 0,
      flexShrink: 0,
      flex: 1,
    },
    firstSortSelect: {
      paddingRight: isMobile ? 0 : 8,
    },
    secondSortSelect: {
      paddingLeft: isMobile || midWidth ? 0 : 8,
      paddingRight: midWidth && !isMobile ? 10 : 0,
    },
    statusSelect: {
      maxWidth: isMobile || midWidth ? '35%' : '100%',
    },
    subjectList: {
      flex: 1,
      marginHorizontal: 10,
    },
    pageNavButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 20,
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const handleGroupChange = (_group: string) => {
    setSelectedGroup(_group);
  };

  const handleSubjectStatusChange = (_status: string) => {
    setSubjectStatus(_status);
  };

  const getHeadingContent = () => {
    if (isPDP) {
      return translate.subjectList.subjectData.allGroups;
    }

    if (groups.length > 3) {
      return `${groups.length} ${translate.subjectList.subjectData.groups}`;
    } else {
      return groups.map((group) => group.label).join(', ');
    }
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const Heading: React.FC = () => (
    <View style={styles.organizationName}>
      <AppText font="header4">{role.organizationName}</AppText>
      <View style={styles.groups}>
        <AppText font="labelDefault">{getHeadingContent()}</AppText>
      </View>
    </View>
  );

  const isSearchOrFilterApplied = searchStr !== '' || subjectStatus !== 'all' || selectedGroup !== 'all';

  return (
    <View key={role.organizationId} style={styles.container}>
      <Heading />

      {isSearchAndSortVisible || isSearchOrFilterApplied ? (
        <>
          <View>
            <View style={styles.searchSortSelectWrapper}>
              <View
                style={[styles.sortSelect, styles.firstSortSelect, isMobile || midWidth ? { paddingRight: 15 } : null]}
              >
                <AppText>{translate.subjectList.subjectData.groupLabel}</AppText>
                <Select value={selectedGroup} items={groupOptions} onChange={handleGroupChange} />
              </View>
              <View
                style={[
                  styles.sortSelect,
                  styles.firstSortSelect,
                  midWidth && !isMobile ? { paddingRight: 20 } : { paddingRight: 10 },
                ]}
              >
                <AppText>{translate.users.userDetails.sort}</AppText>
                <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
              </View>
              {showStatusDropdown ? (
                <View
                  style={[
                    styles.sortSelect,
                    styles.secondSortSelect,
                    styles.statusSelect,
                    isMobile || midWidth ? { paddingRight: 15 } : null,
                  ]}
                >
                  <AppText>{translate.subjectStatus.modalTitle}</AppText>
                  <Select value={subjectStatus} items={subjectStatusOptions} onChange={handleSubjectStatusChange} />
                </View>
              ) : null}
              <View style={styles.searchBarContainer}>
                <Input
                  value={searchStr}
                  onChangeText={handleSearchStr}
                  ref={inputRef}
                  onFocus={() => setIsSearchInputFocused(true)}
                  onBlur={() => setIsSearchInputFocused(false)}
                  // style={styles.searchBar}
                  icon="search"
                  size="small"
                  iconPosition="right"
                  placeholder={translate.users.userDetails.searchByName}
                  debounce={{
                    milliseconds: 1000,
                    callback: handleSearchStr,
                    textValue: searchStr,
                  }}
                />
              </View>
            </View>
          </View>
          <PageNavButtons
            isPrevPageButtonVisible={currentPage !== 0}
            isNextPageButtonVisible={subjects && subjects.length === 25}
            prevPageAction={goToPreviousPage}
            nextPageAction={goToNextPage}
            style={styles.pageNavButtons}
          />
        </>
      ) : null}
      {subjects ? (
        <UserList
          noUsersMessage={emptyListMessage}
          displayItems={subjects.map((subject) => ({
            onSelect: () => handleSelect(subject),
            key: subject.id,
            components: [
              <Name name={subject.fullName} />,
              <StatusLabel memberships={subject.subjectMemberships} />,
              <Groups groups={subject.groups} />,
            ],
          }))}
        />
      ) : (
        <ActivityIndicator style={styles.activityIndicator} />
      )}
    </View>
  );
};
