import { CaseItem } from '../../../../fragments/__generated__/CaseItem';
import React from 'react';
import { CaseLogContextSubject } from './CaseLogs.interface';
import { CaseLogItem } from './CaseLogItem/CaseLogItem';

export interface CaseLogItemsProps {
  items: CaseItem[];
  subject: CaseLogContextSubject;
  organizationName: string;
}

export const CaseLogItems: React.FC<CaseLogItemsProps> = ({ items, subject, organizationName }) => (
  <>
    {items.map((item) => (
      <CaseLogItem key={item.id} subject={subject} item={item} organizationName={organizationName} />
    ))}
  </>
);
