import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { AppText } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppModal } from '../../../../uiComponents';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';

interface RowValidationError {
  rowNumber: number | null;
  reason: string | null;
}

interface ErrorsModalProps {
  title: string;
  rowValidationErrors?: RowValidationError[];
  showErrorsModal: boolean;
  setShowErrorsModal: (boolean) => void;
}

export const ErrorsModal: React.FC<ErrorsModalProps> = (props) => {
  const { rowValidationErrors, title, showErrorsModal, setShowErrorsModal } = props;

  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    rowValidationErrorsTitle: {
      textAlign: 'center',
      marginBottom: 30,
    },
    rowValidationError: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      marginLeft: 50,
    },
    rowValidationRow: {
      width: 300,
    },
  });

  return (
    <AppModal title={title} mode="dialog" visible={showErrorsModal} onDismiss={() => setShowErrorsModal(false)}>
      <View>
        <AppText style={styles.rowValidationErrorsTitle}>
          {translate.superAdmin.importData.importErrorsModalText}
        </AppText>
        {rowValidationErrors
          ? rowValidationErrors.map((error, index) => (
              <View style={styles.rowValidationError} key={index}>
                <AppText style={styles.rowValidationRow} font="labelDefault" color="grayDark">
                  {`${translate.superAdmin.importData.row} ${error.rowNumber}:`}
                </AppText>
                <AppText>{error.reason}</AppText>
              </View>
            ))
          : null}
      </View>
    </AppModal>
  );
};
