import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, TextInput, Linking, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  ActivityIndicator,
  AppScrollView,
  AppText,
  KPI,
  Select,
  UserList,
  PageNavButtons,
  Input,
  AppLink,
  SVG,
  AppButton,
  AppModal,
} from '../../../uiComponents';
import { TrainingChrome } from '../TrainingChrome';
import { TrainingNavType } from '../Training.interface';
import { useTranslations } from '../../../i18n/useTranslations';
import { useGetEducationReport } from '../hooks/useGetEducationReport';
import { useCurrentUser } from '../../../shared/CurrentUser/useCurrentUser';
import { CurrentUser_roles } from '../../../fragments/__generated__/CurrentUser';
import { useIsMobile } from '../../../hooks/ui/useResponsive';
import { Name } from '../../TeamRoster/Columns';
import { Roles } from '../../Admin/screens/ManageUsers/Columns';
import { Role } from '../../../types/globalTypes';
import { SortValue, useSearchAndSort } from '../../Admin/screens/ManageGroups/GroupDetails/hooks/useSearchAndSort';
import { useGetEducationCompletion } from '../hooks/useGetEducationCompletion';
import { useColors } from '../../../shared/Ui';
import { useNudgeNotReviewedEducation } from '../hooks/useNudgeNotReviewedEducation';
import { useNudgeParticipantsNotReviewedEducation } from '../hooks/useNudgeParticipantsNotReviewedEducation';
import { isApiError } from '../../../utils/isApiError';

export interface nudgeUsersModal {
  visible: boolean;
  text: string;
}

export const Education: React.FC = (props) => {
  const [organizations, setOrganizations] = useState<CurrentUser_roles[]>([]);
  const [nudgeAlert, setNudgeAlert] = useState<nudgeUsersModal>({ visible: false, text: '' });
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<'reviewed' | 'notReviewed' | 'all'>('all');

  const navigation = useNavigation<TrainingNavType<'LoggedIn_Education_Report'>>();
  const { currentUser } = useCurrentUser();

  const { translate, translateWithVars } = useTranslations();
  const isMobile = useIsMobile();
  const colors = useColors();
  const [nudgeUser] = useNudgeNotReviewedEducation({
    fetchPolicy: 'no-cache', // TODO: change policy once https://github.com/apollographql/apollo-client/issues/6636 is resolved
    onCompleted: (res) => {
      const total = !isApiError(res.nudgeNotReviewedEducation) ? res.nudgeNotReviewedEducation.total : 0;
      setNudgeAlert({ visible: true, text: translateWithVars(translate.users.nudgedAlert, { total }) });
    },
  });

  const [nudge] = useNudgeParticipantsNotReviewedEducation({
    fetchPolicy: 'no-cache', // TODO: change policy once https://github.com/apollographql/apollo-client/issues/6636 is resolved
    onCompleted: (res) => {
      const total = !isApiError(res.nudgeParticipantsNotReviewedEducation)
        ? res.nudgeParticipantsNotReviewedEducation.total
        : 0;
      setNudgeAlert({ visible: true, text: translateWithVars(translate.users.nudgedAlert, { total }) });
    },
  });
  useEffect(() => {
    const allOrganizations = currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.roles : [];
    const organizationsWherePDP = allOrganizations.filter(
      (organization) => organization.role === Role.PRIMARY_DESIGNATED_PERSON
    );
    setOrganizations(organizationsWherePDP);
    if (organizationsWherePDP.length > 0) setSelectedOrganization(organizationsWherePDP[0].organizationId);
  }, [currentUser]);

  const handleGoBack = () => {
    navigation.navigate('LoggedIn_Training', {
      screen: 'LoggedIn_Training_Index',
    });
  };

  const inputRef = useRef<TextInput>(null);

  const {
    handleSortChange,
    handleSearchStr,
    selectedSortType,
    searchStr,
    sortCategories,
    setIsSearchInputFocused,
    roleFilter,
    roleFilterOptions,
    handleRoleFilterChange,
  } = useSearchAndSort(inputRef);

  let useGetEducationReportVariables: {
    organizationId: string;
    paginationArgs: {
      skip: number;
      take: number;
      orderBy: string;
      direction: string;
    };
    status?: boolean;
    searchStr?: string;
    role?: string;
  } = {
    organizationId: selectedOrganization,
    paginationArgs: {
      skip: currentPage,
      take: 25,
      orderBy: selectedSortType.split('_')[0],
      direction: selectedSortType.split('_')[1],
    },
  };

  if (selectedStatus !== 'all') {
    useGetEducationReportVariables = {
      ...useGetEducationReportVariables,
      status: selectedStatus === 'reviewed' ? true : false,
    };
  }

  if (searchStr) {
    useGetEducationReportVariables = {
      ...useGetEducationReportVariables,
      searchStr,
    };
  }

  if (roleFilter !== 'All') {
    useGetEducationReportVariables = {
      ...useGetEducationReportVariables,
      role: roleFilter,
    };
  }

  const { data: educationReport } = useGetEducationReport({
    skip: !selectedOrganization,
    variables: useGetEducationReportVariables,
  });
  const { data: EducationCompletion } = useGetEducationCompletion(selectedOrganization, {
    skip: !selectedOrganization,
  });

  const handleOrganizationChange = (_organizationId: string) => {
    setSelectedOrganization(_organizationId);
  };

  const organizationItems = organizations.map(({ organizationId, organizationName }) => ({
    key: organizationId,
    value: organizationId,
    label: organizationName,
  }));

  const calculateEducationPercentage = () => {
    if (EducationCompletion && EducationCompletion.educationCompletion.__typename === 'EducationCompletion') {
      if (EducationCompletion.educationCompletion.totalNumberOfUsers > 0) {
        return `${Math.round(
          (100 * EducationCompletion.educationCompletion.numberOfUsersCompleted) /
            EducationCompletion.educationCompletion.totalNumberOfUsers
        )}%`;
      } else {
        return '0%';
      }
    } else {
      return 'Error';
    }
  };

  const educationCompletionStatuses = [
    { label: translate.searchAndSort.all, key: 'all', value: 'all' },
    { label: translate.education.label.completed, key: 'reviewed', value: 'reviewed' },
    { label: translate.education.label.notCompleted, key: 'not reviewed', value: 'not reviewed' },
  ];

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 10,
    },
    selectorsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      justifyContent: !isMobile ? 'flex-start' : 'center',
    },
    selectorContainer: {
      marginRight: !isMobile ? 40 : 0,
      marginTop: isMobile ? 20 : 0,
      width: isMobile ? '100%' : 400, // change width to 50% once we have a selector for the education program instead of just Making Head Way
    },
    selector: {
      width: isMobile ? '100%' : 400,
    },
    disclaimerText: {
      flex: 1,
    },
    kpiValue: {
      fontSize: 60,
      paddingTop: !isMobile ? 20 : 50,
      paddingBottom: !isMobile ? 20 : 0,
    },
    educationCompletedContainer: {
      marginBottom: 40,
    },
    reportUsersTitle: {
      marginBottom: 15,
    },
    report: {
      width: !isMobile ? 600 : '100%',
      marginVertical: 10,
      paddingVertical: 8,
    },
    reportInner: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: !isMobile ? 550 : '90%',
      alignSelf: 'center',
    },
    reportName: {
      marginRight: 50,
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchAndSortContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'flex-end',
      justifyContent: isMobile ? 'flex-start' : 'space-between',
      marginBottom: 10,
    },
    sortContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
      width: isMobile ? '100%' : '60%',
    },
    sortSelect: {
      width: '50%',
      flexGrow: 1,
      flexShrink: 0,
      flex: 1,
    },
    searchContainer: {
      width: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
      top: 10,
    },
    pageNavButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    participationStatus: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: isMobile ? 'flex-end' : 'flex-start',
      maxWidth: 200,
    },
    reload: {
      marginLeft: 20,
    },
    button: {
      marginTop: isMobile ? 20 : 0,
    },
    buttonsAndPageNavigation: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
      flexWrap: isMobile ? 'wrap' : 'nowrap',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
    },
  });

  const organizationSelector = (
    <Select<string> value={selectedOrganization} onChange={handleOrganizationChange} items={organizationItems} />
  );

  const isEducationReportAvailable =
    educationReport && educationReport.educationReport.__typename === 'GetEducationReportResult';

  const educationReportResult =
    educationReport && educationReport.educationReport.__typename === 'GetEducationReportResult'
      ? educationReport.educationReport.EducationReportResult
      : [];

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };
  const handleNudge = (userId: string, organizationId: string) => {
    const organization = organizations.find(
      ({ organizationId: _organizationId }) => _organizationId === organizationId
    );
    const organizationName = organization?.organizationName ? organization?.organizationName : '';
    nudgeUser({
      variables: {
        input: {
          userId,
          organizationName,
        },
      },
    });
  };

  const handleNudgePress = () => {
    const organization = organizations.find(
      ({ organizationId: _organizationId }) => _organizationId === selectedOrganization
    );
    const organizationName = organization?.organizationName ? organization?.organizationName : '';

    nudge({
      variables: {
        input: {
          organizationId: selectedOrganization,
          organizationName,
        },
      },
    });
  };
  const ParticipationStatus: React.FC<{ accepted: boolean | null; userId: string }> = ({ accepted, userId }) => {
    return (
      <View style={styles.participationStatus}>
        <AppText font="bodyDefault" color={accepted ? 'green' : 'red'}>
          {accepted ? translate.education.label.completed : translate.education.label.notCompleted}
        </AppText>
        {!accepted ? (
          <TouchableOpacity onPress={() => handleNudge(userId, selectedOrganization)} style={styles.reload}>
            <SVG color={colors.blue} height={isMobile ? 18 : 24} width={isMobile ? 18 : 24} image="reload" />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  const noSearchOrFiltersApplied = selectedStatus === 'all' && roleFilter === 'All' && !searchStr;

  return (
    <>
      <AppModal
        mode="dialog"
        title={nudgeAlert.text}
        visible={nudgeAlert.visible}
        onDismiss={() => setNudgeAlert({ visible: false, text: '' })}
        buttons={{
          ok: (
            <AppButton onPress={() => setNudgeAlert({ visible: false, text: '' })}>
              {translate.admin.manageOrganization.ok}
            </AppButton>
          ),
        }}
      ></AppModal>

      <TrainingChrome titleText={translate.education.educationReport} onBack={handleGoBack} showHamburger={false}>
        <AppScrollView showsVerticalScrollIndicator={false} style={styles.container}>
          <View style={styles.selectorsContainer}>
            {organizations.length > 1 ? (
              <View style={styles.selectorContainer}>
                <AppText>{translate.profile.term.organization}</AppText>
                <View style={[styles.selector, { paddingRight: isMobile ? 15 : 0 }]}>{organizationSelector}</View>
              </View>
            ) : null}
          </View>
          <View style={styles.educationCompletedContainer}>
            {educationReport ? (
              <KPI value={calculateEducationPercentage()} label={translate.education.label.usersEducated} />
            ) : (
              <ActivityIndicator style={styles.activityIndicator} />
            )}
          </View>
          <View style={styles.searchAndSortContainer}>
            <View style={styles.sortContainer}>
              <View style={[styles.sortSelect, { paddingRight: 15 }]}>
                <AppText>{translate.subjectStatus.modalTitle}</AppText>
                <Select<string>
                  value={selectedStatus}
                  items={educationCompletionStatuses}
                  onChange={handleStatusChange}
                />
              </View>
              <View style={[styles.sortSelect, { paddingRight: isMobile ? 0 : 15 }]}>
                <AppText>{translate.users.userDetails.sort}</AppText>
                <Select<SortValue> value={selectedSortType} items={sortCategories} onChange={handleSortChange} />
              </View>
            </View>

            <View style={styles.sortContainer}>
              <View style={[styles.sortSelect, { paddingRight: 15 }]}>
                <AppText>{translate.users.userDetails.role}</AppText>
                <Select<string> value={roleFilter} items={roleFilterOptions} onChange={handleRoleFilterChange} />
              </View>
              <View style={styles.searchContainer}>
                <Input
                  icon="search"
                  ref={inputRef}
                  onFocus={() => setIsSearchInputFocused(true)}
                  onBlur={() => setIsSearchInputFocused(false)}
                  placeholder={translate.users.userDetails.searchByName}
                  type="name"
                  debounce={{
                    milliseconds: 1000,
                    callback: handleSearchStr,
                    textValue: searchStr,
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.buttonsAndPageNavigation}>
            <View style={styles.buttonContainer}>
              <AppButton
                size={isMobile ? 'extraSmall' : 'small'}
                style={{ marginRight: 10, ...styles.button }}
                onPress={handleNudgePress}
              >
                {translate.education.label.nudgeAllNotReviewed}
              </AppButton>
            </View>
            <PageNavButtons
              isPrevPageButtonVisible={currentPage !== 0}
              isNextPageButtonVisible={educationReportResult && educationReportResult.length === 25}
              prevPageAction={goToPreviousPage}
              nextPageAction={goToNextPage}
            />
          </View>
          {isEducationReportAvailable ? (
            <UserList
              noUsersMessage={noSearchOrFiltersApplied ? translate.training.noUsers : translate.training.noUsersFound}
              displayItems={educationReportResult.map((user) => ({
                key: user.id,
                components: [
                  <Name name={user.user} />,
                  <Roles roles={user.roles.map((role) => Role[role])} />,
                  <></>,
                  <ParticipationStatus userId={user.id} accepted={user.completed} />,
                ],
              }))}
            />
          ) : (
            <ActivityIndicator style={styles.activityIndicator} />
          )}
        </AppScrollView>
      </TrainingChrome>
    </>
  );
};
