/* Single tile
=============================== */

import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileOnProtocolProps {
  onPress: () => void;
  disabled?: boolean;
  badge?: number;
}

export const TileOnProtocol: React.FC<TileOnProtocolProps> = ({ onPress, disabled, badge }) => {
  const { translate } = useTranslations();

  return (
    <BaseTile
      backgroundColor="orange"
      labelColor="white"
      label={translate.subjectStatus.onProtocol}
      onPress={onPress}
      image="tile/protocols"
      disabled={disabled}
      badge={badge}
    />
  );
};
