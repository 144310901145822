import { useRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { LoggedInRouteProp } from '../LoggedIn.interface';
import { AdminParamList } from './Admin.interface';
import { useAdminRoutes } from './hooks/useAdminRoutes';
import { AdminContextProvider } from './AdminContext';
import { ImportDataContextProvider } from '../SuperAdmin/ImportDataContext';
import { RouteGuard } from '../shared/RouteGuard';
import { Role } from '../../types/globalTypes';

const AdminNav = createStackNavigator<AdminParamList>();
const routes = useAdminRoutes();

export interface AdminNavigatorProps {}

export const AdminNavigator: React.FC<AdminNavigatorProps> = () => {
  const route = useRoute<LoggedInRouteProp<'LoggedIn_Admin'>>();
  return (
    <AdminNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component: Component, name }) => (
        <AdminNav.Screen
          key={name}
          name={name}
          children={() => (
            <RouteGuard acceptedRoles={[Role.ADMIN]}>
              <ImportDataContextProvider>
                <AdminContextProvider organizationId={route.params?.organizationId} children={<Component />} />
              </ImportDataContextProvider>
            </RouteGuard>
          )}
        />
      ))}
    </AdminNav.Navigator>
  );
};
