import { useRef, useEffect, useState } from 'react';
import { View, Linking, StyleSheet, Platform } from 'react-native';
import {
  CaseItemRfaProtocolResult,
  CaseItemRfaProtocolResult_steps,
  CaseItemRfaProtocolResult_steps_StepChallengeReport,
  CaseItemRfaProtocolResult_steps_StepSymptomReport,
} from '../../../../../../fragments/__generated__/CaseItemRfaProtocolResult';
import { CaseLogContextSubject } from '../../CaseLogs.interface';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppButton, AppScrollView, AppText } from '../../../../../../uiComponents';
import { useDateFormat } from '../../../../../../hooks/ui/useDateFormat';
import { IncidentTypeEnum, RadioButtonResponseEnum } from '../../../../../../types/globalTypes';
import { useIsMobile } from '../../../../../../hooks/ui/useResponsive';
import { useReactToPrint } from 'react-to-print';
import CAPT from '../../../../../../assets/svg/CAPT.svg';

export interface IncidentReportDetailsProps {
  item: CaseItemRfaProtocolResult;
  subject: CaseLogContextSubject;
  organizationName: string;
}

interface FieldProps {
  label: string;
  content?: RadioButtonResponseEnum | string | null;
  subContent?: string | null;
  isHorizontal?: boolean;
}

export const IncidentReportDetails: React.FC<IncidentReportDetailsProps> = ({ item, subject, organizationName }) => {
  const { translate } = useTranslations();
  const { formatDateAndTime, formatDate } = useDateFormat();
  const isMobile = useIsMobile();
  const [isPrinting, setIsPrinting] = useState(false);

  const printableRef = useRef<View>(null);
  const promiseResolveRef = useRef<any>(null);

  const handleOpenDocument = () => {
    if (item.attachedDocument?.downloadUri) {
      Linking.openURL(item.attachedDocument.downloadUri);
    }
  };

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        if (Platform.OS === 'web') {
          document.title = `Incident Report - ${subject.fullName}`;
        }
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const styles = StyleSheet.create({
    printHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      marginLeft: 40,
    },
    heading: {
      marginTop: 30,
    },
    symptoms: {
      marginRight: 80,
    },
    rowOfFields: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      maxWidth: '100%',
    },
    incidentDescription: {
      marginTop: 20,
    },
    stepsContainer: {
      maxWidth: '100%',
      flexWrap: 'wrap',
    },
    steps: {
      width: isMobile ? '100%' : '40%',
    },
    stepResponses: {
      maxWidth: '100%',
    },
    fieldLabel: {
      marginRight: 20,
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      marginTop: 20,
      maxWidth: '100%',
    },
    fieldContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    author: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
    },
    reportForm: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
    },
    printFormat: {
      marginVertical: 50,
      marginHorizontal: 70,
    },
  });

  const redFlags = item.redFlag.flags.filter((flag) => flag.result === 'PRESENT').map((flag) => flag.value);

  const getSymptoms = (step: CaseItemRfaProtocolResult_steps_StepSymptomReport) => {
    const symptoms = step.symptoms.filter((symptom) => symptom.result === 'PRESENT').map((symptom) => symptom.value);
    return symptoms.length > 0 ? symptoms.join(', ') : translate.label.none;
  };

  const getIncorrectResponses = (step: CaseItemRfaProtocolResult_steps_StepChallengeReport) => {
    const incorrectResponses = step.challenges
      .filter((challenge) => challenge.result === 'INCORRECT')
      .map((challenge) => challenge.value);
    return incorrectResponses.length > 0 ? incorrectResponses.join(', ') : translate.label.none;
  };

  const getIncidentType = (incidentType: IncidentTypeEnum) => {
    const incidentTypes = {
      [IncidentTypeEnum.COLLISION_WITH_GROUND]: translate.incidentReport.incidentTypes.collisionWithGround,
      [IncidentTypeEnum.COLLISION_WITH_MOVING_OBJECT]: translate.incidentReport.incidentTypes.collisionWithMovingObject,
      [IncidentTypeEnum.COLLISION_WITH_PERSON]: translate.incidentReport.incidentTypes.collisionWithPerson,
      [IncidentTypeEnum.COLLISION_WTH_STATIC_OBJECT]: translate.incidentReport.incidentTypes.collisionWithStaticObject,
      [IncidentTypeEnum.MOTOR_VEHICLE_COLLISION]: translate.incidentReport.incidentTypes.motorVehicleCollision,
      [IncidentTypeEnum.MULTIPLE]: translate.subjectStatus.complex,
      [IncidentTypeEnum.OTHER]: translate.superAdmin.createOrganization.sports.other,
      [IncidentTypeEnum.UNKNOWN]: translate.incidentReport.incidentTypes.unknown,
    };
    return incidentTypes[incidentType] ? incidentTypes[incidentType] : '';
  };

  const Field: React.FC<FieldProps> = ({ label, content, subContent, isHorizontal = false }) => {
    return (
      <View style={[styles.fieldContainer, isHorizontal ? { marginRight: 25 } : null]}>
        <AppText font="labelDefault" color="navyDark" style={styles.fieldLabel}>
          {`${label}:`}
        </AppText>
        <View style={styles.fieldContent}>
          <AppText
            style={
              content === 'YES' ||
              content === 'NO' ||
              content === 'DONT_KNOW' ||
              content === translate.superAdmin.createOrganization.sports.other ||
              content === translate.subjectStatus.complex
                ? { fontWeight: 'bold' }
                : null
            }
          >
            {content
              ? `${
                  content === 'YES' || content === 'NO' || content === 'DONT_KNOW'
                    ? translate.answers[content]
                    : content
                }${
                  content === 'YES' ||
                  content === translate.superAdmin.createOrganization.sports.other ||
                  content === translate.subjectStatus.complex
                    ? ': '
                    : ''
                }`
              : translate.incidentReport.notReported}
          </AppText>
          <AppText>{subContent}</AppText>
        </View>
      </View>
    );
  };

  const Content: React.FC = () => {
    return (
      <View ref={printableRef} style={isPrinting ? styles.printFormat : null}>
        {isPrinting ? (
          <View style={styles.printHeader}>
            <CAPT width={150} height={55} />
            <AppText font="header1" style={styles.title}>
              {translate.protocol.incidentReport}
            </AppText>
          </View>
        ) : null}
        <AppText font="header2" style={styles.heading}>
          {formatDateAndTime(new Date(item.incidentDate || item.createdDate))}
        </AppText>
        <AppText font="header3" style={styles.heading}>
          {translate.users.roles.subject}
        </AppText>

        <View style={styles.rowOfFields}>
          <Field label={translate.label.name} content={subject.fullName} isHorizontal={!isMobile} />
          <Field
            label={translate.incidentReport.birthDate}
            content={
              subject.birthday ? formatDate(new Date(parseInt(subject.birthday.toString())), 'MMMM dd yyyy') : null
            }
            isHorizontal={!isMobile}
          />
        </View>

        <AppText font="header3" style={styles.heading}>
          {translate.profile.term.organization}
        </AppText>
        <View style={styles.rowOfFields}>
          <Field label={translate.label.name} content={organizationName} isHorizontal={!isMobile} />
          <Field
            label={translate.subjectList.subjectData.groupLabel}
            content={item.groupName ? item.groupName : translate.label.none}
          />
        </View>

        <AppText font="header3" style={styles.heading}>
          {translate.caseLog.caseItemRfaProtocolResult.incidentDescription}
        </AppText>
        <AppText style={styles.incidentDescription}>{item.incidentDescription}</AppText>
        <View style={styles.rowOfFields}>
          <Field
            label={translate.caseLog.caseItemRfaProtocolResult.outcome}
            content={
              item.result === 'REMOVE'
                ? translate.incidentReport.removedFromActivity
                : translate.incidentReport.resumedActivity
            }
            isHorizontal={!isMobile}
          />
          <Field label={translate.incidentReport.location} content={item.location} />
        </View>

        <Field
          label={translate.incidentReport.incidentTypeLowerCase}
          content={item.type ? getIncidentType(item.type) : null}
          subContent={item.typeDetails}
        />

        {!item.isSkippedAssessment ? (
          <View>
            <AppText font="header3" style={styles.heading}>
              {translate.protocol.redFlags}
            </AppText>
            <AppText style={styles.fieldContainer}>
              {redFlags.length > 0 ? redFlags.join(', ') : translate.label.none}
            </AppText>
          </View>
        ) : null}

        {!item.isSkippedAssessment ? (
          <View style={[styles.rowOfFields, styles.stepsContainer, isPrinting ? { flexDirection: 'column' } : null]}>
            {item.steps.map((step: CaseItemRfaProtocolResult_steps) => (
              <View key={step.id} style={styles.steps}>
                <AppText font="header3" style={styles.heading}>
                  {step.name}
                </AppText>
                <AppText style={[styles.fieldContainer, styles.stepResponses]}>
                  {step.__typename === 'StepSymptomReport'
                    ? getSymptoms(step)
                    : `${translate.incidentReport.incorrectResponses}: ${getIncorrectResponses(step)}`}
                </AppText>
              </View>
            ))}
          </View>
        ) : null}

        <AppText font="header3" style={styles.heading}>
          {translate.incidentReport.other}
        </AppText>
        <Field
          label={translate.incidentReport.otherSymptoms}
          content={item.hasOtherSymptoms}
          subContent={item.hasOtherSymptomsDetails}
        />
        <Field
          label={translate.incidentReport.otherInjuries}
          content={item.hasOtherInjuries}
          subContent={item.hasOtherInjuriesDetails}
        />

        <AppText font="header3" style={styles.heading}>
          {translate.incidentReport.concussionHistory.title}
        </AppText>
        <Field
          label={translate.incidentReport.previousConcussions}
          content={item.hasPreviousConcussion}
          subContent={item.hasPreviousConcussionDetails}
        />

        <AppText font="header3" style={styles.heading}>
          {translate.incidentReport.medicalHistory.title}
        </AppText>
        <Field
          label={translate.incidentReport.existingMedicalConditions}
          content={item.hasMedicalConditions}
          subContent={item.hasMedicalConditionsDetails}
        />

        <Field
          label={translate.incidentReport.existingMedications}
          content={item.hasMedication}
          subContent={item.hasMedicationDetails}
        />
        <AppText font="header3" style={styles.heading}>
          {translate.incidentReport.author}
        </AppText>

        <View style={styles.author}>
          <Field label={translate.label.name} content={item.actor.fullName} isHorizontal={!isMobile} />
          <Field label={translate.account.yourDetails.email} content={item.actor.email} isHorizontal={!isMobile} />
          <Field label={translate.incidentReport.phone} content={item.actor.phoneNumber} isHorizontal={!isMobile} />
        </View>
        <AppText font="header3" style={styles.heading}>
          {`${translate.incidentReport.reportSubmitted}: ${formatDate(new Date(item.createdDate))}`}
        </AppText>
      </View>
    );
  };

  return (
    <AppScrollView>
      {Platform.OS === 'web' ? (
        <AppButton icon="printer" onPress={handlePrint}>
          {translate.incidentReport.printReport}
        </AppButton>
      ) : null}

      <Content />

      {item.attachedDocument ? (
        <View style={styles.reportForm}>
          <AppText font="header3" style={[styles.heading, { marginRight: 30 }]}>
            {translate.incidentReport.form}
          </AppText>
          <AppButton onPress={handleOpenDocument} style={{ marginTop: 30 }}>
            {translate.caseLog.caseItemRfaProtocolResult.download}
          </AppButton>
        </View>
      ) : null}
    </AppScrollView>
  );
};
