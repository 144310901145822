import React, { useEffect, useState } from 'react';
import { CurrentUserContext } from './CurrentUserContext';
import { CurrentUser as CurrentUserType } from '../../fragments/__generated__/CurrentUser';
import { UnverifiedCurrentUser as UnverifiedCurrentUserType } from '../../fragments/__generated__/UnverifiedCurrentUser';
import { useCurrentUserLazyQuery } from './hooks/useCurrentUserLazyQuery';

export interface CurrentUserProps {
  isLoggedIn?: boolean;
  isReady?: boolean;
  children: React.ReactNode;
}

export const CurrentUser: React.FC<CurrentUserProps> = ({ children, isLoggedIn, isReady }) => {
  const [initialized, setInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUserType | UnverifiedCurrentUserType | undefined>();

  const [getCurrentUser] = useCurrentUserLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.currentUser.__typename !== 'ApiError') {
        setCurrentUser(data.currentUser);
        setInitialized(true);
      } else {
        throw new Error('Could not set currentUser.');
      }
    },
  });

  useEffect(() => {
    if (isReady) {
      if (isLoggedIn) {
        getCurrentUser();
      } else {
        setCurrentUser(undefined);
        setInitialized(true);
      }
    }
  }, [isLoggedIn, isReady]);

  if (!initialized) {
    return null;
  }

  return <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>{children}</CurrentUserContext.Provider>;
};
