import { gql } from '@apollo/client';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { useMutation, UseMutationOptions } from '../../../../../hooks/useMutation';
import { RemoveUserFromGroup, RemoveUserFromGroupVariables } from './__generated__/RemoveUserFromGroup';

const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveUserFromGroup($groupId: ID!, $userId: ID!, $role: Role!, $organizationId: ID!) {
    removeUserFromGroup(groupId: $groupId, userId: $userId, role: $role, organizationId: $organizationId) {
      ... on GroupUserInfo {
        groupId
        userId
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;
// data from this mutation is refetched after it is done

export const useRemoveUserFromGroup = (
  options?: UseMutationOptions<RemoveUserFromGroup, RemoveUserFromGroupVariables>
) => useMutation<RemoveUserFromGroup, RemoveUserFromGroupVariables>(REMOVE_USER_FROM_GROUP, options);
