import React, { useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { View, StyleSheet, Animated, Pressable } from 'react-native';
import { AppText } from '../../../uiComponents';
import { useColors } from '../../Ui';
import { useCurrentUser } from '../../CurrentUser/useCurrentUser';
import { useTranslations } from '../../../i18n/useTranslations';
import { MenuItem } from './useHamburgerMenuProps';

interface HamburgerMenuProps {
  setShowHamburgerMenu: Dispatch<SetStateAction<boolean>>;
  type: 'hamburgerMenuItems' | 'languageMenuItems';
  handlePress: (any) => void;
  menuItems: MenuItem[];
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ setShowHamburgerMenu, type, menuItems, handlePress }) => {
  const colors = useColors();
  const { currentUser } = useCurrentUser();
  const { translate } = useTranslations();

  if (!currentUser || currentUser.__typename !== 'CurrentUser') return null;

  const slideAnim = useRef(new Animated.Value(0)).current;

  const slideDown = () => {
    Animated.timing(slideAnim, {
      toValue: type === 'hamburgerMenuItems' ? 30 : 37,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    slideDown();
  }, []);

  const animatedStyles = {
    transform: [
      {
        translateY: slideAnim,
      },
    ],
  };

  const styles = StyleSheet.create({
    hamburgerMenu: {
      backgroundColor: colors.navyDark,
      width: menuItems.length * 60,
      position: 'absolute',
      top: 0,
      right: 0,
      borderRadius: 15,
      paddingVertical: 18,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    hamburgerMenuItem: {
      height: 45,
      paddingLeft: 22,

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });

  return (
    <Animated.View style={animatedStyles}>
      <View style={styles.hamburgerMenu}>
        {menuItems.map((item: MenuItem) =>
          item.name === translate.navigation.superAdmin && !currentUser.isSuperAdmin ? (
            <></>
          ) : (
            <Pressable
              key={item.name}
              onPress={() => {
                item.type === 'language'
                  ? handlePress({ variables: { language: item.value } })
                  : handlePress(item.value);
                setShowHamburgerMenu(false);
              }}
              style={({ pressed }) => [styles.hamburgerMenuItem, { opacity: pressed ? 0.5 : 1 }]}
            >
              <AppText font="labelDefault" color="white">
                {item.name}
              </AppText>
            </Pressable>
          )
        )}
      </View>
    </Animated.View>
  );
};
