import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../../../hooks/useMutation';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { SubmitMedicalAssessment, SubmitMedicalAssessmentVariables } from './__generated__/SubmitMedicalAssessment';

export const SUBMIT_MEDICAL_ASSESSMENT = gql`
  mutation SubmitMedicalAssessment($input: SubmitMedicalAssessmentInput!) {
    submitMedicalAssessment(input: $input) {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${RecoveryPlanFragment}
  ${ApiErrorFragment}
`;

export const useSubmitMedicalAssessment = (
  options?: MutationHookOptions<SubmitMedicalAssessment, SubmitMedicalAssessmentVariables>
) => useMutation<SubmitMedicalAssessment, SubmitMedicalAssessmentVariables>(SUBMIT_MEDICAL_ASSESSMENT, options);
