import * as React from 'react';
import { BaseStage } from './BaseStage/BaseStage';
import { RecoveryPlanStageSignOff as RecoveryPlanStageSignOffType } from '../../../fragments/__generated__/RecoveryPlanStageSignOff';

export interface RecoveryPlanStageSignoffProps {
  onPress?: (id: string) => void;
  stage: RecoveryPlanStageSignOffType;
}

export const RecoveryPlanStageSignoff: React.FC<RecoveryPlanStageSignoffProps> = ({ stage, onPress }) => {
  return (
    <BaseStage
      key={stage.id}
      id={stage.id}
      title={stage.name}
      organizationName={stage.organizationName}
      onPress={onPress}
      protocolType={stage.protocolType}
      state={stage.state}
    />
  );
};
