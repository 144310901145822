import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, SafeAreaView, PanResponder, Animated, ViewStyle } from 'react-native';
import { Icon, IconName } from '../Icon/Icon';
import { AppText } from '../AppText/AppText';
import { ShadowView } from '../ShadowView/ShadowView';
import { ColorKeys, useColors } from '../../shared/Ui';
import { useIsMobile } from '../../hooks/ui/useResponsive';

interface ErrorBubbleProps {
  message?: string;
  icon?: IconName;
  backgroundColor?: string;
  textColor?: ColorKeys;
  onPress?: () => void;
  onDismiss?: () => void;
  interactive?: boolean;
  style?: ViewStyle;
}

export const ErrorBubble: React.FC<ErrorBubbleProps> = (props) => {
  const {
    message = '',
    icon,
    backgroundColor = 'navyDark',
    textColor = 'white',
    onPress,
    onDismiss,
    interactive = true,
    style,
  } = props;

  const colors = useColors();
  const isMobile = useIsMobile();

  const [visible, setVisible] = useState(true);
  const translate = new Animated.ValueXY({ x: 0, y: 0 });
  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (evt, gestureState) => true,
    onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
    onPanResponderMove: Animated.event(
      [
        null,
        {
          dx: translate.x,
          dy: translate.y,
        },
      ],
      {
        useNativeDriver: false,
      }
    ),
    onPanResponderRelease: (evt, gestureState) => {
      if (gestureState.dy < -30) {
        setVisible(false);
        if (onDismiss) {
          onDismiss();
        }
      }
      Animated.spring(
        translate, // Auto-multiplexed
        { tension: 200, useNativeDriver: true, toValue: { x: 0, y: 0 } } // Back to zero
      ).start();
    },
  });

  const constrainedY = translate.y.interpolate({
    inputRange: [-100, 0, 10],
    outputRange: [-100, 0, 0],
  });

  const opacity = translate.y.interpolate({
    inputRange: [-40, 0],
    outputRange: [0, 1],
  });

  // styles

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: !isMobile ? 15 : 0,
      paddingBottom: 15,
      justifyContent: 'center',
    },
    violator: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingVertical: 7,
      paddingHorizontal: 12,
      backgroundColor: colors[backgroundColor],
      borderRadius: 999,
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      color: colors[textColor],
      marginLeft: -1,
      marginRight: 8,
    },
  });

  return (
    <View style={[styles.container, style]} pointerEvents={interactive ? 'box-none' : 'none'}>
      {visible && interactive ? (
        <Animated.View
          pointerEvents="box-none"
          style={{ opacity, transform: [{ translateY: constrainedY }] }}
          {...panResponder.panHandlers}
        >
          <SafeAreaView />
          <TouchableOpacity onPress={onPress} style={styles.violator}>
            {!!icon ? <Icon name={icon} size={16} style={styles.icon} /> : null}
            <AppText color={textColor} font="labelSmall">
              {message}
            </AppText>
          </TouchableOpacity>
        </Animated.View>
      ) : null}
      {visible && !interactive ? (
        <View>
          <SafeAreaView />
          <ShadowView style={styles.violator}>
            {!!icon ? <Icon name={icon} size={16} style={styles.icon} /> : null}
            <AppText color={textColor} font="labelSmall">
              {message}
            </AppText>
          </ShadowView>
        </View>
      ) : null}
    </View>
  );
};
