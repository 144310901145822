import React from 'react';
import { useRoute } from '@react-navigation/native';
import { ManageSeasonsRouteProp } from '../ManageSeasons.interface';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useGetSeasonById } from '../hooks/useGetSeasonById';
import { Chrome } from '../../../../shared/Chrome/Chrome';
import { useAdminContext } from '../../../hooks/useAdminContext';
import { UpdateSeason } from './UpdateSeason';
import { useNavigation } from '@react-navigation/native';
import { AdminNavType } from '../../../Admin.interface';
import { ActivityIndicator } from '../../../../../uiComponents';

export interface SeasonDetailsProps {}

export const SeasonDetails = () => {
  const { translate } = useTranslations();
  const { organizationId } = useAdminContext();
  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_ManageSeasons'>>();

  const route = useRoute<ManageSeasonsRouteProp<'LoggedIn_Admin_ManageSeasons_SeasonsDetails'>>();
  if (!route?.params?.seasonId) {
    throw new Error('Season ID is not set');
  }

  const { seasonId } = route.params;

  const { season } = useGetSeasonById(organizationId, seasonId);

  if (!season) {
    return <Chrome mode="title-card" content={<ActivityIndicator />} />;
  }

  const onBack = () =>
    navigation.navigate('LoggedIn_Admin_ManageSeasons', { screen: 'LoggedIn_Admin_ManageSeasons_Index' });

  return (
    <Chrome
      mode="title-card"
      titleText={season.name}
      navBarProps={{ onBack }}
      content={
        <UpdateSeason
          input={{
            name: season?.name || '',
            startDate: season?.startDate || undefined,
            endDate: season?.endDate || undefined,
          }}
          organizationId={organizationId}
          seasonId={seasonId}
        />
      }
    />
  );
};
