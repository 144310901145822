import React from 'react';
import { FieldArrayRenderProps } from 'formik';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppButton } from '../../../../../../uiComponents';
import { InviteGuardianFormData } from '../InvitationModal';
import { Guardian } from './Guardian';

export interface GuardiansProps extends FieldArrayRenderProps {
  guardians: InviteGuardianFormData[];
}

export const Guardians: React.FC<GuardiansProps> = ({ guardians, ...formikProps }) => {
  const newGuardian = { firstName: '', lastName: '', email: '' };
  const { translate } = useTranslations();
  const handleNewGuardian = () => formikProps.push(newGuardian);

  return (
    <>
      {guardians.map((_, index) => (
        <Guardian key={index} index={index} {...formikProps} />
      ))}
      <AppButton icon="plus" style={{ alignSelf: 'flex-start' }} onPress={handleNewGuardian}>
        {translate.users.addUser.addGuardian}
      </AppButton>
    </>
  );
};
