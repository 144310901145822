import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { FormikErrors, FormikTouched } from 'formik';
import { CheckboxOption, CheckboxOptionProps } from './CheckboxOption';
import { CheckboxOptionType } from './Checkbox.interface';
import { Pill } from '../Pill/Pill';

export interface CheckboxProps<T> {
  options?: CheckboxOptionType<T>[];
  onSelect?: (value: T[]) => void;
  value: T[];
  isMarkdown?: boolean;
  customComponent?: React.ComponentType<CheckboxOptionProps<T>>;
  style?: ViewStyle;
  touched?: FormikTouched<boolean | string | Date>;
  error?: FormikErrors<string | Date>;
}

export function Checkbox<T = any>({
  options = [],
  onSelect,
  value,
  customComponent,
  touched,
  error,
  ...props
}: CheckboxProps<T>) {
  const handleSelect = (selectedItem: CheckboxOptionType<T>) => {
    if (value.includes(selectedItem.value)) {
      if (onSelect) {
        onSelect(value.filter((currentValue) => currentValue !== selectedItem.value));
      }
    } else {
      if (onSelect) {
        onSelect([...value, selectedItem.value]);
      }
    }
  };

  const styles = StyleSheet.create({
    container: {},
    box: {},
    error: {
      marginRight: 'auto',
      zIndex: 1,
    },
  });

  const OptionComponent = customComponent || CheckboxOption;

  return (
    <View style={[styles.container, props.style]}>
      {options.map((option, index) => (
        <View style={styles.box} key={index}>
          <OptionComponent option={option} checked={value?.includes(option.value)} onPress={handleSelect} />
        </View>
      ))}
      {touched && error && error.toString ? (
        <Pill style={styles.error} icon="x" color="red" label={error as string} />
      ) : null}
    </View>
  );
}
