import { createContext, Dispatch, SetStateAction } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { AppSettingsQueryQuery } from './__generated__/AppSettingsQuery';
import { AppSettings } from './AppSettings.interface';

export type AppSettingsContextType = {
  appSettings: AppSettings;
  refetch: (variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<AppSettingsQueryQuery>>;
};

export const AppSettingsContext = createContext<AppSettingsContextType>([] as unknown as AppSettingsContextType);
