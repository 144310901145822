import { ChallengeResult, RfaProtocolResult, SymptomResult } from '../../../../../types/globalTypes';
import { AssessContextState } from '../../../Assess.interface';

export const setSuggestedResultFromState = (nextState: AssessContextState): AssessContextState => ({
  ...nextState,
  suggestedResult: nextState.steps.reduce<RfaProtocolResult>((accum, val) => {
    if (accum === RfaProtocolResult.REMOVE) {
      return accum;
    }

    if (val.type === 'symptom') {
      return val.result.some(({ result }) => result === SymptomResult.PRESENT)
        ? RfaProtocolResult.REMOVE
        : RfaProtocolResult.RESUME;
    } else {
      return val.results.some(({ result }) => result === ChallengeResult.INCORRECT)
        ? RfaProtocolResult.REMOVE
        : RfaProtocolResult.RESUME;
    }
  }, RfaProtocolResult.RESUME),
});
