import React from 'react';
import { isEqual } from 'lodash';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { isEmpty, set } from 'lodash/fp';
import { Image } from '../../../../../fragments/__generated__/Image';
import { InvitationModalContent } from './Content';
import { GroupListItem } from '../../ManageGroups/GroupsList';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppButton, AppModal, SpacerItems } from '../../../../../uiComponents';
import { validateName, validateEmail } from '../HelperFunctions';
import { Role } from '../../../../../types/globalTypes';

export interface InvitationModalUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string | null;
  userImage?: Image | null;
  subtitle?: React.ReactNode;
  actions?: SpacerItems;
  memberships?: Array<Membership> | null;
}

interface Membership {
  role: string;
  status: string;
}

export interface InvitationModalProps {
  title: string;
  visible: boolean;
  onDismiss: () => void;
  onAdd: (user: any) => void;
  submitting?: boolean;
  defaultGroup?: GroupListItem;
}

export interface InviteGuardianFormData {
  email: string;
  firstName: string;
  lastName: string;
}
export interface InviteUserFormData {
  email: string;
  firstName: string;
  lastName: string;
  birthday: string;
  roles: string[];
  guardians: InviteGuardianFormData[];
  groupList: GroupListItem[];
}

export const InvitationModal: React.FC<InvitationModalProps> = (props) => {
  const { title, visible, onDismiss, onAdd, defaultGroup } = props;
  const { translate } = useTranslations();

  const initialValues: InviteUserFormData = {
    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
    roles: [],
    guardians: [],
    groupList: defaultGroup ? [defaultGroup] : [],
  };

  const handleInviteSubject = (
    values: InviteUserFormData,
    { setSubmitting, resetForm }: FormikHelpers<InviteUserFormData>
  ) => {
    onAdd(values);
    resetForm();
    setSubmitting(false);
    onDismiss();
  };

  const validate = (values: InviteUserFormData) => {
    const isSubject = isEqual(values.roles, [Role.SUBJECT]);
    let errors: FormikErrors<InviteUserFormData> = {};

    validateName(values, errors, translate);
    validateEmail(
      values,
      errors,
      translate,
      !isSubject || (values.guardians.length > 0 && values.guardians.some((guardian) => !guardian.email)),
      isSubject
    );

    if (isSubject && isEmpty(values.birthday)) {
      errors.birthday = translate.error.message.cantBeEmpty;
    }
    if (!values.roles.length) {
      errors.roles = translate.error.message.cantBeEmpty;
    }

    const guardiansErrors = values.guardians
      .map((guardian) => {
        const guardianErrors: FormikErrors<InviteGuardianFormData> = {};
        validateName(guardian, guardianErrors, translate);
        validateEmail(guardian, guardianErrors, translate);
        return guardianErrors;
      })
      .filter((val) => val && !isEmpty(val));

    if (guardiansErrors.length > 0) {
      errors = set('guardians', guardiansErrors, errors); // this avoids typescript error
    }

    return errors;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleInviteSubject} validate={validate}>
      {({ handleSubmit, isSubmitting, isValid, resetForm }) => (
        <AppModal
          mode="full-screen"
          visible={visible}
          title={title}
          onDismiss={() => {
            onDismiss();
            resetForm();
          }}
          buttons={{
            cancel: (
              <AppButton
                type="outline"
                onPress={() => {
                  onDismiss();
                  resetForm();
                }}
              >
                {translate.label.cancel}
              </AppButton>
            ),
            submit: (
              <AppButton disabled={!isValid || isSubmitting} loading={isSubmitting} onPress={handleSubmit}>
                {translate.users.addUser.invite}
              </AppButton>
            ),
          }}
        >
          <InvitationModalContent {...props} />
        </AppModal>
      )}
    </Formik>
  );
};
