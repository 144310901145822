import * as React from 'react';
import { ProfileChrome } from './ProfileChrome';
import { Content } from './Content';
import { useTranslations } from '../../../i18n/useTranslations';

export const Overview: React.FC = () => {
  const { translate } = useTranslations();

  return <ProfileChrome activeTab="overview" content={<Content />} title={translate.home.tile.myProfile} />;
};
