import { gql } from '@apollo/client';
import { ImageFragment } from './ImageFragment';

export const UnverifiedCurrentUserFragment = gql`
  fragment UnverifiedCurrentUser on UnverifiedCurrentUser {
    id
    firstName
    lastName
    fullName
    age
    email
    userImage {
      ...Image
    }
  }
  ${ImageFragment}
`;
