import { gql } from '@apollo/client';
import { useQuery } from './useQuery';

export const IS_FEATURE_FLAG_AVAILABLE = gql`
  query isFeatureFlagAvailable($featureFlag: FeatureFlag!) {
    hasFeature(featureFlag: $featureFlag)
  }
`;

export const useIsFeatureFlagAvailable = (options) => useQuery(IS_FEATURE_FLAG_AVAILABLE, options);
