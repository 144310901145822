import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ManageOrganizationParamList } from './ManageOrganization.interface';
import { useManageOrganizationRoutes } from './hooks/useManageOrganizationRoutes';

const ManageOrganizationNav = createStackNavigator<ManageOrganizationParamList>();
const routes = useManageOrganizationRoutes();

export const ManageOrganizationNavigator: React.FC<{}> = () => {
  return (
    <ManageOrganizationNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <ManageOrganizationNav.Screen key={name} name={name} component={component} />
      ))}
    </ManageOrganizationNav.Navigator>
  );
};
