import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

interface SectionWrapper {
  style?: ViewStyle;
  children: React.ReactNode;
}

export const SectionWrapper: React.FC<SectionWrapper> = ({ style, children }) => {
  const styles = StyleSheet.create({
    container: {
      ...style,
      marginBottom: 50,
    },
  });

  return <View style={[styles.container]}>{children}</View>;
};
