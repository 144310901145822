import { gql } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { DeactivateGroup, DeactivateGroupVariables } from './__generated__/DeactivateGroup';
import { GetGroupsByOrganizationId } from './__generated__/GetGroupsByOrganizationId';
import { GET_GROUPS_BY_ORGANIZATION_ID_QUERY } from './useGetGroupsByOrganizationId';

export const DEACTIVATE_USER = gql`
  mutation DeactivateGroup($organizationId: String!, $groupId: String!) {
    deactivateGroup(organizationId: $organizationId, groupId: $groupId) {
      ... on DeactivatedGroup {
        id
        name
        organizationId
        creationDate
        active
        deactivationDate
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useDeactivateGroup = (organizationId: string, groupId: string) =>
  useMutation<DeactivateGroup, DeactivateGroupVariables>(DEACTIVATE_USER, {
    variables: { organizationId, groupId },
    update(cache, { data }) {
      const groups = cache.readQuery<GetGroupsByOrganizationId>({
        query: GET_GROUPS_BY_ORGANIZATION_ID_QUERY,
        variables: {
          organizationId,
        },
      });
      if (groups) {
        cache.writeQuery<GetGroupsByOrganizationId>({
          query: GET_GROUPS_BY_ORGANIZATION_ID_QUERY,
          data: {
            groups: groups.groups.filter(
              (group) =>
                group.id !== (data?.deactivateGroup.__typename === 'DeactivatedGroup' ? data.deactivateGroup.id : '')
            ),
          },
          variables: {
            organizationId,
          },
        });
      }
    },
  });
