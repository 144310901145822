/* Single tag
=============================== */

import React from 'react';
import { TouchableOpacity, StyleSheet, ViewStyle } from 'react-native';
import { Icon } from '../Icon/Icon';
import { AppText } from '../AppText/AppText';
import { useColors } from '../../shared/Ui/hooks/useColors';
import { TagType } from './TagList.interface';

export interface TagProps<T> {
  tag: TagType<T>;
  mode?: string;
  onPress?: (tag: TagType<T>) => void;
  editable?: boolean;
  style?: ViewStyle;
}

export function Tag<T = any>({ tag, mode = 'added', onPress = () => null, editable = true, style }: TagProps<T>) {
  // styles
  const colors = useColors();

  const styles = StyleSheet.create({
    item: {
      backgroundColor: editable ? (mode === 'add' ? colors.white : colors.navyDark) : colors.gray + '88',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 4,
      paddingHorizontal: 14,
      borderRadius: 30,
      marginRight: 8,
      marginBottom: 6,
      maxWidth: '100%',
    },

    icon: {
      marginLeft: 6,
      marginRight: -2,
      marginTop: 2,
      color: mode === 'add' ? colors.green : colors.red,
    },
  });

  return (
    <TouchableOpacity style={[style, styles.item]} onPress={() => onPress(tag)}>
      <AppText font="labelDefault" color={mode === 'add' ? 'blue' : 'white'}>
        {tag.label}
      </AppText>
      {editable ? <Icon name={mode === 'add' ? 'plus' : 'x'} size={20} style={styles.icon} /> : null}
    </TouchableOpacity>
  );
}
