import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { CaseFragment } from '../../../../../fragments/CaseFragment';

export const GET_USER_CASE_LOG = gql`
  query userCaseLog($input: SubjectCaseInput!) {
    userCaseLog(input: $input) {
      ... on Case {
        ...Case
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${CaseFragment}
`;

export const useGetMyCaseLog = (options?) => useQuery(GET_USER_CASE_LOG, options);
