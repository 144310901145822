import { useTranslations } from '../../../../../../../i18n/useTranslations';
import * as React from 'react';
import { MedicalAssessment } from '../../../fragments/__generated__/MedicalAssessment';
import { BaseStage } from './BaseStage/BaseStage';

export interface StageItemProps {
  onPress?: (id: string) => void;
  stage: MedicalAssessment;
}

export const MedicalAssessmentStage: React.FC<StageItemProps> = ({ stage, onPress }) => {
  const { translate } = useTranslations();
  return (
    <BaseStage
      key={stage.id}
      id={stage.id}
      title={translate.recovery.submitMedicalAssessment.medicalAssessment}
      organizationName={stage.organizationName}
      onPress={onPress}
      state={stage.state}
    />
  );
};
