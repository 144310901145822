import React from 'react';
import { View, StyleSheet } from 'react-native';
import { AppText } from '../../../uiComponents';

export interface SubtitleProps {
  fullName: string;
}

export const Subtitle: React.FC<SubtitleProps> = ({ fullName }) => {
  const styles = StyleSheet.create({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    },
    mainContainer: {
      flexDirection: 'column',
      width: '100%',
    },
  });

  return (
    <View style={styles.root}>
      <View style={styles.mainContainer}>
        <AppText font="header1">{fullName}</AppText>
      </View>
    </View>
  );
};
