import { useValidation } from '../../../hooks/useValidation';
import { useRegister, useSignInWithCustomToken } from '../../../shared/Firebase';
import { useTranslations } from '../../../i18n/useTranslations';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import * as React from 'react';
import { RegisterFormValues } from './RegisterScreen.interface';
import { RegisterFields } from './RegisterFields';
import { validatePhoneNumber } from '../../../hooks/useValidation/components/useValidatePhoneNumber';
import { UserLanguage } from '../../../types/globalTypes';

export interface RegisterFormProps {
  initialValues: RegisterFormValues;
  lockedToEmail?: boolean | undefined | null;
  isAskForPhoneNumber: boolean;
  isAskForBirthday: boolean;
  organizationLanguage?: string;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  initialValues,
  lockedToEmail,
  isAskForPhoneNumber,
  isAskForBirthday,
  organizationLanguage,
}) => {
  const { translate } = useTranslations(organizationLanguage ? UserLanguage[organizationLanguage] : null);
  const { validateEmail, validatePassword, validatePasswordConfirm } = useValidation();
  const signIn = useSignInWithCustomToken();

  const [registerUser] = useRegister({
    onCompleted: (data) => {
      if (data.register.__typename === 'RegisteredUser') {
        signIn(data.register.token);
      }
    },
  });

  const submitForm = async (values: RegisterFormValues, { resetForm }: FormikHelpers<RegisterFormValues>) => {
    const { firstName, lastName, email, password, birthday, invitation, phoneNumber, isoCode } = values;

    if (phoneNumber && isoCode) {
      registerUser({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            birthday,
            password,
            invitation,
            phoneNumber: {
              isoCode,
              phoneNumber,
            },
          },
        },
      }).then(() => {
        resetForm();
      });
    } else {
      registerUser({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            birthday,
            password,
            invitation,
          },
        },
      }).then(() => {
        resetForm();
      });
    }
  };

  const validateForm = (values: RegisterFormValues) => {
    const errors: FormikErrors<RegisterFormValues> = {};

    if (!values.firstName) {
      errors.firstName = translate.error.message.authNoFirstName;
    }

    if (!values.lastName) {
      errors.lastName = translate.error.message.authNoLastName;
    }

    if (isAskForBirthday && !values.birthday) {
      errors.birthday = translate.error.message.authNoBirthday;
    }

    if (values.email && !validateEmail(values.email)) {
      errors.email = translate.error.message.authNoEmail;
    }

    if (!values.password || !validatePassword(values.password)) {
      errors.password = translate.error.message.authNoPassword;
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = translate.error.message.authNoPasswordConfirm;
    }
    if (!validatePasswordConfirm(values.password, values.passwordConfirm)) {
      errors.passwordConfirm = translate.error.message.authPasswordConfirmNotMatch;
    }

    if (values.phoneNumber && !validatePhoneNumber(values.phoneNumber)) {
      errors.phoneNumber = translate.error.message.auth.invalidPhoneNumber;
    }

    if (!values.termsAndConditionsAccepted) {
      errors.termsAndConditionsAccepted = translate.reviewInvitation.rightUser.youMustAcceptAllPolicies;
    }

    return errors;
  };

  return (
    <Formik<RegisterFormValues> initialValues={initialValues} validate={validateForm} onSubmit={submitForm}>
      <RegisterFields
        disableEmail={lockedToEmail ? true : false}
        isAskForPhoneNumber={isAskForPhoneNumber}
        organizationLanguage={organizationLanguage}
      />
    </Formik>
  );
};
