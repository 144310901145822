import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { PageNavButtonsProps } from './PageNavButtons.interface';
import { Icon } from '../Icon/Icon';

export const PageNavButtons: React.FC<PageNavButtonsProps> = ({
  isPrevPageButtonVisible,
  isNextPageButtonVisible,
  nextPageAction,
  prevPageAction,
  style,
}) => {
  const styles = StyleSheet.create({
    pageNavButtons: {
      display: 'flex',
      flexDirection: 'row',
    },
    prevPageButton: {
      marginRight: 30,
    },
  });

  return (
    <View style={style ? style : styles.pageNavButtons}>
      {isPrevPageButtonVisible ? (
        <TouchableOpacity onPress={prevPageAction} style={styles.prevPageButton}>
          <Icon name="chevron-left" />
        </TouchableOpacity>
      ) : null}
      {isNextPageButtonVisible ? (
        <TouchableOpacity onPress={nextPageAction}>
          <Icon name="chevron-right" />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};
