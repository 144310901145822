import {
  AssessActionSetProtocolPayload,
  AssessContextState,
  AssessStepChallenge,
  AssessStepValueSymptom,
} from '../../../Assess.interface';
import { isNotStepInformation } from './isNotStepInformation';

export const setProtocol = (
  nextState: AssessContextState,
  payload: AssessActionSetProtocolPayload
): AssessContextState => {
  const {
    removeProtocol: { redFlag, steps },
  } = payload.selectedProtocolOption.protocol;

  const ne: AssessContextState = {
    ...nextState,
    selectedProtocolOption: payload.selectedProtocolOption,
    redFlags: {
      viewed: false,
      values: redFlag.flags.map(({ id }) => ({
        redFlagId: id,
        result: null,
      })),
    },

    steps: steps.filter(isNotStepInformation).map((step) => {
      if (step.__typename === 'StepChallenge') {
        const result: AssessStepChallenge = {
          stepId: step.id,
          results: step.challenges.map(({ id }) => ({ challengeId: id, viewed: false, result: null })),
          type: 'challenge',
        };
        return result;
      } else {
        const result: AssessStepValueSymptom = {
          stepId: step.id,
          result: step.symptoms.map(({ id }) => ({ symptomId: id, result: null })),
          type: 'symptom',
          viewed: false,
        };
        return result;
      }
    }),
  };
  return ne;
};
