import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { RemoveUserRole, RemoveUserRoleVariables } from './__generated__/RemoveUserRole';
import { GetOrganizationUsers } from '../../../hooks/__generated__/GetOrganizationUsers';
import { GET_ORGANIZATION_USERS } from '../../../hooks/useGetOrganizationUsers';

export const REMOVE_USER_ROLE_MUTATION = gql`
  mutation RemoveUserRole($organizationId: ID!, $userId: ID!, $role: Role!) {
    removeUserRole(organizationId: $organizationId, userId: $userId, role: $role) {
      ... on RemoveUserRoleInfo {
        userId
        organizationId
        role
        roleId
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export interface CacheUpdateUserOptions {
  organizationId: string;
  skip: number;
  orderBy: string;
  direction: string;
  role: string | null;
  searchStr: string | null;
  selectedRole?: string;
}

export const useRemoveUserRole = (
  cacheUpdateOptions: CacheUpdateUserOptions,
  options?: MutationHookOptions<RemoveUserRole, RemoveUserRoleVariables>
) =>
  useMutation<RemoveUserRole, RemoveUserRoleVariables>(REMOVE_USER_ROLE_MUTATION, {
    ...options,
    update(cache, { data }) {
      const { organizationId, skip, orderBy, direction, role, searchStr, selectedRole } = cacheUpdateOptions;
      const cachedUsers = cache.readQuery<GetOrganizationUsers>({
        query: GET_ORGANIZATION_USERS,
        variables: {
          organizationId,
          paginationArgs: {
            skip,
            orderBy,
            direction,
          },
          role,
          searchStr: searchStr ? searchStr : null,
        },
      });

      const userId = data?.removeUserRole.__typename === 'RemoveUserRoleInfo' ? data.removeUserRole.userId : '';
      const removedRoleId = data?.removeUserRole.__typename === 'RemoveUserRoleInfo' ? data.removeUserRole.roleId : '';

      if (cachedUsers) {
        let userToUpdate = cachedUsers.organizationUsers.find((user) => user.id === userId);
        if (userToUpdate?.memberships) {
          userToUpdate = {
            ...userToUpdate,
            memberships: userToUpdate.memberships.filter((membership) => membership.id !== removedRoleId),
          };
        }

        cache.writeQuery<GetOrganizationUsers>({
          query: GET_ORGANIZATION_USERS,
          variables: {
            organizationId,
            paginationArgs: {
              skip,
              orderBy,
              direction,
            },
            searchStr,
            selectedRole,
          },
          data: {
            organizationUsers: cachedUsers.organizationUsers
              .filter((cachedUser) => cachedUser.id !== userId)
              .concat(userToUpdate ? userToUpdate : []),
          },
        });
      }
    },
  });
