import React, { useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { AppButton, AppModal, AppText, useLoading } from '../../../uiComponents';
import { LoggedOutChrome } from '../LoggedOutChrome';
import { useTranslations } from '../../../i18n/useTranslations';
import { ResetPasswordFields } from './ResetPasswordFields';
import { useNotification } from '../../../hooks/ui/useNotification';
import { RootNavType } from '../../../RootNavigator.interface';
import { useResetPassword } from './hooks/useResetPassword';
import { isApiError } from '../../../utils/isApiError';

export interface ResetPasswordFormValues {
  email: string;
}

export const ResetPasswordScreen = () => {
  const { translate } = useTranslations();
  const formik = useRef<FormikProps<ResetPasswordFormValues> | null>(null);
  const navigation = useNavigation<RootNavType<'ResetPassword'>>();
  const [loading, setLoading] = useState(false);
  const [showEmailSentModal, setShowEmailSentModal] = useState<boolean>(false);
  useLoading(loading);
  const notify = useNotification();
  const [resetPassword] = useResetPassword();

  const initialValues: ResetPasswordFormValues = {
    email: '',
  };

  const handleValidate = ({ email }: ResetPasswordFormValues) => {
    const errors: FormikErrors<ResetPasswordFormValues> = {};

    if (!email) {
      errors.email = translate.error.message.authNoEmail;
    }

    return errors;
  };

  const handleSubmit = (
    { email }: ResetPasswordFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ResetPasswordFormValues>
  ) => {
    setLoading(true);

    resetPassword({
      variables: {
        input: {
          email,
        },
      },
    })
      .then(({ data }) => {
        setSubmitting(false);

        resetForm();
        setShowEmailSentModal(true);
        setLoading(false);

        if (!isApiError(data?.resetPassword)) {
          notify({
            image: 'header/people',
            title: translate.flash.title.messageSent,
            description: translate.flash.message.checkYourEmail,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const primaryContent = (
    <Formik<ResetPasswordFormValues>
      innerRef={(ref) => (formik.current = ref)}
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      <>
        <AppModal
          mode="dialog"
          title={translate.flash.title.messageSent}
          onDismiss={() => {
            setShowEmailSentModal(false);
            navigation.navigate('Login');
          }}
          visible={showEmailSentModal}
          buttons={{
            ok: (
              <AppButton
                onPress={() => {
                  setShowEmailSentModal(false);
                  navigation.navigate('Login');
                }}
              >
                {translate.admin.manageOrganization.ok}
              </AppButton>
            ),
          }}
        >
          <AppText>{translate.auth.changePassword.emailSent}</AppText>
        </AppModal>
        <ResetPasswordFields />
      </>
    </Formik>
  );

  return <LoggedOutChrome mode="title-card" titleText={translate.auth.title.resetPassword} content={primaryContent} />;
};
