import React, { useEffect } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { NetInfoStateType, useNetInfo } from '@react-native-community/netinfo';
import { ErrorBubble } from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';
import { FullScreen, FullScreenProps } from './FullScreen';
import { NavBarForWeb } from './NavBar/NavBarForWeb';
import { NavBarForMobile } from './NavBar/NavBarForMobile';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { CommonProps } from './Chrome.interface';
import { TitleImage } from './TitleImage';
import { useIsDPCompletedEducationItems } from '../../hooks/useIsDPCompletedEducationItems';
import { Role } from '../../types/globalTypes';
import { useGetAllUserRoles } from '../../hooks/useGetAllUserRoles';
import { useRoute } from '@react-navigation/native';

export interface NavBarProps extends CommonProps {
  mode: 'title-card' | 'dashboard' | 'full-screen';
  title?: React.ReactNode;
  titleText?: string;
  subtitle?: React.ReactNode;
  subtitleHighlighted?: boolean;
  rightSideArea?: React.ReactNode;
  subNavigation?: React.ReactNode;
  content?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  theme?: 'default' | 'flat' | 'emergency' | 'two-tone' | undefined;
  hideNavigation?: boolean;
  footer?: React.ReactNode;
  titleImage?: TitleImage;
  hideNavItems?: boolean;
  children?: React.ReactNode;
}

export type ChromeProps = FullScreenProps | NavBarProps;

const isFullScreen = (props: ChromeProps): props is FullScreenProps => props.mode === 'full-screen';

export const Chrome: React.FC<ChromeProps> = (props) => {
  const { translate } = useTranslations();
  const { isConnected, isInternetReachable, type } = useNetInfo();
  const isMobile = useIsMobile();
  const route = useRoute();
  const { data: rolesData } = useGetAllUserRoles({
    skip: route.name === 'Welcome' || route.name === 'Login' || route.name === 'ResetPassword',
  });

  const organizationIds: string[] =
    rolesData && rolesData.allUserRoles
      ? rolesData.allUserRoles
          .filter(
            (role) => role.role === Role.PRIMARY_DESIGNATED_PERSON || role.role === Role.SECONDARY_DESIGNATED_PERSON
          )
          .map((role) => role.organizationId)
      : [];

  const { data: isDPCompletedEducationItems } = useIsDPCompletedEducationItems(organizationIds, {
    skip: route.name === 'Welcome' || route.name === 'Login' || route.name === 'ResetPassword',
  });

  const hideNavItems = isDPCompletedEducationItems && isDPCompletedEducationItems.isDPCompletedEducationItems === false;

  let content: React.ReactNode = null;

  if (isFullScreen(props)) {
    content = <FullScreen {...props} />;
  } else {
    content = isMobile ? (
      <NavBarForMobile {...props} hideNavItems={hideNavItems} />
    ) : (
      <NavBarForWeb {...props} hideNavItems={hideNavItems} />
    );
  }

  const networkNotice =
    !isConnected && !isInternetReachable && type === NetInfoStateType.none ? (
      <ErrorBubble message={translate.violator.noConnection} icon="zap-off" backgroundColor="red" interactive={false} />
    ) : null;

  return (
    <>
      {content}
      {networkNotice}
    </>
  );
};
