import { FieldArray, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import {
  UpdateRemoveProtocolStepChallengeConfigFormValue,
  UpdateProtocolConfigFormValue,
} from '../../../ManageProtocol.interface';
import { KeyValuePairFields } from '../../../KeyValuePairFields/KeyValuePairFields';
import { RemoveProtocolStepBaseConfig } from './RemoveProtocolStepBaseConfig';

export interface RemoveProtocolStepChallengeConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateRemoveProtocolStepChallengeConfigFormValue>;
  touch?: FormikTouched<UpdateRemoveProtocolStepChallengeConfigFormValue>;
  value: UpdateRemoveProtocolStepChallengeConfigFormValue;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const RemoveProtocolStepChallengeConfig: React.FC<RemoveProtocolStepChallengeConfigProps> = ({
  index,
  onRemove,
  move,
  itemKey,
  error,
  touch,
  value,
  canMoveUp,
  canMoveDown,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            remove: { step: translate },
          },
        },
      },
    },
  } = useTranslations();
  const { setFieldValue } = useFormikContext<UpdateProtocolConfigFormValue>();

  const challenges = (
    <FieldArray
      name={`${itemKey}.challenges`}
      children={(props) => <KeyValuePairFields addNewLabel={translate.addChallenge} {...props} />}
    />
  );

  return (
    <RemoveProtocolStepBaseConfig
      index={index}
      itemKey={itemKey}
      move={move}
      onRemove={onRemove}
      value={value}
      canMoveDown={canMoveDown}
      canMoveUp={canMoveUp}
      error={error}
      touch={touch}
      typeSpecificItems={{
        challenges,
      }}
    />
  );
};
