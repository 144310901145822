import React from 'react';
import { StyleSheet, View, Platform, UIManager } from 'react-native';
import { AppText } from '../../../../uiComponents';
import { useShadow, useColors } from '../../../../shared/Ui/hooks';
import { CardRow } from './CardRow';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';

import {
  GetCircleOfCare_circleOfCare_CircleOfCare_primaryDesignatedPersons as PrimaryDesignatedPersons,
  GetCircleOfCare_circleOfCare_CircleOfCare_secondaryDesignatedPersons as SecondaryDesignatedPersons,
  GetCircleOfCare_circleOfCare_CircleOfCare_guardians as Guardians,
} from '../../ProfileChrome/hooks/__generated__/GetCircleOfCare';
import { useTranslations } from '../../../..//i18n/useTranslations';

type PersonsType = 'Primary Designated Persons' | 'Secondary Designated Persons' | 'Guardians';

export interface CardProps {
  persons: PrimaryDesignatedPersons[] | SecondaryDesignatedPersons[] | Guardians[] | null;
  personsType: PersonsType;
}

export const Card: React.FC<CardProps> = (props) => {
  const { personsType, persons } = props;
  const shadow = useShadow();
  const colors = useColors();
  const isMobile = useIsMobile();
  const { translate } = useTranslations();

  if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  const styles = StyleSheet.create({
    cardContainer: {
      ...shadow,
      backgroundColor: colors.white,
      width: isMobile ? '100%' : 300,
      paddingVertical: 20,
      borderRadius: 10,
      margin: 5,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      alignSelf: 'center',
      maxWidth: '95%',
      textAlign: 'center',
      marginBottom: 15,
    },
  });

  const getRowData = (data: PrimaryDesignatedPersons[] | SecondaryDesignatedPersons[] | Guardians[]) => {
    return data.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phoneNumber: item.phoneNumber ? item.phoneNumber.phoneNumber : '',
      groups: item.groups ? item.groups : '',
    }));
  };

  const cardHeaderProperties = {
    'Primary Designated Persons': {
      color: colors.orange,
      label: translate.users.rolesPlural.primaryDesignatedPerson,
    },
    'Secondary Designated Persons': {
      color: colors.blue,
      label: translate.users.rolesPlural.secondaryDesignatedPerson,
    },
    Guardians: { color: colors.green, label: translate.users.rolesPlural.guardian },
  };

  return (
    <View style={styles.cardContainer}>
      <View style={styles.title}>
        <AppText font="header5" style={{ color: cardHeaderProperties[personsType].color }}>
          {cardHeaderProperties[personsType].label}
        </AppText>
      </View>
      {persons
        ? getRowData(persons).map((person) => (
            <CardRow
              key={person.id}
              id={person.id}
              firstName={person.firstName}
              lastName={person.lastName}
              email={person.email}
              phoneNumber={person.phoneNumber}
              groups={person.groups ? person.groups : ''}
            />
          ))
        : null}
    </View>
  );
};
