export enum Tabs {
  IMPORT_DATA = 'IMPORT_DATA',
  PAST_UPLOADS = 'PAST_UPLOADS',
}

export enum ImportStatus {
  NO_ATTACHMENT = 'NO_ATTACHMENT',
  READY_TO_IMPORT = 'READY TO IMPORT',
  IMPORT_SUCCESSFUL = 'IMPORT_SUCCESSFUL',
  IMPORT_FAILED = 'IMPORT FAILED',
  PREPARING_UPLOAD_AND_VALIDATE = 'PREPARING_UPLOAD',
  UPLOAD_AND_VALIDATE_IN_PROGRESS = 'UPLOAD_AND_VALIDATE_IN_PROGRESS',
  INVALID_DATA = 'INVALID_DATA',
  COMPLETE_IMPORT = 'COMPLETE_IMPORT',
}

export interface FormFieldProps {
  title: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<Tabs>>;
  value: string;
  selectedOrganization: string;
  sendInviteEmailsOnImport: boolean;
}

interface PastUpload {
  fileName: string;
  group: 'Subjects & Guardians' | 'Staff' | 'Subject Groups' | 'Designated Person Groups' | 'Guardians';
  user: string;
  date: string;
  status: 'validated' | 'imported' | 'completed';
}

export interface PastUploadProps {
  pastUploads: PastUpload[];
  selectedOrganization: string;
}
