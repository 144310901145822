/* Custom text input with icon
=============================== */

import React, { useEffect, forwardRef, useState } from 'react';
import { TextInput, StyleSheet, Platform, TextInputProps, TouchableOpacity, View } from 'react-native';
import { BaseInput, BaseInputProps } from './BaseInput';
import { Icon } from '../Icon/Icon';
import { AppText } from '../AppText/AppText';
import { useColors, useShadow } from '../../shared/Ui';
import { InputError } from '../InputError/InputError';
import { InputContainer } from '../InputContainer/InputContainer';
import { InputLabel } from '../InputLabel/InputLabel';
import { useDebounce } from 'use-debounce';
import { fonts } from '../../shared/Ui/defaultStyleConfig';

interface Debounce {
  milliseconds: number;
  callback: (string) => void;
  textValue: string;
}

export interface InputProps extends Omit<TextInputProps, 'style'>, Omit<BaseInputProps, 'children'> {
  type?: 'text' | 'name' | 'email' | 'phone' | 'password' | 'number' | 'address';
  label?: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  onClear?: () => void;
  numLines?: number;
  beforeComponent?: React.ReactNode;
  afterComponent?: React.ReactNode;
  size?: 'small' | 'regular';
  debounce?: Debounce;
}

// TODO: [COL-114] fix hardware keyboard input type not compatible when type specified
export const Input = forwardRef<TextInput, InputProps>(
  (
    {
      label,
      type,
      icon,
      iconPosition = 'left',
      value,
      error,
      touched,
      placeholder,
      editable = true,
      disabled,
      returnKeyType = 'done',
      keyboardType = 'default',
      textContentType = 'none',
      autoComplete = 'off',
      autoCapitalize = 'sentences',
      numLines = 1,
      beforeComponent,
      afterComponent,
      onClear,
      size = 'small',
      debounce,
      ...props
    },
    ref
  ) => {
    const colors = useColors();
    const shadow = useShadow();
    const [textValue, onChangeTextValue] = useState<string>(debounce ? debounce.textValue : '');

    const [debouncedText] = useDebounce(textValue, debounce ? debounce.milliseconds : 1000);

    useEffect(() => {
      if (debounce) {
        debounce.callback(debouncedText);
      }
    }, [debouncedText]);

    const styles = StyleSheet.create({
      shadowViewForIos: {
        maxWidth: '100%',
        flex: 1,
        flexGrow: 1,
        elevation: 1,
        ...shadow,
      },
      baseInput: {
        ...shadow,
        ...fonts.bodyMedium,
        borderColor: colors.grayLight,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center', // ios = flex-end?
        // justifyContent: 'space-between',
        flexDirection: iconPosition === 'left' ? 'row' : 'row-reverse',
        zIndex: 2,
        height: size === 'small' ? numLines * 24 + 16 : numLines * 24 + 30,
        overflow: 'hidden',
      },
      textInput: {
        ...fonts.bodyMedium,
        color: disabled ? colors.gray : colors.navyDark,
        opacity: disabled ? 0.7 : 1,
        flex: 1,
        minHeight: numLines * 24,
        padding: 0,
        paddingLeft: iconPosition === 'right' ? 10 : 0,
        maxWidth: icon || disabled ? '85%' : '100%',
        overflow: 'hidden',
      },
      errorInput: {
        borderColor: colors.red,
      },
      placeholder: {
        color: colors.gray,
        opacity: 0.7,
      },
      error: {
        marginRight: 'auto',
        zIndex: 1,
      },
      clear: {
        width: 16,
        color: colors.grayDark,
      },
    });

    // base input props

    const inputProps: TextInputProps = {
      returnKeyType,
      keyboardType,
      textContentType,
      autoComplete,
      autoCapitalize,
      placeholder,
      value,
      multiline: Boolean(numLines > 1),
    };

    // shorthand types

    if (type === 'name') {
      inputProps.autoComplete = 'name';
      inputProps.textContentType = 'name';
    }

    if (type === 'email') {
      inputProps.keyboardType = 'email-address';
      inputProps.autoComplete = 'email';
      inputProps.textContentType = 'emailAddress';
      inputProps.autoCapitalize = 'none';
    }

    if (type === 'phone') {
      inputProps.keyboardType = 'phone-pad';
      inputProps.autoComplete = 'tel';
      inputProps.textContentType = 'telephoneNumber';
    }

    if (type === 'number') {
      inputProps.keyboardType = 'number-pad';
    }

    if (type === 'password') {
      inputProps.autoComplete = 'password';
      inputProps.textContentType = 'password';
    }

    if (type === 'address') {
      inputProps.autoComplete = 'street-address';
      inputProps.textContentType = 'fullStreetAddress';
    }

    // web

    if (Platform.OS === 'web') {
      if (inputProps.autoComplete) {
        // inputProps.autoComplete = inputProps.autoCompleteType; // TODO: fix this enable autocomplete for web
        delete inputProps.autoComplete;
      }
    }

    // password or editable?

    const isPassword = type === 'password';
    const isEditable = editable !== false && !disabled;

    const handleClear = () => {
      if (onClear) {
        onClear();
      }
    };

    const onClearDisplay =
      onClear && value && value.length > 0 ? (
        <TouchableOpacity onPress={handleClear}>
          <Icon style={styles.clear} name="x" size={20} />
        </TouchableOpacity>
      ) : null;

    const output = (
      <>
        <BaseInput
          style={StyleSheet.flatten([styles.baseInput, props.style, touched && !!error ? styles.errorInput : null])}
          icon={disabled ? 'lock' : icon}
          iconPosition={iconPosition}
        >
          {beforeComponent}
          {!isEditable ? (
            <>
              <AppText
                font="bodyDefault"
                color="navyDarker"
                style={{
                  ...styles.textInput,
                  ...(placeholder && !value ? styles.placeholder : {}),
                }}
              >
                {placeholder && !value ? placeholder : value}
              </AppText>
            </>
          ) : (
            <TextInput
              ref={ref}
              {...inputProps}
              {...props}
              style={styles.textInput}
              enablesReturnKeyAutomatically={true}
              secureTextEntry={isPassword}
              numberOfLines={numLines}
              {...(debounce
                ? {
                    onChangeText: onChangeTextValue,
                    value: textValue,
                  }
                : {})}
            />
          )}
          {onClearDisplay}
          {afterComponent}
        </BaseInput>
        <InputError touched={touched} error={error} style={styles.error} />
      </>
    );

    const outputForIos = <View style={Platform.OS === 'ios' ? styles.shadowViewForIos : null}>{output}</View>;

    const returnedOutput = Platform.OS === 'ios' ? outputForIos : output;

    if (label) {
      return (
        <InputContainer>
          <InputLabel label={label} />
          {returnedOutput}
        </InputContainer>
      );
    } else {
      return returnedOutput;
    }
  }
);
