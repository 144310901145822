import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { AssessNavItem, AssessParamList } from './Assess.interface';
import { useAssessReducer } from './hooks/useAssessReducer/useAssessReducer';
import { useAssessRoutes } from './hooks/useAssessRoutes';
import { CurrentAssessment } from './CurrentAssessment';
import { RouteGuard } from '../shared/RouteGuard';
import { Role } from '../../types/globalTypes';

const Assess = createStackNavigator<AssessParamList>();

export const AssessNavigator: React.FC = () => {
  const routes = useAssessRoutes();
  const assessReducer = useAssessReducer();
  const navigation = useNavigation();
  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        assessReducer.dispatch({ type: 'reset' });
        navigation.dispatch(e.data.action);
      }),
    [navigation]
  );

  useEffect(
    () =>
      navigation.addListener('focus', (e) => {
        assessReducer.dispatch({ type: 'initialize' });
      }),
    [navigation]
  );

  const routesRequiringInitialization = (route: AssessNavItem) =>
    assessReducer.state.initialized === false &&
    route.name !== 'LoggedIn_Assess_FindAthlete' &&
    route.name !== 'LoggedIn_Assess_Index'
      ? false
      : true;

  return (
    <Assess.Navigator initialRouteName="LoggedIn_Assess_Index" screenOptions={{ headerShown: false }}>
      {routes.filter(routesRequiringInitialization).map(({ component: Component, name }) => (
        <Assess.Screen
          key={name}
          name={name}
          children={() => (
            <CurrentAssessment
              value={assessReducer}
              children={
                <RouteGuard acceptedRoles={[Role.PRIMARY_DESIGNATED_PERSON, Role.SECONDARY_DESIGNATED_PERSON]}>
                  <Component />
                </RouteGuard>
              }
            />
          )}
        />
      ))}
    </Assess.Navigator>
  );
};
