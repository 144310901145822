import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Tag } from './Tag';
import { TagType } from './TagList.interface';

interface TagListProps<T> {
  tags: TagType<T>[];
  onRemove?: (tag: TagType<T>) => void;
  editable?: boolean;
  style?: ViewStyle;
}

export function TagList<T>({ tags = [], onRemove, editable = true, style }: TagListProps<T>) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginVertical: 20,
    },
  });

  return (
    <View style={[styles.container, style]}>
      {tags.map((item, index) => (
        <Tag
          key={index}
          tag={item}
          onPress={() => {
            if (onRemove) {
              onRemove(item);
            }
          }}
          editable={editable}
        />
      ))}
    </View>
  );
}
