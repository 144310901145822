import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileTrainingProps {
  onPress: () => void;
  disabled?: boolean;
}

export const TileTraining: React.FC<TileTrainingProps> = ({ onPress, disabled }) => {
  const { translate } = useTranslations();

  return (
    <BaseTile
      backgroundColor="green"
      labelColor="white"
      label={translate.home.tile.training}
      onPress={onPress}
      image="tile/training"
      disabled={disabled}
    />
  );
};
