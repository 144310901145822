import { useState } from 'react';
import { View } from 'react-native';
import { AppText } from '../../../../../uiComponents';
import { addHours, differenceInMilliseconds } from 'date-fns';
import { useDateFormat } from '../../../../../hooks/ui/useDateFormat';
import { useTranslations } from '../../../../../i18n/useTranslations';

interface TimeGateMessageProps {
  timeGateHours: number | null;
}

export const useTimeGating = (caseCreatedDate, timeGateHours: number | null) => {
  const { translate, translateWithVars } = useTranslations();
  const { formatDateAndTime } = useDateFormat();

  const getTimeGateEnd = () => {
    return caseCreatedDate && timeGateHours ? addHours(new Date(caseCreatedDate), timeGateHours) : new Date();
  };

  const [timeGateMessageColour, setTimeGateMessageColour] = useState<'red' | 'grayDark'>(
    timeGateHours && differenceInMilliseconds(getTimeGateEnd(), Date.now()) > 0 ? 'red' : 'grayDark'
  );

  const startTimeGateEndCountdown = (timeGateEnd) => {
    let timeGateMesssageColourTimeout;
    if (caseCreatedDate && timeGateHours && differenceInMilliseconds(timeGateEnd, Date.now()) > 0) {
      timeGateMesssageColourTimeout = setTimeout(() => {
        setTimeGateMessageColour('grayDark');
      }, differenceInMilliseconds(timeGateEnd, Date.now()));
    }

    return () => {
      clearTimeout(timeGateMesssageColourTimeout);
    };
  };

  const TimeGateMessage: React.FC<TimeGateMessageProps> = ({ timeGateHours }) => {
    return timeGateHours ? (
      <View style={{ marginTop: 20 }}>
        <AppText color={timeGateMessageColour} font="header5">
          {translateWithVars(translate.recovery.stageSignoff.timeGateRestriction, {
            date: formatDateAndTime(getTimeGateEnd()),
          })}
        </AppText>
        <AppText color="grayDark" font="header6" style={{ marginTop: 10 }}>
          {translateWithVars(translate.recovery.stageSignoff.timeGateHours, {
            hours: timeGateHours,
            hourOrHours:
              timeGateHours === 1 ? translate.recovery.stageSignoff.hour : translate.recovery.stageSignoff.hours,
          })}
        </AppText>
      </View>
    ) : null;
  };

  return {
    getTimeGateEnd,
    startTimeGateEndCountdown,
    TimeGateMessage,
    timeGateMessageColour,
  };
};
