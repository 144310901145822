import React from 'react';
import { StyleSheet } from 'react-native';
import { AppText } from '../../../../uiComponents';
import { Role } from '../../../../types/globalTypes';
import { useTranslations } from '../../../../i18n/useTranslations';

interface NameProps {
  name: string;
}

interface EmailProps {
  email: string | null;
}

interface RolesProps {
  roles?: Role[];
}

export const Name: React.FC<NameProps> = ({ name }) => {
  const styles = StyleSheet.create({
    userName: {
      marginTop: 5,
    },
  });

  return (
    <AppText numberOfLines={1} color="blue" font="labelDefault" style={styles.userName}>
      {name.trim()}
    </AppText>
  );
};

export const Email: React.FC<EmailProps> = ({ email }) => {
  return <AppText numberOfLines={1}>{email ? email : ''}</AppText>;
};

export const Roles: React.FC<RolesProps> = ({ roles }) => {
  const { translate } = useTranslations();

  const getRole = (role: Role) => {
    const roles = {
      [Role.SUBJECT]: translate.users.roles.subject,
      [Role.GUARDIAN]: translate.users.roles.guardian,
      [Role.PRIMARY_DESIGNATED_PERSON]: translate.users.roles.primaryDesignatedPerson,
      [Role.SECONDARY_DESIGNATED_PERSON]: translate.users.roles.secondaryDesignatedPerson,
      [Role.ADMIN]: translate.users.roles.admin,
    };
    return roles[role] ? roles[role] : '';
  };

  return <AppText numberOfLines={1}>{roles && roles.map((role) => getRole(role)).join(', ')}</AppText>;
};
