import { FormikErrors, FormikTouched, getIn, useFormikContext } from 'formik';
import { isBoolean, isString } from 'lodash';
import React from 'react';
import { useWindowDimensions, View, StyleSheet } from 'react-native';
import { KeyValuePair } from '../ManageProtocol.interface';
import { useColors } from '../../../../../shared/Ui/hooks';
import { Icon, Input, Spacer } from '../../../../../uiComponents';
import { useTranslations } from '../../../../../i18n/useTranslations';

export interface KeyValuePairFieldProps {
  index: number;
  name: string;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const KeyValuePairField: React.FC<KeyValuePairFieldProps> = ({
  index,
  onRemove,
  move,
  canMoveUp,
  canMoveDown,
  name,
}) => {
  const { width } = useWindowDimensions();
  const colors = useColors();
  const { handleBlur, handleChange, errors: _errors, touched: _touched, values: _values } = useFormikContext();
  const { translate } = useTranslations();

  const incomingError = getIn(_errors, name) as FormikErrors<KeyValuePair> | string | undefined;
  const error = isString(incomingError) ? undefined : incomingError;
  const incomingTouched = getIn(_touched, name) as FormikTouched<KeyValuePair> | boolean | undefined;
  const touched = isBoolean(incomingTouched) ? undefined : incomingTouched;

  const value = getIn(_values, name) as KeyValuePair;
  const wide = width > 340;

  const styles = StyleSheet.create({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowItem: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    label: {
      marginRight: 10,
    },
    actions: {
      flex: 0,
    },
  });

  const handleRemove = () => onRemove(index);
  const handleMoveUp = () => move(index, index - 1);
  const handleMoveDown = () => move(index, index + 1);

  return (
    <View style={styles.row}>
      <View style={StyleSheet.flatten([styles.rowItem, styles.label])}>
        <Input
          error={error?.value}
          touched={touched?.value}
          onChangeText={handleChange(`${name}.value`)}
          onBlur={handleBlur(`${name}.value`)}
          label={translate.admin.manageProtocol.label}
          value={value.value}
        />
      </View>
      <View style={styles.rowItem}>
        <Input
          error={error?.key}
          touched={touched?.key}
          onChangeText={handleChange(`${name}.key`)}
          onBlur={handleBlur(`${name}.key`)}
          label={translate.admin.manageProtocol.value}
          value={value.key}
        />
      </View>
      <View style={StyleSheet.flatten([styles.rowItem, styles.actions])}>
        <Spacer
          orientation="horizontal"
          style={{ alignSelf: 'flex-end', width: 'auto' }}
          items={{
            up: canMoveUp ? <Icon color={colors.blue} name="arrow-up" onPress={handleMoveUp} size={24} /> : null,
            down: canMoveDown ? (
              <Icon color={colors.blue} name="arrow-down" onPress={handleMoveDown} size={24} />
            ) : null,
            trash: <Icon color={colors.red} name="trash" onPress={handleRemove} size={24} />,
          }}
        />
      </View>
    </View>
  );
};
