import { FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import React from 'react';
import { AppButton, Spacer, SpacerItems } from '../../../../../uiComponents';
import { KeyValuePair } from '../ManageProtocol.interface';
import { KeyValuePairField } from './KeyValuePairField';

export interface KeyValuePairFieldsProps extends FieldArrayRenderProps {
  push<KeyValuePair>(obj: KeyValuePair): void;
  addNewLabel: string;
}

export const KeyValuePairFields: React.FC<KeyValuePairFieldsProps> = ({ name, push, move, remove, addNewLabel }) => {
  const { values: _values } = useFormikContext();
  const values = getIn(_values, name) as KeyValuePair[];

  const items: SpacerItems = values.reduce((accum, _, index) => {
    const isFirst = index === 0;
    const isLast = index === values.length - 1;
    const numTotal = values.length;
    const canMoveUp = numTotal > 1 && !isFirst;
    const canMoveDown = numTotal > 1 && !isLast;
    accum[index] = (
      <KeyValuePairField
        index={index}
        move={move}
        name={`${name}[${index}]`}
        onRemove={remove}
        canMoveDown={canMoveDown}
        canMoveUp={canMoveUp}
      />
    );
    return accum;
  }, {});

  const handleAddNew = () => push({ key: '', value: '' });

  return (
    <Spacer
      items={{
        items: <Spacer items={items} />,
        addNew: (
          <AppButton icon="plus" onPress={handleAddNew}>
            {addNewLabel}
          </AppButton>
        ),
      }}
    />
  );
};
