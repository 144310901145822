/* Date input with date picker
=============================== */

import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AppDateTimePickerProps } from './AppDateTimePicker.interface';
import { Input } from '../Input/Input';
import { useColors } from '../../shared/Ui/hooks/useColors';
import { useDateFormat } from '../../hooks/ui/useDateFormat';
import { AppModal } from '../AppModal/AppModal';
import { InputContainer } from '../InputContainer/InputContainer';
import { InputLabel } from '../InputLabel/InputLabel';
import { useTranslations } from '../../i18n/useTranslations';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { fr, enUS } from 'date-fns/locale';

export const AppDateTimePicker: React.FC<AppDateTimePickerProps> = ({
  label,
  value,
  onChange,
  onBlur,
  minimumDate,
  maximumDate,
  error,
  touched,
  style,
}) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const colors = useColors();
  const { formatDate } = useDateFormat();
  const { translate } = useTranslations();

  const { currentUser } = useCurrentUser();

  const language = currentUser && currentUser.__typename === 'CurrentUser' ? currentUser.language : 'en';

  const languageOptions = {
    fr: 'fr', // add to this list once there are more languages
    en: 'en',
  };
  registerLocale('fr', fr);
  registerLocale('en', enUS);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      bottom: '100%',
      zIndex: 1,
      padding: 20,
      backgroundColor: colors.white,
      borderRadius: 15,
      marginBottom: 5,
    },
    close: {
      position: 'absolute',
      top: 0,
      right: 20,
      padding: 8,
      backgroundColor: colors.white,
      borderRadius: 999,
      transform: [{ translateY: -20 }],
      zIndex: 2,
    },
    closeIcon: {
      width: 24,
      height: 24,
      color: colors.navyDark,
    },
    closeLink: {
      paddingTop: 10,
      marginHorizontal: 'auto',
      marginBottom: -5,
    },
    calendarContainer: {
      width: '100%',
      marginTop: 40,
    },
  });

  const toggleDatePicker = () => {
    setPickerVisible(!pickerVisible);
  };

  const handleChange = (date: Date) => {
    if (onChange) {
      onChange(date.toISOString());
    }

    toggleDatePicker();
  };

  const handleClose = () => {
    if (onBlur) {
      onBlur({});
    }
    setPickerVisible(false);
  };

  const minDate = minimumDate ? new Date(minimumDate) : undefined;
  const maxDate = maximumDate ? new Date(maximumDate) : undefined;
  const _date = value ? new Date(value) : undefined;
  const _displayValue = _date ? formatDate(_date) : translate.label.select;

  const calendarContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <View children={children} style={styles.calendarContainer} />
  );

  const output = (
    <>
      <AppModal visible={pickerVisible} mode="dialog" onDismiss={handleClose}>
        <DatePicker
          selected={_date}
          onChange={handleChange}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          minDate={minDate}
          maxDate={maxDate}
          dropdownMode="select"
          inline
          title={'Title'}
          calendarContainer={calendarContainer}
          locale={languageOptions[language]}
        />
      </AppModal>
      <TouchableOpacity onPress={toggleDatePicker}>
        <Input icon="calendar" value={_displayValue} editable={false} touched={touched} error={error} />
      </TouchableOpacity>
    </>
  );

  if (label) {
    return (
      <InputContainer>
        <InputLabel label={label} />
        {output}
      </InputContainer>
    );
  } else {
    return output;
  }
};
