import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { SimpleCaseFragment } from '../../../../../fragments/SimpleCaseFragment';
import { SubjectCaseLogsQuery, SubjectCaseLogsQueryVariables } from './__generated__/SubjectCaseLogsQuery';

export const SUBJECT_CASE_LOGS_QUERY = gql`
  query SubjectCaseLogsQuery($subjectInput: CurrentUserSubjectInput!) {
    getSubject(input: $subjectInput) {
      ... on Subject {
        id
        cases {
          ... on Case {
            ...SimpleCase
          }
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${SimpleCaseFragment}
  ${ApiErrorFragment}
`;

export const useSubjectCaseLogsQuery = (
  options: UseQueryOptions<SubjectCaseLogsQuery, SubjectCaseLogsQueryVariables>
) => useQuery<SubjectCaseLogsQuery, SubjectCaseLogsQueryVariables>(SUBJECT_CASE_LOGS_QUERY, options);
