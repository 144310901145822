import React from 'react';
import { AppModal, AppText, AppButton } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';

interface FastTrackWarningModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (modalVisible: boolean) => void;
  timeGateHours: number | null;
  stepForward: () => void;
}

export const FastTrackWarningModal: React.FC<FastTrackWarningModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  timeGateHours,
  stepForward,
}) => {
  const { translate, translateWithVars } = useTranslations();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleStepForward = () => {
    setIsModalVisible(false);
    stepForward();
  };

  return (
    <AppModal
      title={translateWithVars(translate.recovery.fastTrackWarning.title, {
        numberOfHours: timeGateHours,
        hourOrHours: timeGateHours === 1 ? translate.recovery.stageSignoff.hour : translate.recovery.stageSignoff.hours,
      })}
      mode="dialog"
      visible={isModalVisible}
      onDismiss={() => setIsModalVisible(false)}
      buttonOrientation="horizontal"
      buttons={{
        cancel: (
          <AppButton type="outline" onPress={handleCancel}>
            {translate.label.cancel}
          </AppButton>
        ),
        stepForward: (
          <AppButton type="danger" onPress={handleStepForward}>
            {translate.label.stepForward}
          </AppButton>
        ),
      }}
    >
      <AppText>{translate.recovery.fastTrackWarning.content}</AppText>
    </AppModal>
  );
};
