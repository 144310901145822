import * as React from 'react';
import { useStatusColor } from './useStatusColor';
import { SubjectStatus } from '../../types/globalTypes';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

export interface DotProps {
  status: SubjectStatus;
  style?: StyleProp<ViewStyle>;
}

export const Dot: React.FC<DotProps> = ({ status, style }) => {
  const getBackgroundColor = useStatusColor();

  const styles = StyleSheet.create({
    itemIndicator: {
      width: 8,
      height: 8,
      backgroundColor: getBackgroundColor(status),
      borderRadius: 50,
      marginRight: 5,
    },
  });

  return <View style={StyleSheet.flatten([styles.itemIndicator, style])} />;
};
