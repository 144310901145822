import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import * as Linking from 'expo-linking';
import { useColors } from '../../../../shared/Ui/hooks';
import {
  AppButton,
  AppText,
  FileButton,
  FileButtonSelectedFile,
  Icon,
  InputError,
  Spacer,
} from '../../../../uiComponents';
import { DocumentInput } from './ManageProtocol.interface';
import { useTranslations } from '../../../../i18n/useTranslations';
export interface DocumentSelectorProps {
  document: DocumentInput;
  onChange: (file: DocumentInput) => void;
  error?: string | undefined | null;
}

export const DocumentSelector: React.FC<DocumentSelectorProps> = ({
  document: { currentDocument, nextDocument },
  onChange,
  error,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: { documentSelector: translate },
      },
    },
    translate: rootTranslate,
  } = useTranslations();
  const colors = useColors();
  const handleOpenDocument = () => {
    if (currentDocument?.downloadUri) {
      Linking.openURL(currentDocument?.downloadUri);
    }
  };

  const handleClearCurrent = () => {
    onChange({
      currentDocument: null,
      nextDocument: {
        id: null,
        upload: null,
      },
    });
  };

  const handleClearNext = () => {
    onChange({
      currentDocument: null,
      nextDocument: null,
    });
  };

  const handleChangeNext = (upload: FileButtonSelectedFile) => {
    onChange({
      currentDocument: null,
      nextDocument: {
        id: null,
        upload,
      },
    });
  };

  let content: React.ReactNode = null;

  if (currentDocument) {
    content = (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 20,
          width: 'auto',
        }}
      >
        <AppButton onPress={handleOpenDocument} icon="file">
          {translate.downloadCurrent}
        </AppButton>
        <TouchableOpacity
          onPress={handleClearCurrent}
          style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}
        >
          <Icon color={colors.red} name="x" size={24} />
          <AppText font="labelDefault" color="red">
            {translate.clear}
          </AppText>
        </TouchableOpacity>
      </View>
    );
  } else {
    content = (
      <FileButton
        onFileChange={handleChangeNext}
        onFileClear={handleClearNext}
        file={nextDocument?.upload || undefined}
        mediaType="image/pdf"
      >
        {rootTranslate.label.select}
      </FileButton>
    );
  }

  return (
    <Spacer
      items={{
        content,
        error: error ? <InputError touched={true} error={error} /> : null,
      }}
    />
  );
};
