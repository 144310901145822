import React from 'react';
import { SvgProps } from 'react-native-svg';
import { View, StyleSheet } from 'react-native';
import { SVG } from '../SVG/SVG';
import { AppText } from '../AppText/AppText';
import { useColors } from '../../shared/Ui/hooks';

export interface BadgeProps {
  status: 'in-sport' | 'removed-from-sport';
}

export const Badge: React.FC<BadgeProps> = ({ status }) => {
  const colors = useColors();
  const mainColor = status === 'in-sport' ? colors.green : colors.red;

  const styles = StyleSheet.create({
    badge: {
      display: 'flex',
    },
    inside: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      flex: 0,
      minWidth: 32,
      marginRight: 10,
    },
    label: {
      flex: 1,
    },
    text: {
      color: mainColor,
    },
  });

  const svgProps: SvgProps = { height: 32, width: 32 };

  const badge = status === 'in-sport' ? <SVG image="checkbox" {...svgProps} /> : <SVG image="cancel" {...svgProps} />;
  const text = status === 'in-sport' ? 'In Sport' : 'Out of Sport';

  return (
    <View style={styles.badge}>
      <View style={styles.inside}>
        <View style={styles.icon}>{badge}</View>
        <View style={styles.label}>
          <AppText font="labelDefault" style={styles.text}>
            {text}
          </AppText>
        </View>
      </View>
    </View>
  );
};
