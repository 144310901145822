/* Single tile
=============================== */

import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileMyKidsProps {
  onPress: () => void;
  disabled?: boolean;
}

export const TileMyKids: React.FC<TileMyKidsProps> = ({ onPress, disabled = false }) => {
  const { translate } = useTranslations();

  return (
    <BaseTile
      backgroundColor="green"
      labelColor="white"
      label={translate.home.tile.myKids}
      onPress={onPress}
      image="tile/people"
    />
  );
};
