import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../../fragments/ApiErrorFragment';
import { GroupFragment } from '../../fragments/GroupFragment';
import { UpdateGroup, UpdateGroupVariables } from './__generated__/UpdateGroup';

export const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup($organizationId: ID!, $groupId: ID!, $name: String!) {
    updateGroup(name: $name, groupId: $groupId, organizationId: $organizationId) {
      ... on Group {
        ...Group
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${GroupFragment}
  ${ApiErrorFragment}
`;

export const useUpdateGroup = (options?: MutationHookOptions<UpdateGroup, UpdateGroupVariables>) => {
  return useMutation<UpdateGroup, UpdateGroupVariables>(UPDATE_GROUP_MUTATION, options);
};
