import * as React from 'react';
import { ActionListItem } from './ActionListItem';
import { PendingDocumentSubmissionReviewActionItem as PendingDocumentSubmissionReviewActionItemType } from '../hooks/__generated__/PendingDocumentSubmissionReviewActionItem';
import { useTranslations } from '../../../i18n/useTranslations';
import { useNavigation } from '@react-navigation/core';
import { LoggedInNavType } from '../../LoggedIn.interface';
export interface PendingDocumentSubmissionReviewActionItemProps {
  item: PendingDocumentSubmissionReviewActionItemType;
}

export const PendingDocumentSubmissionReviewActionItem: React.FC<PendingDocumentSubmissionReviewActionItemProps> = ({
  item,
}) => {
  const { translateWithVars, translate } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ActionItems'>>();

  const handlePress = () =>
    navigation.navigate('LoggedIn_Profile', {
      screen: 'LoggedIn_Profile_RecoveryPlan',
      subjectId: item.subject.id,
      params: {
        screen: 'LoggedIn_Profile_RecoveryPlan_Stage',
        params: {
          stageId: item.id,
        },
      },
    });

  return (
    <ActionListItem
      onPress={handlePress}
      text={translateWithVars(translate.actionItems.pendingDocumentSubmissionActionItem, {
        subject: item.subject.fullName,
        organization: item.organization.name,
      })}
    />
  );
};
