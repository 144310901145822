import { useFormikContext } from 'formik';
import { isString, isUndefined } from 'lodash';
import * as React from 'react';
import { UpdateProtocolConfigFormValue } from '../../../ManageProtocol.interface';

import { EducationProtocolItemDocumentConfig } from './EducationProtocolItemDocumentConfig';
import { EducationProtocolItemUrlConfig } from './EducationProtocolItemUrlConfig';
export interface EducationProtocolItemConfigProps {
  index: number;
  totalItems: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
}

export const EducationProtocolItemConfig: React.FC<EducationProtocolItemConfigProps> = ({
  index,
  totalItems,
  onRemove,
  move,
}) => {
  const { values, errors, touched } = useFormikContext<UpdateProtocolConfigFormValue>();

  const itemError = errors?.educationProtocol?.items?.[index] ? errors.educationProtocol.items[index] : undefined;
  const itemTouched = touched?.educationProtocol?.items?.[index] ? touched.educationProtocol.items[index] : undefined;
  const value = values.educationProtocol.items[index] ? values.educationProtocol.items[index] : undefined;
  const error = !isString(itemError) && !isUndefined(itemError) ? itemError : undefined;
  const touch = !isString(itemTouched) && !isUndefined(itemTouched) ? itemTouched : undefined;
  const isFirst = index === 0;
  const isLast = index >= totalItems - 1;
  const moreThanOneItem = totalItems > 1;
  const canMoveUp = !isFirst && moreThanOneItem;
  const canMoveDown = !isLast && moreThanOneItem;
  const itemKey = `educationProtocol.items[${index}]`;

  if (value?.type === 'document') {
    return (
      <EducationProtocolItemDocumentConfig
        canMoveUp={canMoveUp}
        canMoveDown={canMoveDown}
        touch={touch}
        error={error}
        value={value}
        onRemove={onRemove}
        move={move}
        index={index}
        itemKey={itemKey}
      />
    );
  } else if (value?.type === 'url') {
    return (
      <EducationProtocolItemUrlConfig
        canMoveUp={canMoveUp}
        canMoveDown={canMoveDown}
        touch={touch}
        error={error}
        value={value}
        onRemove={onRemove}
        move={move}
        index={index}
        itemKey={itemKey}
      />
    );
  } else {
    return null;
  }
};
