import { gql } from '@apollo/client';
import { DocumentFragment } from '../../../../../fragments/DocumentFragment';
export const RecoveryPlanStageDocumentFragment = gql`
  fragment RecoveryPlanStageDocument on RecoveryPlanStageDocument {
    id
    order
    caseId
    organizationName
    protocolType
    protocolName
    protocolUrl
    stepStartTime
    state
    iCanReview
    iCanSubmit
    name
    description
    isFirstStage
    isLastStage
    isCurrentStage
    isUpcomingStage
    isCompletedStage
    rejectionReason
    templateDocument {
      ...Document
    }
    document {
      ...Document
    }
    timeGateHours
  }
  ${DocumentFragment}
`;
