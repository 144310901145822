import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useRecoveryPlanRoutes } from './hooks/useRecoveryPlanRoutes';
import { RecoveryPlanParamList } from './RecoveryPlan.interface';

const RecoveryPlan = createStackNavigator<RecoveryPlanParamList>();

export const RecoveryPlanNavigator: React.FC = () => {
  const routes = useRecoveryPlanRoutes();

  return (
    <RecoveryPlan.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <RecoveryPlan.Screen key={name} name={name} component={component} />
      ))}
    </RecoveryPlan.Navigator>
  );
};
