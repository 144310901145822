/* Custom phone text input with icon
=============================== */

import React from 'react';
import { NativeSyntheticEvent, TextInputFocusEventData, StyleSheet, View, Platform } from 'react-native';
import { DialCodeList } from './DialCodeList';
import { Input, InputProps } from '../Input/Input';
import { InputContainer } from '../InputContainer/InputContainer';
import { InputLabel } from '../InputLabel/InputLabel';
import { useIsMobile } from '../../hooks/ui/useResponsive';

interface PhoneInputProps<T extends string>
  extends Omit<InputProps, 'icon' | 'type' | 'placeholder' | 'beforeComponent' | 'onBlur' | 'onChangeText' | 'value'> {
  label?: string;
  phoneNumber: string;
  isoCode: T;
  onChangeIsoCode?: (isoCode: T) => void;
  onBlurIsoCode?: (e: any) => void;
  onBlurPhoneNumber?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onChangePhoneNumber?: (text: string) => void;
  placeholder?: string;
}

export const PhoneInput = <T extends string>({
  label,
  phoneNumber,
  isoCode,
  onChangeIsoCode,
  onBlurIsoCode,
  onChangePhoneNumber,
  onBlurPhoneNumber,
  placeholder,
  style,
  ...props
}: PhoneInputProps<T>) => {
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    root: {
      ...(!isMobile ? { width: 100 } : { width: 115, paddingRight: 0 }),
      borderColor: 'transparent',
      shadowColor: 'transparent',
    },
    isoCodeSelectorContainer: {
      width: Platform.OS === 'web' ? 120 : 100,
      bottom: Platform.OS === 'android' ? 10 : 0,
    },
    platformDialCode: {
      height: Platform.OS === 'ios' ? 30 : 40,
      top: Platform.OS == 'ios' ? 5 : 0,
    },
  });

  const isoCodeSelector = (
    <View style={styles.isoCodeSelectorContainer}>
      <DialCodeList<T> style={[styles.root, styles.platformDialCode]} value={isoCode} onChange={onChangeIsoCode} />
    </View>
  );

  const output = (
    <Input
      icon={'phone'}
      type="phone"
      placeholder={placeholder}
      beforeComponent={isoCodeSelector}
      onBlur={onBlurPhoneNumber}
      onChangeText={onChangePhoneNumber}
      value={phoneNumber || ''}
      style={StyleSheet.flatten([{ paddingVertical: 0 }, style])}
      keyboardType="phone-pad"
      autoComplete="tel"
      textContentType="telephoneNumber"
      {...props}
    />
  );

  if (label) {
    return (
      <InputContainer>
        <InputLabel label={label} />
        {output}
      </InputContainer>
    );
  } else {
    return output;
  }
};
