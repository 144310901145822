import { gql } from '@apollo/client';
import { useQuery } from '../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { GetUserTrainingStatus } from './__generated__/GetUserTrainingStatus';

export const GET_USER_TRAINING_STATUS = gql`
  query GetUserTrainingStatus {
    userTrainingStatus {
      ... on UserTrainingStatus {
        nccpNumber
        completed
        completedAt
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetUserTrainingStatus = () => useQuery<GetUserTrainingStatus, {}>(GET_USER_TRAINING_STATUS);
