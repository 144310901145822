import { EducationProtocolConfig } from '../screens/EducationProtocolConfig/EducationProtocolConfig';
import { Index } from '../Index';
import { ManageProtocolNavItem } from '../ManageProtocol.interface';
import { GeneralConfig } from '../screens/OverviewConfig/GeneralConfig';
import { RemoveProtocolInstructionsConfig } from '../screens/RemoveProtocolInstructionsConfig/RemoveProtocolInstructionsConfig';
import { RemoveProtocolRedFlagConfig } from '../screens/RemoveProtocolRedFlagConfig/RemoveProtocolRedFlagConfig';
import { RemoveProtocolStepsConfig } from '../screens/RemoveProtocolStepsConfig/RemoveProtocolStepsConfig';
import { ReturnProtocolMedicalAssessmentConfig } from '../screens/ReturnProtocolMedicalAssessmentConfig/ReturnProtocolMedicalAssessmentConfig';
import { ReturnProtocolChildProtocolConfig } from '../screens/ReturnProtocolChildProtocolConfig/ReturnProtocolChildProtocolConfig';
import { IncidentReportConfig } from '../screens/IncidentReportConfig/IncidentReportConfig';

const manageProtocolRoutes: ManageProtocolNavItem[] = [
  {
    name: 'LoggedIn_Admin_ManageProtocol_Index' as const,
    path: 'index',
    component: Index,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_General' as const,
    path: 'general',
    component: GeneralConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Education' as const,
    path: 'education',
    component: EducationProtocolConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Remove_Instructions' as const,
    path: 'remove/overview',
    component: RemoveProtocolInstructionsConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Remove_RedFlag' as const,
    path: 'remove/redFlag',
    component: RemoveProtocolRedFlagConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Remove_Steps' as const,
    path: 'remove/steps',
    component: RemoveProtocolStepsConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Remove_IncidentReport' as const,
    path: 'remove/incidentReport',
    component: IncidentReportConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Return_MedicalAssessment' as const,
    path: 'return/medicalAssessment',
    component: ReturnProtocolMedicalAssessmentConfig,
  },
  {
    name: 'LoggedIn_Admin_ManageProtocol_Return_ChildProtocol' as const,
    path: 'return/childProtocol/:name',
    component: ReturnProtocolChildProtocolConfig,
  },
];

export const useManageProtocolRoutes: () => ManageProtocolNavItem[] = () => {
  return manageProtocolRoutes;
};
