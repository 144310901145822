import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet } from 'react-native';
import { AppText, Input, PasswordHints, AppButton, AppModal } from '../../uiComponents';
import { usePasswordRules } from '../../hooks/usePasswordRules';
import { useFormikContext } from 'formik';
import { ChangePasswordInput } from './Account';
import { useTranslations } from '../../i18n/useTranslations';
import { useIsMobile } from '../../hooks/ui/useResponsive';
import { PasswordInput, PasswordChangeModal } from './Account';
import { isEmpty } from 'lodash';

interface PasswordContentProps {
  handleChangePassword: (input: PasswordInput) => void;
  showPasswordChangeModal: PasswordChangeModal;
  setShowPasswordChangeModal: Dispatch<SetStateAction<PasswordChangeModal>>;
}

export const PasswordContent: React.FC<PasswordContentProps> = ({
  handleChangePassword,
  showPasswordChangeModal,
  setShowPasswordChangeModal,
}) => {
  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    passwordInput: {
      width: '40%',
      paddingRight: 10,
      paddingBottom: 20,
    },
    changePasswordButton: {
      marginBottom: 15,
    },
  });

  const { values, handleChange, errors, touched, handleBlur } = useFormikContext<ChangePasswordInput>();

  const [showHints, setShowHints] = React.useState<boolean>(false);
  const passwordRules = usePasswordRules();
  const handlePasswordFieldFocus = () => setShowHints(true);
  const handlePasswordFieldBlur = (e: any, field: string) => {
    setShowHints(false);
    handleBlur(field)(e);
  };

  return (
    <>
      <AppModal
        mode="dialog"
        title={
          !showPasswordChangeModal.error
            ? translate.flash.title.passwordChanged
            : translate.flash.title.passwordNotChanged
        }
        visible={showPasswordChangeModal.visible}
        onDismiss={() => setShowPasswordChangeModal({ visible: false, text: '', error: false })}
        buttons={{
          ok: (
            <AppButton onPress={() => setShowPasswordChangeModal({ visible: false, text: '', error: false })}>
              {translate.admin.manageOrganization.ok}
            </AppButton>
          ),
        }}
      >
        <AppText>{showPasswordChangeModal.text}</AppText>
      </AppModal>
      <View style={!isMobile ? styles.passwordInput : null}>
        <AppText font="header3" style={{ marginBottom: 24 }}>
          {translate.account.yourDetails.changePassword}
        </AppText>
        <Input
          label={translate.placeholder.passwordExisting}
          onChangeText={handleChange('currentPassword')}
          onBlur={(e) => {
            handlePasswordFieldBlur(e, 'currentPassword');
          }}
          error={errors.currentPassword}
          touched={touched.currentPassword}
          value={values.currentPassword}
          type="password"
          onFocus={handlePasswordFieldFocus}
        />
      </View>
      <View style={!isMobile ? styles.passwordInput : null}>
        <Input
          label={translate.placeholder.passwordNew}
          onChangeText={handleChange('newPassword')}
          onBlur={(e) => {
            handlePasswordFieldBlur(e, 'newPassword');
          }}
          error={errors.newPassword}
          touched={touched.newPassword}
          value={values.newPassword}
          type="password"
          onFocus={handlePasswordFieldFocus}
        />
      </View>
      <PasswordHints
        password={values.newPassword ? values.newPassword : ''}
        visible={showHints}
        validColor="green"
        style={{ marginBottom: 15 }}
        rules={passwordRules}
      />
      <View style={!isMobile ? styles.passwordInput : null}>
        <Input
          label={translate.placeholder.passwordConfirm}
          onChangeText={handleChange('confirmNewPassword')}
          onBlur={handleBlur('confirmNewPassword')}
          error={errors.confirmNewPassword}
          touched={touched.confirmNewPassword}
          value={values.confirmNewPassword}
          type="password"
          onFocus={handlePasswordFieldFocus}
        />
      </View>
      <AppButton
        style={styles.changePasswordButton}
        onPress={() => handleChangePassword(values)}
        disabled={!isEmpty(errors) || !values.currentPassword || !values.confirmNewPassword || !values.currentPassword}
      >
        {translate.account.yourDetails.changePassword}
      </AppButton>
    </>
  );
};
