import { gql } from '@apollo/client';

export const SimpleCaseFragment = gql`
  fragment SimpleCase on Case {
    id
    createdDate
    status
    organization {
      id
      name
    }
  }
`;
