import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Formik, FormikErrors } from 'formik';
import { compareAsc } from 'date-fns';
import { AppButton, AppScrollView, AppText, FileButtonSelectedFile, Layout } from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';
import { RadioButtonResponse, Role } from '../../types/globalTypes';
import { IncidentReportContent } from './IncidentReportContent';
import { IncidentType } from '../../types/globalTypes';
import { Chrome } from '../shared/Chrome/Chrome';
import { useGetIncidentReportCase } from './hooks/useGetIncidentReportCase';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../LoggedIn.interface';
import { RouteGuard } from '../shared/RouteGuard';
import { useSubmitIncidentReport } from './hooks/useSubmitIncidentReport';
import { useGetAllUserGroups } from '../../hooks/useGetAllUserGroups';

export interface IncidentReportFormData {
  incidentDate: string;
  hour: string;
  minutes: string;
  location?: string;
  incidentDescription?: string;
  type: IncidentType | '';
  typeDetails?: string;
  hasOtherSymptoms?: RadioButtonResponse;
  hasOtherSymptomsDetails?: string;
  hasOtherInjuries?: RadioButtonResponse;
  hasOtherInjuriesDetails?: string;
  hasPreviousConcussion?: RadioButtonResponse;
  hasPreviousConcussionDetails?: string;
  hasMedicalConditions?: RadioButtonResponse;
  hasMedicalConditionsDetails?: string;
  hasMedication?: RadioButtonResponse;
  hasMedicationDetails?: string;
  group: string;
}

export const IncidentReport: React.FC<{ route: { params: { caseId: string } } }> = (props) => {
  const caseId = props.route.params.caseId;
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_IncidentReport'>>();
  const [submitIncidentReport] = useSubmitIncidentReport();

  const [file, updateFile] = useState<FileButtonSelectedFile | null>(null);
  const { data } = useGetIncidentReportCase({ variables: { caseId } });

  const { data: groupData } = useGetAllUserGroups({
    variables: {
      participantId: data && data.getIncidentReportCase.subject ? data.getIncidentReportCase.subject.id : '',
    },
    skip: !data?.getIncidentReportCase.subject?.id,
  });

  if (!data?.getIncidentReportCase || !groupData) {
    return <Chrome titleText={translate.incidentReport.title} mode="title-card" autoScroll={false} content={null} />;
  }

  const { subject, incidentReportConfig, organization, status, createdDate } = data?.getIncidentReportCase;

  const groupsForOrganization = groupData.allUserGroups
    .filter((group) => group.organizationId === organization.id)
    .filter((value, index, self) => {
      return index === self.findIndex((item) => item.group.id === value.group.id);
    });

  if (!subject || !incidentReportConfig) {
    navigation.navigate('LoggedIn_Home');
    return null;
  }

  const styles = StyleSheet.create({
    instructionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    skipText: {
      marginRight: 15,
    },
  });

  const incidentDate = new Date(createdDate);
  const incidentHour = incidentDate.getHours();
  const incidentMinutes = incidentDate.getMinutes();

  const validateRadioOptions = (
    values: IncidentReportFormData,
    field: string,
    errors: FormikErrors<IncidentReportFormData>
  ) => {
    if (!values[field]) {
      errors[field] = translate.error.message.mustSelectOption;
    }

    if (values[field] === RadioButtonResponse.YES && !values[`${field}Details`]) {
      errors[`${field}Details`] = translate.error.message.cantBeEmpty;
    }
  };

  const validate = (values: IncidentReportFormData) => {
    let errors: FormikErrors<IncidentReportFormData> = {};

    const mergedDateTime = new Date(values.incidentDate).setHours(parseInt(values.hour), parseInt(values.minutes));

    const isPast = compareAsc(new Date(), mergedDateTime);

    if (isPast === -1) {
      errors.incidentDate = translate.incidentReport.dateError;
    }

    if (!values.type) {
      errors.type = translate.error.message.cantBeEmpty;
    }

    if ((values.type === IncidentType.MULTIPLE || values.type === IncidentType.OTHER) && !values.typeDetails) {
      errors.typeDetails = translate.error.message.cantBeEmpty;
    }

    ['hasOtherSymptoms', 'hasOtherInjuries', 'hasPreviousConcussion', 'hasMedicalConditions', 'hasMedication'].forEach(
      (field) => validateRadioOptions(values, field, errors)
    );

    if (values.group === 'select') {
      errors.group = translate.error.message.cantBeEmpty;
    }

    return errors;
  };

  const getInitialGroup = (): string => {
    if (groupsForOrganization) {
      switch (groupsForOrganization.length) {
        case 0:
          return '';
        case 1:
          return groupsForOrganization[0].group.id;
        default:
          return 'select';
      }
    } else {
      return '';
    }
  };

  const initialValues: IncidentReportFormData = {
    incidentDate: incidentDate.toJSON(),
    hour: incidentHour.toString(),
    minutes: incidentMinutes.toString(),
    location: '',
    type: '',
    typeDetails: '',
    incidentDescription: '',
    hasOtherSymptoms: undefined,
    hasOtherSymptomsDetails: '',
    hasOtherInjuries: undefined,
    hasOtherInjuriesDetails: '',
    hasPreviousConcussion: undefined,
    hasPreviousConcussionDetails: '',
    hasMedicalConditions: undefined,
    hasMedicalConditionsDetails: '',
    hasMedication: undefined,
    hasMedicationDetails: '',
    group: getInitialGroup(),
  };

  const goToProfile = () =>
    navigation.navigate('LoggedIn_Profile', {
      screen: 'LoggedIn_Profile_Overview',
      subjectId: subject.id,
    });

  const handleSubmitButtonPress = (values) => storeIncidentReport(values).then(goToProfile);
  const storeIncidentReport = async (values: IncidentReportFormData) => {
    const mergedDateValues = new Date(
      new Date(new Date(values.incidentDate).setHours(parseInt(values.hour))).setMinutes(parseInt(values.minutes))
    ).toJSON();

    return submitIncidentReport({
      variables: {
        caseId: caseId,
        input: {
          subjectId: subject.id,
          organizationId: organization.id,
          incidentDate: mergedDateValues,
          location: values.location,
          incidentDescription: values.incidentDescription,
          type: values.type ? values.type : null,
          typeDetails: values.typeDetails && values.type === IncidentType.OTHER ? values.typeDetails : null,
          hasOtherSymptoms: values.hasOtherSymptoms,
          hasOtherSymptomsDetails:
            values.hasOtherSymptoms === RadioButtonResponse.YES ? values.hasOtherSymptomsDetails : null,
          hasOtherInjuries: values.hasOtherInjuries,
          hasOtherInjuriesDetails:
            values.hasOtherInjuries === RadioButtonResponse.YES ? values.hasOtherInjuriesDetails : null,
          hasPreviousConcussion: values.hasPreviousConcussion,
          hasPreviousConcussionDetails:
            values.hasPreviousConcussion === RadioButtonResponse.YES ? values.hasPreviousConcussionDetails : null,
          hasMedicalConditions: values.hasMedicalConditions,
          hasMedicalConditionsDetails:
            values.hasMedicalConditions === RadioButtonResponse.YES ? values.hasMedicalConditionsDetails : null,
          hasMedication: values.hasMedication,
          hasMedicationDetails: values.hasMedication === RadioButtonResponse.YES ? values.hasMedicationDetails : null,
          file,
          group: values.group,
        },
      },
    });
  };

  const content = (
    <Layout>
      <AppScrollView>
        <AppText font="header2" gutter={true}>
          {translateWithVars(translate.incidentReport.subjectName, {
            subjectName: `${subject?.firstName} ${subject?.lastName}`,
          })}
        </AppText>
        <View style={styles.instructionContainer}>
          <AppText style={styles.skipText}>{translate.incidentReport.instructions}</AppText>
          <AppButton onPress={goToProfile}>{translate.incidentReport.skipForNow}</AppButton>
        </View>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitButtonPress}
          validate={validate}
          isInitialValid={false}
        >
          <IncidentReportContent
            incidentReportConfig={incidentReportConfig}
            subject={subject}
            handleSubmitButtonPress={handleSubmitButtonPress}
            updateFile={updateFile}
            file={file}
            status={status}
            participantGroups={groupsForOrganization}
          />
        </Formik>
      </AppScrollView>
    </Layout>
  );

  return (
    <RouteGuard acceptedRoles={[Role.PRIMARY_DESIGNATED_PERSON, Role.SECONDARY_DESIGNATED_PERSON]}>
      <Chrome
        titleText={translate.incidentReport.title}
        mode="title-card"
        navBarProps={{
          showHamburger: true,
          onBack: () => {
            navigation.dispatch({
              ...CommonActions.reset({
                index: 1,
                routes: [
                  {
                    name: 'LoggedIn_Home',
                  },
                  {
                    name: 'LoggedIn_Profile',
                    params: {
                      subjectId: subject.id,
                    },
                    state: {
                      routes: [
                        {
                          name: 'LoggedIn_Profile_Overview',
                        },
                      ],
                    },
                  },
                ],
              }),
            });
          },
        }}
        autoScroll={false}
        content={content}
      />
    </RouteGuard>
  );
};
