import * as React from 'react';
import { useContext, useState } from 'react';
import { LoggedInPaths } from '../screens/LoggedIn.interface';

export interface InitialLoginRoute {
  name: LoggedInPaths;
  initialParams?: Record<string, string>;
}
export interface InitialNavigationContextType {
  initialLoginRoute: InitialLoginRoute;
  setInitialLoginRoute: (route: InitialLoginRoute) => void;
}

const InitialNavigationContext = React.createContext<InitialNavigationContextType>({
  initialLoginRoute: {
    name: 'LoggedIn_Home',
  },
  setInitialLoginRoute: () => {
    /* noop */
  },
});

export const useInitialNavigation = () => useContext(InitialNavigationContext);

export interface InitialNavigationProps {
  initialLoginRoute: {
    name: LoggedInPaths;
    initialParams?: Record<string, string>;
  };
  children: React.ReactNode;
}

export const InitialNavigationRoutes: React.FC<InitialNavigationProps> = ({
  children,
  initialLoginRoute: _initialLoginRoute,
}) => {
  const [initialLoginRoute, setInitialLoginRoute] = useState(_initialLoginRoute);

  return (
    <InitialNavigationContext.Provider value={{ initialLoginRoute, setInitialLoginRoute }}>
      {children}
    </InitialNavigationContext.Provider>
  );
};
