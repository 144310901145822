import React from 'react';
import { useCurrentUser } from '../../../shared/CurrentUser/useCurrentUser';
import { VerifiedLoggedIn } from './VerifiedLoggedIn';
import { VerifiedLoggedOut } from './VerifiedLoggedOut';

export const VerifiedScreen = () => {
  const { currentUser } = useCurrentUser();

  if (currentUser) {
    return <VerifiedLoggedIn user={currentUser} />;
  } else {
    return <VerifiedLoggedOut />;
  }
};
