import { FieldArray } from 'formik';
import * as React from 'react';
import { RemoveProtocolStepsConfig } from './RemoveProtocolStepsConfigContent';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Spacer } from '../../../../../../uiComponents';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: { remove: translate },
        },
      },
    },
  } = useTranslations();

  return (
    <Spacer
      items={{
        heading: (
          <AppText font="header3" gutter={true}>
            {translate.steps}
          </AppText>
        ),
        steps: <FieldArray name="removeProtocol.steps" children={(vals) => <RemoveProtocolStepsConfig {...vals} />} />,
      }}
    />
  );
};
