import { isString, useFormikContext } from 'formik';
import { isUndefined } from 'lodash';
import * as React from 'react';
import { UpdateProtocolConfigFormValue } from '../../../ManageProtocol.interface';
import { RemoveProtocolStepChallengeConfig } from './RemoveProtocolStepChallengeConfig';
import { RemoveProtocolStepSymptomConfig } from './RemoveProtocolStepSymptomConfig';

export interface RemoveProtocolStepConfigProps {
  index: number;
  totalItems: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
}

export const RemoveProtocolStepConfig: React.FC<RemoveProtocolStepConfigProps> = ({
  index,
  totalItems,
  onRemove,
  move,
}) => {
  const { values, errors, touched } = useFormikContext<UpdateProtocolConfigFormValue>();

  const itemError = errors?.removeProtocol?.steps?.[index] ? errors.removeProtocol.steps[index] : undefined;
  const itemTouched = touched?.removeProtocol?.steps?.[index] ? touched.removeProtocol.steps[index] : undefined;
  const value = values.removeProtocol.steps[index] ? values.removeProtocol.steps[index] : undefined;
  const error = !isString(itemError) && !isUndefined(itemError) ? itemError : undefined;
  const touch = !isString(itemTouched) && !isUndefined(itemTouched) ? itemTouched : undefined;
  const isFirst = index === 0;
  const isLast = index >= totalItems - 1;
  const moreThanOneItem = totalItems > 1;
  const canMoveUp = !isFirst && moreThanOneItem;
  const canMoveDown = !isLast && moreThanOneItem;
  const itemKey = `removeProtocol.steps[${index}]`;

  if (value?.type === 'symptom') {
    return (
      <RemoveProtocolStepSymptomConfig
        canMoveUp={canMoveUp}
        canMoveDown={canMoveDown}
        touch={touch}
        error={error}
        value={value}
        onRemove={onRemove}
        move={move}
        index={index}
        itemKey={itemKey}
      />
    );
  } else if (value?.type === 'challenge') {
    return (
      <RemoveProtocolStepChallengeConfig
        canMoveUp={canMoveUp}
        canMoveDown={canMoveDown}
        touch={touch}
        error={error}
        value={value}
        onRemove={onRemove}
        move={move}
        index={index}
        itemKey={itemKey}
      />
    );
  } else {
    return null;
  }
};
