import React from 'react';
import { CaseItem } from '../../../../../fragments/__generated__/CaseItem';
import { CaseLogContextSubject } from '../CaseLogs.interface';
import { CaseItemCaseStatusChangeView } from './CaseItemCaseStatusChangeView';
import { CaseItemMedicalAssessmentReviewApprovedView } from './CaseItemMedicalAssessmentReviewApprovedView';
import { CaseItemMedicalAssessmentReviewRejectedView } from './CaseItemMedicalAssessmentReviewRejectedView';
import { CaseItemMedicalAssessmentSubmissionView } from './CaseItemMedicalAssessmentSubmissionView';
import { CaseItemRfaProtocolResultView } from './CaseItemRfaProtocolResultView/CaseItemRfaProtocolResultView';
import { CaseItemRtaProtocolCompletionView } from './CaseItemRtaProtocolCompletionView';
import { CaseItemRtaProtocolStageChangeView } from './CaseItemRtaProtocolStageChangeView';
import { CaseItemRtaProtocolStageDocumentReviewApprovedView } from './CaseItemRtaProtocolStageDocumentReviewApprovedView';
import { CaseItemRtaProtocolStageDocumentReviewRejectedView } from './CaseItemRtaProtocolStageDocumentReviewRejectedView';
import { CaseItemRtaProtocolStageDocumentSubmissionView } from './CaseItemRtaProtocolStageDocumentSubmissionView';
import { CaseItemSubjectStatusChangeView } from './CaseItemSubjectStatusChangeView';

export interface CaseLogItemProps {
  item: CaseItem;
  subject: CaseLogContextSubject;
  organizationName: string;
}

export const CaseLogItem: React.FC<CaseLogItemProps> = ({ item, subject, organizationName }) => {
  switch (item.__typename) {
    case 'CaseItemCaseStatusChange':
      return <CaseItemCaseStatusChangeView item={item} subject={subject} />;
    case 'CaseItemMedicalAssessmentReviewApproved':
      return <CaseItemMedicalAssessmentReviewApprovedView item={item} />;
    case 'CaseItemMedicalAssessmentReviewRejected':
      return <CaseItemMedicalAssessmentReviewRejectedView item={item} />;
    case 'CaseItemMedicalAssessmentSubmission':
      return <CaseItemMedicalAssessmentSubmissionView item={item} />;
    case 'CaseItemRfaProtocolResult':
      return <CaseItemRfaProtocolResultView item={item} subject={subject} organizationName={organizationName} />;
    case 'CaseItemRtaProtocolCompletion':
      return <CaseItemRtaProtocolCompletionView item={item} subject={subject} />;
    case 'CaseItemRtaProtocolStageChange':
      return <CaseItemRtaProtocolStageChangeView item={item} subject={subject} />;
    case 'CaseItemRtaProtocolStageDocumentReviewApproved':
      return <CaseItemRtaProtocolStageDocumentReviewApprovedView item={item} />;
    case 'CaseItemRtaProtocolStageDocumentReviewRejected':
      return <CaseItemRtaProtocolStageDocumentReviewRejectedView item={item} />;
    case 'CaseItemRtaProtocolStageDocumentSubmission':
      return <CaseItemRtaProtocolStageDocumentSubmissionView item={item} />;
    case 'CaseItemSubjectStatusChange':
      return <CaseItemSubjectStatusChangeView item={item} subject={subject} />;
    default:
      return null;
  }
};
