import { useFormikContext } from 'formik';
import React from 'react';
import { UpdateProtocolConfigFormValue } from '../../ManageProtocol.interface';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppText, Input, Spacer, SpacerItems } from '../../../../../../uiComponents';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = () => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<UpdateProtocolConfigFormValue>();
  const {
    translate: {
      admin: {
        manageProtocol: { updateProtocolConfigForm: translate },
      },
    },
  } = useTranslations();

  const items: SpacerItems = {
    heading: (
      <>
        <AppText font="header3" gutter={true}>
          {translate.overview.title}
        </AppText>
        <AppText>{translate.overview.instruction1}</AppText>
        <AppText>{translate.overview.instruction2}</AppText>
      </>
    ),
    protocolName: (
      <>
        <Input
          label={translate.overview.protocolName}
          value={values.protocolName}
          error={errors.protocolName}
          touched={touched.protocolName}
          onChangeText={handleChange('protocolName')}
          onBlur={handleBlur('protocolName')}
        />
      </>
    ),
    protocolUrl: (
      <Input
        icon="link"
        label={translate.overview.protocolUrl}
        value={values.protocolUrl}
        error={errors.protocolUrl}
        touched={touched.protocolUrl}
        onChangeText={handleChange('protocolUrl')}
        onBlur={handleBlur('protocolUrl')}
      />
    ),
  };

  return <Spacer size="large" items={items} />;
};
