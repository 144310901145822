import { AssessContextState, AssessStepValue } from '../../../Assess.interface';

const logStep = (stepValue: AssessStepValue) => {
  if (stepValue.type === 'symptom') {
    return `\n\tStep:\n\t\tID: ${stepValue.stepId}\n\t\tType:Symptom\n\t\tViewed: ${
      stepValue.viewed ? 'Yes' : 'No'
    }\n\t\t\t${stepValue.result.map((val) => `${val.symptomId}:${val.result}`).join('\n\t\t\t')}`;
  } else {
    return `\n\tStep:\n\t\tID: ${stepValue.stepId}\n\t\tType:Challenge\n\t\t
    }\n\t\t\t${stepValue.results.map((val) => `${val.challengeId}:${val.result}`).join('\n\t\t\t')}`;
  }
};
export const logState = (nextState: AssessContextState) => {
  console.log(
    'NEXT ASSESSMENT STATE',
    `
  Initialized?: ${nextState.initialized}
  Is Emergency?: ${nextState.emergency ? 'Yes' : 'No'}
  Subject: ${nextState.subject?.fullName}
  Red Flags Viewed: ${nextState.redFlags.viewed}
  Red Flags: 
    Viewed: ${nextState.redFlags.viewed ? 'Yes' : 'No'}
${
  nextState.redFlags.viewed
    ? nextState.redFlags.values.map(({ redFlagId, result }) => `${redFlagId}:${result}`).join('\n\t')
    : ''
}
  Num Protocols Available: ${nextState.availableProtocolOptions?.length || 0}
  Selected Protocol Organization Name: ${nextState.selectedProtocolOption?.name}
  Suggested Result: ${nextState.suggestedResult}
  Selected Result: ${nextState.selectedResult}
  Steps: ${nextState.steps.map(logStep)}
  `
  );
};
