import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppButton, AppText, Spacer, useLoading } from '../../../uiComponents';
import { useAppAlert } from '../../../hooks/ui/useAppAlert';
import { useTranslations } from '../../../i18n/useTranslations';
import { Chrome } from '../../../shared/Chrome/Chrome';
import { RootNavType } from '../../../RootNavigator.interface';
import { useUpdateToken } from '../../../shared/Firebase/hooks/useUpdateToken';
import { useCurrentUserLazyQuery } from '../../../shared/CurrentUser/hooks/useCurrentUserLazyQuery';
import { CurrentUser } from '../../../fragments/__generated__/CurrentUser';
import { UnverifiedCurrentUser } from '../../../fragments/__generated__/UnverifiedCurrentUser';

export interface VerifiedLoggedInParams {
  user: CurrentUser | UnverifiedCurrentUser;
}
export const VerifiedLoggedIn: React.FC<VerifiedLoggedInParams> = ({ user }) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RootNavType<any>>();
  const updateToken = useUpdateToken();

  const [loading, setLoading] = useState(false);
  useLoading(loading);
  const { alert } = useAppAlert();

  const [currentUserLazyQuery] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (val) => {
      setLoading(false);
      if (val.currentUser.__typename === 'CurrentUser') {
        setTimeout(() => navigation.navigate('LoggedIn', { screen: 'LoggedIn_Home' }));
      } else {
        alert(translate.verified.loggedIn.stillNotVerified);
      }
    },
  });

  const handleContinue = () => {
    setLoading(true);
    updateToken().then(() => currentUserLazyQuery());
  };

  const content = (
    <Spacer
      size="large"
      items={{
        title: <AppText font="header3">{translate.verified.subtitle}</AppText>,
        description: (
          <AppText>{user ? translate.verified.loggedIn.description : translate.verified.loggedOut.description}</AppText>
        ),
        button: (
          <AppButton disabled={loading} loading={loading} onPress={handleContinue}>
            {user ? translate.verified.loggedIn.continue : translate.verified.loggedOut.login}
          </AppButton>
        ),
      }}
    />
  );

  return <Chrome mode="title-card" titleText={translate.verified.title} content={content} />;
};
