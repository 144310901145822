import { gql } from '@apollo/client';
import { ApiErrorFragment } from '../../../../fragments/ApiErrorFragment';
import { useMutation, UseMutationOptions } from '../../../../hooks/useMutation';
import { ResetPassword, ResetPasswordVariables } from './__generated__/ResetPassword';

export const USE_RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      ... on ResetPasswordStatus {
        success
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;
export const useResetPassword = (options?: UseMutationOptions<ResetPassword, ResetPasswordVariables>) =>
  useMutation<ResetPassword, ResetPasswordVariables>(USE_RESET_PASSWORD, options);
