import React from 'react';
import { createContext } from 'react';
import { AdminContextType } from './Admin.interface';

export const AdminContext = createContext<AdminContextType>({
  organizationId: '',
} as any);

export interface AdminContextProviderProps {
  organizationId?: string;
  children: React.ReactNode;
}

export const AdminContextProvider: React.FC<AdminContextProviderProps> = ({
  organizationId: _organizationId,
  children,
}) => {
  const organizationId = _organizationId || '';

  return <AdminContext.Provider value={{ organizationId }} children={children} />;
};
