import { StackNavigationProp } from '@react-navigation/stack';
import { LoggedInParamList } from './screens/LoggedIn.interface';
import { RootParamList } from './RootNavigator.interface';

export enum Environment {
  PRODUCTION,
  STAGE,
  DEVELOPMENT,
}

export interface FirebaseWebConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface BuildAppConfigOptions {
  env?: string;
  graphqlEndpoint?: string;
  firebaseApiKey?: string;
  firebaseAuthDomain?: string;
  firebaseAppId?: string;
  firebaseProjectId?: string;
  firebaseStorageBucket?: string;
  firebaseWebConfig?: FirebaseWebConfig;
  firebaseAuthEmulatorHost?: string;
  sentryDsn?: string;
  hostUri?: string;
  releaseChannel?: string;
  release?: string;
  linkingPrefix?: string;
}

export interface AppConfigTypeSentry {
  dsn?: string;
  environment?: string;
  debug?: boolean;
  release?: string;
}
export interface AppConfigType {
  env: Environment;
  graphqlEndpoint: string;
  version?: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    appId: string;
    projectId: string;
    storageBucket: string;
  };
  firebaseAuthEmulatorHost?: string;
  sentry?: AppConfigTypeSentry;
  linkingPrefix?: string;
}

export type AppParamList = LoggedInParamList & RootParamList;

export type AppPaths = keyof AppParamList;

export type AppNavItem = RouteConfig<AppPaths>;

export type AppNavType<CurrentPath extends AppPaths> = StackNavigationProp<AppParamList, CurrentPath>;
export interface RouteConfig<T, U = void> {
  component: React.ComponentType;
  name: T;
  path: string;
  children?: Array<RouteConfig<U>>;
  label?: string | undefined | null;
  mode?: 'public' | 'private' | 'any';
  isInitialRoute?: boolean | undefined | null;

  initialParams?: Record<string, string>;
}
