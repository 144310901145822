import { SymptomResult } from '../../../../../types/globalTypes';
import { AssessContextState } from '../../../Assess.interface';

export const setRedFlagsViewed = (nextState: AssessContextState): AssessContextState => {
  const allRedFlags = nextState.selectedProtocolOption?.protocol.removeProtocol.redFlag.flags || [];
  return {
    ...nextState,
    redFlags: {
      values: allRedFlags.map(({ id }) => ({ redFlagId: id, result: SymptomResult.ABSENT })),
      viewed: true,
    },
  };
};
