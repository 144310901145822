import React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { AppText, Icon } from '../../../../../uiComponents';

export interface EditableTitleProps {
  onEdit: () => void;
  name: string;
  onDelete: () => void;
}

export const EditableTitle: React.FC<EditableTitleProps> = ({ onEdit, onDelete, name }) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexGrow: 1,
      width: '100%',
    },
    icon: {
      marginLeft: 15,
    },
  });

  return (
    <View style={styles.container}>
      <AppText font="header1">{name}</AppText>
      <TouchableOpacity onPress={onEdit}>
        <Icon style={styles.icon} name="edit-2" />
      </TouchableOpacity>
      <TouchableOpacity onPress={onDelete}>
        <Icon style={styles.icon} name="trash" />
      </TouchableOpacity>
    </View>
  );
};
