import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { NavBarProps } from '../../shared/Chrome/Chrome';
import { Chrome as ChromeBase } from '../shared/Chrome/Chrome';
import { AdminNavType } from './Admin.interface';

export interface AdminChromeProps extends Pick<NavBarProps, 'titleText' | 'content' | 'footer'> {}

export const AdminChrome: React.FC<AdminChromeProps> = (props) => {
  const navigation = useNavigation<AdminNavType<'LoggedIn_Admin_Index'>>();

  const onBack = () => navigation.navigate('LoggedIn_Home');

  return (
    <ChromeBase
      mode="title-card"
      navBarProps={{ onBack }}
      titleText={props.titleText}
      content={props.content}
      footer={props.footer}
    />
  );
};
