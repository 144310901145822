import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';
import { LoadingContext } from './LoadingContext';
import { useColors } from '../../shared/Ui/hooks/useColors';

interface LoadingProps {
  visible?: boolean;
  label?: string;
  children?: React.ReactNode;
}

// Using a provider here to solve issue where modal wouldn't unmount on navigation change
// Using an absolutely positioned div here so that loading screen interoperates well with app-alert.
// A bug arises when loading uses modals where alerts are dismissed without user seeing them when loading switches from true to false

export const Loading: React.FC<LoadingProps> = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const colors = useColors();

  const style = StyleSheet.create({
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1999,
      // backgroundColor: colors.overlay,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  // Workaround for https://github.com/joinspontaneous/react-native-loading-spinner-overlay/issues/30
  const setLoading = (loading: boolean) => setTimeout(() => setVisible(loading), 100);

  const spinner = visible ? (
    <View style={style.overlay}>
      <ActivityIndicator />
    </View>
  ) : null;

  return (
    <LoadingContext.Provider value={{ setLoading }}>
      {spinner}
      {children}
    </LoadingContext.Provider>
  );
};
