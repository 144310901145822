import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ManageGroupsParamList } from './ManageGroups.interface';
import { useManageGroupsRoutes } from './hooks/useManageGroupsRoutes';

const ManageGroupsNav = createStackNavigator<ManageGroupsParamList>();
const routes = useManageGroupsRoutes();

export const ManageGroupsNavigator: React.FC<{}> = () => {
  return (
    <ManageGroupsNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <ManageGroupsNav.Screen key={name} name={name} component={component} />
      ))}
    </ManageGroupsNav.Navigator>
  );
};
