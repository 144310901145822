/* Single tile
=============================== */

import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { BaseTile } from '../../../uiComponents';

export interface TileMyProfileProps {
  onPress: () => void;
  disabled?: boolean;
  warning?: boolean;
}

export const TileMyProfile: React.FC<TileMyProfileProps> = ({ onPress, disabled = false, warning = false }) => {
  const { translate } = useTranslations();

  return <BaseTile labelColor="white" backgroundColor="blue" label={translate.home.tile.myProfile} onPress={onPress} />;
};
