import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ManageSeasonsParamList } from './ManageSeasons.interface';
import { useManageSeasonsRoutes } from './hooks/useManageSeasonsRoutes';

const ManageSeasonsNav = createStackNavigator<ManageSeasonsParamList>();
const routes = useManageSeasonsRoutes();

export interface ManageSeasonsNavigatorProps {}

export const ManageSeasonsNavigator: React.FC<ManageSeasonsNavigatorProps> = () => {
  return (
    <ManageSeasonsNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component, name }) => (
        <ManageSeasonsNav.Screen key={name} name={name} component={component} />
      ))}
    </ManageSeasonsNav.Navigator>
  );
};
