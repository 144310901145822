import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AppText } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { SubjectStatus } from '../../../types/globalTypes';
import { useIsMobile } from '../../../hooks/ui/useResponsive';
import { ProfileOverview } from '../Profile.interface';
import { GenericProfile } from '../Profile.interface';
import { GetCircleOfCare_circleOfCare_CircleOfCare_guardians } from './hooks/__generated__/GetCircleOfCare';
import { useDateFormat } from '../../../hooks/ui/useDateFormat';
import { UserProfileData } from './hooks/useGetProfile';

interface ProfileDetailsProps {
  profile: ProfileOverview;
  profileDetails?: GenericProfile | UserProfileData | null;
  guardians: GetCircleOfCare_circleOfCare_CircleOfCare_guardians[] | null;
}

export const ProfileDetails: React.FC<ProfileDetailsProps> = ({ profile, profileDetails, guardians }) => {
  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const {
    subjectMemberships: { organizations },
  } = profile.overview;

  const { formatDate } = useDateFormat();

  const styles = StyleSheet.create({
    title: {
      marginTop: 10,
      marginBottom: 20,
    },
    profileField: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    profileLabel: {
      marginRight: 20,
      marginBottom: 24,
    },
    content: {
      marginLeft: 20,
    },
    multipleOrganizations: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      marginRight: 15,
      marginLeft: 25,
      marginBottom: 24,
      alignItems: isMobile ? 'flex-start' : 'center',
    },
    emailAndBirthDate: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'flex-start' : 'center',
      justifyContent: 'flex-start',
      marginBottom: 24,
    },
    emailOrBirthDateField: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
    },
    emailField: {
      marginRight: 50,
      marginBottom: isMobile ? 20 : 0,
    },
    emailOrBirthDateLabel: {
      marginRight: 20,
      flexShrink: 1,
    },
    emailOrBirthDateContent: {
      flexShrink: 1,
    },
    orgSectionHeader: {
      width: 350,
    },
  });

  const getStatusLabel = (status: SubjectStatus | undefined): React.ReactNode => {
    switch (status) {
      case 'ON_PROTOCOL':
        return (
          <AppText color="orange" font="bodyDefault">
            {translate.subjectStatus.onProtocol}
          </AppText>
        );
      case 'ACTIVE':
        return (
          <AppText color="green" font="bodyDefault">
            {translate.subjectStatus.active}
          </AppText>
        );
      case 'REMOVED':
        return (
          <AppText color="red" font="bodyDefault">
            {translate.subjectStatus.removed}
          </AppText>
        );
      default:
        return '';
    }
  };

  return organizations &&
    organizations.length > 0 &&
    (profileDetails?.__typename === 'CurrentUser' || profileDetails?.__typename === 'Subject') ? (
    <View>
      <View style={styles.title}>
        <AppText font="header3">{translate.myProfile.title}</AppText>
      </View>

      <View style={styles.content}>
        <View
          style={[
            styles.profileField,
            {
              flexDirection: organizations.length > 1 ? 'column' : 'row',
            },
          ]}
        >
          <AppText font="header6" style={styles.profileLabel}>
            {`${translate.subjectStatus.modalTitle}:`}
          </AppText>
          {organizations.length > 1 ? (
            <View>
              {organizations.map((organization) => (
                <View style={styles.multipleOrganizations} key={organization.id}>
                  <View style={styles.orgSectionHeader}>
                    <AppText font="labelDefault">{organization.organization.name}</AppText>
                  </View>
                  {getStatusLabel(organization.status)}
                </View>
              ))}
            </View>
          ) : (
            getStatusLabel(organizations[0].status)
          )}
        </View>

        <View style={styles.emailAndBirthDate}>
          <View style={[styles.emailOrBirthDateField, styles.emailField]}>
            <AppText font="header6" style={styles.emailOrBirthDateLabel}>
              {`${translate.account.yourDetails.email}:`}
            </AppText>
            <AppText>{profileDetails.email}</AppText>
          </View>
          <View style={styles.emailOrBirthDateField}>
            <AppText font="header6" style={styles.emailOrBirthDateLabel}>
              {`${translate.placeholder.dateOfBirth}:`}
            </AppText>
            <AppText style={styles.emailOrBirthDateContent}>
              {profileDetails.__typename === 'CurrentUser'
                ? formatDate(new Date(profileDetails.birthday), 'PP')
                : profileDetails.birthday
                ? formatDate(new Date(parseInt(profileDetails?.birthday)), 'PP')
                : ''}
            </AppText>
            {profileDetails.__typename === 'Subject' && profileDetails.birthday ? (
              <AppText style={[{ marginLeft: 30 }, styles.emailOrBirthDateContent]}>
                {`${profileDetails.age} ${translate.myProfile.yearsOld}`}
              </AppText>
            ) : null}
          </View>
        </View>

        <View style={styles.profileField}>
          <AppText font="header6" style={styles.profileLabel}>
            {`${translate.myProfile.memberships.groups}:`}
          </AppText>
          {organizations.length > 1 ? (
            <View>
              {organizations.map((organization) => (
                <View style={styles.multipleOrganizations} key={organization.id}>
                  <View style={styles.orgSectionHeader}>
                    <AppText font="labelDefault">{organization.organization.name}</AppText>
                  </View>
                  <AppText>{organization.groups.map((group) => group.name).join(', ')}</AppText>
                </View>
              ))}
            </View>
          ) : (
            <AppText>{organizations[0].groups.map((group) => group.name).join(', ')}</AppText>
          )}
        </View>

        <View style={[styles.profileField, organizations.length === 1 ? { marginTop: 20 } : null]}>
          <AppText font="header6" style={styles.profileLabel}>
            {`${translate.emergencyContact.guardians}:`}
          </AppText>
        </View>
        {guardians ? (
          organizations.length > 1 ? (
            <View>
              {guardians.length === 0 ? (
                <AppText>{translate.users.userDetails.userHasNoGuardians}</AppText>
              ) : organizations ? (
                organizations.map((organization) => (
                  <View style={styles.multipleOrganizations} key={organization.id}>
                    <View style={styles.orgSectionHeader}>
                      <AppText font="labelDefault">{organization.organization.name}</AppText>
                    </View>
                    <AppText>
                      {guardians
                        .filter((guardian) => guardian.organizationId === organization.organization.id)
                        .map((guardian) => `${guardian.firstName} ${guardian.lastName}`)
                        .join(', ')}
                    </AppText>
                  </View>
                ))
              ) : null}
            </View>
          ) : (
            <View>
              <AppText>
                {guardians.length > 0
                  ? guardians.map((guardian) => `${guardian.firstName} ${guardian.lastName}`).join(', ')
                  : translate.users.userDetails.userHasNoGuardians}
              </AppText>
            </View>
          )
        ) : null}
      </View>
    </View>
  ) : (
    <></>
  );
};
