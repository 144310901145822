import React, { useEffect } from 'react';
import { View } from 'react-native';
import { RadioButton, Input, InputLabel } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { UpdateReturnProtocolChildProtocolStageConfigFormValue } from '../../ManageProtocol.interface';

interface TimeGateOptionProps {
  error?: FormikErrors<UpdateReturnProtocolChildProtocolStageConfigFormValue>;
  touch?: FormikTouched<UpdateReturnProtocolChildProtocolStageConfigFormValue>;
  itemKey: string;
  timeGateHoursValue?: string;
  isTimeGateSelectedValue: boolean;
  value: UpdateReturnProtocolChildProtocolStageConfigFormValue;
}

export const TimeGateOption: React.FC<TimeGateOptionProps> = ({
  timeGateHoursValue,
  isTimeGateSelectedValue,
  error,
  touch,
  itemKey,
  value,
}) => {
  const { translate } = useTranslations();
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const handleRadioButtonChange = (type: boolean) => {
    if (type === true) {
      setFieldValue(`${itemKey}.isTimeGateSelected`, true);
    } else {
      setFieldValue(`${itemKey}.isTimeGateSelected`, false);
    }
  };

  const handleChangeTimeGateHours = (hours: string | null) => {
    const timeGateItem = {
      ...value,
      timeGateHours: hours ? hours : '',
    };
    setFieldValue(`${itemKey}`, timeGateItem);
  };

  useEffect(() => {
    if (!value.isTimeGateSelected) {
      handleChangeTimeGateHours('');
    }
  }, [value.isTimeGateSelected]);

  return (
    <View style={{ marginBottom: 100, display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
      <InputLabel label={translate.admin.manageProtocol.timeGateOption.askForTimeGating} />
      <RadioButton<boolean>
        value={isTimeGateSelectedValue}
        onSelect={handleRadioButtonChange}
        options={[
          {
            icon: 'check',
            label: translate.answers.YES,
            value: true,
          },
          {
            icon: 'check',
            label: translate.answers.NO,
            value: false,
          },
        ]}
      />
      {isTimeGateSelectedValue === true ? (
        <Input
          label={translate.admin.manageProtocol.timeGateOption.minimumHours}
          type="number"
          value={timeGateHoursValue}
          onChangeText={handleChangeTimeGateHours}
          error={error?.timeGateHours}
          touched={touch?.timeGateHours}
          onBlur={() => setFieldTouched(`${itemKey}.timeGateHours`, true)}
        />
      ) : null}
    </View>
  );
};
