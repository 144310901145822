import * as React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { Icon } from '../Icon/Icon';
import { useColors } from '../../shared/Ui/hooks/useColors';

interface CheckProps {
  checked?: boolean;
  onPress: () => void;
  children?: React.ReactNode;
}

export const Check: React.FC<CheckProps> = ({ onPress, checked, children }) => {
  const colors = useColors();

  const styles = StyleSheet.create({
    item: {
      marginVertical: 12,
      flexDirection: 'row' as 'row',
      alignItems: 'center' as 'center',
    },
    checkbox: {
      width: 26,
      height: 26,
      borderRadius: 6,
      marginRight: 25,
      backgroundColor: colors.white,
      borderColor: colors.navyDark,
      borderWidth: 2,
    },
    selectedCheckbox: {
      backgroundColor: colors.green,
      borderWidth: 0,
    },
    icon: {
      margin: 2,
      color: colors.white,
    },
    box: {},
  });
  return (
    <TouchableOpacity style={styles.item} onPress={onPress} onLongPress={onPress}>
      <View style={[styles.checkbox, checked ? styles.selectedCheckbox : {}]}>
        {checked ? <Icon name="check" size={22} style={styles.icon} /> : null}
      </View>
      {children}
    </TouchableOpacity>
  );
};
