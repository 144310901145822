import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Formik, FormikErrors, FormikHelpers, ErrorMessage } from 'formik';
import { useTranslations } from '../../../../i18n/useTranslations';
import { ReviewInvitationFormValues } from '../ReviewInvitation.interface';
import { AcceptFields } from './AcceptFields';
import { AcceptButton } from './AcceptButton';
import { useAcceptInvitationMutation } from './hooks/useAcceptInvitationMutation';
import { AppButton, AppModal, AppText, Spacer, Check, AppLink } from '../../../../uiComponents';
import { LoggedInNavType, LoggedInRouteProp } from '../../../LoggedIn.interface';
import { UserRoleInvitation } from '../../hooks/__generated__/UserRoleInvitation';
import { Role } from '../../../../types/globalTypes';
import { useActionItemsQuery } from '../../../ActionItems/hooks/useActionItems';
import { LoggedOutChrome } from '../../../../screens/Login/LoggedOutChrome';
import { TermsAndConditions } from './TermsAndConditions';
import { TermsAndConditionsError } from '../../../../screens/Login/RegisterScreen/RegisterFields';

export interface RightUserProps {
  invitation: UserRoleInvitation;
}

export const RightUser: React.FC<RightUserProps> = ({ invitation }) => {
  const route = useRoute<LoggedInRouteProp<'LoggedIn_ReviewInvitation'>>();
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_ReviewInvitation'>>();

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  const identifier = route.params.invitationIdentifier;
  const prevScreen = route.params.prevScreen;
  const [showAcceptedModal, setShowAcceptedModal] = useState(false);
  const [acceptInvitation] = useAcceptInvitationMutation();
  const { refetch: refetchActionItems } = useActionItemsQuery();

  const initialValues: ReviewInvitationFormValues = {
    identifier,
    educationPoliciesAccepted: [],
    termsAndConditionsAccepted: true,
  };

  const styles = StyleSheet.create({
    acceptButton: {
      marginTop: 20,
      width: 200,
    },
    termsAndConditionsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    termsAndConditionsCheckbox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    termsAndConditionsError: {
      alignSelf: 'center',
    },
    termsAndConditionsText: {
      display: 'flex',
      flexDirection: 'row',
    },
  });

  const handleSubmit = (
    { termsAndConditionsAccepted, identifier, educationPoliciesAccepted }: ReviewInvitationFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ReviewInvitationFormValues>
  ) => {
    setSubmitting(true);
    acceptInvitation({
      variables: {
        input: {
          termsAndConditionsAccepted,
          identifier,
          educationPoliciesAccepted,
        },
      },
    }).then(
      () => {
        setSubmitting(false);
        resetForm();
        setShowAcceptedModal(true);
        refetchActionItems();
      },
      () => setSubmitting(false)
    );
    /* noop */
  };

  const validate = (values: ReviewInvitationFormValues) => {
    const errors: FormikErrors<ReviewInvitationFormValues> = {};

    if (!values.termsAndConditionsAccepted) {
      errors.termsAndConditionsAccepted = translate.reviewInvitation.rightUser.youMustAcceptAllPolicies;
    }

    return errors;
  };

  let message = translateWithVars(translate.reviewInvitation.rightUser.messageUserRole, {
    organization: invitation.organizationName,
  });

  const content = (
    <View>
      <Spacer
        items={{
          message: (
            <View>
              <AppText>{message}</AppText>
              {!invitation.termsAndConditionsAccepted && invitation.roles.includes(Role.ADMIN) ? (
                <AppText>{translate.reviewInvitation.rightUser.termsAndConditions}</AppText>
              ) : null}
            </View>
          ),
          fields: <AcceptFields invitation={invitation} />,
        }}
      />
      {prevScreen && prevScreen === 'LoggedIn_ActionItems' ? (
        <View style={styles.termsAndConditionsContainer}>
          <View style={styles.termsAndConditionsCheckbox}>
            <Check
              checked={termsAndConditionsAccepted}
              onPress={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}
            />
            <View style={styles.termsAndConditionsText}>
              <AppText>{`${translate.label.agreeTerms.agreementPart1} `}</AppText>
              <AppLink onPress={() => setTermsModalOpen(true)}>{translate.label.agreeTerms.termsAndConditions}</AppLink>
              <AppText>{` ${translate.label.agreeTerms.agreementPart2}`}</AppText>
            </View>
          </View>
          <View style={styles.termsAndConditionsError}>
            <ErrorMessage name="termsAndConditionsAccepted" component={TermsAndConditionsError} />
          </View>
        </View>
      ) : null}
      <View style={styles.acceptButton}>
        <AcceptButton
          disabled={!!(prevScreen && prevScreen === 'LoggedIn_ActionItems' && !termsAndConditionsAccepted)}
          totalInvitationItems={invitation.educationItems.length}
        />
      </View>
    </View>
  );

  const handleGoHome = () => {
    setShowAcceptedModal(false);
    setTimeout(() => navigation.navigate('LoggedIn_Home', { prevScreen: 'RightUser' }));
  };

  return (
    <>
      <AppModal
        mode="full-screen"
        title={translate.label.agreeTerms.modalTitle}
        visible={termsModalOpen}
        onDismiss={() => setTermsModalOpen(false)}
      >
        <TermsAndConditions />
      </AppModal>
      <AppModal
        mode="dialog"
        visible={showAcceptedModal}
        onDismiss={handleGoHome}
        onRequestClose={handleGoHome}
        title={translateWithVars(translate.reviewInvitation.rightUser.accepted, {
          organization: invitation.organizationName,
        })}
        buttons={{
          goHome: <AppButton onPress={handleGoHome}>{translate.invitation.invitationNotFound.goHome}</AppButton>,
        }}
      />
      <Formik<ReviewInvitationFormValues>
        validate={validate}
        initialValues={initialValues}
        enableReinitialize={false}
        onSubmit={handleSubmit}
      >
        <LoggedOutChrome mode="title-card" titleText={translate.reviewInvitation.rightUser.title} content={content} />
      </Formik>
    </>
  );
};
