import { useDialCodes } from './useDialCodes';

export const useDialCodeLookup = <T extends string, >() => {
	
	const dialCodes = useDialCodes<T>();

	return (value: T) => {
		
		const foundItem = dialCodes.find(({ isoCode: _value }) => _value === value);

		return foundItem?.dialCode || '';
		
	};

}