import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { MedicalAssessmentFragment } from '../fragments/MedicalAssessmentFragment';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';

export const USER_RECOVERY_PLAN = gql`
  query UserRecoveryPlan {
    recoveryPlanWithCases {
      recoveryPlan {
        ...RecoveryPlan
      }
      cases {
        status
        closedDate
        items {
          ... on CaseItemMedicalAssessmentReviewApproved {
            __typename
            diagnosis
          }
        }
        organization {
          id
        }
      }
    }
  }
  ${RecoveryPlanFragment}
  ${MedicalAssessmentFragment}
`;

export const useGetUserRecoveryPlan = (options?) => useQuery(USER_RECOVERY_PLAN, options);
