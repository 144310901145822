import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as useQueryBase,
} from '@apollo/client';
import { first, isObject } from 'lodash/fp';
import { useEffect } from 'react';
import { useTranslations } from '../i18n/useTranslations';
import { useApiErrorExtractor } from './useApiErrorExtractor';
import { useAppAlert } from './ui/useAppAlert';
import { useLoading } from '../uiComponents/Loading/useLoading';

export interface UseQueryOptions<TData, TVariables extends OperationVariables = OperationVariables>
  extends QueryHookOptions<TData, TVariables> {
  hideLoading?: boolean;
  hideAlerts?: boolean;
}

export const useQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: UseQueryOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables> => {
  const { hideLoading = true, hideAlerts, ...restOptions } = options;

  const { loading, error, data, ...rest } = useQueryBase<TData, TVariables>(query, {
    fetchPolicy: 'cache-and-network',
    ...restOptions,
  });

  const { translate } = useTranslations();
  const errorExtractor = useApiErrorExtractor();
  const firstError = isObject(data) ? first(errorExtractor(data as Record<string, unknown>)) : undefined;
  const { alert } = useAppAlert();

  useLoading(!hideLoading && loading);

  useEffect(() => {
    if (!hideAlerts && firstError) {
      alert(translate.error.title.generic, firstError.code);
    }
  }, [hideAlerts, firstError]);

  return { loading, error, data, ...rest };
};
