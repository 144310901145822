import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { UpdateRemoveProtocolStepSymptomConfigFormValue } from '../../../ManageProtocol.interface';
import { KeyValuePairFields } from '../../../KeyValuePairFields/KeyValuePairFields';
import { RemoveProtocolStepBaseConfig } from './RemoveProtocolStepBaseConfig';
export interface RemoveProtocolStepSymptomConfigProps {
  index: number;
  onRemove: (index: number) => void;
  move: (from: number, to: number) => void;
  itemKey: string;
  error?: FormikErrors<UpdateRemoveProtocolStepSymptomConfigFormValue>;
  touch?: FormikTouched<UpdateRemoveProtocolStepSymptomConfigFormValue>;
  value: UpdateRemoveProtocolStepSymptomConfigFormValue;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export const RemoveProtocolStepSymptomConfig: React.FC<RemoveProtocolStepSymptomConfigProps> = ({
  index,
  onRemove,
  move,
  itemKey,
  error,
  touch,
  value,
  canMoveUp,
  canMoveDown,
}) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: {
            remove: { step: translate },
          },
        },
      },
    },
  } = useTranslations();

  const symptoms = (
    <FieldArray
      name={`${itemKey}.symptoms`}
      children={(props) => <KeyValuePairFields addNewLabel={translate.addSymptom} {...props} />}
    />
  );

  return (
    <RemoveProtocolStepBaseConfig
      index={index}
      itemKey={itemKey}
      move={move}
      onRemove={onRemove}
      value={value}
      canMoveDown={canMoveDown}
      canMoveUp={canMoveUp}
      error={error}
      touch={touch}
      typeSpecificItems={{
        symptoms,
      }}
    />
  );
};
