import { Index } from '../Index';
import { ParticipationReport } from '../ParticipationReport/ParticipationReport';
import { ManageUsersNavItem } from '../ManageUsers.interface';

const manageUsersRoutes: ManageUsersNavItem[] = [
  {
    name: 'LoggedIn_Admin_ManageUsers_Index' as const,
    path: '',
    component: Index,
  },
  {
    name: 'LoggedIn_Admin_ManageUsers_ParticipationReport' as const,
    path: '/participation-report',
    component: ParticipationReport,
  },
];

export const useManageUsersRoutes: () => ManageUsersNavItem[] = () => {
  return manageUsersRoutes;
};
