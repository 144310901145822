import { ErrorCode } from '../../types/globalTypes';

export const en: { [key in ErrorCode]: string } = {
  INVITATION_NOT_FOUND: 'This invitation could not be found.',
  USER_ALREADY_REGISTERED: 'An existing user has registered with that email address.',
  USER_DOESNT_BELONG_TO_ANY_ORGANIZATION: 'You are not part of any organization.',
  COUNTRY_NOT_FOUND: 'That country could not be found.',
  NO_EMERGENCY_NUMBERS_FOR_COUNTRY: 'No emergency numbers available for the specified country.',
  USER_NOT_FOUND: 'That user could not be found.',
  USER_NOT_FOUND_IN_ORGANIZATION: 'That user could not be found in the organization.',
  USER_NOT_FOUND_IN_ORGANIZATION_WITH_ROLE: 'That user could not be found in the organization with that role.',
  NO_USERS_LEFT_WITH_ROLE: 'There must be at least one user in the organization with this role.',
  ORGANIZATION_NOT_FOUND: 'That organization could not be found.',
  UNABLE_TO_SEND_INVITATION: 'Unable to send invitation.',
  UNABLE_TO_ACCEPT_INVITATION: 'Unable to accept invitation.',
  PROTOCOL_NOT_FOUND: 'That protocol could not be found.',
  SUBJECT_NOT_FOUND: 'That subject could not be found.',
  CASE_NOT_FOUND: 'That case could not be found.',
  DOCUMENT_NOT_FOUND: 'That document could not be found.',
  GROUP_NOT_FOUND: 'Group could not be found.',
  SEASON_NOT_FOUND: 'Season could not be found.',
  COULD_NOT_CREATE_IMPORT: 'Could not create import',
  COULD_NOT_CREATE_A_USER_WITHOUT_EMAIL: 'Could not create a user without email',
  COULD_NOT_CREATE_GROUP: 'Could not create group.',
  COULD_NOT_CREATE_SEASON: 'Could not create season.',
  COULD_NOT_DELETE_SEASON: 'Could not delete season.',
  COULD_NOT_UPDATE_SEASON: 'Could not update season.',
  SEASON_DATES_NOT_VALID: 'Start date is after end date',
  COULD_NOT_UPDATE_GROUP: 'Could not update group.',
  COULD_NOT_UPDATE_ORGANIZATION: 'Could no update organization.',
  COULD_NOT_ADD_USER_TO_GROUP: 'Could not add user to group.',
  COULD_NOT_CREATE_DESIGNATED_PERSON_INVITATION: 'Could not invite designated person to group.',
  COULD_NOT_CREATE_SUBJECT_INVITATION: 'Could not invite subject to group.',
  COULD_NOT_CREATE_INVITATION: 'Could not invite user.',
  COULD_NOT_NUDGE_DESIGNATED_PERSON: 'Could not nudge this designated person.',
  COULD_NOT_NUDGE_SUBJECT: 'Could not nudge this subject.',
  INVITATION_IS_FOR_ANOTHER_USER:
    'You must accept this invitation with an account email that matches the address the invitation was sent to.',
  COULD_NOT_REGISTER_USER: 'Could not register the user.',
  COULD_NOT_CREATE_ORGANIZATION: 'Could not create organization.',
  USER_HAS_NOT_YET_BEEN_INVITED_TO_SEASON: 'User has not yet been invited to join this season.',
  USER_NOT_FOUND_IN_GROUP: 'This user is not a member of this group. They may have been removed.',
  COULD_NOT_CREATE_MEDICAL_ASSESSMENT_SUBMISSION: 'Could not submit medical assessment.',
  COULD_NOT_SEND_NOTIFICATIONS: 'Could not send notifications.',
  ORGANIZATION_HAS_NO_ACTIVE_SEASON: 'Organization has no active season.',
  NO_PRIVACY_POLICY_FOUND: 'No privacy policy was found.',
  CANNOT_REVIEW_BECAUSE_DOCUMENT_CHANGED:
    'Cannot submit review because the submitted document has changed. Please reload this stage and try again.',
  CANNOT_STEP_BACKWARD_FROM_FIRST_STAGE: 'Cannot step backward because you are on the first stage of the protocol.',
  CANNOT_STEP_BACKWARD_FROM_INACTIVE: 'Cannot step backward because this stage is not currently active.',
  PRESET_INVALID_FOR_ORGANIZATION_TYPE: 'This preset cannot be used for an organization of this type.',
  USER_EMAIL_NOT_SET: 'Could not send verification email because this user has no email address set.',
  PASSWORD_RESET_INVALID_EMAIL: 'The email address you provided is invalid.',
  PASSWORD_RESET_USER_NOT_FOUND: 'No user could be found with this email address.',
  INCORRECT_HEADERS: 'Incorrect Headers',
  UNKNOWN_IMPORT_TYPE: 'Unknown import type',
  COULD_NOT_GET_PAST_UPLOADS: 'Could not get past uploads',
  COULD_NOT_DOWNLOAD_CSV_TEMPLATES: 'Could not download CSV templates',
  COULD_NOT_UPDATE_TRAINING: 'Could not update training',
  COULD_NOT_GET_TRAINING_REPORTS: 'Could not get training reports',
  COULD_NOT_GET_USER_TRAINING_STATUS: 'Could not get user training status',
  COULD_NOT_GET_ORGANIZATION_STATUSES: 'Could not get organization status',
  PAID_OR_TRIAL_ORGANIZATIONS_MUST_HAVE_AN_EXPIRY_DATE: 'Paid or trial organizations must have an expiry date',
  COULD_NOT_GET_GUARDIANS: 'Could not get guardians',
  COULD_NOT_GET_CIRCLE_OF_CARE_FOR_PROFILE: 'Could not get circle of care for profile',
  USER_DOES_NOT_HAVE_FEATURE_FLAG: 'User does not have feature flag',
  CURRENT_USER_CANNOT_REMOVE_THEMSELF: 'User cannot remove themself',
  ADMIN_DOES_NOT_BELONG_IN_ORGANIZATION: 'Admin does not belong in organization',
  CANNOT_CREATE_A_NONSUBJECT_WITH_NO_EMAIL: 'You cannot create a non-subject without an email',
  COULD_NOT_DEACTIVATE_USERS_IN_GROUP: 'Could not deactivate users in group',
  COULD_NOT_RETURN_DEACTIVATED_GROUP: 'Could not return deactivated group',
  COULD_NOT_CHECK_IF_DESIGNATED_PERSON_COMPLETED_EDUCATION_ITEMS:
    'Could not check if designated person completed education items',
  USER_ALREADY_EXISTS_IN_ORGANIZATION: 'User already exists in organization',
  COULD_NOT_CHANGE_USER_LANGUAGE: 'Could not change user language',
  COULD_NOT_RESEND_EDUCATION_ITEMS: 'Could not resend education items',
  NOT_ALL_EDUCATION_ITEMS_WERE_ACCEPTED: 'Not all education items were accepted',
  COULD_NOT_RETURN_UPDATED_USER: 'Could not return updated user',
  COULD_NOT_REACTIVATE_GROUP: 'Could not reactivate group',
};
