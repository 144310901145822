import React from 'react';
import { useTranslations } from '../../../i18n/useTranslations';
import { AppText } from '../../../uiComponents';
import { UserRoleInvitation } from '../../Invitation/hooks/__generated__/UserRoleInvitation';
import { UserLanguage } from '../../../types/globalTypes';

export interface InvitationNoticeProps {
  invitation: UserRoleInvitation;
  organizationLanguage?: string;
}

export const InvitationNotice: React.FC<InvitationNoticeProps> = ({ invitation, organizationLanguage }) => {
  const { translate, translateWithVars } = useTranslations(
    organizationLanguage ? UserLanguage[organizationLanguage] : null
  );

  const message = translateWithVars(translate.register.invitationNotice.invitationMessage.message, {
    firstName: invitation.user.firstName,
    organization: invitation.organizationName,
    email: invitation.user.email,
  });

  return <AppText>{message}</AppText>;
};
