import { getApps, initializeApp } from '@firebase/app';
import * as Device from 'expo-device';
import { connectAuthEmulator, getAuth } from '@firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import { first } from 'lodash';

export const InitializeFirebase = (config: any, emulatorHost?: string) => {
  // Note: The code below extracts your ip address base on the Expo Go manifest. If the code below does not work, add your local machine's IP to the ip variable to run the app locally on your device. make sure your local device is connected to the same wifi network as your machine.
  const ip = first(Constants.manifest2?.extra?.expoGo?.debuggerHost?.split(':19000'));

  if (!getApps().length) {
    const app = initializeApp(config);
    if (
      (Platform.OS === 'ios' || Platform.OS === 'android') &&
      Device.isDevice &&
      process.env.NODE_ENV === 'development'
    ) {
      //Firebase Auth Emulator endpoint to run the app locally on actual devices. The ip is the address of the local host machine.
      connectAuthEmulator(getAuth(app), `http://${ip ? ip : 'localhost'}:8082`);
    } else if (Platform.OS === 'android' && process.env.NODE_ENV === 'development') {
      //Firebase Auth Emulator endpoint to run the app on Android simulator using Android's loopback ip. Read more here: https://developer.android.com/studio/run/emulator-networking.html
      connectAuthEmulator(getAuth(app), `http://10.0.2.2:8082`);
    } else if (emulatorHost) {
      //Firebase Auth Emulator endpoint for web and iOS simulator
      connectAuthEmulator(getAuth(app), `http://${emulatorHost}`);
    }
  }
};
