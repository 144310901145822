import React from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../LoggedIn.interface';
import { SubjectForSubjectsList } from '../shared/SubjectList/hooks/__generated__/SubjectForSubjectsList';
import { Role } from '../../types/globalTypes';
import { SubjectData } from '../TeamRoster/SubjectData';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { SubjectRelation } from '../../types/globalTypes';
import { useTranslations } from '../../i18n/useTranslations';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_MyKids'>>();
  const { translate } = useTranslations();

  const handleSelect = (subject: SubjectForSubjectsList) => {
    navigation.navigate('LoggedIn_Profile', {
      screen: 'LoggedIn_Profile_Overview',
      subjectId: subject.id,
      params: {
        prevScreen: 'LoggedIn_MyKids',
      },
    });
  };

  const { currentUser } = useCurrentUser();

  if (!currentUser || currentUser.__typename !== 'CurrentUser') {
    return null;
  }

  const organizationsWhereGuardian = currentUser.roles.filter((role) => role.role === Role.GUARDIAN);

  return (
    <View style={{ flexGrow: 1, flexBasis: '100%', flexShrink: 0 }}>
      {organizationsWhereGuardian.map((role) => (
        <SubjectData
          role={role}
          key={role.organizationId}
          handleSelect={handleSelect}
          filters={{
            relations: [SubjectRelation.KID],
          }}
          emptyListMessage={translate.subjectList.subjectData.noKidsInOrganization}
        />
      ))}
    </View>
  );
};
