import React from 'react';
import { Linking } from 'react-native';
import { CaseItemMedicalAssessmentSubmission } from '../../../../../fragments/__generated__/CaseItemMedicalAssessmentSubmission';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { MedicalAssessmentDiagnosis } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { useColors } from '../../../../../shared/Ui/hooks';
import { AppText, AppButton } from '../../../../../uiComponents';

export interface CaseItemMedicalAssessmentSubmissionProps {
  item: CaseItemMedicalAssessmentSubmission;
}

export const CaseItemMedicalAssessmentSubmissionView: React.FC<CaseItemMedicalAssessmentSubmissionProps> = ({
  item,
}) => {
  const colors = useColors();
  const { translate, translateWithVars } = useTranslations();

  const handleDocumentPress = () => {
    if (item.document.downloadUri) Linking.openURL(item.document.downloadUri);
  };

  const diagnosis =
    item.diagnosis === MedicalAssessmentDiagnosis.CONCUSSION
      ? translate.caseLog.caseItemMedicalAssessmentSubmission.concussion
      : translate.caseLog.caseItemMedicalAssessmentSubmission.noConcussion;

  const text = translateWithVars(translate.caseLog.caseItemMedicalAssessmentSubmission.message, {
    actor: `${item.actor.firstName} ${item.actor.lastName}`,
    diagnosis,
  });
  const action = (
    <AppButton icon="file" onPress={handleDocumentPress} type="default">
      {translate.recovery.stageDocumentReview.document}
    </AppButton>
  );

  return (
    <BaseCaseLogItemView item={item} action={action}>
      <AppText>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
