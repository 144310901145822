import {
  AssessActionSetStepValuesPayloadSymptom,
  AssessContextState,
  AssessStepValueSymptom,
} from '../../../Assess.interface';
import { setSuggestedResultFromState } from './setSuggestedResultFromState';

export const setStepSymptomValues = (
  nextState: AssessContextState,
  payload: AssessActionSetStepValuesPayloadSymptom
): AssessContextState => {
  nextState = {
    ...nextState,
    steps: nextState.steps.map((step) => {
      if (step.stepId === payload.stepId && payload.type === 'symptom' && step.type === 'symptom') {
        const symptomStep: AssessStepValueSymptom = {
          ...step,
          viewed: true,
          result: payload.results,
        };
        return symptomStep;
      } else {
        return step;
      }
    }),
  };
  return setSuggestedResultFromState(nextState);
};
