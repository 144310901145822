import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { UpdateSeason, UpdateSeasonVariables } from './__generated__/UpdateSeason';
import { SeasonFragment } from '../fragments/SeasonFragment';

export const UPDATE_SEASON_MUTATION = gql`
  mutation UpdateSeason(
    $organizationId: ID!
    $seasonId: ID!
    $name: String!
    $startDate: DateTime!
    $endDate: DateTime
  ) {
    updateSeason(
      organizationId: $organizationId
      seasonId: $seasonId
      name: $name
      startDate: $startDate
      endDate: $endDate
    ) {
      ... on Season {
        ...Season
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${SeasonFragment}
  ${ApiErrorFragment}
`;

export const useUpdateSeason = (options?: MutationHookOptions<UpdateSeason, UpdateSeasonVariables>) => {
  return useMutation<UpdateSeason, UpdateSeasonVariables>(UPDATE_SEASON_MUTATION, options);
};
