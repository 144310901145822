import { useColors } from '../../shared/Ui/hooks';
import { SubjectStatus } from '../../types/globalTypes';

export const useStatusColor = () => (status: SubjectStatus | 'complex') => {
  const colors = useColors();
  switch (status) {
    case SubjectStatus.ACTIVE:
      return colors.green;
    case SubjectStatus.ON_PROTOCOL:
      return colors.orange;
    case SubjectStatus.REMOVED:
      return colors.red;
    case 'complex':
      return colors.grayDark;
    default:
      return colors.grayDark;
  }
};
