import React from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';

export const Spinner = ({}) => {
  return (
    <View
      style={{
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <ActivityIndicator size="large" />
    </View>
  );
};
