import { gql, MutationHookOptions } from '@apollo/client';
import { Register, RegisterVariables } from '../__generated__/Register';
import { useTrackAnonymousEvent } from '../../../hooks/useTrackAnonymousEvent';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';

const REGISTER_MUTATION = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      ... on RegisteredUser {
        id
        token
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useRegister = (options?: MutationHookOptions<Register, RegisterVariables>) => {
  const { trackAnonymousEvent } = useTrackAnonymousEvent();

  return useMutation<Register, RegisterVariables>(REGISTER_MUTATION, {
    onCompleted: (data) => {
      trackAnonymousEvent('sign_up', { success: true });
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
    },
    onError: (error) => {
      console.log(error);
      trackAnonymousEvent('sign_up', { success: false });
      if (options?.onError) {
        options.onError(error);
      }
    },
  });
};
