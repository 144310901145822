import { getApp } from '@firebase/app';
import { getAnalytics, logEvent } from '@firebase/analytics';

export const useTrackAnonymousEvent = () => {
  const app = getApp();
  const analytics = getAnalytics(app);
  const trackAnonymousEvent = (label: string, value: any = null) => {
    const payload = {
      ...value,
    };

    try {
      console.log('Analytics event:', label, payload);
      logEvent(analytics, label, payload);
    } catch (error) {
      console.log(error);
    }
  };

  return { trackAnonymousEvent };
};
