import React from 'react';
import { AppModal, AppText, AppButton } from '../../../../../../uiComponents';
import { useTranslations } from '../../../../../../i18n/useTranslations';

interface IncidentReportNoticeModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (modalVisible: boolean) => void;
}

export const IncidentReportNoticeModal: React.FC<IncidentReportNoticeModalProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const { translate } = useTranslations();

  const handleOK = () => {
    setIsModalVisible(false);
  };

  return (
    <AppModal
      title={translate.recovery.incompleteIncidentReport.title}
      mode="dialog"
      visible={isModalVisible}
      onDismiss={() => setIsModalVisible(false)}
      buttonOrientation="horizontal"
      buttons={{
        ok: <AppButton onPress={handleOK}>{translate.admin.manageOrganization.ok}</AppButton>,
      }}
    >
      <AppText>{translate.recovery.incompleteIncidentReport.content}</AppText>
    </AppModal>
  );
};
