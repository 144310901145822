import React, { createContext, useState } from 'react';
import { CsvAttachments, ImportDataContextType } from './SuperAdmin.interface';
import { ImportStatus } from './screens/ImportData/ImportData.interface';

export const ImportDataContext = createContext<ImportDataContextType>({
  setCsvAttachments: () => undefined,
  csvAttachments: {
    SUBJECTS: undefined,
    GUARDIANS: undefined,
    STAFF: undefined,
    SUBJECT_GROUPS: undefined,
    DESIGNATED_PERSON_GROUPS: undefined,
  },
  setImportStatuses: () => undefined,
  importStatuses: {
    SUBJECTS: ImportStatus.NO_ATTACHMENT,
    GUARDIANS: ImportStatus.NO_ATTACHMENT,
    STAFF: ImportStatus.NO_ATTACHMENT,
    SUBJECT_GROUPS: ImportStatus.NO_ATTACHMENT,
    DESIGNATED_PERSON_GROUPS: ImportStatus.NO_ATTACHMENT,
  },
});

export interface ImportDataContextProviderProps {
  organizationId?: string;
  children: React.ReactNode;
}

export const ImportDataContextProvider: React.FC<ImportDataContextProviderProps> = ({ children }) => {
  const [csvAttachments, setCsvAttachments] = useState<CsvAttachments>({
    SUBJECTS: undefined,
    GUARDIANS: undefined,
    STAFF: undefined,
    SUBJECT_GROUPS: undefined,
    DESIGNATED_PERSON_GROUPS: undefined,
  });
  const [importStatuses, setImportStatuses] = useState({
    SUBJECTS: ImportStatus.NO_ATTACHMENT,
    GUARDIANS: ImportStatus.NO_ATTACHMENT,
    STAFF: ImportStatus.NO_ATTACHMENT,
    SUBJECT_GROUPS: ImportStatus.NO_ATTACHMENT,
    DESIGNATED_PERSON_GROUPS: ImportStatus.NO_ATTACHMENT,
  });

  return (
    <ImportDataContext.Provider
      value={{ setCsvAttachments, csvAttachments, importStatuses, setImportStatuses }}
      children={children}
    />
  );
};
