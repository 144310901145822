import React, { useMemo } from 'react';
import { AppNavItem, AppParamList } from './app.interface';
import { useRootRoutes, useRootScreens } from './hooks/useRootRoutes';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { isEmpty } from 'lodash/fp';
import { RootNavItem, RootParamList } from './RootNavigator.interface';
import { useFirebase } from './shared/Firebase';
import { useAppConfig } from './hooks/useAppConfig';
import { View } from 'react-native';
import { fonts } from './shared/Ui/defaultStyleConfig';

const Root = createStackNavigator<AppParamList>();

export const RootNavigator: React.FC = () => {
  const routes = useRootRoutes();
  const screens = useRootScreens();
  const { token } = useFirebase();
  const config = useAppConfig();
  const loggedIn = !isEmpty(token);

  const currentInitialRoute = loggedIn ? ('LoggedIn' as const) : ('Welcome' as const);

  const linking: LinkingOptions<RootParamList> = {
    prefixes: config.linkingPrefix ? [config.linkingPrefix] : [],
    config: {
      screens,
      initialRouteName: currentInitialRoute,
    },
  };

  const routeFilter = ({ mode }: AppNavItem) => {
    if (mode === 'any') {
      return true;
    }
    if (mode === 'public') {
      if (loggedIn) {
        return false;
      } else {
        return true;
      }
    } else {
      if (loggedIn) {
        return true;
      } else {
        return false;
      }
    }
  };

  const routesByLoginStatus = (routes: RootNavItem[], _loggedIn?: boolean) => {
    return routes.filter(routeFilter);
  };

  const currentRoutes = useMemo(() => routesByLoginStatus(routes, loggedIn), [routes, loggedIn]);

  return (
    <NavigationContainer linking={linking}>
      <Root.Navigator initialRouteName={currentInitialRoute}>
        <Root.Group screenOptions={{ headerShown: false }}>
          {currentRoutes.map(({ component: Component, name }) => (
            <Root.Screen
              key={name}
              name={name}
              children={() => (
                <View style={{ ...fonts.bodyMedium, height: '100%' }}>
                  <Component />
                </View>
              )}
            />
          ))}
        </Root.Group>
      </Root.Navigator>
    </NavigationContainer>
  );
};
