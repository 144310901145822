import React, { useEffect } from 'react';
import { useCaseLog } from '../screens/CaseLogs/hooks/useCaseLogQuery';
import { StyleSheet, View, Platform, UIManager, LayoutAnimation } from 'react-native';
import { useColors, useShadow } from '../../../shared/Ui';
import { AppText, AppButton, ActivityIndicator } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { useRecoveryPlan } from '../screens/RecoveryPlan/hooks/useRecoveryPlan';
import { useNavigation } from '@react-navigation/native';
import { LoggedInNavType } from '../../LoggedIn.interface';
import { useIsMobile } from '../../../hooks/ui/useResponsive';
import { useDateFormat } from '../../../hooks/ui/useDateFormat';

interface ActiveCaseItemProps {
  caseId: string;
  organizationName: string;
  subjectId: string | undefined;
  canViewCaseLog: boolean;
}

export const ActiveCaseItem: React.FC<ActiveCaseItemProps> = ({
  caseId,
  organizationName,
  subjectId,
  canViewCaseLog,
}) => {
  const log = useCaseLog(caseId);
  const shadow = useShadow();
  const colors = useColors();
  const isMobile = useIsMobile();
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Profile'>>();

  if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
  }

  const { formatDate } = useDateFormat();

  useEffect(() => {
    LayoutAnimation.configureNext({
      duration: 200,
      create: { type: 'linear', property: 'opacity' },
      update: { type: 'linear' },
      delete: { type: 'linear', property: 'opacity' },
    });
  }, []);

  const handleViewLogs = () => {
    if (subjectId) {
      navigation.navigate('LoggedIn_Profile', {
        screen: 'LoggedIn_Profile_Logs',
        subjectId,
        params: { caseId: log?.kase.id },
      });
    } else {
      navigation.navigate('LoggedIn_Profile', {
        screen: 'LoggedIn_Profile_Logs',
        params: { caseId: log?.kase.id },
      });
    }
  };

  const handleViewRecovery = () => {
    if (subjectId) {
      navigation.navigate('LoggedIn_Profile', {
        screen: 'LoggedIn_Profile_RecoveryPlan',
        subjectId,
        params: { screen: 'LoggedIn_Profile_RecoveryPlan_Index', params: { caseId: log?.kase.id } },
      });
    } else {
      navigation.navigate('LoggedIn_Profile', {
        screen: 'LoggedIn_Profile_RecoveryPlan',
        params: { screen: 'LoggedIn_Profile_RecoveryPlan_Index', params: { caseId: log?.kase.id } },
      });
    }
  };

  const styles = StyleSheet.create({
    container: {
      ...shadow,
      backgroundColor: colors.white,
      maxHeight: 250,
      width: isMobile ? 320 : 400,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: 10,
      marginTop: 15,
      marginRight: 25,
      borderRadius: 8,
      marginLeft: 20,
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: 10,
    },
    latestStage: {
      marginBottom: 10,
    },
    buttonContainer: {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    activityIndicator: {
      alignItems: 'center',
      justifyContent: 'center',
      height: 250,
      width: isMobile ? 300 : 400,
    },
  });

  const data = useRecoveryPlan();

  if (!data || (data && data.__typename !== 'CurrentUser' && data.__typename !== 'Subject'))
    return <ActivityIndicator style={styles.activityIndicator} />;

  const latestStages = data?.recoveryPlan?.stages.filter(
    (stage) => stage.organizationName === organizationName && stage.isCurrentStage === true
  );

  const getLatestStageMessage = (latestStage): string => {
    const getProtocolType = (): string => {
      if (latestStage.protocolType === 'LEARN') {
        return `${translate.recovery.index.returnToLearn}: `;
      } else if (latestStage.protocolType === 'SPORT') {
        return `${translate.recovery.index.returnToSport}: `;
      } else {
        return '';
      }
    };

    const latestStageMessage = `${getProtocolType()}${latestStage.name}`;
    return latestStageMessage;
  };

  const getStageNumberDetails = (latestStage): string => {
    let numberOfStages: number;
    if (data.recoveryPlan) {
      if (latestStage.protocolType) {
        numberOfStages = data.recoveryPlan.stages
          .filter((stage) => stage.organizationName === organizationName)
          .filter((stage) => 'protocolType' in stage && stage.protocolType === latestStage.protocolType).length;
      } else {
        numberOfStages = data.recoveryPlan.stages.filter((stage) => stage.organizationName === organizationName).length;
      }
      return translateWithVars(translate.myProfile.activeCases.stageNumberDetails, {
        latestStage: latestStage.protocolType ? latestStage.order + 1 : latestStage.order,
        numberOfStages,
      });
    }
    return '';
  };

  return (
    <View style={styles.container}>
      {data &&
      data.recoveryPlan &&
      [new Set(...data.recoveryPlan.stages.map((stage) => stage.organizationName))].length > 1 ? (
        <AppText font="labelDefault" color="grayDark">
          {log?.kase.organization.name}
        </AppText>
      ) : null}

      <View style={styles.top}>
        <AppText color="navyDark" font="labelDefault">
          {translate.subjectStatus.onProtocol}
        </AppText>
        {log && log.kase.items && log.kase.items[0]?.createdDate ? (
          <AppText font="labelDefault" color="grayDark">
            {formatDate(new Date(log.kase.items[0]?.createdDate), 'yyyy/MM/dd')}
          </AppText>
        ) : null}
      </View>
      {latestStages
        ? latestStages.map((latestStage) => (
            <View key={latestStage.id} style={styles.latestStage}>
              <AppText font="labelDefault" color="grayDark">
                {getLatestStageMessage(latestStage)}
              </AppText>
              <AppText font="labelDefault" color="grayDark">
                {getStageNumberDetails(latestStage)}
              </AppText>
            </View>
          ))
        : null}
      <View style={styles.buttonContainer}>
        <AppButton onPress={handleViewRecovery}>{translate.myProfile.profileHeader.recovery}</AppButton>
        {canViewCaseLog ? (
          <AppButton onPress={handleViewLogs}>{translate.myProfile.profileHeader.log}</AppButton>
        ) : null}
      </View>
    </View>
  );
};
