import * as React from 'react';
import { useState } from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { AppButton, AppScrollView, AppText, Checkbox, Input, Loading, SVG, useLoading } from '../../../uiComponents';
import { useTranslations } from '../../../i18n/useTranslations';
import { useGetEducationItems } from '../hooks/useGetEducationItems';
import { useUpdateNccpNumber } from '../hooks/useUpdateNccpNumber';
import { TrainingChrome } from '../TrainingChrome';
import { TrainingNavType, TrainingRouteProp } from '../Training.interface';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useNotification } from '../../../hooks/ui/useNotification';
import { isApiError } from '../../../utils/isApiError';
import { useGetUserTrainingStatus } from '../hooks/useGetUserTrainingStatus';
import { useIsMobile } from '../../../hooks/ui/useResponsive';

export const Content: React.FC = () => {
  const { translate } = useTranslations();
  const { data } = useGetEducationItems();
  const { data: trainingStatus, loading } = useGetUserTrainingStatus();
  const navigation = useNavigation<TrainingNavType<'LoggedIn_Training_Resources'>>();
  const route = useRoute<TrainingRouteProp<'LoggedIn_Training_Resources'>>();
  const notify = useNotification();

  const educationItems = data?.currentUser.educationItems;
  const trainingProgram = 'makingHeadWay';
  const textContent = translate.training[trainingProgram];

  const [nccpInput, setNccpInput] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [updateUserTrainingCompletion] = useUpdateNccpNumber(nccpInput, {
    onCompleted: ({ updateUserTrainingCompletion }) => {
      if (!isApiError(updateUserTrainingCompletion)) {
        notify({ image: 'header/people', description: 'Your NCCP number has been submitted.' });
      }
    },
  });

  useLoading(!textContent);

  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    trainingTitle: {
      marginBottom: 15,
    },
    mobileLogoContainer: {
      height: 150,
    },
    trainingText: {
      marginBottom: 5,
      marginTop: 5,
    },
    intro: {
      width: !isMobile ? '70%' : '100%',
    },
    introWithLogo: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: !isMobile ? 'space-between' : 'flex-start',
      paddingBottom: 0,
      marginBottom: 0,
    },
    trainingCompleted: {
      marginVertical: 40,
    },
    nccpEntry: {
      marginVertical: 40,
    },
    checkmarkContainer: {
      width: 20,
      height: 20,
      borderRadius: 10,
      overflow: 'hidden',
    },
    trainingCompletedMessage: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    trainingCompletedMessageText: {
      marginLeft: 20,
    },
    nccpNumber: {
      paddingBottom: 20,
    },
    confirmButtonContainer: {
      marginBottom: 30,
    },
    trainingButton: {
      paddingTop: 10,
      marginTop: 30,
    },
    documentName: {
      marginBottom: !isMobile ? 0 : 20,
    },
    downloadBtnContainer: {
      marginRight: !isMobile ? 250 : 0,
    },
    downloadContainer: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    organizationDocuments: {
      marginBottom: 30,
    },
  });

  const navigateToTraining = () => {
    Linking.openURL(textContent.links.trainingSite);
  };

  const handleSelect = (checked: boolean[]) => {
    setIsChecked(checked[0]);
  };

  const handleSubmit = () => {
    updateUserTrainingCompletion({
      variables: {
        input: {
          nccpNumber: nccpInput,
          completed: true,
        },
      },
    });
  };

  const handleDownload = (link: string) => {
    Linking.openURL(link);
  };

  const handleGoBack = () => {
    const isPrimaryDesignatedPerson = route?.params?.isPrimaryDesignatedPerson;
    if (isPrimaryDesignatedPerson) {
      navigation.navigate('LoggedIn_Training', {
        screen: 'LoggedIn_Training_Index',
      });
    } else {
      navigation.navigate('LoggedIn', { screen: 'LoggedIn_Home' });
    }
  };

  return (
    <TrainingChrome titleText={translate.training.trainingResources} onBack={handleGoBack} showHamburger={false}>
      <AppScrollView showsVerticalScrollIndicator={false}>
        <View style={{ flexGrow: 1, flexBasis: '100%', flexShrink: 0 }}>
          <AppText font="header3" style={styles.trainingTitle}>
            {textContent.title}
          </AppText>
          <AppText style={styles.trainingText}>{textContent.description}</AppText>
          <View style={styles.introWithLogo}>
            <View style={styles.intro}>
              <AppText style={styles.trainingText}>{textContent.instructions.step1}</AppText>
              {isMobile ? (
                <View style={styles.mobileLogoContainer}>
                  <SVG image={'training/CAC'} width={'100%'} height={'100%'} />
                </View>
              ) : null}
              <AppButton style={styles.trainingButton} onPress={navigateToTraining}>
                {textContent.button.label}
              </AppButton>
            </View>
            {!isMobile ? <SVG image={'training/CAC'} width={'100%'} height={'100%'} /> : null}
          </View>
          {!loading ? (
            trainingStatus &&
            trainingStatus.userTrainingStatus.__typename === 'UserTrainingStatus' &&
            trainingStatus.userTrainingStatus.completed ? (
              <View style={styles.trainingCompleted}>
                <AppText font="labelDefault" color="grayDark" style={styles.nccpNumber}>
                  {`${translate.training.nccpNumberLabel}: ${trainingStatus.userTrainingStatus.nccpNumber}`}
                </AppText>
                <View style={styles.trainingCompletedMessage}>
                  <View style={styles.checkmarkContainer}>
                    <SVG image="checkbox" width={'100%'} height={'100%'} />
                  </View>
                  <AppText font="labelDefault" color="grayDark" style={styles.trainingCompletedMessageText}>
                    {translate.training.trainingCompleted}
                  </AppText>
                </View>
              </View>
            ) : (
              <View style={styles.nccpEntry}>
                <AppText style={styles.trainingText}>{textContent.instructions.step2}</AppText>
                <AppText style={styles.trainingText}>{textContent.instructions.step3}</AppText>
                <Input onChangeText={(e) => setNccpInput(e)} />
                <Checkbox<boolean>
                  options={textContent.checkbox}
                  onSelect={(items: boolean[]) => handleSelect(items)}
                  value={!isChecked ? [] : [isChecked]}
                />
                <View style={styles.confirmButtonContainer}>
                  <AppButton disabled={!nccpInput || !isChecked} style={styles.trainingButton} onPress={handleSubmit}>
                    {translate.label.submit}
                  </AppButton>
                </View>
              </View>
            )
          ) : (
            <Loading />
          )}
          <View>
            {educationItems
              ? educationItems.map((organization) => {
                  return (
                    <View key={organization.organizationId} style={styles.organizationDocuments}>
                      <AppText font="header3" style={styles.trainingTitle}>
                        {`${translate.training.trainingMaterials}: ${organization.organizationName}`}
                      </AppText>
                      {organization.items.map((document) => (
                        <View style={styles.downloadContainer} key={document.id}>
                          <AppText style={styles.documentName}>{document.name}</AppText>
                          <View style={styles.downloadBtnContainer}>
                            <AppButton onPress={() => handleDownload(document.link)}>
                              {translate.training.downloadDocument}
                            </AppButton>
                          </View>
                        </View>
                      ))}
                    </View>
                  );
                })
              : null}
          </View>
        </View>
      </AppScrollView>
    </TrainingChrome>
  );
};
