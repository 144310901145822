import { gql } from '@apollo/client';
import { SeasonFragment } from '../fragments/SeasonFragment';
import { GetSeasonById, GetSeasonByIdVariables } from './__generated__/GetSeasonById';
import { useQuery } from '../../../../../hooks/useQuery';

export const GET_SEASON_BY_ID_QUERY = gql`
  query GetSeasonById($organizationId: ID!, $seasonId: ID!) {
    season(seasonId: $seasonId, organizationId: $organizationId) {
      ... on Season {
        ...Season
      }
    }
  }
  ${SeasonFragment}
`;

export const useGetSeasonById = (organizationId?: string, seasonId?: string) => {
  const { data, refetch } = useQuery<GetSeasonById, GetSeasonByIdVariables>(GET_SEASON_BY_ID_QUERY, {
    variables: {
      seasonId: seasonId as string,
      organizationId: organizationId as string,
    },
    skip: !organizationId || !seasonId,
  });

  if (data?.season) {
    return { season: data.season, refetch };
  }
  return { season: null, refetch };
};
