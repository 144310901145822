import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../hooks/useQuery';
import { UseSubjectsCases } from './__generated__/UseSubjectsCases';

const GET_SUBJECT_CASES = gql`
  query UseSubjectsCases(
    $organizationId: String!
    $paginationArgs: PaginationArgs!
    $fromDate: String
    $toDate: String
    $status: CaseStatus
  ) {
    subjectCases(
      organizationId: $organizationId
      paginationArgs: $paginationArgs
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    ) {
      ... on CasePreview {
        id
        createdDate
        closedDate
        subjectName
        subjectId
        caseStatus
      }
    }
  }
`;

export const useGetCasePreviews = (options?) =>
  useQuery<UseSubjectsCases>(GET_SUBJECT_CASES, {
    ...options,
  });
