import { createContext, useContext } from 'react';
import React from 'react';

export interface ProfileContext {
  subjectId?: string | undefined;
}

const context = createContext<ProfileContext>({});

export const ProfileContext: React.FC<{ value?: ProfileContext; children: React.ReactNode }> = ({
  children,
  value = {},
}) => {
  return <context.Provider value={value} children={children} />;
};

export const useProfileContext = () => useContext(context);
