import React, { useEffect } from 'react';
import { useFirebase } from './Firebase';
import { getAuth, getIdToken, onAuthStateChanged as firebaseOnAuthStateChanged, User } from '@firebase/auth';

export interface FirebaseAuthProps {
  onLogin?: (user: User, idToken: string) => void;
  onLogout?: () => void;
  children: React.ReactNode;
}

export const FirebaseAuth: React.FC<FirebaseAuthProps> = ({ children, onLogin, onLogout }) => {
  const { firebase } = useFirebase();

  const onAuthStateChanged = (user: User | null) => {
    if (user) {
      getIdToken(user).then((token) => {
        if (onLogin) {
          onLogin(user, token);
        }
      });
    } else {
      if (onLogout) {
        onLogout();
      }
    }
  };

  useEffect(() => {
    const unsubscribe = firebaseOnAuthStateChanged(getAuth(firebase), (user) => onAuthStateChanged(user));

    return unsubscribe;
  }, [firebase]);

  return <>{children}</>;
};
