import { FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';
import { AppButton, Spacer, SpacerItems } from '../../../../../../uiComponents';
import {
  UpdateEducationProtocolItemDocumentConfigFormValue,
  UpdateProtocolConfigFormValue,
} from '../../ManageProtocol.interface';
import { EducationProtocolItemConfig } from './EducationProtocolItemConfig/EducationProtocolItemConfig';
import { useTranslations } from '../../../../../../i18n/useTranslations';

export interface EducationProtocolItemsConfigProps extends FieldArrayRenderProps {
  push<UpdateEducationProtocolItemDocumentConfigFormValue>(obj: UpdateEducationProtocolItemDocumentConfigFormValue): void;
}

export const EducationProtocolItemsConfig: React.FC<EducationProtocolItemsConfigProps> = ({ push, remove, move }) => {
  const { values } = useFormikContext<UpdateProtocolConfigFormValue>();
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: { education: translate },
        },
      },
    },
  } = useTranslations();

  const handleAdd = () => {
    const defaultNewItem: UpdateEducationProtocolItemDocumentConfigFormValue = {
      instruction: '',
      name: '',
      useForDesignatedPerson: true,
      useForGuardian: true,
      useForSubject: true,
      document: {
        currentDocument: null,
        nextDocument: {
          id: null,
          upload: null,
        },
      },
      type: 'document',
    };
    push(defaultNewItem);
  };

  const educationItems: SpacerItems = {};
  return (
    <Spacer
      size="large"
      style={{ marginBottom: 20 }}
      items={{
        items: (
          <Spacer
            size="large"
            items={values.educationProtocol.items.reduce((accum, val, index) => {
              accum[index.toString()] = (
                <EducationProtocolItemConfig
                  onRemove={remove}
                  move={move}
                  index={index}
                  totalItems={values.educationProtocol.items.length}
                />
              );

              return accum;
            }, educationItems)}
          />
        ),
        add: <AppButton onPress={handleAdd}>{translate.addNew}</AppButton>,
      }}
    />
  );
};
