import { useRoute } from '@react-navigation/native';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import * as React from 'react';
import { ManageProtocolRouteProp } from '../../ManageProtocol.interface';
import { ReturnProtocolChildProtocolConfig } from './ReturnProtocolChildProtocolConfigContent';

export interface ContentProps {}

export const Content: React.FC<ContentProps> = (props) => {
  const {
    translate: {
      admin: {
        manageProtocol: {
          updateProtocolConfigForm: { return: translate },
        },
      },
    },
  } = useTranslations();

  const route = useRoute<ManageProtocolRouteProp<'LoggedIn_Admin_ManageProtocol_Return_ChildProtocol'>>();

  const { name } = route.params;

  return name === 'returnToLearn' ? (
    <ReturnProtocolChildProtocolConfig title={translate.returnToLearn} name="returnProtocol.returnToLearn" />
  ) : (
    <ReturnProtocolChildProtocolConfig title={translate.returnToSport} name="returnProtocol.returnToSport" />
  );
};
