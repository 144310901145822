import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { CasesChrome } from './CasesChrome';
import { CasesNavType, CaseType } from './Cases.interface';
import { BaseTile, TileProps, Tiles as AppTiles } from '../../uiComponents/Tiles';
import { useTranslations } from '../../i18n/useTranslations';

export const Tiles: React.FC = (props) => {
  const navigation = useNavigation<CasesNavType<'LoggedIn_Cases_Index'>>();
  const { translate } = useTranslations();

  const handleGoBack = () => {
    navigation.navigate('LoggedIn_Home');
  };

  const handleCases = (caseType: CaseType) => {
    navigation.navigate('LoggedIn_Cases', {
      screen: 'LoggedIn_Cases_CaseList',
      params: {
        caseType,
      },
    });
  };

  const handleOnProtocol = () => {
    navigation.navigate('LoggedIn_OnProtocol');
  };

  const tiles: TileProps[] = [
    {
      key: 'pastCases',
      tile: <BaseTile label={translate.cases.pastCases} onPress={() => handleCases('past')} />,
      width: 'half',
    },
    {
      key: 'activeCases',
      tile: <BaseTile label={translate.cases.activeCases} onPress={() => handleOnProtocol()} />,
      width: 'half',
    },
  ];

  return (
    <CasesChrome onBack={handleGoBack} titleText={translate.navigation.cases} showHamburger={true}>
      <AppTiles tiles={tiles} />
    </CasesChrome>
  );
};
