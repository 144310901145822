import { gql, MutationHookOptions } from '@apollo/client';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';
import { GET_USER_TRAINING_STATUS } from './useGetUserTrainingStatus';
import { GetUserTrainingStatus } from './__generated__/GetUserTrainingStatus';
import {
  UpdateUserTrainingCompletion,
  UpdateUserTrainingCompletionVariables,
} from './__generated__/UpdateUserTrainingCompletion';

export const CREATE_NCCP_NUMBER = gql`
  mutation UpdateUserTrainingCompletion($input: CreateUserTrainingInput!) {
    updateUserTrainingCompletion(input: $input) {
      ... on UserTrainingResult {
        completed
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useUpdateNccpNumber = (
  nccpInput: string,
  options?: MutationHookOptions<UpdateUserTrainingCompletion, UpdateUserTrainingCompletionVariables>
) =>
  useMutation(CREATE_NCCP_NUMBER, {
    ...options,
    update(cache, { data }) {
      if (
        data?.updateUserTrainingCompletion.__typename === 'UserTrainingResult' &&
        data.updateUserTrainingCompletion.completed
      ) {
        cache.writeQuery<GetUserTrainingStatus>({
          query: GET_USER_TRAINING_STATUS,
          data: {
            userTrainingStatus: {
              __typename: 'UserTrainingStatus',
              completed: true,
              nccpNumber: nccpInput,
              completedAt: new Date(),
            },
          },
        });
      }
    },
  });
