import React, { useState } from 'react';
import { RfaProtocolResult } from '../../../../../../types/globalTypes';
import { useTranslations } from '../../../../../../i18n/useTranslations';
import { AppButton, AppModal, AppText } from '../../../../../../uiComponents';
import { CaseItemRfaProtocolResult } from '../../../../../../fragments/__generated__/CaseItemRfaProtocolResult';
import { BaseCaseLogItemView } from '../BaseCaseLogItemView';
import { CaseLogContextSubject } from '../../CaseLogs.interface';
import { IncidentReportDetails } from './IncidentReportDetails';

export interface CaseItemRfaProtocolResultProps {
  item: CaseItemRfaProtocolResult;
  subject: CaseLogContextSubject;
  organizationName: string;
}

export const CaseItemRfaProtocolResultView: React.FC<CaseItemRfaProtocolResultProps> = ({
  item,
  subject,
  organizationName,
}) => {
  const { translate, translateWithVars } = useTranslations();
  const [showDetails, setShowDetails] = useState(false);
  const translateText =
    item.result === RfaProtocolResult.REMOVE
      ? translate.caseLog.caseItemRfaProtocolResult.removeDescription
      : translate.caseLog.caseItemRfaProtocolResult.resumeDescription;

  const text = translateWithVars(translateText, {
    actor: item.actor.fullName,
    subject: subject.fullName,
  });

  const handleOpenDetails = () => setShowDetails(true);

  const handleCloseDetails = () => setShowDetails(false);

  const action = (
    <AppButton icon="clipboard" onPress={handleOpenDetails} type="default">
      {translate.caseLog.caseItemRfaProtocolResult.viewIncidentReport}
    </AppButton>
  );

  return (
    <>
      <AppModal
        visible={showDetails}
        onDismiss={handleCloseDetails}
        onRequestClose={handleCloseDetails}
        mode="full-screen"
        title={translate.protocol.incidentReport}
        buttons={{
          close: (
            <AppButton type="outline" onPress={handleCloseDetails}>
              {translate.caseLog.caseItemRfaProtocolResult.close}
            </AppButton>
          ),
        }}
      >
        <IncidentReportDetails item={item} subject={subject} organizationName={organizationName} />
      </AppModal>
      {/* if item has an incident date, that means the incident report is not completed*/}
      <BaseCaseLogItemView item={item} action={item.incidentDate ? action : null}>
        <AppText>{text}</AppText>
      </BaseCaseLogItemView>
    </>
  );
};
