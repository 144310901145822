import React from 'react';
import { SubjectStatus } from '../../../../../types/globalTypes';
import { useTranslations } from '../../../../../i18n/useTranslations';
import { AppText } from '../../../../../uiComponents';
import { BaseCaseLogItemView } from './BaseCaseLogItemView';
import { CaseItemSubjectStatusChange } from '../../../../../fragments/__generated__/CaseItemSubjectStatusChange';
import { CaseLogContextSubject } from '../CaseLogs.interface';

export interface CaseItemSubjectStatusChangeProps {
  item: CaseItemSubjectStatusChange;
  subject: CaseLogContextSubject;
}

export const CaseItemSubjectStatusChangeView: React.FC<CaseItemSubjectStatusChangeProps> = ({ item, subject }) => {
  const { translate, translateWithVars } = useTranslations();

  let statusText = '';

  switch (item.newSubjectStatus) {
    case SubjectStatus.ACTIVE:
      statusText = translate.subjectStatus.active;
      break;
    case SubjectStatus.ON_PROTOCOL:
      statusText = translate.subjectStatus.onProtocol;
      break;
    case SubjectStatus.REMOVED:
      statusText = translate.subjectStatus.removed;
      break;
  }

  const text = translateWithVars(translate.caseLog.caseItemSubjectStatusChange.message, {
    subject: subject.fullName,
    status: statusText,
  });

  return (
    <BaseCaseLogItemView item={item}>
      <AppText>{text}</AppText>
    </BaseCaseLogItemView>
  );
};
