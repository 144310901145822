import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ProfileParamList } from './Profile.interface';
import { useProfileRoutes } from './hooks/useProfileRoutes';
import { ProfileContext } from './ProfileContext';
import { LoggedInRouteProp } from '../LoggedIn.interface';
import { useRoute } from '@react-navigation/native';

const ProfileNav = createStackNavigator<ProfileParamList>();

export const ProfileNavigator: React.FC = () => {
  const routes = useProfileRoutes();
  const route = useRoute<LoggedInRouteProp<'LoggedIn_Profile'>>();

  return (
    <ProfileNav.Navigator screenOptions={{ headerShown: false }}>
      {routes.map(({ component: Component, name }) => (
        <ProfileNav.Screen
          key={name}
          name={name}
          children={() => (
            <ProfileContext value={route.params}>
              <Component />
            </ProfileContext>
          )}
        />
      ))}
    </ProfileNav.Navigator>
  );
};
