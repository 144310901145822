import * as React from 'react';
import { ChromeProps } from '../../../shared/Chrome/Chrome';
import { Chrome as ChromeBase } from '../../shared/Chrome/Chrome';
import { Subtitle } from './Subtitle';
import { useGetProfile } from './hooks/useGetProfile';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LoggedInNavType } from '../../LoggedIn.interface';
import { LoggedInRouteProp } from '../../LoggedIn.interface';
import { useTranslations } from '../../../i18n/useTranslations';

export interface ProfileChromeProps extends Omit<ChromeProps, 'title' | 'mode'> {
  activeTab?: 'overview' | 'recovery' | 'logs' | 'circle_of_care';
  title?: string;
}

export const ProfileChrome: React.FC<ProfileChromeProps> = ({ activeTab = 'overview', title, ...props }) => {
  const data = useGetProfile();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Home'>>();
  const { translate } = useTranslations();

  let finalTitle: React.ReactNode = null;
  let subNavigation: React.ReactNode = null;

  const rootRoute = useRoute<LoggedInRouteProp<'LoggedIn_Profile'>>();
  const profileRoute = useRoute();

  const originRoutes = {
    LoggedIn_MyKids: 'LoggedIn_MyKids',
    LoggedIn_TeamRoster: 'LoggedIn_TeamRoster',
    LoggedIn_OnProtocol: 'LoggedIn_OnProtocol',
  };

  const onBack = () => {
    if (rootRoute.params?.prevScreen) {
      navigation.navigate(originRoutes[rootRoute.params.prevScreen]);
    } else if (
      navigation.canGoBack() ||
      (profileRoute.name !== 'LoggedIn_Profile_RecoveryPlan' && profileRoute.name !== 'LoggedIn_Profile_Logs')
    ) {
      navigation.goBack();
    } else {
      navigation.navigate('LoggedIn_Profile', { screen: 'LoggedIn_Profile_Overview', subjectId: data?.id });
    }
  };

  if (data && data.__typename === 'CurrentUser' && title === translate.home.tile.myProfile) {
    finalTitle = <Subtitle fullName={title} />;
  } else if (title && title !== translate.home.tile.myProfile) {
    finalTitle = <Subtitle fullName={title} />;
  } else if (data) {
    finalTitle = <Subtitle fullName={data.fullName} />;
  } else {
    finalTitle = '';
  }

  return (
    <ChromeBase
      mode="title-card"
      title={finalTitle}
      subNavigation={subNavigation}
      navBarProps={{ onBack }}
      {...props}
    />
  );
};
