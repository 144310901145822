import * as React from 'react';
import { useState } from 'react';
import { View, StyleSheet, Linking, Alert } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useShadow } from '../../../../shared/Ui/hooks';
import { AppButton, AppLink, AppText } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';
import { SuperAdminNavType } from '../../SuperAdmin.interface';
import { ErrorsModal } from './ErrorsModal';
import { useDateFormat } from '../../../../hooks/ui/useDateFormat';

export interface PastUpload {
  id: string;
  fileName: string;
  createdDate: any;
  status: string;
  downloadUri: string;
  author: string;
  errors: string | null;
  type: string;
}

interface PastUploadCardProps {
  pastUpload: PastUpload;
  selectedOrganization: string;
}

export const PastUploadCard: React.FC<PastUploadCardProps> = (props) => {
  const { pastUpload, selectedOrganization } = props;
  const { translate } = useTranslations();
  const isMobile = useIsMobile();
  const shadow = useShadow();
  const navigation = useNavigation<SuperAdminNavType<'LoggedIn_SuperAdmin_Index'>>();
  const route = useRoute();

  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);

  const { formatDate } = useDateFormat();

  const navigateToData = (type: string) => {
    if (type === 'SUBJECTS' || type === 'GUARDIANS' || type === 'STAFF') {
      navigation.navigate('LoggedIn_Admin', {
        screen: 'LoggedIn_Admin_ManageUsers',
        organizationId: selectedOrganization,
        params: {
          screen: 'LoggedIn_Admin_ManageUsers_Index',
        },
      });
    } else if (type === 'SUBJECT_GROUPS' || type === 'DESIGNATED_PERSON_GROUPS') {
      navigation.navigate('LoggedIn_Admin', {
        screen: 'LoggedIn_Admin_ManageGroups',
        organizationId: selectedOrganization,
        params: {
          screen: 'LoggedIn_Admin_ManageGroups_Index',
        },
      });
    }
  };

  const styles = StyleSheet.create({
    pastUploadCard: {
      ...shadow,
      height: !isMobile ? 200 : 270,
      flexGrow: !isMobile ? 1 : 0,
      marginBottom: 10,
      padding: !isMobile ? 0 : 10,
    },
    pastUploadCardContainer: {
      display: 'flex',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 10,
      padding: !isMobile ? 20 : 0,
    },
    firstColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: !isMobile ? '100%' : '33%',
      width: !isMobile ? '33%' : '100%',
      marginBottom: !isMobile ? 0 : 15,
    },
    fileName: {
      marginBottom: 5,
    },
    name: {
      marginBottom: 5,
    },
    nameAndDateContainer: {
      marginBottom: !isMobile ? 0 : 15,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: !isMobile ? '33%' : '100%',
    },
    resultMessage: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
    },
    resultMessageBox: {
      position: !isMobile ? 'absolute' : 'relative',
      bottom: !isMobile ? 10 : 0,
      left: !isMobile ? 20 : 0,
      paddingBottom: 20,
    },
  });

  const renderDownloadButton = () => {
    return (
      <AppButton onPress={() => downloadCsv(pastUpload.downloadUri)}>
        {translate.superAdmin.importData.downloadCsv}
      </AppButton>
    );
  };

  const downloadCsv = async (downloadLink: string) => {
    if (await Linking.canOpenURL(downloadLink)) {
      Linking.openURL(downloadLink);
    } else {
      Alert.alert('Error: Cannot download the file. Invalid link.');
    }
  };

  const getStatusLabel = (status: string) => {
    return translate.superAdmin.importData.status[status] || '';
  };

  const getStatusColor = (status: string) => {
    const colors = {
      SUCCESSFULLY_COMPLETED: 'green',
      IN_PROGRESS: 'gray',
      COMPLETED_WITH_ERRORS: 'orange',
      FAILED: 'red',
      ERRORS: 'orange',
      INCORRECT_HEADERS: 'red',
    };
    return colors[status];
  };

  const getImportType = (importType: string) => {
    const importTypes = {
      SUBJECTS: translate.admin.manageSeasons.groupDetails.subjects,
      GUARDIANS: translate.emergencyContact.guardians,
      STAFF: translate.importTypes.staff,
      SUBJECT_GROUPS: translate.importTypes.subjectGroups,
      DESIGNATED_PERSON_GROUPS: translate.importTypes.designatedPersonGroups,
    };
    return importTypes[importType] ? importTypes[importType] : '';
  };

  return (
    <View style={styles.pastUploadCard} key={pastUpload.id}>
      {pastUpload.errors && JSON.parse(pastUpload.errors)['validation errors'] ? (
        <ErrorsModal
          title={`${translate.superAdmin.importData.importErrors}: ${pastUpload.fileName}`}
          rowValidationErrors={JSON.parse(pastUpload.errors)['validation errors']}
          showErrorsModal={showErrorsModal}
          setShowErrorsModal={setShowErrorsModal}
        />
      ) : null}
      <View style={styles.pastUploadCardContainer}>
        <View style={styles.firstColumn}>
          <AppText font="labelDefault" color="blue" style={styles.fileName}>
            {pastUpload.fileName}
          </AppText>
          <AppText font="labelDefault" color="navyDark">
            {getImportType(pastUpload.type)}
          </AppText>
        </View>
        <View style={styles.nameAndDateContainer}>
          <AppText font="labelDefault" color="gray" style={styles.name}>
            {`${pastUpload.author}, ${formatDate(new Date(pastUpload.createdDate), 'MMM dd, yyyy')}`}
          </AppText>
          <AppText font="labelDefault" color={getStatusColor(pastUpload.status)}>
            {`${translate.subjectStatus.modalTitle}: ${getStatusLabel(pastUpload.status)}`}
          </AppText>
        </View>
        {!isMobile ? renderDownloadButton() : null}
      </View>
      {pastUpload.status === 'SUCCESSFULLY_COMPLETED' && route.name === 'LoggedIn_Admin_ImportData' ? (
        <View style={styles.resultMessageBox}>
          <View style={styles.resultMessage}>
            <MaterialIcons name="check-circle" size={24} color="green" />
            <AppText color="navyDark" font="labelDefault">
              {translate.superAdmin.importData.importIsSuccessful}
            </AppText>
          </View>
          <AppLink onPress={() => navigateToData(pastUpload.type)}>{translate.superAdmin.importData.viewData}</AppLink>
        </View>
      ) : null}
      {pastUpload.status === 'FAILED' ? (
        <View style={styles.resultMessageBox}>
          <View style={styles.resultMessage}>
            <MaterialIcons name="error" size={24} color="red" />
            <AppText color="navyDark" font="labelDefault" style={{ marginLeft: 10 }}>
              {translate.superAdmin.importData.importFailed}
            </AppText>
          </View>
          <View style={styles.resultMessage}>
            <AppText color="navyDark" font="labelDefault">
              {pastUpload.errors ? getStatusLabel(JSON.parse(pastUpload.errors)['errors']) : ''}
            </AppText>
          </View>
        </View>
      ) : null}
      {pastUpload.status === 'ERRORS' ? (
        <View style={styles.resultMessageBox}>
          <View style={styles.resultMessage}>
            <MaterialIcons name="error" size={24} color="red" />
            <AppText color="navyDark" font="labelDefault" style={{ marginLeft: 10 }}>
              {translate.superAdmin.importData.partialErrors}
            </AppText>
          </View>
          <AppLink onPress={() => setShowErrorsModal(true)}>{translate.superAdmin.importData.viewFailedData}</AppLink>
        </View>
      ) : null}
      {isMobile ? renderDownloadButton() : null}
    </View>
  );
};
