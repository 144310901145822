import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { View, StyleSheet } from 'react-native';
import { AppButton } from '../../../../uiComponents';
import { UpdateProtocolConfigFormValue } from './ManageProtocol.interface';

export interface SaveProtocolButtonsProps {}

export const SaveProtocolButtons: React.FC<SaveProtocolButtonsProps> = (props) => {
  const { translate } = useTranslations();
  const { handleSubmit, isSubmitting, isValid, dirty, resetForm } = useFormikContext<UpdateProtocolConfigFormValue>();

  const styles = StyleSheet.create({
    container: {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      flexWrap: 'wrap',
      marginBottom: 15,
    },
    saveProtocolButton: {
      width: 200,
    },
  });

  return (
    <View style={styles.container}>
      {dirty ? (
        <AppButton
          type="outline"
          disabled={isSubmitting || !dirty}
          onPress={resetForm}
          style={styles.saveProtocolButton}
        >
          {translate.admin.manageProtocol.updateProtocolConfigForm.reset}
        </AppButton>
      ) : null}
      <AppButton
        loading={isSubmitting}
        disabled={isSubmitting || !isValid || !dirty}
        onPress={handleSubmit}
        style={styles.saveProtocolButton}
      >
        {translate.admin.manageProtocol.updateProtocolConfigForm.update}
      </AppButton>
    </View>
  );
};
