import { isNull } from 'lodash/fp';
import {
  RtaProtocolType,
  UpdateEducationProtocolConfigInput,
  UpdateEducationProtocolItemConfigInput,
  UpdateProtocolConfigInput,
  UpdateRemoveProtocolConfigInput,
  UpdateRemoveProtocolStepConfigInput,
  UpdateReturnProtocolChildProtocolConfigInput,
  UpdateReturnProtocolConfigInput,
  UpdateReturnProtocolStageConfigInput,
} from '../../../../../types/globalTypes';
import {
  UpdateEducationProtocolConfigFormValue,
  UpdateProtocolConfigFormValue,
  UpdateRemoveProtocolConfigFormValue,
  UpdateReturnProtocolConfigFormValue,
  UpdateReturnProtocolChildProtocolConfigFormValue,
} from '../ManageProtocol.interface';

export const notNullFilter = <T>(val: T | null): val is T => !isNull(val);

const mapEducationProtocol = (values: UpdateEducationProtocolConfigFormValue) => {
  const input: UpdateEducationProtocolConfigInput = {
    items: values.items.map((item) => {
      const _input: UpdateEducationProtocolItemConfigInput = {
        instruction: item.instruction,
        name: item.name,
        useForDesignatedPerson: item.useForDesignatedPerson,
        useForGuardian: item.useForGuardian,
        useForSubject: item.useForSubject,
      };
      if (item.type === 'document') {
        _input.document = {
          id: item.document.nextDocument?.id,
          upload: item.document.nextDocument?.upload,
        };
      } else {
        _input.url = item.url;
      }
      return _input;
    }),
  };

  return input;
};

const mapRemoveProtocol = (values: UpdateRemoveProtocolConfigFormValue) => {
  const input: UpdateRemoveProtocolConfigInput = {
    discretionaryInstruction: values.discretionaryInstruction,
    emergencyInstruction: values.emergencyInstruction,
    removeInstruction: values.removeInstruction,
    resumeInstruction: values.resumeInstruction,
    steps: values.steps.map((_value) => {
      const _input: UpdateRemoveProtocolStepConfigInput = {};

      if (_value.type === 'symptom') {
        _input.symptom = {
          instruction: _value.instruction,
          name: _value.name,
          symptoms: _value.symptoms.map(({ key, value }) => ({
            key,
            value,
          })),
        };
      } else {
        _input.challenge = {
          instruction: _value.instruction,
          name: _value.name,
          challenges: _value.challenges.map(({ key, value }) => ({
            key,
            value,
          })),
        };
      }

      return _input;
    }),
    redFlag: {
      flags: values.redFlag.flags.map(({ key, value }) => ({ key, value })),
      instruction: values.redFlag.instruction,
      name: values.redFlag.name,
    },
    incidentReport: {
      instruction: values.incidentReport.instruction,
      templateDocument: values.incidentReport.templateDocument
        ? {
            id: values.incidentReport.templateDocument.nextDocument?.id,
            upload: values.incidentReport.templateDocument.nextDocument?.upload,
          }
        : null,
    },
  };

  return input;
};

const mapReturnChildProtocol = (type: RtaProtocolType, values: UpdateReturnProtocolChildProtocolConfigFormValue) => {
  if (!values.enabled) {
    return null;
  }

  const input: UpdateReturnProtocolChildProtocolConfigInput = {
    type,
    stages: values.stages.map((_values) => {
      const _input: UpdateReturnProtocolStageConfigInput = {
        authorizedRole: _values.authorizedRole,
        timeGateHours: _values.timeGateHours ? parseInt(_values.timeGateHours) : null,
      };
      if (_values.type === 'document-submission') {
        _input.documentSubmission = {
          description: _values.description,
          name: _values.name,
          templateDocument: _values.templateDocument
            ? {
                id: _values.templateDocument.nextDocument?.id,
                upload: _values.templateDocument.nextDocument?.upload,
              }
            : null,
        };
      } else {
        _input.signOff = {
          description: _values.description,
          name: _values.name,
        };
      }
      return _input;
    }),
  };
  return input;
};

const mapReturnProtocol = (values: UpdateReturnProtocolConfigFormValue) => {
  const input: UpdateReturnProtocolConfigInput = {
    medicalAssessment: {
      instruction: values.medicalAssessment.instruction,
      name: values.medicalAssessment.name,
      templateDocument: values.medicalAssessment.templateDocument
        ? {
            id: values.medicalAssessment.templateDocument.nextDocument?.id,
            upload: values.medicalAssessment.templateDocument.nextDocument?.upload,
          }
        : null,
    },
    childProtocols: [
      mapReturnChildProtocol(RtaProtocolType.LEARN, values.returnToLearn),
      mapReturnChildProtocol(RtaProtocolType.SPORT, values.returnToSport),
    ].filter(notNullFilter),
  };
  return input;
};

export const useMapFormValuesToUpdateProtocolConfigInput =
  () => (organizationId: string, values: UpdateProtocolConfigFormValue) => {
    const input: UpdateProtocolConfigInput = {
      organizationId,
      protocolName: values.protocolName,
      protocolUrl: values.protocolUrl,
      educationProtocol: mapEducationProtocol(values.educationProtocol),
      removeProtocol: mapRemoveProtocol(values.removeProtocol),
      returnProtocol: mapReturnProtocol(values.returnProtocol),
    };
    return input;
  };
