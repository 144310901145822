import React, { useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { Chrome } from '../shared/Chrome/Chrome';
import { AccountContent } from './AccountContent';
import { useUpdateAccount } from './hooks/useUpdateAccount';
import { isEmpty } from 'lodash';
import { useTranslations } from '../../i18n/useTranslations';
import { useGetAccount } from './hooks/useGetAccount';
import { CountryIsoCode } from '../../types/globalTypes';
import { useValidation } from '../../hooks/useValidation/useValidation';
import { ActivityIndicator } from '../../uiComponents';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import * as Localization from 'expo-localization';
import { useChangeUserLanguage } from '../../shared/CurrentUser/useChangeUserLanguage';
import { UserLanguage } from '../../types/globalTypes';
import { PasswordContent } from './PasswordContent';
import { useChangePassword } from '../../shared/Firebase';

export interface AccountProps {}

export interface PhoneNumber {
  isoCode: CountryIsoCode;
  phoneNumber: string;
}

export interface EmergencyContact {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
}

export interface UpdateAccountInput {
  id: string;
  firstName: string;
  lastName: string;
  birthday: string;
  email: string;
  phoneNumber?: PhoneNumber;
  language: UserLanguage;
}

export interface ChangePasswordInput {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface PasswordInput {
  currentPassword: string;
  newPassword: string;
}

export interface PasswordChangeModal {
  visible: boolean;
  text: string;
  error: boolean;
}

export const Account: React.FC<AccountProps> = (props) => {
  const [updateAccount] = useUpdateAccount();
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState<PasswordChangeModal>({
    visible: false,
    text: '',
    error: false,
  });
  const { validatePassword } = useValidation();

  const { translate } = useTranslations();
  const { data } = useGetAccount();
  const { currentUser, setCurrentUser } = useCurrentUser();

  const userLanguage =
    currentUser && currentUser.__typename === 'CurrentUser'
      ? UserLanguage[currentUser.language]
      : UserLanguage[Localization.locale.split('-')[0]];

  const accountData = data?.currentUser;

  const [changeUserLanguage] = useChangeUserLanguage({
    onCompleted: (data) => {
      if (data.changeLanguage.__typename === 'Language' && currentUser && currentUser.__typename === 'CurrentUser') {
        setCurrentUser({ ...currentUser, language: data.changeLanguage.language });
      }
    },
  });

  if (accountData?.__typename !== 'CurrentUser') {
    return <Chrome titleText={translate.account.title} mode="title-card" content={<ActivityIndicator />} />;
  }

  const initialValues: UpdateAccountInput = {
    id: accountData.id,
    firstName: accountData.firstName,
    lastName: accountData.lastName,
    birthday: accountData.birthday,
    email: accountData.email ? accountData.email : '',
    phoneNumber: {
      phoneNumber: accountData.phoneNumber?.phoneNumber || '',
      isoCode: accountData.phoneNumber?.country?.isoCode || CountryIsoCode.CA,
    },
    language: userLanguage,
  };

  const passwordInitialValues: ChangePasswordInput = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const handleUpdateAccount = (input: UpdateAccountInput) => {
    changeUserLanguage({
      variables: {
        language: input.language,
      },
    });
    return updateAccount({
      variables: {
        input: {
          id: initialValues.id,
          firstName: input.firstName,
          lastName: input.lastName,
          birthday: input.birthday,
          phoneNumber: input.phoneNumber,
        },
      },
    });
  };

  const validate = (values: UpdateAccountInput) => {
    const errors: FormikErrors<UpdateAccountInput> = {};
    if (isEmpty(values.firstName)) {
      errors.firstName = translate.error.message.cantBeEmpty;
    }

    return errors;
  };

  const validatePasswords = (values: ChangePasswordInput) => {
    const errors: FormikErrors<ChangePasswordInput> = {};

    if (!values.currentPassword) {
      errors.currentPassword = translate.error.message.cantBeEmpty;
    }

    if (!values.newPassword) {
      errors.newPassword = translate.error.message.cantBeEmpty;
    }

    if (!values.confirmNewPassword) {
      errors.confirmNewPassword = translate.error.message.cantBeEmpty;
    }

    if (values.currentPassword && !validatePassword(values.currentPassword)) {
      errors.currentPassword = translate.error.message.authNoPassword;
    }

    if (values.newPassword && !validatePassword(values.newPassword)) {
      errors.newPassword = translate.error.message.authNoPassword;
    }

    if (values.newPassword && values.confirmNewPassword && values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = translate.account.yourDetails.passwordsDoNotMatch;
    }

    if (
      (values.currentPassword && (!values.currentPassword || !values.confirmNewPassword)) ||
      (values.newPassword && (!values.currentPassword || !values.confirmNewPassword)) ||
      (values.confirmNewPassword && (!values.currentPassword || !values.newPassword))
    ) {
      errors.confirmNewPassword = translate.account.yourDetails.allPasswordFieldsRequired;
    }

    return errors;
  };

  const handleChangePassword = (input: PasswordInput) => {
    if (input.currentPassword && input.newPassword) {
      changePassword(input.currentPassword, input.newPassword);
    }
  };

  const changePassword = useChangePassword({
    // TODO: remove temporaryWebAlert and only use notify and alert once the notification banner is working again
    onPasswordChange: () => {
      // notify({
      //   image: 'header/people',
      //   title: translate.flash.title.passwordChanged,
      //   description: translate.flash.message.passwordChanged,
      // });

      setShowPasswordChangeModal({ visible: true, text: translate.flash.message.passwordChanged, error: false });
    },
    onPasswordChangeError: (error) => {
      if (error.code === 'auth/wrong-password') {
        setShowPasswordChangeModal({ visible: true, text: translate.error.message.existingPassword, error: true });
      } else {
        setShowPasswordChangeModal({ visible: true, text: translate.error.message.unkownError, error: true });
        // console.warn(error);
      }
    },
  });

  return (
    <Chrome
      titleText={translate.account.title}
      mode="title-card"
      content={
        <>
          <Formik<UpdateAccountInput>
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleUpdateAccount}
            validate={validate}
          >
            <AccountContent initialValues={initialValues} />
          </Formik>
          <Formik<ChangePasswordInput>
            enableReinitialize={true}
            onSubmit={handleChangePassword}
            validate={validatePasswords}
            initialValues={passwordInitialValues}
          >
            <PasswordContent
              handleChangePassword={handleChangePassword}
              showPasswordChangeModal={showPasswordChangeModal}
              setShowPasswordChangeModal={setShowPasswordChangeModal}
            />
          </Formik>
        </>
      }
    />
  );
};
