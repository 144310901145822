import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { ResendVerificationEmail, ResendVerificationEmailVariables } from './__generated__/ResendVerificationEmail';
import { UnverifiedCurrentUser } from '../../../../fragments/__generated__/UnverifiedCurrentUser';
import { useMutation } from '../../../../hooks/useMutation';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppModal, AppText, Spacer } from '../../../../uiComponents';

const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($input: ResendVerificationEmailInput!) {
    resendVerificationEmail(input: $input)
  }
`;
export interface VerifyProps {
  user: UnverifiedCurrentUser;
}

export const Verify: React.FC<VerifyProps> = ({ user }) => {
  const { translate, translateWithVars } = useTranslations();
  const [showSuccess, setShowSuccess] = useState(false);
  const [resendVerificationEmail, { loading }] = useMutation<ResendVerificationEmail, ResendVerificationEmailVariables>(
    RESEND_VERIFICATION_EMAIL
  );

  const handleResend = () => {
    if (user) {
      resendVerificationEmail({
        variables: {
          input: {
            id: user.id,
          },
        },
      }).then((res) => {
        if (res.data) {
          setShowSuccess(true);
        }
      });
    }
  };

  const handleDismissSuccess = () => setShowSuccess(false);

  return (
    <>
      <AppModal
        visible={showSuccess}
        mode="dialog"
        title={translate.loggedIn.chrome.notVerified.success}
        buttons={{
          ok: <AppButton onPress={handleDismissSuccess}>{translate.loggedIn.chrome.notVerified.ok}</AppButton>,
        }}
        children={
          <AppText style={{ textAlign: 'center' }}>{translate.loggedIn.chrome.notVerified.successDescription}</AppText>
        }
        onDismiss={handleDismissSuccess}
        onRequestClose={handleDismissSuccess}
      />
      <Spacer
        size="large"
        items={{
          title: <AppText font="header1">{translate.loggedIn.chrome.notVerified.title}</AppText>,
          description: (
            <AppText>
              {translateWithVars(translate.loggedIn.chrome.notVerified.description, { email: user.email })}
            </AppText>
          ),
          trouble: <AppText>{translate.loggedIn.chrome.notVerified.trouble}</AppText>,
          resend: (
            <AppButton disabled={loading} loading={loading} onPress={handleResend} style={{ width: '100%' }}>
              {translate.loggedIn.chrome.notVerified.resend}
            </AppButton>
          ),
        }}
      />
    </>
  );
};
