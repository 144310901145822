import { orderBy } from 'lodash/fp';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useTranslations } from '../../../../../../../i18n/useTranslations';
import { AppText, Spacer } from '../../../../../../../uiComponents';
import { GenericStage, RecoveryPlanNavType } from '../../../RecoveryPlan.interface';
import { MedicalAssessmentStage } from './MedicalAssessmentStage';
import { RecoveryPlanStageDocument } from './RecoveryPlanStageDocument';
import { RecoveryPlanRouteProp } from '../../../RecoveryPlan.interface';
import { RecoveryPlanStageSignoff } from './RecoveryPlanStageSignoff';

export interface StageListProps {
  stages: GenericStage[];
}

export const StageList: React.FC<StageListProps> = ({ stages, ...props }) => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RecoveryPlanNavType<'LoggedIn_Profile_RecoveryPlan_Index'>>();
  const route = useRoute<RecoveryPlanRouteProp<'LoggedIn_Profile_RecoveryPlan_Index'>>();

  const handleStagePress = (stageId: string) => {
    navigation.push('LoggedIn_Profile_RecoveryPlan_Stage', { stageId });
  };

  if (route.params?.caseId) {
    stages = stages.filter((stage) => stage.caseId === route.params?.caseId);
  }

  const generateStageList = (_stages: GenericStage[], _filter: (stage: GenericStage) => boolean) =>
    orderBy(({ order }) => order, 'asc', _stages)
      .filter(_filter)
      .map((stage, index) => {
        if (stage.__typename === 'MedicalAssessment') {
          return <MedicalAssessmentStage key={`${stage.id}${index} `} stage={stage} onPress={handleStagePress} />;
        } else if (stage.__typename === 'RecoveryPlanStageDocument') {
          return <RecoveryPlanStageDocument key={`${stage.id}${index} `} stage={stage} onPress={handleStagePress} />;
        } else if (stage.__typename === 'RecoveryPlanStageSignOff') {
          return <RecoveryPlanStageSignoff key={`${stage.id}${index} `} stage={stage} onPress={handleStagePress} />;
        }
      });

  const upcomingItems = generateStageList(stages, ({ isUpcomingStage }) => isUpcomingStage || false);
  const completedItems = generateStageList(stages, ({ isCompletedStage }) => isCompletedStage || false);
  const currentItems = generateStageList(stages, ({ isCurrentStage }) => isCurrentStage || false);

  const styles = StyleSheet.create({
    subtitle: {
      marginBottom: 10,
    },
    sectionTitle: {
      marginVertical: 15,
    },
  });

  return (
    <View>
      <AppText font="header3" style={styles.subtitle}>
        {translate.myProfile.profileHeader.recovery}
      </AppText>
      <Spacer
        items={{
          current:
            currentItems.length > 0 ? (
              <>
                <AppText font="header4" style={styles.sectionTitle}>
                  {translate.recovery.index.current}
                </AppText>
                {currentItems}
              </>
            ) : null,
          upcoming:
            upcomingItems.length > 0 ? (
              <>
                <AppText font="header4" style={styles.sectionTitle}>
                  {translate.recovery.index.remaining}
                </AppText>
                {upcomingItems}
              </>
            ) : null,
          completed:
            completedItems.length > 0 ? (
              <>
                <AppText font="header4" style={styles.sectionTitle}>
                  {translate.label.completeAdjective}
                </AppText>
                {completedItems}
              </>
            ) : null,
        }}
      />
    </View>
  );
};
