import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../fragments/ApiErrorFragment';
import { GetSubjectProfile, GetSubjectProfileVariables } from './__generated__/GetSubjectProfile';
import { ImageFragment } from '../../../../fragments/ImageFragment';

export const GET_SUBJECT_PROFILE_OVERVIEW = gql`
  query GetSubjectProfile($subjectId: ID!) {
    getSubject(input: { subjectId: $subjectId }) {
      ...SubjectProfile
      ... on ApiError {
        ...ApiError
      }
    }
  }
  fragment SubjectProfile on Subject {
    id
    fullName
    age
    birthday
    email
    recoveryPlan {
      id
      stages {
        ... on MedicalAssessment {
          id
        }
      }
    }
    userImage {
      ...Image
    }
  }
  ${ImageFragment}
  ${ApiErrorFragment}
`;

export const useGetSubjectProfile = (options: UseQueryOptions<GetSubjectProfile, GetSubjectProfileVariables>) =>
  useQuery<GetSubjectProfile, GetSubjectProfileVariables>(GET_SUBJECT_PROFILE_OVERVIEW, options);
