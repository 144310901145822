import { gql, MutationHookOptions } from '@apollo/client';
import { MY_ACTION_ITEMS_QUERY } from '../../../../../screens/ActionItems/hooks/useActionItems';
import { UseActionItemsQuery } from '../../../../../screens/ActionItems/hooks/__generated__/UseActionItems';
import { useMutation } from '../../../../../hooks/useMutation';
import { RecoveryPlanFragment } from '../fragments/RecoveryPlanFragment';
import { ReviewMedicalAssessment, ReviewMedicalAssessmentVariables } from './__generated__/ReviewMedicalAssessment';

export const REVIEW_MEDICAL_ASSESSMENT = gql`
  mutation ReviewMedicalAssessment($input: ReviewMedicalAssessmentInput!) {
    reviewMedicalAssessment(input: $input) {
      ... on RecoveryPlan {
        ...RecoveryPlan
      }
    }
  }
  ${RecoveryPlanFragment}
`;

export const useReviewMedicalAssessment = (
  options?: MutationHookOptions<ReviewMedicalAssessment, ReviewMedicalAssessmentVariables>
) =>
  useMutation<ReviewMedicalAssessment, ReviewMedicalAssessmentVariables>(REVIEW_MEDICAL_ASSESSMENT, {
    ...options,
    update(cache, { data }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });
      const medicalAssessmentId =
        data?.reviewMedicalAssessment?.__typename === 'RecoveryPlan' ? data.reviewMedicalAssessment.id : null;
      if (cachedActionItems && medicalAssessmentId) {
        cache.writeQuery<UseActionItemsQuery>({
          query: MY_ACTION_ITEMS_QUERY,
          data: {
            __typename: 'Query',
            actionItems: cachedActionItems.actionItems.filter(
              (actionItem) =>
                !(
                  actionItem.__typename == 'PendingMedicalAssessmentReviewActionItem' &&
                  actionItem.subject.id !== medicalAssessmentId
                )
            ),
          },
        });
      }
    },
  });
