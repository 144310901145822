import React from 'react';
import { UserRoleInvitation } from '../../Invitation/hooks/__generated__/UserRoleInvitation';
import { useTranslations } from '../../../i18n/useTranslations';
import { AppText } from '../../../uiComponents';

export interface InvitationNoticeProps {
  invitation: UserRoleInvitation;
}

export const InvitationNotice: React.FC<InvitationNoticeProps> = ({ invitation }) => {
  const { translate, translateWithVars } = useTranslations();
  const message = translateWithVars(translate.login.invitationNotice.invitationMessage.message, {
    firstName: invitation.user.firstName,
    organization: invitation.organizationName,
    email: invitation.user.email,
  });

  return <AppText>{message}</AppText>;
};
