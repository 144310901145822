import { orderBy } from 'lodash/fp';
import React from 'react';
import { AppButton, AppMarkdown, AppScrollView, AppText, ProtocolAttribution, Spacer } from '../../../../uiComponents';
import { useTranslations } from '../../../../i18n/useTranslations';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { Challenge } from '../FindSubject/__generated__/Challenge';
import { AssessContextState } from '../../Assess.interface';
import { AssessChrome } from '../../AssessChrome';
import { ChallengeResult } from '../../../../types/globalTypes';
import { View } from 'react-native';

export interface StepChallengeProps {
  stepId: string;
  name: string;
  instruction: string;
  challenges: Challenge[];
  challengeId: string;
  onNext: (nextState: AssessContextState) => void;
}

export const StepChallenge: React.FC<StepChallengeProps> = (props) => {
  const { stepId, name, instruction, challenges, challengeId, onNext } = props;
  const { translate, translateWithVars } = useTranslations();
  const { state, dispatch } = useCurrentAssessment();
  const protocol = state.selectedProtocolOption?.protocol;

  const orderedChallenge = orderBy<Challenge>((challenge) => challenge.order, 'asc', challenges);
  const challengedIndex = challengeId ? orderedChallenge.findIndex((challenge) => challenge.id === challengeId) : 0;

  const handleCorrectButtonPress = () => {
    onNext(
      dispatch({
        type: 'setStepValues',
        payload: {
          stepId,
          challengeId,
          type: 'challenge',
          result: ChallengeResult.CORRECT,
        },
      })
    );
  };

  const handleIncorrectButtonPress = () => {
    onNext(
      dispatch({
        type: 'setStepValues',
        payload: {
          stepId,
          type: 'challenge',
          challengeId,
          result: ChallengeResult.INCORRECT,
        },
      })
    );
  };

  const content = (
    <AppScrollView>
      <Spacer
        items={{
          titleArea: (
            <Spacer
              items={{
                title: <AppText font="header2">{name}</AppText>,
                attribution: (
                  <ProtocolAttribution protocolUrl={protocol?.protocolUrl} protocolName={protocol?.protocolName} />
                ),
              }}
            />
          ),
          instruction: <AppMarkdown>{instruction}</AppMarkdown>,
          question: (
            <AppText>
              {translateWithVars(translate.stepChallenges.question, {
                n: challengedIndex + 1,
                of: orderedChallenge.length,
              })}
            </AppText>
          ),
          values: <AppText>{orderedChallenge[challengedIndex].value}</AppText>,
        }}
      />
    </AppScrollView>
  );

  const bottomPanel = (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 20,
        marginBottom: 15,
        flexWrap: 'wrap',
      }}
    >
      <AppButton
        type="secondary"
        onPress={handleCorrectButtonPress}
        style={{ width: 300, marginBottom: 25, marginRight: 15 }}
      >
        {translate.label.correct}
      </AppButton>
      <AppButton type="danger" onPress={handleIncorrectButtonPress} style={{ width: 300, marginBottom: 15 }}>
        {translate.label.incorrect}
      </AppButton>
    </View>
  );

  return (
    <AssessChrome
      content={
        <>
          {content}
          {bottomPanel}
        </>
      }
    />
  );
};
