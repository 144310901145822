import * as React from 'react';
import { Picker } from '@react-native-picker/picker';
import { View, StyleSheet } from 'react-native';
import { noop } from 'lodash';
import { useColors, useShadow } from '../../shared/Ui';
import { Icon } from '../Icon/Icon';
import { SelectProps, SelectValueType } from './Select.interface';
import { InputError } from '../InputError/InputError';
import { fonts } from '../../shared/Ui/defaultStyleConfig';

export const Select = <T extends SelectValueType>({
  value,
  onChange,
  onBlur,
  items,
  style,
  error,
  touched,
  large,
}: SelectProps<T>) => {
  const colors = useColors();
  const shadow = useShadow();

  const styles = StyleSheet.create({
    root: {
      ...shadow,
      paddingVertical: 8,
      paddingHorizontal: 16,
      marginVertical: 15,
      borderRadius: 8,
      backgroundColor: colors.white,
      position: 'relative',
      height: large ? 50 : 40,
    },
    picker: {
      // TODO: Find another way to reset default Webkit styles if necessary.
      // WebkitAppearance: 'none',
      ...fonts.bodyMedium,
      fontSize: large ? fonts.bodyLarge.fontSize : fonts.bodyMedium.fontSize,
      color: colors.navyDark,
      borderWidth: 0,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      width: '100%',
    },
    pickerItems: {},
    icon: {
      position: 'absolute',
      right: 16,
      top: 6,
      pointerEvents: 'none',
    },
  });

  const handleValueChange = (value: T) => (onChange ? onChange(value) : noop());

  return (
    <>
      <View style={StyleSheet.flatten([styles.root, style])}>
        <Picker<T>
          mode="dropdown"
          style={StyleSheet.flatten([styles.picker])}
          selectedValue={value}
          onValueChange={handleValueChange}
          onBlur={onBlur}
          itemStyle={styles.pickerItems}
        >
          {items.map(({ key, label, value }) => (
            <Picker.Item key={key} label={label} value={value} />
          ))}
        </Picker>
        <Icon name={'chevron-down'} color={colors.blue} size={24} style={styles.icon} />
      </View>
      <InputError error={error} touched={touched} />
    </>
  );
};
