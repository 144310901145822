import { gql } from '@apollo/client';
import { useQuery, UseQueryOptions } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { GetMyProfileOverview } from './__generated__/GetMyProfileOverview';

export const GET_MY_PROFILE_OVERVIEW = gql`
  query GetMyProfileOverview {
    currentUser {
      ... on CurrentUser {
        id
        status
        statuses {
          id
          organizationId
          status
        }
        roles {
          id
          organizationId
          role
          organizationName
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useGetMyProfileOverview = (options: UseQueryOptions<GetMyProfileOverview, {}>) =>
  useQuery<GetMyProfileOverview, {}>(GET_MY_PROFILE_OVERVIEW, options);
