import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { AcceptInvitationAction, ErrorCode } from '../../types/globalTypes';
import { isApiError } from '../../utils/isApiError';
import * as React from 'react';
import { useInvitationQuery } from './hooks/useInvitationQuery';
import { InvitationNotFound } from './InvitationNotFound';
import { useTranslations } from '../../i18n/useTranslations';
import { RootNavType, RootRouteProp } from '../../RootNavigator.interface';
import { useEffect } from 'react';
import { useCurrentUser } from '../../shared/CurrentUser/useCurrentUser';
import { LoggedOutChrome } from '../Login/LoggedOutChrome';

export interface InvitationProps {}

// Landing page for accepting an invitation
// Handles logged out case, and redirects to ReviewInvitation to handle logged in case
export const Invitation: React.FC<InvitationProps> = (props) => {
  const route = useRoute<RootRouteProp<'Invitation'>>();
  const navigation = useNavigation<RootNavType<'Invitation'>>();
  const { translate } = useTranslations();
  const { invitationIdentifier, language } = route.params;
  const { currentUser } = useCurrentUser();
  const { data } = useInvitationQuery({
    variables: {
      input: {
        identifier: invitationIdentifier,
      },
    },
  });

  const invitation = data?.invitation;
  const foundInvitation = invitation?.__typename === 'FoundInvitation' ? invitation.foundInvitation : null;
  let content: React.ReactNode = null;

  useEffect(() => {
    if (currentUser) {
      // If we found the invitation and it matches the currently logged in user
      // Redirect the user to the review screen so they can accept it
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: 'LoggedIn',
              state: {
                index: 0,
                routes: [
                  {
                    name: 'LoggedIn_ReviewInvitation',
                    params: { invitationIdentifier },
                  },
                ],
              },
            },
          ],
        })
      );
    }
  }, [currentUser]);
  useEffect(() => {
    if (foundInvitation && !currentUser) {
      // If we found the invitation and the user is not logged in
      // Then either redirect them to login if their account exists
      // Or Redirect them to sign up if not
      if (foundInvitation.action === AcceptInvitationAction.LOGIN) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: 'Login',
                params: { invitationIdentifier, language },
              },
            ],
          })
        );
      } else if (foundInvitation.action === AcceptInvitationAction.REGISTER) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: 'Register',
                params: { invitationIdentifier, language },
              },
            ],
          })
        );
      }
    }
  }, [foundInvitation, currentUser]);

  if (isApiError(invitation)) {
    if (invitation.code === ErrorCode.INVITATION_NOT_FOUND) {
      content = <InvitationNotFound />;
    }
  }

  return (
    <LoggedOutChrome
      mode="title-card"
      titleText={translate.invitation.home.title}
      titleImage="People"
      content={content}
    />
  );
};
