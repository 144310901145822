import { AuthorizedRole, FileIdOrUploadInput } from '../../../../types/globalTypes';
import type { Document } from '../../../../fragments/__generated__/Document';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteConfig } from '../../../../app.interface';
import { AdminNavType } from '../../Admin.interface';

export interface UpdateProtocolConfigFormValue {
  organizationId: string;
  protocolName: string;
  protocolUrl: string;
  educationProtocol: UpdateEducationProtocolConfigFormValue;
  removeProtocol: UpdateRemoveProtocolConfigFormValue;
  returnProtocol: UpdateReturnProtocolConfigFormValue;
}

export interface KeyValuePair {
  key: string;
  value: string;
}
export interface UpdateEducationProtocolConfigFormValue {
  items: UpdateEducationProtocolItemConfigFormValue[];
}

export interface UpdateEducationProtocolItemBaseConfigFormValue {
  instruction: string;
  name: string;
  useForDesignatedPerson: boolean;
  useForGuardian: boolean;
  useForSubject: boolean;
}

export interface UpdateEducationProtocolItemUrlConfigFormValue extends UpdateEducationProtocolItemBaseConfigFormValue {
  type: 'url';
  url: string;
  initialDocumentId?: string | undefined | null;
}
export interface UpdateEducationProtocolItemDocumentConfigFormValue
  extends UpdateEducationProtocolItemBaseConfigFormValue {
  type: 'document';
  document: DocumentInput;
}
export type UpdateEducationProtocolItemConfigFormValue =
  | UpdateEducationProtocolItemUrlConfigFormValue
  | UpdateEducationProtocolItemDocumentConfigFormValue;

export interface UpdateRemoveProtocolConfigFormValue {
  discretionaryInstruction: string;
  emergencyInstruction: string;
  removeInstruction: string;
  resumeInstruction: string;
  redFlag: UpdateRemoveProtocolRedFlagConfigFormValue;
  steps: UpdateRemoveProtocolStepConfigFormValue[];
  incidentReport: UpdateRemoveProtocolIncidentReportConfigFormValue;
}

export interface UpdateRemoveProtocolIncidentReportConfigFormValue {
  instruction: string;
  templateDocument: DocumentInput;
}

export interface UpdateRemoveProtocolRedFlagConfigFormValue {
  instruction: string;
  name: string;
  flags: KeyValuePair[];
}

export interface UpdateRemoveProtocolStepSymptomConfigFormValue {
  type: 'symptom';
  instruction: string;
  name: string;
  symptoms: KeyValuePair[];
}

export interface UpdateRemoveProtocolStepChallengeConfigFormValue {
  type: 'challenge';
  instruction: string;
  name: string;
  challenges: KeyValuePair[];
}

export type UpdateRemoveProtocolStepConfigFormValue =
  | UpdateRemoveProtocolStepSymptomConfigFormValue
  | UpdateRemoveProtocolStepChallengeConfigFormValue;

export interface UpdateReturnProtocolConfigFormValue {
  medicalAssessment: UpdateReturnProtocolMedicalAssessmentConfigFormValue;
  returnToLearn: UpdateReturnProtocolChildProtocolConfigFormValue;
  returnToSport: UpdateReturnProtocolChildProtocolConfigFormValue;
}

export interface DocumentInput {
  nextDocument?: FileIdOrUploadInput | undefined | null;
  currentDocument?: Document | undefined | null;
}

export interface UpdateReturnProtocolMedicalAssessmentConfigFormValue {
  name: string;
  instruction: string;
  templateDocument: DocumentInput;
}

export interface UpdateReturnProtocolChildProtocolConfigFormValue {
  enabled: boolean;
  stages: UpdateReturnProtocolChildProtocolStageConfigFormValue[];
}

export interface UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue {
  type: 'document-submission';
  authorizedRole: AuthorizedRole;
  name: string;
  description: string;
  templateDocument: DocumentInput;
  isTimeGateSelected: boolean;
  timeGateHours?: string;
}
export interface UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue {
  type: 'signoff';
  authorizedRole: AuthorizedRole;
  name: string;
  description: string;
  initialTemplateDocumentId?: string | undefined | null;
  isTimeGateSelected: boolean;
  timeGateHours?: string;
}
export type UpdateReturnProtocolChildProtocolStageConfigFormValue =
  | UpdateReturnProtocolChildProtocolStageDocumentSubmissionConfigFormValue
  | UpdateReturnProtocolChildProtocolStageSignoffConfigFormValue;

export enum EducationItemScope {
  USE_FOR_DESIGNATED_PERSON,
  USE_FOR_GUARDIAN,
  USE_FOR_SUBJECT,
}

export type ManageProtocolParamList = {
  LoggedIn_Admin_ManageProtocol_Index: undefined;
  LoggedIn_Admin_ManageProtocol_General: undefined;
  LoggedIn_Admin_ManageProtocol_Education: undefined;
  LoggedIn_Admin_ManageProtocol_Remove_Instructions: undefined;
  LoggedIn_Admin_ManageProtocol_Remove_RedFlag: undefined;
  LoggedIn_Admin_ManageProtocol_Remove_Steps: undefined;
  LoggedIn_Admin_ManageProtocol_Remove_IncidentReport: undefined;
  LoggedIn_Admin_ManageProtocol_Return_MedicalAssessment: undefined;
  LoggedIn_Admin_ManageProtocol_Return_ChildProtocol: { name: 'returnToLearn' | 'returnToSport' };
};

export type ManageProtocolPaths = keyof ManageProtocolParamList;

export type ManageProtocolNavItem = RouteConfig<ManageProtocolPaths>;

export type ManageProtocolNavType<CurrentPath extends ManageProtocolPaths> = CompositeNavigationProp<
  StackNavigationProp<ManageProtocolParamList, CurrentPath>,
  AdminNavType<'LoggedIn_Admin_ManageProtocol'>
>;

export type ManageProtocolRouteProp<CurrentPath extends ManageProtocolPaths> = RouteProp<
  ManageProtocolParamList,
  CurrentPath
>;
