import { gql } from '@apollo/client';

export const AdminSubjectFragment = gql`
  fragment AdminSubject on AdminSubject {
    id
    firstName
    lastName
    fullName
    email
    status
  }
`;
