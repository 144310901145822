import { useNavigation } from '@react-navigation/core';
import React, { useState } from 'react';
import { AppButton, AppMarkdown, AppModal } from '../../../../uiComponents';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';
import { useTranslations } from '../../../../i18n/useTranslations';
import { useSubmitRemoveProtocol } from '../../hooks/useSubmitRemoveProtocol';
import { SubmitRemoveProtocolInput, RfaProtocolResult } from '../../../../types/globalTypes';
import { notNilFilter } from './notNilFilter';
import { LoggedInNavType } from '../../../LoggedIn.interface';
import { RemoveAthleteDialog } from '../Step/RemoveAthleteDialog';
import { SuggestedResultDialog } from '../Step/SuggestedResultDialog';

export const FinishAssessment: React.FC<{}> = ({}) => {
  const { translate, translateWithVars } = useTranslations();
  const navigation = useNavigation<LoggedInNavType<'LoggedIn_Assess'>>();
  const { state, dispatch } = useCurrentAssessment();
  const [submitRemoveProtocol] = useSubmitRemoveProtocol();

  const [showSucceededModal, setSucceededModal] = useState(false);
  const [caseId, setCaseId] = useState<string | null>(null);

  if (!state.subject || !state.selectedProtocolOption) {
    return null;
  }

  const { subject, selectedProtocolOption } = state;

  const cancelFinalDialog = () =>
    dispatch({
      type: 'setDoneRemovalProtocol',
      payload: {
        status: false,
      },
    });

  const handleRemove = () => {
    console.log('running handleRemove');
    dispatch({
      type: 'setSelectedResult',
      payload: {
        selectedResult: RfaProtocolResult.REMOVE,
      },
    });
    handleSubmitButtonPress(RfaProtocolResult.REMOVE);
  };

  const handleResume = () => {
    dispatch({
      type: 'setSelectedResult',
      payload: {
        selectedResult: RfaProtocolResult.RESUME,
      },
    });
    handleSubmitButtonPress(RfaProtocolResult.RESUME);
  };
  const handleSubmitButtonPress = (selectedResult: RfaProtocolResult) =>
    storeAssessment(selectedResult).then((response) => {
      if (response.data?.submitRemoveProtocol.__typename === 'Case') {
        setSucceededModal(true);
        setCaseId(response.data?.submitRemoveProtocol.id);
      }
    });
  const leaveSucceededDialog = () => {
    setSucceededModal(false);
    if (caseId)
      if (state.selectedResult === RfaProtocolResult.REMOVE) {
        navigation.navigate('LoggedIn_IncidentReport', { caseId });
      } else {
        navigation.navigate('LoggedIn_Profile', {
          screen: 'LoggedIn_Profile_RecoveryPlan',
          subjectId: state.subject?.id,
          params: { screen: 'LoggedIn_Profile_RecoveryPlan_Index' },
        });
      }
  };
  const storeAssessment = async (selectedResult: RfaProtocolResult) => {
    if (!state.suggestedResult) {
      throw new Error('Suggested Result not set ');
    }
    const finalSelectedResult = state.selectedResult || selectedResult;

    if (!finalSelectedResult) {
      throw new Error('Selected Result not set ');
    }

    if (!state.selectedProtocolOption) {
      throw new Error('Selected Protocol not set');
    }

    const input: SubmitRemoveProtocolInput = {
      subjectId: subject.id,
      protocolId: selectedProtocolOption.protocol.id,
      organizationId: selectedProtocolOption.organization.id,
      suggestedResult: state.suggestedResult,
      result: finalSelectedResult,
      emergency: state.emergency,
      redFlagResults: state.redFlags.values
        .map(({ redFlagId, result: value }) => (value ? { redFlagId, value } : null))
        .filter(notNilFilter),
      stepResults: state.steps.map((val) => {
        if (val.type === 'symptom') {
          return {
            protocolStepId: val.stepId,
            symptomResults: val.result.map(({ symptomId, result: value }) => ({ symptomId, value })),
            challengeResults: [],
          };
        } else {
          return {
            protocolStepId: val.stepId,
            symptomResults: [],
            challengeResults: val.results.map(({ challengeId, result: value }) => ({ challengeId, value })),
          };
        }
      }),
    };
    return submitRemoveProtocol({
      variables: { input },
    });
  };

  let instructionArea: React.ReactNode;
  switch (state.selectedResult) {
    case RfaProtocolResult.REMOVE:
      if (state.emergency) {
        instructionArea = state.selectedProtocolOption?.protocol.removeProtocol.emergencyInstruction ? (
          <AppMarkdown>{state.selectedProtocolOption.protocol.removeProtocol.emergencyInstruction}</AppMarkdown>
        ) : null;
      } else {
        instructionArea = state.selectedProtocolOption?.protocol.removeProtocol.removeInstruction ? (
          <AppMarkdown>{state.selectedProtocolOption.protocol.removeProtocol.removeInstruction}</AppMarkdown>
        ) : null;
      }
      break;
    case RfaProtocolResult.RESUME:
      instructionArea = state.selectedProtocolOption?.protocol.removeProtocol.resumeInstruction ? (
        <AppMarkdown>{state.selectedProtocolOption.protocol.removeProtocol.resumeInstruction}</AppMarkdown>
      ) : null;
      break;
  }

  return (
    <>
      <RemoveAthleteDialog
        visible={!showSucceededModal && state.showFinalDialog && state.suggestedResult === RfaProtocolResult.REMOVE}
        onRemove={handleRemove}
        onDismiss={cancelFinalDialog}
      />
      <SuggestedResultDialog
        visible={!showSucceededModal && state.showFinalDialog && state.suggestedResult !== RfaProtocolResult.REMOVE}
        onRemove={handleRemove}
        onResume={handleResume}
        onDismiss={cancelFinalDialog}
      />
      <AppModal
        mode="dialog"
        title={translateWithVars(
          state.selectedResult == RfaProtocolResult.REMOVE
            ? translate.incidentReport.subjectRemovedFromActivity
            : translate.incidentReport.subjectResumingActivity,
          {
            name: state.subject?.fullName,
          }
        )}
        onDismiss={leaveSucceededDialog}
        onRequestClose={leaveSucceededDialog}
        buttons={{
          done: <AppButton onPress={leaveSucceededDialog}>{translate.label.done}</AppButton>,
        }}
        visible={showSucceededModal}
        children={instructionArea}
      />
    </>
  );
};
