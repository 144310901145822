import React, { useContext, useEffect, useState } from 'react';
import { FirebaseApp, getApp } from '@firebase/app';
import { getAuth, getIdToken, onAuthStateChanged as firebaseOnAuthStateChanged, User } from '@firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
export interface UseFirebaseContext {
  firebase: FirebaseApp;
  token?: string;
  user?: User;
  initialized?: boolean;
}

export const FirebaseContext = React.createContext<UseFirebaseContext>({
  firebase: undefined as any,
  token: undefined,
});

const { Provider } = FirebaseContext;

export const useFirebase = () => useContext(FirebaseContext);

export const Firebase: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string>();
  const [user, setUser] = useState<User>();
  const [initialized, setInitialized] = useState(false);
  const app = getApp();

  const onAuthStateChanged = (user: User | null) => {
    if (user) {
      // Force refresh so we get updated
      // token when user verifies their email
      getIdToken(user, true).then((token) => {
        setToken(token);
        setUser(user);
        setInitialized(true);
      });
    } else {
      setToken(undefined);
      setUser(undefined);
      setInitialized(true);
    }
  };
  useEffect(() => {
    const unsubscribe = firebaseOnAuthStateChanged(getAuth(app), (user) => onAuthStateChanged(user));

    return unsubscribe;
  }, []);

  return <Provider value={{ firebase: app, token, user, initialized }}>{children}</Provider>;
};
