import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AppButton, AppLink, AppText, Spacer } from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';
import { useAppConfig } from '../../hooks/useAppConfig';
import { Chrome } from '../../shared/Chrome/Chrome';
import CAPT from '../../assets/svg/CAPT.svg';
import PartnerLogo from '../../assets/svg/CAC.svg';
import { RootNavType } from '../../RootNavigator.interface';

export const WelcomeScreen = () => {
  const { translate } = useTranslations();
  const navigation = useNavigation<RootNavType<'Welcome'>>();
  const config = useAppConfig();
  const { version } = config;

  const styles = StyleSheet.create({
    footer: {
      marginTop: 20,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      marginTop: 80,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 40,
    },
    buttons: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    button: {
      marginTop: 6,
      marginBottom: 6,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    link: {
      marginTop: 30,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    version: {
      padding: 5,
      marginTop: 30,
    },
    partner: {
      padding: 5,
      fontSize: 12,
    },
    text: {
      marginBottom: 0,
    },
  });

  const primaryContent = (
    <Spacer
      items={{
        primary: (
          <>
            <View style={styles.logo}>
              <CAPT width={300} height={110} />
            </View>
            <View style={styles.buttons}>
              <AppButton size="large" onPress={() => navigation.navigate('Login')} style={styles.button}>
                {translate.auth.term.signIn}
              </AppButton>
              {/* Commenting out the 'Create Account' button as per task CAPT-655 */}
              {/* <AppButton onPress={() => navigation.navigate('Register')} type="outline" size="large" style={styles.button}>
                  {translate.auth.term.createAccount}
                </AppButton> */}
              <AppLink onPress={() => navigation.navigate('ResetPassword')} style={styles.link}>
                {translate.auth.term.forgotPassword}
              </AppLink>
            </View>
          </>
        ),
        secondary: (
          <View style={styles.footer}>
            <AppText style={styles.partner}>{translate.auth.term.inPartnershipWith}</AppText>
            <PartnerLogo width={160} height={83} />
            <AppText style={styles.version}>{version}</AppText>
          </View>
        ),
      }}
    />
  );

  return (
    <>
      <Chrome mode="full-screen" content={primaryContent} />
    </>
  );
};
