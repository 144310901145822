import React from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { AppButton, AppMarkdown, AppModal } from '../../../../uiComponents';
import { useCurrentAssessment } from '../../hooks/useCurrentAssessment';

export interface RemoveAthleteDialogProps {
  visible: boolean;
  onDismiss: () => void;
  onRemove: () => void;
}

export const RemoveAthleteDialog: React.FC<RemoveAthleteDialogProps> = ({ visible, onDismiss, onRemove }) => {
  const { translate, translateWithVars } = useTranslations();
  const { state } = useCurrentAssessment();

  const removeInstructionArea = state.selectedProtocolOption?.protocol.removeProtocol.removeInstruction ? (
    <AppMarkdown>{state.selectedProtocolOption.protocol.removeProtocol.removeInstruction}</AppMarkdown>
  ) : null;

  return (
    <AppModal
      mode="dialog"
      title={translateWithVars(translate.step.shouldBeRemoved, {
        name: state.subject?.fullName,
      })}
      children={removeInstructionArea}
      onDismiss={onDismiss}
      buttons={{
        cancel: (
          <AppButton type="outline" onPress={onDismiss}>
            {translate.label.cancel}
          </AppButton>
        ),
        remove: (
          <AppButton type="danger" onPress={onRemove}>
            {translate.removeFromSport}
          </AppButton>
        ),
      }}
      visible={visible}
    />
  );
};
