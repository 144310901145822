import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslations } from '../../../i18n/useTranslations';
import { AppScrollView, AppText } from '../../../uiComponents';
import { useIsMobile } from '../../../hooks/ui/useResponsive';
import { ActiveCaseItem } from './ActiveCaseItem';
import { ClosedCaseItem } from './ClosedCaseItem';
import { SimpleCase } from '../../../fragments/__generated__/SimpleCase';
import { useGetAllUserRoles } from '../../../hooks/useGetAllUserRoles';
import { Role } from '../../../types/globalTypes';

interface CaseLogProps {
  subjectId: string | undefined;
  logs: SimpleCase[];
}

export const CaseLog: React.FC<CaseLogProps> = ({ subjectId, logs }) => {
  const { translate } = useTranslations();
  const isMobile = useIsMobile();

  const activeLogs = logs.filter((log) => log.status === 'OPEN');
  const closedLogs = logs.filter((log) => log.status === 'CLOSED');

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    logs: {
      paddingLeft: isMobile ? 0 : 5,
      minHeight: 250,
      maxHeight: 300,
    },
    itemsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 30,
    },
    caseLogsTitle: {
      marginTop: isMobile ? 20 : 0,
    },
  });

  const { data } = useGetAllUserRoles();

  const userRoles = data ? data.allUserRoles.map((role) => role.role) : [];

  const canViewCaseLog =
    userRoles.includes(Role.PRIMARY_DESIGNATED_PERSON) ||
    userRoles.includes(Role.SECONDARY_DESIGNATED_PERSON) ||
    userRoles.includes(Role.SUBJECT) ||
    userRoles.includes(Role.GUARDIAN);

    return (
    <View style={styles.container}>
      {activeLogs.length > 0 ? (
        <View style={styles.logs}>
          <AppText font="header3">{translate.caseLog.activeCases}</AppText>
          <AppScrollView contentContainerStyle={styles.itemsContainer}>
            {activeLogs.map((item) => (
              <ActiveCaseItem
                caseId={item.id}
                key={item.id}
                organizationName={item.organization.name}
                subjectId={subjectId}
                canViewCaseLog={canViewCaseLog}
              />
            ))}
          </AppScrollView>
        </View>
      ) : null}
      {closedLogs.length > 0 ? (
        <View style={styles.logs}>
          <AppText font="header3" style={styles.caseLogsTitle}>
            {translate.caseLog.caseLogs}
          </AppText>
          <AppScrollView contentContainerStyle={styles.itemsContainer}>
            {closedLogs.map((item) => (
              <ClosedCaseItem caseId={item.id} key={item.id} subjectId={subjectId} canViewCaseLog={canViewCaseLog} />
            ))}
          </AppScrollView>
        </View>
      ) : null}
    </View>
  );
};
