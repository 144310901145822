import { gql, MutationHookOptions } from '@apollo/client';
import { useMutation } from '../../../../../hooks/useMutation';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { UpdateUser, UpdateUserVariables } from './__generated__/UpdateUser';
import { CacheUpdateUserOptions } from './useRemoveUserRole';
import { GetOrganizationUsers } from '../../../hooks/__generated__/GetOrganizationUsers';
import { GET_ORGANIZATION_USERS } from '../../../hooks/useGetOrganizationUsers';

interface RefetchUsersOptions {}

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $organizationId: String!
    $userId: String!
    $firstName: String!
    $lastName: String!
    $newGuardians: [GuardianInvitationInput!]
    $guardiansToRemove: [String!]
    $rolesToAdd: [Role!]
    $rolesToRemove: [Role!]
    $groupsToAdd: [String!]
    $groupsToRemove: [String!]
    $email: String
  ) {
    updateUser(
      organizationId: $organizationId
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      newGuardians: $newGuardians
      guardiansToRemove: $guardiansToRemove
      rolesToAdd: $rolesToAdd
      rolesToRemove: $rolesToRemove
      groupsToAdd: $groupsToAdd
      groupsToRemove: $groupsToRemove
      email: $email
    ) {
      ... on OrganizationUser {
        id
        fullName
        firstName
        lastName
        groups {
          groupId
        }
        email
        birthday
        memberships {
          role
          status
          id
        }
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
`;

export const useUpdateUser = (
  paginationArgs,
  searchStr,
  role,
  options?: MutationHookOptions<UpdateUser, UpdateUserVariables>
) =>
  useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER_MUTATION, {
    ...options,
    refetchQueries: [
      {
        query: GET_ORGANIZATION_USERS,
        variables: {
          organizationId: options?.variables?.organizationId,
          paginationArgs,
          searchStr,
          role,
        },
      },
      'GetOrganizationUsers',
    ],
  });
