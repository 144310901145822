import { gql, useLazyQuery } from '@apollo/client';
import { getSubjectsCasesCSV } from './__generated__/getSubjectsCasesCSV';

const GET_SUBJECT_CASES_CSV = gql`
  query getSubjectsCasesCSV($organizationId: String!, $fromDate: String, $toDate: String, $status: CaseStatus) {
    getCasesCSV(organizationId: $organizationId, fromDate: $fromDate, toDate: $toDate, status: $status)
  }
`;

export const useGetCasesCSV = (options?) =>
  useLazyQuery<getSubjectsCasesCSV>(GET_SUBJECT_CASES_CSV, {
    ...options,
  });
