import React from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { AppButton, AppMarkdown, AppText } from '../../uiComponents';
import { useTranslations } from '../../i18n/useTranslations';

export const ReturnProtocol: React.FC<{
  protocol: {
    type: string;
    stages: Array<{
      name: string;
      description: string;
      __typename: string;
      authorizedRole: string;
      templateDocument?;
      order: number;
      timeGateHours: number | null;
    }>;
  };
}> = (props) => {
  const { protocol } = props;
  const { translate, translateWithVars } = useTranslations();
  const styles = StyleSheet.create({
    title: {
      marginTop: 30,
      marginBottom: 15,
    },
    subTitle: {
      marginTop: 20,
      marginBottom: 15,
    },
    text: {
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'row',
    },
    timeGateText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
  });

  return (
    <View>
      <AppText font="header3" style={styles.title}>
        {protocol.type === 'LEARN' ? translate.protocol.returnToLearn : translate.protocol.returnToSport}
      </AppText>
      {protocol.stages.map((stage, index) => (
        <View key={stage.order}>
          <AppText font="header5" style={styles.subTitle}>
            {`${index + 1}. ${stage.name}`}
          </AppText>
          <AppMarkdown style={{ marginVertical: 15, marginLeft: 15 }}>{stage.description}</AppMarkdown>
          <View style={styles.text}>
            <AppText font="labelDefault" color="grayDark">
              {stage.authorizedRole === 'ANYONE'
                ? translate.protocol.returnStep.anyone
                : translate.protocol.returnStep.pdp}
            </AppText>
            <AppText>
              {` ${
                stage.__typename === 'SignOffStage'
                  ? translate.protocol.returnStep.signoffPermission
                  : translate.protocol.returnStep.documentPermission
              }`}
            </AppText>
          </View>
          {stage.timeGateHours ? (
            <View style={styles.timeGateText}>
              <AppText>{translate.protocol.returnStep.minimum}</AppText>
              <AppText font="labelDefault">
                {translateWithVars(translate.protocol.returnStep.hours, {
                  numberOfHours: stage.timeGateHours,
                  hourOrHours:
                    stage.timeGateHours === 1
                      ? translate.recovery.stageSignoff.hour
                      : translate.recovery.stageSignoff.hours,
                })}
              </AppText>
            </View>
          ) : null}
          {stage.__typename === 'DocumentSubmissionStage' ? (
            <>
              <AppButton
                onPress={() => {
                  if (stage.templateDocument?.downloadUri) {
                    Linking.openURL(stage.templateDocument.downloadUri);
                  }
                }}
                style={{ marginVertical: 15 }}
              >
                {translate.protocol.downloadTemplate}
              </AppButton>
              <AppText style={styles.text}>{translate.protocol.documentApproval}</AppText>
              {stage.timeGateHours ? (
                <View style={styles.timeGateText}>
                  <AppText>{translate.protocol.returnStep.minimum}</AppText>
                  <AppText font="labelDefault" color="black">
                    {translateWithVars(translate.protocol.returnStep.hours, {
                      numberOfHours: stage.timeGateHours,
                      hourOrHours:
                        stage.timeGateHours === 1
                          ? translate.recovery.stageSignoff.hour
                          : translate.recovery.stageSignoff.hours,
                    })}
                  </AppText>
                </View>
              ) : null}
            </>
          ) : null}
        </View>
      ))}
    </View>
  );
};
