import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { isEmpty } from 'lodash';
import { RtaProtocolType, StageState as StageStateType } from '../../../../../../../../types/globalTypes';
import { useColors, useShadow } from '../../../../../../../../shared/Ui/hooks';
import { AppText } from '../../../../../../../../uiComponents';
import { StageState } from './StageState';
import { useTranslations } from '../../../../../../../../i18n/useTranslations';

export interface BaseStageProps {
  onPress?: (id: string) => void;
  id: string;
  title: string;
  organizationName: string;
  state: StageStateType;
  stageNumber?: string;
  protocolType?: RtaProtocolType | undefined | null;
}

export const BaseStage: React.FC<BaseStageProps> = ({ onPress, id, title, organizationName, state, protocolType }) => {
  const colors = useColors();
  const shadow = useShadow();
  const { translate } = useTranslations();

  const styles = StyleSheet.create({
    itemGroupContainer: {
      flexDirection: 'column',
      ...shadow,
      padding: 10,
      paddingLeft: 14,
      marginBottom: 12,
      backgroundColor: colors.white,
      borderRadius: 8,
    },
    itemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    itemStageName: {
      marginTop: 4,
    },
    itemOrganization: {
      marginTop: 10,
    },
  });

  const handlePress = () => {
    if (onPress) {
      onPress(id);
    }
  };

  const protocolTypeText = !protocolType
    ? ''
    : protocolType === RtaProtocolType.LEARN
    ? translate.recovery.index.returnToLearn
    : translate.recovery.index.returnToSport;

  return (
    <TouchableOpacity style={styles.itemGroupContainer} onPress={handlePress}>
      <View style={styles.itemContainer}>
        <AppText font="labelDefault" color="grayDark" style={styles.itemStageName}>
          {title}
        </AppText>
        <StageState state={state} />
      </View>
      <AppText style={styles.itemOrganization}>
        {[organizationName, protocolTypeText].filter((val) => !isEmpty(val)).join(' – ')}
      </AppText>
    </TouchableOpacity>
  );
};
