import { gql, MutationHookOptions } from '@apollo/client';
import { CaseFragment } from '../../../fragments/CaseFragment';
import { ApiErrorFragment } from '../../../fragments/ApiErrorFragment';
import { useMutation } from '../../../hooks/useMutation';
import { SubmitIncidentReport, SubmitIncidentReportVariables } from './__generated__/SubmitIncidentReport';
import { MY_ACTION_ITEMS_QUERY } from '../../ActionItems/hooks/useActionItems';
import { UseActionItemsQuery } from '../../ActionItems/hooks/__generated__/UseActionItems';

export const SUBMIT_INCIDENT_REPORT = gql`
  mutation SubmitIncidentReport($caseId: ID!, $input: SubmitIncidentReportInput!) {
    submitIncidentReport(caseId: $caseId, input: $input) {
      ... on Case {
        ...Case
      }
      ... on ApiError {
        ...ApiError
      }
    }
  }
  ${ApiErrorFragment}
  ${CaseFragment}
`;

export const useSubmitIncidentReport = (
  options?: MutationHookOptions<SubmitIncidentReport, SubmitIncidentReportVariables>
) =>
  useMutation<SubmitIncidentReport, SubmitIncidentReportVariables>(SUBMIT_INCIDENT_REPORT, {
    ...options,
    update(cache, { data }, { variables }) {
      const cachedActionItems = cache.readQuery<UseActionItemsQuery>({
        query: MY_ACTION_ITEMS_QUERY,
      });

      if (cachedActionItems && data && data.submitIncidentReport.__typename === 'Case') {
        cache.writeQuery<UseActionItemsQuery>({
          query: MY_ACTION_ITEMS_QUERY,
          data: {
            __typename: 'Query',
            actionItems: cachedActionItems.actionItems.filter(
              (actionItem) =>
                !(actionItem.__typename === 'IncompleteIncidentReportActionItem' && actionItem.id === variables?.caseId)
            ),
          },
        });
      }
    },
  });
