import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { useColors, useShadow } from '../../../../shared/Ui/hooks';
import { AppText, Icon } from '../../../../uiComponents';
import { useIsMobile } from '../../../../hooks/ui/useResponsive';

export interface SuperAdminTileProps {
  onPress: () => void;
  icon?: string;
  title?: string;
}

export const SuperAdminTile = ({ icon, title, onPress }) => {
  const shadow = useShadow();
  const colors = useColors();
  const isMobile = useIsMobile();

  const styles = StyleSheet.create({
    tile: {
      display: 'flex',
      justifyContent: 'space-evenly',
      aspectRatio: 1,
      backgroundColor: colors.white,
      alignItems: 'center',
      flex: !isMobile ? 1 : 0,
      ...shadow,
    },
    iconWrapper: {
      padding: 20,
      backgroundColor: colors.white,
      borderRadius: 99,
    },
    icon: {
      color: colors.navyDark,
      width: 40,
      height: 40,
    },
    title: {
      marginBottom: !isMobile ? 5 : 0,
      textAlign: 'center',
    },
    badge: {
      position: 'absolute',
      top: 8,
      right: 10,
      lineHeight: 14,
      color: colors.white,
      backgroundColor: colors.blue,
      paddingTop: !isMobile ? 4 : 5,
      paddingBottom: !isMobile ? 4 : 2,
      paddingHorizontal: 8,
      borderRadius: 20,
    },
  });

  return (
    <TouchableOpacity style={styles.tile} onPress={onPress}>
      <View style={styles.iconWrapper}>
        <Icon style={styles.icon} name={icon} size={40} />
      </View>
      <AppText font="header3" style={styles.title}>
        {title}
      </AppText>
    </TouchableOpacity>
  );
};
