import React, { SetStateAction } from 'react';
import { useTranslations } from '../../../../i18n/useTranslations';
import { Tabs } from '../../../../uiComponents';
import { Tabs as Tab } from './ImportData.interface';

export type ImportDataNavigationTab = 'import-data' | 'past-uploads';

interface ImportDataNavigationProps {
  currentTab: Tab;
  setCurrentTab: React.Dispatch<SetStateAction<Tab>>;
}

export const ImportDataNavigation: React.FC<ImportDataNavigationProps> = ({ currentTab, setCurrentTab }) => {
  const { translate } = useTranslations();

  return (
    <Tabs
      tabs={[
        {
          label: translate.superAdmin.importData.importData,
          onPress: () => setCurrentTab(Tab.IMPORT_DATA),
          active: currentTab === Tab.IMPORT_DATA,
        },
        {
          label: translate.superAdmin.importData.pastUploads,
          onPress: () => setCurrentTab(Tab.PAST_UPLOADS),
          active: currentTab === Tab.PAST_UPLOADS,
        },
      ]}
    />
  );
};
