import { useTranslations } from '../../i18n/useTranslations';
import { SubjectStatus } from '../../types/globalTypes';

export const useStatusLabel =
  () =>
  (status: SubjectStatus): string => {
    const { translate } = useTranslations();

    switch (status) {
      case SubjectStatus.ACTIVE:
        return translate.subjectStatus.active;
      case SubjectStatus.ON_PROTOCOL:
        return translate.subjectStatus.onProtocol;
      case SubjectStatus.REMOVED:
        return translate.subjectStatus.removed;
      default:
        return '';
    }
  };
