import { gql } from '@apollo/client';
import { useQuery } from '../../../../../hooks/useQuery';
import { ApiErrorFragment } from '../../../../../fragments/ApiErrorFragment';
import { BasicUserFragment } from '../../../../../fragments/BasicUserFragment';
import { GetGroupsByUserId, GetGroupsByUserIdVariables } from './__generated__/GetGroupsByUserId';

export const GET_GROUPS_BY_USER_ID = gql`
  query GetGroupsByUserId($userId: ID!) {
    groupsByUserId(userId: $userId) {
      id
      organizationId
      name
    }
  }
`;

export const useGetGroupsByUserId = (options?) =>
  useQuery<GetGroupsByUserId, GetGroupsByUserIdVariables>(GET_GROUPS_BY_USER_ID, options);
