import { Chrome } from '../../../../shared/Chrome/Chrome';
import React from 'react';
import { Content } from './Content';
import { useTranslations } from '../../../../../i18n/useTranslations';

export const WrongUser: React.FC = () => {
  const { translate } = useTranslations();
  return (
    <Chrome mode="title-card" autoScroll={false} titleText={translate.wrongUser.screenTitle} content={<Content />} />
  );
};
